import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ImageGallery} from "./image-gallery-dialog";
import {Image} from "../image";
import {Gallery} from "../../gallery";
import {Pagination} from "@core/shared/models/pagination";

@Injectable()
export class ImageGalleryDialogService {

    /**
     * API URL
     */
    private API_URL: string;

    private readonly IMAGE_URL: string = '/images';

    private readonly USER_SELF_URL: string = '/users/self';

    private readonly GALLERY_IMAGE_URL: string = '/galleries';

    private readonly CLONE_URL: string = '/clone';

    private readonly MEDIA_URL: string = '/media';

    /**
     * @param _http
     */
    public constructor(private _http: HttpClient) {
    }

    /**
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }


    public getApiUrl(): string {
        return this.API_URL;
    }

    public getImagesGallery(gallery: Gallery, params?: string[]): Observable<Pagination<ImageGallery>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${ this.API_URL }${ this.USER_SELF_URL }${ this.GALLERY_IMAGE_URL }/${ gallery.id }${ this.IMAGE_URL }`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._http.get<Pagination<ImageGallery>>(url, { headers: headers });
    }

    /**
     * @param gallery
     * @param image
     */
    public deleteImageGallery(gallery: Gallery, image: ImageGallery): Observable<object> {

        return this._http.delete(`${ this.API_URL }${ this.GALLERY_IMAGE_URL }/${ gallery.id }${ this.IMAGE_URL }/${ image.id }`);
    }

    /**
     * @param image
     */
    public getCloneImage(image: ImageGallery): Observable<Image>{

        return this._http.get<Image>(`${ this.API_URL }${ this.IMAGE_URL }/${ image.id }${ this.CLONE_URL }`);
    }

    /**
     * @param image
     */
    public getBinaryImage(image: ImageGallery): Observable<Blob> {

        return this._http.get(`${ this.API_URL }${ this.IMAGE_URL }/${ image.id }${ this.MEDIA_URL }`, {
            responseType: "blob"
        });
    }
}
