import {Component, OnInit} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {TranslationService} from "@core/shared/services/translation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Moment} from "moment";

@Component({
    selector: 'app-core-page-availability-offer-read',
    templateUrl: './page-availability-offer-read.component.html',
    styleUrls: ['./page-availability-offer-read.component.scss']
})
export class PageAvailabilityOfferReadComponent implements OnInit {

    public offer: Offer;

    public dateReadConfiguration: { enable: boolean, date: Moment, offerDateId: number } = {
        enable: false,
        date: null,
        offerDateId: null
    };

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { offer: Offer }): void => {

            this.offer = data.offer;
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/availability/offer/list']);
    }

    public redirectToUpdate(): void {

        this._router.navigate(['account/availability/offer/update', this.offer.id]);
    }

    public openReadDatePanel(event: { date: Moment, offerDateId: number }): void {

        this.closeReadDatePanel();

        if(!event.offerDateId){

            return;
        }

        document.querySelector('body').classList.add('has_panel_open');

        setTimeout((): void => {

            this.dateReadConfiguration = {
                enable: true,
                date: event.date,
                offerDateId: event.offerDateId
            };
        });
    }

    public closeReadDatePanel(): void {

        document.querySelector('body').classList.remove('has_panel_open');

        this.dateReadConfiguration = {
            enable: false,
            date: null,
            offerDateId: null
        };
    }
}
