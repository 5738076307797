import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {File} from "./file";
import {FileUploadConfig} from "../file-upload/file-upload";
import {FileService} from "./file.service";
import {Gallery} from "../gallery";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'media-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
    providers: [
        FileService
    ]
})
export class FileComponent implements OnInit {

    @Input() apiUrl: string;

    @Input() file: File;

    @Input() gallery: Gallery;

    @Input() uploadMaxFileSize: number;

    @Input() fileUploadConfig: Partial<FileUploadConfig>;

    @Output() onAdd: EventEmitter<File> = new EventEmitter<File>();

    @Output() onDelete: EventEmitter<File> = new EventEmitter<File>();

    @Output() onUpdate: EventEmitter<File> = new EventEmitter<File>();

    constructor(
        private _fileService: FileService,
        private _http: HttpClient
    ) {
    }

    ngOnInit() {

        this._fileService.setApiUrl(this.apiUrl);

        if (this.gallery.context) {
            this._http.get<any>(`${this._fileService.getApiUrl()}/galleries/file/${this.gallery.context}`).subscribe((config: any) => {
                this.gallery = config.gallery;

                this.fileUploadConfig = Object.assign({
                    allowedTypes: this._fileService.getAllowedFileTypes().join(),
                    maxSize: this.uploadMaxFileSize || 30000000,
                    multiple: false,
                    uploadApi: {
                        url: `${ this._fileService.getApiUrl() + this._fileService.getGalleryUrl() }/${ this.gallery.id + this._fileService.getFileUrl() }`
                    }
                }, this.fileUploadConfig);
            });
        }


    }

    add(file: any): void {

        this.file = Object.assign(file, {
            originalName: file.original_name
        });

        this.onAdd.emit(this.file);
    }

    delete(): void {

        const deletedFile = JSON.parse(JSON.stringify(this.file));

        this.file = null;

        this.onDelete.emit(deletedFile);
    }

    update(): void {

        this.onUpdate.emit(this.file);
    }

    public downloadItem(): void {

        this._http.get(`${this._fileService.getApiUrl()}/files/${this.file.id}/download`, {
            responseType: 'blob'
        }).subscribe((blob: Blob): void =>{

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            a.href = objectUrl;

            a.download = this.file.originalName;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }
}
