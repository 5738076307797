import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {StatusType} from "@core/shared/models/status-type";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {Tender} from "@core/shared/models/tender";
import {TenderService} from "@core/shared/services/tender.service";
import {TENDER_STATUS} from "@core/shared/data/tender/tender-status";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {TenderOfferCreator} from "@core/shared/models/tender/tender-offer-creator";
import {TENDER_OFFER_CREATOR_STATUS} from "@core/shared/data/tender/tender-offer-creator/tender-offer-creator-status";
import moment, {Moment} from "moment/moment";
import {DATE_UNDERSCORE_FORMAT} from "@app/data";
import {Access, AccessType} from "@core/shared/models/access";

@Component({
  selector: 'app-core-page-tender-list',
  templateUrl: './page-tender-list.component.html',
  styleUrls: ['./page-tender-list.component.scss']
})
export class PageTenderListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public status: StatusType[] = [];

    public offerCreatorStatus: StatusType[] = [];

    public customerTypologies: { id: number, label: string }[];

    public regions: OfferAttribute[] = [];

    public totalItems: number = 0;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: Tender[];

    public currentUser: User;

    public regionFilterChoices: { id: number, label: string }[] = [];

    constructor(
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _tenderService: TenderService,
        private _customerTypologyService: CustomerTypologyService,
        private _offerAttributeService: OfferAttributeService,
        public userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this._initStatus();

        this._initOfferCreatorStatus();

        this._initCustomerTypologies();

        this._initRegions();

        this._initDisplayedColumns();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        if (this.sort) {

            this.sort.sortChange.subscribe(() => {

                this._resetPagination();
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {

                        return this._loadItems();
                    }),
                    map(this.mapApiResult),
                ).subscribe((data: Tender[]): void => {

                this.items = data;
            })
            ;
        }

        this._loadItems();
    }

    private _initStatus(): void {

        this.status = TENDER_STATUS.map((item: StatusType) => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    private _initOfferCreatorStatus(): void {

        this.offerCreatorStatus = TENDER_OFFER_CREATOR_STATUS.map((item: StatusType) => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((customerTypologies: CustomerTypology[]): void => {

            this.customerTypologies = customerTypologies.map((item: CustomerTypology): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: this.translationService.getFallbackTranslation(item.translations).name
                }
            });
        });
    }

    private _initRegions(): void {

        const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', 'region');

        const params: string[] = [
            `sort[label]=ASC`,
            typeFilter.serialize
        ];

        this._offerAttributeService.getItemsAPI(params).subscribe((regions: OfferAttribute[]): void => {

            this.regions = regions;

            this.regionFilterChoices = this.regions.map((region: OfferAttribute): { id: number, label: string } => {

                return {
                    id: region.id,
                    label: this.translationService.getFallbackTranslation(region.translations).label
                };
            });
        });
    }

    private _initDisplayedColumns(): void {

        this.displayedColumns.push('actions');

        this.displayedColumns.push('society');

        this.displayedColumns.push('responsibleUserLastName');

        this.displayedColumns.push('responsibleUserFirstName');

        this.displayedColumns.push('createdAt');

        this.displayedColumns.push('ownerReference');

        this.displayedColumns.push('reference');

        if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR')){

            this.displayedColumns.push('offerCreatorStatus');
        }

        this.displayedColumns.push('status');

        this.displayedColumns.push('closedAt');

        this.displayedColumns.push('customerTypology');

        this.displayedColumns.push('regions');

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            this.displayedColumns.push('countOfferCreators');
        }

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_OFFER_CREATOR'])){

            this.displayedColumns.push('countOffers');
        }
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._loadItems().pipe(
                map(this.mapApiResult)
            ).subscribe((data: Tender[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _loadItems(): Observable<Pagination<Tender>> {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

            return this._tenderService.getPaginationItemsAPI(this.itemsApiParams);

        } else {

            return this._tenderService.getPaginationSocietyItemsAPI(this.currentUser.society.id, this.itemsApiParams);
        }
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Tender[]): void => {

            this.items = data;
        });
    }

    public view(id: number): void {

        this._router.navigate(['account/tender/read', id]);
    }

    public getModel(item: Tender): Tender {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('tender.delete.value');

        const content : string = this._translateService.instant('tender.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._tenderService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('tender.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Tender[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public parsedItemRegions(item: Tender): string {

        if(item.configuration.regions.length && (item.configuration.regions.length === this.regions.length)){

            return this._translateService.instant('france.all.value');
        }

        return item.configuration.regions.map((region: OfferAttribute): string => {

            return this.translationService.getFallbackTranslation(region.translations).label;

        }).join(', ');
    }

    public getItemOfferCreator(item: Tender): TenderOfferCreator {

        return item.offerCreators.find((offerCreator: TenderOfferCreator): boolean => {

            return offerCreator.offerCreator.id === this.currentUser.society.id;
        });
    }

    public isMine(item: Tender): boolean {

        return item.society.id === this.currentUser.society.id;
    }

    public exportTenders(): void {

        this._tenderService.exportItemsAPI().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('tender.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public hasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.currentUser.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    public openCreateTenderDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('tender.question.title.value'),
                content: `<p>${this._translateService.instant('tender.question.description.value')}</p>`
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._router.navigate(['account/tender/create'], { queryParams: { origin: 'tender' } });
        });
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);

            if(this.filterBuilder.getFieldByKey('configuration.regions.translations.label').value){

                params.push(`configuration.regions.translations.locale[eq]=${ this.localeId }`);
            }

            if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && this.filterBuilder.getFieldByKey('offerCreators.status').value){

                params.push(`offerCreators.offerCreator.id[eq]=${ this.currentUser.society.id }`);
            }
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Tender>) => Tender[] {

        return (data: Pagination<Tender>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
