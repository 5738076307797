import {Component, OnInit} from '@angular/core';
import {Subscription} from "@core/shared/models/subscription";
import {ActivatedRoute, Router} from "@angular/router";
import {SocietySubscription} from "@core/shared/models/society-subscription";

@Component({
    selector: 'app-page-licence-update',
    templateUrl: './page-licence-update.component.html',
    styleUrls: ['./page-licence-update.component.scss']
})
export class PageLicenceUpdateComponent implements OnInit {

    public paymentWaiting: boolean = false;

    public downgradeWaiting: boolean = false;

    public societySubscriptions: SocietySubscription[] = [];

    public subscriptions: Subscription[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute, private _router: Router
    ) {}

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { subscriptions: Subscription[], societySubscriptions: SocietySubscription[] }): void => {

            this.subscriptions = data.subscriptions;

            this.societySubscriptions = data.societySubscriptions;

            let countNoValid: number = 0;

            let countToPay : number = 0;

            data.societySubscriptions.forEach((subscription: SocietySubscription): void => {

                if(!subscription.isValid && subscription.paymentStatus === 0){

                    countNoValid++;
                }

                if(subscription.paymentStatus === 2){

                    countToPay++;
                }
            });

            this.paymentWaiting = (countNoValid > 0);

            this.downgradeWaiting = (countToPay > 0);

            if(this.paymentWaiting || this.downgradeWaiting){

                this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
            }
        });
    }
}
