import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {ApiService} from "../api.service";
import {Pagination} from "../../models/pagination";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {NetworkProvider} from "@core/shared/models/network-provider";

@Injectable({
    providedIn: 'root'
})
export class OfferNetworkProviderService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemAPI(id: number, params?: string[]): Observable<OfferGroupNetworkProvider> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/groupNetworkProviders/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<OfferGroupNetworkProvider>(url,{
            headers: headers
        });
    }

    public getItemsAPI(params?: string[]): Observable<OfferGroupNetworkProvider[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/groupNetworkProviders`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferGroupNetworkProvider[]>(url, {
            headers: headers
        });
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<OfferGroupNetworkProvider>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/groupNetworkProviders`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<OfferGroupNetworkProvider>>(url, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<OfferGroupNetworkProvider> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<OfferGroupNetworkProvider>(`${this._apiService.getApiUrl(false, true)}/groupNetworkProviders/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/groupNetworkProviders/${id}`,{
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<OfferGroupNetworkProvider> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferGroupNetworkProvider>(`${this._apiService.getApiUrl(false, true)}/groupNetworkProviders`, data, {
            headers: headers
        });
    }

    public getNetworkProvidersAPI(params?: string[]): Observable<NetworkProvider[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/networkProviders`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<NetworkProvider[]>(url, {
            headers: headers
        });
    }
}
