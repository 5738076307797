<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <ng-container [ngSwitch]="true">

                    <ng-container  *ngSwitchCase="mode === 'personnal-offers'">
                        <div class="personal_offers">


                            <ng-container *ngIf="!hasAccessSubscription">

                                <div class="row">
                                    <h1 class="title">{{ 'offer.reservoir.offerCreator.value' | translate }}</h1>
                                </div>

                            </ng-container>

                           <ng-container *ngIf="hasAccessSubscription">

                               <div class="row">
                                   <h1 class="title">{{ 'offer.reservoir.offerCreator.value' | translate }} ({{ offerSearchService.totalFilteredOffers }} <span>{{ offerSearchService.totalFilteredOffers | translationFromQuantity:'offer.catalog.offerCreator.' + offerFilterType + '.total.empty.value':'offer.catalog.offerCreator.' + offerFilterType + '.total.singular.value':'offer.catalog.offerCreator.' + offerFilterType + '.total.plural.value' }}</span>)</h1>
                               </div>

                               <ng-container *ngIf="currentValidSocietySubscription">
                                   <div class="licences">
                                       <span> {{ 'offers.published.value' | translate }} {{ society.countPublishedOffers }} / {{ currentValidSocietySubscription.subscription.maxOffer }}</span>
                                   </div>
                               </ng-container>

                           </ng-container>

                        </div>

                    </ng-container>

                    <h1 class="title" *ngSwitchCase="mode === 'reservoir' && hasRole('ROLE_PROVIDER')">{{ 'offer.reservoir.provider.value' | translate }}</h1>

                    <h1 class="title" *ngSwitchCase="mode === 'reservoir' && !hasRole('ROLE_PROVIDER')">{{ 'offer.reservoir.value' | translate }}</h1>

                    <ng-container *ngSwitchCase="mode === 'catalog' || mode === 'channel'" >
                        <div class="catalog">

                            <ng-container *ngIf="!hasAccessSubscription">

                                <div class="row">
                                    <h1 class="title">{{ 'offer.reservoir.offerCreator.value' | translate }}</h1>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="hasAccessSubscription">

                                <div class="row">
                                    <h1 class="title">{{ 'offer.catalog.offerCreator.value' | translate }} ({{ offerSearchService.totalFilteredOffers }} <span>{{ offerSearchService.totalFilteredOffers | translationFromQuantity:'offer.catalog.offerCreator.' + offerFilterType + '.total.empty.value':'offer.catalog.offerCreator.' + offerFilterType + '.total.singular.value':'offer.catalog.offerCreator.' + offerFilterType + '.total.plural.value' }}</span>)</h1>
                                </div>

                                <ng-container *ngIf="society.restrictedOfferQuota && hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])">

                                    <div class="licences">

                                        <span>{{ 'society.restrictedQuota.value' | translate}} : {{ society.countRestrictedCatalogOffers }}/{{ society.restrictedOfferQuota }} </span>

                                    </div>
                                </ng-container>

                            </ng-container>

                        </div>
                    </ng-container>

                </ng-container>
            </div>
            <div class="col_content y_items_center">

                <ng-container *ngIf="origin">

                    <!-- Retour à l'origine -->

                    <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToOrigin()">
                        {{ origin.backLabel | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR')">

                    <!-- Création d'une offre -->

                    <button (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">
                        {{ 'offer.create.action.value' | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="(mode === 'catalog') && isQuotationCreationAllowed">

                    <!-- Demande sur-mesure -->

                    <button (click)="openCreateQuotationDialog()" class="btn_cta btn_little btn_add">
                        {{ 'quotation.create' | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="(mode === 'catalog') && isTenderCreationAllowed">

                    <!-- Demande de création d’offres -->

                    <button (click)="openCreateTenderDialog()" class="btn_cta btn_little btn_add">
                        {{ 'tender.value' | translate }}
                    </button>

                </ng-container>

            </div>
        </div>
    </div>

    <!-- Demande d'accès multiple -->

    <ng-container *ngIf="hasRole('ROLE_OFFER_DISTRIBUTOR') || hasRole('ROLE_INSTITUTIONAL') || hasRole('ROLE_FEDERATION')">

        <ng-container *ngIf="offerSearchService.multipleAccessRequestSelectionActivated.getValue(); then multipleAccessRequestSelectionActivated else multipleAccessRequestSelectionDeactivated"></ng-container>

            <ng-template #multipleAccessRequestSelectionActivated>
                <div class="row marges multiple_selection">

                    <div class="col_content">
                        <button class="btn_cta btn_small" (click)="cancelMultipleAccessRequestSelection()">
                            {{ 'offer.accessRequest.multiple.cancel.action.value' | translate }}
                        </button>
                    </div>

                    <div class="col_content">
                        <button class="btn_cta btn_small" [disabled]="!offerSearchService.selectedAccessRequestOffers.getValue().length" (click)="openConfirmMultipleAccessRequestSelectionDialog(offerSearchService.selectedAccessRequestOffers.getValue())">
                            {{ 'offer.accessRequest.multiple.confirm.action.value' | translate }}
                        </button>
                    </div>

                </div>

            </ng-template>

            <ng-template #multipleAccessRequestSelectionDeactivated>

                <div class="row marges multiple_selection" *ngIf="mode === 'reservoir'">
                    <div class="col_content">
                        <button class="btn_cta btn_little" (click)="activateMultipleAccessRequestSelection()">
                            {{ 'offer.accessRequest.multiple.value' | translate }}
                        </button>
                    </div>
                </div>

            </ng-template>

    </ng-container>

    <ng-container *ngIf="hasRole('ROLE_INSTITUTIONAL') || hasRole('ROLE_FEDERATION')">
        <div class="subscription_information">
            <p [innerHTML]="'offer.access.support.contact.value' | translate"></p>
        </div>
    </ng-container>

    <div *ngIf="mode === 'personnal-offers' && displaySubscriptionInformation$ | async" class="subscription_information">
        {{ 'offer.reservoir.subscriptionInformation.value' | translate }}
    </div>

    <!-- Liste des offres -->

    <app-core-offer-search
        [user]="user"
        [society]="society"
        [mode]="mode"
    ></app-core-offer-search>
</div>

