import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Access} from "../models/access";
import {AccessService} from "../services/access.service";

@Injectable({
    providedIn: 'root'
})
export class AccessListResolver  {

    constructor(
        private _accessService: AccessService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._accessService.getItemsAPI();
    }
}
