<ng-container [formGroup]="form">

    <ng-container [formArrayName]="formArrayName">

        <mat-expansion-panel class="mat-expansion-panel-spacing">
            <mat-expansion-panel-header class="movable">
                <mat-panel-title>
                    <mat-icon aria-label="side icon">menu</mat-icon>
                    <span>{{ locationControl.valid ? panelLabel : ((index === 0 ? 'offer.location.index.value' : 'offer.location.secondary.index.value') | translate: { index: index }) }}</span>
                    <span class="{{ locationControl.valid ? 'valid' : 'invalid' }}"></span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="ligne_form row marges">
                <div class="col_6">
                    <app-core-google-places-location [fields]="getLocationLocationFields(locationControl)"></app-core-google-places-location>
                </div>
            </div>
            <ng-container [formGroupName]="indexAsString(index)">

                <div class="ligne_form row marges">

                    <!-- Adresse -->

                    <div class="col_5">
                        <div class="form-group">
                            <input required="required" type="text" value="" placeholder=" " class="form-control" formControlName="address" id="offer-location-address-{{ index }}">
                            <label for="offer-location-address-{{ index }}">{{'offer.location.address.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('address').errors && (locationControl.get('address').dirty || locationControl.get('address').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <!-- Code postal -->

                    <div class="col_2">
                        <div class="form-group">
                            <input required="required" type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="offer-location-zipcode-{{ index }}">
                            <label for="offer-location-zipcode-{{ index }}">{{'offer.location.zipcode.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('zipcode').errors && (locationControl.get('zipcode').dirty || locationControl.get('zipcode').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="locationControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <!-- Ville -->

                    <div class="col_5">
                        <div class="form-group">
                            <input required="required" type="text" value="" placeholder=" " class="form-control" formControlName="city" id="offer-location-city-{{ index }}">
                            <label for="offer-location-city-{{ index }}">{{'offer.location.city.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('city').errors && (locationControl.get('city').dirty || locationControl.get('city').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="ligne_form row marges">

                    <!-- Département -->

                    <div class="col_6">
                        <div class="form-group">
                            <input required="required" type="text" value="" placeholder=" " class="form-control" formControlName="department" id="offer-location-department-{{ index }}">
                            <label for="offer-location-department-{{ index }}">{{'offer.location.department.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('department').errors && (locationControl.get('department').dirty || locationControl.get('department').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('department').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <!-- Pays -->

                    <div class="col_6">
                        <div class="form-group">
                            <select class="form-control" formControlName="country" id="offer-location-country-{{ index }}">
                                <option *ngFor="let country of countries" [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                            </select>
                            <label class="required" for="offer-location-country-{{ index }}">{{'offer.location.country.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('country').errors && (locationControl.get('country').dirty || locationControl.get('country').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="ligne_form row marges">

                    <!-- Latitude -->

                    <div class="col_6">
                        <div class="form-group">
                            <input required="required" type="text" value="" placeholder=" " class="form-control" formControlName="latitude" id="offer-location-latitude-{{ index }}">
                            <label for="offer-location-latitude-{{ index }}">{{'offer.location.latitude.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('latitude').errors && (locationControl.get('latitude').dirty || locationControl.get('latitude').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('latitude').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="locationControl.get('latitude').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <!-- Longitude -->

                    <div class="col_6">
                        <div class="form-group">
                            <input required="required" type="text" value="" placeholder=" " class="form-control" formControlName="longitude" id="offer-location-longitude-{{ index }}">
                            <label for="offer-location-longitude-{{ index }}">{{'offer.location.longitude.value' | translate}}</label>
                            <mat-error *ngIf="locationControl.get('longitude').errors && (locationControl.get('longitude').dirty || locationControl.get('longitude').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="locationControl.get('longitude').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="locationControl.get('longitude').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12">
                        <div class="form-group">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                {{ 'offer.location.coordinates.info.value' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="translationBuilder.items.length">
                    <div class="ligne_form row marges">
                        <div class="col_12">

                            <!-- Champs propres à une langue -->

                            <div class="mat-tab-translations">

                                <ng-container formArrayName="translations">
                                    <mat-tab-group [@.disabled]="true">
                                        <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                            <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                                <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">


                                                    <div class="row marges ligne_form">

                                                        <!-- Titre -->

                                                        <div class="col_6">
                                                            <div class="form-group">
                                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="title" id="offer-location-{{index}}-translation-{{ translationBuilder.getItemIndexByLocale(locale) }}-title">
                                                                <label for="offer-location-{{index}}-translation-{{ translationBuilder.getItemIndexByLocale(locale) }}-title">{{'offer.location.title.value' | translate}}</label>
                                                            </div>
                                                        </div>

                                                        <!-- Description -->

                                                        <div class="col_6">
                                                            <div class="form-group">
                                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="description" id="offer-location-{{index}}-translation-{{ translationBuilder.getItemIndexByLocale(locale) }}-description">
                                                                <label for="offer-location-{{index}}-translation-{{ translationBuilder.getItemIndexByLocale(locale) }}-description">{{'offer.location.description.value' | translate}}</label>
                                                            </div>
                                                            <div class="message">
                                                                    <span class="message-help">
                                                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                                                        {{ 'form.validation.error.maxLength' | translate: {value: 200} }}
                                                                    </span>
                                                            </div>

                                                            <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('description').errors && (translationBuilder.getItemControlByLocale(locale).get('description').dirty || translationBuilder.getItemControlByLocale(locale).get('description').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('description').errors.pattern">{{ 'form.control.error.maxLength.value' | translate: {maxLength: 200} }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                </ng-container>
                                            </mat-tab>
                                        </div>
                                    </mat-tab-group>
                                </ng-container>
                            </div>
                        </div>

                    </div>
                </ng-container>

                <!-- Prestaires -->

                <ng-container *ngIf="form.get('enableMultipleMainLocation').value  && accessMultipleGeolocation ">

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="form-group">

                                <app-offer-location-provider [form]="form" [providersForm]="getLocationForm(indexAsString(index))"  [formName]="'providers'" [emitProviderLocationsUpdate]="false"></app-offer-location-provider>

                            </div>
                        </div>

                    </div>

                </ng-container>

            </ng-container>
        </mat-expansion-panel>

    </ng-container>

</ng-container>
