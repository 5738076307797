import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {OfferDuration} from "@core/shared/models/offer/offer-duration";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {Role} from "@core/shared/models/role";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {Society} from '@core/shared/models/society';
import {
    SelectArrayMultipleFilterComponent
} from "@core/components/filter/select-array-multiple-filter/select-array-multiple-filter.component";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {TextFilterComponent} from "@core/components/filter/text-filter/text-filter.component";
import {
    SelectBooleanFilterComponent
} from "@core/components/filter/select-boolean-filter/select-boolean-filter.component";
import {DateIntervalFilterComponent} from "@core/components/filter/date-interval-filter/date-interval-filter.component";
import * as moment from "moment";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {
    CheckboxBooleanFilterComponent
} from "@core/components/filter/checkbox-boolean-filter/checkbox-boolean-filter.component";
import {SocietyService} from "@core/shared/services/society.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";

@Component({
    selector: 'app-core-offer-filter-panel',
    templateUrl: './offer-filter-panel.component.html',
    styleUrls: ['./offer-filter-panel.component.scss']
})
export class OfferFilterPanelComponent implements AfterViewInit {

    @Input() filterBuilder: FilterBuilder;

    @Input() prefixFilter: string;

    @Input() providerNetwork: OfferGroupNetworkProvider[];

    @Input() offerCreatorNetwork: NetworkOfferCreator[] = [];

    @Input() provider: Society[] = [];

    @Input() restrictedSocietyProviders: Society[] = [];

    @Input() offerCreator: Society[] = [];

    @Input() roles: Role[] = [];

    @Input() mode: ModeType;

    @Input() offerAttributeTouristsDestinations: { id: number, label: string }[] = [];

    @Input() offerAttributeThemes: { id: number, label: string }[] = [];

    @Input() offerAttributeTypes: { id: number, label: string }[] = [];

    @Input() offerAttributeActivities: { id: number, label: string }[] = [];

    @Input() offerAttributeAccomodations: { id: number, label: string }[] = [];

    @Input() offerAttributeRestoration: { id: number, label: string }[] = [];

    @Input() offerAttributeRegions: { id: number, label: string }[] = [];

    @Input() offerDuration: OfferDuration[] = [];

    @Input() offerCustomerTypology: CustomerTypology[] = [];

    @Output() public closeFilterPanelEvent: EventEmitter<any> = new EventEmitter();

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    @ViewChild('offerCreatorFilter', {static: false}) offerCreatorFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('dateFilter', {static: true}) dateFilterComponent: DateIntervalFilterComponent;

    @ViewChild('adultFilter', {static: true}) adultFilterComponent: TextFilterComponent;

    @ViewChild('childFilter', {static: true}) childFilterComponent: TextFilterComponent;

    @ViewChild('cityFilter', {static: true}) cityFilterComponent: TextFilterComponent;

    @ViewChild('durationFilter', {static: true}) durationFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('customerTypologyFilter', {static: true}) customerTypologyFilterComponent: SelectArrayMultipleFilterComponent;

    //@ViewChild('destinationsFilter', {static: true}) destinationsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('typesFilter', {static: true}) typesFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('accomodationsFilter', {static: true}) accomodationsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('restaurationsFilter', {static: true}) restaurationsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('regionsFilter', {static: true}) regionsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('themesFilter', {static: true}) themesFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('activitiesFilter', {static: true}) activitiesFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('isOnAlertFilter', {static: false}) isOnAlertFilterComponent: SelectBooleanFilterComponent;

    @ViewChild('offersAddedFilter', {static: true}) offersAddedFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('groupNetworkProvidersFilter', {static: false}) groupNetworkProvidersFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('restrictedProvidersFilter', {static: false}) restrictedProvidersFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('providersFilter', {static: false}) providersFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('networkOfferCreatorsFilter', {static: false}) groupNetworkOfferCreatorsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('notInCatalogFilter', { static: false }) notInCatalogFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('updatedAtFilter', { static: false }) updatedAtFilterComponent: DateIntervalFilterComponent;

    public translatedOfferDuration = [];

    public translatedOfferType = [];

    constructor(
        private _offerSearchService: OfferSearchService,
        private _societyService: SocietyService,
        private _translateService: TranslateService,
        public translationService: TranslationService
    ) {
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            this.initFilters();

            this._offerSearchService.updateFilter$.subscribe((data: { key: string, value: any }): void => {

                this.updateFilter(data.key, data.value);
            });
        });

        this._offerSearchService.refreshFiltersState$.subscribe((): void => {

            this.initFilters();
        });
    }

    initFilters(): void {

        if (!this._offerSearchService.hasSessionFilters(this.mode)) {

            return;
        }

        // Créateur d'offre

        if (this.offerCreatorFilterComponent) {

            if(this._offerSearchService.getSessionFilter(this.mode, `society.id`)){

                this.offerCreatorFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `society.id`).value;
            }
        }

        // Offres ajoutées dans le catalogue

        if (this.offersAddedFilterComponent) {

            if(this._offerSearchService.getSessionFilter(this.mode, `offerAdded`)){

                this.offersAddedFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `offerAdded`).value;
            }
        }

        if(this.isOneOfTheseModes(['reservoir', 'personnal-offers', 'offer-permanent-option-personnal-offers', 'hashtag-reservoir'])) {

            if(this.notInCatalogFilterComponent){

                this.notInCatalogFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `notInCatalog`).value;
            }
        }

        // Dates

        if(this.dateFilterComponent){

            this.dateFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `date`).value;

            if (this.dateFilterComponent.value) {

                this.dateFilterComponent.start = moment(this.dateFilterComponent.value.start);

                this.dateFilterComponent.end = moment(this.dateFilterComponent.value.end);
            }
        }

        // Nombre d'adultes

        if(this.adultFilterComponent){

            this.adultFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `adult`).value;
        }

        // Nombre d'enfants

        if(this.childFilterComponent){

            this.childFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `child`).value;
        }

        // Villes

        if(this.cityFilterComponent){

            this.cityFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `locations.city`).value;
        }

        // Durée

        if(this.durationFilterComponent){

            this.durationFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `duration.id`).value;
        }

        // Typologie de clientèle

        if(this.customerTypologyFilterComponent){

            this.customerTypologyFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `customerTypology.id`).value;
        }

        //Destinations

        // if(this.destinationsFilterComponent) {
        //
        //     this.destinationsFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.destinations`).value;
        // }

        //Hébergements

        if(this.accomodationsFilterComponent) {

            this.accomodationsFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.accommodations`).value;
        }

        //Restaurations

        if(this.restaurationsFilterComponent) {

            this.restaurationsFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.restoration`).value;
        }

        //Thématiques

        if(this.themesFilterComponent) {

            this.themesFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.themes`).value;
        }

        //Activités

        if(this.activitiesFilterComponent) {

            this.activitiesFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.activities`).value;
        }

        //Régions

        if(this.regionsFilterComponent) {

            this.regionsFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.region`).value;
        }

        //Types de séjour

        if(this.typesFilterComponent) {

            this.typesFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.types`).value;
        }

        // Date de mise à jour

        if (this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_OFFER_CREATOR', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PROVIDER']) || this.isOneOfTheseModes(['catalog', 'offer-permanent-option-catalog', 'hashtag-catalog'])){

            if(this.updatedAtFilterComponent){

                this.updatedAtFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `updatedAt`).value;
            }
        }

        // Groupes des réseaux de prestataires

        if(this.groupNetworkProvidersFilterComponent) {

            if(this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.networkProviders.groupNetworkProvider.id`)) {

                this.groupNetworkProvidersFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.networkProviders.groupNetworkProvider.id`).value;
            }
        }

        // Prestataires restreints

        if(this.restrictedProvidersFilterComponent) {

            if(this._offerSearchService.getSessionFilter(this.mode, `restrictedProviderSocieties.id`)){

                this.restrictedProvidersFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `restrictedProviderSocieties.id`).value;
            }
        }

        // Prestataires

        if(this.providersFilterComponent) {

            if(this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.id`)){

                this.providersFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.id`).value;
            }
        }

        // Réseaux de créateur d'offres

        if(this.groupNetworkOfferCreatorsFilterComponent){

            if(this.groupNetworkOfferCreatorsFilterComponent && this.mode !== 'personnal-offers' && this.mode !== 'offer-permanent-option-personnal-offers') {

                if(this._offerSearchService.getSessionFilter(this.mode, `society.networkOfferCreators.id`)) {

                    this.groupNetworkOfferCreatorsFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `society.networkOfferCreators.id`).value;
                }
            }
        }

        // Vigilance

        if (this.isOnAlertFilterComponent) {

            this.isOnAlertFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `isOnAlert`).value;
        }
    }

    public submit(): void {

        this.filterBuilder.filter();

        this.closePanel();
    }

    public closePanel(): void {

        this.closeFilterPanelEvent.emit(false);
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public isMode(value: ModeType): boolean {

        return this.mode === value;
    }

    public isOneOfTheseModes(items: ModeType[]): boolean {

        return items.some((item: ModeType): boolean => {

            return this.isMode(item);
        });
    }

    public updateFilter(key: string, value: any): void {

        switch (key) {

            case 'society.id':

                // Créateur d'offre

                this.offerCreatorFilterComponent.values = (value as number[]);

                break;
        }
    }

    get translatedDuration(): { id: number, label: string }[] {

        // tslint:disable-next-line:triple-equals
        if (this.translatedOfferDuration.length == 0) {
            this.translatedOfferDuration = this.offerDuration.map((item: OfferDuration): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: this.translationService.getFallbackTranslation(item.translations).label || null
                };
            });

        }

        return this.translatedOfferDuration;
    }

    get translatedType(): { id: number, name: string }[] {

        if (this.translatedOfferType.length == 0) {
            this.translatedOfferType = this.offerCustomerTypology.map((item: CustomerTypology): { id: number, name: string } => {

                return {
                    id: item.id,
                    name: this.translationService.getFallbackTranslation(item.translations).name || null
                };
            });
        }

        return this.translatedOfferType;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get society(): Society {

        return this._societyService.currentUserSociety.getValue();
    }
}
