import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {LocaleItem} from "@core/shared/models/translation";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-core-select-locale-dialog',
    templateUrl: './select-locale-dialog.component.html',
    styleUrls: ['./select-locale-dialog.component.scss']
})
export class SelectLocaleDialogComponent implements OnInit {

    @Output() select: EventEmitter<LocaleItem> = new EventEmitter<LocaleItem>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    item: LocaleItem;

    constructor(
        private _dialogRef: MatDialogRef<SelectLocaleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { items: LocaleItem[] }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.select,
            this.cancel
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    get items(): LocaleItem[] {

        return this.data.items;
    }
}
