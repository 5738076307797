import { Injectable } from '@angular/core';
import {IColumn, IColumnBackgroundColor} from "./column";
import {IRow} from "../row/row";
import {IElement} from "../element/element";

@Injectable({
    providedIn: 'root'
})
export class ColumnService{

    private backgroundColors: IColumnBackgroundColor[] = [];

    setConfig(config: any): void {
        this.backgroundColors = config.backgroundColors;
    }
    /**
     * Récupération d'une colonne en fonction de sa position
     *
     * @param {IRow} row
     * @param {number} columnPosition
     * @returns {IRow}
     */
    getColumnByPosition(row: IRow, columnPosition: number): IColumn{

        let match = row.columns.filter((column: IColumn) => {
            return column.position === columnPosition;
        }).shift();

        return match || null;
    }

    /**
     * Récupération de la dernière colonne
     *
     * @param {IRow} row
     * @returns {IRow}
     */
    getLastColumn(row: IRow): IColumn{

        if(!row.columns.length)
            return null;

        let position: number = -1;

        row.columns.map((column: IColumn) => {
            if(column.position > position){
                position++;
            }
        });

        return this.getColumnByPosition(row, position);
    }

    /**
     * Détermine si la colonne est la première pour une ligne
     *
     * @param {IColumn} column
     * @param {IRow} row
     * @returns {boolean}
     */
    isFirstColumn(row: IRow, column: IColumn): boolean{
        return column.position === 0;
    }

    /**
     * Détermine si la colonne est la dernière pour une ligne
     *
     * @param {IColumn} column
     * @param {IRow} row
     * @returns {boolean}
     */
    isLastColumn(row: IRow, column: IColumn): boolean{
        return this.getLastColumn(row).position === column.position;
    }



    /**
     * Décrémentation de la position d'une colonne pour une ligne
     * Sur l'interface la colonne prend donc la place vers la gauche
     *
     * @param {IColumn} column
     * @param {IRow} row
     */
    decreasePositionColumn(row: IRow, column: IColumn): void{

        if(this.isFirstColumn(row, column))
            return;

        let rowToUp = this.getColumnByPosition(row, column.position - 1);

        rowToUp.position++;
        column.position--;
    }

    /**
     * Incrémentation de la position d'une colonne pour une ligne
     * Sur l'interface la ligne prend donc la place vers la droite
     *
     * @param {IColumn} column
     * @param {IRow} row
     */
    increasePositionColumn(row: IRow, column: IColumn): void{

        if(this.isLastColumn(row, column))
            return;

        let rowToDown = this.getColumnByPosition(row, column.position + 1);

        rowToDown.position--;
        column.position++;
    }

    /**
     * Détermine si une colonne peut prendre la place a gauche
     *
     * @param {IColumn} column
     * @param {IRow} row
     * @returns {boolean}
     */
    isDecreaseColumnAllowed(row: IRow, column: IColumn): boolean{
        return !this.isFirstColumn(row, column);
    }

    /**
     * Détermine si la colonne peut prendre la place a droite
     *
     * @param {IColumn} column
     * @param {IRow} row
     * @returns {boolean}
     */
    isIncreaseColumnAllowed(row: IRow, column: IColumn): boolean{
        return !this.isLastColumn(row, column);
    }

    /**
     * Ajout d'un élement pour une colonne (en dernière position)
     *
     * @param {IColumn} column
     */
    addElement(column: IColumn): void{

        let last_element = 0;

        if(column.elements.length > 0){
            last_element = column.elements[column.elements.length - 1].position + 1;
        }

        let element: IElement = {
            id: null,
            position: last_element,
            type: null,
            backgroundColor: null,
            title: null,
            subtitle: null,
            content: null,
        };

        column.elements.push(element);

    }

    /**
     * Réinitialisation d'une colonne
     *
     * @param {IColumn} column
     */
    clearColumn(column: IColumn): void {

        column.elements = [
            {
                id: null,
                position: 0,
                type: null,
                backgroundColor: null,
                title: null,
                subtitle: null,
                content: null,
            }
        ]
    }

    getBackgroundColors(): IColumnBackgroundColor[]{
        return this.backgroundColors;
    }

}
