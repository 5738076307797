<h1 mat-dialog-title>{{ 'offer.update.confirmation.title.value'|translate }}</h1>

<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <mat-dialog-content>

        <div class="ck-content">
            <p [innerHTML]="'offer.update.confirmation.description.giftVoucher.value'|translate"></p>
        </div>

        <ng-container *ngIf="hasType('gift-voucher')">

            <br>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <div class="radio">
                            <input type="radio" [value]="'update'" formControlName="giftVoucherManagement" class="form-control" id="giftVoucherManagementUpdate">
                            <label for="giftVoucherManagementUpdate">{{ 'offer.update.confirmation.form.giftVoucher.update.value' | translate }}</label>
                        </div>
                        <br/><br/>
                        <div class="radio">
                            <input type="radio" [value]="'dissociate'" formControlName="giftVoucherManagement" class="form-control" id="giftVoucherManagementDissociate">
                            <label for="giftVoucherManagementDissociate">{{ 'offer.update.confirmation.form.giftVoucher.dissociate.value' | translate }}</label>
                        </div>
                        <mat-error *ngIf="form.get('giftVoucherManagement').errors && (form.get('giftVoucherManagement').dirty || form.get('giftVoucherManagement').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('giftVoucherManagement').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <br>

        </ng-container>

    </mat-dialog-content>

    <mat-dialog-actions>

        <div class="row x_center marges">
            <div class="col_content">
                <button type="button" class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
            </div>
            <div class="col_content">
                <button type="submit" class="btn_cta btn_little">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
            </div>
        </div>

    </mat-dialog-actions>

</form>

