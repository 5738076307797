import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {QuotationService} from "@core/shared/services/quotation.service";

@Injectable({
    providedIn: 'root'
})
export class PageQuotationItemResolver  {
    constructor(
        private _quotationService: QuotationService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._quotationService.getItemAPI(parseInt(route.paramMap.get('id')))
    }
}
