import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {Hashtag} from "@core/shared/models/hashtag";

@Injectable({
    providedIn: 'root'
})
export class HashtagService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Hashtag>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/hashtags`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Hashtag>>(url, {
            headers: headers
        });
    }

    public getPaginationSocietyItemsAPI(societyId: number, params?: string[]): Observable<Pagination<Hashtag>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/hashtags`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Hashtag>>(url, {
            headers: headers
        });
    }

    public getMarketplaceOfferItemsAPI(params?: string[]): Observable<Hashtag[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/hashtags/offers/marketplace`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Hashtag[]>(url, {
            headers: headers
        });
    }

    public getSocietyOfferItemsAPI(societyId: number, params?: string[]): Observable<Hashtag[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/hashtags/offers`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Hashtag[]>(url, {
            headers: headers
        });
    }

    public getCatalogOfferItemsAPI(params?: string[]): Observable<Hashtag[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/hashtags/offers/catalog`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Hashtag[]>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number, params?: string[]): Observable<Hashtag> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/hashtags/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<Hashtag>(url,{
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<Hashtag> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Hashtag>(`${this._apiService.getApiUrl(false, true)}/hashtags`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<Hashtag> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Hashtag>(`${this._apiService.getApiUrl(false, true)}/hashtags/${id}`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/hashtags/${id}`,{
            headers: headers
        });
    }
}
