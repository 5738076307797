<!-- Recherche des sociétés -->

<div class="ligne_form" [formGroup]="searchForm">
    <div class="form-group">
        <mat-select placeholder="{{'network.offerCreator.society.select.action.value' | translate}}" [formControlName]="'selection'" [compareWith]="compareItems" (closed)="handleClosure()" multiple="true">
            <mat-option>
                <ngx-mat-select-search
                    #nameFilter [formControl]="optionFilterNameCtrl"
                    [placeholderLabel]="'network.offerCreator.society.search.action.value' | translate"
                    [noEntriesFoundLabel]="'network.offerCreator.society.search.noResult.value' | translate"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option disabled="true">{{'network.offerCreator.society.select.description.value' | translate}}</mat-option>
            <ng-container *ngIf="(filteredOptions | async) as options">
                <mat-option #matOption *ngFor="let option of options" [value]="option" (click)="handleItemSelection(matOption)">
                    {{ option.name }} | {{ getMailingAddress(option)?.address }}, {{ getMailingAddress(option)?.zipcode }} {{ getMailingAddress(option)?.city }}, {{ getCountryTranslation(option)?.name }}
                </mat-option>
            </ng-container>
        </mat-select>
    </div>
</div>

<!-- Sélection actuelle -->

<div class="ligne_form">
    <div class="row marges" *ngIf="selectedItems && selectedItems.length">
        <div class="col y_items_center">
            <div class="selected_offers">
                <span>{{ 'network.offerCreator.society.selection.list.value' | translate }} :</span>
                <ul>
                    <li *ngFor="let item of selectedItems">{{ item.name }}<mat-icon (click)="removeItemSelection(item)">close</mat-icon></li>
                </ul>
            </div>
        </div>
    </div>
</div>
