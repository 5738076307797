import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import * as moment from "moment/moment";
import { DateIntervalFilterValue} from "@core/shared/models/filter/date-interval-filter-field";

@Component({
  selector: 'invoice-download-dialog',
  templateUrl: './invoice-download-dialog.component.html',
  styleUrls: ['./invoice-download-dialog.component.scss'],
  providers: [
     FormService
  ]
})
export class InvoiceDownloadDialogComponent implements OnInit {

    @Output() confirm: EventEmitter<DateIntervalFilterValue> = new EventEmitter<DateIntervalFilterValue>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    constructor(public dialogRef: MatDialogRef<InvoiceDownloadDialogComponent>,
                private _formBuilder: UntypedFormBuilder,
                public formService: FormService,) {
    }

    ngOnInit(): void {

        this._initForm();

        const events: EventEmitter<any>[] = [
            this.confirm,
            this.cancel,
        ];

        events.forEach((event: EventEmitter<any>): void => {

            event.subscribe((): void => {
                this.dialogRef.close();
            })

        });

    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            'dateStart': ['', [Validators.required]],
            'dateEnd': ['', [Validators.required]],
        });

        this.formService.submitCallback = () => {

            const data = {
                start: moment(this.form.get('dateStart').value).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(this.form.get('dateEnd').value).format('YYYY-MM-DD HH:mm:ss')
            }

            this.confirm.emit(data);
        };
    }

    get form(): UntypedFormGroup {
        return this.formService.form;
    }

}
