import { Injectable } from '@angular/core';
import {IContent} from "./content/content";
import {Mode} from "./content/content.service";
import {OptionsImage} from "./cms";

@Injectable()
export class CmsService {

    private content: IContent;
    private enableMultilingual: boolean = false;
    private mode: Mode = null;
    private context: string = null;
    private blocLabel: string = null;
    private entityName: string = null;
    private entityFieldName: string = null;
    private optionsImage: OptionsImage;

    /**
     * Mise à jour du contenu de la page
     *
     * @param {IContent} c
     */
    setContent(c: IContent) {
        this.content = c;
    }

    /**
     * Récupération du contenu de la page
     *
     * @returns {IContent}
     */
    getContent(): IContent {
        return this.content;
    }

    /**
     * Définit l'activation du multilingue
     *
     * @param enable
     */
    setEnableMultilingual(enable): void {
        this.enableMultilingual = enable;
    }

    /**
     * Détermine si le multilingue est activé
     *
     * @returns {boolean}
     */
    isEnableMultilingual(): boolean{
        return this.enableMultilingual;
    }

    /**
     * Mise à jour du mode
     *
     * @param {string} mode
     */
    setMode(mode: Mode): void {
        this.mode = mode;
    }

    /**
     * Détermine si l'application est en mode 'Création"
     *
     * @returns {boolean}
     */
    isCreateMode(): boolean {
        return this.mode === 'create';
    }

    /**
     * Détermine si l'application est en mode 'Edition"
     *
     * @returns {boolean}
     */
    isEditMode(): boolean {
        return this.mode === 'edit';
    }

    /**
     * Détermine le contexte de la page
     *
     * @param {string} context
     */
    setContext(context: string): void{
        this.context = context;
    }

    /**
     * Retourne le contexte de la page
     *
     * @returns {string}
     */
    getContext(): string{
        return this.context;
    }

    setBlocLabel(blocLabel: string): void{
        this.blocLabel = blocLabel;
    }

    getBlocLabel(): string{
        return this.blocLabel;
    }

    setEntityName(entityName: string): void{
        this.entityName = entityName;
    }

    getEntityName(): string{
        return this.entityName;
    }

    setEntityFieldName(entityFieldName: string): void{
        this.entityFieldName = entityFieldName;
    }

    getEntityFieldName(): string{
        return this.entityFieldName;
    }

    setOptionsImage(options: OptionsImage): void{
        this.optionsImage = options;
    }

    getOptionsImage(): OptionsImage{
        return this.optionsImage;
    }
}
