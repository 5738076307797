import {Directive, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
    selector: '[cvv-card]'
})
export class CvvCardDirective {

    @Input() control: AbstractControl;

    @HostListener('input', ['$event'])

    onKeyDown(event: KeyboardEvent) {

        const input = event.target as HTMLInputElement;

        let trimmed = input.value.replace(/\s+/g, '');

        if (trimmed.length > 3) {

            trimmed = trimmed.substr(0, 3);
        }

        if(!trimmed.match(/^[0-9]+$/)){

            trimmed = trimmed.substr(0,-1);
        }

        this.control.patchValue(trimmed);

        this.control.updateValueAndValidity();
    }
}
