<ng-container *ngIf="item$ | async as item">

    <div class="text_content">
        <p>
            <strong>{{ 'distributor.name.value' | translate }}</strong> : {{ item.society.name }}
        </p>

        <p>
            <strong>{{ 'distributor.website.value' | translate }}</strong> : <a href="{{ item.society.mainWebsite }}" target="_blank">{{ item.society.mainWebsite }}</a>
        </p>

        <p>
            <strong>{{ 'distributor.address.value' | translate }}</strong> : {{ getAddressFields(item).address }}, {{ getAddressFields(item).zipcode }} {{ getAddressFields(item).city }}
        </p>

        <p>
            <strong>{{ 'distributor.country.value' | translate }}</strong> : {{ getAddressFields(item).country }}
        </p>

        <ng-container *ngIf="!!item.commissionType">
            <p>
                <strong>{{ 'distributor.commission.type.value' | translate }}</strong> : {{commissionTypeLabel(item.commissionType)}}
            </p>
        </ng-container>

        <ng-container *ngIf="!!item.commission">
            <p>
                <strong>{{ 'distributor.commission.value' | translate }}</strong> : {{formatCommission(item.commission)}}
            </p>
        </ng-container>

        <ng-container *ngIf="item.incomingPayment !== null">
            <p>
                <strong>{{ 'distributor.commission.incomingPayment.value' | translate }}</strong> : {{incomingPaymentLabel(item.incomingPayment)}}
            </p>
        </ng-container>

        <ng-container *ngIf="!!item.society.admin">
            <p>
                <strong>{{ 'distributor.type.value' | translate }}</strong> : {{getRoleLabel(item.society.admin.roles)}}
            </p>
            <span class="message-help" *ngIf="hasOneOfThisRoles(['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'], item.society.admin)">
                <mat-icon class="tooltip_icon">info</mat-icon>
                {{ 'distributor.type.info.value' | translate }}
            </span>
        </ng-container>

    </div>

</ng-container>
