import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Offer} from "@core/shared/models/offer";
import {OfferAccessRequestMultipleCreateConfiguration} from "@core/shared/models/offer/offer-access-request/offer-access-request-multiple/offer-access-request-multiple-create";
import {OfferAccessRequestMultipleCreateComponent} from "@core/components/offer/offer-access-request/offer-access-request-multiple/offer-access-request-multiple-create/offer-access-request-multiple-create.component";
import {UserService} from "@core/shared/services/user.service";

@Component({
    selector: 'app-core-offer-access-request-multiple-create-dialog',
    templateUrl: './offer-access-request-multiple-create-dialog.component.html',
    styleUrls: ['./offer-access-request-multiple-create-dialog.component.scss']
})
export class OfferAccessRequestMultipleCreateDialogComponent implements OnInit {

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(OfferAccessRequestMultipleCreateComponent) offerAccessRequestMultipleCreateComponent: OfferAccessRequestMultipleCreateComponent;

    public submitDisabled: boolean = false;

    constructor(
        private _dialogRef: MatDialogRef<OfferAccessRequestMultipleCreateComponent>,
        public userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: { offers: Offer[] }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.create
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.submitDisabled = true;

        this.offerAccessRequestMultipleCreateComponent.formService.submit();
    }

    get configuration(): OfferAccessRequestMultipleCreateConfiguration {

        return {
            showSubmitButton: false
        };
    }

    get offers(): Offer[] {

        return this.data.offers;
    }
}
