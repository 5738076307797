<h1 mat-dialog-title>
    {{ 'element.types.video.preview.label' | translate }}
</h1>
<div mat-dialog-content>
    <video controls [width]="data.width">
        <source *ngFor="let extension of data.extensions" [src]="data.video[extension].path" [type]="'video/' + extension">
    </video>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="dialogRef.close()">Fermer</button>
</div>
