<div class="row marges ligne_form">

    <!-- Régions -->

    <div class="col_12 col_md_6">
        <div class="bloc_recap">
            <div class="ck-content">
                <p class="titre_h4 highlight">{{ 'offer.item.details.regions.value' | translate }}</p>
                <ul>
                    <ng-container *ngFor="let region of getAttributesByType('region')">
                        <li>{{ translationService.getFallbackTranslation(region.translations).label }}</li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>

    <div class="col_12 col_md_6">
        <div class="bloc_recap">
            <div class="ck-content">

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">

                        <!-- Type de séjours -->

                        <p class="titre_h4 highlight">{{ 'offer.item.details.types.value' | translate }}</p>
                        <ul>
                            <ng-container *ngFor="let type of getAttributesByType('types')">
                                <li>{{ translationService.getFallbackTranslation(type.translations).label }}</li>
                            </ng-container>
                        </ul>

                        <!-- Thématiques -->

                        <p class="titre_h4 highlight">{{ 'offer.item.details.themes.value' | translate }}</p>
                        <ul>
                            <ng-container *ngFor="let theme of getAttributesByType('themes')">
                                <li>{{ translationService.getFallbackTranslation(theme.translations).label }}</li>
                            </ng-container>
                        </ul>

                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Activités payantes incluses dans l'offre -->

                        <p class="titre_h4 highlight">{{ 'offer.item.details.activities.value' | translate }}</p>
                        <ng-container *ngIf="getAttributesByType('activities').length > 0; else uninformed">
                            <ul>
                                <ng-container *ngFor="let activity of getAttributesByType('activities')">
                                    <li>{{ translationService.getFallbackTranslation(activity.translations).label }}</li>
                                </ng-container>
                            </ul>
                        </ng-container>

                    </div>

                </div>

            </div>
        </div>
    </div>

</div>

<div class="row marges ligne_form">

    <div class="col_12 col_md_8">
        <div class="bloc_recap">
            <div class="ck-content">

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">

                        <!-- Hébergement -->

                        <p class="titre_h4 highlight">{{ 'offer.item.details.accommodations.value' | translate }}</p>
                        <ng-container *ngIf="getAttributesByType('accommodations').length > 0; else uninformed">
                            <ul>
                                <ng-container *ngFor="let accommodation of getAttributesByType('accommodations')">
                                    <li>{{ translationService.getFallbackTranslation(accommodation.translations).label }}</li>
                                </ng-container>
                            </ul>
                        </ng-container>

                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Restauration -->

                        <p class="titre_h4 highlight">{{ 'offer.item.details.restorations.value' | translate }}</p>
                        <ng-container *ngIf="getAttributesByType('restoration').length > 0; else uninformed">
                            <ul>
                                <ng-container *ngFor="let restoration of getAttributesByType('restoration')">
                                    <li>{{ translationService.getFallbackTranslation(restoration.translations).label }}</li>
                                </ng-container>
                            </ul>
                        </ng-container>

                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="col_12 col_md_4">

        <!-- Assurances -->

        <div class="bloc_recap">
            <div class="ck-content">
                <p class="titre_h4 highlight">{{ 'offer.item.details.permanentOptions.value' | translate }}</p>
                <ng-container *ngIf="item.permanentOptions.length > 0; else uninformed">
                    <ul>
                        <ng-container *ngFor="let permanentOption of item.permanentOptions">
                            <li>{{ translationService.getFallbackTranslation(permanentOption.offerPermanentOption.translations).title }}</li>
                        </ng-container>
                    </ul>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="item.giftVoucher && item.giftVoucher.enable">

            <!-- Bons cadeaux -->

            <div class="bloc_recap">
                <div class="ck-content">
                    <p class="titre_h4 highlight">{{ 'offer.item.details.giftVoucher.value' | translate }}</p>
                    <p *ngIf="item.publics.includes('adult')">{{ 'offer.item.details.giftVoucher.adultPriceTTC.value' | translate }} : {{ formatPrice(item.giftVoucher.adultPriceTTC, item.currency.symbol) }}</p>
                    <p *ngIf="item.publics.includes('child')">{{ 'offer.item.details.giftVoucher.childPriceTTC.value' | translate }} : {{ formatPrice(item.giftVoucher.childPriceTTC, item.currency.symbol) }}</p>
                </div>
            </div>

        </ng-container>

    </div>

</div>

<ng-template #uninformed>
    <p>{{ 'uninformed.value' | translate }}</p>
</ng-template>