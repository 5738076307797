import {Component, Input, OnInit} from '@angular/core';
import {ServiceService} from "@core/shared/services/service.service";
import {User} from "@core/shared/models/user";
import {Service} from "@core/shared/models/service";
import {SocietyService} from "@core/shared/models/society-service";

@Component({
    selector: 'app-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit{

    @Input() user: User;

    public items: Service[] = [];

    constructor(private _service: ServiceService) {
    }

    ngOnInit() {

        this._service.getItemsAPI().subscribe((data: Service[]): void => {

            this.items = data;
        });
    }
}
