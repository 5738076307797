import {Society} from "@core/shared/models/society";
import {Service} from "@core/shared/models/service";
import {Exclude, Expose, Type} from "class-transformer";
import {PromotionService} from "@core/shared/models/promotion-service";


@Exclude()
export class SocietyService {

    @Expose()
    id: number;

    @Expose()
    createdAt: string;

    @Expose()
    society: Society;

    @Expose()
    @Type(() => Service)
    service: Service;

    @Expose()
    dateNextInvoice: Date;

    @Expose()
    total: number;

    @Expose()
    periodicity: 'M'|'Y';

    @Expose()
    quantity: number;

    @Expose()
    isValid: boolean;

    @Expose()
    isAdmission: boolean;

    @Expose()
    admission: boolean;

    @Expose()
    promotionService: PromotionService;

    @Expose()
    promotionPrice: number;

    @Expose()
    promotionAdmissionPrice: number;

    @Expose()
    hasAdmissionPrice: boolean;

    @Expose()
    hasPromotionPrice: boolean;

    @Expose()
    hasPromotionAdmissionPrice: boolean;

    @Expose()
    vatPercent: number;

    @Expose()
    toDelete: boolean;

    @Expose()
    paymentStatus: number;

    @Expose()
    paymentId: number;

    @Expose()
    admissionPrice: number;

    @Expose()
    price: number;

    @Expose()
    recurringPromotionPrice?: number;

    @Expose()
    recurringPromotionDuration?: number;

}
