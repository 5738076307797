import {FilterField, FilterOperator} from "../filter";

export interface DateIntervalFilterValue {

    start: string;

    end: string;
}

export class DateIntervalFilterField extends FilterField {

    constructor(key: string, operator: FilterOperator, private _startOperator: FilterOperator, private _endOperator: FilterOperator, value?: DateIntervalFilterValue) {

        super(key, operator);

        if(value){

            this.value = value;
        }
    }

    get serialize(): string {

        const value: DateIntervalFilterValue = this.value;

        if(!value){

            return '';
        }

        const requests: string[] = [];

        if(value.start){

            requests.push(`${this.key}[${this._startOperator}]=${value.start}`);
        }

        if(value.end){

            requests.push(`${this.key}[${this._endOperator}]=${value.end}`);
        }

        return requests.join('&');
    }
}
