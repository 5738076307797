import {Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList, Output, EventEmitter} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormService} from '@core/shared/services/form.service';
import {TranslationBuilder} from '@core/shared/models/translation';
import {Offer} from '@core/shared/models/offer';
import {MatExpansionPanel} from "@angular/material/expansion";
import {OfferIncluded} from "@core/shared/models/offer/offer-included";
import {OfferIncludedTranslation} from "@core/shared/models/offer/offer-included-translation";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "@core/shared/services/translation.service";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {OfferService} from "@core/shared/services/offer.service";

@Component({
    selector: 'app-offer-included-create',
    templateUrl: './offer-included-create.component.html',
    styleUrls: ['./offer-included-create.component.scss']
})
export class OfferIncludedCreateComponent implements OnInit, AfterViewInit {

    @ViewChildren(MatExpansionPanel) expensionPanels: QueryList<MatExpansionPanel>;

    public includsLen : number = 0;

    public includsTranslationBuilder: TranslationBuilder[] = [];

    public maxLengthTitle: number = 255;

    public maxLengthDescription: number = 255;

    @Input() parentIncluds: UntypedFormGroup;

    @Input() offer : Offer;

    @Output() controlUpdated: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        public offerService: OfferService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        if (this.offer) {

            this.offer.included.forEach((item: OfferIncluded): void => {

                if (item.isIncluded){

                    this.includsForm.push(this.initIncludsFormControl(item));
                }
            });

        } else {

            this.includsForm.push(this.initIncludsFormControl(null));
        }

        this.form.get('locales').valueChanges.subscribe((locales: string[]): void => {

            this.includsTranslationBuilder.forEach((builder: TranslationBuilder): void => {

                builder.updateItems(locales);
            });
        });
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            if(this.expensionPanels && this.includsForm.length === 1){

                this.expensionPanels.first.expanded = true;
            }
        });
    }

    public initIncludsFormControl(data: OfferIncluded): UntypedFormGroup {

        const control: UntypedFormGroup = this._formBuilder.group({
            isIncluded: [true],
            translations: new UntypedFormArray([])
        });

        const translationBuilder = new TranslationBuilder(this._formBuilder);

        translationBuilder.form = control;

        translationBuilder.addItemCallback = () : UntypedFormGroup => {

            return this._formBuilder.group({
                title: [null, [Validators.required, Validators.maxLength(this.maxLengthTitle)]],
                description : ['', Validators.maxLength(this.maxLengthDescription)]
            });
        };

        if (data){

            data.translations.forEach((translation: OfferIncludedTranslation): void => {

                translationBuilder.addItemControl(translationBuilder.getLocaleItem(translation.locale), translation);
            });

        } else {

            translationBuilder.updateItems(this.form.get('locales').value);
        }

        this.includsTranslationBuilder.push(translationBuilder);

        this.includsLen++;

        return control;
    }

    public addInclud(): void {

        this.includsForm.push(this.initIncludsFormControl(null));

        setTimeout((): void => {

            this.controlUpdated.emit();
        });

        this.openNewPanel();
    }

    public deleteInclud(index: number): void {

        this.includsForm.removeAt(index);

        setTimeout((): void => {

            this.controlUpdated.emit();
        });

        this.includsLen--;
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getIncludsTranslation(indexInclud: number, indexTranslation : number): UntypedFormGroup {

        return (this.includsForm.controls[indexInclud].get('translations') as UntypedFormGroup).controls[indexTranslation] as UntypedFormGroup;
    }

    public getTranslationBuilder(index: number): TranslationBuilder {

        return this.includsTranslationBuilder.find((builder: TranslationBuilder): boolean => {

            return builder.form === this.getIncludControl(index);
        });
    }

    get form(): UntypedFormGroup {

        return this.parentIncluds;
    }

    get includsForm(): UntypedFormArray {

        return this.form.get('trueIncluded') as UntypedFormArray;
    }

    public getIncludControl(index: number): AbstractControl {

        return this.includsForm.controls[index];
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public openNewPanel(): void {

        setTimeout((): void => {

            this.expensionPanels.last.expanded = true;
        });
    }

    public getItemTranslationControl(index: number): AbstractControl {

        const translationControls: UntypedFormArray = this.includsForm.controls[index].get('translations') as UntypedFormArray;

        if(!translationControls.length){

            return null;
        }

        const translationsLocale = this.translationService.getFallbackTranslation(translationControls.value).locale;

        return translationControls.controls.find((control: UntypedFormGroup): boolean => {

            return control.value.locale === translationsLocale;

        }) || translationControls.controls[0];

    }

    get selectedLocales(): string[] {

        if(!this.form || !this.form.get('locales')){

            return [];
        }

        return this.form.get('locales').value;
    }

    dragAndDrop(event: CdkDragDrop<string[]>): void {

        const from: number = this.clampNumber( event.previousIndex, this.includsForm.length - 1);
        const to: number = this.clampNumber(event.currentIndex, this.includsForm.length - 1);

        if (from === to) {
            return;
        }

        const previous: AbstractControl = this.includsForm.at(from);
        const current: AbstractControl = this.includsForm.at(to);

        this.includsForm.setControl(to, previous);
        this.includsForm.setControl(from, current);
    }

    clampNumber(value: number, max: number): number {
        return  Math.max(0, Math.min(max, value));
    }
}
