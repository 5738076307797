<div class="form-group">
    <input type="text" id="location-autocomplete" #location placeholder=" " [matAutocomplete]="auto">
    <label for="location-autocomplete">{{ 'location.search.start.value' | translate }}</label>
    <button type="button" class="autocomplete-trigger" (click)="search()"></button>
</div>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event.option.value)">
    <mat-option *ngFor="let item of items" [value]="item">
        <span>{{ item.description }}</span>
    </mat-option>
</mat-autocomplete>
