import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Iframe} from "./iframe";
import {IframeDialogComponent, IframeDialogData} from "./iframe-dialog/iframe-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: [
        '../../element.component.scss'
    ],
})
export class IframeComponent implements OnInit {

    @Input() content: Iframe;

    @Output() update: EventEmitter<Iframe> = new EventEmitter<Iframe>();

    constructor(private _dialog: MatDialog){
    }

    ngOnInit() {

        if (!this.content) {

            this.content = {
                source: null,
                width: 600,
                height: 400
            };
        }
    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {Iframe} button
     */
    setContent(button: Iframe) {

        this.update.emit(button); // On met à jour le contenu de l'élément
    }

    displayIframe(): void {

        const data: IframeDialogData = {
            iframe: this.content
        };

        this._dialog.open(IframeDialogComponent, {
            width: '800px',
            data: data
        });
    }
}
