import {Component, Input, OnInit} from '@angular/core';
import {FilterBuilder, FilterComponent, FilterOperator} from "@core/shared/models/filter";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";

@Component({
    selector: 'app-core-select-array-filter',
    templateUrl: './select-array-filter.component.html',
    styleUrls: ['./select-array-filter.component.scss']
})
export class SelectArrayFilterComponent implements OnInit, FilterComponent {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() itemValueProperty: string;

    @Input() itemLabelProperty: string;

    @Input() items: object[] = [];

    @Input() operator: FilterOperator;

    @Input() disableAutoSubmit: boolean;

    value: string;

    filterField: ArrayFilterField;

    constructor() {

        this.value = null;
    }

    ngOnInit(): void {

        this.filterField = new ArrayFilterField(this.key, this.operator);

        this.builder.addField(this.filterField);
    }

    public hydrateValue(): void {

        this.filterField.value = (this.value === null || this.value === undefined) ? null : this.value;
    }

    public filter(): void {

        this.hydrateValue();

        if(this.disableAutoSubmit){

            return;
        }

        this.builder.filter();
    }

    public reset(): void {

        this.value = null;
    }
}
