import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {QuotationFlexibility} from "@core/shared/models/quotation/quotation-flexibility";

@Injectable({
    providedIn: 'root'
})
export class QuotationFlexibilityService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getItemsAPI(params?: string[]): Observable<QuotationFlexibility[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/quotations/flexibilities`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<QuotationFlexibility[]>(url, {
            headers: headers
        });
    }
}
