<div class="display_options">
    <div class="row marges">

        <!-- Affichage des informations dans le calendrier -->

        <div class="col y_items_start">
            <div>
                <span class="display_title">{{ 'offer.date.stock.filter.title.value' | translate }}</span>
                <div class="calendar_switch">

                    <ng-container *ngIf="hasAccessOnlineSale">

                        <div class="switch">
                            <app-core-toggle-boolean-filter [builder]="filterBuilder" [key]="'totalAllocatedStock'" [defaultValue]="true"></app-core-toggle-boolean-filter>
                            <div class="switch_label">
                                <img src="assets/images/total-stock-icon.svg" alt="switch">
                                <span>{{ 'offer.date.stock.total.value' | translate }}</span>
                            </div>
                        </div>

                        <div class="switch">
                            <app-core-toggle-boolean-filter [builder]="filterBuilder" [key]="'totalRemainingStock'" [defaultValue]="true"></app-core-toggle-boolean-filter>
                            <div class="switch_label">
                                <img src="assets/images/remaining-stock-icon.svg" alt="switch">
                                <span>{{ 'offer.date.stock.remaining.value' | translate }}</span>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="isSelectedOfferPricePublic('adult')">

                        <div class="switch">
                            <app-core-toggle-boolean-filter [builder]="filterBuilder" [key]="'adultPrice'" [defaultValue]="true"></app-core-toggle-boolean-filter>
                            <div class="switch_label">
                                <img src="assets/images/adult-icon.svg" alt="switch">
                                <span>{{ 'offer.date.price.adult.value' | translate }}</span>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="isSelectedOfferPricePublic('child')">

                        <div class="switch">
                            <app-core-toggle-boolean-filter [builder]="filterBuilder" [key]="'childPrice'" [defaultValue]="true"></app-core-toggle-boolean-filter>
                            <div class="switch_label">
                                <img src="assets/images/child-icon.svg" alt="switch">
                                <span>{{ 'offer.date.price.child.value' | translate }}</span>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="hasAccessOnlineSale">

                        <div class="switch">
                            <app-core-toggle-boolean-filter [builder]="filterBuilder" [key]="'releaseDate'" [defaultValue]="true"></app-core-toggle-boolean-filter>
                            <div class="switch_label">
                                <img src="assets/images/release-date-icon.svg" alt="switch">
                                <span>{{ 'offer.date.releaseDate.value' | translate }}</span>
                            </div>
                        </div>

                    </ng-container>
                </div>
            </div>
        </div>

        <!-- Affichage des tarifs HT / TTC -->

        <div class="col_content y_items_start price-type">

            <div>
                <span class="display_title">{{ 'offer.date.stock.filter.price.title.value' | translate }}</span>
                <mat-radio-group>
                    <mat-radio-button ngDefaultControl value="TTC" [(ngModel)]="priceType" [checked]="priceType === 'TTC'" (ngModelChange)="refreshCalendar(false)">{{ 'price.ttc.value' | translate }}</mat-radio-button>
                    <mat-radio-button ngDefaultControl value="HT" [(ngModel)]="priceType" [checked]="priceType === 'HT'" (ngModelChange)="refreshCalendar(false)">{{ 'price.ht.value' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>

        </div>

        <ng-content select="[stopSale]"></ng-content>

    </div>
</div>


<!-- Gestion de l'affichage du calendrier (mois / année) -->

<div class="toggle_month">
    <button type="button" [ngClass]="getNumberMonthsDisplayedElementClasses(1)" (click)="setNumberMonthsDisplayed(1)">{{ 'calendar.view.oneMonth.value' | translate }}</button>
    <button type="button" [ngClass]="getNumberMonthsDisplayedElementClasses(2)" (click)="setNumberMonthsDisplayed(2)">{{ 'calendar.view.twoMonths.value' | translate }}</button>
    <button type="button" [ngClass]="getNumberMonthsDisplayedElementClasses(12)" (click)="setNumberMonthsDisplayed(12)">{{ 'calendar.view.oneYear.value' | translate }}</button>
</div>

<div #calendar></div>
