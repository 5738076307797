import {Component, Input, OnInit} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {OfferAttributeOffer} from "@core/shared/models/offer-attribute-offer";
import {TranslationService} from "@core/shared/services/translation.service";
import {parsePrice} from "@core/shared/utils/price";

@Component({
    selector: 'app-core-offer-item-detail',
    templateUrl: './offer-item-detail.component.html',
    styleUrls: ['./offer-item-detail.component.scss']
})
export class OfferItemDetailComponent implements OnInit {

    @Input() item: Offer;

    public locale: string;

    public user: User;

    constructor(
        private _translateService: TranslateService,
        private _userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this.locale = this._translateService.currentLang;

        if(!this.item.translations.length){

            this.locale = this.item.translations[0].locale;
        }
    }

    public getAttributesByType(type: OfferAttributeTypeTagType): OfferAttribute[] {

        return this.item.attributes.map((attributeOffer: OfferAttributeOffer): OfferAttribute => {

            return attributeOffer.attribute;

        }).filter((attribute: OfferAttribute): boolean => {

            return attribute.type.tag === type;
        });
    }

    public formatPrice(price: number, symbol: string): string {

        return `${parsePrice(price / 100)} ${symbol}`;
    }
}
