import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Menu} from "../models/menu";
import {PageService} from "../services/page.service";

@Injectable({
    providedIn: 'root'
})
export class PageItemResolver  {

    constructor(
        private _pageService: PageService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._pageService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
