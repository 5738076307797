import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Moment} from "moment";
import {DATE_FORMAT} from '@app/data';
import {Day, DAYS} from "@core/shared/models/day";
import {OfferPeriodService} from "@core/shared/services/offer/offer-period.service";
import {Offer, OfferStockType} from "@core/shared/models/offer";
import {TranslateService} from "@ngx-translate/core";
import {ConfigurationField, ConfigurationFieldIdentifier} from "@core/shared/models/offer/offer-date/offer-date-configuration";
import {UserService} from "@core/shared/services/user.service";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {OfferPricePublicType} from "@core/shared/models/offer/offer-price-public";
import {parsePrice} from "@core/shared/utils/price";
import {User} from "@core/shared/models/user";
import {Access} from "@core/shared/models/access";
import {OfferPeriodPOST} from "@core/shared/models/offer/offer-period";
import {OfferDateStock, OfferDateStockType} from "@core/shared/models/offer/offer-date/offer-date-stock";

@Component({
    selector: 'app-core-offer-period-create',
    templateUrl: './offer-period-create.component.html',
    styleUrls: ['./offer-period-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferPeriodCreateComponent implements OnInit {

    @Input() offer: Offer;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @Output() periodCreated: EventEmitter<void> = new EventEmitter<void>();

    public configurationFields: {[p in ConfigurationFieldIdentifier]: ConfigurationField} = {
        stock: null,
        price: null,
        releaseDate: null
    };

    public priceCalculationAutomatic: boolean = false;

    public priceTypeReference: 'HT' | 'TTC' = 'HT';

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _offerPeriodService: OfferPeriodService,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this._initForm();

        this._initConfigurationFields();

        this._initEvents();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]],
            excludedDays: [[]],
            isClosed: [false, [Validators.required]],
            stock: this._formBuilder.group({
                unlimited: [false, [Validators.required]],
                stocks: new UntypedFormArray([])
            }),
            price: this._formBuilder.group({
                adultPriceHT: [null],
                adultPriceTTC: [null],
                childPriceHT: [null],
                childPriceTTC: [null]
            }),
            releaseDate: this._formBuilder.group({
                value: [null]
            })
        });

        this.formService.submitCallback = (): void => {

            const data: OfferPeriodPOST = Object.assign({...this.form.value}, {
                dateStart: (this.form.get('dateStart').value as Moment).format(DATE_FORMAT),
                dateEnd: (this.form.get('dateEnd').value as Moment).format(DATE_FORMAT),
                stock: Object.assign({...this.stockForm.value}, {
                    enable: this.configurationFields.stock.toProcess,
                    stocks: this.configurationFields.stock.toProcess ? (this.stocksForm.value as []).map((data: { type: OfferDateStockType, commonAllocatedStock: string, adultAllocatedStock: string, childAllocatedStock: string }) : Partial<OfferDateStock> => {

                        return Object.assign({...data}, {
                            commonAllocatedStock: this.isStockManagementType('common') ? parseInt(data.commonAllocatedStock) : null,
                            adultAllocatedStock: this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('adult') ? parseInt(data.adultAllocatedStock) : null,
                            childAllocatedStock: this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('child') ? parseInt(data.childAllocatedStock) : null
                        });
                    }): []
                }),
                price: Object.assign({...this.priceForm.value}, {
                    enable: this.configurationFields.price.toProcess,
                    adultPriceHT: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('adult') && this.offer.vatPercent ? parseInt((this.priceForm.get('adultPriceHT').value * 100).toFixed()) : null,
                    adultPriceTTC: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('adult') ? parseInt((this.priceForm.get('adultPriceTTC').value * 100).toFixed()) : null,
                    childPriceHT: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('child') && this.offer.vatPercent ? parseInt((this.priceForm.get('childPriceHT').value * 100).toFixed()) : null,
                    childPriceTTC: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('child') ? parseInt((this.priceForm.get('childPriceTTC').value * 100).toFixed()) : null
                }),
                releaseDate: Object.assign({...this.releaseDateForm.value}, {
                    enable: this.configurationFields.releaseDate.toProcess,
                    value: this.configurationFields.releaseDate.toProcess ? parseInt(this.releaseDateForm.get('value').value) : null
                })
            } as OfferPeriodPOST);

            this._offerPeriodService.createItemAPI(this.offer.id, data).subscribe((): void => {

                this.periodCreated.emit();
            });
        };
    }

    private _initConfigurationFields(): void {

        this.configurationFields.stock = {
            allowed: this.hasOnlineSaleAccess && this.offer.onlineSale && this.offer.onlineSale.enable,
            toProcess: false
        };

        this.configurationFields.price = {
            allowed: true,
            toProcess: false
        };

        this.configurationFields.releaseDate = {
            allowed: this.hasOnlineSaleAccess && this.offer.onlineSale && this.offer.onlineSale.enable,
            toProcess: false
        };
    }

    private _initEvents(): void {

        this.form.get('isClosed').valueChanges.subscribe((): void => {

            Object.keys(this.configurationFields).forEach((identifier: ConfigurationFieldIdentifier): void => {

                this.configurationFields[identifier].toProcess = false;

                this.handleConfigurationFieldProcess(identifier);
            });
        });

        this.stockForm.get('unlimited').valueChanges.subscribe((): void => {

            this.handleConfigurationFieldProcess('stock');
        });
    }

    private _handleStockControls(): void {

        if(!this.hasOnlineSaleAccess){

            return;
        }

        this.stocksForm.clearValidators();

        this.stocksForm.clear();

        if(!this.stockForm.get('unlimited').value){

            this.stocksForm.push(this._createStockControl({
                commonAllocatedStock: 0,
                adultAllocatedStock: 0,
                childAllocatedStock: 0
            }));
        }

        this.stocksForm.controls.forEach((control: UntypedFormGroup): void => {

            // Commun

            if(this.configurationFields.stock.toProcess && this.isStockManagementType('common')) {

                control.get('commonAllocatedStock').setValidators([Validators.pattern(/^[0-9]*$/), (control: UntypedFormControl) => {

                    if(!this.stockForm.get('unlimited').value && Number.isNaN(parseInt(control.value))){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]);
            }

            // Adultes

            if(this.configurationFields.stock.toProcess && this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('adult')) {

                control.get('adultAllocatedStock').setValidators([Validators.pattern(/^[0-9]*$/), (control: UntypedFormControl) => {

                    if(!this.stockForm.get('unlimited').value && Number.isNaN(parseInt(control.value))){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]);
            }

            // Enfants

            if(this.configurationFields.stock.toProcess && this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('child')) {

                control.get('childAllocatedStock').setValidators([Validators.pattern(/^[0-9]*$/), (control: UntypedFormControl) => {

                    if(!this.stockForm.get('unlimited').value && Number.isNaN(parseInt(control.value))){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]);
            }
        });

        this.stocksForm.updateValueAndValidity();
    }

    private _handlePriceControls(): void {

        // Tarifs adultes

        this.priceForm.get('adultPriceHT').clearValidators();

        this.priceForm.get('adultPriceTTC').clearValidators();

        this.priceForm.get('adultPriceHT').patchValue(null);

        this.priceForm.get('adultPriceTTC').patchValue(null);

        if(this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('adult')){

            // Tarif HT

            if(this.offer.vatPercent){

                this.priceForm.get('adultPriceHT').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
            }

            // Tarif TTC

            this.priceForm.get('adultPriceTTC').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
        }

        this.priceForm.get('adultPriceHT').updateValueAndValidity();

        this.priceForm.get('adultPriceTTC').updateValueAndValidity();

        // Tarifs enfants

        this.priceForm.get('childPriceHT').clearValidators();

        this.priceForm.get('childPriceTTC').clearValidators();

        this.priceForm.get('childPriceHT').patchValue(null);

        this.priceForm.get('childPriceTTC').patchValue(null);

        if(this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('child')){

            // Tarif HT

            if(this.offer.vatPercent){

                this.priceForm.get('childPriceHT').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
            }

            // Tarif TTC

            this.priceForm.get('childPriceTTC').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
        }

        this.priceForm.get('childPriceHT').updateValueAndValidity();

        this.priceForm.get('childPriceTTC').updateValueAndValidity();
    }

    private _handleReleaseDateControl(): void {

        if(!this.hasOnlineSaleAccess){

            return;
        }

        this.releaseDateForm.get('value').clearValidators();

        this.releaseDateForm.get('value').patchValue(null);

        if(this.configurationFields.releaseDate.toProcess) {

            this.releaseDateForm.get('value').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
        }

        this.releaseDateForm.get('value').updateValueAndValidity();
    }

    private _createStockControl(data: Partial<OfferDateStock>): UntypedFormGroup {

        return this._formBuilder.group({
            type: ['public' as OfferDateStockType, [Validators.required]],
            commonAllocatedStock: [data.commonAllocatedStock],
            adultAllocatedStock: [data.adultAllocatedStock],
            childAllocatedStock: [data.childAllocatedStock]
        });
    }

    public handleExcludedDay(event: any): void{

        const control: UntypedFormControl = this.form.get('excludedDays') as UntypedFormControl;

        const controlValue: number[] = control.value;

        const value: number = parseInt(event.target.value);

        if(event.target.checked){

            controlValue.push(value);
        }
        else{

            const index: number = controlValue.findIndex((i: number): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue.sort());
    }

    public isStockManagementType(value: OfferStockType): boolean {

        return this.offer.onlineSale.stockManagementType === value;
    }

    public handleConfigurationFieldProcess(identifier: ConfigurationFieldIdentifier): void {

        if(!this.configurationFields[identifier].allowed){

            return;
        }

        switch (identifier){

            case 'stock':

                this._handleStockControls();

                break;

            case 'price':

                this._handlePriceControls();

                break;

            case 'releaseDate':

                this._handleReleaseDateControl();

                break;
        }
    }

    public isSelectedOfferPricePublic(type: OfferPricePublicType): boolean {

        return this.offer.publics.includes(type);
    }

    public calculatePrices(): void {

        const TVA: number = this.offer.vatPercent * 100;

        if(this.priceTypeReference === 'HT'){

            if(this.isSelectedOfferPricePublic('adult')){

                const controlReference: AbstractControl = this.priceForm.get('adultPriceHT');

                const controlToCalculate: AbstractControl = this.priceForm.get('adultPriceTTC');

                const HT: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(HT + (HT * TVA / 100), 2, '', '.') : null);
            }

            if(this.isSelectedOfferPricePublic('child')){

                const controlReference: AbstractControl = this.priceForm.get('childPriceHT');

                const controlToCalculate: AbstractControl = this.priceForm.get('childPriceTTC');

                const HT: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(HT + (HT * TVA / 100), 2, '', '.') : null);
            }
        }

        if(this.priceTypeReference === 'TTC'){

            if(this.isSelectedOfferPricePublic('adult')){

                const controlReference: AbstractControl = this.priceForm.get('adultPriceTTC');

                const controlToCalculate: AbstractControl = this.priceForm.get('adultPriceHT');

                const TTC: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(((100 * TTC) / (100 + TVA)), 2, '', '.') : null);
            }

            if(this.isSelectedOfferPricePublic('child')){

                const controlReference: AbstractControl = this.priceForm.get('childPriceTTC');

                const controlToCalculate: AbstractControl = this.priceForm.get('childPriceHT');

                const TTC: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(((100 * TTC) / (100 + TVA)), 2, '', '.') : null);
            }
        }
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get stockForm(): UntypedFormGroup {

        return this.form.get('stock') as UntypedFormGroup;
    }

    get priceForm(): UntypedFormGroup {

        return this.form.get('price') as UntypedFormGroup;
    }

    get releaseDateForm(): UntypedFormGroup {

        return this.form.get('releaseDate') as UntypedFormGroup;
    }

    get stocksForm(): UntypedFormArray {

        return this.stockForm.get('stocks') as UntypedFormArray;
    }

    get days(): Day[] {

        return DAYS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isPeriodOpen(): boolean {

        return !this.form.get('isClosed').value as boolean;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get hasOnlineSaleAccess(): boolean {

        return this.currentUser.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }
}
