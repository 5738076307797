<ng-container [formGroup]="formGroup">
    <div #reference>
        <ngx-intl-tel-input
            searchCountryPlaceholder="{{'country.search.value' | translate}}"
            formControlName="{{controlName}}"
            [customPlaceholder]="customPlaceholder"
            [maxLength]="maxLength"
            [cssClass]="cssClass"
            [preferredCountries]="preferredCountries"
            [searchCountryField]="searchCountryField"
            [selectedCountryISO]="selectedCountryISO"
            [separateDialCode]="separateDialCode"
            [numberFormat]="numberFormat"
            [phoneValidation]="phoneValidation"
            [enableAutoCountrySelect]="enableAutoCountrySelect"
            [enablePlaceholder]="enablePlaceholder"
            [searchCountryFlag]="searchCountryFlag"
            [selectFirstCountry]="selectFirstCountry"
        >
        </ngx-intl-tel-input>
    </div>
</ng-container>
