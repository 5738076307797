import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pagination} from '../../models/pagination';
import {OfferDuration} from '../../models/offer/offer-duration';

@Injectable({
    providedIn: 'root'
})
export class OfferDurationService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(params?: string[]): Observable<OfferDuration[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/offerDurations`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<OfferDuration[]>(url, {
            headers: headers
        });

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<OfferDuration>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/offerDurations`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<OfferDuration>>(url, {
            headers: headers
        });

    }
}
