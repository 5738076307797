import {StatusType} from "@core/shared/models/status-type";

export const QUOTATION_STATUS: StatusType[] =  [
    {
        id: 'in-progress',
        label: 'status.in-progress.value'
    },
    {
        id: 'finished',
        label: 'status.finished.value'
    },
    {
        id: 'cancelled',
        label: 'status.cancelled.value'
    }
];
