<ng-container *ngIf="bookingSummaryService.item">

    <!-- Titre -->

    <div class="title ck-content">

        <p class="titre_h4">{{ translationService.getFallbackTranslation(offer.translations).name }}</p>

    </div>

    <!-- Visuel -->

    <ng-container *ngIf="translationService.getFallbackTranslation(offer.translations).pictures.length">

        <div class="visuel">

            <img [src]="getOfferVisualSource(offer.id, translationService.getFallbackTranslation(offer.translations).pictures[0].id)" alt="{{translationService.getFallbackTranslation(offer.translations).name}}"/>

        </div>

    </ng-container>

    <div class="infos">

        <!-- Description -->

        <div class="teaser">

            <p [innerHTML]="translationService.getFallbackTranslation(offer.translations).teaser | safeHtml"></p>

        </div>

        <ng-container *ngIf="composition">

            <!-- Nombre de personne -->

            <div class="row">

                <div class="col_12">

                    <p class="label">{{ 'offer.booking.composition.value'|translate }}</p>

                </div>

                <div class="col_12 x_end nbPerson">

                    <ng-container *ngIf="!!composition.nbAdult && (composition.nbAdult > 0)">

                        <span>{{ (composition.nbAdult > 1 ? 'offer.booking.summary.adult.plural.value' : 'offer.booking.summary.adult.value')|translate:{ count : composition.nbAdult } }}</span>

                    </ng-container>

                    <ng-container *ngIf="!!composition.nbChild && (composition.nbChild > 0)">

                        <span>{{ (composition.nbChild > 1 ? 'offer.booking.summary.child.plural.value' : 'offer.booking.summary.child.value')|translate:{ count : composition.nbChild } }}</span>

                    </ng-container>
                </div>
            </div>

            <!-- Dates de naissance -->

            <ng-container *ngIf="!!composition.childInformations && composition.childInformations.length">

                <div class="row">

                    <div class="col_12">

                        <p class="label">{{ 'offer.booking.children.birthdate.value'|translate }}</p>

                    </div>

                    <div class="col_12 nbPerson">

                        <div class="row" *ngFor="let childInformation of composition.childInformations; let index = index;">

                            <div class="col_12 x_end">

                                <span>{{ 'offer.booking.child.index.value'|translate:{index: index + 1 } }} : <strong> {{ childInformation.birthDate | date:'dd/MM/yyyy' }}</strong></span>

                            </div>

                        </div>

                    </div>

                </div>

            </ng-container>

            <!-- Dates sélectionnées -->

            <div class="row">

                <div class="col_12">

                    <p class="label">{{ ((offer.duration.value > 1) ? 'offer.booking.dates.selected.value' : 'offer.booking.date.selected.value')|translate }}</p>

                </div>

                <div class="col_12 x_end nbPerson">

                    <ng-container *ngIf="(offer.duration.value > 1)">

                        <span>{{ 'offer.booking.dates.value'|translate:{dateStart : composition.dateStart | date:'dd/MM/yyyy', dateEnd : composition.dateEnd | date:'dd/MM/yyyy'} }}</span>

                    </ng-container>

                    <ng-container *ngIf="(offer.duration.value <= 1)">

                        <span>{{ 'offer.booking.date.value'|translate:{ date : composition.dateStart | date:'dd/MM/yyyy' } }}</span>

                    </ng-container>

                </div>

            </div>

            <!-- Durée du séjour -->

            <div class="row">

                <div class="col_12">

                    <p class="label">{{ 'offer.booking.stay.duration.value'|translate }}</p>

                </div>

                <div class="col_12 x_end nbPerson">

                    <span>{{ ((offer.duration.value > 1) ? 'offer.booking.duration.day.plural.value' : 'offer.booking.duration.day.value')|translate:{count: offer.duration.value} }}</span>

                </div>

            </div>

            <!-- Tarif -->

            <div class="row">

                <div class="col_12">

                    <p class="label">{{ 'offer.booking.price.value'|translate }}</p>

                </div>

                <!-- Vente en ligne / Demande de réservation -->

                <ng-container *ngIf="bookingSummaryService.item.type === 'booking'">

                    <!-- Tarif initial -->

                    <ng-container *ngIf="(composition.promotionPriceTTC < composition.priceTTC);">

                        <div class="col_12 x_end nbPerson">
                            <span class="value old_price">{{ formatPrice(composition.priceTTC, offer.currency.symbol) }} {{ 'tax.included.value' | translate }}</span>
                            <span class="value old_price" *ngIf="composition.priceHT">({{ formatPrice(composition.priceHT, offer.currency.symbol) }} {{ 'tax.excluded.value' | translate }})</span>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="!(composition.promotionPriceTTC < composition.priceTTC);">

                        <div class="col_12 x_end nbPerson">
                            <span class="value">{{ formatPrice(composition.priceTTC, offer.currency.symbol) }} {{ 'tax.included.value' | translate }}</span>
                            <span class="value" *ngIf="composition.priceHT">({{ formatPrice(composition.priceHT, offer.currency.symbol) }} {{ 'tax.excluded.value' | translate }})</span>
                        </div>

                    </ng-container>

                </ng-container>

                <!-- Demande de devis -->

                <ng-container *ngIf="bookingSummaryService.item.type === 'request'">

                    <div class="col_12 x_end nbPerson">

                        <span>{{ 'offer.booking.request.quotation.value' | translate }}</span>

                    </div>

                </ng-container>

            </div>


            <!-- Tarif promotionnel -->

            <ng-container *ngIf="(bookingSummaryService.item.type === 'booking') && (composition.promotionPriceTTC < composition.priceTTC)">

                <div class="row">

                    <div class="col_12">

                        <p class="label">{{ 'offer.booking.promotion.value'|translate }}</p>

                    </div>

                    <div class="col_12 x_end nbPerson">
                        <span class="value">{{ formatPrice(composition.promotionPriceTTC, offer.currency.symbol) }} {{ 'tax.included.value' | translate }}</span>
                        <span class="value" *ngIf="composition.promotionPriceHT">({{ formatPrice(composition.promotionPriceHT, offer.currency.symbol) }} {{ 'tax.excluded.value' | translate }})</span>
                    </div>

                </div>

            </ng-container>

            <ng-container>

                <!-- Options permanentes -->

                <ng-container *ngIf="composition.permanentOptions.length">

                    <div class="row">

                        <div class="col_12">

                            <p class="label">{{ 'offer.booking.permanentOptions.value' | translate }}</p>

                        </div>

                        <ng-container *ngFor="let permanentOption of composition.permanentOptions">

                            <div class="col_12 x_end nbPerson">

                                <span>
                                    {{ translationService.getFallbackTranslation(permanentOption.permanentOption.translations).title }}
                                </span>

                                <span class="value">

                                    <ng-container *ngIf="!!permanentOption.priceTTC">
                                        {{ formatPrice(permanentOption.priceTTC, offer.currency.symbol) }} {{ 'tax.included.value' | translate }}
                                    </ng-container>

                                    <ng-container *ngIf="permanentOption.onRequest">
                                        {{ 'offer.booking.permanentOptions.onRequest' | translate }}
                                    </ng-container>

                                </span>

                                <ng-container *ngIf="!!permanentOption.priceHT">

                                    <span class="value">({{ formatPrice(permanentOption.priceHT, offer.currency.symbol) }} {{ 'tax.excluded.value' | translate }})</span>

                                </ng-container>

                            </div>

                        </ng-container>

                    </div>

                </ng-container>

            </ng-container>

            <ng-container *ngIf="(bookingSummaryService.item.type === 'booking') && ((composition.promotionPriceTTC < composition.priceTTC) || (composition.options.length) || (composition.permanentOptions.length))">

                <!-- Tarif total -->

                <div class="row">

                    <div class="col_12">

                        <p class="label">{{ 'offer.booking.summary.total.value'|translate }}</p>

                    </div>

                    <div class="col_12 x_end nbPerson">

                        <span class="value price">
                            {{ formatPrice(composition.totalPriceTTC, offer.currency.symbol) }} {{ 'tax.included.value' | translate }}
                        </span>

                        <ng-container *ngIf="!!composition.totalPriceHT && (composition.totalPriceHT > 0)">

                            <span class="value price">
                                ({{ formatPrice(composition.totalPriceHT, offer.currency.symbol) }} {{ 'tax.excluded.value' | translate }})
                            </span>

                        </ng-container>

                    </div>
                </div>

            </ng-container>

        </ng-container>

    </div>

</ng-container>
