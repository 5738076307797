import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Booking} from "@core/shared/models/booking";

@Injectable({
    providedIn: 'root'
})
export class BookingSummaryService {

    private _item$: BehaviorSubject<Booking> = new BehaviorSubject(null);

    set item(value: Booking) {

        this._item$.next(value);
    }

    get item(): Booking {

        return this._item$.getValue();
    }
}
