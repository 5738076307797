<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.option.add.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'offer.option.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit" *ngIf="isUniquePrice">
                    {{ 'form.submit.action.value' | translate }}
                </button>

                <!-- Enregistrer et sélectionner les périods -->

                <button class="btn_cta btn_little" type="submit" *ngIf="!isUniquePrice">
                    {{ 'offer.option.submitAndSelectPeriods.value' | translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'offer.option.data.generic.value' | translate">
            <ng-container *ngTemplateOutlet="genericData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'offer.option.data.pricing.value' | translate">
            <ng-container *ngTemplateOutlet="pricingData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'offer.option.data.presential.value' | translate">
            <ng-container *ngTemplateOutlet="presentialData"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<!-- Données génériques -->

<ng-template #genericData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <!-- Langues -->

            <div class="row marges ligne_form">

                <div class="col_12">
                    <div class="form-group">
                        <mat-select formControlName="locales" multiple>
                            <mat-option *ngFor="let locale of locales$ | async;" [value]="locale.id">{{ locale.label | translate }}</mat-option>
                        </mat-select>
                        <mat-label class="required">{{ 'offer.option.locales.value' | translate }}</mat-label>
                        <mat-error *ngIf="form.get('locales').invalid && (form.get('locales').dirty || form.get('locales').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('locales').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form" *ngIf="translationBuilder.itemsControl.length">

                <div class="col_12">
                    <div class="mat-tab-translations">
                        <ng-container formArrayName="translations">
                            <mat-tab-group [@.disabled]="true">
                                <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">
                                    <mat-tab label="{{translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">
                                        <ng-container [formGroupName]="translationBuilder.indexAsString(it)">
                                            <div class="row marges ligne_form">
                                                <div class="col_12 col_md_6">

                                                    <!-- Nom -->

                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                                                        <label for="name">{{ 'offer.option.name.value' | translate }}</label>
                                                        <mat-error *ngIf="this.getTranslation(it).get('name').errors && (this.getTranslation(it).get('name').dirty ||this.getTranslation(it).get('name').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="this.getTranslation(it).get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col_12 col_md_6">

                                                    <!-- Titre -->

                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="title" id="title" required="required">
                                                        <label for="title">{{ 'offer.option.title.value' | translate }}</label>
                                                        <mat-error *ngIf="this.getTranslation(it).get('title').errors && (this.getTranslation(it).get('title').dirty ||this.getTranslation(it).get('name').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="this.getTranslation(it).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row marges ligne_form">
                                                <div class="col_12">

                                                    <!-- Description -->

                                                    <div class="form-group">
                                                        <label>{{ 'offer.option.description.value' | translate }}</label>
                                                        <app-ckeditor [form]="translationBuilder.getItemControl(it)" [config]="descriptionEditorConfig"></app-ckeditor>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>
                                    </mat-tab>
                                </div>
                            </mat-tab-group>

                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">

                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'offer.option.image.value' | translate }}</label>
                    </div>
                    <br>
                    <div class="add_picture collection">

                        <!-- Visuel -->

                        <div class="ligne_form row marges">
                            <div class="col_content">
                                <div class="form-group">
                                    <form-field-image [config]="imageConfig" [form]="form.get('image')"></form-field-image>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">

                <div class="col_6 y_items_center">

                    <!-- Sélectionnable à la journée -->

                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" class="form-control" id="availableByDay" formControlName="availableByDay">
                            <label for="availableByDay">{{'offer.option.availableByDay.value' | translate}}</label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">

                <div class="col_12">

                    <!-- Commentaire -->

                    <div class="form-group">
                        <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                        <label for="comment">{{'offer.option.comment.value' | translate}}</label>
                    </div>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Données tarifaires -->

<ng-template #pricingData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6">

                    <!-- Sélection de la devise -->

                    <div class="row marges ligne_form">

                        <div class="col_6 y_items_center">
                            <div class="form-group">
                                <select class="form-control" id="currency" formControlName="currency" required="required">
                                    <option value=""></option>
                                    <option *ngFor="let currency of currencies" [value]="currency.id">
                                        {{ translationService.getFallbackTranslation(currency.translations).label }}
                                    </option>
                                </select>
                                <label class="required" for="currency">{{ 'currency.value' | translate }}</label>
                                <mat-error *ngIf="form.get('currency').errors && (form.get('currency').dirty || form.get('currency').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_6 y_items_center">

                            <!-- Mark-up -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="markup" id="markup" appInputTextParseMarkup [control]="form.get('markup')">
                                <label for="markup">{{'markup.value' | translate}}</label>
                                <mat-error *ngIf="form.get('markup').errors && (form.get('markup').dirty || form.get('markup').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('markup').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        <div *ngIf="form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                    </div>

                    <ng-container *ngIf="!!form.get('currency').value">

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <!-- Sélection du public de l'offre -->

                                <div class="form-group">
                                    <label>{{'offer.public.selection.value' | translate}}</label>
                                    <div class="checkbox inline" *ngFor="let offerOptionPricePublic of offerOptionPricePublics">
                                        <input type="checkbox" id="offer-option-price-public-{{offerOptionPricePublic.type}}" [value]="offerOptionPricePublic.type" [checked]="isSelectedOfferOptionPricePublic(offerOptionPricePublic.type)" (change)="handleOfferOptionPricePublic($event)">
                                        <label for="offer-option-price-public-{{offerOptionPricePublic.type}}">{{ offerOptionPricePublic.label | translate }}</label>
                                    </div>
                                    <mat-error *ngIf="form.get('publics').invalid && (form.get('publics').dirty || form.get('publics').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('publics').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">

                            <div class="col_12 col_md_6">

                                <!-- Prix unique -->

                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" class="form-control" id="isUniquePrice" [(ngModel)]="isUniquePrice" [ngModelOptions]="{ standalone: true }" (ngModelChange)="handleUniquePrice()">
                                        <label for="isUniquePrice">{{'offer.option.uniquePrice.value' | translate}}</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <ng-container *ngIf="isUniquePrice" formGroupName="uniquePrice">

                            <div class="row marges ligne_form">

                                <div class="col_12 col_md_6">

                                    <!-- Tarif HT -->

                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="valueHT" id="valueHT" [control]="uniquePriceControl.get('valueHT')" autocomplete="off" appInputTextParsePrice>
                                        <label for="valueHT">{{'offer.option.uniquePrice.valueHT.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}</label>
                                        <mat-error *ngIf="uniquePriceControl.get('valueHT').errors && (uniquePriceControl.get('valueHT').dirty || uniquePriceControl.get('valueHT').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="uniquePriceControl.get('valueHT').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="uniquePriceControl.get('valueHT').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col_12 col_md_6">

                                    <!-- Tarif TTC -->

                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="valueTTC" id="valueTTC" [control]="uniquePriceControl.get('valueTTC')" autocomplete="off" appInputTextParsePrice>
                                        <label for="valueTTC">{{'offer.option.uniquePrice.valueTTC.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}</label>
                                        <mat-error *ngIf="uniquePriceControl.get('valueTTC').errors && (uniquePriceControl.get('valueTTC').dirty || uniquePriceControl.get('valueTTC').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="uniquePriceControl.get('valueTTC').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="uniquePriceControl.get('valueTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>

                                </div>

                            </div>

                        </ng-container>

                        <ng-container *ngIf="!isUniquePrice">

                            <div class="row marges ligne_form">
                                <div class="col_12">

                                    <!-- Calcul automatique des tarifs -->

                                    <ng-container *ngIf="isAllOfferOptionTypesSelected">
                                        <div class="row marges ligne_form">
                                            <div class="col_12">
                                                <div class="form-group">
                                                    <label>{{'offer.price.calculation.automatic.action.value' | translate}}</label>
                                                    <div class="radio">
                                                        <input type="radio" [value]="true" formControlName="priceCalculationAutomatic" class="form-control" id="priceCalculationAutomaticYes">
                                                        <label for="priceCalculationAutomaticYes">{{'form.user.fields.yes.value' | translate}}</label>
                                                    </div>
                                                    <div class="radio">
                                                        <input type="radio" [value]="false" formControlName="priceCalculationAutomatic" class="form-control" id="priceCalculationAutomaticNo">
                                                        <label for="priceCalculationAutomaticNo">{{'form.user.fields.no.value' | translate}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="form.get('priceCalculationAutomatic').value">

                                            <div class="row marges ligne_form">
                                                <div class="col_12">

                                                    <!-- Sélection du type de référence -->

                                                    <div class="form-group">
                                                        <label>{{'offer.price.type.reference.value' | translate}}</label>
                                                        <div class="radio">
                                                            <input type="radio" [value]="'HT'" formControlName="priceTypeReference" class="form-control" id="priceTypeReferenceHT">
                                                            <label for="priceTypeReferenceHT">{{'taxes.excluding.value' | translate}}</label>
                                                        </div>
                                                        <div class="radio">
                                                            <input type="radio" [value]="'TTC'" formControlName="priceTypeReference" class="form-control" id="priceTypeReferenceTTC">
                                                            <label for="priceTypeReferenceTTC">{{'taxes.including.value' | translate}}</label>
                                                        </div>
                                                        <mat-error *ngIf="form.get('priceTypeReference').errors && (form.get('priceTypeReference').dirty || form.get('priceTypeReference').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="form.get('priceTypeReference').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-container *ngIf="form.get('priceTypeReference').value">

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">

                                                        <!-- Sélection du montant de la TVA -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="vatPercent" id="vatPercent">
                                                            <label for="vatPercent">{{'tva.percent.value' | translate}}</label>
                                                            <mat-error *ngIf="form.get('vatPercent').errors && (form.get('vatPercent').dirty || form.get('vatPercent').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="form.get('vatPercent').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>

                                        </ng-container>

                                    </ng-container>

                                    <!-- Tarifs -->

                                    <ng-container *ngIf="form.get('currency').value && pricesInitializedSubject.value">

                                        <!-- Tarifs adultes -->

                                        <ng-container *ngIf="isSelectedOfferOptionPricePublic('adult')">

                                            <!-- Tarifs HT -->

                                            <ng-container *ngIf="isSelectedOfferOptionPriceType('HT')">

                                                <div class="row marges ligne_form">

                                                    <div class="col_12">
                                                        <app-core-offer-price-form [index]="getPriceControlIndexByFilter('adult', 'HT')" [form]="form" [formArrayName]="'prices'" [pricesControl]="pricesControl" [pricePublic]="'adult'" [priceType]="'HT'" [currency]="getCurrencyById(form.get('currency').value).symbol"></app-core-offer-price-form>
                                                    </div>

                                                </div>

                                            </ng-container>

                                            <!-- Tarifs TTC -->

                                            <ng-container *ngIf="isSelectedOfferOptionPriceType('TTC')">

                                                <div class="row marges ligne_form">

                                                    <div class="col_12">
                                                        <app-core-offer-price-form [index]="getPriceControlIndexByFilter('adult', 'TTC')" [form]="form" [formArrayName]="'prices'" [pricesControl]="pricesControl" [pricePublic]="'adult'" [priceType]="'TTC'" [currency]="getCurrencyById(form.get('currency').value).symbol"></app-core-offer-price-form>
                                                    </div>

                                                </div>

                                            </ng-container>

                                        </ng-container>

                                        <!-- Tarifs enfants -->

                                        <ng-container *ngIf="isSelectedOfferOptionPricePublic('child')">

                                            <!-- Tarifs HT -->

                                            <ng-container *ngIf="isSelectedOfferOptionPriceType('HT')">

                                                <div class="row marges ligne_form">

                                                    <div class="col_12">
                                                        <app-core-offer-price-form [index]="getPriceControlIndexByFilter('child', 'HT')" [form]="form" [formArrayName]="'prices'" [pricesControl]="pricesControl" [pricePublic]="'child'" [priceType]="'HT'" [currency]="getCurrencyById(form.get('currency').value).symbol"></app-core-offer-price-form>
                                                    </div>

                                                </div>

                                            </ng-container>

                                            <!-- Tarifs TTC -->

                                            <ng-container *ngIf="isSelectedOfferOptionPriceType('TTC')">

                                                <div class="row marges ligne_form">

                                                    <!-- Haute saison -->

                                                    <div class="col_12">
                                                        <app-core-offer-price-form [index]="getPriceControlIndexByFilter('child', 'TTC')" [form]="form" [formArrayName]="'prices'" [pricesControl]="pricesControl" [pricePublic]="'child'" [priceType]="'TTC'" [currency]="getCurrencyById(form.get('currency').value).symbol"></app-core-offer-price-form>
                                                    </div>

                                                </div>

                                            </ng-container>

                                        </ng-container>

                                        <div class="row marges ligne_form">
                                            <div class="col_12">
                                                <button class="btn_cta btn_little" type="button" (click)="calculatePrices()" *ngIf="form.get('priceCalculationAutomatic').value && form.get('priceTypeReference').value">
                                                    {{ 'offer.price.calculation.automatic.action.value' | translate }}
                                                </button>
                                            </div>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>

                        </ng-container>

                        <!-- Âge enfant -->

                        <ng-container *ngIf="isSelectedOfferOptionPricePublic('child')">
                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="minChildrenAge" id="minChildrenAge">
                                        <label for="minChildrenAge">{{'offer.minChildrenAge.value' | translate}}</label>
                                        <mat-error *ngIf="form.get('minChildrenAge').errors && (form.get('minChildrenAge').dirty || form.get('minChildrenAge').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="form.get('minChildrenAge').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="form.get('minChildrenAge').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="maxChildrenAge" id="maxChildrenAge">
                                        <label for="maxChildrenAge">{{'offer.maxChildrenAge.value' | translate}}</label>
                                        <mat-error *ngIf="form.get('maxChildrenAge').errors && (form.get('maxChildrenAge').dirty || form.get('maxChildrenAge').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="form.get('maxChildrenAge').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="form.get('maxChildrenAge').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>

                </div>

            <ng-container *ngIf="!!form.get('currency').value">

                <div class="col_12 col_md_6">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <!-- Tarifs palier -->

                            <div class="ligne_form collection">

                                <!-- Ajout d'un palier -->

                                <ng-container formArrayName="priceLevels">
                                    <div *ngFor="let _ of priceLevelsControl.controls; index as i" class="price-level form-group removable_item">
                                        <ng-container [formGroupName]="indexAsString(i)">
                                            <div class="row marges ligne_form">
                                                <div class="col_6">
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="min" id="offer-price-level-min-{{ i }}">
                                                        <label for="offer-price-level-min-{{ i }}">{{'offer.price.level.min.value' | translate}}</label>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('min').errors && (getPriceLevelControl(i).get('min').dirty || getPriceLevelControl(i).get('min').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('min').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                <div *ngIf="getPriceLevelControl(i).get('min').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col_6">
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="max" id="offer-price-level-max-{{ i }}">
                                                        <label for="offer-price-level-max-{{ i }}">{{'offer.price.level.max.value' | translate}}</label>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('max').errors && (getPriceLevelControl(i).get('max').dirty || getPriceLevelControl(i).get('max').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('max').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                <div *ngIf="getPriceLevelControl(i).get('max').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row marges ligne_form">
                                                <div class="col_12">
                                                    <div class="form-group">
                                                        <label>{{'offer.price.level.type.value' | translate}}</label>
                                                        <div class="radio" *ngFor="let type of offerOptionPriceLevelTypes">
                                                            <div class="radio">
                                                                <input type="radio" [value]="type.type" formControlName="type" class="form-control" id="priceLevelType-{{ type.type }}-{{ i }}">
                                                                <label for="priceLevelType-{{ type.type }}-{{ i }}">{{ type.label | translate: {currency: getCurrencyById(form.get('currency').value).symbol} }}</label>
                                                            </div>
                                                        </div>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('type').errors && (getPriceLevelControl(i).get('type').dirty || getPriceLevelControl(i).get('type').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row marges ligne_form">
                                                <div class="col_6">
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="value" id="offer-price-level-value-{{ i }}">
                                                        <label for="offer-price-level-value-{{ i }}">{{'offer.price.level.amount.value' | translate}}</label>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('value').errors && (getPriceLevelControl(i).get('value').dirty || getPriceLevelControl(i).get('value').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('value').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                <div *ngIf="getPriceLevelControl(i).get('value').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row marges ligne_form">
                                                <div class="col_12">
                                                    <div class="form-group">
                                                        <label>{{'offer.price.level.increaseArea.value' | translate}}</label>
                                                        <div class="radio" *ngFor="let increaseArea of offerOptionPriceLevelIncreaseAreas">
                                                            <div class="radio">
                                                                <input type="radio" [value]="increaseArea.type" formControlName="priceIncreaseArea" class="form-control" id="priceIncreaseArea-{{ increaseArea.type }}-{{ i }}">
                                                                <label for="priceIncreaseArea-{{ increaseArea.type }}-{{ i }}">{{ increaseArea.label | translate }}</label>
                                                            </div>
                                                        </div>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('priceIncreaseArea').errors && (getPriceLevelControl(i).get('priceIncreaseArea').dirty || getPriceLevelControl(i).get('priceIncreaseArea').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('priceIncreaseArea').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <!-- Suppression du palier -->

                                        <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="removePriceLevel(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>

                                <button class="btn_cta btn_little" type="button" (click)="addPriceLevel()">{{ 'offer.price.level.add.action.value' | translate }}</button>

                            </div>
                        </div>
                    </div>

                </div>

            </ng-container>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Données présentiel -->

<ng-template #presentialData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <div class="row marges ligne_form">
                <div class="col_12">

                    <!-- Présentiel individuel -->

                    <div class="ligne_form">
                        <div class="form-group">
                            <label>{{ 'offer.presential.individual.value' | translate }}</label>
                        </div>
                    </div>
                    <div class="ligne_form">
                        <app-core-offer-option-presential-form [form]="form" [control]="presentialIndividualControl" [DOMIdentifier]="'offer-presential-individual'" [enableMaxField]="true" ></app-core-offer-option-presential-form>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="ligne_form">

                        <!-- Présentiel groupe -->

                        <div class="form-group">
                            <label>{{ 'offer.presential.group.value' | translate }}</label>
                        </div>
                    </div>
                    <div class="ligne_form">
                        <app-core-offer-option-presential-form [form]="form" [control]="presentialGroupControl" [DOMIdentifier]="'offer-presential-group'" [enableMaxField]="false" ></app-core-offer-option-presential-form>
                    </div>
                </div>
            </div>

        </div>

    </ng-container>

</ng-template>
