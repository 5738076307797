<div *ngIf="configInitialized && content">
    <div class="thumb-input">
        <div class="big-label">
            <span class="label">{{ 'element.types.button.template.choice' | translate }}</span>
            <select [(ngModel)]="content.template" (ngModelChange)="setContent(content)" [compareWith]="compareByOptionClass">
                <option [ngValue]="null" disabled selected>--</option>
                <option *ngFor="let template of templates" [ngValue]="template">
                    {{ template.label }}
                </option>
            </select>
        </div>
        <div class="big-label">
            <span class="label">{{ 'element.types.button.title' | translate }}</span>
            <input type="text" [(ngModel)]="content.title" (ngModelChange)="setContent(content)"/>
        </div>
        <div class="big-label" *ngIf="config.enableLink">
            <span class="label">{{ 'element.types.button.link' | translate }}</span>
            <input type="text" [(ngModel)]="content.link" (ngModelChange)="setContent(content)"/>
        </div>
        <div class="no-padding" *ngIf="config.enableLink">
            <label class="input-checkbox">
                <input class="checkbox-target" type="checkbox" [(ngModel)]="content.targetBlank" (ngModelChange)="setContent(content)"/>
                <span class="label">{{ 'element.types.button.targetBlank' | translate }}</span>
            </label>
        </div>
        <div *ngIf="config.help">
            <span class="label help-button">
                <mat-icon>help</mat-icon>
                {{ config.help }}
            </span>
        </div>

        <div *ngIf="content.template" class="big-label">
            <span class="label">{{ 'element.types.button.preview.label' | translate }}</span>
            <br/>
            <a class="button-component btn_cta" [ngClass]="getPreviousButtonClass(content.template)">
                <span>{{content.title}}</span>
            </a>
        </div>
    </div>
</div>
