<div [formGroup]="form" class="form-group">
    <media-image-collection [options]="config.options" [images]="images" [apiUrl]="config.uploadApiUrl" [gallery]="config.gallery" [max]="config.max" (onAdd)="add($event)" (onDelete)="delete($event)" (onPositionChanged)="changePosition($event)"></media-image-collection>
    <p *ngIf="config.help" class="message-help">{{ config.help }}.</p>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid">
            <div *ngIf="form.controls[config.id].errors.invalidMin">{{ 'form.validation.error.image.collection.invalidMin' | translate:{ min: this.config.min, label: this.config.min > 1 ? ('form.image.plural.label' | translate) : ('form.image.label' | translate) } }}</div>
            <div *ngIf="form.controls[config.id].errors.invalidMax">{{ 'form.validation.error.image.collection.invalidMax' | translate:{ max: this.config.max, label: this.config.max > 1 ? ('form.image.plural.label' | translate) : ('form.image.label' | translate) } }}</div>
        </div>
    </mat-error>
</div>
