import {Exclude, Expose, Transform} from "class-transformer";
import {Moment} from "moment";
import * as moment from "moment";
import {DATE_FORMAT} from "../../../../data";
import {PromotionStatusType} from "./promotion-status";

@Exclude()
export class PromotionDate {

    @Expose()
    id: number;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATE_FORMAT).startOf('day') : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATE_FORMAT) : null, {toPlainOnly: true})
    date: Moment;

    @Expose()
    status: PromotionStatusType;
}
