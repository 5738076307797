import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IRow} from "./row";
import {IColumn} from "../column/column";
import {ColumnService} from "../column/column.service";

@Component({
    selector: 'app-row',
    templateUrl: './row.component.html',
    styleUrls: ['./row.component.scss']
})

/**
 * Ligne de la page pour une langue
 */
export class RowComponent implements OnInit, AfterViewInit {

    @Input() row: IRow;

    @Input() isIncreaseAllowed: boolean;

    @Input() isDecreaseAllowed: boolean;

    @Output() decreasePosition = new EventEmitter();

    @Output() increasePosition = new EventEmitter();

    @Output() delete = new EventEmitter();

    /**
     * @param _element
     * @param _columnService
     */
    constructor(public _element: ElementRef, private _columnService: ColumnService) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {

    }

    ngAfterViewInit(): void {

        // Si la ligne vient d'être créee, on scroll automatique sur sa position

        if(!this.row.id){
            this._element.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    /**
     * Décrémentation de la position d'une colonne
     *
     * Sur l'interface le type ainsi que contenu de la colonne est donc décalé vers la gauche
     */
    decreaseColumnPosition(column: IColumn): void{

        this._columnService.decreasePositionColumn(this.row, column);
    }

    /**
     * Incrémentation de la position d'une colonne
     *
     * Sur l'interface le type ainsi que contenu de la colonne est donc décalé vers la droite
     */
    increaseColumnPosition(column: IColumn): void{

        this._columnService.increasePositionColumn(this.row, column);
    }

    /**
     * Détermine si une colonne peut être décalée vers la gauche
     *
     * @returns {boolean}
     */
    isDecreaseColumnAllowed(column: IColumn): boolean{

        return this._columnService.isDecreaseColumnAllowed(this.row, column);
    }

    /**
     * Détermine si une colonne peut être décalée vers la droite
     *
     * @returns {boolean}
     */
    isIncreaseColumnAllowed(column: IColumn): boolean{

        return this._columnService.isIncreaseColumnAllowed(this.row, column);
    }
}
