import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {OfferCreatorSocietyAnnotation} from "@core/shared/models/offer-creator/offer-creator-society/offer-creator-society-annotation";

@Injectable({
    providedIn: 'root'
})
export class OfferCreatorSocietyAnnotationService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public createItemAPI(offerCreatorId: number, data: object): Observable<OfferCreatorSocietyAnnotation> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferCreatorSocietyAnnotation>(`${this._apiService.getApiUrl(false, true)}/offerCreatorAnnotation/${offerCreatorId}`, data, {
            headers: headers
        });
    }

    public updateItemAPI(offerCreatorId: number, data: object): Observable<OfferCreatorSocietyAnnotation> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<OfferCreatorSocietyAnnotation>(`${this._apiService.getApiUrl(false, true)}/offerCreatorAnnotation/${offerCreatorId}`, data,{
            headers: headers
        });
    }

    public getItemAPI(offerCreatorId: number): Observable<OfferCreatorSocietyAnnotation> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offerCreatorAnnotation/${offerCreatorId}`;

        return this._httpClient.get<OfferCreatorSocietyAnnotation>(url,{
            headers: headers
        });
    }
}
