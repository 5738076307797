import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {AbstractControl, UntypedFormArray, FormGroup} from "@angular/forms";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DragAndDropService {

    constructor() {
    }

    public dragAndDrop(control: UntypedFormArray, event: CdkDragDrop<string[]>): void {

        const from: number = this._clampNumber( event.previousIndex, control.length - 1);
        const to: number = this._clampNumber(event.currentIndex, control.length - 1);

        if (from === to){
            return;
        }

        const previousControlValue = Object.assign({}, control.at(from).value);
        const currentControlValue = Object.assign({}, control.at(to).value);

        // Récupération des positions de chaque élément
        const previousPosition = previousControlValue.position;
        const currentPosition = currentControlValue.position;

        // Echange des positions
        previousControlValue.position = currentPosition;
        currentControlValue.position = previousPosition;

        // Patch des controls
        control.at(from).patchValue(currentControlValue);
        control.at(to).patchValue(previousControlValue);
    }

    private _clampNumber(value: number, max: number): number {
        return  Math.max(0, Math.min(max, value));
    }

}
