import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from "../services/loader.service";
import {finalize} from "rxjs/operators";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(
        private _loaderService: LoaderService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        this._loaderService.show();

        return next.handle(request).pipe(
            finalize(() => {

                this._loaderService.hide()
            })
        );
    }
}
