import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from "@core/core.module";
import {MaterialModule} from "@material/material.module";
import {Router} from "@angular/router";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DEFAULT_LOCALE} from "@app/data";
import {APP_BASE_HREF} from "@angular/common";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import {registerLocaleData} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeIt from "@angular/common/locales/it";
import localePt from "@angular/common/locales/pt";
import localeDe from "@angular/common/locales/de";
import localeNl from "@angular/common/locales/nl";
import * as moment from "moment";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MaterialModule,
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpBackend]
            }
        })
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: () => {

                const locales: string[] = LOCALE_ITEMS.map((item: LocaleItem): string => {

                    return item.id;
                })

                const currentLocale: string = locales.find((locale: string): boolean => {

                    return (window.location.href).includes(`${window.location.origin}/${locale}`);

                }) || DEFAULT_LOCALE;

                return `/${currentLocale}/`;
            }
        },
        {
            provide: LOCALE_ID,
            useFactory: () => {

                const locales: string[] = LOCALE_ITEMS.map((item: LocaleItem): string => {

                    return item.id;
                })

                const currentLocale: string = locales.find((locale: string): boolean => {

                    return (window.location.href).includes(`${window.location.origin}/${locale}`);

                }) || DEFAULT_LOCALE;

                switch (currentLocale) {

                    case 'fr':

                        registerLocaleData(localeFr);

                        break;

                    case 'en':

                        registerLocaleData(localeEn);

                        break;

                    case 'es':

                        registerLocaleData(localeEs);

                        break;

                    case 'it':

                        registerLocaleData(localeIt);

                        break;

                    case 'pt':

                        registerLocaleData(localePt);

                        break;

                    case 'de':

                        registerLocaleData(localeDe);

                        break;

                    case 'nl':

                        registerLocaleData(localeNl);

                        break;

                    default:

                        registerLocaleData(localeFr);
                }

                return currentLocale;
            }
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [
                MAT_DATE_LOCALE,
                MAT_MOMENT_DATE_ADAPTER_OPTIONS
            ]
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(private _router: Router){

        this._router.onSameUrlNavigation = "reload";

        this._router.routeReuseStrategy.shouldReuseRoute = () : boolean => {

            return false;
        };

        const locales: string[] = LOCALE_ITEMS.map((item: LocaleItem): string => {

            return item.id;
        })

        const currentLocale: string = locales.find((locale: string): boolean => {

            return (window.location.href).includes(`${window.location.origin}/${locale}`);

        }) || DEFAULT_LOCALE;

        moment.locale(currentLocale);
    }
}

export function createTranslateLoader(http: HttpBackend) {

    return new MultiTranslateHttpLoader(new HttpClient(http), [
        {prefix: "./app/lib/cms/assets/i18n/", suffix: `.json`},
        {prefix: "./app/lib/confirm-dialog/assets/i18n/", suffix: `.json`},
        {prefix: "./app/lib/media/assets/i18n/", suffix: `.json`},
        {prefix: "./app/lib/form/assets/i18n/", suffix: `.json`},
        {prefix: "./assets/i18n/", suffix: `.json`}
    ]);
}
