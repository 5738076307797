<h1 mat-dialog-title>{{ 'markup.choice.value' | translate }}</h1>
<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <div class="form-group">
            <input type="text" (input)="setMarkup($event)" value="" placeholder=" " class="form-control" formControlName="markup" id="markup" appInputTextParseMarkup [control]="form.get('markup')">
            <label for="markup">{{'markup.form.value' | translate}}</label>
            <mat-error *ngIf="form.get('markup').errors && (form.get('markup').dirty || form.get('markup').touched)">
                <div class="invalid-feedback">
                    <div *ngIf="form.get('markup').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    <div *ngIf="form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                    <div *ngIf="form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
                </div>
            </mat-error>
            <span class="message-help" *ngIf="typeMarkup === 'catalog'">
                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'markup.catalog.help.value' | translate }}
            </span>
        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="submit()">{{ 'submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
