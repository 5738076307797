import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";

@Component({
    selector: 'app-core-toggle-boolean-filter',
    templateUrl: './toggle-boolean-filter.component.html',
    styleUrls: ['./toggle-boolean-filter.component.scss']
})
export class ToggleBooleanFilterComponent implements OnInit {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() defaultValue: boolean = null;

    @Input() disableAutoSubmit: boolean;

    @Output() valueChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    public value: boolean;

    public filterField: TextFilterField;

    constructor() {
    }

    ngOnInit(): void {

        this.filterField = new TextFilterField(this.key, 'eq');

        this.builder.addField(this.filterField);

        this.value = this.defaultValue;

        this.hydrateValue();
    }

    public hydrateValue(): void {

        this.filterField.value = (this.value === null || this.value === undefined) ? null : +this.value;
    }

    public filter(): void {

        this.hydrateValue();

        this.valueChanges.emit(this.value);

        if(this.disableAutoSubmit){

            return;
        }

        this.builder.filter();
    }

    public reset(): void {

        this.value = this.defaultValue;
    }
}
