import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {PaymentInitialize} from "@core/shared/models/payment-initialize";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {Card} from "@core/shared/models/card";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CardService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public societyItemInitialize(id: number): Observable<PaymentInitialize> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/card/initialize`;

        return this._httpClient.get<PaymentInitialize>(url, {
            headers: headers
        });
    }

    public societyItemValidate(id, data): Observable<any> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/card/validate`;

        return this._httpClient.post<any>(url, data, {
            headers: headers
        });
    }

    public getSocietyItem(id: number): Observable<Card|null> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/card`;

        return this._httpClient.get<Card|null>(url, {
            headers: headers
        }).pipe(
            map((card: Card): (Card|null) => {

                return (Object.keys(card).length > 0) ? card : null;
            })
        );
    }

    public registrationUrlItem(url: string, data): Observable<any> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Accept', 'text/plain');

        return this._httpClient.post(url, data, {
            headers: headers,
            responseType: "text"
        });
    }
}
