import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyService} from "@core/shared/services/society.service";
import {Observable} from "rxjs";
import {ServiceService} from "@core/shared/services/service.service";
import {Society} from "@core/shared/models/society";


@Injectable({
    providedIn: 'root'
})
export class ServiceSocietyItemsResolver  {

    constructor(
        private _serviceService: ServiceService,
        private _societyService: SocietyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {
            this._societyService.getItemSelfAPI().subscribe((society:Society) => {

                this._serviceService.getItemsServiceBySocietyWithoutPagination(society.id).subscribe(resolve, reject)
            },reject)
        });

    }
}
