import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {Transporter} from "@core/shared/models/transporter";
import {TransporterService} from "@core/shared/services/transporter.service";

@Injectable({
  providedIn: 'root'
})
export class TransporterItemResolver  {

  constructor(
    private _transporterService: TransporterService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this._transporterService.getItemAPI(parseInt(route.paramMap.get('id')));
  }
}
