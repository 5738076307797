import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {filter, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AccessGuard  {
    constructor(
        private _router: Router,
        private _userService: UserService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._userService.currentUser.pipe(
            filter(user => !!user),
            map(user => this._canActivate(user, route))
        );
    }

    _canActivate(user, route: ActivatedRouteSnapshot) {
        if (!user) {
            return false;
        }

        /** On vérifie si le accessRequired est un objet ou un string
         *
         *  Si c'est un string : on test directement le ou les accès
         *  Exemple : {accessRequired: [OFFER_LIST_IS_MINE']}
         *
         *  Si c'est un objet : on recupère le ou les accès en fonction du paramètre de route spécifié
         *  Exemple : {
                        accessRequired: [
                            {params: {mode: 'personnal-offers'}, accesses: ['OFFER_LIST_IS_MINE','OFFER_LIST']},
                            {params: {mode: 'catalog'}, accesses: ['OFFER_CATALOG_LIST']},
                        ]
                      }
         ***/
        let accessRequired = route.data.accessRequired.reduce((carry, item) => {
            if (typeof item === 'object') {
                let allMatches = true;
                const {params, accesses} = item;


                for (const param of Object.keys(params)) {
                    if (!route.paramMap.has(param) || route.paramMap.get(param) !== params[param]) {
                        allMatches = false;
                        break;
                    }
                }

                /** Si des roles sont spécifiés on vérifie que le user possède bien l'un des roles **/
                if (item.roles) {
                    var countMatchRole = 0;
                    item.roles.forEach((role: string) => {
                        var findRoles = user?.roles.findIndex((roleUser) => {
                            return roleUser == role;
                        }) || [];

                        if (findRoles > -1) {
                            countMatchRole++;
                        }
                    });

                    if (!countMatchRole) {
                        allMatches = false;
                    }
                }

                if (allMatches) {
                    carry = carry.concat(accesses);
                }
            }

            if (typeof item === 'string') {
                carry.push(item);
            }

            return carry;
        }, []);

        var countMatch = 0;

        /** Si le user possède l'un des accès required, on autorise l'accès **/
        accessRequired.forEach((access: string) => {

            /** On vérifie que le user à le bon droit dans ses accès **/
            var findAccess = user?.accesses.findIndex((acc) => {
                return acc.tag == access;
            }) || [];

            if (findAccess > -1) {
                countMatch++;
            }
        });

        return (countMatch > 0);
    }
}
