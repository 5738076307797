import {IFileUpload} from "@lib/media/file-upload/file-upload";

export type VideoExtension = 'mp4' | 'webm' | 'ogv';

export const VIDEO_EXTENSIONS: VideoExtension[] = ['mp4', 'webm', 'ogv'];

export interface IMimeTypes {
    waitingImage: string[];
    mp4: string[];
    webm: string[];
    ogv: string[];
}

export const MimeTypes: IMimeTypes = {
    waitingImage: ['image/*'],
    mp4: ['video/mp4'],
    webm: ['video/webm'],
    ogv: ['video/ogg', 'video/ogv']
};

export interface ApiVideoConfig {
    error: boolean;
    msg: string;
    config: VideoConfig;
}

export interface VideoConfig {
    uploadMaxSize: number;
}

export interface Video {
    waitingImage: IFileUpload;
    mp4: IFileUpload;
    webm: IFileUpload;
    ogv: IFileUpload;
}
