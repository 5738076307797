import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpHeaders, HttpClient, HttpResponse} from '@angular/common/http';
import {ApiService} from './api.service';
import {Pagination} from "@core/shared/models/pagination";
import {User} from "@core/shared/models/user";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Role} from "@core/shared/models/role";
import {JwtToken} from "@core/shared/models/jwt";
import {environment} from "../../../../environments/environment";
import {Access, AccessType} from "@core/shared/models/access";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
        private _authenticationService: AuthenticationService
    ) {
        this._authenticationService.jwtToken.subscribe((data: JwtToken): void => {

            this.refreshUser(data.id);
        });
    }

    public refreshUser(id: number|null) {

        if (!id) {

            this.currentUser.next(null);

        } else {

            this.getItemAPI(id).subscribe((user: User): void => {

                this.currentUser.next(user);

            }, (): void => {

                if(!environment.production){

                    return;
                }

                this._authenticationService.logout();
            });
        }
    }

    public getItemsAPI(params?: string[]): Observable<any> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/users`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<User>(url, {
            headers: headers
        });

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<User>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/users`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<User>>(url, {
            headers: headers
        });

    }

    public getItemAPI(id: number): Observable<User> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<User>(`${this._apiService.getApiUrl(false, true)}/users/${id}`, {
            headers: headers
        });
    }

    public getPaginationItemAPI(id: number, params?: string[]): Observable<Pagination<User>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/users/${id}`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<User>>(url, {
            headers: headers
        });

    }

    public getItemSocietyUsers(id: number, params?: string[]): Observable<User[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/users/${id}/users`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<User[]>(url, {
            headers: headers
        });

    }

    public getPaginationItemSocietyUsers(id: number, params?: string[]): Observable<Pagination<User>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/users/${id}/users`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<User>>(url, {
            headers: headers
        });

    }

    public getItemSelfAPI(): Observable<Pagination<User>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');


        return this._httpClient.get<Pagination<User>>(`${this._apiService.getApiUrl(false, true)}/users/self/users`, {
            headers: headers
        })
    }

    public createItemAPI(data: object): Observable<User> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<User>(`${this._apiService.getApiUrl(false, true)}/users`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<User> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<User>(`${this._apiService.getApiUrl(false, true)}/users/${id}`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/users/${id}`, {
            headers: headers
        });
    }

    public validateAccountAPI(token: string, data: object): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, false)}/public/users/validate-account/${token}`, data, {
            headers: headers
        });
    }

    public resetAccountPasswordRequestAPI(data: object): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, false)}/public/users/reset-password/request`, data, {
            headers: headers
        });
    }

    public resetAccountPasswordAPI(token: string, data: object): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, false)}/public/users/reset-password/validate/${token}`, data, {
            headers: headers
        });
    }

    public exportItemsAPI(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/users/export`, {
            responseType: 'blob'
        });

    }

    public getLoginLogoAPI(params?: string[]): Observable<HttpResponse<Blob>> {

        let url: string = `${this._apiService.getApiUrl(false, true)}/public/users/login/logo`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get(url, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    public hasRole(user: User, role: Role): boolean {

        if(!user){

            return false;
        }

        return user.roles.includes(role);
    }

    public hasOneOfThisRoles(user: User, roles: Role[]): boolean {

        if(!user){

            return false;
        }

        return roles.some((role: Role): boolean => {

            return this.hasRole(user, role);
        });
    }

    public hasAllOfThisRoles(user: User, roles: Role[]): boolean {

        if(!user){

            return false;
        }

        return roles.every((role: Role): boolean => {

            return this.hasRole(user, role);
        });
    }

    public hasAccess(user: User, accessTag: AccessType): boolean {

        if(!user){

            return false;
        }

        return !!user.accesses.find((item: Access): boolean => {

            return item.tag === accessTag;
        });
    }

    public hasOneOfTheseAccesses(user: User, accessTags: AccessType[]): boolean {

        if(!user){

            return false;
        }

        return accessTags.some((item: AccessType): boolean => {

            return this.hasAccess(user, item);
        });
    }

    public hasAllOfTheseAccesses(user: User, accessTags: AccessType[]): boolean {

        if(!user){

            return false;
        }

        return accessTags.every((item: AccessType): boolean => {

            return this.hasAccess(user, item);
        });
    }
}
