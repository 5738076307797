import {AbstractControl} from "@angular/forms";

export const minLengthArrayValidator = (min: number) => {

    return (control: AbstractControl): { [key: string]: any } => {

        if (control.value.length >= min) {

            return null;
        }

        return {
            'minLengthArray': {
                valid: false
            }
        };
    }
};
