import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {TranslationBuilder} from "@core/shared/models/translation";
import {LocationField, LocationFieldType} from "@core/shared/models/location";
import {Country} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {MatExpansionPanel} from "@angular/material/expansion";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import { OfferLocationProviderComponent } from "@core/components/offer/offer-location/offer-location-provider/offer-location-provider.component";
import {OfferService} from "@core/shared/services/offer.service";

@Component({
    selector: 'app-core-offer-location-form',
    templateUrl: './offer-location-form.component.html',
    styleUrls: ['./offer-location-form.component.scss']
})
export class OfferLocationFormComponent implements OnInit, AfterViewInit {

    @ViewChild(MatExpansionPanel) expensionPanel: MatExpansionPanel;

    @ViewChild(OfferLocationProviderComponent, {static: false}) providerReadComponent: OfferLocationProviderComponent;

    @Input() form: UntypedFormGroup;

    @Input() formArrayName: string;

    @Input() index: number;

    @Input() translationBuilder: TranslationBuilder;

    @Input() accessMultipleGeolocation: boolean;

    public countries: Country[] = [];

    constructor(
        private _countryService: CountryService,
        private _translateService: TranslateService,
        public offerService: OfferService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._loadCountries();

    }

    ngAfterViewInit(): void {

        this._disableMouseDownEvent();
    }

    private _loadCountries(): void {

         this._countryService.getItemsAPI().subscribe((countries: Country[]): void => {

             this.countries = countries;
         });
    }

    public indexAsString(index: number): string{

        return index.toString();
    }

    public getLocationLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Department,
                reference: form.get('department')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            },
            {
                type: LocationFieldType.Latitude,
                reference: form.get('latitude')
            },
            {
                type: LocationFieldType.Longitude,
                reference: form.get('longitude')
            },
        ]
    }

    public getLocationForm(index: string): UntypedFormGroup {

        return (this.form.get('locations') as UntypedFormArray).controls[index] as UntypedFormGroup;
    }

    private _disableMouseDownEvent(): void {

        const inputs: NodeListOf<HTMLInputElement> = this.expensionPanel._body.nativeElement.querySelectorAll('input');

        inputs.forEach((input: HTMLInputElement) => {

            input.addEventListener('mousedown', (e) => {

                e.stopPropagation();
            });

        });
    }

    get locationControl(): AbstractControl {

        return (this.form.get(this.formArrayName) as UntypedFormArray).controls[this.index];
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get panelLabel(): string {

        const value: { address: string, zipcode: string, city: string, department: string, country: string} = this.locationControl.value;

        const country: Country = this.countries.find((c: Country): boolean => {

            return c.code === value.country;
        });

        if(!country){

            return '';
        }

        return `${value.address}, ${value.zipcode} ${value.city} | ${value.department}, ${country.translations[0].name}`;
    }

    get selectedLocales(): string[] {

        if(!this.form || !this.form.get('locales')){

            return [];
        }

        return this.form.get('locales').value;
    }
}
