import {Directive, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
    selector: '[number-card]'
})
export class NumberCardDirective {

    @Input() control: AbstractControl;

    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        this.control.patchValue(this._formatCardNumber(this.control.value));

        this.control.updateValueAndValidity();
    }

    private _formatCardNumber(value: string): string {

        value = ('' + value).replace(/\D/g, '');

        let index: number = 0;

        const pattern: string = 'XXXX XXXX XXXX XXXX';

        return pattern.replace(/X/g, (): string => {

            return value.charAt(index++) || '';

        }).trim();
    };
}
