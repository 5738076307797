<div [formGroup]="form" class="tab_content_overflow">

    <div class="row marges">
        <div class="col y_items_center">

            <div class="selected_offers" *ngIf="offerSearchService.selectedOffers.value.length">

                <span>{{ 'offer.list.selected.value' | translate }} :</span>
                <ul>
                    <li *ngFor="let offer of offerSearchService.selectedOffers.value">{{ translationService.getFallbackTranslation(offer.translations).name }}</li>
                </ul>
            </div>
        </div>
        <div class="col_content y_items_center" *ngIf="offerSearchService.selectedOffers.value.length">
            <button type="button" class="btn_cta btn_little" (click)="resetOfferSelection() ">{{ 'offer.list.selection.reset.action.value' | translate}}</button>
        </div>

    </div>

    <app-core-offer-search
        [user]="society.admin"
        [society]="society"
        [mode]="mode"
    ></app-core-offer-search>

</div>
