import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Form, IApiFormConfig, IFormConfig} from "./form";

@Injectable({
    providedIn: 'root'
})
export class FormService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * PREFIX URL
     */
    private readonly PREFIX_URL: string = '/form';

    /**
     * CONFIG URL
     */
    private readonly CONFIG_URL: string = '/config';

    private _config: IFormConfig;

    /**
     * @param _http
     */
    public constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    public configLoaded(): boolean {

        return (typeof this._config !== 'undefined');
    }

    public setConfig(config: IFormConfig): void {

        this._config = config;
    }

    /**
     * Retourne la configuration nécessaire au chargement d'une page
     *
     * @returns {Observable<IApiFormConfig>}
     */
    public loadConfig(): Observable<IApiFormConfig> {

        return this._http.get<IApiFormConfig>(`${this.API_URL}${this.PREFIX_URL}${this.CONFIG_URL}`);
    }

    public getConfig(): IFormConfig {

        return this._config;
    }

    get forms(): Form[] {
        return (this._config) ? this._config.forms : null;
    }
}
