import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {Router} from "@angular/router";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {Invoice, InvoiceType, InvoiceTypeItem} from "@core/shared/models/invoice";
import {InvoiceService} from "@core/shared/services/invoice.service";
import * as moment from "moment";
import {INVOICE_TYPE_ITEMS} from "@core/shared/data/invoice";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {parsePrice} from "@core/shared/utils/price";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import { InvoiceDownloadDialogComponent } from "@core/components/account/invoice/invoice-download-dialog/invoice-download-dialog.component";
import {DateIntervalFilterField, DateIntervalFilterValue} from "@core/shared/models/filter/date-interval-filter-field";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {LoaderService} from "@core/shared/services/loader.service";

@Component({
    selector: 'app-core-page-invoice-list',
    templateUrl: './page-invoice-list.component.html',
    styleUrls: ['./page-invoice-list.component.scss']
})
export class PageInvoiceListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: Invoice[];

    public types: InvoiceTypeItem[] = [];

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _invoiceService: InvoiceService,
        private _loaderService: LoaderService,
        public userService: UserService
    ) {
    }

    ngOnInit() {

        this._initDisplayedColumns();

        this._initFilterBuilder();

        this._initTypes();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this.dataSource;
                }),
                map(this.mapApiResult),
            ).subscribe((data: Invoice[]): void => {

                this.items = data;

            }
        );
    }

    private _initTypes(): void {

        this.types = INVOICE_TYPE_ITEMS.map((item: InvoiceTypeItem): InvoiceTypeItem => {
            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    private _initDisplayedColumns(): void {

        this.displayedColumns.push(...[
            'actions',
            'createdAt'
        ]);

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])){

            this.displayedColumns.push(...[
                'societyName',
                'contactLastname',
                'contactFirstname',
                'contactEmail'
            ]);
        }

        this.displayedColumns.push(...[
            'amountHT',
            'amountTTC',
            'number',
            'paymentReference',
            'types'
        ]);

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR'])){

            this.displayedColumns.push(...[
                'additionalSociety'
            ]);
        }

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])){

            this.displayedColumns.push(...[
                'isUserEnabled'
            ]);
        }
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this.dataSource.pipe(map(this.mapApiResult)).subscribe((data: Invoice[]): void => {

                this.items = data;

            })
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this.dataSource.pipe(map(this.mapApiResult)).subscribe((data: Invoice[]): void => {

            this.items = data;

        });
    }

    public getAmount(invoice: Invoice, amount: number): string {

        return `${parsePrice(amount)} ${ invoice.currency ? invoice.currency.symbol : '€' }`;
    }

    public getModel(item: Invoice): Invoice {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public downloadItem(item: Invoice, format: ('pdf'|'csv')): void {

        this._invoiceService.downloadItemAPI(item, format).subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            a.href = objectUrl;

            a.download = `${this._translateService.instant('invoice.download.file.title.value')}_${item.id}_${moment(item.createdAt).format('DD-MM-YYYY')}.${format}`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public downloadItems(items: Invoice[], format: ('pdf'|'csv')): void {

        setTimeout(() => {
            this._loaderService.show();
        })

        this._invoiceService.downloadItemsAPI(items, format).subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            a.href = objectUrl;

            a.download = `${this._translateService.instant('invoice.download.files.title.value')}.${ format === 'pdf' ? 'zip' : 'csv'}`;

            a.click();

            URL.revokeObjectURL(objectUrl);

        });
    }

    public openInvoiceDonwloadDialog(format: ('pdf' | 'csv')): void {

        const dialogRef: MatDialogRef<InvoiceDownloadDialogComponent> = this._dialog.open(InvoiceDownloadDialogComponent, {
            width: '500px',
            data: {
                user: this.currentUser
            }
        });

        dialogRef.componentInstance.confirm.subscribe((data: DateIntervalFilterValue) => {

            const params = this.downloadApiParams;

            const dateFilter = new DateIntervalFilterField('createdAt', null, 'gte', 'lte', data);

            params.push(dateFilter.serialize);

            if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])){

                return this._invoiceService.getPaginationItemsAPI(params).pipe(map(this.mapApiResult)).subscribe((invoices) => {

                    if (invoices.length)
                        this.downloadItems(invoices, format);

                    else {

                        this._snackBar.open(this._translateService.instant('invoice.download.no_result'), this._translateService.instant('notification.close.action.value'), {
                            duration: 5000
                        });
                    }

                });
            }
            else{

                return this._invoiceService.getPaginationSocietyItemsAPI(this.currentUser.society.id, params).pipe(map(this.mapApiResult)).subscribe((invoices) => {

                    if (invoices.length)
                        this.downloadItems(invoices, format);

                    else {

                        this._snackBar.open(this._translateService.instant('invoice.download.no_result'), this._translateService.instant('notification.close.action.value'), {
                            duration: 5000
                        });
                    }

                });
            }

        });

    }

    public getTypesLabel(types: InvoiceType[]): string {

        return types.map((type: InvoiceType): string => {

            return this._translateService.instant('invoice.type.' + type + '.value');

        }).join(', ');
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        const typesFilters: ArrayFilterField[] = [
            new ArrayFilterField('types', 'lkin', 'service'),
            new ArrayFilterField('types', 'lkin', 'subscription'),
            new ArrayFilterField('types', 'lkin', 'booking_commission'),
            new ArrayFilterField('types', 'lkin', 'gift_voucher_commission'),
            new ArrayFilterField('types', 'lkin', 'service_using_automatic_translation')
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }
        else {

            params.push(`sort[createdAt]=DESC`);
        }

        if(!this.filterBuilder.hasFilters ||
            (this.filterBuilder.hasFilters &&
                this.filterBuilder.hasFilter('types') &&
                this.filterBuilder.getFieldByKey('types').value === undefined) ) {

            typesFilters.forEach((roleFilter: ArrayFilterField): void => {

                params.push(roleFilter.serialize);
            });

        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get downloadApiParams(): string[] {

        const params = [];

        const typesFilters: ArrayFilterField[] = [
            new ArrayFilterField('types', 'lkin', 'service'),
            new ArrayFilterField('types', 'lkin', 'subscription'),
            new ArrayFilterField('types', 'lkin', 'booking_commission'),
            new ArrayFilterField('types', 'lkin', 'gift_voucher_commission'),
            new ArrayFilterField('types', 'lkin', 'service_using_automatic_translation'),
        ];

        typesFilters.forEach((roleFilter: ArrayFilterField): void => {

            params.push(roleFilter.serialize);

        });

        return params;

    }

    get mapApiResult(): (data: Pagination<Invoice>) => Invoice[] {

        return (data: Pagination<Invoice>) => {

            this.totalItems = data.totalItems;

            return data.items.map((invoice: Invoice): Invoice => {

                return Object.assign(invoice, {
                    amount: invoice.amount / 100,
                    amountTTC: invoice.amountTTC / 100
                });
            });
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get currentUser(): User {

        return this.userService.currentUser.getValue();
    }

    get dataSource(): Observable<Pagination<Invoice>> {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])){

            return this._invoiceService.getPaginationItemsAPI(this.itemsApiParams);
        }
        else{

            return this._invoiceService.getPaginationSocietyItemsAPI(this.currentUser.society.id, this.itemsApiParams);
        }
    }
}
