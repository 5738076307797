import {SearchAutocompleteConfiguration} from "@core/shared/models/search/search-autocomplete";

export const DEFAULT_SEARCH_AUTOCOMPLETE_CONFIGURATION: SearchAutocompleteConfiguration<unknown> = {
    placeholder: 'search.autocomplete.placeholder.value',
    displayResult: true,
    resultLabel: (item: unknown, index: number): unknown => {

        return index;
    }
}
