<h1 mat-dialog-title class="">{{ 'tender.offer.select.value' | translate }}</h1>
<mat-dialog-content>

    <form [formGroup]="form">

        <div class="row marges ligne_form">

            <div class="col_12">

                <div class="form-group">
                    <label>{{ 'tender.offerCreator.comment.add.description.value' | translate:{ emitter: data.emitter.name } }}</label>
                    <div class="radio">
                        <input type="radio" class="form-control" id="commentYes" [checked]="addComment" (change)="handleComment(true)">
                        <label for="commentYes">{{'form.user.fields.yes.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" class="form-control" id="commentNo" [checked]="!addComment" (change)="handleComment(false)">
                        <label for="commentNo">{{'form.user.fields.no.value' | translate}}</label>
                    </div>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form" *ngIf="addComment">

            <div class="col_12">

                <div class="form-group">
                    <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                    <label for="comment">{{'comment.value' | translate}}</label>
                    <span class="message-help">
                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: commentMaxLength } }}
                    </span>
                    <mat-error *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('comment').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength: commentMaxLength } }}</div>
                        </div>
                    </mat-error>
                </div>

            </div>

        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions>
    <div class="row marges x_center">
        <div class="col_content">
            <button class="btn_cta btn_little btn_green_lines" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="formService.submit()">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
