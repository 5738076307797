import {TranslationItem} from "@core/shared/models/translation";

export type OfferAttributeTypeTagType = 'touristsDestinations' | 'themes' | 'types' | 'activities' | 'accommodations' | 'restoration' | 'region';

export interface OfferAttributeType {

    id: number;

    tag: OfferAttributeTypeTagType;

    translations: OfferAttributeTypeTranslation[];
}

export interface OfferAttributeTypeTranslation extends TranslationItem {

    label: string;
}

export const OFFER_ATTRIBUTES_TYPE_TAGS: OfferAttributeTypeTagType[] = [
    'accommodations',
    'activities',
    'restoration',
    'themes',
    'touristsDestinations',
    'types',
    'region'
];
