import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from "@core/shared/services/api.service";
import {Page} from "@core/shared/models/page";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {TranslationService} from "@core/shared/services/translation.service";
import {Location} from '@angular/common';

@Component({
    selector: 'page-read',
    templateUrl: './page-read.component.html',
    styleUrls: ['./page-read.component.scss']
})
export class PageReadComponent implements OnInit {

    public page: Page;

    public native_types: string[] = ['button', 'text', 'file', 'image', 'slideshow', 'iframe', 'marketoForm', 'form'];

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _apiService: ApiService,
        private sanitizer: DomSanitizer,
        public _translationService: TranslationService,
        private _location: Location
    ) {
    }

    ngOnInit() {

        this._activatedRoute.data.subscribe((data: { page: Page }): void => {

            this.page = data.page;
        });
    }

    public sanitize(string): SafeHtml {

        return this.sanitizer.bypassSecurityTrustHtml(string);
    }

    public backClicked(): void {

        this._location.back();
    }

    get localeId(): string {

        return this._translationService.getUserLocale();
    }

    get backEnabled(): boolean {

        const param: string = this._activatedRoute.snapshot.queryParams['backEnabled'];

        return !!param ? Boolean(parseInt(param)) : false;
    }
}
