import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchAutocompleteConfiguration} from "@core/shared/models/search/search-autocomplete";
import uniqid from 'uniqid';
import {MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from "@angular/material/legacy-autocomplete";
import {fromEvent, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {DEFAULT_SEARCH_AUTOCOMPLETE_CONFIGURATION} from "@core/shared/data/search/search-autocomplete";

@Component({
    selector: 'app-core-search-autocomplete',
    templateUrl: './search-autocomplete.component.html',
    styleUrls: ['./search-autocomplete.component.scss']
})
export class SearchAutocompleteComponent<T> implements OnInit, AfterViewInit {

    /**
     * Configuration de l'autocompléteur
     */
    @Input() configuration: SearchAutocompleteConfiguration<T>;

    /**
     * Fonction retourtant la source de données
     */
    @Input() sourceCallback: (search: string) => Observable<T[]>;

    /**
     * Événement déclenché lors de la sélection d'une valeur
     */
    @Output() onSelection: EventEmitter<T> = new EventEmitter<T>();

    /**
     * Accesseur pour le champ de saisie d'une valeur
     */
    @ViewChild('autocomplete', { static: true }) autocomplete: ElementRef;

    /**
     * Accesseur pour le résultat de la recherche
     */
    @ViewChild(MatAutocompleteTrigger, { static: true, read: MatAutocompleteTrigger }) autocompleteTrigger: MatAutocompleteTrigger;

    /**
     * Identifiant unique du champ de saisie
     */
    public searchAutocompleteIdentifier: string;

    /**
     * Liste des valeurs disponibles
     */
    public items: T[] = [];

    constructor(
        private _httpClient: HttpClient,
        private _apiService: ApiService
    ) {}

    ngOnInit(): void {

        this.configuration = Object.assign(DEFAULT_SEARCH_AUTOCOMPLETE_CONFIGURATION, {...this.configuration} || {});

        this.searchAutocompleteIdentifier = uniqid('search-autocomplete-');
    }

    ngAfterViewInit(): void {

        this._initEvents();
    }

    /**
     * Initialise la recherche d'une valeur
     */
    private _initEvents(): void {

        fromEvent(this.autocomplete.nativeElement, 'keypress').subscribe((event: KeyboardEvent): void => {

            this._clearItems();

            this.autocompleteTrigger.closePanel();

            if(event.code === 'Enter'){

                event.preventDefault();
            }
        });

        fromEvent(this.autocomplete.nativeElement, 'keyup').subscribe((event: KeyboardEvent): void => {

            this._clearItems();

            this.autocompleteTrigger.closePanel();

            if(event.key === 'Enter'){

                if(!this.autocomplete.nativeElement.value.length){

                    return;
                }

                this.search();
            }
        });

        fromEvent(this.autocomplete.nativeElement, 'focus').subscribe((): void => {

            this.autocomplete.nativeElement.value = '';

            this._clearItems();

            this.autocompleteTrigger.closePanel();
        });
    }

    /**
     * Réinitialise la liste des valeurs
     */
    private _clearItems(): void {

        this.items = [];
    }

    /**
     * Recherche les valeurs en fonction de la saisie
     */
    public search(): void {

        this.autocompleteTrigger.closePanel();

        if(this.autocomplete.nativeElement.value.length){

            this.sourceCallback(this.autocomplete.nativeElement.value).subscribe((items: T[]): void => {

                this.items = items;

                if(this.configuration.displayResult){

                    this.autocompleteTrigger.openPanel();
                }
            });
        }
        else{

            this._clearItems();
        }
    }

    /**
     * Réinitialise la recherche
     */
    public clearSearch(): void {

        this.autocomplete.nativeElement.value = '';

        this._clearItems();

        this.autocompleteTrigger.closePanel();
    }

    /**
     * Retourne le label affiché pour une valeur
     *
     * @param item
     * @param index
     */
    public getLabel(item: T, index: number): unknown {

        return this.configuration.resultLabel(item, index);
    }

    /**
     * Sélectionne une valeur
     */
    public select(value: T): void {

        this.autocomplete.nativeElement.value = '';

        this.autocomplete.nativeElement.blur();

        this._clearItems();

        this.autocompleteTrigger.closePanel();

        this.onSelection.emit(value);
    }
}
