<ng-container [formGroup]="form">
    <mat-select [formControlName]="formControlName">
        <mat-option  *ngFor="let item of items$ | async;" [value]="item.value">{{ item.label }}</mat-option>
    </mat-select>
    <mat-label [ngClass]="{ required: required }">{{ label | translate }}</mat-label>
    <mat-error *ngIf="form.get(formControlName).invalid && (form.get(formControlName).dirty || form.get(formControlName).touched)">
        <div class="invalid-feedback">
            <div *ngIf="required && form.get(formControlName).errors.required">{{ requiredErrorTranslation | translate }}</div>
        </div>
    </mat-error>
</ng-container>
