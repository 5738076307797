<div class="channel_pim_preview" [ngClass]="[styleForm.get('font').value]">
    <div class="row marges">
        <div class="col_4 content_card">
            <div class="card_offer">
                <div class="row h_full direction_y">
                    <div class="col_content card_top">
                        <div [ngClass]="{'visuel' : isView('visual'), 'map_wrapper' : isView('map')}">
                            <ng-container *ngIf="isView('map')">
                                <div class="map" leaflet [leafletOptions]="mapOptions" [leafletLayer]="marker"></div>
                            </ng-container>
                            <ng-container *ngIf="isView('visual')">
                                <img src="assets/images/offer/preview/1.jpg" alt="image">
                            </ng-container>
                            <div class="infos">
                                <div class="extras">
                                    <span class="map-switch ty-map" *ngIf="isView('visual')" (click)="setView('map')"></span>
                                    <span class="map-switch ty-eye" *ngIf="isView('map')" (click)="setView('visual')"></span>
                                </div>
                                <div class="tags">
                                    <span class="tag"><span>{{ 'offer.preview.content.new.value' | translate }}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col card_bottom">
                        <div class="infos_offer">
                            <div class="row h_full direction_y">
                                <div class="col infos_wrapper">
                                    <div class="infos" [ngClass]="{'closed' : !showMoreOpened}">
                                        <div class="row marges">
                                            <div class="col y_items_start">
                                                <div>
                                                    <p class="title" [ngStyle]="{ 'color': styleForm.get('colorTitle').value }">{{ 'offer.preview.content.title.value' | translate }}</p>
                                                    <p class="duration">{{ 'offer.preview.content.duration.value' | translate }}</p>
                                                </div>
                                            </div>
                                            <div class="col_content y_items_start">
                                                <span class="show_more ty-chevron" (click)="showMoreOpened ? showMoreOpened = false : showMoreOpened = true"></span>
                                            </div>
                                        </div>
                                        <div class="price">
                                            <span class="price_amount" [ngStyle]="{ 'color': styleForm.get('colorTitle').value }">{{ 'offer.preview.content.price.value' | translate }}</span>
                                            <div class="price_info">
                                                <span class="from">{{ 'offer.preview.content.price.from.value' | translate }}</span>
                                                <span>{{ 'offer.preview.content.price.byPerson.value' | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="description">
                                            <p>{{ 'offer.preview.content.description.value' | translate }}</p>
                                        </div>
                                        <div class="infos_sup">
                                            <div class="row">
                                                <div class="col_4">
                                                    <p>{{ 'offer.preview.content.type.label.value' | translate }}</p>
                                                </div>
                                                <div class="col_8">
                                                    <p>{{ 'offer.preview.content.type.value' | translate }}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col_4">
                                                    <p>{{ 'offer.preview.content.interests.label.value' | translate }}</p>
                                                </div>
                                                <div class="col_8">
                                                    <ul>
                                                        <li>{{ 'offer.preview.content.interests.first.value' | translate }}</li>
                                                        <li>{{ 'offer.preview.content.interests.second.value' | translate }}</li>
                                                        <li>{{ 'offer.preview.content.interests.third.value' | translate }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col_content">
                                    <div class="links_offer">
                                        <div class="black">
                                            <a class="btn_cta offer_show" (mouseover)="viewOfferHover=true" (mouseleave)="viewOfferHover=false" [ngStyle]="{ 'background-color' : styleForm.get(viewOfferHover ? 'colorHighlighting' : 'colorBackgroundButton').value }">
                                                <span [ngStyle]="{ 'color': styleForm.get('colorTextButton').value }">{{ 'offer.preview.content.view.action.value' | translate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
