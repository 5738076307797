import {Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, } from "@angular/forms";
import {FormErrorService} from "@core/shared/services/form.error.service";

@Injectable()
export class FormService {

    private _form: UntypedFormGroup;

    private _submitCallback: () => void;

    constructor(
        private _formErrorService: FormErrorService
    ) {
    }

    public showValidationMessages(formGroup: UntypedFormGroup): void {

        for (const key in formGroup.controls) {

            if (formGroup.controls.hasOwnProperty(key)) {

                const control: UntypedFormControl = <UntypedFormControl>formGroup.controls[key];

                if (Object.keys(control).includes('controls')) {

                    const formGroupChild: UntypedFormGroup = <UntypedFormGroup>formGroup.controls[key];

                    this.showValidationMessages(formGroupChild);
                }

                control.markAsTouched();
            }
        }
    }

    public checkValidity(formGroup?: UntypedFormGroup): void {

        if(!formGroup){

            formGroup = this._form;
        }

        this.showValidationMessages(formGroup);
    }

    public submit(configuration?: { fragment?: string }): void {

        this.checkValidity();

        if (this.form.invalid) {

            console.log('DONNÉES DU FORMULAIRE :', this.form.value);

            this._formErrorService.handleErrors(this._form);

            return;
         }

        if(!this.submitCallback){

            throw new Error('Fonction de callback non définie');
        }

        this.submitCallback(configuration);
    }

    get form(): UntypedFormGroup {

        return this._form;
    }

    set form(value: UntypedFormGroup) {

        this._form = value;
    }

    get submitCallback(): (configuration?: { fragment?: string }) => void {

        return this._submitCallback;
    }

    set submitCallback(value: (configuration?: { fragment?: string }) => void) {

        this._submitCallback = value;
    }
}
