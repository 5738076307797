<h1 mat-dialog-title>
    {{ data.title }}
</h1>
<div mat-dialog-content>
    <subscription-form [dialogRef]="dialogRef" [subscriptions]="subscriptions" [isDisabled]="false" [redirectionToSubscriptionViewEnabled]="false"></subscription-form>
</div>
<mat-dialog-actions>
    <div class="row x_end">
        <button class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'action.close' | translate }}</button>
    </div>
</mat-dialog-actions>
