import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Form, IForm, IFormConfig} from "./form";
import {FormService} from "./form.service";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {IButtonTemplate} from "../button/button";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'app-element-form',
    templateUrl: './form.component.html',
    styleUrls: [
        '../../element.component.scss'
    ],
})
export class FormComponent implements OnInit {

    @Input() public content: IForm;

    @Output() public update: EventEmitter<IForm> = new EventEmitter<IForm>();

    public config: IFormConfig;

    public configInitialized: boolean = false;

    @Input() public apiUrl: string;

    /**
     * @param _translateService
     * @param _formService
     * @param _snackbar
     */
    public constructor(
        private _translateService: TranslateService,
        private _formService: FormService,
        private _snackbar: MatSnackBar
    ) {}

    public ngOnInit():void  {
        this._formService.setApiUrl(this.apiUrl);

        if (!this.content) {

            this.content = {
                form: null
            };
        }


        if(this._formService.configLoaded()){

            this.config = this._formService.getConfig();

            this.configInitialized = true;
        }
        else{

            this._formService.loadConfig().subscribe((data: any) => {

                    data = Object.assign(data, {
                        help: data.help,
                    });


                    this._formService.setConfig(data);

                    this.config = this._formService.getConfig();

                    this.configInitialized = true;


                },
                (error : HttpErrorResponse) => {
                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                        this._snackbar.open(error.error, messages['action.close']);
                    });
                }
            );
        }

    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {IForm} form
     */
    public setContent(form: IForm) : any {
        this.update.emit(form); // On met à jour le contenu de l'élément
    }



    get forms(): Form[] {
        return this._formService.forms;
    }

    /**
     *
     * @param {IButtonTemplate} first
     * @param {IButtonTemplate} second
     *
     * @returns {boolean}
     */
    public compareByOptionClass(first: Form, second: Form) : any {

        if (!first && !second) {
            return true;
        }

        return first && second && first.id === second.id;
    }
}
