import {Injectable} from '@angular/core';
import {ICustomButton, IElement, IElementBackgroundColor, IElementConfig} from "./element";
import {IColumn} from "../column/column";

@Injectable()
export class ElementService {

    private backgroundColors: IElementBackgroundColor[] = [];

    private customButtons: ICustomButton[] = [];

    setConfig(config: IElementConfig): void {
        this.backgroundColors = config.backgroundColors;
    }

    getBackgroundColors(): IElementBackgroundColor[]{
        return this.backgroundColors;
    }

    addCustomButton(customButton: ICustomButton): void {
        this.customButtons.push(customButton);
    }

    getCustomButtons(): ICustomButton[] {
        return this.customButtons;
    }

    /**
     * Récupération d'un élément en fonction de sa position dans une colonne
     *
     * @param {IColumn} column
     * @param {number} elementPosition
     * @returns {IElement}
     */
    getElementByPosition(column: IColumn, elementPosition: number): IElement {

        let match = column.elements.filter((element: IElement) => {
            return element.position === elementPosition;
        }).shift();

        return match || null;
    }

    /**
     * Récupération du dernier élément dans une colonne
     *
     * @param {IColumn} column
     * @returns {IElement}
     */
    getLastElement(column: IColumn): IElement {

        if (!column.elements.length)
            return null;

        let position: number = -1;

        column.elements.map((element: IElement) => {
            if (element.position > position) {
                position++;
            }
        });

        return this.getElementByPosition(column, position);
    }

    /**
     * Détermine si l'élément est le premier dans la colonne
     *
     )     * @param {IElement} element
     * @returns {boolean}
     */
    isFirstElement(column: IColumn, element: IElement): boolean {
        return element.position === 0;
    }

    /**
     * Détermine si l'élément est le dernier dans la colonne
     *
     * @param {IColumn} column
     * @param {IElement} element
     * @returns {boolean}
     */
    isLastElement(column: IColumn, element: IElement): boolean {
        return this.getLastElement(column).position === element.position;
    }

    /**
     * Décrémentation de la position d'un élément dans une colonne
     * Sur l'interface le type ainsi que contenu de l'élement est donc décalé au dessus
     *
     * @param {IColumn} column
     * @param {IElement} element
     */
    decreaseContentElement(column: IColumn, element: IElement): void {

        if (this.isFirstElement(column, element))
            return;

        const elementToUp: IElement = this.getElementByPosition(column, element.position - 1);

        const duplicateElementToUp: IElement = <IElement> JSON.parse(JSON.stringify(elementToUp));

        const values: string[] = ['type', 'title', 'subtitle', 'content', 'backgroundColor'];

        values.map(function (value) {
            if (elementToUp.hasOwnProperty(value)) {
                elementToUp[value] = element[value];
            }
            if (element.hasOwnProperty(value)) {
                element[value] = duplicateElementToUp[value];
            }
        });

    }

    /**
     * Incrémentation de la position d'un élément dans une colonne
     * Sur l'interface le type ainsi que contenu de l'élement est donc décalé en dessous
     *
     * @param {IColumn} column
     * @param {IElement} element
     */
    increaseContentElement(column: IColumn, element: IElement): void {

        if (this.isLastElement(column, element))
            return;

        const elementToDown: IElement = this.getElementByPosition(column, element.position + 1);

        const duplicateElementToDown: IElement = <IElement> JSON.parse(JSON.stringify(elementToDown));

        const values: string[] = ['type', 'title', 'subtitle', 'content', 'backgroundColor'];

        values.map(function (value) {
            if (elementToDown.hasOwnProperty(value)) {
                elementToDown[value] = element[value];
            }
            if (element.hasOwnProperty(value)) {
                element[value] = duplicateElementToDown[value];
            }
        });


    }

    /**
     * Détermine si l'élément peut être décalé au dessus
     *
     * @param {IColumn} column
     * @param {IElement} element
     * @returns {boolean}
     */
    isDecreaseElementAllowed(column: IColumn, element: IElement): boolean {
        return !this.isFirstElement(column, element);
    }

    /**
     * Détermine si l'élément peut être décalé au dessous
     *
     * @param {IColumn} column
     * @param {IElement} element
     * @returns {boolean}
     */
    isIncreaseElementAllowed(column: IColumn, element: IElement): boolean {
        return !this.isLastElement(column, element);
    }

    /**
     * Réinitialisation du type ainsi que du contenu d'un élément
     *
     * @param {IElement} element
     */
    clearElement(element: IElement): void {

        let values = ['type', 'title', 'subtitle', 'content'];

        values.map(function (value) {
            if (element.hasOwnProperty(value))
                element[value] = null;
        });
    }

    /**
     * Suppression d'un élément pour une colonne
     *
     * @param {IColumn} column
     * @param {IElement} deletedElement
     */
    deleteElement(column: IColumn, deletedElement: IElement): void {

        let deletedPosition: number = deletedElement.position;

        column.elements = column.elements.filter((element: IElement) => {
            return element.position !== deletedElement.position;
        });

        column.elements.map((element: IElement) => {
            if (element.position > deletedPosition) {
                element.position--;
            }
        });
    }
}
