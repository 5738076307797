<div [formGroup]="form" class="form-group">
    <mat-form-field>
        <mat-label *ngIf="config.attrs.label">{{config.attrs.label}}</mat-label>

        <mat-select selectInput *ngIf="!config.attrs.groupped" [attrs]="config.attrs" [formControlName]="config.id"
                    (selectionChange)="selectChange($event)" [(ngModel)]="value">
            <mat-option [value]="null">--</mat-option>
            <div *ngFor="let item of config.attrs.choices| keyvalue">
                <mat-option *ngFor="let choice of item.value| keyvalue" [value]="choice.key">
                    {{ choice.value}}
                </mat-option>
            </div>
        </mat-select>

        <mat-select selectInput *ngIf="config.attrs.groupped" [attrs]="config.attrs" [formControlName]="config.id"
                    (selectionChange)="selectChange($event)" [(ngModel)]="value">
            <mat-option [value]="null">--</mat-option>
            <mat-optgroup *ngFor="let group of config.attrs.choices" [label]="group.name">
                <mat-option *ngFor="let choice of group.choices | keyvalue" [value]="choice.key">
                    {{choice.value}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>
        </div>
    </mat-error>
</div>
