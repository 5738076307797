import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-cms-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class CmsVideoComponent{


    /**
     * Valeur du champ texte
     */
    @Input() video : any;



    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor() {
    }


}
