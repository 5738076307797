import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OfferAttributeType} from "../models/offer-attribute-type";
import {OfferAttributeTypeService} from "../services/offer-attribute-type.service";

@Injectable({
    providedIn: 'root'
})
export class OfferAttributeTypeListResolver  {

    constructor(
        private _offerAttributeTypeService: OfferAttributeTypeService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._offerAttributeTypeService.getItemsAPI();
    }
}
