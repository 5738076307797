import {Injectable} from '@angular/core';
import {OfferCreatorCardCustomAction} from "@core/shared/models/offer-creator/offer-creator-card";

@Injectable()
export class OfferCreatorCardService {

    public customActions: OfferCreatorCardCustomAction[] = [];

    public resetCustomActions(): void {

        this.customActions = [];
    }
}
