<div class="page_title">
    <div class="row marges">
        <div class="col y_items_center">
            <h1 class="title">{{ 'payment.title.value' | translate }}</h1>
        </div>

    </div>
</div>

<div *ngIf="isSuccess">

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p class="title success">{{ 'services.success.title.value' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p><strong>{{ 'subscription.success.activation.value' | translate }}</strong></p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form" *ngIf="paymentId">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p>{{ 'subscription.success.ref.value' | translate }} : <strong>{{ paymentId }}</strong></p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p>{{ 'subscription.success.email.value' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p>{{'subscription.success.card.value' | translate}}</p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isOneOfTheseServices(['online-sale']) && !user.society.isValidMangoPayAccount && !service.isValidatedMangoPayAccountRequired">

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form_paymment_reponse">
                    <p>{{ serviceTranslation.mangoPayDocumentDescription }}</p>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <button class="btn_cta btn_little" type="button" (click)="goToMangoPayDocuments()">{{ 'service.mangopay.documents.add.action.value' | translate }}</button>
            </div>
        </div>

    </ng-container>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p><strong>{{ 'subscription.success.thanks.value' | translate }}</strong></p>
            </div>
        </div>
    </div>

</div>
<div *ngIf="!isSuccess">

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p class="title error">{{ 'subscription.error.title.value' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p>{{ 'subscription.error.content.value' | translate }}</p>
            </div>
        </div>
    </div>

</div>

<div class="row marges ligne_form">
    <div class="col_12">
        <div class="form_paymment_reponse">
            <button class="btn_cta btn_little" type="button" (click)="goToServices()">{{ 'services.success.services.btn.value' | translate }}</button>
        </div>
    </div>
</div>
