import {Injectable} from "@angular/core";
import {CrudAction} from "@core/shared/models/crud";

@Injectable()
export class CrudService {

    private _action: CrudAction = null;

    setAction(value: CrudAction): void{

        this._action = value;
    }

    get action(): CrudAction {

        return this._action;
    }

    get isCreateAction(): boolean {

        return this.action === 'create';
    }

    get isUpdateAction(): boolean {

        return this.action === 'update';
    }
}
