import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {GiftVoucherPeriod} from "@core/shared/models/gift-voucher/gift-voucher-period";

@Injectable({
    providedIn: 'root'
})
export class GiftVoucherPeriodService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public createItemAPI(offerDataId: number, data: object): Observable<GiftVoucherPeriod> {

        const url = `${this._apiService.getApiUrl(false, true)}/giftVouchers/offers/data/${offerDataId}/periods`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<GiftVoucherPeriod>(url, data, {
            headers: headers
        });
    }
}

