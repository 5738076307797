import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {SocietyService} from "@core/shared/services/society.service";
import {UserService} from "@core/shared/services/user.service";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Observable} from "rxjs";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";

@Injectable({
    providedIn: 'root'
})
export class PageGiftVoucherItemResolver {

    constructor(
        private _giftVoucherServce: GiftVoucherService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _authenticationService: AuthenticationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

         return this._giftVoucherServce.getItemAPI(parseInt(route.paramMap.get('id')))
    }
}