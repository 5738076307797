import {Pipe, PipeTransform} from '@angular/core';
import {parsePrice} from "@core/shared/utils/price";

@Pipe({
    name: 'parsePrice'
})
export class ParsePricePipe implements PipeTransform {

    transform(value: number): string {

        return parsePrice(value);
    }
}
