import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {Moment} from "moment";
import {DATE_FORMAT} from "@app/data";
import {Day, DAYS} from "@core/shared/models/day";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {GiftVoucherPeriodService} from "@core/shared/services/gift-voucher/gift-voucher-period.service";

@Component({
    selector: 'app-core-gift-voucher-period-create',
    templateUrl: './gift-voucher-period-create.component.html',
    styleUrls: ['./gift-voucher-period-create.component.scss'],
    providers: [
        FormService
    ]
})
export class GiftVoucherPeriodCreateComponent implements OnInit {

    @Input() giftVoucher: GiftVoucher;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @Output() periodCreated: EventEmitter<void> = new EventEmitter<void>();

    public minDate: Date = new Date();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _giftVoucherPeriodService: GiftVoucherPeriodService,
        private _translateService: TranslateService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]],
            excludedDays: [[]],
            isAvailable: [null, [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                dateStart: (this.form.get('dateStart').value as Moment).format(DATE_FORMAT),
                dateEnd: (this.form.get('dateEnd').value as Moment).format(DATE_FORMAT)
            });

            this._giftVoucherPeriodService.createItemAPI(this.giftVoucher.offerData.id, data).subscribe((): void => {

                this.periodCreated.emit();
            });
        };
    }

    public handleExcludedDay(event: any): void{

        const control: UntypedFormControl = this.form.get('excludedDays') as UntypedFormControl;

        const controlValue: number[] = control.value;

        const value: number = parseInt(event.target.value);

        if(event.target.checked){

            controlValue.push(value);
        }
        else{

            const index: number = controlValue.findIndex((i: number): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue.sort());
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get days(): Day[] {

        return DAYS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
