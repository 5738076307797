import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {File} from "./file";

@Injectable()
export class FileService {

    /**
     * API URL
     */
    private API_URL: string;

    private readonly GALLERY_URL: string = '/galleries';

    private readonly MEDIA_URL: string = '/media';

    private readonly FILE_URL: string = '/files';

    private readonly _allowedFileTypes: string[] = [

        // Image

        'image/*', // .jpg, .jpeg, .png, .gif

        'video/*',

        // Adobe

        'application/pdf', // .pdf

        // Archive

        'application/zip', // .zip
        'application/x-rar-compressed', // .rar
        'application/x-tar', // .tar
        'application/x-7z-compressed', // .7z

        // Tableau

        'text/csv', // .csv

        // Microsoft

        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.ms-powerpoint', // .ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx

        // Open Office

        'application/vnd.oasis.opendocument.text', // .odt
        'application/vnd.oasis.opendocument.presentation', // .odp
        'application/vnd.oasis.opendocument.spreadsheet', // .ods
    ];

    /**
     * @param _http
     */
    constructor(private _http: HttpClient) {
    }

    /**
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    /**
     * @return string
     */
    public getApiUrl(): string {

        return this.API_URL;
    }

    /**
     * @return string
     */
    public getGalleryUrl(): string {

        return this.GALLERY_URL;
    }

    /**
     * @return string
     */
    public getMediaUrl(): string {

        return this.MEDIA_URL;
    }

    /**
     * @return string
     */
    public getFileUrl(): string {

        return this.FILE_URL;
    }

    /**
     * Retourne la liste des Mime Types autorisés
     *
     * @returns {string[]}
     */
    getAllowedFileTypes(): string[] {

        return this._allowedFileTypes;
    }

    /**
     * @param file
     */
    getPath(file: File): string {

        return `${ this.API_URL }${ this.MEDIA_URL }${ this.FILE_URL }/${ file.id }`;
    }
}
