import {Exclude, Expose} from "class-transformer";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";

@Exclude()
export class SocietyGiftVoucherConfiguration {

    @Expose()
    validityDuration: number;

    @Expose()
    enableRenewal: boolean;

    @Expose()
    renewalDuration: number;

    @Expose()
    enableRenewalPayment: boolean;

    @Expose()
    renewalPercentPrice: number;

    @Expose()
    templateGiftVouchers: TemplateGiftVoucher[];

}