<div class="panel_title">
    <div class="row marges">
        <div class="col y_items_center">
            <div>
                <p class="title">{{ 'offer.date.detail.value' | translate }}</p>
                <p>{{ (date.format('YYYY-MM-DD') | date : 'fullDate' : null: localeId) | titlecase }}</p>
            </div>
        </div>
        <div class="col_content y_items_center">
            <span class="ty-close" (click)="close.emit()"></span>
        </div>
    </div>
</div>

<ng-container *ngIf="offerDate">

    <ng-container *ngIf="isConfigurationFieldConfigured('price')">

        <div class="form_part">

            <!-- Tarifs par personne -->

            <p class="title_part">{{ 'price.plural.byPerson.value' | translate}}</p>

            <div class="ligne_form row marges">

                <div class="col_12">

                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ 'price.net.ht.value' | translate }}</th>
                            <th>{{ 'price.net.ttc.value' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="offer.publics.includes('adult')">
                            <td><img src="assets/images/adult-icon.svg" alt="adult">{{ 'adult.value' | translate }}</td>
                            <td>{{ parsePrice(offerDate.adultPriceHT, offer.currency) }}</td>
                            <td>{{ parsePrice(offerDate.adultPriceTTC, offer.currency) }}</td>
                        </tr>
                        <tr *ngIf="offer.publics.includes('child')">
                            <td><img src="assets/images/child-icon.svg" alt="child">{{ 'child.value' | translate }}</td>
                            <td>{{ parsePrice(offerDate.childPriceHT, offer.currency) }}</td>
                            <td>{{ parsePrice(offerDate.childPriceTTC, offer.currency) }}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="isConfigurationFieldConfigured('stock')">

        <div class="form_part">

            <!-- Stocks -->

            <p class="title_part">{{ 'stock.plural.value' | translate}}</p>

            <div class="ligne_form row marges">

                <div class="col_12">

                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ 'offer.date.stock.allocated.value' | translate }}</th>
                            <th>{{ 'offer.date.stock.sold.value' | translate }}</th>
                            <th>{{ 'offer.date.stock.remaining.value' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>

                            <!-- Stock public -->

                            <tr>
                                <td colspan="4">{{ 'offer.date.stock.public.value' | translate }}</td>
                            </tr>

                            <ng-template #unlimitedStock>
                                <span class="unlimited">∞</span>
                            </ng-template>

                            <ng-container *ngFor="let stock of offerDate.stocks">

                                <ng-container *ngIf="isStockManagementType('common')">

                                    <!-- Stock commun -->

                                    <tr>
                                        <td><img src="assets/images/adult-child-icon.svg" alt="adult-child">{{ 'stock.type.common.value' | translate }}</td>
                                        <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ stock.commonAllocatedStock }}</td>
                                        <td>{{ stock.commonSoldStock }}</td>
                                        <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ stock.commonRemainingStock }}</td>
                                    </tr>

                                </ng-container>

                                <ng-container *ngIf="isStockManagementType('dissociated')">

                                    <!-- Stock dissocié -->

                                    <tr *ngIf="offer.publics.includes('adult')">
                                        <td><img src="assets/images/adult-icon.svg" alt="adult">{{ 'adult.value' | translate }}</td>
                                        <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ stock.adultAllocatedStock }}</td>
                                        <td>{{ stock.adultSoldStock }}</td>
                                        <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ stock.adultRemainingStock }}</td>
                                    </tr>

                                    <tr *ngIf="offer.publics.includes('child')">
                                        <td><img src="assets/images/child-icon.svg" alt="child">{{ 'child.value' | translate }}</td>
                                        <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ stock.childAllocatedStock }}</td>
                                        <td>{{ stock.childSoldStock }}</td>
                                        <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ stock.childRemainingStock }}</td>
                                    </tr>

                                </ng-container>

                            </ng-container>

                            <!-- Stock total -->

                            <tr>
                                <td colspan="4">{{ 'offer.date.stock.total.value' | translate }}</td>
                            </tr>

                            <ng-container *ngIf="isStockManagementType('common')">

                                <!-- Stock commun -->

                                <tr>
                                    <td><img src="assets/images/adult-child-icon.svg" alt="adult-child">{{ 'stock.type.common.value' | translate }}</td>
                                    <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ offerDate.totalCommonAllocatedStock }}</td>
                                    <td>{{ offerDate.totalCommonSoldStock }}</td>
                                    <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ offerDate.totalCommonRemainingStock }}</td>
                                </tr>

                            </ng-container>

                            <ng-container *ngIf="isStockManagementType('dissociated')">

                                <!-- Stock dissocié -->

                                <tr *ngIf="offer.publics.includes('adult')">
                                    <td><img src="assets/images/adult-icon.svg" alt="adult">{{ 'adult.value' | translate }}</td>
                                    <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ offerDate.totalAdultAllocatedStock }}</td>
                                    <td>{{ offerDate.totalAdultSoldStock }}</td>
                                    <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ offerDate.totalAdultRemainingStock }}</td>
                                </tr>

                                <tr *ngIf="offer.publics.includes('child')">
                                    <td><img src="assets/images/child-icon.svg" alt="child">{{ 'child.value' | translate }}</td>
                                    <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ offerDate.totalChildAllocatedStock }}</td>
                                    <td>{{ offerDate.totalChildSoldStock }}</td>
                                    <td *ngIf="!offerDate.unlimitedStock else unlimitedStock;">{{ offerDate.totalChildRemainingStock }}</td>
                                </tr>

                            </ng-container>

                        </tbody>
                    </table>

                </div>

            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="isConfigurationFieldConfigured('releaseDate')">

        <div class="form_part">

            <!-- Relâchement des allotements -->

            <p class="title_part">{{ 'allocation.plural.release.value' | translate}}</p>

            <div class="ligne_form row marges">

                <div class="col_12">
                    <p [innerHTML]="'allocation.plural.release.action.value' | translate: { day: offerDate.releaseDate }"></p>
                </div>
            </div>
        </div>

    </ng-container>

    <div class="form_part">

        <!-- Réservations confirmées -->

        <p class="title_part">{{ 'booking.booked.plural.value' | translate}}</p>

        <div class="ligne_form row marges">

            <div class="col_12" *ngFor="let booking of bookings; trackBy: identifyBooking">
                - {{ booking.reference }} /
                <strong>{{ booking.customer.firstName }} {{ booking.customer.lastName }}</strong>
                <ng-container *ngIf="booking.composition.nbAdult">
                    &nbsp;/ {{ 'booking.read.fields.adult.count.value' | translate }}
                    : {{ booking.composition.nbAdult }}</ng-container>
                <ng-container *ngIf="booking.composition.nbChild">
                    &nbsp;| {{ 'booking.read.fields.child.count.value' | translate }}
                    : {{ booking.composition.nbChild }}</ng-container>
            </div>

            <ng-container *ngIf="!bookings.length">
                <div class="col_12">
                    <p>{{ 'booking.booked.find.noResult.value' | translate }}</p>
                </div>
            </ng-container>

        </div>

        <div class="ligne_form row marges more_booking" *ngIf="hasMoreBookings">
            <div class="col_12 x_center">
                <button class="btn_cta btn_little" type="button" (click)="loadMoreBookings()">
                    {{ 'booking.more.value' | translate }}
                </button>
            </div>
        </div>
    </div>

</ng-container>
