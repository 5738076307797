import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {OfferOption} from "@core/shared/models/offer/offer-option";

@Injectable()
export class OfferOptionSelectionService {

    public items$: BehaviorSubject<OfferOption[]> = new BehaviorSubject([]);

    public addItem(item: OfferOption): void {

        const items: OfferOption[] = this.items$.getValue();

        items.push(item);

        this.items$.next(items);
    }

    public removeItem(item: OfferOption): void {

        const items: OfferOption[] = this.items$.getValue();

        const index: number = items.findIndex((i: OfferOption): boolean => {

            return i.id === item.id;
        });

        items.splice(index, 1);

        this.items$.next(items);
    }

    public hasItem(item: OfferOption): boolean {

        const match: OfferOption = this.items.find((i: OfferOption): boolean => {

            return i.id === item.id;
        });

        return !!match;
    }

    public getItemById(id: number): OfferOption {

        return this.items.find((item: OfferOption): boolean => {

            return item.id === id;
        });
    }

    public clearItems(): void {

        this.items$.next([]);
    }

    get items(): OfferOption[] {

        return this.items$.getValue();
    }
}
