<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'promotionService.add.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'promotionService.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit" [disabled]="form.invalid">
                    {{ 'form.submit.action.value' | translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'offer.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalData>

    <p> {{ 'promotionService.add.admin.value' | translate }}</p>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form">

            <div class="col_6">

                <!-- Service -->

                <div class="form-group">
                    <app-select-search [config]="fieldCollection.getFieldConfig('service')" [form]="form" (valueChange)="updateService($event)"></app-select-search>
                </div>

            </div>

        </div>

        <ng-container *ngIf="form.get('service').value">

            <div class="row marges ligne_form">

                <div class="col_6">

                    <!-- Code -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="code" id="code" required="required">
                        <label for="code">{{'promotionService.code.value' | translate}}</label>
                        <mat-error *ngIf="form.get('code').errors && (form.get('code').dirty || form.get('code').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('code').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>

            </div>

            <div class="row marges ligne_form" *ngIf="serviceQuantities.length > 1">

                <div class="col_6">

                    <!-- Quantité -->

                    <div class="form-group">
                        <select class="form-control" id="quantity" formControlName="quantity" required="required">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let quantity of serviceQuantities;" [ngValue]="quantity">{{ quantity }}</option>
                        </select>
                        <label for="quantity">{{'promotionService.quantity.value' | translate}}</label>
                        <mat-error *ngIf="form.get('quantity').errors && (form.get('quantity').dirty || form.get('quantity').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('quantity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>

            </div>

            <div class="row marges ligne_form">

                <div class="col_6">

                    <!-- Utilisateur -->

                  <ng-container *ngIf="fieldCollection.getField('society')">

                      <div class="form-group">
                          <app-select-search [config]="fieldCollection.getFieldConfig('society')" [form]="form"></app-select-search>
                      </div>

                  </ng-container>

                </div>

            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6 y_items_center">
                    <div class="row">
                        <div class="col_12">

                            <div class="row marges ligne_form">
                                <div class="col_12">

                                    <!-- Fuseau horaire -->

                                    <div class="form-group">
                                        <app-core-timezone-select [form]="form" [required]="true"></app-core-timezone-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <div class="row marges">
                                        <div class="col_6">

                                            <!-- Date de début -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="dateStartPicker" formControlName="dateStart" (click)="dateStartPicker.open()" id="dateStart" placeholder=" ">
                                                <label for="dateStart">{{'promotion.date.start.value' | translate}}</label>
                                                <mat-datepicker #dateStartPicker></mat-datepicker>
                                                <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('dateStart').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="form.get('dateStart').errors.startAfter">{{ 'form.control.error.dateStart.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                        <div class="col_6">

                                            <!-- Heure de début -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput [placeholder]=" " aria-label="24hr format" formControlName="timeStart" [ngxTimepicker]="fullTimeStart" [format]="24" readonly>
                                                <label>{{ 'promotion.time.start.value' | translate }}</label>
                                                <ngx-material-timepicker #fullTimeStart></ngx-material-timepicker>
                                                <mat-error *ngIf="form.get('timeStart').errors && (form.get('timeStart').dirty || form.get('timeStart').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('timeStart').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <div class="row marges">

                                        <div class="col_6">

                                            <!-- Date de fin -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="dateEndPicker" formControlName="dateEnd" (click)="dateEndPicker.open()" id="dateEnd" placeholder=" ">
                                                <label for="dateEnd">{{'promotion.date.end.value' | translate}}</label>
                                                <mat-datepicker #dateEndPicker></mat-datepicker>
                                                <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('dateEnd').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="form.get('dateEnd').errors.startBefore">{{ 'form.control.error.dateEnd.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                        <div class="col_6">

                                            <!-- Heure de fin -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput [placeholder]=" " aria-label="24hr format" formControlName="timeEnd" [ngxTimepicker]="fullTimeEnd" [format]="24" readonly>
                                                <label>{{ 'promotion.time.end.value' | translate }}</label>
                                                <ngx-material-timepicker #fullTimeEnd></ngx-material-timepicker>
                                                <mat-error *ngIf="form.get('timeEnd').errors && (form.get('timeEnd').dirty || form.get('timeEnd').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('timeEnd').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="row marges ligne_form">

                                <div class="col_12">
                                    <div class="form-group">
                                        <span class="message-help"><mat-icon>info</mat-icon>
                                            {{ 'promotion.service.dates.help.value' | translate }}</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="service.hasAdmissionPrice">

                <div class="row marges ligne_form">
                    <div class="col_6">
                        <div class="border">
                            <p class="title">{{'promotionService.admissionPriceDiscount.value' | translate }}</p>
                            <div class="col_12">
                                <div class="form-group margin-bottom">
                                    <div [formGroup]="form" class="app-toggle">
                                        <mat-slide-toggle formControlName="admissionPriceFree">{{ 'promotionService.admissionPriceFree.value' | translate}}</mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="col_12" *ngIf="!form.get('admissionPriceFree').value">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="admissionPriceDiscount" id="admissionPriceDiscount">
                                    <label for="admissionPriceDiscount">{{'promotionService.admissionPriceDiscountValue.value' | translate}}</label>
                                    <mat-error>
                                        <div *ngIf="form.get('admissionPriceDiscount').invalid && (form.get('admissionPriceDiscount').dirty || form.get('admissionPriceDiscount').touched)">
                                            <div *ngIf="form.get('admissionPriceDiscount').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="form.get('admissionPriceDiscount').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="form.get('admissionPriceDiscount').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="service.isWithSubscription">

                <div class="row marges ligne_form">
                    <div class="col_6">
                        <div class="border">
                            <p class="title">{{'promotionService.subscriptionDiscount.value' | translate }}</p>
                            <div class="col_12">
                                <div class="form-group margin-bottom">
                                    <div [formGroup]="form" class="app-toggle">
                                        <mat-slide-toggle formControlName="subscriptionFree">{{ 'promotionService.subscriptionFree.value' | translate}}</mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="col_12" *ngIf="!form.get('subscriptionFree').value">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="subscriptionDiscount" id="subscriptionDiscount">
                                    <label for="subscriptionDiscount">{{'promotionService.subscriptionDiscountValue.value' | translate}}</label>
                                    <mat-error>
                                        <div *ngIf="form.get('subscriptionDiscount').invalid && (form.get('subscriptionDiscount').dirty || form.get('subscriptionDiscount').touched)">
                                            <div *ngIf="form.get('subscriptionDiscount').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="form.get('subscriptionDiscount').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="form.get('subscriptionDiscount').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_6">
                        <div class="border">
                            <p class="title">{{'promotionService.applicationCriteria.value' | translate }}</p>

                            <div class="form-group">
                                <label>{{'promotionService.subscriptionPeriodicity.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" value="M" formControlName="periodicity" class="form-control" id="periodicity-month">
                                    <label for="periodicity-month">{{'form.user.subscription.fields.periodicity.month.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" value="Y" formControlName="periodicity" class="form-control" id="periodicity-year">
                                    <label for="periodicity-year">{{'form.user.subscription.fields.periodicity.year.value' | translate}}</label>
                                </div>
                                <mat-error *ngIf="form.get('periodicity').errors && (form.get('periodicity').dirty || form.get('periodicity').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('periodicity').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                            <ng-container *ngIf="form.get('periodicity').value !== null">

                                <div class="form-group">
                                    <label>{{'promotionService.subscriptionDiscountDuration.value' | translate}}</label>
                                    <select formControlName="subscriptionDiscountDuration">
                                        <option [ngValue]="null">{{ 'periodicity.duration.unlimited.value' | translate }}</option>
                                        <option *ngFor="let duration of durations" [ngValue]="duration.id">
                                            {{ duration.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'promotion.service.duration.help.value' | translate }}</span>
                                </div>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>

    </ng-container>

</ng-template>
