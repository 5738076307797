import {Role} from "@core/shared/models/role";

export type PromotionPublicType = 'customer' | 'distributor' | 'networkProvider';

export interface PromotionPublicItem {

    label: string;

    value: PromotionPublicType;

    allowedRoles: Role[];

    roleFilterMode: 'every' | 'some';
}

export const PROMOTION_PUBLIC_ITEMS: PromotionPublicItem[] = [
    {
        value: 'customer',
        label: 'promotion.public.customer.value',
        allowedRoles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'],
        roleFilterMode: 'some'
    },
    {
        value: 'distributor',
        label: 'promotion.public.distributor.value',
        allowedRoles: ['ROLE_OFFER_CREATOR'],
        roleFilterMode: 'every'
    },
    {
        value: 'networkProvider',
        label: 'promotion.public.networkProvider.value',
        allowedRoles: ['ROLE_OFFER_CREATOR'],
        roleFilterMode: 'every'
    }
];
