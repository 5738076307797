import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SocietyProvider} from "@core/shared/models/society/society-provider";
import {Address} from "@core/shared/models/address";
import {Country, CountryTranslation} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {Observable} from "rxjs";
import {Offer} from "@core/shared/models/offer";
import {OfferService} from "@core/shared/services/offer.service";
import {Society} from "@core/shared/models/society";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {TranslationService} from "@core/shared/services/translation.service";
import {
    DEFAULT_GROUP_DAY,
    DEFAULT_GROUP_STAY,
    DEFAULT_INDIVIDUAL_DAY,
    DEFAULT_INDIVIDUAL_STAY,
    SocietyCommission
} from "@core/shared/models/society-commission";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-page-society-provider-read',
    templateUrl: './page-society-provider-read.component.html',
    styleUrls: ['./page-society-provider-read.component.scss']
})
export class PageSocietyProviderReadComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    public society: Society;

    public item: SocietyProvider;

    public countries: Country[] = [];

    public associatedOffers$: Observable<Offer[]>;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _countryService: CountryService,
        private _offerService: OfferService,
        private _translateService: TranslateService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._initCountries();

        this._activatedRoute.data.subscribe((data: { society: Society, societyProvider: SocietyProvider }): void => {

            this.society = data.society;

            this.item = data.societyProvider;

            this._initAssociatedOffers();
        });
    }

    ngAfterViewInit(): void {

        this._activatedRoute.fragment.subscribe((fragment: ('availableOffers')): void => {

            switch (fragment) {

                case 'availableOffers':

                    this.tabGroup.selectedIndex = 1;

                    break;
            }
        });
    }

    private _initCountries(): void {

        this._countryService.getItemsAPI().subscribe((countries: Country[]): void => {

            this.countries = countries;
        });
    }

    private _initAssociatedOffers(): void {

        const params: string[] = [
            `providerSocieties.id[in][]=${this.item.provider.id}`
        ];

        this.associatedOffers$ = this._offerService.getItemsSocietyAPI(this.society.id, params);
    }

    public redirectToList(): void {

        this._router.navigate(['account/society/provider/list']);
    }

    get countryTranslation(): CountryTranslation {

        const item: Country = this.countries.find((country: Country): boolean => {

            return country.code === this.mailingAddress.country;
        });

        if(!item){

            return null;
        }

        return this.translationService.getFallbackTranslation(item.translations);
    }

    get mailingAddress(): Address {

        return this.item.provider.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';
        });
    }

    get commissionIndividualStay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.item.provider.commission;

        const value: string = commission.type === 'custom' ? (commission.individualStay * 100).toFixed(2) : DEFAULT_INDIVIDUAL_STAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get commissionIndividualDay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.item.provider.commission;

        const value: string = commission.type === 'custom' ? (commission.individualDay * 100).toFixed(2) : DEFAULT_INDIVIDUAL_DAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get commissionGroupStay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.item.provider.commission;

        const value: string = commission.type === 'custom' ? (commission.groupStay * 100).toFixed(2) : DEFAULT_GROUP_STAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get commissionGroupDay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.item.provider.commission;

        const value: string = commission.type === 'custom' ? (commission.groupDay * 100).toFixed(2) : DEFAULT_GROUP_DAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get incomingPayment(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.item.provider.commission;

        return this._translateService.instant(`${commission.incomingPayment ? 'yes' : 'no'}.value`);
    }

    get isAvailableCommission(): boolean {

        return this.item.provider.commission !== null;
    }
}
