<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">

        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ 'collaborator.create.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'collaborator.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit" [disabled]="form.invalid">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>

        </div>

    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'collaborator.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'collaborator.roles.value' | translate">
            <ng-container *ngTemplateOutlet="rolesData"></ng-container>
        </mat-tab>
    </mat-tab-group>
</form>

<!-- Données générales -->

<ng-template #generalData>
    <ng-container [formGroup]="form">
        <div class="row marges ligne_form">

            <div class="col_12">

                <!-- Civilité -->

                <div class="form-group">
                    <label>{{'form.user.fields.civility.title.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="1" formControlName="civility" class="form-control" id="civility-mister">
                        <label for="civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="2" formControlName="civility" class="form-control" id="civility-madam">
                        <label for="civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                    </div>
                    <mat-error *ngIf="form.get('civility').errors && (form.get('civility').dirty || form.get('civility').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">

            <div class="col_12 col_md_4">

                <!-- Nom -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName" required="required">
                    <label for="lastName">{{'form.user.fields.lastname.value' | translate}}</label>
                    <mat-error *ngIf="form.get('lastName').errors && (form.get('lastName').dirty || form.get('lastName').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
            <div class="col_12 col_md_4">

                <!-- Prénom -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName" required="required">
                    <label for="firstName">{{'form.user.fields.firstname.value' | translate}}</label>
                    <mat-error *ngIf="form.get('firstName').errors && (form.get('firstName').dirty || form.get('firstName').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
            <div class="col_12 col_md_4">

                <!-- Email -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="userEmail" required="required">
                    <label for="userEmail">{{'form.user.fields.email.contact.value' | translate}}</label>
                    <mat-error *ngIf="form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12 col_md_4">

                <!-- Téléphone fixe -->

                <div class="form-group">

                    <app-core-intl-tel-input [formGroup]="form" [controlName]="'switchboardPhone'" customPlaceholder="{{'form.user.fields.switchboardPhone.value' | translate}}"></app-core-intl-tel-input>

                    <mat-error *ngIf="form.get('switchboardPhone').errors && (form.get('switchboardPhone').dirty || form.get('switchboardPhone').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('switchboardPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('switchboardPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
            <div class="col_12 col_md_4">

                <!-- Ligne directe -->

                <div class="form-group">

                    <app-core-intl-tel-input [formGroup]="form" [controlName]="'directPhone'" customPlaceholder="{{'form.user.fields.directPhone.value' | translate}}"></app-core-intl-tel-input>

                    <mat-error *ngIf="form.get('directPhone').errors && (form.get('directPhone').dirty || form.get('directPhone').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('directPhone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('directPhone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
            <div class="col_12 col_md_4">

                <!-- Portable -->

                <div class="form-group">

                    <app-core-intl-tel-input [formGroup]="form" [controlName]="'cellphone'" customPlaceholder="{{'form.user.fields.cellphone.value' | translate}}"></app-core-intl-tel-input>

                    <mat-error *ngIf="form.get('cellphone').errors && (form.get('cellphone').dirty || form.get('cellphone').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('cellphone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('cellphone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <app-core-timezone-select [form]="form" [required]="true"></app-core-timezone-select>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Service -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="service" id="service">
                    <label for="service">{{'collaborator.list.fields.service.value' | translate}}</label>
                    <mat-error *ngIf="form.get('service').errors &&(form.get('service').dirty || form.get('service').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('service').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <div class="checkbox">
                        <input type="checkbox" id="isAddressLikePrincipalAccount" [(ngModel)]="isAddressLikePrincipalAccount" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleAddress()">
                        <label for="isAddressLikePrincipalAccount">{{ 'user.address.likePrincipalAccount.apply.action.value' | translate }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div formGroupName="address" class="row marges ligne_form">

            <div class="col_12">

                <div class="row marges ligne_form">
                    <div class="col_6 y_items_center">
                        <app-core-google-places-location [fields]="getAddressLocationFields(addressForm)"></app-core-google-places-location>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Adresse -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                            <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                            <mat-error
                                    *ngIf="addressForm.get('address').errors && (addressForm.get('address').dirty || addressForm.get('address').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="addressForm.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Complément d'adresse -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                            <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">

                        <!-- Code postal -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode" required="required">
                            <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                            <mat-error
                                    *ngIf="addressForm.get('zipcode').errors && (addressForm.get('zipcode').dirty || addressForm.get('zipcode').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="addressForm.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="addressForm.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Ville -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city" required="required">
                            <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                            <mat-error
                                    *ngIf="addressForm.get('city').errors && (addressForm.get('city').dirty || addressForm.get('city').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="addressForm.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">

                        <!-- Region -->

                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region" required="required">
                            <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                            <mat-error
                                    *ngIf="addressForm.get('region').invalid && (addressForm.get('region').dirty || addressForm.get('region').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="addressForm.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Pays -->

                        <div class="form-group">
                            <select class="form-control" id="country" formControlName="country" required="required">
                                <option value="" disabled selected>{{ 'form.user.fields.country.value' | translate }}</option>
                                <option *ngFor="let country of countries$ | async" [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                            </select>
                            <label class="required" for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                            <mat-error
                                    *ngIf="addressForm.get('country').invalid && (addressForm.get('country').dirty || addressForm.get('country').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="addressForm.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>


            </div>

        </div>

        <div class="row marges ligne_form">
            <div class="col_12 col_md_6">

                <!-- Locale -->
                <div class="form-group">
                    <select class="form-control" id="locale" formControlName="locale" required="required">
                        <option value=""></option>
                        <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                            {{ locale.label | translate }}
                        </option>
                    </select>
                    <label class="required" for="locale">{{ 'form.user.fields.locale.value' | translate }}</label>
                    <mat-error *ngIf="form.get('locale').errors && (form.get('locale').dirty || form.get('locale').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>

                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12 col_md_12">
                <!-- Commentaire -->

                <div class="form-group">
                    <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                    <label for="comment">{{'comment.value' | translate}}</label>
                </div>
            </div>
        </div>
    </ng-container>

</ng-template>


<!-- Gestion des droits -->

<ng-template #rolesData>
    <ng-container [formGroup]="form">
        <div class="row marges ligne_form" >
            <div class="col_12">
                <div class="form-group">
                    <button type="button" (click)="allCheck()">{{'collaborator.actions.allChecked.value' | translate }}</button> |
                    <button type="button" (click)="noneCheck()">{{'collaborator.actions.noneChecked.value' | translate }}</button>
                    <div class="checkbox" *ngFor="let access of accessesList" [style.display]="isAccessToHide(access) ? 'none' : 'block'">
                        <p class="title" *ngIf="getTitleRole(access, true)" style="font-weight:bold;margin-top:10px;">{{ getTitleRole(access, false)+'.value' | translate }}</p>
                        <input type="checkbox" value="{{access}}" class="form-control" [checked]="hasAccess(access)" [attr.disabled]="(isAutoAssignedAccess(access) || isAutoRevokedAccess(access)) ? true : null" (change)="patchAccessesValue(access)" id="role_{{access.id}}">
                        <label for="role_{{access.id}}">{{ access.label | translate}}</label>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
