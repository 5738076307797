<!-- Sélection -->

<ng-container *ngIf="!hideSelection">

    <div class="ligne_form">
        <div class="row marges" *ngIf="selectedProviders && selectedProviders.length">
            <div class="col y_items_center">
                <div class="selected_offers">
                    <span>{{ 'offer.provider.selection.list.value' | translate }} :</span>
                    <ul>
                        <li *ngFor="let provider of selectedProviders">{{ provider.name }}<mat-icon (click)="removeProviderSelection(provider)">close</mat-icon></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</ng-container>

<!-- Recherche -->

<div class="ligne_form" [formGroup]="providersForm">
    <div class="form-group">
        <mat-select placeholder="{{'offer.form.fields.provider.actions.choose.value' | translate}}" [formControlName]="formName" (closed)="handleClosure()" multiple="true" [compareWith]="compareProvider">
            <mat-option>
                <ngx-mat-select-search #nameFilter [formControl]="optionFilterNameCtrl"
                                       [placeholderLabel]="'offer.form.fields.provider.actions.search.name.value' | translate"
                                       [noEntriesFoundLabel]="'offer.form.fields.provider.actions.search.result.no.value' | translate"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option disabled="true">{{'offer.form.fields.provider.actions.options.value' | translate}}</mat-option>
            <ng-container *ngIf="(filteredOptions | async) as options">
                <mat-option #matOption *ngFor="let option of options" [value]="option" (click)="handleUpdate(matOption)">
                    {{ option.name }} | {{ getMailingAddress(option)?.address }}, {{ getMailingAddress(option)?.zipcode }} {{ getMailingAddress(option)?.city }}, {{ getCountryTranslation(option)?.name }}
                </mat-option>
            </ng-container>
        </mat-select>
    </div>
</div>




