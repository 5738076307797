import {InvoiceTypeItem} from "@core/shared/models/invoice";

export const INVOICE_TYPE_ITEMS: InvoiceTypeItem[] = [
    {
        id: 'subscription',
        label: `invoice.type.subscription.value`
    },
    {
        id: 'service',
        label: `invoice.type.service.value`
    },
    {
        id: 'booking_commission',
        label: `invoice.type.booking_commission.value`
    },
    {
        id: 'gift_voucher_commission',
        label: `invoice.type.gift_voucher_commission.value`
    },
    {
        id: 'society_commission',
        label: `invoice.type.society_commission.value`
    },
    {
        id: 'service_using_automatic_translation',
        label: `invoice.type.service_using_automatic_translation.value`
    }
];

export const INVOICE_CUSTOMER_TYPE_ITEMS: InvoiceTypeItem[] = [
    {
        id: 'gift_voucher',
        label: `invoice.type.gift_voucher.value`
    },
    {
        id: 'gift_voucher_renewal',
        label: `invoice.type.gift_voucher_renewal.value`
    }
];
