import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SocietyWebsite} from "@core/shared/models/society/society-website";
import {ApiService} from "@core/shared/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class WebsiteService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient)
    {}

    public getSocietyItemsAPI(id: number, params?: string[]): Observable<SocietyWebsite[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/websites`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<SocietyWebsite[]>(url, {
            headers: headers
        });
    }

    public updateSocietyItemsAPI(id: number, data: Partial<SocietyWebsite>[]): Observable<SocietyWebsite[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/websites`;

        return this._httpClient.post<SocietyWebsite[]>(url, data, {
            headers: headers
        });
    }
}
