import {
    AfterViewInit,
    Component,
    DoCheck,
    EventEmitter,
    Input, KeyValueDiffer, KeyValueDiffers,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import {ReplaySubject, Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MatLegacySelect as MatSelect} from "@angular/material/legacy-select";

/**
 * Configuration paramètres du champ
 */
export interface SelectSearchConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        label?: string;
        choices?: OptionsSelectConfig[];
        callbackApi?: string;
        callbackData?: object;
        multiple?: boolean;
        groupped?: boolean;
        canCheckGroup?: boolean;
        help?: string;
    };
}

export interface OptionsSelectConfig {
    id: number | string;
    name: string;
    choices?: object;
}

/**
 * Champ select
 */
@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

    /**
     * Paramètres du champ
     */
    @Input() public config: SelectSearchConfig = {id: null, attrs: {}};

    /**
     * Formulaire
     */
    @Input() public form: UntypedFormGroup;

    @Input() public value: string;

    /**
     * Événement si la valeur change
     */
    @Output() public valueChange: EventEmitter<any> = new EventEmitter();

    @Output() public keypressSearch: EventEmitter<any> = new EventEmitter();

    @Input() public disabled: boolean;

    private differ: KeyValueDiffer<any, any>;

    /** list of banks */
    protected valuesOptions: OptionsSelectConfig[];

    /** control for the selected bank */
    public selectCtrl: UntypedFormControl = new UntypedFormControl();

    /** control for the MatSelect filter keyword */
    public optionFilterCtrl: UntypedFormControl = new UntypedFormControl();

    /** list of banks filtered by search keyword */
    public filteredOptions: ReplaySubject<object[]> = new ReplaySubject<object[]>(1);

    @ViewChild('singleSelect', {static: true}) public singleSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy: Subject<void> = new Subject<void>();

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor(private _http: HttpClient, private differs: KeyValueDiffers) {

        this.differ = this.differs.find(this.config).create();
    }

    /**
     *
     * @param apiUrl
     * @param apiData
     */
    public getData(apiUrl: string, apiData: object): any[] {
        const data: object[] = [];
        this._http.get<any>(`${apiUrl}`).subscribe((d: []) => {
            d['hydra:member'].forEach((elem: any) => {
                let newData: object = {};
                newData = {'id': '' + elem[apiData['key']], 'name': elem[apiData['value']]};
                data.push(newData);
            });

            this.initValues();
        });

        return data;
    }

    /**
     *
     * @param apiUrl
     * @param apiData
     */
    public getGrouppedData(apiUrl: string, apiData: object): void {
        const data: [] = [];

        this._http.get<any>(`${apiUrl}`).subscribe((d: []) => {
            d['hydra:member'].forEach((elem: []) => {
                const groupId: string = elem[apiData['groupBy']['field']]['id'];
                const groupName: string = elem[apiData['groupBy']['field']][apiData['groupBy']['label']];

                if (!data[groupId]) {
                    data[groupId] = {
                        name: groupName,
                        choices: []
                    };
                }

                data[groupId]['choices'].push({'id': '' + elem[apiData['key']], 'name': elem[apiData['value']]});
            });

            const result: [] = [];
            for (const k in data) {
                if (data.hasOwnProperty(k)) {
                    result.push(data[k]);
                }
            }
            this.config.attrs.choices = result;
            this.initValuesGroupped();

        });
    }

    public ngOnInit(): void {
        if (!this.config.attrs.multiple) {
            this.config.attrs.multiple = false;
        }


        if (this.config.attrs && this.config.attrs.groupped) {


            this.getGrouppedData(this.config.attrs.callbackApi, this.config.attrs.callbackData);
        } else if (this.config.attrs && this.config.attrs.callbackApi) {
            this.config.attrs.choices = this.getData(this.config.attrs.callbackApi, this.config.attrs.callbackData);
            this.initValues();
        } else {
            this.initValues();
        }

        this.initForm();


    }

    public initValues(): void {
        this.valuesOptions = this.config.attrs.choices;
        this.filteredOptions.next(this.valuesOptions.slice());

        this.optionFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOptions();
            });

        this.filterOptions();
    }

    public initValuesGroupped(): void {
        this.valuesOptions = this.config.attrs.choices;

        this.filteredOptions.next(this.copyGroups(this.valuesOptions));

        this.optionFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOptionsGroupped();
            });



    }


    public selectAllGroup(list: [], event: Event): void {

        // @ts-ignore
        const isChecked: boolean = event.currentTarget.checked;

        let actualValue: any = this.form.get(this.config.id).value;

        if(actualValue === undefined){
            actualValue = [];
        }

        list.forEach((item: OptionsSelectConfig) => {
            const allReadyCkecked: OptionsSelectConfig = actualValue.find((x: any) => x === item.id);

            if (isChecked) {
                if (allReadyCkecked === undefined) {
                    actualValue.push(item.id);
                }
            } else {
                if (allReadyCkecked !== undefined) {
                    const index: number = actualValue.findIndex((x: any) => x === item.id);
                    actualValue.splice(index, 1);
                }
            }
        });

        this.form.get(this.config.id).patchValue(actualValue)
    }

    public initForm(): void {
        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, this.selectCtrl);

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        this.form.controls[this.config.id].setValidators(validators);

        if(this.disabled){

            this.form.controls[this.config.id].disable();
        }
    }

    public ngAfterViewInit(): void {
        this.setInitialValue();
    }

    public ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * Sets the initial value after the filteredBanks are loaded initially
     */
    protected setInitialValue(): void {
        this.filteredOptions
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                if (this.singleSelect !== undefined) {
                    this.singleSelect.compareWith = (a: OptionsSelectConfig, b: OptionsSelectConfig): boolean => a && b && a.id === b.id;
                }
            });
    }

    protected filterOptions(): void {

        if (!this.valuesOptions) {
            return;
        }
        let search: string = this.optionFilterCtrl.value;

        if (!search) {
            this.filteredOptions.next(this.valuesOptions);
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredOptions.next(
            this.valuesOptions.filter((valuesOptions: any) => valuesOptions.name.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterOptionsGroupped(): void {
        if (!this.valuesOptions) {
            return;
        }
        let search: string = this.optionFilterCtrl.value;
        const groupsCopy: OptionsSelectConfig[] = this.copyGroups(this.valuesOptions);

        if (!search) {
            this.filteredOptions.next(groupsCopy);
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOptions.next(
            groupsCopy.filter((group: any) => {
                const showGroup: boolean = group.name.toLowerCase().indexOf(search) > -1;
                if (!showGroup) {
                    group.choices = group.choices.filter((gr: any) => gr.name.toLowerCase().indexOf(search) > -1);
                }
                return group.choices.length > 0;
            })
        );
    }

    public selectChange(event: Event): void {
        this.valueChange.emit(event);
    }

    public ngDoCheck(): void {
        const changes: any = this.differ.diff(this.config.attrs);

        if (changes) {
            changes.forEachChangedItem((r: any) => {
                if (r.key == 'choices') {
                    this.config.attrs.choices = r.currentValue;
                    this.initValues();
                }
                if (r.key == 'callbackApi') {
                    this.value = '';
                    this.config.attrs.choices = this.getData(this.config.attrs.callbackApi, this.config.attrs.callbackData);
                    this.initValues();
                }
            });
        }
    }

    protected copyGroups(groups: OptionsSelectConfig[]): OptionsSelectConfig[] {
        const groupsCopy: any = [];
        groups.forEach((group: OptionsSelectConfig) => {
            groupsCopy.push({
                'name': group.name,
                //@ts-ignore
                'choices': group.choices.slice()
            });
        });
        return groupsCopy;
    }

}
