import {AfterContentInit, Directive, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {parseMarkup} from "@core/shared/utils/markup";

@Directive({
    selector: '[appInputTextParseMarkup]'
})
export class InputTextParseMarkupDirective implements AfterContentInit {

    @Input()
    control: AbstractControl;

    constructor() {
    }

    @HostListener('blur') onBlur() {

        this._apply();
    }

    ngAfterContentInit(): void {

        this._apply();
    }

    private _apply(): void {

        if (!this.control.value) {

            return;
        }

        setTimeout((): void => {

            this.control.patchValue(parseMarkup(parseFloat(this.control.value)));
        });
    }

}
