import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {Data} from "@lib/cms/cms";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslationBuilder} from "@core/shared/models/translation";
import {CategoryService} from "@core/shared/services/category.service";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Role} from "@core/shared/models/role";
import {FieldCollection} from "@lib/form/field";
import {OfferNetworkProvider} from "@core/shared/models/offer/offer-network-provider";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {Channel} from "@core/shared/models/channel";
import {OfferNetworkProviderService} from "@core/shared/services/offer/offer-network-provider.service";
import {Society} from "@core/shared/models/society";


@Component({
    selector: 'app-offer-group-network-provider-page-update',
    templateUrl: './page-offer-group-network-provider-update.component.html',
    styleUrls: ['./page-offer-group-network-provider-update.component.scss'],
    providers: [
        FormService
    ],
})
export class PageOfferGroupNetworkProviderUpdateComponent implements OnInit {

    public data: Data;

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public pages: Object[] = [];

    public roles : string[] = [];

    public fieldCollection = new FieldCollection();

    public channels: Object[] = [];

    public providers: {id: number, name: string}[] = [];

    public networkGroup: OfferGroupNetworkProvider;

    /**
     *
     * @param _translateService
     * @param _snackBar
     * @param _offerNetworkProviderService
     * @param _router
     * @param _formBuilder
     * @param formService
     * @param _apiService
     * @param _activatedRoute
     */
    public constructor(private _translateService: TranslateService, private _snackBar: MatSnackBar, public _offerNetworkProviderService: OfferNetworkProviderService, private _router: Router, private _formBuilder: UntypedFormBuilder, public formService: FormService, private _apiService: ApiService, private _activatedRoute: ActivatedRoute) {
        this.formService.form = this._formBuilder.group({
            label: ['', Validators.required],
            comment: [''],
            networkProviders: new UntypedFormArray([]),
        });

    }

    public ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { network: OfferGroupNetworkProvider, channels: Channel[], providers: Society[] }): void => {

            const pageDatas: any = {
                label:data.network.label,
                comment:data.network.comment,
                networkProviders : data.network.networkProviders
            };

            this.networkGroup = data.network;

            data.channels.forEach((channel: Channel) => {
                this.channels.push(
                    {
                        id:  channel.id,
                        name: channel.society.name + ' - ' + channel.translations[0].name
                    });
            });

            data.providers.forEach((provider: Society) => {
                this.providers.push(
                    {
                        id: provider.id,
                        name: provider.name + ' (' + provider.identificationNumber + ')'
                    });
            });

            this.form.patchValue(pageDatas);
        });

        this.formService.submitCallback = (): void => {

            const data: OfferGroupNetworkProvider = this.form.value;

            for (let i = 0; i < data.networkProviders.length; i++) {

                data.networkProviders[i].channels.forEach((channel, index) =>{
                    /** @ts-ignore **/
                    data.networkProviders[i].channels[index] = {'id' : channel};
                });

                data.networkProviders[i].providers.forEach((provider, index) =>{
                    /** @ts-ignore **/
                    data.networkProviders[i].providers[index] = {'id' : provider};
                });
            }

            this._offerNetworkProviderService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('networkProvider.edit.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    public changeSocieties(event){
        this.form.get('societies').patchValue(event.value);
    }

    public hasRole(role: Role): boolean {
        return this.roles.indexOf(role) >= 0;
    }



    public redirectToList(): void {

        this._router.navigate(['account/relationship/list'], { queryParams: { targetTab: 'provider'}});
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {

        return {
            id: 'published',
            attrs: {
                required: true,
                label: "Publié"
            }
        };
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get networkProviders(): OfferNetworkProvider[] {

        return (this.form.get('networkProviders').value as OfferNetworkProvider[]).filter((networkProvider: OfferNetworkProvider): boolean => {

           return !!networkProvider.id;

        });
    }

    public changePages(event){
        this.form.get('pages').patchValue(event.value);
    }
}
