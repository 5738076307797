import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ADDITIONAL_WEBSITE_SERVICE_LABEL, GIFT_VOUCHER_SERVICE_LABEL, Service, ServiceTranslation} from "@core/shared/models/service";
import {SocietyService as SocietyServiceModel} from "@core/shared/models/society-service";
import {parsePrice} from "@core/shared/utils/price";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {ServiceService} from "@core/shared/services/service.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {PaymentInitialize} from "@core/shared/models/payment-initialize";
import {PaymentServiceService} from "@core/shared/services/payment-service.service";
import {validateNumericOnly} from "@core/shared/utils/card-number";
import {concat, Observable, throwError} from "rxjs";
import {browserInfos} from "@core/shared/utils/browser-infos";
import {catchError, toArray} from "rxjs/operators";
import {TermsAndConditionsService} from "@core/shared/services/terms-and-conditions.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import { GiftVoucherConfigurationFormComponent } from "@core/components/gift-voucher/gift-voucher-configuration-form/gift-voucher-configuration-form.component";
import {SocietyService} from "@core/shared/services/society.service";
import {Role} from "@core/shared/models/role";
import {Card} from "@core/shared/models/card";
import {CardService} from "@core/shared/services/card.service";
import {AdditionalWebsiteConfigurationFormComponent} from "@core/components/additional-website/additional-website-configuration-form/additional-website-configuration-form.component";
import moment from "moment";

@Component({
    selector: 'app-service-subscription-update',
    templateUrl: './page-service-subscription-update.component.html',
    styleUrls: ['./page-service-subscription-update.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService
    ]
})
export class PageServiceSubscriptionUpdateComponent implements OnInit {

    @ViewChild(GiftVoucherConfigurationFormComponent) giftVoucherFormComponent: GiftVoucherConfigurationFormComponent;

    @ViewChild(AdditionalWebsiteConfigurationFormComponent) additionalWebsiteFormComponent: AdditionalWebsiteConfigurationFormComponent;

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('serviceData', {static: true}) serviceDataRef: TemplateRef<any>;

    @ViewChild('giftVoucherData', {static: true}) giftVoucherDataRef: TemplateRef<any>;

    @ViewChild('additionalWebsiteData', {static: true}) additionalWebsiteDataRef: TemplateRef<any>;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public societyServices: SocietyServiceModel[];

    public societyService: SocietyServiceModel;

    public societyServiceSummaries: SocietyServiceModel[] = [];

    public quantities: number[] = [];

    public cardAlreadyRegistered: boolean = false;

    public card$: Observable<Card>;

    public dateStart: Date;

    public cardRegistrationUrl: string;

    public paymentInitializeInProcess: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _societyService: SocietyService,
        private _matDialog: MatDialog,
        private _snackbar: MatSnackBar,
        private _activatedRoute: ActivatedRoute,
        private _translationService: TranslationService,
        private _translateService: TranslateService,
        private _serviceService: ServiceService,
        private _paymentServiceService: PaymentServiceService,
        private _userService: UserService,
        private _cardService: CardService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public formService: FormService,
        public termsAndConditionsService: TermsAndConditionsService
    ) {}

    ngOnInit(): void {

        this.dateStart = new Date();

        this._activatedRoute.data.subscribe((data: {societyService: SocietyServiceModel, societyServices: SocietyServiceModel[]}): void => {

            this.societyService = data.societyService;

            this.societyServices = data.societyServices;

            this._initTabItems();

            this._initForm();

            this._initEvents();

            this._initServiceSummaries().subscribe((data) => {

                this.societyServiceSummaries = data;
            });
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            acceptTermsAndConditions: [false, [Validators.requiredTrue]]
        });

        if (this.societyService.service.isWithSubscription) {

            this.form.addControl('periodicity', this._formBuilder.control(null, [Validators.required]))

            this.periodicityControl.patchValue(this.societyService.periodicity);
        }

        if (this.societyService.service.min !== this.societyService.service.max) {

            this.form.addControl('quantity', this._formBuilder.control(null, [Validators.required]))

            this.quantityControl.patchValue(this.societyService.quantity);

            this._initQuantities();
        }

        this.formService.submitCallback = (): void => {

            this._serviceService.createSocietyServiceItemAPI(this.createSocietyServiceData, this.currentUser.society.id).subscribe((societyService: SocietyServiceModel): void => {

                if(this.isPaymentRequired){

                    if (this.cardAlreadyRegistered) {

                        this._initPaymentValidate(null, societyService);

                    } else {

                        const dataToCardRegistration = `cardNumber=${this.creditCardControl.get('cardNumber').value.replace(/\s/g, '')}&cardExpirationDate=${this.creditCardControl.get('cardExpirationDate').value.replace('/', '')}&cardCvx=${this.creditCardControl.get('cardCvx').value}&accessKeyRef=${this.creditCardControl.get('accessKey').value}&data=${this.creditCardControl.get('data').value}`;

                        this._paymentServiceService.cardRegistrationUrl(this.cardRegistrationUrl, dataToCardRegistration).subscribe((data) => {

                            const dataToValidate = Object.assign({}, {
                                "data": data,
                                "id": this.creditCardControl.get("id").value,
                            });

                            this._initPaymentValidate(dataToValidate, societyService);
                        });
                    }
                }
                else{

                    this._snackbar.open(this._translateService.instant('service.management.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this._router.navigate([`account/service/list`]);
                }
            });
        }
    }

    private _initEvents(): void {

        const controls: AbstractControl[] = [];

        if(this.periodicityControl){

            controls.push(this.periodicityControl);
        }

        if(this.quantityControl){

            controls.push(this.quantityControl);
        }

        controls.forEach((control: AbstractControl): void => {

            control.valueChanges.subscribe((): void => {

                if(this.isPaymentRequired){

                    this._paymentInitialize();
                }
                else{

                    this.form.removeControl('creditCard');
                }

                const validControls: boolean = controls.every((item: AbstractControl): boolean => {

                    return item.valid;
                });

                if(validControls){

                    this._initServiceSummaries().subscribe((societyService: SocietyServiceModel[]): void => {

                        this.societyServiceSummaries = societyService;
                    });
                }
            });
        });
    }

    private _initCard(): void {

        this.card$ = this._cardService.getSocietyItem(this.currentUser.society.id);
    }

    private _paymentInitialize(): void {

        this.paymentInitializeInProcess = true;

        this._paymentServiceService.paymentInitialize(this.currentUser.society.id).subscribe((paymentInitialize: PaymentInitialize): void => {

            if ('id' in paymentInitialize) {

                this.cardRegistrationUrl = paymentInitialize.url;

                if(!this.creditCardControl){

                    this.form.addControl('creditCard', this.createCreditCardControl);
                }

                this.creditCardControl.get('accessKey').patchValue(paymentInitialize.accessKeyRef);

                this.creditCardControl.get('data').patchValue(paymentInitialize.data);

                this.creditCardControl.get('id').patchValue(paymentInitialize.id);
            }
            else {

                this.cardAlreadyRegistered = true;

                this._initCard();
            }

            this.paymentInitializeInProcess = false;
        });
    }

    private _initPaymentValidate(cardRegistration, societyService: SocietyServiceModel): void {

        const dataToValidate: object = {
            cardRegistration: cardRegistration,
            societyService: {
                id: societyService.id
            },
            browserInfo: browserInfos()
        };

        this._paymentServiceService.paymentValidate(this.currentUser.society.id, dataToValidate).pipe(catchError((err : Observable<any>) => {

            this.cardAlreadyRegistered = false;

            this._paymentInitialize();

            return throwError(this._translateService.instant('payment.error.card.value'));

        })).subscribe((data: { status: string, redirectUrl: string, paymentId: string }): void => {

            if (data.status === 'REDIRECT') {

                location.href = data.redirectUrl;
            }

            if (data.status === 'SUCCESS') {

                if(data.paymentId){

                    this._router.navigate([`account/service/payment-response/${this.service.id}/success/${data.paymentId}`]);
                }
                else{

                    this._router.navigate([`account/service/payment-response/${this.service.id}/success`]);
                }
            }
        });
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: 'serviceData',
                label: 'services.generalData.value',
                template: this.serviceDataRef
            }
        ];

        if(this.isOnlineSale && this.isAccountAdmin && this.hasRole('ROLE_OFFER_CREATOR')){

            this.tabItems.push({
                tag: 'giftVoucherData',
                label: 'services.giftVoucher.edit.value',
                template: this.giftVoucherDataRef
            });
        }

        if(this.isAdditionalWebsite && this.isAccountAdmin){

            this.tabItems.push({
                tag: 'additionalWebsiteData',
                label: 'services.additionalWebsite.edit.value',
                template: this.additionalWebsiteDataRef
            });
        }
    }

    private _initServiceSummaries(): Observable<SocietyServiceModel[]> {

        const arrayToForkJoin: Observable<SocietyServiceModel>[] = [];

        if (this.service.isWithSubscription){

            if(this.hasServicesInPeriodicity('M')){

                if(this.isPeriodicityUpdated || this.isQuantityUpdated){

                    arrayToForkJoin.push(this._serviceService.summarySocietyServiceItemAPI(this.initSocietyServiceData('M'), this.currentUser.society.id));
                }
                else{

                    const monthlyService: SocietyServiceModel = this.getServiceInPeriodicity('M');

                    arrayToForkJoin.push(this._serviceService.getSocietyServiceItemAPI(monthlyService.id));
                }
            }
            else{

                arrayToForkJoin.push(this._serviceService.summarySocietyServiceItemAPI(this.initSocietyServiceData('M'), this.currentUser.society.id));
            }

            if(this.hasServicesInPeriodicity('Y')){

                if(this.isPeriodicityUpdated || this.isQuantityUpdated){

                    arrayToForkJoin.push(this._serviceService.summarySocietyServiceItemAPI(this.initSocietyServiceData('Y'), this.currentUser.society.id));
                }
                else{

                    const yearlyService: SocietyServiceModel = this.getServiceInPeriodicity('Y');

                    arrayToForkJoin.push(this._serviceService.getSocietyServiceItemAPI(yearlyService.id));
                }
            }
            else{

                arrayToForkJoin.push(this._serviceService.summarySocietyServiceItemAPI(this.initSocietyServiceData('Y'), this.currentUser.society.id));
            }
        }
        else{

            arrayToForkJoin.push(this._serviceService.getSocietyServiceItemAPI(this.societyService.id));
        }

        return concat(
            ...arrayToForkJoin
        ).pipe(toArray());
    }

    private _initQuantities(): void {

        for (let quantity: number = this.societyService.service.min; quantity <= this.societyService.service.max; quantity++){

            this.quantities.push(quantity);
        }
    }

    public hasRole(role: Role): boolean {

        return this.currentUser.roles.indexOf(role) >= 0;
    }

    public initSocietyServiceData(periodicity: string): object {

        return {
            service: {
                id: this.service.id
            },
            periodicity: periodicity,
            quantity: (this.quantityControl && this.quantityControl.valid) ? this.quantityControl.value : this.service.min
        };
    }

    public servicesInPeriodicity(periodicity: ('M'|'Y')): SocietyServiceModel {

        return this.societyServices.find((societyService: SocietyServiceModel): boolean => {

            return societyService.isValid && societyService.periodicity === periodicity && societyService.service.isWithSubscription;
        });
    }

    public checkPeriodicity(periodicity: any) {

        if(!this.periodicityControl){

            return;
        }

        this.periodicityControl.patchValue(periodicity);
    }

    public getAdmissionPrice(index: number): number {

        return this.societyServiceSummaries[index].hasAdmissionPrice ? this.societyServiceSummaries[index].admissionPrice * (1 + this.societyServiceSummaries[index].vatPercent) : 0;
    }

    public getAmountTTC(index: number): string {

        let total: number = this.societyServiceSummaries[index].total;

        const vatPercent: number = this.societyServiceSummaries[index].vatPercent;

        total = total + (total * vatPercent);

        return parsePrice(total/100) + '€ TTC';
    }

    public formatPrice(price: number, symbol: string) {

        return parsePrice(price / 100) + ` € ${symbol}`;
    }

    public redirectToServices(): void {

        this._router.navigate(['account/service/list']);
    }

    public getServiceInPeriodicity(periodicity: ('M'|'Y')): SocietyServiceModel {

        return this.societyServices.find((data: SocietyServiceModel): boolean => {

            return (data.service.id === this.service.id) && data.isValid && data.periodicity === periodicity && data.service.isWithSubscription;
        });
    }

    public hasServicesInPeriodicity(periodicity: ('M'|'Y')): boolean {

        return this.societyServices.some((data: SocietyServiceModel): boolean => {

            return (data.service.id === this.service.id) && data.isValid && data.periodicity === periodicity && data.service.isWithSubscription;
        });
    }

    public openConfirmUpdateDialog(): void {

        if(this.form.invalid || this.paymentInitializeInProcess){

            return;
        }

        const title: string = this._translateService.instant('services.subscription.dialog.update.title.value');

        const content_text = this.isPaymentRequired ? 'services.subscription.dialog.update.contentPayment.value' : 'services.subscription.dialog.update.content.value';

        const content: string = this._translateService.instant(content_text, {title: this.serviceTranslation.name});

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._matDialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this.formService.submit();
        });
    }

    public hasReachInvoiceDate(societySummary: SocietyServiceModel): boolean {

        const today: moment.Moment = moment().startOf('day');

        const dateNextInvoice: moment.Moment = moment(societySummary.dateNextInvoice).startOf('day');

        return dateNextInvoice.isSame(today);
    }

    public calculatePrice(societySummary: SocietyServiceModel, symbol:  'TTC'|'HT'): number {

        const quantity = this.hasQuantity ? this.quantityControl.value : 1;

        switch (societySummary.periodicity) {

            case "M":
                return quantity * ((symbol === 'TTC')  ? societySummary.service.monthlyPriceTTC : societySummary.service.monthlyPriceHT);

            case "Y":

                return quantity * ((symbol === 'TTC')  ? societySummary.service.yearlyPriceTTC : societySummary.service.yearlyPriceHT);
        }
    }

    public calculateTVA(societySummary: SocietyServiceModel): number {

        const quantity = this.hasQuantity ? this.quantityControl.value : 1;

        switch (societySummary.periodicity) {

            case "M":
                return (quantity * (societySummary.service.monthlyPriceTTC - societySummary.service.monthlyPriceHT)) / 100;

            case "Y":

                return  (quantity * (societySummary.service.yearlyPriceTTC - societySummary.service.yearlyPriceHT)) / 100;
        }
    }

    get createCreditCardControl(): UntypedFormGroup {

        return this._formBuilder.group({
            cardNumber: [null, [Validators.required, (control: UntypedFormControl) => {
                let field = {...control}
                let cardNumber = field.value;
                cardNumber = (cardNumber) ? cardNumber.replace(/\s/g, "") : "";

                return validateNumericOnly(cardNumber) === false ?
                    {invalidNUmberCard: {valid: false}} : null;

            }]],
            cardExpirationDate: [null, [Validators.required, (control: UntypedFormControl) => {
                let field = {...control}
                let cardDate = field.value ? field.value.replace("/", "") : "";

                let currentDate = new Date();

                // Requires 2 digit for month and 2 digits for year
                if (cardDate.length === 4) {

                    let year = parseInt(cardDate.substr(2, 2), 10) + 2000;
                    let month = parseInt(cardDate.substr(0, 2), 10);

                    if (month > 0 && month <= 12) {

                        let currentYear = currentDate.getFullYear();
                        if (currentYear < year)
                            return null;

                        if (currentYear === year) {
                            let currentMonth = currentDate.getMonth() + 1;
                            if (currentMonth <= month)
                                return null;
                        }

                        // Date is in the past
                        return {invalidDatePast: {valid: false}};
                    }
                }

                // Date does not look correct
                return {invalidFormatDate: {valid: false}};

            }]],
            cardCvx: [null, [Validators.required, (control: UntypedFormControl) => {
                let field = {...control}
                let cvv = field.value ? field.value.trim() : "";

                if (validateNumericOnly(cvv) === true) {
                    if (cvv.length === 3) {
                        return null;
                    }
                }

                // Invalid format
                return {invalidFormatCvv: {valid: false}};

            }]],
            accessKey: [],
            data: [],
            id: []
        });
    }

    get creditCardControl(): UntypedFormArray {

        return this.form.get('creditCard') as UntypedFormArray;
    }

    get periodicityControl(): AbstractControl {

        return this.form.get('periodicity');
    }

    get quantityControl(): AbstractControl {

        return this.form.get('quantity');
    }

    get isPeriodicityUpdated(): boolean {

        return this.periodicityControl && (this.societyService.periodicity !== this.periodicityControl.value);
    }

    get isQuantityUpdated(): boolean {

        return this.quantityControl && (this.societyService.quantity !== this.quantityControl.value);
    }

    get serviceTranslation(): ServiceTranslation {

        return this._translationService.getFallbackTranslation(this.societyService.service.translations);
    }

    get service(): Service {

        return this.societyService.service;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isFormSubmitEnabled(): boolean {

        const conditions: boolean[] = [
            this.societyService.service.isWithSubscription || (this.societyService.service.min !== this.societyService.service.max),
            this.isPeriodicityUpdated || this.isQuantityUpdated
        ];

        return conditions.every((condition: boolean): boolean => {

            return Boolean(condition);
        });
    }

    get isPaymentRequired(): boolean {

        return !this.isPeriodicityUpdated && (this.quantityControl && (this.quantityControl.value > this.societyService.quantity));
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get createSocietyServiceData(): object {

        return {
            service: {
                id: this.service.id
            },
            periodicity: (this.periodicityControl && this.periodicityControl.valid) ? this.periodicityControl.value : 'M',
            quantity: (this.quantityControl && this.quantityControl.valid) ? this.quantityControl.value : this.service.min
        };
    }

    get hasQuantity(): boolean {

        return this.societyService.service.min !== this.societyService.service.max;
    }

    get isValidBilling(): boolean {

        if(!this.hasQuantity) {

            return true;
        }

        if(this.hasQuantity && !this.societyService.service.isWithSubscription){

            if((this.quantityControl && this.quantityControl.valid)) {

                return true;
            }
        }

        return (this.periodicityControl && this.periodicityControl.valid) && (this.quantityControl && this.quantityControl.valid);
    }

    get admissionPrice(): number {

        return this.selectedSocietyServiceSummary.hasAdmissionPrice ? this.selectedSocietyServiceSummary.admissionPrice * (1 + this.selectedSocietyServiceSummary.vatPercent) : 0;
    }

    get isDowngrade(): boolean {

        if(this.selectedSocietyServiceSummary){

            return  this.selectedSocietyServiceSummary.quantity < this.societyService.quantity && this.selectedSocietyServiceSummary.periodicity === this.societyService.periodicity;
        }
        return false;
    }

    get selectedSocietyServiceSummary(): SocietyServiceModel {

        const periodicity = this.service.isWithSubscription ? this.periodicityControl.value : 'M';

        return this.societyServiceSummaries.find((societyService: SocietyServiceModel) => {
            return periodicity === societyService.periodicity;
        });
    }

    get hasInformationsToShow(): boolean {

        const conditions: boolean[] = [
            !(this.isQuantityUpdated && !this.selectedSocietyServiceSummary.service.isWithSubscription && this.isPaymentRequired),
            this.isDowngrade && !this.isPaymentRequired,
            this.periodicityControl && this.hasServicesInPeriodicity(this.periodicityControl.value),
            (this.selectedSocietyServiceSummary && this.selectedSocietyServiceSummary.total === 0) && !this.cardAlreadyRegistered
        ];

        return conditions.some((condition: boolean): boolean => {

            return Boolean(condition);
        });
    }

    get isOnlineSale(): boolean {

        return this.service.tag === GIFT_VOUCHER_SERVICE_LABEL;
    }

    get isAdditionalWebsite(): boolean {

        return this.service.tag === ADDITIONAL_WEBSITE_SERVICE_LABEL;
    }

    get isAccountAdmin(): boolean {

        return !!this.currentUser.societyAdmin;
    }

    get periodicity(): 'M' | 'Y' {

        return this.periodicityControl.value;
    }

    get deregistrationInProcess(): boolean {

        return (this.societyService.paymentStatus === 1) && this.societyService.isValid && this.societyService.toDelete;
    }
}
