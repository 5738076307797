import {Component, Inject, OnInit} from '@angular/core';
import {Iframe} from "../iframe";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

export interface IframeDialogData {
    iframe: Iframe;
}

@Component({
  selector: 'cms-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IframeDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<IframeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IframeDialogData
    ) {
    }

  ngOnInit() {
  }

}
