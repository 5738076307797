import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {Society} from "@core/shared/models/society";

@Injectable({
    providedIn: 'root'
})
export class OfferCreatorService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Society>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offerCreators`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Society>>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number): Observable<Society> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Society>(`${this._apiService.getApiUrl(false, true)}/offerCreators/${id}`, {
            headers: headers
        });
    }
}
