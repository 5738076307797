<ng-container [formGroup]="form">

    <div class="form_part col_6" *ngIf="mainWebsiteControl">

        <p class="title_part">{{ 'website.main.value' | translate }}</p>

        <div class="ligne_form row marges">

            <div class="col_12">

                <ng-container formArrayName="websites">

                    <div class="row marges ligne_form">

                        <ng-container [formGroupName]="indexAsString(0)">

                            <!-- Site web -->

                            <div class="col_6">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="website" id="website-main">
                                    <label for="website-main">{{ 'website.main.value' | translate }}</label>
                                    <mat-error *ngIf="mainWebsiteControl.get('website').errors && (mainWebsiteControl.get('website').dirty || mainWebsiteControl.get('website').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="mainWebsiteControl.get('website').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                </ng-container>

            </div>
        </div>
    </div>

    <div class="form_part col_6" *ngIf="additionalWebsitesControls.length">

        <p class="title_part">{{ 'website.all.additional.value' | translate }}</p>

        <div class="ligne_form row marges" *ngIf="websitesControl.errors && (websitesControl.dirty || websitesControl.touched)">

            <div class="col_12">

                <mat-error>
                    <div class="invalid-feedback">
                        <div *ngIf="websitesControl.errors.invalidUnicity">{{ 'website.form.control.error.unicity.value' | translate }}</div>
                    </div>
                </mat-error>

            </div>
        </div>

        <div class="ligne_form row marges">

            <div class="col_12">

                <ng-container formArrayName="websites">

                    <div *ngFor="let websiteControl of additionalWebsitesControls; let i = index" class="row marges ligne_form">

                        <ng-container [formGroupName]="indexAsString(i + 1)">

                            <!-- Site web -->

                            <div class="col_6">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="website" id="website-additional-{{i}}">
                                    <label for="website-additional-{{i}}">{{ 'website.additional.value' | translate }}</label>
                                    <mat-error *ngIf="websiteControl.get('website').errors && (websiteControl.get('website').dirty || websiteControl.get('website').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="websiteControl.get('website').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                            <ng-container *ngIf="!!websiteControl.get('website').value">
                                <div class="col_6 clear_website">
                                    <div class="form-group">
                                        <button mat-button type="button" (click)="clearWebsite(websiteControl.get('website'))">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                        </ng-container>

                    </div>

                </ng-container>

            </div>
        </div>
    </div>

</ng-container>
