import {StatusType} from "@core/shared/models/status-type";

export const TENDER_OFFER_CREATOR_STATUS: StatusType[] =  [
    {
        id: 'to-process',
        label: 'status.to-process.value'
    },
    {
        id: 'reply',
        label: 'status.reply.value'
    },
    {
        id: 'no-reply',
        label: 'status.no-reply.value'
    }
];
