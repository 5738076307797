import {UntypedFormGroup} from "@angular/forms";

/**
 * Vérifie que 2 champs de formulaire ont une valeur identique
 *
 * @param controlName
 * @param matchingControlName
 */
export const mustMatchValidator = (controlName: string, matchingControlName: string) => {

    return (formGroup: UntypedFormGroup) => {

        const control = formGroup.controls[controlName];

        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {

            return;
        }

        if (control.value !== matchingControl.value) {

            matchingControl.setErrors({ mustMatch: true });

        } else {

            matchingControl.setErrors(null);
        }
    }
};
