import {Injectable} from '@angular/core';
import {HttpRequest, HttpClient, HttpParams, HttpHeaders} from "@angular/common/http";
import {FileUploadConfig, IFileUploadApi, IFileUploadApiHeader, IFileUploadApiParam} from "./file-upload";

@Injectable()
export class FileUploadService {

    /**
     * Constructeur
     *
     * @param {HttpClient} _http
     */
    constructor(private _http: HttpClient) {
    }

    config: FileUploadConfig = {
        multiple: false,
        allowedTypes: '',
        maxSize: 1,
        uploadApi: null,
        uploadBtnMsg: 'media.file.choice.action.value',
        uploadLabelMsg: 'media.file.add.action.value'
    };

    /**
     * Retourne la configuration par défaut
     *
     * @returns {FileUploadConfig}
     */
    getConfig(): FileUploadConfig {
        return this.config;
    }

    /**
     * API POST UPLOAD
     *
     * @param {IFileUploadApi} uploadApi
     * @param formData
     * @returns {Observable<HttpEvent<any>>}
     */
    uploadFiles(uploadApi: IFileUploadApi, formData) {

        let headers = new HttpHeaders();
        let params = new HttpParams();

        // Initialisation des Headers

        if(uploadApi.headers){
            uploadApi.headers.map((header: IFileUploadApiHeader) => {
                headers = headers.append(header.name, header.value);
            });
        }

        // Initialisation des Query Params

        if(uploadApi.params){
            uploadApi.params.map((param: IFileUploadApiParam) => {
                params = params.append(param.name, param.value);
            });
        }

        const request = new HttpRequest('POST', uploadApi.url, formData, {
            reportProgress: true,
            headers: headers,
            params: params
        });

        return this._http.request(request);
    }
}
