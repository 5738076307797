import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import CalendarDataSourceElement from "js-year-calendar/dist/interfaces/CalendarDataSourceElement";
import Calendar from "js-year-calendar";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {DATE_FORMAT} from "@app/data";
import {map} from "rxjs/operators";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {GiftVoucherPeriodCalendarDatesInterval} from "@core/shared/models/gift-voucher/gift-voucher-period/gift-voucher-period-calendar";
import {GiftVoucherDateService} from "@core/shared/services/gift-voucher/gift-voucher-date.service";
import {GiftVoucherDate, GiftVoucherDateStatus} from "@core/shared/models/gift-voucher/gift-voucher-date";

interface DataSource extends CalendarDataSourceElement {

    status: GiftVoucherDateStatus;
}

@Component({
    selector: 'app-core-gift-voucher-period-calendar',
    templateUrl: './gift-voucher-period-calendar.component.html',
    styleUrls: ['./gift-voucher-period-calendar.component.scss']
})
export class GiftVoucherPeriodCalendarComponent implements AfterViewInit {

    @Input() giftVoucher: GiftVoucher;

    @ViewChild('calendar', { static: true }) calendarRef: ElementRef<HTMLDivElement>;

    public datesInterval: GiftVoucherPeriodCalendarDatesInterval = {
        start: null,
        end: null
    };

    public calendar: Calendar<DataSource>;

    constructor(
        private _giftVoucherDateService: GiftVoucherDateService,
        private _translateService: TranslateService
    ) {
    }

    ngAfterViewInit() {

        this.calendar = new Calendar<DataSource>(this.calendarRef.nativeElement, {
            language: this._translateService.currentLang,
            style: 'custom',
            loadingTemplate: null,
            numberMonthsDisplayed: 2,
            startDate: moment().startOf('year').toDate(),
            // @ts-ignore
            dataSource: (data: { startDate: Date, endDate: Date }): Promise<DataSource[]> => {

                this.datesInterval = {
                    start: moment(data.startDate),
                    end: moment(data.endDate)
                };

                return this.dataSourcePromise;
            },
            customDayRenderer: (element: HTMLDivElement): void => {

                element.classList.add('day-label');
            },
            customDataSourceRenderer: (element: HTMLDivElement, currentDate: Date, events: DataSource[]): void => {

                const event: DataSource|null = events[0];

                if(event.status === 'closed'){

                    element.parentElement.insertAdjacentHTML('beforeend', `
                        <div class="infos">
                            <p class="closed">${ this._translateService.instant('offer.date.closed.value') }</p>
                        </div>
                    `);
                }
                else{

                    element.parentElement.insertAdjacentHTML('beforeend', `
                        <div class="infos"></div>
                    `);
                }
            }
        });
    }

    public setNumberMonthsDisplayed(numberMonthDisplayed: number): void {

        this.calendar.setNumberMonthsDisplayed(numberMonthDisplayed);

        this.refreshCalendar();
    }

    public refreshCalendar(): void {

        if(this.isYearView){

            this.calendar.setStartDate(this.datesInterval.start.clone().startOf('year').toDate());
        }
        else{

            this.calendar.setStartDate(this.datesInterval.start.clone().toDate());
        }
    }

    public getNumberMonthsDisplayedElementClasses(numberMonthDisplayed: number): {[p: string]: boolean} {

        return {
            'actif' : this.calendar?.getNumberMonthsDisplayed() === numberMonthDisplayed
        };
    }

    get dataSourcePromise(): Promise<DataSource[]>{

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('date', 'gte', this.datesInterval.start.format(DATE_FORMAT)));

        filterBuilder.addField(new TextFilterField('date', 'lte', this.datesInterval.end.format(DATE_FORMAT)));

        return this._giftVoucherDateService.getItemsAPI(this.giftVoucher.offerData.id, filterBuilder.serializedFilters).pipe(
            map((items: GiftVoucherDate[]): DataSource[] => {

                return items.map((item: GiftVoucherDate): DataSource => {

                    return {
                        startDate: item.date.toDate(),
                        endDate: item.date.toDate(),
                        status: item.status
                    }
                });
            })
        ).toPromise();
    }

    get isYearView(): boolean {

        return this.calendar.getNumberMonthsDisplayed() === 12;
    }
}
