import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class OfferBookingRequestService {

    public localeUpdated$: Subject<string> = new Subject<string>();

    constructor() {
    }
}
