<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'promotionService.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccess('PROMOTION_SERVICE_CREATE')" class="btn_cta btn_little btn_add">{{ 'promotionService.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">

        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Service -->

                <ng-container [matColumnDef]="getFilterColumnDef('service')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'promotionService.service.value' | translate" [key]="'service.id'" [operator]="'eq'" [items]="filterServices" [itemLabelProperty]="'name'" [itemValueProperty]="'id'"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Code -->

                <ng-container [matColumnDef]="getFilterColumnDef('code')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionService.code.value' | translate" [key]="'code'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom de l'utilisateur -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyLastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionService.societyLastName.value' | translate" [key]="'society.admin.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom de l'utilisateur -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyFirstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionService.societyFirstName.value' | translate" [key]="'society.admin.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Email de l'utilisateur -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyEmail')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionService.societyEmail.value' | translate" [key]="'society.admin.email'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom de la société -->

                <ng-container [matColumnDef]="getFilterColumnDef('societyName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionService.societyName.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="update(element.id)" *ngIf="hasAccess('PROMOTION_SERVICE_READ')">
                            <mat-icon *ngIf="!isEditable(i)">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="isEditable(i)">edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="hasAccess('PROMOTION_SERVICE_DELETE') && getModel(element).isDeletable">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Service -->

                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionService.service.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).service.translations).name }}</td>
                </ng-container>

                <!-- Code -->

                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionService.code.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).code }}</td>
                </ng-container>

                <!-- Nom de l'utilisateur -->

                <ng-container matColumnDef="societyLastName">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionService.societyLastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society.admin.lastName }}</td>
                </ng-container>

                <!-- Prénom de l'utilisateur -->

                <ng-container matColumnDef="societyFirstName">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionService.societyFirstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society.admin.firstName }}</td>
                </ng-container>

                <!-- Email de l'utilisateur -->

                <ng-container matColumnDef="societyEmail">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionService.societyEmail.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society.admin.email }}</td>
                </ng-container>

                <!-- Nom de la société -->

                <ng-container matColumnDef="societyName">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionService.societyName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society.name }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>
