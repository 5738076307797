import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {RelationshipType} from "@core/shared/models/relationship";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";

@Component({
    selector: 'app-core-relationship-create-dialog',
    templateUrl: './relationship-create-dialog.component.html',
    styleUrls: ['./relationship-create-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class RelationshipCreateDialogComponent implements OnInit {

    @Output() submit: EventEmitter<RelationshipType> = new EventEmitter<RelationshipType>();

    public types: RelationshipType[] = [
        'offerCreator',
        'provider',
        'societyGroup',
        'affiliation'
    ];

    constructor(
        private _formBuilder: FormBuilder,
        private _dialogRef: MatDialogRef<RelationshipCreateDialogComponent>,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            type: [null, [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            this.submit.emit(this.form.get('type').value as RelationshipType);

            this._dialogRef.close();
        };
    }

    public cancel(): void {

        this._dialogRef.close();
    }

    get form(): FormGroup {

        return this.formService.form;
    }
}
