import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";

@Component({
    selector: 'app-core-checkbox-boolean-filter',
    templateUrl: './checkbox-boolean-filter.component.html',
    styleUrls: ['./checkbox-boolean-filter.component.scss']
})
export class CheckboxBooleanFilterComponent implements OnInit {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() disableAutoSubmit: boolean;

    @Output() valueChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    public value: boolean;

    public filterField: TextFilterField;

    constructor() {

        this.value = null;
    }

    ngOnInit(): void {

        this.filterField = new TextFilterField(this.key, 'eq');

        this.builder.addField(this.filterField);
    }

    public hydrateValue(): void {

        this.filterField.value = (this.value === null || this.value === undefined) ? null : +this.value;
    }

    public filter(): void {

        this.hydrateValue();

        this.valueChanges.emit(this.value);

        if(this.disableAutoSubmit){

            return;
        }

        this.builder.filter();
    }

    public reset(): void {

        this.value = null;
    }
}
