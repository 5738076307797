import {AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {icon, Layer, MapOptions, marker, tileLayer} from "leaflet";

@Component({
    selector: 'app-core-channel-offer-view-preview',
    templateUrl: './channel-offer-view-preview.component.html',
    styleUrls: ['./channel-offer-view-preview.component.scss']
})
export class ChannelOfferViewPreviewComponent implements OnInit, AfterViewInit {

    @Input() styleForm: UntypedFormGroup;

    @ViewChild('styles', { static: true }) stylesRef: ElementRef<HTMLDivElement>;

    @ViewChildren('accordion') accordionElements: QueryList<ElementRef<HTMLDivElement>>;

    public mapOptions: MapOptions;

    public mapInitialized: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {

        setTimeout((): void => {

            this._initMap();
        });

        this._initAccordions();

        this._handleStyles();

        this.styleForm.valueChanges.subscribe((): void => {

            this._handleStyles();
        });
    }

    private _initMap(): void {

        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18
                })
            ],
            zoom: 5,
            center: {
                lat: 46.85,
                lng: 2.3518
            }
        };

        this.mapInitialized = true;
    }

    private _initAccordions(): void {

        this.accordionElements.forEach((elementRef: ElementRef<HTMLDivElement>): void => {

            const headerElement: HTMLElement = elementRef.nativeElement.querySelector('.accordion_header');

            headerElement.onclick = (): void => {

                elementRef.nativeElement.classList.toggle('open');
            };
        });
    }

    private _handleStyles(): void {

        const container: HTMLDivElement = (this.stylesRef.nativeElement as HTMLDivElement);

        while(container.firstChild){

            container.removeChild(container.firstChild);
        }

        const styleContainer = document.createElement('style');

        styleContainer.innerHTML = `
            .channel_pim_preview .title_offer .infos .offer_type:before {
                color: ${ this.styleForm.get('colorSubTitle').value } !important;
            }
            .channel_pim_preview .title_offer .infos .offer_type:after {
                background-color: ${ this.styleForm.get('colorSubTitle').value } !important;
            }
            .channel_pim_preview .txt_offer .interest span:before {
                color: ${ this.styleForm.get('colorHighlighting').value } !important;
            }
            .channel_pim_preview .infos_program .slider_program .swiper-wrapper .swiper-slide .content_program:after {
                border: 1px solid ${ this.styleForm.get('colorHighlighting').value } !important;
            }
            .channel_pim_preview .btn_cta:hover {
                background-color: ${ this.styleForm.get('colorHighlighting').value } !important;
            }
        `;

        container.appendChild(styleContainer);
    }

    get marker(): Layer {

        return marker({lat: 46.85, lng: 2.3518}, {
            icon: icon({
                iconSize: [ 29, 45 ],
                iconAnchor: [ 16, 25 ],
                iconUrl: 'assets/images/icons/marker-icon-first.svg',
                shadowUrl: null
            })
        });
    }
}
