import {Injectable} from '@angular/core';
import {IApiSlideshowConfig, SlideshowConfig, SlideshowFormat} from "./slideshow";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {IApiTextConfig} from "../text/text";

@Injectable({
    providedIn: 'root'
})
export class SlideshowService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * IMAGE URL
     */
    private readonly IMAGE_URL: string = '/image/';

    /**
     * GALLERY URL
     */
    private readonly GALLERY_URL: string = '/galleries';

    /**
     * PREFIX URL
     */
    private readonly PREFIX_URL: string = '/slideshow';

    private readonly FORMAT_URL: string = '/format';

    /**
     * CONFIG URL
     */
    private readonly CONFIG_URL: string = '/config';

    /**
     * Configuration
     */
    private _config: SlideshowConfig;

    /**
     * Formats
     */
    private _formats: SlideshowFormat[];

    /**
     * @param _http
     */
    constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    /**
     * @return boolean
     */
    configLoaded(): boolean {

        return (typeof this._config !== 'undefined');
    }

    /**
     * @param config
     */
    setConfig(config: SlideshowConfig): void {

        this._config = config;
    }

    /**
     * @returns {Observable<IApiFileConfig>}
     */
    loadConfigGallery(context: string): Observable<SlideshowConfig> {

        return this._http.get<SlideshowConfig>(`${this.API_URL}${ this.GALLERY_URL }${this.IMAGE_URL}${context}`);
    }

    /**
     * Retourne la configuration nécessaire pour le slideshow
     *
     * @returns {Observable<IApiTextConfig>}
     */
    loadConfig(context: string): Observable<IApiSlideshowConfig> {

        return this._http.get<IApiSlideshowConfig>(`${this.API_URL}${this.PREFIX_URL}${this.CONFIG_URL}`, {
            params: {
                context: context
            }
        });
    }

    /**
     * @param formats
     */
    setFormats(formats: SlideshowFormat[]): void {

        this._formats = formats;
    }

    getFormats(): SlideshowFormat[] {

        return this._formats;
    }

    setFormatImage(image): Observable<object> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const httpOptions: object = {
            headers: headers
        };

        return this._http.post(`${ this.API_URL }/images/${ image.id }${ this.FORMAT_URL }`, {
            image: image
        }, httpOptions);
    }
}
