import {Injectable} from '@angular/core';
import {ITranslation} from "./translation";
import {ILocale} from "./locale";
import {CmsService} from "../cms.service";

@Injectable()
export class TranslationService {

    constructor(private _cmsService: CmsService) {
    }

    // Liste des langues disponibles
    private availablesLocales: ILocale[] = [];

    private defaultLocale: string = null;

    // Langue actuellement affichée
    private currentLocale: string = null;

    /**
     * Récupération d'une langue en fonction de la locale (fr, en etc...)
     *
     * @param locale
     * @returns {ITranslation|null}
     */
    getTranslationByLocale(locale: string): ITranslation {

        let content = this._cmsService.getContent();

        let match = content.translations.filter((translation: ITranslation) => {
            return translation.locale === locale;
        }).shift();

        return match || null;
    }

    /**
     *  Récupération de la liste des langues disponibles
     *  Si definedLocalesFilter vaut TRUE, les langues déjà définies pour la page ne seront pas retournées
     *
     * @param {boolean} definedLocalesFilter
     * @returns {any[]}
     */
    getAvailableLocales(definedLocalesFilter: boolean): ILocale[] {

        if (!definedLocalesFilter)
            return this.availablesLocales;

        return this.availablesLocales.filter((locale: ILocale) => {
            return !this.getTranslationByLocale(locale.id);
        });
    }

    /**
     * Mise à jour des langues disponibles sur le site
     *
     * @param {ILocale[]} locales
     */
    setAvailableLocales(locales: ILocale[]): void {
        this.availablesLocales = locales;
    }

    /**
     * Mise à jour de la langue par défaut
     *
     * @param {string} locale
     */
    setDefaultLocale(locale: string): void {
        this.defaultLocale = locale;
    }

    /**
     * Détermine si la langue est celle par défaut
     *
     * @param {string} locale
     * @returns {boolean}
     */
    isDefaultLocale(locale: string): boolean {
        return locale === this.defaultLocale;
    }

    /**
     * Retourne la langue par défaut
     *
     * @returns {string}
     */
    getDefaultLocale(): string{
        return this.defaultLocale;
    }

    /**
     * Mise à jour de la langue affichée
     *
     * @param {string} locale
     */
    setCurrentLocale(locale: string): void {
        this.currentLocale = locale;
    }

    /**
     * Récupération de la langue affichée
     *
     * @returns {string}
     */
    getCurrentLocale(): string {
        return this.currentLocale;
    }

    /**
     * Détermine si la langue passée en paramètre est celle actuellement affichée
     *
     * @param {string} locale
     * @returns {boolean}
     */
    isCurrentLocale(locale: string): boolean {
        return this.currentLocale === locale;
    }

    /**
     * Ajout d'une langue pour la page
     *
     * @param locale
     * @returns {ITranslation}
     */
    addTranslation(locale: string): ITranslation{

        let content = this._cmsService.getContent();

        let newTranslation: ITranslation = {
            id: null,
            locale: locale,
            rows: []
        };

        content.translations.push(newTranslation);

        return newTranslation;
    }

    /**
     * Duplication du contenu (row + element) d'une traduction existante
     *
     * @param {ITranslation} newTranslation
     * @param {ITranslation} duplicateTranslation
     */
    duplicateTranslation(newTranslation: ITranslation, duplicateTranslation: ITranslation): void {

        let dontUpdate = ['id', 'locale'];

        Object.keys(duplicateTranslation).map(function (objectKey) {
            if (newTranslation.hasOwnProperty(objectKey) && (dontUpdate.indexOf(objectKey) < 0)) {
                newTranslation[objectKey] = duplicateTranslation[objectKey];
            }
        });
    }

    /**
     * Récupération d'une langue disponible en fonction de son ID (fr, en etc...)
     *
     * @param {string} id
     * @returns {ILocale}
     */
    getLocaleById(id: string): ILocale {

        let match = this.availablesLocales.filter((locale: ILocale) => {
            return locale.id === id;
        }).shift();

        return match || null;
    }

    /**
     * Suppression d'une langue pour la page
     *
     * @param {ITranslation} deletedTranslation
     */
    deleteTranslation(deletedTranslation: ITranslation): void {

        let content = this._cmsService.getContent();

        content.translations = content.translations.filter((translation: ITranslation) => {
            return translation.locale !== deletedTranslation.locale;
        });

        // La langue par défaut est définie comme langue actuellement affichée
        this.setCurrentLocale(this.defaultLocale);
    }
}
