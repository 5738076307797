import {Component, DoCheck, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output} from '@angular/core';
import {InputConfig} from "../input";
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {Gallery} from "@lib/media/gallery";
import {Image} from "@lib/media/image/image";
import {ApiService} from "@core/shared/services/api.service";

export interface ImageConfig extends InputConfig {

    uploadApiUrl: string;

    gallery?: Gallery;

    gallery_context?: string;

    required: boolean;

    options?: OptionsImage;

    help? : string;
}

export interface OptionsImage {

    enableTitle?: boolean;
    enableSubtitle?: boolean;
    enableAlt?: boolean;
    enableLink?: boolean;
    enableTargetBlank?: boolean;
    format?: string;
    label?: string;
}

@Component({
    selector: 'form-field-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, DoCheck {

    @Input() public config: ImageConfig = {id: null, uploadApiUrl: null, required: false, options: {enableTitle: false,
            enableSubtitle: false,
            enableAlt: false,
            enableLink: false,
            enableTargetBlank: false,
            format: null,
            label : "Ajouter une image :"
        }};

    /**
     * Valeur du champ texte
     */
    @Input() public image: Image;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    @Output() imageAdded: EventEmitter<void> = new EventEmitter<void>();

    @Output() imageDeleted: EventEmitter<void> = new EventEmitter<void>();

    private differConfig: KeyValueDiffer<any, any>;
    private differOptions: KeyValueDiffer<any, any>;

    public galleryObject: Gallery = null;

    public constructor(private _http: HttpClient, private differs: KeyValueDiffers, private _apiService: ApiService) {
        this.differConfig = this.differs.find(this.config).create();
        this.differOptions = this.differs.find(this.config.options).create();
    }

    ngOnInit() {

        const api: string = this._apiService.getApiUrl(false, true);

        if (this.config.gallery_context) {
            this._http.get<ImageConfig>(`${api}/galleries/image/${this.config.gallery_context}`).subscribe((config: ImageConfig) => {
                this.galleryObject = config.gallery;
            });
        }

        this.config.options = Object.assign(this.config.options, {
            enableTitle: this.config.options.enableTitle,
            enableSubtitle: this.config.options.enableSubtitle,
            enableAlt: this.config.options.enableAlt,
            enableLink: this.config.options.enableLink,
            enableTargetBlank: this.config.options.enableTargetBlank,
            format: this.config.options.format || null,
            label: (this.config.options.label ? this.config.options.label : 'Ajouter une image') + (this.config.required ? ' *' : '')
        });

        const validators: ValidatorFn[] = [];

        if (this.config.required) {
            validators.push(Validators.required);
        }

        this.form.addControl(this.config.id, new UntypedFormControl('', validators));

        if(this.form.value[this.config.id] && this.form.value[this.config.id].image){
            this.image = this.form.value[this.config.id].image;
        }else if(this.form.value[this.config.id] && !this.form.value[this.config.id].image){
            this.image = this.form.value[this.config.id];
        }
    }

    add(image: Image): void {

        this.image = image;

        this.form.controls[this.config.id].setValue(this.image);

        this.imageAdded.emit();
    }
    delete(): void {

        this.image = null;

        this.form.controls[this.config.id].setValue(this.image);

        this.imageDeleted.emit();
    }

    public ngDoCheck(): void {
        const changesConfig = this.differConfig.diff(this.config);
        const changesOptions = this.differOptions.diff(this.config.options);

        if (changesConfig) {
            changesConfig.forEachChangedItem((r) => {
                if (r.key == 'gallery_context') {
                    this.config.gallery_context = r.currentValue;
                    this._http.get<ImageConfig>(`${this._apiService.getApiUrl(false, true)}/galleries/image/${this.config.gallery_context}`).subscribe((config: ImageConfig) => {
                        this.galleryObject = config.gallery;
                    });
                }

                if (r.key == 'help') {
                    this.config.help = r.currentValue;
                }
            });
        }

        if(changesOptions){
            changesOptions.forEachChangedItem((r) => {
                if (r.key == 'format') {
                    this.config.options.format = r.currentValue;
                }
            });
        }




    }
}
