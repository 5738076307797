<div class="thumb-input ajout_video" *ngIf="configInitialized">

    <div>
        <div class="semi">
            <span class="label">{{ 'element.types.video.waitingImage' | translate }}</span>
            <media-file-upload  [config]="getFileUploadConfig(this.mimeTypes.waitingImage)" (postUpload)="postUploadWaitingImage($event)"></media-file-upload>
        </div>
        <div class="semi" *ngFor="let videoExtension of videoExtensions">
            <span class="label">{{ videoExtension }}</span>
            <media-file-upload [config]="getFileUploadConfig(mimeTypes[videoExtension])" (postUpload)="postUploadExtension(videoExtension, $event)"></media-file-upload>
        </div>
    </div>

    <div class="apercu_element">
        <button mat-raised-button color="primary" *ngIf="content.mp4 && content.webm && content.ogv" (click)="displayVideo()">{{ 'element.types.video.preview.show' | translate }}</button>
    </div>
</div>
