<div [formGroup]="form" class="form-group">
    <mat-form-field>
        <textarea matInput appInput [attrs]="config.attrs" [formControlName]="config.id"></textarea>
    </mat-form-field>
    <p *ngIf="config.attrs.help" class="message-help">{{ config.attrs.help }}.</p>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            <div *ngIf="form.controls[config.id].errors.minlength" [translate]="'form.validation.error.minLength'" [translateParams]="{value: config.attrs.minlength}"></div>
            <div *ngIf="form.controls[config.id].errors.maxlength" [translate]="'form.validation.error.maxLength'" [translateParams]="{value: config.attrs.maxlength}"></div>
        </div>
    </mat-error>
</div>
