<div class="page_card">
    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <div class="page_title">
            <div class="row marges">
                <div class="col y_items_center">
                    <h1 class="title">{{ 'creditCard.create.value' | translate }}</h1>
                </div>
                <div class="col_content y_items_center">
                    <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToCard()">
                        {{ 'creditCard.self.redirect.value' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="recap col_6">
            <div class="ck-content">
                <div class="row marges ">
                    <div class="warning">
                        <div class="row marges">
                            <div class="col_12">
                                <p>{{ 'creditCard.authenticityValidation.description.value' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Données bancaires -->

        <div class="row ligne_form">
            <div class="col_6">
                <app-core-card-form [form]="creditCardControl"></app-core-card-form>
            </div>
        </div>

        <!-- Acception des conditions générales -->

        <div class="row marges ligne_form">
            <div class="col_6">

                <div class="form-group">
                    <div class="checkbox">
                        <input type="checkbox" id="acceptTermsAndConditions" formControlName="acceptTermsAndConditions">
                        <label for="acceptTermsAndConditions">{{'acceptTermsAndConditions.success.action.description.value' | translate}}
                            <a [href]="termsAndConditionsService.getItemAPIUrl(this.society.locale)" target="_blank">{{ 'acceptTermsAndConditions.value' | translate }}</a>
                        </label>
                        <mat-error *ngIf="form.get('acceptTermsAndConditions').errors && (form.get('acceptTermsAndConditions').dirty || form.get('acceptTermsAndConditions').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('acceptTermsAndConditions').errors.required">{{ 'acceptTermsAndConditions.form.invalid.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

        <!-- Validation du formulaire -->

        <div class="row ligne_form x_center col_6">
            <button type="submit" class="btn_cta btn_little btn_validate_paiement" [disabled]="form.invalid">{{ 'subscription.actions.validatePayment.value' | translate }}</button>
        </div>

    </form>
</div>
