<!-- Affichage de la map -->

<div class="map_container" leaflet [leafletOptions]="mapOptions" [leafletMarkerCluster]="markers"></div>

<!-- Affichage du détail d'une offre -->

<ng-container *ngIf="displayedOffer">
    <div class="col_4 card_offer_map">
        <app-core-offer-card
            [item]="displayedOffer"
            [displayLocation]="displayLocation"
            [roles]="roles"
            [layoutView]="layoutView"
            [mode]="mode"
            [identificationNumber]="identificationNumber"
            [publicPrice]="publicPrice"
            (closeItem)="resetDisplayedOffer()"
        ></app-core-offer-card>
    </div>
</ng-container>
