import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Society} from "../models/society";
import {SocietyService} from "../services/society.service";
import {UserService} from '@core/shared/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class SocietyItemSelfResolver  {

    constructor(
        private _societyService: SocietyService,
        private _userService: UserService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        if (this._userService.hasOneOfThisRoles(this._userService.currentUser.getValue(),['ROLE_ADMIN','ROLE_SUPER_ADMIN'])){
            return of(null);
        }

        return this._societyService.getItemSelfAPI();
    }
}
