import {Exclude, Expose, Transform} from "class-transformer";
import {MediaImage} from "@lib/media/image/image";
import * as moment from "moment";
import {DATETIME_FORMAT} from "@app/data";
import {Moment} from "moment";

export type TemplateGiftVoucherLocale = 'generic' | 'multilingual';

@Exclude()
export class TemplateGiftVoucher {

    @Expose()
    id: number;

    @Expose()
    type: TemplateGiftVoucherLocale;

    @Expose()
    isPhysical: boolean;

    @Expose()
    isDematerialized: boolean;

    @Expose()
    translations: TemplateGiftVoucherTranslation[];

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    createdAt: string;

    @Expose()
    isMine: boolean;

    locales;
}

export interface TemplateGiftVoucherTranslation {

    id: number;

    label: string;

    picture: TemplateGiftVoucherPicture;

    locale: string;

}

export interface TemplateGiftVoucherPicture {

    id: number;

    copyright: string;

    image: MediaImage;

}
