import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {FormService} from "@core/shared/services/form.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferAccessRequestMultipleCreateConfiguration} from "@core/shared/models/offer/offer-access-request/offer-access-request-multiple/offer-access-request-multiple-create";
import {DEFAULT_MARKUP, MIN_MARKUP} from '@core/shared/models/data';
import {REGEX_PRICE} from "@core/shared/models/regex";
import {UserService} from "@core/shared/services/user.service";

@Component({
    selector: 'app-core-offer-access-request-multiple-create',
    templateUrl: './offer-access-request-multiple-create.component.html',
    styleUrls: ['./offer-access-request-multiple-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferAccessRequestMultipleCreateComponent implements OnInit {

    @Input() configuration: OfferAccessRequestMultipleCreateConfiguration;

    @Input() offers: Offer[];

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    public minMarkupPercent: number = MIN_MARKUP;

    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    public showMarkupInput: boolean = true;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offerCatalogService: OfferCatalogService,
        public _userService: UserService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this.showMarkupInput = !this._userService.hasOneOfThisRoles(this._userService.currentUser.getValue(),['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER']);

        this.formService.form = new UntypedFormGroup({});

        this._initForm();
    }

    private _initForm(): void {

        this._userService.currentUser.getValue();

        this.formService.form = this._formBuilder.group({});

        if (this.showMarkupInput){

            this.form.addControl('markup', this._formBuilder.control(this.defaultMarkupPercent, [
                Validators.required,
                Validators.pattern(REGEX_PRICE),
                Validators.min(this.minMarkupPercent)]
            ));
        }

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                offers: this.offers.map((offer: Offer): { id: number } => {

                    return {
                        id: offer.id
                    };
                })
            });

            if(this.showMarkupInput){

                Object.assign(data, {
                    markup: this.form.get('markup').value / 100
                });
            }

            this._offerCatalogService.createItemsAPI(data).subscribe((): void => {

                this._loadSelfOfferCatalogs();

                this.create.emit();
            });
        };
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
