export type OfferPriceLevelIncreaseAreaType = 'travel' | 'adult-price' | 'child-price' | 'all';

export interface OfferPriceLevelIncreaseArea{

    type: OfferPriceLevelIncreaseAreaType;

    label: string;
}

export const OFFER_PRICE_LEVEL_INCREASE_AREAS: OfferPriceLevelIncreaseArea[] = [
    {
        type: 'travel',
        label: 'offer.price.level.increaseArea.travel.value'
    },
    {
        type: 'adult-price',
        label: 'offer.price.level.increaseArea.adultPrice.value'
    },
    {
        type: 'child-price',
        label: 'offer.price.level.increaseArea.childPrice.value'
    },
    {
        type: 'all',
        label: 'offer.price.level.increaseArea.all.value'
    }
];
