import {IElement} from "../element";

export type ElementType = 'text' | 'button' | 'file' | 'iframe' | 'video' | 'image' | 'slideshow' | 'form';

/**
 * Détermine si un élément est d'un type spécifique
 *
 * @param type
 * @param element
 */
export function isType(type: ElementType, element: IElement): boolean{

    return element.type === type;
}
