import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormService} from "@core/shared/services/form.service";
import {locale} from "moment/moment";
import {environment} from "../../../../environments/environment";
import {JwtToken} from "@core/shared/models/jwt";
import {Role} from "@core/shared/models/role";
import {UserService} from "@core/shared/services/user.service";
import {map} from "rxjs/operators";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Observable, of} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'app-core-page-signin',
    templateUrl: './page-signin.component.html',
    styleUrls: ['./page-signin.component.scss'],
    providers: [
        FormService
    ]
})
export class PageSigninComponent implements OnInit {

    public display: boolean = false;

    public loginLogoUrl$: Observable<SafeUrl>;

    public poweredByTywin: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _sanitizer: DomSanitizer,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _authenticationService: AuthenticationService,
        private _userService: UserService,
        public formService: FormService
    ) {}

    ngOnInit() {

        this._authenticationService.jwtToken.subscribe((data: JwtToken): void => {

            this.display = !data.isAuthenticated;
        });

        if (this._authenticationService.isAuthenticated) {

            this._redirectUserToAppropriatePage(this._authenticationService.roles);
        }
        else{

            this._initLoginLogo();

            this._initForm();
        }
    }

    private _initLoginLogo(): void {

        const params: string[] = [];

        if('choicePreference' in this._activatedRoute.snapshot.queryParams){

            params.push(`choicePreference=${ this._activatedRoute.snapshot.queryParams['choicePreference'] as string }`);
        }

        if('registrationAffiliation' in this._activatedRoute.snapshot.queryParams){

            params.push(`registrationAffiliation=${ this._activatedRoute.snapshot.queryParams['registrationAffiliation'] as string }`);
        }

        if('societyGroup' in this._activatedRoute.snapshot.queryParams){

            params.push(`societyGroup=${ this._activatedRoute.snapshot.queryParams['societyGroup'] as string }`);
        }

        this.loginLogoUrl$ = Boolean(params.length) ? this._userService.getLoginLogoAPI(params).pipe(
            map((response: HttpResponse<Blob>): SafeUrl => {

                this.poweredByTywin = Boolean(parseInt(response.headers.get('Powered-By-Ty-Win')));

                const imageUrl: string = URL.createObjectURL(response.body);

                return this._sanitizer.bypassSecurityTrustUrl(imageUrl);
            })
        ) : of('assets/images/logo_tywin.png');
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            this._authenticationService.signin(this.form.value).subscribe( (data : {roles : Role[]} ) => {

                if(('choicePreference' in this._activatedRoute.snapshot.queryParams)){

                    localStorage.setItem('choicePreference', this._activatedRoute.snapshot.queryParams['choicePreference']);
                }

                if(('registrationAffiliation' in this._activatedRoute.snapshot.queryParams)){

                    localStorage.setItem('registrationAffiliation', this._activatedRoute.snapshot.queryParams['registrationAffiliation']);
                }

                setTimeout((): void => {

                    this._redirectUserToAppropriatePage(data.roles);
                });
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    private _redirectUserToAppropriatePage(userRoles : Role[]): void {

        switch(true){

            case (userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_SUPER_ADMIN')):

                this._router.navigate(['/account/dashboard']);

                break;

            case (userRoles.includes('ROLE_OFFER_CREATOR')):

                this._router.navigate(['/account/offer/list/personnal-offers']);

                break;

            case (userRoles.includes('ROLE_OFFER_DISTRIBUTOR') || userRoles.includes('ROLE_INSTITUTIONAL') || userRoles.includes('ROLE_FEDERATION') || userRoles.includes('ROLE_PROVIDER')):

                this._router.navigate(['/account/offer/list/reservoir']);

                break;
        }
    }

    get registerUrl(): string {

        let url: string = `${environment.registerUrl}/${locale()}`;

        const params: string[] = [];

        if('choicePreference' in this._activatedRoute.snapshot.queryParams){

            params.push(`choicePreference=${ this._activatedRoute.snapshot.queryParams['choicePreference'] as string }`);
        }

        if('registrationAffiliation' in this._activatedRoute.snapshot.queryParams){

            params.push(`registrationAffiliation=${ this._activatedRoute.snapshot.queryParams['registrationAffiliation'] as string }`);
        }

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return url;
    }

    get queryParams(): Params {

        return this._activatedRoute.snapshot.queryParams;
    }
}
