<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">

        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ 'booking.read.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour à la liste des suivis de commande -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'booking.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit" [disabled]="!isSubmitEnabled">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>

        </div>

    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'booking.folder.data.value' | translate">
            <ng-container *ngTemplateOutlet="folderData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'booking.folder.data.offer.value' | translate">
            <ng-container *ngTemplateOutlet="offerData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'booking.read.data.stay.value' | translate">
            <ng-container *ngTemplateOutlet="stayData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'booking.read.data.customer.data.value' | translate" *ngIf="hasAccessCustomer">
            <ng-container *ngTemplateOutlet="clientData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'booking.read.data.distributor.contactDetails.value' | translate">
            <ng-container *ngTemplateOutlet="distributorContactDetails"></ng-container>
        </mat-tab>
        <mat-tab *ngIf="isTypeBooking || (isTypeRequest && hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_DISTRIBUTOR']))" [label]="'booking.read.data.creator.contactDetails.value' | translate">
            <ng-container *ngTemplateOutlet="creatorContactDetails"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<ng-template #folderData>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges col_12">

            <div class="col_6">

                <div class="form-group">

                    <!-- Statut créateur d'offre-->

                    <div class="ligne_form row_marges">

                        <div class="col_12">

                            <div class="form-group">

                                <select class="form-control" formControlName="status" id="status" required>

                                    <option *ngFor="let item of statusItems" [value]="item.id">{{ item.label | translate }}</option>

                                </select>

                                <label class="required" for="status">{{ 'booking.list.fields.status.creator.value' | translate }}</label>

                                <mat-error *ngIf="form.get('status').invalid && (form.get('status').dirty || form.get('status').touched)">

                                    <div class="invalid-feedback">

                                        <div *ngIf="form.get('status').errors.required">{{ 'form.control.error.required.value' | translate }}</div>

                                    </div>

                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <div class="form-group">

                                <textarea class="form-control" formControlName="comment" id="comment"></textarea>

                                <label for="comment">{{'booking.read.fields.comment.value' | translate}}</label>

                            </div>

                        </div>

                    </div>

                    <div class="ligne_form row marges">

                        <div class="col_12">

                            <div class="form-group">

                                <!-- Société en charge de la relation client -->

                                <p class="read">
                                    <strong>{{ 'booking.read.fields.society.inChargeOfCustomerRelation.value' | translate }} : </strong>
                                    {{ booking.responsibleSociety.name }}
                                </p>

                                <ng-container *ngIf="booking.responsibleSocietyData?.isDeleted">

                                    <!-- Ancienne société en charge de la relation client -->

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.responsibleSocietyData.value' | translate }} : </strong>
                                        {{ booking.responsibleSocietyData.name }} - {{ booking.responsibleSocietyData.phone }} - {{ booking.responsibleSocietyData.email }}
                                    </p>

                                </ng-container>

                            </div>

                        </div>

                    </div>

                    <!-- Collaborateur -->

                    <div class="ligne_form row_marges">

                        <div class="col_12">

                            <div class="form-group">

                                <p class="read">
                                    <strong class="subtitle_green">{{ 'booking.list.fields.responsibleUser.value' | translate }}</strong>
                                </p>

                                <div style="margin-top: 12px">
                                    <app-core-select-search [configuration]="collaboratorSearchConfiguration" [sourceCallback]="collaboratorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'responsibleUser'"></app-core-select-search>
                                </div>

                                <ng-container *ngIf="currentCollaborator && (currentCollaborator.society.id === booking.responsibleSociety?.id) && hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && !isUpdateCollaboratorAllowed">

                                    <mat-error style="padding: 0">

                                        <div class="invalid-feedback">
                                            <div>{{ 'collaborator.update.denied.description.value' | translate }}</div>
                                        </div>

                                    </mat-error>

                                </ng-container>

                                <mat-error *ngIf="form.get('responsibleUser').invalid && (form.get('responsibleUser').dirty || form.get('responsibleUser').touched)">

                                    <div class="invalid-feedback">

                                        <div *ngIf="form.get('responsibleUser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>

                                    </div>

                                </mat-error>

                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!!currentCollaborator">

                        <!-- Données du collaborateur -->

                        <div class="ligne_form row marges">

                            <div class="col_12">

                                <div class="form-group">
                                    <p class="read">
                                        <strong class="subtitle_green">{{ 'collaborator.data.plural.value' | translate }}</strong>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="ligne_form row marges">

                            <div class="col_12">

                                <div class="form-group">

                                    <!-- Nom -->

                                    <ng-container *ngIf="currentCollaborator?.firstName || currentCollaborator?.lastName">

                                        <p class="read">
                                            <strong>{{ 'collaborator.name.value' | translate }} : </strong>
                                            {{ currentCollaborator?.firstName }} {{ currentCollaborator?.lastName }}
                                        </p>

                                    </ng-container>

                                    <!-- Service -->

                                    <ng-container *ngIf="currentCollaborator?.service">

                                        <p class="read">
                                            <strong>{{ 'collaborator.service.value' | translate }} : </strong>
                                            {{ currentCollaborator?.service }}
                                        </p>

                                    </ng-container>

                                    <!-- Email -->

                                    <ng-container *ngIf="currentCollaborator?.email">

                                        <p class="read">
                                            <strong>{{ 'collaborator.email.value' | translate }} : </strong>
                                            {{ currentCollaborator?.email }}
                                        </p>

                                    </ng-container>

                                    <!-- Téléphone -->

                                    <ng-container *ngIf="currentCollaborator?.switchboardPhone">

                                        <p class="read">
                                            <strong>{{ 'collaborator.phone.value' | translate }} : </strong>
                                            {{ currentCollaborator?.switchboardPhone }}
                                        </p>

                                    </ng-container>

                                    <!-- Adresse -->

                                    <ng-container *ngIf="currentCollaborator?.address">

                                        <p class="read">
                                            <strong>{{ 'collaborator.address.value' | translate }} : </strong>
                                            {{ currentCollaborator?.address.address }}, {{ currentCollaborator?.address.zipcode }} {{ currentCollaborator?.address.city }}
                                        </p>

                                    </ng-container>

                                </div>

                            </div>

                        </div>

                    </ng-container>

                    <ng-container *ngIf="this.booking.offerOwnerSociety?.id !== this.booking.responsibleSociety?.id">

                        <!-- Statut distributeur d'offre-->

                        <div class="ligne_form row_marges">
                            <div class="col_12">
                                <div class="form-group">
                                    <select class="form-control" formControlName="distributorStatus" id="distributorStatus" required>
                                        <option *ngFor="let item of statusItems" [value]="item.id">{{ item.label | translate }}</option>
                                    </select>
                                    <label class="required" for="distributorStatus">{{ 'booking.list.fields.status.distributor.value' | translate }}</label>
                                    <mat-error *ngIf="form.get('distributorStatus').invalid && (form.get('distributorStatus').dirty || form.get('distributorStatus').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('distributorStatus').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">

                            <div class="col_12">

                                <div class="form-group">

                                    <textarea class="form-control" formControlName="commentResponsibleSociety" id="commentResponsibleSociety"></textarea>

                                    <label for="commentResponsibleSociety">{{'booking.read.fields.commentResponsibleSociety.value' | translate}}</label>

                                </div>

                            </div>

                        </div>

                    </ng-container>

                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<ng-template #offerData>

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <!-- Nom de l'offre -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.offerName.value' | translate }} : </strong>
                    <a *ngIf="!!booking.offer" [routerLink]="['/account/offer/read', booking.offer.id]" [queryParams]="{ origin: 'bookingRead', booking: booking.id }">{{ translationService.getFallbackTranslation(booking.offerData.translations).name}}</a>
                    <span *ngIf="!booking.offer">{{ translationService.getFallbackTranslation(booking.offerData.translations).name}}</span>
                </p>

            </div>
        </div>
    </div>

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <p class="read">
                    <strong>{{ 'booking.read.fields.description.value' | translate }} : </strong>
                </p>

                <div class="read">
                    <div class="txt_offer">
                        <p [innerHTML]="translationService.getFallbackTranslation(booking.offerData.translations).description|safeHtml"></p>
                    </div>
                </div>

                <p class="read">
                    <strong>{{ 'offer.form.fields.included.value' | translate }} : </strong>
                </p>

                <div class="read">
                    <div *ngFor="let included of translatedIncludedsItemFields" class="txt_offer">

                        <p class="title_2">{{ included?.title }}</p>

                        <p>{{ included?.description }}</p>

                    </div>
                </div>

                <p class="read">
                    <strong>{{ 'offer.form.fields.included.no.value' | translate }} : </strong>
                </p>

                <div class="read">
                    <div *ngFor="let included of translatedNotIncludedsItemFields" class="txt_offer little">

                        <p class="title_2">{{ included?.title }}</p>

                        <p>{{ included?.description }}</p>

                    </div>
                </div>

            </div>
        </div>
    </div>

</ng-template>

<ng-template #stayData>

    <!-- Données Générales -->

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <ng-container *ngIf="isTypeRequest && (booking.referenceResponsibleSociety !== null)">

                    <!-- Référence distributeur demande de devis -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.referenceResponsibleSociety.value' | translate }} : </strong>
                        {{booking.referenceResponsibleSociety}}
                    </p>

                </ng-container>

                <!-- N° de dossier -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.reference.value' | translate }} : </strong>
                    {{booking.reference}}
                </p>

                <ng-container *ngIf="isTypeBooking">

                    <!-- Bon cadeau Oui / Non -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.giftVoucher.value' | translate }} : </strong>
                        {{  'booking.read.fields.giftVoucher.isGiftVoucher.' + (booking.giftVoucher ? 'yes' : 'no') + '.value' | translate }}
                    </p>

                    <!-- Numéro du bon cadeau -->

                    <ng-container *ngIf="booking.giftVoucher">

                        <p class="read">
                            <strong>{{ 'giftVoucher.read.fields.code.value' | translate }} : </strong>
                            {{ booking.giftVoucher.code }}
                        </p>

                    </ng-container>

                </ng-container>

                <!-- Date de la demande -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.createdAt.value' | translate }} : </strong>
                    {{ (booking.createdAt | date : 'fullDate' : null: localeId) | titlecase }}
                </p>

                <!-- Type de demande -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.type.value' | translate }} : </strong>
                    <ng-container *ngIf="!booking.giftVoucher && !isOnlineSale"> {{ 'booking.type.' + booking.type + '.value' | translate}}</ng-container>
                    <ng-container *ngIf="isOnlineSale"> {{ 'booking.type.onlineSale.value' | translate}}</ng-container>
                    <ng-container *ngIf="booking.giftVoucher && !booking.hasStock">{{ 'booking.read.fields.giftVoucher.value' | translate}}</ng-container>
                </p>

                <p class="read">
                    <strong>{{ 'booking.read.fields.offerName.value' | translate }} : </strong>
                    <span>{{ translationService.getFallbackTranslation(booking.offerData.translations).name}}</span>
                </p>

                <ng-container *ngIf="isTypeRequest">

                    <!-- Langue parlée par le client -->

                    <p class="read" *ngIf="hasAccessCustomer">
                        <strong>{{ 'booking.read.fields.customer.language.spoken.value' | translate }} : </strong>
                        {{ booking.customer.spokenLanguage }}
                    </p>

                    <!-- Budget par personne -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.budget.perPerson.value' | translate }} : </strong>
                        {{booking.composition.budget}} {{ booking.composition.currency.symbol }} <ng-container *ngIf="booking.composition.isFlexibleBudget !== null">({{ (booking.composition.isFlexibleBudget ? 'booking.read.fields.budget.type.flexible.value' : 'booking.read.fields.budget.type.fixed.value') | translate }})</ng-container>
                    </p>

                    <!-- Project client -->

                    <p class="read" *ngIf="hasAccessCustomer">
                        <strong>{{ 'booking.read.fields.client.project.value' | translate }} : </strong>
                        {{ booking.customer.comment }}
                    </p>

                </ng-container>

                <ng-container *ngIf="isTypeBooking">

                    <!-- Commentaire client -->

                    <ng-container *ngIf="hasAccessCustomer && booking.customer.comment?.length">

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerComment.value' | translate }} : </strong>
                            {{ booking.customer.comment }}
                        </p>

                    </ng-container>

                </ng-container>

            </div>
        </div>
    </div>

    <!-- Données Séjour -->

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <p class="read">
                    <strong class="subtitle_green">{{ 'booking.read.title.stay' | translate }}</strong>
                </p>

            </div>
        </div>
    </div>

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <div class="row marges ligne_form" *ngIf="booking.composition">

                    <div class="col_12 y_content_start">

                        <div>

                            <!-- Date de début de séjour -->

                            <p class="read">
                                <strong>{{ 'booking.read.fields.date.start.value' | translate }} : </strong>
                                {{ (composition.dateStart | date : 'fullDate' : null: localeId) | titlecase }}
                            </p>

                            <!-- Date de fin de séjour -->

                            <p class="read">
                                <strong>{{ 'booking.read.fields.date.end.value' | translate }} : </strong>
                                {{ (composition.dateEnd | date : 'fullDate' : null : localeId) | titlecase }}
                            </p>

                            <ng-container *ngIf="isTypeRequest">

                                <!-- Flexibilité -->

                                <p class="read">
                                    <strong>{{ 'booking.read.fields.flexibility.value' | translate }} : </strong>
                                    {{ translationService.getFallbackTranslation(booking.composition.flexibility.translations).label }}
                                </p>

                            </ng-container>

                            <!-- Nombre d'adultes -->

                            <p class="read">
                                <strong>{{ 'booking.read.fields.adult.count.value' | translate }} : </strong>
                                {{ composition.nbAdult || 0 }}
                            </p>

                            <!-- Nombre d'enfants -->

                            <p class="read">
                                <strong>{{ 'booking.read.fields.child.count.value' | translate }} : </strong>
                                {{ composition.nbChild || 0 }}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Données Participants -->

                <ng-container *ngIf="composition && participants.length">

                    <div class="ligne_form row marges col_12">

                        <div class="col_12">

                            <div class="form-group">

                                <p class="read">
                                    <strong *ngIf="isTypeBooking" class="subtitle_green">{{ 'booking.read.title.participant.plural.value' | translate }}</strong>
                                    <strong *ngIf="isTypeRequest" class="subtitle_green">{{ 'booking.read.title.participant.children.plural.value' | translate }}</strong>
                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="ligne_form row marges col_12">

                        <div class="col_12">

                            <div class="form-group">

                                <div class="row marges">

                                    <div class="col_3 y_content_start" *ngFor="let participant of participants">

                                        <div class="read_composition_option" style="width: 100%">

                                            <ng-container *ngIf="isTypeBooking">

                                                <!-- Type -->

                                                <p class="read">
                                                    <strong>{{ 'type.value' | translate }} : </strong>
                                                    {{ (participant.type + '.value') | translate }}
                                                </p>

                                                <!-- Nom -->

                                                <ng-container *ngIf="participant.lastName">

                                                    <p class="read">
                                                        <strong>{{ 'user.lastName.value' | translate }} : </strong>
                                                        {{ participant.lastName }}
                                                    </p>

                                                </ng-container>

                                                <!-- Prénom -->

                                                <ng-container *ngIf="participant.firstName">

                                                    <p class="read">
                                                        <strong>{{ 'user.firstName.value' | translate }} : </strong>
                                                        {{ participant.firstName }}
                                                    </p>

                                                </ng-container>

                                            </ng-container>

                                            <!-- Date de naissance -->

                                            <ng-container *ngIf="participant.birthDay">

                                                <p class="read">
                                                    <strong>{{ 'offer.form.fields.birthdate.value' | translate }} : </strong>
                                                    {{ (participant.birthDay | date : 'fullDate' : null: localeId) | titlecase }}
                                                </p>

                                            </ng-container>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </ng-container>

                <div class="row marges ligne_form" *ngIf="composition.permanentOptions.length || (!composition.permanentOptions.length && booking.optoutPermanentOptions)">

                    <div class="col_12 y_content_start">

                        <div>

                            <!-- Option Permanentes -->

                            <ng-container *ngIf="composition.permanentOptions.length">

                                <p class="read">
                                    <strong class="subtitle_green">{{ 'booking.read.title.permanentOptionData' | translate }}</strong>
                                </p>

                                <br>

                                <div *ngFor="let compositionPermanentOption of composition.permanentOptions" class="read_composition_option">

                                    <!-- Nom -->

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.compositionPermanentOption.permanentOption.name.value' | translate }} : </strong>
                                        {{ translationService.getFallbackTranslation(compositionPermanentOption.permanentOptionData.translations).title }} <ng-container *ngIf="compositionPermanentOption.permanentOptionData.reference !== null">({{ compositionPermanentOption.permanentOptionData.reference }})</ng-container>
                                    </p>

                                    <!-- Nombre d'adulte(s) -->

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.adult.count.value' | translate }} : </strong>
                                        {{ composition.nbAdult || 0 }}
                                    </p>

                                    <!-- Nombre d'enfant(s) -->

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.child.count.value' | translate }} : </strong>
                                        {{ composition.nbChild || 0 }}
                                    </p>

                                    <!-- Prix -->

                                    <ng-container *ngIf="compositionPermanentOption.priceTTC">

                                        <p class="read">
                                            <strong>{{ 'booking.read.fields.compositionPermanentOption.permanentOption.price.ttc.value' | translate }} : </strong>
                                            {{ parsePriceTTC(compositionPermanentOption.priceTTC, composition.currency) }}

                                            <ng-container *ngIf="booking.type === 'booking' && !! compositionPermanentOption.priceHT">

                                                ({{ parsePriceHT(compositionPermanentOption.priceHT, composition.currency) }})

                                            </ng-container>
                                        </p>

                                    </ng-container>

                                    <!-- Option sur devis sans tarif défini -->

                                    <ng-container *ngIf="compositionPermanentOption.onRequest">
                                        <p class="read">
                                            <strong>{{ 'booking.read.fields.compositionPermanentOption.permanentOption.onRequest.value' | translate }}</strong>
                                        </p>
                                    </ng-container>

                                </div>

                            </ng-container>

                            <ng-container *ngIf="!composition.permanentOptions.length && booking.optoutPermanentOptions">

                                <p class="read">
                                    <strong class="subtitle_green">{{ 'booking.read.title.permanentOptionData' | translate }}</strong>
                                </p>

                                <p class="read">
                                    {{ 'booking.read.optoutPermanentOptions.checked.value' | translate }}
                                </p>

                            </ng-container>
                        </div>

                    </div>

                </div>

                <div class="row marges ligne_form" *ngIf="booking.composition">

                    <div class="col_12 y_content_start">

                        <div>

                            <ng-container *ngIf="isTypeBooking">

                                <!-- Données tarifaires -->

                                <p class="read">
                                    <strong class="subtitle_green">{{ 'booking.read.data.pricing.value' | translate }}</strong>
                                </p>

                                <br>

                                <!-- Prix dans le cas d'une demande de réservation -->

                                <!-- Promotion appliquée -->

                                <ng-container *ngIf="booking.promotionData">

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.promotion.code.value' | translate }} : </strong>
                                        <span>{{ booking.promotionData.code }}</span>
                                    </p>

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.promotionCode.value' | translate }} : </strong>
                                        <span>{{ getPromotion(booking.promotionData) }}</span>
                                    </p>

                                </ng-container>

                                <ng-container *ngIf="composition.promotionOfferCreatorData">

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.promotion.value' | translate }} : </strong>
                                        <span>{{ getPromotion(composition.promotionOfferCreatorData) }}</span>
                                    </p>

                                </ng-container>

                                <ng-container *ngIf="composition.promotionDistributorData">

                                    <p class="read">
                                        <strong>{{ 'booking.read.fields.promotion.value' | translate }} : </strong>
                                        <span>{{ getPromotion(composition.promotionDistributorData) }}</span>
                                    </p>

                                </ng-container>

                                <ng-template #netPrices>

                                    <!-- Montant total Net HT -->

                                    <p class="read" *ngIf="composition.totalNetPriceHT">
                                        <strong>{{ 'booking.read.fields.price.net.ht.value' | translate }} : </strong>
                                        <span>{{ parsePriceHT(composition.totalNetPriceHT, composition.currency) }}</span>
                                    </p>

                                    <!-- Montant total Net TTC -->

                                    <p class="read" *ngIf="composition.totalNetPriceTTC">
                                        <strong>{{ 'booking.read.fields.price.net.ttc.value' | translate }} : </strong>
                                        <span>{{ parsePriceTTC(composition.totalNetPriceTTC, composition.currency) }}</span>
                                    </p>

                                </ng-template>

                                <ng-container *ngIf="isOfferOwner || isInCommercialRelationshipCharge || isAdmin()">

                                    <ng-container [ngSwitch]="true">

                                        <ng-container *ngSwitchCase="userService.hasOneOfThisRoles(user, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && isOfferOwner && isInCommercialRelationshipCharge">
                                            <!-- Ne pas afficher les prix NET -->
                                        </ng-container>

                                        <ng-container *ngSwitchCase="userService.hasOneOfThisRoles(user, ['ROLE_OFFER_CREATOR']) && isInCommercialRelationshipCharge">
                                            <!-- Ne pas afficher les prix NET -->
                                        </ng-container>

                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngTemplateOutlet="netPrices"></ng-container>
                                        </ng-container>

                                    </ng-container>

                                </ng-container>

                                <ng-container *ngIf="!(isOfferOwner && !isInCommercialRelationshipCharge)">

                                    <ng-container *ngIf="!userService.hasOneOfThisRoles(user, ['ROLE_OFFER_CREATOR'])">

                                        <!-- Montant total Net HT hors assurance -->

                                        <p class="read" *ngIf="composition.priceHT && composition.permanentOptions.length > 0">
                                            <strong>{{ 'booking.read.fields.price.ht.without_permanent_option.value' | translate }} : </strong>
                                            <span>{{ parsePriceHT(composition.priceHT, composition.currency) }}</span>
                                        </p>

                                        <!-- Montant total Net TTC hors assurance -->

                                        <p class="read" *ngIf="composition.priceTTC && composition.permanentOptions.length > 0">
                                            <strong>{{ 'booking.read.fields.price.ttc.without_permanent_option.value' | translate }} : </strong>
                                            <span>{{ parsePriceTTC(composition.priceTTC, composition.currency) }}</span>
                                        </p>

                                    </ng-container>

                                    <!-- Montant total HT -->

                                    <p class="read" *ngIf="composition.totalPriceHT">
                                        <strong>{{ (composition.permanentOptions.length ? 'booking.read.fields.price.ht.with_permanent_option.value' : 'booking.read.fields.price.ht.value') | translate }} : </strong>
                                        <span>
                                            {{ parsePriceHT(composition.totalPriceHT, composition.currency) }}
                                            <ng-container *ngIf="hasOnePermanentOptionOnRequest">
                                                ({{ 'booking.read.fields.price.permanentOption.onRequest.value' | translate }})
                                            </ng-container>
                                        </span>
                                    </p>

                                    <!-- Montant total TTC -->

                                    <p class="read" *ngIf="composition.totalPriceTTC">
                                        <strong>{{ (composition.permanentOptions.length ? 'booking.read.fields.price.ttc.with_permanent_option.value' : 'booking.read.fields.price.ttc.value') | translate }} : </strong>
                                        <span>
                                            {{ parsePriceTTC(composition.totalPriceTTC, composition.currency) }}
                                            <ng-container *ngIf="hasOnePermanentOptionOnRequest">
                                                ({{ 'booking.read.fields.price.permanentOption.onRequest.value' | translate }})
                                            </ng-container>
                                        </span>
                                    </p>

                                </ng-container>

                                <!-- Taux de commission HT applicable -->

                                <p class="read">
                                    <strong>{{ 'booking.read.fields.commission.ht.description.value' | translate }} : </strong>
                                    <span>{{ booking.commission ? parsedMarkup(booking.commission * 100) : 'N/A' }}</span>
                                </p>

                                <!-- Taux de commission TTC applicable -->

                                <p class="read">
                                    <strong>{{ 'booking.read.fields.commission.description.value' | translate }} : </strong>
                                    <span>{{ booking.commissionTTC ? parsedMarkup(booking.commissionTTC * 100) : 'N/A' }}</span>
                                </p>

                                <!-- Montant de la commission TTC -->

                                <p class="read">
                                    <strong>{{ 'booking.read.fields.amountCommission.description.value' | translate }} : </strong>
                                    <span>{{ booking.amountCommissionTTC ? parsePriceTTC(booking.amountCommissionTTC, composition.currency) : 'N/A' }}</span>
                                </p>

                                <!-- Commission encaissée -->

                                <p class="read">
                                    <strong>{{ 'booking.read.fields.isPaidCommission.value' | translate }} : </strong>
                                    <span>{{ ((booking.isPaidCommission ? 'yes' : 'no') + '.value') | translate }}</span>
                                </p>

                            </ng-container>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</ng-template>

<ng-template #distributorContactDetails>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges">

            <div class="col_12">

                <div class="form-group">

                    <!-- Nom du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channelName.value' | translate }} : </strong>
                        {{ booking.channel ? translationService.getFallbackTranslation(booking.channel.translations).name : ( booking.giftVoucher ? ('booking.read.fields.channelName.giftVoucher.value' | translate) : ('booking.read.fields.channelName.none.value' | translate))}}
                    </p>

                    <!-- Site internet -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channelMarketplace.value' | translate }} : </strong>
                        {{ (booking.channel && booking.channel.marketplace) ? booking.channel.marketplace.website : (booking.giftVoucher ? giftVoucherUrl : originUrl) }}
                    </p>

                    <!-- Nom du propriétaire du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channel.owner.name.value' | translate }} : </strong>
                        {{ booking.channel ? booking.channel.society.name : booking.responsibleSociety.name }}
                    </p>

                    <!-- Email du propriétaire du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channel.owner.email.value' | translate }} : </strong>
                        {{ booking.channel ? booking.channel.society.email : booking.responsibleSociety.email }}
                    </p>

                    <!-- Téléphone du propriétaire du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channel.owner.phone.value' | translate }} : </strong>
                        {{ booking.channel ? booking.channel.society.phone : booking.responsibleSociety.phone }}
                    </p>

                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<ng-template #creatorContactDetails>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">

                <!-- Nom du créateur d'offre -->

                <p class="read" *ngIf="booking.offerData.societyName">
                    <strong>{{ 'booking.read.fields.offerProductor.value' | translate }} : </strong>
                    {{ booking.offerData.societyName }}
                </p>

                <!-- Email du créateur d'offre -->

                <p class="read" *ngIf="booking.offerData.societyEmail">
                    <strong>{{ 'booking.read.fields.offerProductor.email.value' | translate }} : </strong>
                    {{ booking.offerData.societyEmail }}
                </p>

                <!-- Téléphone du créateur d'offre -->

                <p class="read" *ngIf="booking.offerData.societyPhone">
                    <strong>{{ 'booking.read.fields.offerProductor.phone.value' | translate }} : </strong>
                    {{ booking.offerData.societyPhone }}
                </p>

            </div>

        </div>

    </div>

</ng-template>

<ng-template #clientData>

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <ng-container *ngIf="booking.customer.society">

                    <!-- Nom de la structure -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.customerSociety.value' | translate }} : </strong>
                        {{ booking.customer.society }}<ng-container *ngIf="booking.customer.customerType"> ({{ translationService.getFallbackTranslation(booking.customer.customerType.translations).label }})</ng-container>
                    </p>

                </ng-container>

                <ng-container *ngIf="booking.customer.civility !== null">

                    <!-- Civilité -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.civility.value' | translate }} : </strong>
                        {{ (booking.customer.civility === 1 ? 'form.user.fields.civility.mister.value' : 'form.user.fields.civility.madam.value') | translate }}
                    </p>

                </ng-container>

                <ng-container *ngIf="booking.customer.lastName">

                    <!-- Nom -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.customerName.value' | translate }} : </strong>
                        {{ booking.customer.lastName }}
                    </p>

                </ng-container>

                <ng-container *ngIf="booking.customer.firstName">

                    <!-- Prénom -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.customerFirstName.value' | translate }} : </strong>
                        {{ booking.customer.firstName }}
                    </p>

                </ng-container>

                <ng-container *ngIf="isInCommercialRelationshipCharge || userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PROVIDER'])">

                    <ng-container *ngIf="booking.customer.address">

                        <!-- Adresse -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerAddress.value' | translate }} : </strong>
                            {{ booking.customer.address }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.additionalAddress">

                        <!-- Complément d'adresse -->

                        <p class="read" *ngIf="booking.customer.additionalAddress">
                            <strong>{{ 'booking.read.fields.customerAdditionalAddress.value' | translate }} : </strong>
                            {{ booking.customer.additionalAddress }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.zipcode">

                        <!-- Code postal -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerZipcode.value' | translate }} : </strong>
                            {{ booking.customer.zipcode }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.city">

                        <!-- Ville -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerCity.value' | translate }} : </strong>
                            {{ booking.customer.city }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.region">

                        <!-- Région -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerRegion.value' | translate }} : </strong>
                            {{ booking.customer.region }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.country">

                        <!-- Pays -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerCountry.value' | translate }} : </strong>
                            {{ getCountryTranslation(booking.customer.country)?.name }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.email">

                        <!-- Email -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerEmail.value' | translate }} : </strong>
                            {{ booking.customer.email }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.phone">

                        <!-- Téléphone -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.customerFirstPhone.value' | translate }} : </strong>
                            {{ booking.customer.phone }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.spokenLanguage">

                        <!-- Langue parlée -->

                        <p class="read">
                            <strong>{{ 'booking.read.fields.language.spoken.value' | translate }} : </strong>
                            {{ ('locale.' + booking.customer.spokenLanguage + '.value') | translate }}
                        </p>

                    </ng-container>

                    <ng-container *ngIf="booking.customer.hasOtherSpokenLanguages">

                        <!-- Autres langues parlées -->

                        <p class="read">
                            <strong>{{ (booking.customer.spokenLanguage === null ? 'booking.read.fields.language.spoken.value' : 'language.plural.spoken.other.value') | translate }} : </strong>
                            {{ booking.customer.otherSpokenLanguages }}
                        </p>

                    </ng-container>

                </ng-container>

            </div>

        </div>

    </div>

</ng-template>
