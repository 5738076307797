<ng-container [formGroup]="form">

    <ng-container [formGroup]="control">

        <ng-container *ngIf="enableMaxField">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <input  type="text" value="" placeholder=" " class="form-control" formControlName="max" id="{{ DOMIdentifier }}-max">
                        <label for="{{ DOMIdentifier }}-max">{{'offer.presential.max.value' | translate}}</label>
                        <mat-error *ngIf="control.get('max').errors && (control.get('max').dirty || control.get('max').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="control.get('max').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="control.get('max').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                <div *ngIf="control.get('max').errors.max">{{ 'form.control.error.max.value' | translate:{max: presentialMax} }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <br>

        </ng-container>

        <div class="row marges ligne_form" *ngIf="form.get('publics').value.includes('adult')">

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="adultMin" id="{{ DOMIdentifier }}-adultMin">
                    <label for="{{ DOMIdentifier }}-adultMin">{{'offer.presential.adultMin.value' | translate}}</label>
                    <mat-error *ngIf="control.get('adultMin').errors && (control.get('adultMin').dirty || control.get('adultMin').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('adultMin').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('adultMin').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="adultMax" id="{{ DOMIdentifier }}-adultMax">
                    <label for="{{ DOMIdentifier }}-adultMax">{{'offer.presential.adultMax.value' | translate}}</label>
                    <mat-error *ngIf="control.get('adultMax').errors && (control.get('adultMax').dirty || control.get('adultMax').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('adultMax').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('adultMax').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="adultIncrementalStep" id="{{ DOMIdentifier }}-adultIncrementalStep">
                    <label for="{{ DOMIdentifier }}-adultIncrementalStep">{{'offer.presential.adultIncrementalStep.value' | translate}}</label>
                    <mat-error *ngIf="control.get('adultIncrementalStep').errors && (control.get('adultIncrementalStep').dirty || control.get('adultIncrementalStep').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('adultIncrementalStep').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('adultIncrementalStep').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="adultDefault" id="{{ DOMIdentifier }}-adultDefault">
                    <label for="{{ DOMIdentifier }}-adultDefault">{{'offer.presential.adultDefault.value' | translate}}</label>
                    <mat-error *ngIf="control.get('adultDefault').errors && (control.get('adultDefault').dirty || control.get('adultDefault').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('adultDefault').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('adultDefault').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form" *ngIf="form.get('publics').value.includes('child')">
            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="childMin" id="{{ DOMIdentifier }}-childMin">
                    <label for="{{ DOMIdentifier }}-childMin">{{'offer.presential.childMin.value' | translate}}</label>
                    <mat-error *ngIf="control.get('childMin').errors && (control.get('childMin').dirty || control.get('childMin').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('childMin').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('childMin').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="childMax" id="{{ DOMIdentifier }}-childMax">
                    <label for="{{ DOMIdentifier }}-childMax">{{'offer.presential.childMax.value' | translate}}</label>
                    <mat-error *ngIf="control.get('childMax').errors && (control.get('childMax').dirty || control.get('childMax').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('childMax').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('childMax').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="childIncrementalStep" id="{{ DOMIdentifier }}-childIncrementalStep">
                    <label for="{{ DOMIdentifier }}-childIncrementalStep">{{'offer.presential.childIncrementalStep.value' | translate}}</label>
                    <mat-error *ngIf="control.get('childIncrementalStep').errors && (control.get('childIncrementalStep').dirty || control.get('childIncrementalStep').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('childIncrementalStep').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('childIncrementalStep').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_3">
                <div class="form-group">
                    <input  type="text" value="" placeholder=" " class="form-control" formControlName="childDefault" id="{{ DOMIdentifier }}-childDefault">
                    <label for="{{ DOMIdentifier }}-childDefault">{{'offer.presential.childDefault.value' | translate}}</label>
                    <mat-error *ngIf="control.get('childDefault').errors && (control.get('childDefault').dirty || control.get('childDefault').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="control.get('childDefault').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="control.get('childDefault').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="form-group col_content y_items_center" *ngIf="form.get('publics').value.includes('adult') || form.get('publics').value.includes('child')">
            <span class="message-help">
                <mat-icon class="tooltip_icon">info</mat-icon>
                {{ 'offer.public.info.value' | translate }}
            </span>
            <span class="message-help">
                {{ 'offer.public.info.exemple.value' | translate }}
            </span>
        </div>

        <div *ngIf="!form.get('publics').value.includes('adult') && !form.get('publics').value.includes('child')">
            {{ 'offer.option.public.choice.value' | translate}}
        </div>

    </ng-container>

</ng-container>
