import {Injectable} from '@angular/core';
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";

@Injectable({
    providedIn: 'root'
})
export class MarketplacePreferenceService {

    constructor() {
    }

    public getItemByFilterFromCollection(filter: string, collection: MarketplacePreference[]): MarketplacePreference {

        return collection.find((item: MarketplacePreference): boolean => {

            return item.filter === filter

        }) || null;
    }
}
