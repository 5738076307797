import {Component, OnInit} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Society} from "@core/shared/models/society";
import {PaymentInitialize} from "@core/shared/models/payment-initialize";
import {browserInfos} from "@core/shared/utils/browser-infos";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {TermsAndConditionsService} from "@core/shared/services/terms-and-conditions.service";
import {CardService} from "@core/shared/services/card.service";

@Component({
    selector: 'app-core-page-card-create',
    templateUrl: './page-card-create.component.html',
    styleUrls: ['./page-card-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageCardCreateComponent implements OnInit {

    private _cardRegistrationUrl: string = null;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _cardService: CardService,
        private _translateService: TranslateService,
        public termsAndConditionsService: TermsAndConditionsService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this._paymentInitialize();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            acceptTermsAndConditions: [false, [Validators.requiredTrue]],
            creditCard: this._formBuilder.group({
                accessKey: [],
                data: [],
                id: []
            })
        });

        this.formService.submitCallback = (): void => {

            const dataToCardRegistration = `cardNumber=${this.creditCardControl.get('cardNumber').value.replace(/\s/g, '')}&cardExpirationDate=${this.creditCardControl.get('cardExpirationDate').value.replace('/', '')}&cardCvx=${this.creditCardControl.get('cardCvx').value}&accessKeyRef=${this.creditCardControl.get('accessKey').value}&data=${this.creditCardControl.get('data').value}`;

            this._cardService.registrationUrlItem(this._cardRegistrationUrl, dataToCardRegistration).subscribe((cardRegistrationData: string) => {

                this._initPaymentValidate(cardRegistrationData);
            });
        };
    }

    private _paymentInitialize() {

        this._cardService.societyItemInitialize(this.society.id).subscribe((paymentInitialize: PaymentInitialize) => {

            this._cardRegistrationUrl = paymentInitialize.url;

            this.creditCardControl.patchValue({
                accessKey: paymentInitialize.accessKeyRef,
                data: paymentInitialize.data,
                id: paymentInitialize.id
            });
        });
    }

    private _initPaymentValidate(cardRegistrationData: string): void {

        const dataToValidate: object = {
            "cardRegistration": {
                "data": cardRegistrationData,
                "id": this.creditCardControl.get('id').value
            },
            "browserInfo": browserInfos()
        };

        this._cardService.societyItemValidate(this.society.id, dataToValidate).pipe(
            catchError((): Observable<never> => {

                this._paymentInitialize();

                return throwError(this._translateService.instant('payment.error.card.value'));
            })
        ).subscribe((data: { status: string, redirectUrl: string }): void => {

            if (data.status === 'REDIRECT') {

                location.href = data.redirectUrl;
            }

            if (data.status === 'SUCCESS') {

                this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'card' }});
            }
        });
    }

    public redirectToCard(): void {

        this._router.navigate(['account/card/read']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get creditCardControl(): UntypedFormGroup {

        return this.form.get('creditCard') as UntypedFormGroup;
    }

    get society(): Society {

        return this._activatedRoute.snapshot.data.society;
    }
}
