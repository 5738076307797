import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Society} from '@core/shared/models/society';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyService} from '@core/shared/services/society.service';

@Injectable({
    providedIn: 'root'
})
export class SocietyItemResolver  {

    constructor(
        private _societyService: SocietyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._societyService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
