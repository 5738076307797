<div class="thumb-input" *ngIf="configInitialized && content">
    <div class="full">
        <span class="label">{{ 'element.types.form.id' | translate }}</span>
        <select [(ngModel)]="content.form" (ngModelChange)="setContent(content)" [compareWith]="compareByOptionClass">
            <option [ngValue]="null" disabled selected>--</option>
            <option *ngFor="let form of forms" [ngValue]="form">
                {{ form.label }}
            </option>
        </select>
    </div>
</div>
