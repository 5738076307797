import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "@core/shared/services/translation.service";
import {map} from "rxjs/operators";
import {restrictLocales} from "@core/shared/utils/locale";

@Component({
    selector: 'app-core-locale-switch',
    templateUrl: './locale-switch.component.html',
    styleUrls: ['./locale-switch.component.scss']
})
export class LocaleSwitchComponent implements OnInit {

    public locale: string;

    public locales$: Observable<LocaleItem[]>;

    constructor(
        private _translateService: TranslateService,
        private _translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this.locale = this._translateService.currentLang;

        this._initLocales();
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS).pipe(
            map((localeItems: LocaleItem[] ):LocaleItem[] => restrictLocales(localeItems) )
        );

    }

    public switchLocale(): void {

        this._translationService.onLangChange.next(this.locale);
    }
}
