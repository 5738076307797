import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {InputConfig} from "../input";
import {ImageConfig, OptionsImage} from "../image/image.component";
import {HttpClient} from "@angular/common/http";
import {Gallery} from "@lib/media/gallery";
import {Image} from "@lib/media/image/image";

export interface ImageCollectionConfig extends InputConfig {

    uploadApiUrl: string;

    gallery?: Gallery;

    gallery_context?: string;

    required: boolean;

    help? : string;

    min: number;

    max: number;

    options?: OptionsImage;

}

@Component({
    selector: 'form-field-image-collection',
    templateUrl: './image-collection.component.html',
    styleUrls: ['./image-collection.component.scss']
})
export class ImageCollectionComponent implements OnInit {

    @Input() config: ImageCollectionConfig;

    /**
     * Valeur du champ texte
     */
    @Input() images : Image[];

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    constructor(private _http: HttpClient) {

    }

    ngOnInit() {

        let options: OptionsImage = {
            enableTitle: false,
            enableSubtitle: false,
            enableAlt: false,
            enableLink: false,
            enableTargetBlank: false,
            format: null
        };

        options = Object.assign(this.config.options, {
            enableTitle: this.config.options.enableTitle,
            enableSubtitle: this.config.options.enableSubtitle,
            enableAlt: this.config.options.enableAlt,
            enableLink: this.config.options.enableLink,
            enableTargetBlank: this.config.options.enableTargetBlank,
            format: this.config.options.format || null
        });

        this.config.options = options;

        if (this.config.gallery_context) {
            this._http.get<ImageConfig>(`/api/galleries/image/${this.config.gallery_context}`).subscribe((config: ImageConfig) => {
                this.config.gallery = config.gallery;
            });
        }

        if((typeof this.images === 'undefined')){
            this.images = [];
        }

        this.form.addControl(this.config.id, new UntypedFormControl('', this.limitValidator.bind(this)));

        this.form.valueChanges.subscribe( () => {
            if(this.form.value[this.config.id]) {
                if((typeof this.form.value[this.config.id].images === 'undefined')){
                    this.images = this.form.value[this.config.id];
                }else{
                    this.images = this.form.value[this.config.id].images;
                }
            }
        });
    }

    /**
     * @param images
     */
    add(images: Image[]): void {

        this.images = images;

        this.form.controls[this.config.id].setValue(this.images);
    }

    /**
     * @param images
     */
    delete(images: Image[]): void {

        this.images = images;

        this.form.controls[this.config.id].setValue(this.images);
    }

    /**
     * @param images
     */
    changePosition(images: Image[]): void {

        this.images = images;

        this.form.controls[this.config.id].setValue(this.images);
    }

    /**
     * @param control
     */
    limitValidator(control: AbstractControl): object|null {

        if(control.value.length < this.config.min){

            return { invalidMin: true };
        }
        else if(control.value.length > this.config.max){

            return { invalidMax: true };
        }
        else {

            return null;
        }
    }
}
