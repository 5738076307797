import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";

@Component({
    selector: 'app-core-select-boolean-filter',
    templateUrl: './select-boolean-filter.component.html',
    styleUrls: ['./select-boolean-filter.component.scss']
})
export class SelectBooleanFilterComponent implements OnInit, OnChanges {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() truthyLabel: string;

    @Input() falsyLabel: string;

    @Input() disableAutoSubmit: boolean;

    value: string;

    filterField: TextFilterField;

    items: { identifier: number, label: string }[];

    constructor() {

        this.value = null;
    }

    ngOnInit(): void {

        this.filterField = new TextFilterField(this.key, 'eq');

        this.builder.addField(this.filterField);
    }

    ngOnChanges(): void {

        this._initOptions();
    }

    private _initOptions(): void {

        this.items = [
            {
                identifier: 1,
                label: this.truthyLabel
            },
            {
                identifier: 0,
                label: this.falsyLabel
            }
        ];
    }

    public hydrateValue(): void {

        this.filterField.value = (this.value === null || this.value === undefined) ? null : this.value;
    }

    public filter(): void {

        this.hydrateValue();

        if(this.disableAutoSubmit){

            return;
        }

        this.builder.filter();
    }

    public reset(): void {

        this.value = null;
    }
}
