import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Pagination} from "../models/pagination";
import {Access} from "@core/shared/models/access";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AccessService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public getItemsAPI(): Observable<Access[]> {

        return this.getPaginationItemsAPI().pipe(
            map((response: Access[]): Access[] => {

                return response;
            })
        );
    }

    public getManagedItemsAPI(): Observable<Access[]> {

        return this.getPaginationItemsAPI(['isSystem[eq]=0']).pipe(
            map((response: Access[]): Access[] => {
                return response;
            })
        );
    }



    public getPaginationItemsAPI(params?: string[]): Observable<Access[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/accesses`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Access[]>(url, {
            headers: headers
        });
    }
}
