import timezones from "timezones-list";

export const TIMEZONES = timezones.map((item:{ label: string, tzCode: string }): {label: string, value: string} => {

    return {
        label: item.label,
        value: item.tzCode
    };

}).sort((a: {label: string, value: string}, b: {label: string, value: string}): number => {

    return a.label.localeCompare(b.label);
});
