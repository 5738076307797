import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Location, LocationField, LocationFieldType} from "@core/shared/models/location";
import {MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from "@angular/material/legacy-autocomplete";
import {HttpClient} from "@angular/common/http";
import {fromEvent} from "rxjs";
import {debounceTime, map} from "rxjs/operators";

@Component({
    selector: 'app-core-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent implements AfterViewInit {

    /**
     * Liste des champs de localisation
     */
    @Input() fields: LocationField[] = [];

    /**
     * Événement déclenché lors de la sélection d'une localisation
     */
    @Output() onSelection: EventEmitter<Location> = new EventEmitter<Location>();

    /**
     * Accesseur pour le champ de saisie d'une localisation
     */
    @ViewChild('location', { static: true }) location: ElementRef;

    @ViewChild(MatAutocompleteTrigger, { static: true, read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

    /**
     * Liste des localisations retournées par l'API
     */
    private _items: Location[] = [];

    /**
     * @ignore
     *
     * @param _httpClient
     */
    constructor(private _httpClient: HttpClient){

        if (new.target === LocationComponent) {

            throw new Error('Cette classe ne peut être instanciée directement.')
        }
    }

    /**
     * @ignore
     */
    ngAfterViewInit(): void {

        this._init();
    }

    /**
     * Initialise la recherche d'une localisation
     */
    private _init(): void {

        fromEvent(this.location.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((search: string): void => {

                this.inputAutoComplete.closePanel();

                if(search.length > 2){

                    this._httpClient.get(this.apiUrl.replace('__QUERY__', search))
                        .pipe(
                            map(this.apiTransform)
                        )
                        .subscribe((items: Location[]): void => {

                            this._items = items;

                            this.inputAutoComplete.openPanel();
                        })
                    ;
                }
                else{

                    this._items = [];
                }
            })
        ;
    }

    /**
     * Retourne une liste de champs en fonction du type
     *
     * @param type
     */
    private _getFieldsByType(type: LocationFieldType): LocationField[] {

        return this.fields.filter((field: LocationField): boolean => {

            return field.type === type;
        });
    }

    /**
     * Réinitialise la liste des localisations retournées par l'API
     */
    private _clearItems(): void {

        this._items = [];
    }

    /**
     * Sélectionne une localisation
     *
     * @param location
     */
    public select(location: Location): void {

        this._getFieldsByType(LocationFieldType.Housenumber).forEach((field: LocationField): void => {

            field.reference.setValue(location.housenumber);
        });

        this._getFieldsByType(LocationFieldType.Street).forEach((field: LocationField): void => {

            field.reference.setValue(location.street);
        });

        this._getFieldsByType(LocationFieldType.Postcode).forEach((field: LocationField): void => {

            field.reference.setValue(location.postcode);
        });

        this._getFieldsByType(LocationFieldType.City).forEach((field: LocationField): void => {

            field.reference.setValue(location.city);
        });

        this._getFieldsByType(LocationFieldType.Department).forEach((field: LocationField): void => {

            field.reference.setValue(location.department);
        });

        this._getFieldsByType(LocationFieldType.Latitude).forEach((field: LocationField): void => {

            field.reference.setValue(location.latitude);
        });

        this._getFieldsByType(LocationFieldType.Longitude).forEach((field: LocationField): void => {

            field.reference.setValue(location.longitude);
        });

        this.location.nativeElement.value = '';

        this.location.nativeElement.blur();

        this._clearItems();

        this.onSelection.emit(location);
    }

    /**
     * Retourne l'URL d'accès à l'API
     *
     * Cette méthode doit être surchargée en fonction de l'API utilisée
     */
    get apiUrl(): string {

        throw new Error('Cette méthode doit être surchargée.');
    }

    /**
     * Retourne la méthode de transformation permettant de retourner une liste de localisations conformes
     *
     * Cette méthode doit être surchargée en fonction de l'API utilisée
     */
    get apiTransform(): (items: any) => Location[] {

        throw new Error('Cette méthode doit être surchargée.');
    }

    /**
     * Retourne la liste des localisations retournées par l'API
     */
    get items(): Location[] {

        return this._items;
    }

    /**
     * Met à jour la liste des localisations retournées par l'API
     *
     * @param value
     */
    set items(value: Location[]) {

        this._items = value;
    }
}
