import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DATE_FORMAT} from "@app/data";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {Promotion} from "@core/shared/models/promotion";
import * as moment from "moment";
import {PromotionDateService} from "@core/shared/services/promotion/promotion-date.service";
import {PromotionDate} from "@core/shared/models/promotion/promotion-date";
import Calendar from "js-year-calendar";
import CalendarDataSourceElement from "js-year-calendar/dist/interfaces/CalendarDataSourceElement";
import {map} from "rxjs/operators";
import {PromotionStatusType} from "@core/shared/models/promotion/promotion-status";
import {TranslateService} from "@ngx-translate/core";
import {Moment} from "moment/moment";

interface DataSource extends CalendarDataSourceElement {

    status: PromotionStatusType;
}

@Component({
    selector: 'app-core-promotion-period-calendar',
    templateUrl: './promotion-period-calendar.component.html',
    styleUrls: ['./promotion-period-calendar.component.scss']
})
export class PromotionPeriodCalendarComponent implements AfterViewInit {

    @Input() promotion: Promotion;

    @ViewChild('calendar', { static: true }) calendarRef: ElementRef<HTMLDivElement>;

    public datesInterval: { start: Moment, end: Moment } = {
        start: null,
        end: null
    };

    public calendar: Calendar<DataSource>;

    constructor(
        private _promotionDateService: PromotionDateService,
        private _translateService: TranslateService
    ) {
    }

    ngAfterViewInit() {

        this.calendar = new Calendar<DataSource>(this.calendarRef.nativeElement, {
            language: this._translateService.currentLang,
            style: 'custom',
            loadingTemplate: null,
            numberMonthsDisplayed: 2,
            startDate: moment().startOf('year').toDate(),
            // @ts-ignore
            dataSource: (data: { startDate: Date, endDate: Date }): Promise<DataSource[]> => {

                this.datesInterval = {
                    start: moment(data.startDate),
                    end: moment(data.endDate)
                };

                return this.dataSourcePromise;
            },
            customDataSourceRenderer: (element: HTMLDivElement, currentDate: Date, events: DataSource[]): void => {

                const event: DataSource|null = events[0];

                const className: string = event.status === 'available' ? 'available' : 'closed';

                element.closest('.day').classList.add(className);
            }
        });
    }

    public setNumberMonthsDisplayed(numberMonthDisplayed: number): void {

        this.calendar.setNumberMonthsDisplayed(numberMonthDisplayed);

        this.refreshCalendar();
    }

    public refreshCalendar(): void {

        if(this.isYearView){

            this.calendar.setStartDate(this.datesInterval.start.clone().startOf('year').toDate());
        }
        else{

            this.calendar.setStartDate(this.datesInterval.start.clone().toDate());
        }
    }

    public getNumberMonthsDisplayedElementClasses(numberMonthDisplayed: number): {[p: string]: boolean} {

        return {
            'actif' : this.calendar?.getNumberMonthsDisplayed() === numberMonthDisplayed
        };
    }

    get dataSourcePromise(): Promise<DataSource[]>{

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('dateStart', 'gte', this.datesInterval.start.format(DATE_FORMAT)));

        filterBuilder.addField(new TextFilterField('dateEnd', 'lte', this.datesInterval.end.format(DATE_FORMAT)));

        return this._promotionDateService.getItemsAPI(this.promotion.id, filterBuilder.serializedFilters).pipe(
            map((items: PromotionDate[]): DataSource[] => {

                return items.map((item: PromotionDate): DataSource => {

                    return {
                        startDate: item.date.toDate(),
                        endDate: item.date.toDate(),
                        status: item.status
                    }
                });
            })
        ).toPromise();
    }

    get isYearView(): boolean {

        return this.calendar.getNumberMonthsDisplayed() === 12;
    }
}
