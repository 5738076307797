import {Component, Input, OnInit } from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {TranslateService} from "@ngx-translate/core";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {User} from "@core/shared/models/user";
import {SocietyGiftVoucherConfiguration} from "@core/shared/models/gift-voucher/society-gift-voucher-configuration";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {ApiService} from "@core/shared/services/api.service";
import {TranslationBuilder} from "@core/shared/models/translation";
import {TemplateGiftVoucher } from "@core/shared/models/gift-voucher/template-gift-voucher";
import {TranslationService} from "@core/shared/services/translation.service";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { TemplateGiftVoucherDialogComponent } from "@core/components/gift-voucher/template-gift-voucher-dialog/template-gift-voucher-dialog.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";
import {Router} from "@angular/router";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {REGEX_ONLY_NUMBER} from "@core/shared/models/regex";

@Component({
    selector: 'app-gift-voucher-configuration-form',
    templateUrl: './gift-voucher-configuration-form.component.html',
    styleUrls: ['./gift-voucher-configuration-form.component.scss'],
    providers: [
        FormService
    ]
})
export class GiftVoucherConfigurationFormComponent implements OnInit {

    @Input() user: User;

    @Input() submit: boolean;

    public templatesGiftVouchers: TemplateGiftVoucher[] = [];

    public adminTemplatesGiftVouchers: TemplateGiftVoucher[] = [];

    public selectedTemplates: number[] = [];

    public configuration: SocietyGiftVoucherConfiguration;

    public validityDurations: { id: number, name: string }[] = [];

    public renewalDurations: { id: number, name: string }[] = [];

    public translationBuilder: TranslationBuilder;

    public templateFileConfig: ImageConfig;

    public items: GiftVoucher[];

    public defaultMinPercentRenewal = 0;

    public defaultMaxPercentRenewal = 100;

    constructor(
        public formService: FormService,
        public translationService: TranslationService,
        public giftVoucherService: GiftVoucherService,
        private _router: Router,
        private _templateVoucherService: TemplateGiftVoucherService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _apiService: ApiService,
        private _translateService: TranslateService,
        private _formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit(): void {

        this._initFileConfig();

        this._loadTemplateGiftVouchers();

        this._initForm();

        this._initEvents();

        this._loadConfiguration();

        this._initDurations();

    }

    private _loadConfiguration() {

        this.giftVoucherService.getGiftVoucherConfiguration(this.user.society.id).subscribe((data: SocietyGiftVoucherConfiguration) => {

            this.configuration = data;

            this._hydrateForm();
        });
    }

    private _loadTemplateGiftVouchers(): void {

        this.giftVoucherService.getTemplateGiftVouchersBySocietyWithoutPagination(this.user.society.id).subscribe((templates: TemplateGiftVoucher[]): void => {

            this.adminTemplatesGiftVouchers = templates.filter((template: TemplateGiftVoucher): boolean => {

                return !template.isMine;
            });

            this.templatesGiftVouchers = templates.filter((template: TemplateGiftVoucher): boolean => {

                return template.isMine;
            });
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            validityDuration: [null, [Validators.required]],
            enableRenewal: [false, [Validators.required]],
            renewalDuration: [null, [(control) => {

                if (!control) {
                    return null;
                }

                if (!this.form || !this.form.get('enableRenewal') || !this.form.get('enableRenewal').value) {

                    return null;
                }

                return (!control.value) ? {isRequired: {valid: false}} : null;
            }]],
            enableRenewalPayment: [{ value: false, disabled: !this.user.society.isValidMangoPayAccount }],
            renewalPercentPrice: [null, [
                Validators.pattern(REGEX_ONLY_NUMBER),
                Validators.min(this.defaultMinPercentRenewal),
                Validators.max(this.defaultMaxPercentRenewal),
                (control) => {

                if (!control) {
                    return null;
                }

                if (!this.form || !this.form.get('enableRenewal') || !this.form.get('enableRenewalPayment')) {

                    return null;
                }

                if (!this.form || !this.form.get('enableRenewal').value || !this.form.get('enableRenewalPayment').value) {
                    return null;
                }

                return (!control.value) ? {isRequired: {valid: false}} : null;
            }]],
            templateGiftVouchers: [],
            translations: new UntypedFormArray([])
        });

        this.formService.submitCallback = () => {

            const data = Object.assign({...this.form.value}, {
                templateGiftVouchers: this.form.get('templateGiftVouchers').value ? (this.form.get('templateGiftVouchers').value as number[]).map((id) => {
                    return {
                        id: id
                    }
                }) : null,
                enableRenewalPayment: this.user.society.isValidMangoPayAccount ? this.form.get('enableRenewalPayment').value : false,
                renewalPercentPrice: (this.user.society.isValidMangoPayAccount && this.form.get('renewalPercentPrice').value) ? (parseFloat(this.form.get('renewalPercentPrice').value) / 100).toFixed(4) : null
            });

            this.giftVoucherService.updateGiftVoucherConfiguration(this.user.society.id, data).subscribe(() => {

                this._snackBar.open(this._translateService.instant('giftVoucher.configuration.action.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._redirectToList();

            });
        }
    }

    private _initEvents(): void {

        this.form.get('enableRenewalPayment').valueChanges.subscribe(() => {

            this._updateRenewalPriceValidators();

        });

        this.form.get('enableRenewal').valueChanges.subscribe(() => {

            this.form.get('renewalDuration').patchValue(null);
            this.form.get('enableRenewalPayment').patchValue(false);
            this.form.get('renewalPercentPrice').patchValue(null);

            this.form.get('renewalDuration').updateValueAndValidity();
            this.form.get('enableRenewalPayment').updateValueAndValidity();
            this.form.get('renewalPercentPrice').updateValueAndValidity();
        });
    }

    private _hydrateForm(): void {

        this.selectedTemplates = this.configuration.templateGiftVouchers.map((data) => data.id);

        this.form.patchValue(Object.assign({...this.configuration}, {
            templateGiftVouchers: this.selectedTemplates,
            enableRenewalPayment: this.user.society.isValidMangoPayAccount ? this.configuration.enableRenewalPayment : false,
            renewalPercentPrice: this.user.society.isValidMangoPayAccount ? (this.configuration.renewalPercentPrice * 100) : null
        }));
    }

    private _initFileConfig(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg'}).subscribe((): void => {

            this.templateFileConfig = {
                id: 'image',
                gallery_context: 'template_gift_voucher_picture',
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                options: {
                    enableTitle: false,
                    enableSubtitle: false,
                    enableAlt: false,
                    enableLink: false,
                    enableTargetBlank: false
                }
            };
        });
    }

    private _initDurations(): void {

        for (let i: number = 12; i <= 36; i++) {

            this.validityDurations.push({
                id: i,
                name: `${i} ${this._translateService.instant('month.value')}`
            });
        }

        for (let i: number = 1; i <= 18; i++) {

            this.renewalDurations.push({
                id: i,
                name: `${i} ${this._translateService.instant('month.value')}`
            });
        }
    }

    private _redirectToList(): void {

        this._router.navigate(['account/service/list']);
    }

    private _updateRenewalPriceValidators(): void {

        this.form.get('renewalPercentPrice').clearValidators();

        const validators: ValidatorFn[] = [];

        if (this.form.get('enableRenewalPayment').value) {

            validators.push(Validators.required,
                Validators.pattern(REGEX_ONLY_NUMBER),
                Validators.min(this.defaultMinPercentRenewal),
                Validators.max(this.defaultMaxPercentRenewal));
        }

        this.form.get('renewalPercentPrice').setValidators(validators);
        this.form.get('renewalPercentPrice').updateValueAndValidity();
    }

    public openCreateTemplateDialog() {

        const dialogRef: MatDialogRef<TemplateGiftVoucherDialogComponent> = this._dialog.open(TemplateGiftVoucherDialogComponent, {
            width: '700px',
            data: {
                user: this.user,
                template: null
            }
        });

        dialogRef.componentInstance.templatesUpdated.subscribe(() => {

            this._loadTemplateGiftVouchers();
        });
    }

    public openTemplateVoucherDialog(template: TemplateGiftVoucher) {

        this._templateVoucherService.getItemAPI(template.id).subscribe((item) => {

            const dialogRef: MatDialogRef<TemplateGiftVoucherDialogComponent> = this._dialog.open(TemplateGiftVoucherDialogComponent, {
                width: '700px',
                data: {
                    user: this.user,
                    template: item
                }
            });

            dialogRef.componentInstance.templatesUpdated.subscribe(() => {

                this._loadTemplateGiftVouchers();
            });
        });
    }

    public openDeleteItemDialog(id: number): void {

        const item: TemplateGiftVoucher = this.templatesGiftVouchers.find((templateGiftVoucher: TemplateGiftVoucher) => {
            return templateGiftVoucher.id === id;
        });

        const indexToDelete = this.selectedTemplates.findIndex((templateId) => templateId === item.id );

        const title: string = this._translateService.instant('giftVoucher.template.action.delete.value');

        const content: string = this._translateService.instant('giftVoucher.template.action.delete.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._templateVoucherService.deleteItemAPI(item.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('giftVoucher.template.action.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.selectedTemplates.splice(indexToDelete,1);

                this._loadTemplateGiftVouchers();
            });
        });
    }

    public patchTemplatesValue(template: TemplateGiftVoucher) {

        const result = this.selectedTemplates.findIndex((id: number): boolean => {

            return id === template.id;
        });

        if (result > -1) {

            this.selectedTemplates.splice(result, 1);
        } else {

            this.selectedTemplates.push(template.id);
        }

        this.form.get('templateGiftVouchers').setValue(this.selectedTemplates);
    }

    public hasTemplate(template: TemplateGiftVoucher) {

        const result = this.selectedTemplates.find((id: number): boolean => {

            return id === template.id;
        });

        return !!(result);
    }

    get locale(): string {

        return this.user.locale;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
