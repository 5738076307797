import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FilterBuilder, FilterComponent, FilterOperator} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {fromEvent} from "rxjs";
import {debounceTime, map} from "rxjs/operators";

@Component({
    selector: 'app-core-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent implements OnInit, FilterComponent {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() placeholder: string;

    @Input() key: string;

    @Input() operator: FilterOperator;

    @Input() disableAutoSubmit: boolean;

    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    value: string;

    filterField: TextFilterField;

    constructor() {

        this.value = null;
    }

    ngOnInit(): void {

        this.filterField = new TextFilterField(this.key, this.operator);

        this.builder.addField(this.filterField);

        this.filter();
    }

    public hydrateValue(): void {

        this.filterField.value = (this.value === null || this.value === undefined || !this.value.length) ? null : this.value;
    }

    public filter(): void {

        if(this.disableAutoSubmit){

            return;
        }

        fromEvent(this.searchInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this.builder.filter();
            })
        ;

    }

    public reset(): void {

        this.value = null;
    }
}
