<div class="thumb-input" style="margin-bottom: 10px;">
    <div class="big-label">
        <span>Choisir un format : </span>
        <select [(ngModel)]="content.format.format" (ngModelChange)="changeFormat(content.format)">
            <option [ngValue]="null" disabled selected>--</option>
            <option *ngFor="let format of slideshowFormats" [ngValue]="format.format">
                {{ format.label }}
            </option>
        </select>
    </div>
</div>
<media-image-collection [options]="options" [apiUrl]="apiUrl" [gallery]="gallery" [images]="content.images" [max]="max" (onAdd)="add($event)" (onDelete)="delete($event)" (onPositionChanged)="changePosition($event)"></media-image-collection>