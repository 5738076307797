import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchAutocompleteConfiguration} from "@core/shared/models/search/search-autocomplete";
import {Offer} from "@core/shared/models/offer";
import {OfferService} from "@core/shared/services/offer.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {TranslationService} from "@core/shared/services/translation.service";
import {Pagination} from "@core/shared/models/pagination";
import {BehaviorSubject, Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {SearchAutocompleteComponent} from "@core/components/search/search-autocomplete/search-autocomplete.component";

@Component({
    selector: 'app-core-page-availability-offer-list',
    templateUrl: './page-availability-offer-list.component.html',
    styleUrls: ['./page-availability-offer-list.component.scss']
})
export class PageAvailabilityOfferListComponent implements OnInit {

    @ViewChild(SearchAutocompleteComponent) searchAutocompleteComponent: SearchAutocompleteComponent<Offer>;

    private _paginationOffset: number = 1;

    private _countItemsPerPage: number = 50;

    private _totalOffers: number = 0;

    public searchOfferAutocompleteSourceCallback: (search: string) => Observable<Offer[]>;

    public searchOfferAutocompleteConfiguration: SearchAutocompleteConfiguration<Offer>;

    public offers$: BehaviorSubject<Offer[]> = new BehaviorSubject<Offer[]>([]);

    public searchQuery: string = null;

    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _offerService: OfferService,
        private _userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._loadOffers(false);

        this.searchOfferAutocompleteSourceCallback = (search: string): Observable<Offer[]> => {

            this._paginationOffset = 1;

            this.searchQuery = search;

            this.offers$.next([]);

            this.isLoading$.next(true);

            return this.itemsApiObservable.pipe(
                tap((items: Offer[]): void => {

                    this.offers$.next(items);

                    this.isLoading$.next(false);
                })
            );
        }

        this.searchOfferAutocompleteConfiguration = {
            placeholder: 'offer.search.action.value',
            displayResult: false,
            resultLabel: (item: Offer): string => {

                return this.translationService.getFallbackTranslation(item.translations).name;
            }
        };
    }

    private _loadOffers(reset: boolean): void {

        if(reset){

            this.offers$.next([]);
        }

        this.isLoading$.next(true);

        this.itemsApiObservable.subscribe((items: Offer[]): void => {

            if(reset){

                this.offers$.next(items);
            }
            else{

                const offers: Offer[] = [...this.offers$.getValue()];

                offers.push(...items);

                this.offers$.next(offers);
            }

            this.isLoading$.next(false);
        });
    }

    public loadMoreOffers(): void {

        this._paginationOffset++;

        this._loadOffers(false);
    }

    public resetSearchQuery(): void {

        this._paginationOffset = 1;

        this.searchQuery = null;

        this.searchAutocompleteComponent.clearSearch();

        this._loadOffers(true);
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get hasMoreOffers(): boolean {

        return this.offers$.getValue().length < this._totalOffers;
    }

    get itemsApiObservable(): Observable<Offer[]> {

        return this._offerService.getPaginationItemsSocietyAPI(this.currentUser.society.id, this.itemsApiParams).pipe(
            map((data: Pagination<Offer>): Offer[] => {

                this._totalOffers = data.totalItems;

                return data.items;
            })
        );
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this._paginationOffset}`,
            `limit=${this._countItemsPerPage}`,
            `sort[translations.name]=ASC`,
            `onRequest[eq]=0`
        ];

        if(this.searchQuery){

            params.push(`nameOrReference[lk]=${this.searchQuery}`);
        }

        return params;
    }
}
