/**
 * @param value Montant du prix
 * @param n     Nombre de décimale après la virgule
 * @param s     Délimiteur entre les centaines
 * @param c     Séparateur entre l'entier et les décimales
 */
export const parsePrice = (value: number, n: number = 2, s: string = ' ', c: string = '.') : string => {

    if(Number.isInteger(value)){

        return value.toString();
    }

    const re = '\\d(?=(\\d{' + (null || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';

    const num = value.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s));
};
