<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ 'giftVoucher.read.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'giftVoucher.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit" [disabled]="!isOfferOwner || giftVoucher.status === 'forwarded'">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'giftVoucher.read.data.folder.value' | translate">
            <ng-container *ngTemplateOutlet="folderData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'giftVoucher.read.data.value' | translate">
            <ng-container *ngTemplateOutlet="giftVoucherData"></ng-container>
        </mat-tab>
        <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
            <mat-tab [label]="'giftVoucher.read.data.download.value' | translate">
                <ng-container *ngTemplateOutlet="downloadData"></ng-container>
            </mat-tab>
        </ng-container>
        <mat-tab [label]="'giftVoucher.read.data.distributor.contactDetails.value' | translate">
            <ng-container *ngTemplateOutlet="distributorContactDetails"></ng-container>
        </mat-tab>
        <mat-tab [label]="'giftVoucher.read.data.creator.contactDetails.value' | translate">
            <ng-container *ngTemplateOutlet="creatorContactDetails"></ng-container>
        </mat-tab>
        <mat-tab *ngIf="!isAdmin()" [label]="'giftVoucher.read.data.buyer.contactDetails.value' | translate">
            <ng-container *ngTemplateOutlet="buyerContactDetails"></ng-container>
        </mat-tab>
        <mat-tab *ngIf="!isAdmin()" [label]="'giftVoucher.read.data.beneficiary.contactDetails.value' | translate">
            <ng-container *ngTemplateOutlet="beneficiaryContactDetails"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<!-- Suivi du dossier -->

<ng-template #folderData [formGroup]="form">

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form ">

            <div class="col_6">

                <div class="form-group">

                    <!-- Statut bon cadeau-->

                    <div class="ligne_form row_marges">

                        <div class="col_12">

                            <div class="form-group">

                                <select class="form-control" formControlName="status" id="status" required>

                                    <option *ngFor="let item of status" [value]="item.id">{{ item.label | translate }}</option>

                                </select>

                                <label class="required" for="status">{{ 'giftVoucher.list.fields.status.value' | translate }}</label>

                                <mat-error *ngIf="form.get('status').invalid && (form.get('status').dirty || form.get('status').touched)">

                                    <div class="invalid-feedback">

                                        <div *ngIf="form.get('status').errors.required">{{ 'form.control.error.required.value' | translate }}</div>

                                    </div>

                                </mat-error>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="isForwarded">

                       <div class="col_12">

                           <div class="form-group">

                               <!-- Nom du nouveau client -->

                               <p *ngIf="!isAdmin()" class="read">
                                   <strong>{{ 'giftVoucher.read.fields.forwarded.beneficiary.lastName.value' | translate }} : </strong>
                                   {{ giftVoucher.transferredGiftVoucher.beneficiary.lastName }}
                               </p>

                               <!-- Prénom du nouveau client -->

                               <p *ngIf="!isAdmin()" class="read">
                                   <strong>{{ 'giftVoucher.read.fields.forwarded.beneficiary.firstName.value' | translate }} : </strong>
                                   {{ giftVoucher.transferredGiftVoucher.beneficiary.firstName }}
                               </p>

                               <!-- Email du nouveau client -->

                               <p *ngIf="!isAdmin()" class="read">
                                   <strong>{{ 'giftVoucher.read.fields.forwarded.beneficiary.email.value' | translate }} : </strong>
                                   {{ giftVoucher.transferredGiftVoucher.beneficiary.email }}
                               </p>

                               <!-- Numéro de bon cadeau -->

                               <p class="read">
                                   <strong>{{ 'giftVoucher.read.fields.forwarded.code.value' | translate }} : </strong>
                                   {{ giftVoucher.transferredGiftVoucher.code }}
                               </p>

                           </div>

                       </div>

                    </div>

                    <!-- Commentaire -->

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <div class="form-group">

                                <textarea class="form-control" formControlName="comment" id="comment"></textarea>

                                <label for="comment">{{'booking.read.fields.comment.value' | translate}}</label>

                            </div>

                        </div>

                    </div>

                    <!-- Collaborateur -->

                    <div class="ligne_form row_marges">

                        <div class="col_12">

                            <div class="form-group">

                                <p class="read ligne_form">
                                    <strong class="subtitle_green">{{ 'booking.list.fields.responsibleUser.value' | translate }}</strong>
                                </p>

                                <div style="margin-top: 12px">
                                    <app-core-select-search [configuration]="collaboratorSearchConfiguration" [sourceCallback]="collaboratorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'responsibleUser'"></app-core-select-search>
                                </div>

                                <ng-container *ngIf="currentCollaborator && (currentCollaborator.society.id === giftVoucher.offerOwnerSociety?.id) && hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && !isUpdateCollaboratorAllowed">

                                    <mat-error style="padding: 0">

                                        <div class="invalid-feedback">
                                            <div>{{ 'collaborator.update.denied.description.value' | translate }}</div>
                                        </div>

                                    </mat-error>

                                </ng-container>

                                <mat-error *ngIf="form.get('responsibleUser').invalid && (form.get('responsibleUser').dirty || form.get('responsibleUser').touched)">

                                    <div class="invalid-feedback">

                                        <div *ngIf="form.get('responsibleUser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>

                                    </div>

                                </mat-error>

                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!!currentCollaborator">

                        <!-- Données du collaborateur -->

                        <div class="ligne_form row marges">

                            <div class="col_12">

                                <div class="form-group">
                                    <p class="read">
                                        <strong class="subtitle_green">{{ 'collaborator.data.plural.value' | translate }}</strong>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="ligne_form row marges">

                            <div class="col_12">

                                <div class="form-group">

                                    <!-- Nom -->

                                    <ng-container *ngIf="currentCollaborator?.firstName || currentCollaborator?.lastName">

                                        <p class="read">
                                            <strong>{{ 'collaborator.name.value' | translate }} : </strong>
                                            {{ currentCollaborator?.firstName }} {{ currentCollaborator?.lastName }}
                                        </p>

                                    </ng-container>

                                    <!-- Service -->

                                    <ng-container *ngIf="currentCollaborator?.service">

                                        <p class="read">
                                            <strong>{{ 'collaborator.service.value' | translate }} : </strong>
                                            {{ currentCollaborator?.service }}
                                        </p>

                                    </ng-container>

                                    <!-- Email -->

                                    <ng-container *ngIf="currentCollaborator?.email">

                                        <p class="read">
                                            <strong>{{ 'collaborator.email.value' | translate }} : </strong>
                                            {{ currentCollaborator?.email }}
                                        </p>

                                    </ng-container>

                                    <!-- Téléphone -->

                                    <ng-container *ngIf="currentCollaborator?.switchboardPhone">

                                        <p class="read">
                                            <strong>{{ 'collaborator.phone.value' | translate }} : </strong>
                                            {{ currentCollaborator?.switchboardPhone }}
                                        </p>

                                    </ng-container>

                                    <!-- Adresse -->

                                    <ng-container *ngIf="currentCollaborator?.address">

                                        <p class="read">
                                            <strong>{{ 'collaborator.address.value' | translate }} : </strong>
                                            {{ currentCollaborator?.address.address }}, {{ currentCollaborator?.address.zipcode }} {{ currentCollaborator?.address.city }}
                                        </p>

                                    </ng-container>

                                </div>

                            </div>

                        </div>

                    </ng-container>

                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Informations associées à la réservation -->

<ng-template #giftVoucherData>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">

                <!-- Référence -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.reference.value' | translate }} : </strong>
                    {{ giftVoucher.reference }}
                </p>

                <!-- Numéro du bon cadeaau -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.code.value' | translate }} : </strong>
                    {{ giftVoucher.code }}
                </p>

                <!-- Date de commande -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.createdAt.value' | translate }} : </strong>
                    {{ (giftVoucher.createdAt | date : 'fullDate' : null: localeId) | titlecase }}
                </p>

                <!-- Durée de validité -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.expiryDate.value' | translate }} : </strong>
                    {{ (giftVoucher.expiryDate | date : 'fullDate' : null: localeId) | titlecase }}
                </p>

                <!-- Type de bon cadeau -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.type.value' | translate }} : </strong>
                    {{ 'giftVoucher.read.fields.type.' + giftVoucher.type + '.value' | translate }}
                </p>

                <ng-container *ngIf="giftVoucher.type === 'physical' && giftVoucher.offerOwnerSociety.id === society.id">

                    <!-- Info pour l'envoi du bon cadeau physique -->

                    <p class="read message-help">
                        <mat-icon>info</mat-icon> <span>{{ ('giftVoucher.read.fields.type.physical.' + giftVoucher.recipient + '.help.value') | translate }}</span>
                    </p>

                </ng-container>

                <ng-container *ngIf="giftVoucher.transporter">

                    <!-- Nom du transporteur -->

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.transporter.name.value' | translate }} : </strong>
                        {{ giftVoucher.transporter.name }}
                    </p>

                </ng-container>

                <ng-container *ngIf="giftVoucher.nbAdult > 0">

                    <!-- Nombre d'adulte(s) -->

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.adult.count.value' | translate }} : </strong>
                        {{ giftVoucher.nbAdult }}
                    </p>

                </ng-container>

                <ng-container *ngIf="giftVoucher.nbChild > 0">

                    <!-- Nombre d'enfant(s) -->

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.child.count.value' | translate }} : </strong>
                        {{ giftVoucher.nbChild }}
                    </p>

                </ng-container>

                <!-- Prix -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.price.value' | translate }} : </strong>
                    {{ ((giftVoucher.price / 100)|parsePrice) + ' ' + currencySymbol }}
                </p>

                <!-- Promotion appliquée -->

                <ng-container *ngIf="giftVoucher.promotionData">

                    <p class="read">
                        <strong>{{ 'booking.read.fields.promotion.code.value' | translate }} : </strong>
                        <span>{{ giftVoucher.promotionData.code }}</span>
                    </p>

                    <p class="read">
                        <strong>{{ 'booking.read.fields.promotionCode.value' | translate }} : </strong>
                        <span>{{ giftVoucher.promotionData.value * 100 }} %</span>
                    </p>

                    <!-- Prix promotionnel -->

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.promotion.price.value' | translate }} : </strong>
                        {{ ((giftVoucher.promotionPrice / 100)|parsePrice) + ' ' + currencySymbol }}
                    </p>

                </ng-container>

                <!-- Prix du transporteur -->

                <ng-container *ngIf="giftVoucher.transporterPriceTTC">

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.transporter.price.value' | translate }} : </strong>
                        {{ 'giftVoucher.read.fields.transporter.price.ttc.value' | translate:{ price: ((giftVoucher.transporterPriceTTC / 100)|parsePrice), currency: currencySymbol } }}
                        ({{ 'giftVoucher.read.fields.transporter.price.ht.value' | translate:{ price: ((giftVoucher.transporterPriceHT / 100)|parsePrice), currency: currencySymbol } }})
                    </p>

                </ng-container>

                <!-- Total NET -->

                <p class="read" *ngIf="giftVoucher.totalNetPrice">
                    <strong>{{ 'giftVoucher.read.fields.total.net_price.value' | translate }} : </strong>
                    {{ ((giftVoucher.totalNetPrice / 100)|parsePrice) + ' ' + currencySymbol }}
                </p>

                <!-- Taux de commission HT applicable -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.commission.ht.description.value' | translate }} : </strong>
                    <span>{{ giftVoucher.commission ? parsedMarkup(giftVoucher.commission * 100) : 'N/A' }}</span>
                </p>

                <!-- Taux de commission TTC applicable -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.commission.ttc.value' | translate }} : </strong>
                    <span>{{ giftVoucher.commissionTTC ? parsedMarkup(giftVoucher.commissionTTC * 100) : 'N/A' }}</span>
                </p>

                <!-- Montant de la commission TTC -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.amountCommission.value' | translate }} : </strong>
                    <span>{{ giftVoucher.amountCommissionTTC ? (((giftVoucher.amountCommissionTTC / 100)|parsePrice) + ' ' + currencySymbol) : 'N/A' }}</span>
                </p>

                <!-- Commission encaissée -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.isPaidCommission.value' | translate }} : </strong>
                    <span>{{ ((giftVoucher.isPaidCommission ? 'yes' : 'no') + '.value') | translate }}</span>
                </p>

                <!-- Total -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.total.price.value' | translate }} : </strong>
                    {{ ((giftVoucher.totalPrice / 100)|parsePrice) + ' ' + currencySymbol }}
                </p>

                <!--  Bon de cadeau renouvelable  -->

                <p class="read">
                    <strong>{{ 'giftVoucher.read.fields.renewal.value' | translate }} : </strong>
                    {{ 'giftVoucher.list.fields.renewed.' + giftVoucher.isRenewable + '.value' | translate }}
                </p>

                <ng-container *ngIf="giftVoucher.isRenewable">

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.renewal.price.value' | translate }} : </strong>
                        {{ (renewalAmountTTC > 0) ? ((renewalAmountTTC | parsePrice) + ' ' + currencySymbol + ' ' + ('taxes.including.value' | translate) + ' (' + (renewalAmountHT | parsePrice) + ' ' + currencySymbol + ' ' + ('taxes.excluding.value' | translate) + ')') : ('giftVoucher.read.fields.renewal.free.value' | translate) }}
                    </p>

                    <p class="read">
                        <strong>{{ 'giftVoucher.read.fields.renewal.renewed.value' | translate }} : </strong>
                        {{ 'giftVoucher.list.fields.renewed.' + giftVoucher.isRenewed + '.value' | translate  }}
                    </p>

                </ng-container>

                <!-- Nom de l'offre -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.offerName.value' | translate }} : </strong>
                    <a *ngIf="!giftVoucher.isDetached" [routerLink]="['/account/offer/read', offer.id]" [queryParams]="{ origin: 'giftVoucherRead', giftVoucher: giftVoucher.id }">{{ translationService.getFallbackTranslation(offer.translations).name}}</a>
                    <span *ngIf="giftVoucher.isDetached">{{ translationService.getFallbackTranslation(offer.translations).name}}</span>
                </p>

                <!-- Commentaire -->

                <ng-container *ngIf="!isAdmin() && giftVoucher.buyer.comment?.length">

                    <p class="read">
                        <strong>{{ 'booking.read.fields.customerComment.value' | translate }} : </strong>
                        {{ giftVoucher.buyer.comment }}
                    </p>

                </ng-container>

                <!-- Société en charge de la relation client -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.society.inChargeOfCustomerRelation.value' | translate }} : </strong>
                    {{ giftVoucher.offerOwnerSocietyData.societyName }}
                </p>

            </div>
        </div>
    </div>

    <div class="ligne_form row marges">

        <div class="col_6">

            <div class="form-group">
                <p class="read">
                    <strong class="subtitle_green">{{ 'booking.read.title.description' | translate }}</strong>
                </p>

            </div>
        </div>
    </div>


    <div class="ligne_form row marges">

        <div class="col_6">

            <div class="form-group">
                <div class="read">

                    <mat-accordion multi>

                        <!-- Description -->

                        <mat-expansion-panel>

                            <mat-expansion-panel-header>

                                <mat-panel-title>{{ 'booking.read.fields.description.value' | translate }}</mat-panel-title>

                            </mat-expansion-panel-header>

                            <div class="txt_offer">

                                <p [innerHTML]="translationService.getFallbackTranslation(offer.translations).description|safeHtml"></p>

                            </div>

                        </mat-expansion-panel>

                        <!-- Compris dans l'offre -->

                        <mat-expansion-panel>

                            <mat-expansion-panel-header>

                                <mat-panel-title>{{ 'offer.form.fields.included.value' | translate }}</mat-panel-title>

                            </mat-expansion-panel-header>

                            <div *ngFor="let included of translatedIncludedsItemFields" class="txt_offer">

                                <p class="title_2">{{ included?.title }}</p>

                                <p>{{ included?.description }}</p>

                            </div>

                        </mat-expansion-panel>

                        <!-- Non Compris dans l'offre -->

                        <mat-expansion-panel>

                            <mat-expansion-panel-header>

                                <mat-panel-title>{{ 'offer.form.fields.included.no.value' | translate }}</mat-panel-title>

                            </mat-expansion-panel-header>

                            <div *ngFor="let included of translatedNotIncludedsItemFields" class="txt_offer little">

                                <p class="title_2">{{ included?.title }}</p>

                                <p>{{ included?.description }}</p>

                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>

                </div>

            </div>

        </div>

    </div>

</ng-template>

<!-- Téléchargement du bon cadeau -->

<ng-template #downloadData>

    <div>

        <ng-container *ngIf="giftVoucher.isAllowedDownloadFile">

            <div class="page_title">
                <div class="row marges">
                    <div class="col_content y_items_center">
                        <button class="btn_cta btn_little" type="button" (click)="$event.preventDefault(); downloadItem()">
                            {{ 'giftVoucher.read.fields.download.value'|translate }}
                        </button>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="!giftVoucher.isAllowedDownloadFile">

            <div class="page_title">
                <div class="row marges">
                    <div class="col_content">
                        <p>{{ 'giftVoucher.download.unavailable.value' | translate: {creator: giftVoucher.offerOwnerSocietyData.societyName} }}</p>
                    </div>
                </div>
            </div>

        </ng-container>

    </div>

</ng-template>

<!-- Coordonnées du distributeur -->

<ng-template #distributorContactDetails>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges">

            <div class="col_12">

                <div class="form-group">

                    <!-- Nom du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channelName.value' | translate }} : </strong>
                        {{ giftVoucher.channel ? translationService.getFallbackTranslation(giftVoucher.channel.translations).name : ('booking.read.fields.channelName.none.value' | translate)}}
                    </p>

                    <!-- Site internet -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channelMarketplace.value' | translate }} : </strong>
                        {{ (giftVoucher.channel && giftVoucher.channel.marketplace) ? giftVoucher.channel.marketplace.website : originUrl }}
                    </p>

                    <!-- Nom du propriétaire du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channel.owner.name.value' | translate }} : </strong>
                        {{ giftVoucher.channel ? giftVoucher.channel.society.name : giftVoucher.society.name }}
                    </p>

                    <!-- Email du propriétaire du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channel.owner.email.value' | translate }} : </strong>
                        {{ giftVoucher.channel ? giftVoucher.channel.society.email : giftVoucher.society.email }}
                    </p>

                    <!-- Téléphone du propriétaire du canal -->

                    <p class="read">
                        <strong>{{ 'booking.read.fields.channel.owner.phone.value' | translate }} : </strong>
                        {{ giftVoucher.channel ? giftVoucher.channel.society.phone : giftVoucher.society.phone }}
                    </p>

                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Coordonnées du créateur -->

<ng-template #creatorContactDetails>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">

                <!-- Nom du créateur d'offre -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.offerProductor.value' | translate }} : </strong>
                    {{ giftVoucher.offerOwnerSocietyData.societyName }}
                </p>

                <!-- Email du créateur d'offre -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.offerProductor.email.value' | translate }} : </strong>
                    {{ giftVoucher.offerOwnerSocietyData.email }}
                </p>

                <!-- Téléphone du créateur d'offre -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.offerProductor.phone.value' | translate }} : </strong>
                    {{ giftVoucher.offerOwnerSocietyData.phone }}
                </p>

            </div>

        </div>

    </div>

</ng-template>

<!-- Coordonnées de l'acheteur -->

<ng-template #buyerContactDetails>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">

                <!-- Société -->

                <ng-container *ngIf="giftVoucher.buyer.society">

                    <p class="read">
                        <strong>{{ 'booking.read.fields.customerSociety.value' | translate }} : </strong>
                        {{ giftVoucher.buyer.society }}<ng-container *ngIf="giftVoucher.buyer.customerType"> ({{ translationService.getFallbackTranslation(giftVoucher.buyer.customerType.translations).label }})</ng-container>
                    </p>

                </ng-container>

                <!-- Nom -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerName.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.lastName }}
                </p>

                <!-- Prénom -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerFirstName.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.firstName }}
                </p>

                <!-- Adresse -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerAddress.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.address }}
                </p>

                <!-- Complément d'adresse -->

                <p class="read" *ngIf="giftVoucher.buyer.additionalAddress">
                    <strong>{{ 'booking.read.fields.customerAdditionalAddress.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.additionalAddress }}
                </p>

                <!-- Code postal -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerZipcode.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.zipcode }}
                </p>

                <!-- Ville -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerCity.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.city }}
                </p>

                <!-- Région -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerRegion.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.region }}
                </p>

                <!-- Pays -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerCountry.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.country }}
                </p>

                <!-- Email -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerEmail.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.email }}
                </p>

                <!-- Téléphone -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerFirstPhone.value' | translate }} : </strong>
                    {{ giftVoucher.buyer.phone }}
                </p>

            </div>

        </div>

    </div>

</ng-template>

<!-- Coordonnées du bénéficiaire -->

<ng-template #beneficiaryContactDetails>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">

                <!-- Société -->

                <ng-container *ngIf="giftVoucher.beneficiary.society">

                    <p class="read">
                        <strong>{{ 'booking.read.fields.customerSociety.value' | translate }} : </strong>
                        {{ giftVoucher.beneficiary.society }}<ng-container *ngIf="giftVoucher.beneficiary.customerType"> ({{ translationService.getFallbackTranslation(giftVoucher.beneficiary.customerType.translations).label }})</ng-container>
                    </p>

                </ng-container>

                <!-- Nom -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerName.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.lastName }}
                </p>

                <!-- Prénom -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerFirstName.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.firstName }}
                </p>

                <!-- Adresse -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerAddress.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.address }}
                </p>

                <!-- Complément d'adresse -->

                <p class="read" *ngIf="giftVoucher.beneficiary.additionalAddress">
                    <strong>{{ 'booking.read.fields.customerAdditionalAddress.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.additionalAddress }}
                </p>

                <!-- Code postal -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerZipcode.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.zipcode }}
                </p>

                <!-- Ville -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerCity.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.city }}
                </p>

                <!-- Région -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerRegion.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.region }}
                </p>

                <!-- Pays -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerCountry.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.country }}
                </p>

                <!-- Email -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerEmail.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.email }}
                </p>

                <!-- Téléphone -->

                <p class="read">
                    <strong>{{ 'booking.read.fields.customerFirstPhone.value' | translate }} : </strong>
                    {{ giftVoucher.beneficiary.phone }}
                </p>

            </div>

        </div>

    </div>

</ng-template>

