import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "@core/shared/services/translation.service";
import {FormService} from "@core/shared/services/form.service";
import {Offer} from "@core/shared/models/offer";
import {User} from "@core/shared/models/user";
import {TransporterService} from "@core/shared/services/transporter.service";
import {Transporter} from "@core/shared/models/transporter";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";
import { OfferGiftVoucherComposition, OfferGiftVoucherConfig,  OfferGiftVoucherType} from "@core/shared/models/offer/offer-gift-voucher-composition";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";
import {parsePrice} from "@core/shared/utils/price";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {Observable} from "rxjs";
import {CustomerType} from "@core/shared/models/customer/customer-type";
import {CustomerTypeService} from "@core/shared/services/customer/customer-type.service";


@Component({
    selector: 'app-offer-gift-voucher-configuration',
    templateUrl: './offer-gift-voucher-request-configuration.component.html',
    styleUrls: ['./offer-gift-voucher-request-configuration.component.scss']
})
export class OfferGiftVoucherRequestConfigurationComponent implements OnInit {

    @Input() offer: Offer;

    @Input() giftVoucher: GiftVoucher;

    @Input() user: User;

    @Input() generalData: OfferGiftVoucherComposition;

    @Output() reloadSummaryRequested: EventEmitter<void> = new EventEmitter<void>();

    @Output() backToOfferRequested: EventEmitter<void> = new EventEmitter<void>();

    @Output() backToCompositionRequested: EventEmitter<void> = new EventEmitter<void>();

    public transporters$: Observable<Transporter[]>;

    public templates: TemplateGiftVoucher[] = [];

    public view: 'configuration' | 'confirmation' = 'configuration';

    public customerTypes: CustomerType[] = [];

    constructor(private _formBuilder: UntypedFormBuilder,
                private _transporterService: TransporterService,
                private _templateService: TemplateGiftVoucherService,
                private _giftVoucherService: GiftVoucherService,
                private _translateService: TranslateService,
                private _customerTypeService: CustomerTypeService,
                public formService: FormService,
                public translationService: TranslationService,
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this._initEvent();

        this._loadTemplates();

        this._initCustomerTypes();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            transporter: [null, [Validators.required]],
            template: [null, [Validators.required]],
            personalizedNote: [null, Validators.pattern(/^(.{0,400})$/)],
            personalizedBeneficiaryName: [null, Validators.maxLength(100)],
            personalizedBuyerName: [null, Validators.maxLength(100)],
            type: ['', Validators.required],
            notifyBeneficiary: [this.generalData.beneficiarySameAsBuyer]
        });

        this.formService.submitCallback = () => {

            const data = Object.assign({...this.generalData},
                {
                    ...this.form.value,
                    locale: this.generalData.buyer.locale,
                    transporter: (this.isPhysical && this.form.get('transporter').value) ? {id: parseInt(this.form.get('transporter').value)} as Transporter : null,
                    template: this.form.get('template') .value ? { id: parseInt(this.form.get('template').value) } as TemplateGiftVoucher : null,
                    recipient: (this.form.get('notifyBeneficiary').value as boolean) ? 'beneficiary' : 'buyer',
                });

            this._giftVoucherService.createItemAPI(this.user.society.id, data).subscribe((): void => {

                this.setView('confirmation');
            });
        };
    }

    private _loadTransporters(): void {

        const country: string = this.form.get('notifyBeneficiary').value ? this.generalData.beneficiary.country : this.generalData.buyer.country;

        const countryFilter: TextFilterField = new TextFilterField('countries', 'lk', country);

        const params: string[] = [
            countryFilter.serialize
        ];

        this.transporters$ = this._transporterService.getOfferItemsAPI(this.user.society.id, this.offer.id, params);
    }

    private _loadTemplates(): void {

        const params: string[] = [];

        params.push(`translations.locale[eq]=${this.generalData.buyer.locale}`);

        if (this.isDematerialized) {

            params.push('isDematerialized[eq]=1');
        }

        if (this.isPhysical) {

            params.push('isPhysical[eq]=1');
        }

        this._templateService.getSocietyTemplateGiftVouchers(this.user.society.id, this.offer.id, params).subscribe((templates: TemplateGiftVoucher[]) => {

            this.templates = templates;
        });
    }

    private _initEvent(): void {

        this.form.get('type').valueChanges.subscribe((): void => {

            this._updateTransporterValidator();

            if(this.isPhysical){

                this._loadTransporters();
            }

            this._loadTemplates();

            this.reloadSummaryRequested.emit();
        });

        this.form.get('transporter').valueChanges.subscribe((): void => {

            this.reloadSummaryRequested.emit();
        });

        this.form.get('notifyBeneficiary').valueChanges.subscribe((): void => {

            this._updateTransporterValidator();

            this._loadTransporters();

            this.reloadSummaryRequested.emit();
        });
    }

    private _updateTransporterValidator(): void {

        this.form.get('transporter').clearValidators();

        this.form.get('transporter').patchValue(null);

        const validators: ValidatorFn[] = [];

        if (this.isPhysical) {

            validators.push(Validators.required);
        }

        this.form.get('transporter').setValidators(validators);

        this.form.get('transporter').updateValueAndValidity();
    }

    private _initCustomerTypes(): void {

        this._customerTypeService.getItemsAPI().subscribe((customerTypes: CustomerType[]): void => {

            this.customerTypes = customerTypes;
        });
    }

    public patchTemplatesValue(id: number): void {

        const result: number = this.form.get('template').value;

        if (result === id) {

            this.form.get('template').setValue(null);
        } else {

            this.form.get('template').setValue(id);
        }

    }

    public isTemplateSelected(template: TemplateGiftVoucher): boolean {

        return this.form.get('template').value === template.id;
    }

    public formatPrice(price: number, symbol: string): string {

        return parsePrice(price / 100) + `€${symbol}`;
    }

    public isView(view: ('configuration' | 'confirmation')) {

        return this.view === view;
    }

    public setView(view: ('configuration' | 'confirmation')): void {

        this.view = view;
    }

    public handleBackToCompositionRequest() {

        this.backToCompositionRequested.emit();
    }

    get getConfirmationMessage(): string {

        const notifyBeneficiary: boolean = this.form.get('notifyBeneficiary').value;

        switch (this.form.get('type').value) {

            case 'physical':

                return this._translateService.instant('offer.giftVoucher.notifyBeneficiary.physical.' + (this.giftVoucher.offerOwnerSociety.id === this.user.society.id ? 'owner' : 'distributor') + '.success.value', {
                    society : this.giftVoucher.offerOwnerSociety.name
                });

            case 'dematerialized':

                return this._translateService.instant('offer.giftVoucher.notifyBeneficiary.dematerialized.success.value', {
                    email : (notifyBeneficiary ? this.giftVoucher.beneficiary.email : this.giftVoucher.buyer.email)
                });
        }

    }

    get item(): OfferGiftVoucherConfig {

        return {
            type: this.form.get('type') ? (this.form.get('type').value.toString() as OfferGiftVoucherType) : null,
            transporter: (this.isPhysical && this.form.get('transporter').value) ? {id: parseInt(this.form.get('transporter').value)} as Transporter : null,
            template: this.form.get('template') .value ? { id: parseInt(this.form.get('template').value) } as TemplateGiftVoucher : null,
            personalizedNote: this.form.get('personalizedNote').value,
            personalizedBeneficiaryName: this.form.get('personalizedBeneficiaryName').value,
            personalizedBuyerName: this.form.get('personalizedBuyerName').value,
            notifyBeneficiary: this.form.get('notifyBeneficiary').value
        };
    }

    get isDematerialized(): boolean {

        return (this.form.get('type').value as OfferGiftVoucherType) === 'dematerialized';
    }

    get isPhysical(): boolean {

        return (this.form.get('type').value as OfferGiftVoucherType) === 'physical';
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get customerType(): CustomerType {

        const customerType: CustomerType = this.customerTypes.find((item: CustomerType): boolean => {

            return item.id === this.generalData.buyer.customerType.id;
        });

        return customerType || null;
    }
}
