import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'formatAge'
})
export class FormatAgePipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService
    ){}

    transform(value: number, ...args: unknown[]): string {

        if(value === 0){

            return '0';
        }

        return `${value} ` + this._translateService.instant(`year.shortcut${value > 1 ? '.plural' : ''}.value`);
    }
}
