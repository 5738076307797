<div [hidden]="configuration.displayOnlyComposition">

    <div class="title_offer">

        <!-- Catalogues / Canaux utilisant l'offre -->

        <ng-container *ngIf="configuration.displayChannels && catalogsIncludingOffer.length > 0">

            <div class="row marges infos infos_canaux">
                <div class="col_12">

                    <ng-container *ngIf="catalogsIncludingOffer.length">

                        <ng-container *ngIf="channelsIncludingOffer.length">

                            <p><strong>{{ (catalogsIncludingOffer.length > 1 ? 'offer.presence.plural.value' : 'offer.presence.value') | translate:{countCatalogsIncludingOffer: catalogsIncludingOffer.length} }}
                                {{ (channelsIncludingOffer.length > 1 ? 'offer.presence.channelIncluding.plural.value' : 'offer.presence.channelIncluding.value') | translate:{countChannelsIncludingOffer: channelsIncludingOffer.length} }}</strong></p>

                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>{{ 'channel.list.value' | translate }}</span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div *ngFor="let channel of channelsIncludingOffer; first as isFirst" class="txt_offer">
                                        <p><strong>{{ 'channel.distributor.value' | translate }}</strong>
                                            : {{ channel.society.name }}</p>
                                        <p><strong>{{ 'channel.value' | translate }}</strong>
                                            : {{ getTranslatedChannelFields(channel)?.name }}</p>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>

                        </ng-container>

                        <ng-container *ngIf="!channelsIncludingOffer.length">

                            <span>{{ (catalogsIncludingOffer.length > 1 ? 'offer.presence.plural.value' : 'offer.presence.value') | translate:{countCatalogsIncludingOffer: catalogsIncludingOffer.length} }}</span>

                        </ng-container>

                    </ng-container>

                </div>

                <div class="col_12 col_md_4" *ngIf="item.locales.length > 1">
                    <div class="form-group choice_language">
                        <label><strong>{{ 'offer.read.choiceLanguage.value' | translate }} :</strong></label>
                        <mat-select [(ngModel)]="locale" class="no_label" (ngModelChange)="onLocaleUpdated.emit(locale)">
                            <mat-option *ngFor="let locale of item.locales" [value]="locale">
                                {{ 'locale.'+locale+ '.value' | translate }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- Date de création / Date de mise à jour -->

                <ng-container *ngIf="isMine">
                    <div class="col_12">
                        <div class="row marges">
                            <div class="col_content y_items_center">
                                <span>{{'offer.list.item.createdAt.value' | translate}} - {{ item.createdAt | date:'full' : null : localeId }}</span>
                            </div>
                        </div>
                        <div class="row marges">
                            <div class="col_content y_items_center">
                                <span>{{'offer.list.item.updatedAt.value' | translate}} - {{ item.updatedAt | date:'full' : null : localeId }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>


            </div>

        </ng-container>

        <div class="row marges">
            <div class="col">
                <div class="row marges">

                    <!-- Offre "Nouveauté" -->

                    <ng-container *ngIf="item.isNew">
                        <div class="col_content y_items_center">
                            <span class="tag">{{'offer.list.item.new.value' | translate}}</span>
                        </div>
                    </ng-container>

                    <!-- Nom de l'offre -->

                    <div class="col y_items_center">
                        <p class="title">{{translatedItemFields?.name}}</p>
                    </div>
                </div>

                <div class="row marges infos">

                    <div class="col_content y_items_center">

                        <!-- Créateur d'offre -->

                        <span>{{ 'offer.list.item.show.value' | translate }} : {{ item.society.name }}</span>

                    </div>

                    <div class="col_content y_items_center">

                        <!-- Référence -->

                        <span>{{ 'offer.reference.identifier.value' | translate }} : {{ item.reference }}</span>

                    </div>
                    <div class="col_content y_items_center">

                        <!-- Type d'offre -->

                        <span class="offer_type ty-house">{{ translatedCustomerTypologyItemFields?.name }}</span>

                        <!-- Ville / Pays -->

                        <ng-container *ngIf="!item.enableMultipleMainLocation && item.firstLocation">

                            <span>{{ item.firstLocation.city }}, {{ item.firstLocation.country }}</span>

                        </ng-container>

                    </div>
                    <div class="col_content y_items_center">

                        <!-- Durée de l'offre -->

                        <span class="offer_type ty-calendar">{{ translatedDurationItemFields?.label }}</span>

                    </div>
                    <div class="col_content y_items_center">

                        <!-- Présentiel Adulte -->

                        <span *ngIf="item.presential.adultMax > 0; else noAdult">
                        {{ 'offer.presential.interval.value' | translate:{
                            min: item.presential.adultMin,
                            max: item.presential.adultMax
                        } }} {{ (item.presential.adultMax > 1 ? 'offer.presential.adult.plural.value' : 'offer.presential.adult.value') | translate }}
                    </span>

                        <ng-template #noAdult>
                            <span>{{ 'offer.presential.adult.void.value' | translate }}</span>
                        </ng-template>

                        <span>,&nbsp;</span>

                        <!-- Présentiel Enfant -->

                        <span *ngIf="item.presential.childMax > 0; else noChild">
                        {{ 'offer.presential.interval.value' | translate:{
                            min: item.presential.childMin,
                            max: item.presential.childMax
                        } }} {{ (item.presential.childMax > 1 ? 'offer.presential.child.plural.value' : 'offer.presential.child.value') | translate }}

                            <ng-container *ngIf="!item.onRequest && item.publics.includes('child')">
                            {{ 'offer.presential.child.age.interval.value' | translate :{min: item.minChildrenAge, max: item.maxChildrenAge} }}
                        </ng-container>
                    </span>

                        <ng-template #noChild>
                            <span>{{ 'offer.presential.child.void.value' | translate }}</span>
                        </ng-template>

                    </div>
                </div>

                <!-- Producteur de l'offre -->

                <ng-container *ngIf="configuration.displayOwner">

                    <div class="row marges infos">
                        <div class="col_12">
                            <span>{{ item.society.name }} - {{ item.society.email }} - {{ item.society.phone }}</span>
                        </div>
                    </div>

                </ng-container>

            </div>
            <!-- Signalement de l'offre -->
            <ng-container *ngIf="hasRole('ROLE_PROVIDER') || hasRole('ROLE_INSTITUTIONAL') || hasRole('ROLE_FEDERATION')">
                <div class="col_content">
                    <button type="button" class="btn_cta btn_small" (click)="openSignalementDialog(item.id)">
                        <span class="ty-info">{{ 'offer.report.value' | translate }}</span>
                    </button>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="item.hashtags.length">

            <div class="row marges">
                <div class="col_12">
                    <div class="hashtags">
                        <div class="hashtag" *ngFor="let hashtag of parsedHashtags"><img src="assets/images/hashtag.png" alt="hashtag"><span>{{ hashtag }}</span></div>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>

    <!-- Visuels -->

    <ng-container *ngIf="translatedItemFields?.pictures.length">

        <div class="row offer_pictures">

            <!-- Visuel principal -->

            <div [ngClass]="{ 'col_6' : translatedItemFields.pictures.length > 1, 'col_12' : translatedItemFields.pictures.length <= 1 }" class="col_md_6 col_left">
                <img [src]="item | offerPictureSource: translatedItemFields.pictures[0] : 'rectangle' : (translatedItemFields.pictures.length > 1 ? 500 : 1000) : 90"/>
            </div>

            <!-- Visuels secondaires -->

            <div class="col_12 col_md_6 col_right">

                <div class="row h_full">

                    <ng-container *ngIf="translatedItemFields.pictures.length > 1">

                        <div *ngFor="let picture of translatedItemFields.pictures.slice(1, countDefaultDisplayedPictures); last as isLast" class="col_6">

                            <img [src]="item | offerPictureSource: picture : 'rectangle' : 230 : 90"/>

                            <ng-container *ngIf="isLast && translatedItemFields.pictures.length <= countDefaultDisplayedPictures">

                                <div (click)="openPictureGalleryDialog()" class="see_more">
                                    <span>{{ 'offer.picture.open.value' | translate }}</span>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="isLast && translatedItemFields.pictures.length > countDefaultDisplayedPictures">

                                <div (click)="openPictureGalleryDialog()" class="see_more">
                                    <span>{{ 'offer.picture.more.question.value' | translate }}</span>
                                </div>

                            </ng-container>

                        </div>

                    </ng-container>

                </div>

            </div>


        </div>

    </ng-container>

</div>

<!-- Composition -->

<ng-content select="[compositionContent]"></ng-content>

<div [hidden]="configuration.displayOnlyComposition">

    <div class="txt_offer">
        <p class="title" *ngIf="translatedInterestsItemFields.length > 0">{{ 'offer.form.fields.interests.title.value' | translate }}</p>

        <p class="interest">

            <!-- Intérêts -->

            <ng-container *ngFor="let interest of translatedInterestsItemFields">

                <span>{{ interest.label }}</span><br/>

            </ng-container>
        </p>
    </div>

    <!-- Description -->

    <div class="txt_offer">

        <mat-accordion multi *ngIf="translatedItemFields?.salesPitch">

            <!-- Argumentaire de vente -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'offer.salesPitch.value' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <p [innerHTML]="translatedItemFields?.salesPitch|safeHtml" class="argument"></p>

            </mat-expansion-panel>

        </mat-accordion>

        <p class="title">{{ 'offer.description.value' | translate }}</p>

        <div [innerHTML]="translatedItemFields?.description|safeHtml" #descriptionContainer class="description_offer ck-content"></div>

        <div *ngIf="isMoreDescriptionEnabled" class="see_more"><span (click)="moreDescription()">Lire la suite</span></div>
    </div>

    <!-- Compris / Non compris dans l'offre -->

    <mat-accordion multi>

        <!-- Compris dans l'offre -->

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'offer.form.fields.included.value' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngFor="let included of translatedIncludedsItemFields" class="txt_offer">
                <p class="title_2">{{ included?.title }}</p>
                <p>{{ included?.description }}</p>
            </div>

        </mat-expansion-panel>

        <!-- Non Compris dans l'offre -->

        <mat-expansion-panel>

            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'offer.form.fields.included.no.value' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngFor="let included of translatedNotIncludedsItemFields" class="txt_offer little">
                <p class="title_2">{{ included?.title }}</p>
                <p>{{ included?.description }}</p>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

    <!-- Prestataires -->

    <ng-container *ngIf="configuration.displayProviders && item.providerSocieties.length">

        <div class="txt_offer">
            <p class="title">{{ 'role.provider.plural.value' | translate }}</p>

            <p *ngFor="let provider of item.providerSocieties">{{ provider.name }}</p>
        </div>

    </ng-container>

    <!-- Programme -->

    <ng-container *ngIf="translatedProgramsItemFields.length > 0">
        <div>
            <div class="txt_offer">
                <p class="title">{{ 'offer.program.value' | translate}}</p>
            </div>

            <!-- Sélection du jour -->

            <ng-container *ngIf="translatedProgramsItemFields.length > 1">
                <div class="nav_slider_program">
                    <button type="button" class="btn_cta btn_small" *ngFor="let program of translatedProgramsItemFields; index as indexOfElement" (click)="setCurrentProgramDay(indexOfElement)">
                        {{ indexOfElement + 1 }}
                    </button>
                </div>
            </ng-container>

            <!-- Slider -->

            <div #swiper class="swiper-container slider_program" [ngClass]="{'not_pictures': !item.hasProgramsPictures}">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let program of translatedProgramsItemFields; index as indexOfElement">
                        <ng-container>
                            <span *ngIf="translatedProgramsItemFields.length > 1" class="day">{{ 'day.value' | translate }} {{ indexOfElement + 1 }}</span>
                            <div class="row h_full content_program">
                                <div [ngClass]="{'col_7': item.hasProgramsPictures}">
                                    <div class="info_program">
                                        <div class="txt_offer">
                                            <p><strong>{{ program?.title }}</strong></p>
                                            <div [innerHTML]="program?.description|safeHtml" class="ck-content"></div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="item.hasProgramsPictures">

                                    <div class="col_5" *ngIf="program?.picture?.image?.image">
                                        <img [src]="item.programs[indexOfElement] | offerProgramPictureSource: item : locale : 'portrait' : 500 : 90"/>
                                    </div>

                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="swiper-slide"></div>
                    <div class="swiper-slide" *ngIf="!item.hasProgramsPictures"></div>
                </div>
                <div #swiperPagination class="swiper-pagination"></div>
            </div>
        </div>
    </ng-container>

    <!-- Options -->

    <ng-content select="[optionsContent]"></ng-content>

    <!-- Localisation(s) -->

    <ng-container *ngIf="locations.length">

        <div>
            <div class="txt_offer">
                <p class="title">{{ 'offer.location.value' | translate}}</p>
            </div>
            <div class="map_container" leaflet [leafletOptions]="mapOptions" [leafletLayers]="markers" [leafletFitBounds]="fitBounds"   ></div>
        </div>

    </ng-container>


</div>
