<div [formGroup]="form" class="form-group">
    <mat-form-field *ngIf="!config.attrs.groupped">
        <mat-select  [formControl]="selectCtrl" [required]="config.attrs.required" placeholder="{{config.attrs.label}}" (selectionChange)="selectChange($event)" [(ngModel)]="value" [multiple]="config.attrs.multiple">

            <mat-option>
                <ngx-mat-select-search [formControl]="optionFilterCtrl" [placeholderLabel]="'Rechercher...'"
                                       [noEntriesFoundLabel]="'Aucun resultat..'" (keyup)="keypressSearch.emit($event)"

                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="config.attrs.multiple" disabled="true">Sélectionner une ou plusieurs options</mat-option>
            <mat-option *ngIf="!config.attrs.multiple"></mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id" [attr.data-cf-mat-option-value]="option.id">
                {{option.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field *ngIf="config.attrs.groupped">
        <mat-select [formControl]="selectCtrl" [required]="config.attrs.required" placeholder="{{config.attrs.label}}" (selectionChange)="selectChange($event)" [(ngModel)]="value" [multiple]="config.attrs.multiple">

            <mat-option>
                <ngx-mat-select-search [formControl]="optionFilterCtrl" [placeholderLabel]="'Rechercher...'"
                                       [noEntriesFoundLabel]="'Aucun resultat..'" (keyup)="keypressSearch.emit($event)"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="config.attrs.multiple" disabled="true">Sélectionner une ou plusieurs options</mat-option>
            <mat-option *ngIf="!config.attrs.multiple"></mat-option>

            <mat-optgroup *ngFor="let group of filteredOptions | async" [label]="group.name" [ngClass]="(config.attrs.canCheckGroup) ? 'canCheckGroup' : ''">

                <input *ngIf="config.attrs.canCheckGroup" type="checkbox" class="mat-option-pseudo-checkbox mat-pseudo-checkbox" (click)="selectAllGroup(group.choices, $event)"  />
                <span *ngIf="config.attrs.canCheckGroup" class="container_checkbox"></span>
                <mat-option *ngFor="let choice of group.choices" [value]="choice.id">
                    {{choice.name}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
    <p *ngIf="config.attrs.help" class="message-help">{{ config.attrs.help }}.</p>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors?.required">{{ 'form.validation.error.required' | translate }}</div>
        </div>
    </mat-error>
</div>
