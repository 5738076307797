import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {FormService} from "@core/shared/services/form.service";
import {SocietyDistributor} from "@core/shared/models/society/society-distributor";
import {SocietyDistributorService} from "@core/shared/services/society/society-distributor.service";
import {TranslateService} from "@ngx-translate/core";
import {Address} from "@core/shared/models/address";
import {Observable} from "rxjs";
import {OfferCatalog, OfferCatalogStatusType} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {Offer} from "@core/shared/models/offer";
import {map} from "rxjs/operators";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {TranslationService} from "@core/shared/services/translation.service";
import {
    DEFAULT_GROUP_DAY,
    DEFAULT_GROUP_STAY,
    DEFAULT_INDIVIDUAL_DAY,
    DEFAULT_INDIVIDUAL_STAY,
    SocietyCommission
} from "@core/shared/models/society-commission";
import {Role} from "@core/shared/models/role";
import {UserService} from '@core/shared/services/user.service';

@Component({
    selector: 'app-core-page-society-distributor-update',
    templateUrl: './page-society-distributor-update.component.html',
    styleUrls: ['./page-society-distributor-update.component.scss'],
    providers: [
        FormService
    ]
})
export class PageSocietyDistributorUpdateComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    public societyDistributor: SocietyDistributor;

    public availableOffers$: Observable<Offer[]>;

    public autoAcceptanceAdditionnalInfo: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _societyDistributorService: SocietyDistributorService,
        private _offerCatalogService: OfferCatalogService,
        private _userService: UserService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { societyDistributor: SocietyDistributor }): void => {

            this.societyDistributor = data.societyDistributor;

            this._loadAvailableOffers();

            this._initForm();

            this.form.patchValue(this.societyDistributor);

            if (this._userService.currentUser.getValue().society.automaticCatalogOffersAcceptance) {
                this.form.get('automaticCatalogOffersAcceptance').disable();
                this.autoAcceptanceAdditionnalInfo = true;
            }
        });
    }

    ngAfterViewInit(): void {

        this._activatedRoute.fragment.subscribe((fragment: ('availableOffers')): void => {

            switch (fragment) {

                case 'availableOffers':

                    this.tabGroup.selectedIndex = 2;

                    break;
            }
        });
    }

    public distributorHasRole(role: Role): boolean {

        return this.societyDistributor.distributor.admin.roles.indexOf(role) >= 0;
    }

    private _loadAvailableOffers(): void {

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('society.id', 'eq', this.societyDistributor.distributor.id));

        filterBuilder.addField(new TextFilterField('status', 'eq', 'accepted' as OfferCatalogStatusType));

        const params: string[] = filterBuilder.serializedFilters;

        this.availableOffers$ = this._offerCatalogService.getItemsRequestAPI(params).pipe(
            map((items: OfferCatalog[]): Offer[] => {

                return items.map((item: OfferCatalog): Offer => {

                    return item.offer;
                });
            })
        );
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            automaticCatalogOffersAcceptance: [false, Validators.required]
        });

        this.formService.submitCallback = (): void => {

            const data: object = this.form.value;

            this._societyDistributorService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('society.distributor.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    public redirectToList(): void {

        this._router.navigate(['account/society/distributor/list']);
    }

    get commissionIndividualStay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.societyDistributor.distributor.commission;

        const value: string = commission.type === 'custom' ? (commission.individualStay * 100).toFixed(2) : DEFAULT_INDIVIDUAL_STAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get commissionIndividualDay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.societyDistributor.distributor.commission;

        const value: string = commission.type === 'custom' ? (commission.individualDay * 100).toFixed(2) : DEFAULT_INDIVIDUAL_DAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get commissionGroupStay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.societyDistributor.distributor.commission;

        const value: string = commission.type === 'custom' ? (commission.groupStay * 100).toFixed(2) : DEFAULT_GROUP_STAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get commissionGroupDay(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.societyDistributor.distributor.commission;

        const value: string = commission.type === 'custom' ? (commission.groupDay * 100).toFixed(2) : DEFAULT_GROUP_DAY.toString();

        return `${value} % ${this._translateService.instant('tax.excluded.value')}`;
    }

    get incomingPayment(): string {

        if(!this.isAvailableCommission) {

            return 'N/A';
        }

        const commission: SocietyCommission = this.societyDistributor.distributor.commission;

        return this._translateService.instant(`${commission.incomingPayment ? 'yes' : 'no'}.value`);
    }

    get isAvailableCommission(): boolean {

        return this.societyDistributor.distributor.commission !== null;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get addressFields(): Address {

        return this.societyDistributor.distributor.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';

        }) || this.societyDistributor.distributor.addresses[0];
    }
}
