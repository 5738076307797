import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'translationFromQuantity'
})
export class TranslationFromQuantityPipe implements PipeTransform {

    constructor(
       private _translateService: TranslateService
    ) {}

  transform(quantity: number, emptyTranslation: string, singularTranslation: string, pluralTranslation: string): string {

      switch (quantity){

          case 0:

              return this._translateService.instant(emptyTranslation);

          case 1:

              return this._translateService.instant(singularTranslation);

          default:

              return this._translateService.instant(pluralTranslation, { quantity: quantity });
      }
  }
}
