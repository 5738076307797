import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {Pagination} from "../models/pagination";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "./api.service";
import {Promotion} from "../models/promotion";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable({
    providedIn: 'root'
})
export class PromotionService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getPaginationItemsAPI(societyId: number, params?: string[]): Observable<Pagination<Promotion>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/promotions`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Promotion>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<object>): Pagination<Promotion> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: object): Promotion => {

                        return plainToClass(Promotion, item);
                    })
                });
            })
        );
    }

    public getItemAPI(id: number): Observable<Promotion> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Promotion>(`${this._apiService.getApiUrl(false, true)}/promotions/${id}`, {
            headers: headers
        }).pipe(
            map((item: object): Promotion => {

                return plainToClass(Promotion, item);
            })
        );
    }

    public createItemAPI(societyId: number, data: object): Observable<Promotion> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Promotion>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/promotions`, data, {
            headers: headers
        }).pipe(
            map((item: object): Promotion => {

                return plainToClass(Promotion, item);
            })
        );
    }

    public updateItemAPI(id: number, data: object): Observable<Promotion> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Promotion>(`${this._apiService.getApiUrl(false, true)}/promotions/${id}`, data,{
            headers: headers
        }).pipe(
            map((item: object): Promotion => {

                return plainToClass(Promotion, item);
            })
        );
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/promotions/${id}`,{
            headers: headers
        });
    }

    public validateItemAPI(data: object): Observable<Promotion> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Promotion>(`${this._apiService.getApiUrl(false, true)}/promotions/validate`, data, {
            headers: headers
        }).pipe(
            map((item: object): Promotion => {

                return plainToClass(Promotion, item);
            })
        );
    }
}
