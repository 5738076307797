import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";

@Component({
    selector: 'app-core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {

    public user$:Observable<User>;

    constructor(
        private _authenticationService: AuthenticationService,
        private _userService: UserService
    ) {
        this.user$ = this._userService.currentUser.asObservable();
    }

    public logout(): void {

        this._authenticationService.logout();
    }
}
