import {Exclude, Expose, Transform, Type} from "class-transformer";
import {Moment} from "moment";
import * as moment from "moment";
import {DATE_FORMAT} from "@app/data";
import {OfferDateStock} from "@core/shared/models/offer/offer-date/offer-date-stock";

@Exclude()
export class OfferDate {

    @Expose()
    id: number;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATE_FORMAT).startOf('day') : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATE_FORMAT) : null, {toPlainOnly: true})
    date: Moment;

    @Expose()
    totalAllocatedStock: number;

    @Expose()
    totalRemainingStock: number;

    @Expose()
    totalSoldStock: number;

    @Expose()
    totalCommonAllocatedStock: number;

    @Expose()
    totalCommonSoldStock: number;

    @Expose()
    totalCommonRemainingStock: number;

    @Expose()
    totalAdultAllocatedStock: number;

    @Expose()
    totalAdultSoldStock: number;

    @Expose()
    totalAdultRemainingStock: number;

    @Expose()
    totalChildAllocatedStock: number;

    @Expose()
    totalChildSoldStock: number;

    @Expose()
    totalChildRemainingStock: number;

    @Expose()
    @Type(() => OfferDateStock)
    stocks: OfferDateStock[];

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    adultPriceTTC: number;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    adultPriceHT: number;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    childPriceTTC: number;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    childPriceHT: number;

    @Expose()
    releaseDate: number;

    @Expose()
    isClosed: boolean;

    @Expose()
    unlimitedStock: boolean;
}

export interface OfferDatePOST extends OfferDateAPIData {}

export interface OfferDatePATCH extends OfferDateAPIData {}

export interface OfferDateAPIData {

    date: string;

    isClosed: boolean;

    unlimitedStock: boolean;

    stocks: OfferDateStock[];

    adultPriceTTC: number;

    adultPriceHT: number;

    childPriceTTC: number;

    childPriceHT: number;

    releaseDate: number;
}
