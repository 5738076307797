<form [formGroup]="form" (submit)="formService.submit()" >

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'giftVoucher.template.action.add.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'giftVoucher.own.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit" [disabled]="form.invalid" >
                    {{ 'form.submit.action.value' | translate }}
                </button>

            </div>
        </div>

    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'offer.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<ng-template #generalData [formGroup]="form">

    <app-template-gift-voucher-form
                                    [item]="null"
                                    [user]="user"
                                    [parentForm]="form"
                                    (submit)="formService.submit()"
                                    [translationBuilder]="translationBuilder">
    </app-template-gift-voucher-form>

</ng-template>