<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'creditCard.self.value' | translate }}</h1>
            </div>
        </div>
    </div>

    <div class="content_tab content_cards">

        <ng-container *ngIf="(card$ | async) as card; else cardToRegisterTemplate">

            <div class="form-group">
                <p class="bold">{{ 'creditCard.registered.value' | translate }}</p>
            </div>

            <div class="form-group">
                <div class="card {{ (card.validity == 'INVALID') ? 'card_invalid' : 'card_valid' }}">
                    <span class="icon_{{ card.type | lowercase }}"></span>
                    <span class="number_card">{{ card.cardNumber }} | {{ card.expirationDate | slice:0:2 }}/{{ card.expirationDate | slice:2:4 }}</span>
                    <span class="cardStatut" *ngIf="card.validity == 'INVALID'">{{ 'payment.card.invalid.value' | translate }}</span>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col_content">
                        <button type="submit" class="btn_cta btn_little" (click)="redirectToCardUpdate()">{{ 'creditCard.update.action.value' | translate }}</button>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-template #cardToRegisterTemplate>

            <div class="create_card_information col_12">
                <p [innerHTML]="'creditCard.description.value' | translate"></p>
            </div>

            <div class="col_content y_items_center">
                <button type="submit" class="btn_cta btn_little" (click)="redirectToCardCreate()">{{ 'creditCard.add.action.value' | translate }}</button>
            </div>

        </ng-template>
    </div>
</div>
