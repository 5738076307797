import {Component} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {LegacyProgressSpinnerMode as ProgressSpinnerMode} from "@angular/material/legacy-progress-spinner";
import {Subject} from "rxjs";
import {LoaderService} from "@core/shared/services/loader.service";

@Component({
    selector: 'app-core-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    public color : ThemePalette = 'primary';

    public mode : ProgressSpinnerMode = 'indeterminate';

    public value = 50;

    public isLoading: Subject<boolean> = this._loaderService.isLoading;

    constructor(
        private _loaderService: LoaderService
    ){}

}
