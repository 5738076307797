import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {PromotionPeriodDeleteComponent} from "@core/components/promotion/promotion-period/promotion-period-delete/promotion-period-delete.component";
import {Promotion} from "@core/shared/models/promotion";
import {PromotionPeriodDeleteConfiguration} from "@core/shared/models/promotion/promotion-period/promotion-period-delete";

@Component({
    selector: 'app-core-promotion-period-delete-dialog',
    templateUrl: './promotion-period-delete-dialog.component.html',
    styleUrls: ['./promotion-period-delete-dialog.component.scss']
})
export class PromotionPeriodDeleteDialogComponent implements OnInit {

    @Output() delete: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(PromotionPeriodDeleteComponent) promotionPeriodDeleteComponent: PromotionPeriodDeleteComponent;

    constructor(
        private _dialogRef: MatDialogRef<PromotionPeriodDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { promotion: Promotion }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.delete
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.promotionPeriodDeleteComponent.formService.submit();
    }

    get promotion(): Promotion {

        return this.data.promotion;
    }

    get configuration(): PromotionPeriodDeleteConfiguration {

        return {
            showSubmitButton: false
        };
    }
}
