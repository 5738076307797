import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";
/**
 * Configuration paramètres du champ
 */
export interface InputTextConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        placeholder?: string;
        minlength?: number;
        maxlength?: number;
        email?: boolean;
        pattern?: string;
        pattern_info?: string;
        help?: string;
        disabled?: boolean;
    };
}

/**
 * Champ input text
 */
@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() config: InputTextConfig;

    /**
     * Valeur du champ texte
     */
    @Input() value : string;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    ngOnInit(): void {

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl(''));

        if(this.config.attrs.disabled){
            this.form.get(this.config.id).disable();
        }

        if ('minlength' in this.config.attrs) {
            validators.push(Validators.minLength(this.config.attrs.minlength));
        }

        if ('maxlength' in this.config.attrs) {
            validators.push(Validators.maxLength(this.config.attrs.maxlength));
        }

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        if (('email' in this.config.attrs) && this.config.attrs.email) {
            validators.push(Validators.email);
        }

        if (('pattern' in this.config.attrs) && this.config.attrs.pattern) {
            if(!this.config.attrs.pattern_info){
                this.config.attrs.pattern_info = "Ce champ est invalide";
            }
            validators.push(Validators.pattern(this.config.attrs.pattern));
        }

        this.form.controls[this.config.id].setValidators(validators);
    }
}
