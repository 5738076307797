import { Component, OnInit} from '@angular/core';
import {SocietyService as SocietyServiceModel, SocietyService} from "@core/shared/models/society-service";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ServiceService} from "@core/shared/services/service.service";
import {parsePrice} from "@core/shared/utils/price";
import {Service, ServiceTranslation} from "@core/shared/models/service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {concat, Observable, throwError} from "rxjs";
import {validateNumericOnly} from "@core/shared/utils/card-number";
import {PaymentServiceService} from "@core/shared/services/payment-service.service";
import {catchError, toArray} from "rxjs/operators";
import {browserInfos} from "@core/shared/utils/browser-infos";
import {TermsAndConditionsService} from "@core/shared/services/terms-and-conditions.service";
import {PaymentInitialize} from "@core/shared/models/payment-initialize";
import {Role} from "@core/shared/models/role";
import {Card} from "@core/shared/models/card";
import {CardService} from "@core/shared/services/card.service";

@Component({
  selector: 'app-service-subscription-create',
  templateUrl: './page-service-subscription-create.component.html',
  styleUrls: ['./page-service-subscription-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageServiceSubscriptionCreateComponent implements OnInit{

    public service: Service;

    public societyServices: SocietyService[];

    public societyServiceSummaries: SocietyService[];

    public cardAlreadyRegistered: boolean = false;

    public card$: Observable<Card>;

    public dateStart: Date;

    public cardRegistrationUrl: string;

    public quantities: number[] = [];

    public submitLoading: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _translationService: TranslationService,
        private _translateService: TranslateService,
        private _paymentServiceService: PaymentServiceService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _userService: UserService,
        private _serviceService: ServiceService,
        private _cardService: CardService,
        public formService: FormService,
        public termsAndConditionsService: TermsAndConditionsService
    ) {
    }

    ngOnInit(): void {

        this.dateStart = new Date();

        this._activatedRoute.data.subscribe((data: {service: Service, societyServices: SocietyService[]}): void => {

            this.service = data.service;

            this.societyServices = data.societyServices;

            this._initForm();

            this._paymentInitialize(this.currentUser.society.id);

            this._initServiceSummaries().subscribe((societyServices: SocietyService[]) => {

                this.societyServiceSummaries = societyServices;

                if (this.service.isWithSubscription) {

                    this.form.addControl('periodicity', this._formBuilder.control(null, [Validators.required]))
                }

                if (this.service.min !== this.service.max) {

                    this.form.addControl('quantity', this._formBuilder.control(null, [Validators.required]))

                    this._initQuantities();
                }

                this._initEvents();

            });
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            acceptTermsAndConditions: [false, [Validators.requiredTrue]]
        });

        this.formService.submitCallback = (): void => {

            this.submitLoading = true;

            this._serviceService.createSocietyServiceItemAPI(this.createSocietyServiceData, this.currentUser.society.id).subscribe((societyService: SocietyService): void => {

                if (this.cardAlreadyRegistered) {

                    this._initPaymentValidate(null, societyService);

                } else {

                    const dataToCardRegistration = `cardNumber=${this.creditCardControl.get('cardNumber').value.replace(/\s/g, '')}&cardExpirationDate=${this.creditCardControl.get('cardExpirationDate').value.replace('/', '')}&cardCvx=${this.creditCardControl.get('cardCvx').value}&accessKeyRef=${this.creditCardControl.get('accessKey').value}&data=${this.creditCardControl.get('data').value}`;

                    this._paymentServiceService.cardRegistrationUrl(this.cardRegistrationUrl, dataToCardRegistration).subscribe((data) => {

                        const dataToValidate: object = Object.assign({}, {
                            "data": data,
                            "id": this.creditCardControl.get("id").value,
                        });

                        this._initPaymentValidate(dataToValidate, societyService);
                    });
                }
            }, () => {

                this.submitLoading = false;
            });
        }
    }

    private _initEvents(): void {

        const controls: AbstractControl[] = [];

        if(this.periodicityControl){

            controls.push(this.periodicityControl);
        }

        if(this.quantityControl){

            controls.push(this.quantityControl);
        }

        controls.forEach((control: AbstractControl): void => {

            control.valueChanges.subscribe((): void => {

                if(!this.quantityControl.valid){

                    return;
                }

                this._initServiceSummaries().subscribe((societyService: SocietyService[]) => {

                    this.societyServiceSummaries = societyService;
                });
            });
        });
    }

    private _initCard(): void {

        this.card$ = this._cardService.getSocietyItem(this.currentUser.society.id);
    }

    private _paymentInitialize(societyId: number): void {

        this._paymentServiceService.paymentInitialize(societyId).subscribe((paymentInitialize: PaymentInitialize): void => {

            if ('id' in paymentInitialize) {

                this.form.addControl('creditCard', this.createCreditCardControl);

                this.cardRegistrationUrl = paymentInitialize.url;

                this.creditCardControl.get('accessKey').patchValue(paymentInitialize.accessKeyRef);
                this.creditCardControl.get('data').patchValue(paymentInitialize.data);
                this.creditCardControl.get('id').patchValue(paymentInitialize.id);
            }
            else {

                this.cardAlreadyRegistered = true;

                this._initCard();
            }
        });
    }

    private _initPaymentValidate(cardRegistration: object, societyService: SocietyService): void {

        const dataToValidate: object = {
            cardRegistration: cardRegistration,
            societyService: {
                id: societyService.id
            },
            browserInfo: browserInfos()
        };

        this._paymentServiceService.paymentValidate(this.currentUser.society.id, dataToValidate).pipe(catchError((err : Observable<any>) => {

            this.cardAlreadyRegistered = false;

            this._paymentInitialize(this.currentUser.society.id);

            return throwError(this._translateService.instant('payment.error.card.value'));

        })).subscribe((data: { status: string, redirectUrl: string, paymentId: string }): void => {

            if (data.status === 'REDIRECT') {

                location.href = data.redirectUrl;
            }

            if (data.status === 'SUCCESS') {

                if(data.paymentId){

                    this._router.navigate([`account/service/payment-response/${this.service.id}/success/${data.paymentId}`]);
                }
                else{

                    this._router.navigate([`account/service/payment-response/${this.service.id}/success`]);
                }
            }
        });
    }

    private _initQuantities(): void {

        for(let quantity: number = this.service.min; quantity <= this.service.max; quantity++){

            this.quantities.push(quantity);
        }
    }

    private _initServiceSummaries(): Observable<SocietyService[]> {

        const arrayToForkJoin = [
            this._serviceService.summarySocietyServiceItemAPI(this._initSocietyServiceData('M'), this.currentUser.society.id)
        ]

        if (this.service.isWithSubscription){

            arrayToForkJoin.push(this._serviceService.summarySocietyServiceItemAPI(this._initSocietyServiceData('Y'), this.currentUser.society.id));
        }

        return concat(...arrayToForkJoin).pipe(toArray());
    }

    private _initSocietyServiceData(periodicity: string): object {

        return {
            service: {
                id: this.service.id
            },
            periodicity: periodicity,
            quantity: (this.quantityControl && this.quantityControl.valid) ? this.quantityControl.value : this.service.min
        };
    }

    public getAdmissionPrice(index: number): number {

        return this.societyServiceSummaries[index].hasAdmissionPrice ? this.societyServiceSummaries[index].admissionPrice * (1 + this.societyServiceSummaries[index].vatPercent) : 0;
    }

    public getAmountTTC(index: number): string {

        let total: number = this.societyServiceSummaries[index].total;

        const vatPercent: number = this.societyServiceSummaries[index].vatPercent;

        total = total + (total * vatPercent);

        return parsePrice(total/100) + '€ TTC';
    }

    public handleBackToServices(): void {

        this._router.navigate(['account/service/list']);
    }

    public formatPrice(price: number, symbol: string): string {

        return parsePrice(price / 100) + `€ ${symbol}`;
    }

    public hasServicesInPeriodicity(periodicity: ('M'|'Y')): boolean {

        return this.societyServices.some((societyService: SocietyService): boolean => {

            return societyService.isValid && societyService.periodicity === periodicity && societyService.service.isWithSubscription;
        });
    }

    public servicesInPeriodicity(periodicity: ('M'|'Y')): SocietyService {

        return this.societyServices.find((societyService: SocietyService): boolean => {

            return societyService.isValid && societyService.periodicity === periodicity && societyService.service.isWithSubscription;
        });
    }

    public checkPeriodicity(periodicity: any) {

        if(!this.periodicityControl){

            return;
        }

        this.periodicityControl.patchValue(periodicity);
    }

    public hasRole(role: Role): boolean {

        return this.currentUser.roles.includes(role);
    }

    public calculatePrice(societySummary: SocietyServiceModel, symbol:  'TTC'|'HT'): number {

        const quantity = this.hasQuantity ? this.quantityControl.value : 1;

        switch (societySummary.periodicity) {

            case "M":

                return quantity * ((symbol === 'TTC')  ? societySummary.service.monthlyPriceTTC : societySummary.service.monthlyPriceHT);

            case "Y":

                return quantity * ((symbol === 'TTC')  ? societySummary.service.yearlyPriceTTC : societySummary.service.yearlyPriceHT);
        }
    }

    public calculateTVA(societySummary: SocietyServiceModel): number {

        const quantity = this.hasQuantity ? this.quantityControl.value : 1;

        switch (societySummary.periodicity) {

            case "M":
                return (quantity * (societySummary.service.monthlyPriceTTC - societySummary.service.monthlyPriceHT)) / 100;

            case "Y":

                return  (quantity * (societySummary.service.yearlyPriceTTC - societySummary.service.yearlyPriceHT)) / 100;
        }
    }

    get createCreditCardControl(): UntypedFormGroup {

        return this._formBuilder.group({
            cardNumber: [null, [Validators.required, (control: UntypedFormControl) => {
                let field = {...control}
                let cardNumber = field.value;
                cardNumber = (cardNumber) ? cardNumber.replace(/\s/g, "") : "";

                return validateNumericOnly(cardNumber) === false ?
                    {invalidNUmberCard: {valid: false}} : null;

            }]],
            cardExpirationDate: [null, [Validators.required, (control: UntypedFormControl) => {
                let field = {...control}
                let cardDate = field.value ? field.value.replace("/", "") : "";

                let currentDate = new Date();

                // Requires 2 digit for month and 2 digits for year
                if (cardDate.length === 4) {

                    let year = parseInt(cardDate.substr(2, 2), 10) + 2000;
                    let month = parseInt(cardDate.substr(0, 2), 10);

                    if (month > 0 && month <= 12) {

                        let currentYear = currentDate.getFullYear();
                        if (currentYear < year)
                            return null;

                        if (currentYear === year) {
                            let currentMonth = currentDate.getMonth() + 1;
                            if (currentMonth <= month)
                                return null;
                        }

                        // Date is in the past
                        return {invalidDatePast: {valid: false}};
                    }
                }

                // Date does not look correct
                return {invalidFormatDate: {valid: false}};

            }]],
            cardCvx: [null, [Validators.required, (control: UntypedFormControl) => {
                let field = {...control}
                let cvv = field.value ? field.value.trim() : "";

                if (validateNumericOnly(cvv) === true) {
                    if (cvv.length === 3) {
                        return null;
                    }
                }

                // Invalid format
                return {invalidFormatCvv: {valid: false}};

            }]],
            accessKey: [],
            data: [],
            id: []
        });
    }

    get creditCardControl(): UntypedFormArray {

        return this.form.get('creditCard') as UntypedFormArray;
    }

    get periodicityControl(): AbstractControl {

        return this.form.get('periodicity');
    }

    get quantityControl(): AbstractControl {

        return this.form.get('quantity');
    }

    get serviceTranslation(): ServiceTranslation {

        return this._translationService.getFallbackTranslation(this.service.translations);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get createSocietyServiceData(): object {

        return {
            service: {
                id: this.service.id
            },
            periodicity: (this.periodicityControl && this.periodicityControl.valid) ? this.periodicityControl.value : 'M',
            quantity: (this.quantityControl && this.quantityControl.valid) ? this.quantityControl.value : this.service.min
        };
    }

    get hasQuantity(): boolean {

        return this.service.min !== this.service.max;
    }

    get isValidBilling(): boolean {

        if(!this.hasQuantity) {

            return true;
        }

        if(this.hasQuantity && !this.service.isWithSubscription){

            if((this.quantityControl && this.quantityControl.valid)) {

                return true;
            }
        }

        return (this.periodicityControl && this.periodicityControl.valid) && (this.quantityControl && this.quantityControl.valid);
    }

    get admissionPrice(): number {

        return this.selectedSocietyServiceSummary.hasAdmissionPrice ? this.selectedSocietyServiceSummary.admissionPrice * (1 + this.selectedSocietyServiceSummary.vatPercent) : 0;
    }

    get selectedSocietyServiceSummary(): SocietyService {

        const periodicity = this.service.isWithSubscription ? this.periodicityControl.value : 'M';

        return this.societyServiceSummaries.find((societyService: SocietyService) => {
            return periodicity === societyService.periodicity;
        });
    }

    get hasInformationsToShow(): boolean {

        const conditions: boolean[] = [
            this.periodicityControl && this.hasServicesInPeriodicity(this.periodicityControl.value),
             (this.selectedSocietyServiceSummary && this.selectedSocietyServiceSummary.total === 0) && !this.cardAlreadyRegistered
        ];

        return conditions.some((condition: boolean): boolean => {

            return Boolean(condition);
        });
    }

    get periodicity(): 'M' | 'Y' {

        return this.periodicityControl.value;
    }

    get paymentLabel(): string {

        switch (true){

            case (!this.service.hasAdmissionPrice && !this.service.isWithSubscription && this.service.hasUsingPrice):

                return 'subscription.action.value';

            default:

                return 'subscription.actions.validatePayment.value';
        }
    }
}
