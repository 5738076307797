import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import { TranslationBuilder} from "@core/shared/models/translation";
import {UserService} from "@core/shared/services/user.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "@core/shared/services/api.service";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";
import {ActivatedRoute, Router} from "@angular/router";
import { TemplateGiftVoucher } from "@core/shared/models/gift-voucher/template-gift-voucher";
import {User} from "@core/shared/models/user";

@Component({
    selector: 'app-page-template-gift-voucher-update',
    templateUrl: './page-template-gift-voucher-update.component.html',
    styleUrls: ['./page-template-gift-voucher-update.component.scss'],
    providers: [FormService]
})
export class PageTemplateGiftVoucherUpdateComponent implements OnInit {

    public translationBuilder: TranslationBuilder;

    public user: User;

    public templateGiftVoucher: TemplateGiftVoucher;

    constructor(public formService: FormService,
                public userService: UserService,
                private _router: Router,
                private _activatedRoute: ActivatedRoute,
                private _snackBar: MatSnackBar,
                private _translateService: TranslateService,
                private _apiService: ApiService,
                private _formBuilder: UntypedFormBuilder,
                private _giftVoucherService: TemplateGiftVoucherService) {
    }

    ngOnInit(): void {

        this.user = this.userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { templateGiftVoucher: TemplateGiftVoucher }): void => {

            this.templateGiftVoucher = data.templateGiftVoucher;

            this._initForm();

        });
    }

    private _initTranslations(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            type: ['', [Validators.required]],
            validType: [null, [Validators.requiredTrue]],
            isDematerialized: [false],
            isPhysical: [false],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this._initTranslations();

        this.formService.submitCallback = () => {

            const data = Object.assign({...this.form.value}, {
                translations: this.translationBuilder.itemsControl.controls.map((control: AbstractControl): object => {

                    const value: object = {...control.value};

                    if(!value['id']){

                        delete value['id'];
                    }

                    return value;
                })
            });


            delete data['validType'];

            this._giftVoucherService.updateItemAPI(this.templateGiftVoucher.id, data).subscribe(() => {

                this._snackBar.open(this._translateService.instant('giftVoucher.template.action.edit.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        }
    }

    public redirectToList(): void {

        this._router.navigate(['account/template-gift-voucher/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

}
