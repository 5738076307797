import {Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter,} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '@core/shared/services/form.service';
import {TranslationBuilder} from '@core/shared/models/translation';
import {Offer} from '@core/shared/models/offer';
import {OfferInterest, OfferInterestTranslation} from '@core/shared/models/offer/offer-interest';
import {MatExpansionPanel} from "@angular/material/expansion";
import {OfferService} from "@core/shared/services/offer.service";

@Component({
    selector: 'app-offer-interest-create',
    templateUrl: './offer-interest-create.component.html',
    styleUrls: ['./offer-interest-create.component.scss']
})
export class OfferInterestCreateComponent implements OnInit, AfterViewInit {

    @ViewChildren(MatExpansionPanel) expensionPanels: QueryList<MatExpansionPanel>;

    public interestsLen : number = 0;

    public limitInterest : number = 3;

    public interestsTranslationBuilder: TranslationBuilder[] = [];

    @Input() parentInterests: UntypedFormGroup;

    @Input() offer : Offer;

    @Output() controlUpdated: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        public offerService: OfferService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        if (this.offer) {

            this.offer.interests.forEach((interest: OfferInterest): void => {

                this.interestsForm.push(this.initInterestsFormControl(interest));
            });

        } else {

            this.interestsForm.push(this.initInterestsFormControl(null));
        }

        this.form.get('locales').valueChanges.subscribe((locales: string[]): void => {

            this.interestsTranslationBuilder.forEach((builder: TranslationBuilder): void => {

                builder.updateItems(locales);
            });
        });
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            if(this.expensionPanels && this.interestsForm.length === 1){

                this.expensionPanels.first.expanded = true;
            }
        });
    }

    public initInterestsFormControl(data: OfferInterest): UntypedFormGroup {

        const control: UntypedFormGroup = this._formBuilder.group({
            translations: new UntypedFormArray([]),
        });

        const interestsTranslationBuilder = new TranslationBuilder(this._formBuilder);

        interestsTranslationBuilder.form = control;

        interestsTranslationBuilder.addItemCallback = () : UntypedFormGroup => {

            return this._formBuilder.group({
                label: [null, [Validators.pattern(/^(.{0,100})$/), Validators.required]],
            });
        };

        if (data){

            data.translations.forEach((translation: OfferInterestTranslation): void => {

                interestsTranslationBuilder.addItemControl(interestsTranslationBuilder.getLocaleItem(translation.locale), translation);
            });

        } else {

            interestsTranslationBuilder.updateItems(this.form.get('locales').value);
        }

        this.interestsTranslationBuilder.push(interestsTranslationBuilder);

        this.interestsLen++;

        return control;
    }

    public getErrorMaxLabel(): string {

        return this._translateService.instant('offer.form.action.interests.error.value', {
            nbPrograms : (this.interestsLen - this.limitInterest),
        });
    }

    public addInterest(): void {

        this.interestsForm.push(this.initInterestsFormControl(null));

        setTimeout((): void => {

            this.controlUpdated.emit();
        });

        this.openNewPanel();
    }

    public deleteInterest(index: number): void {

        this.interestsForm.removeAt(index);

        setTimeout((): void => {

            this.controlUpdated.emit();
        });

        this.interestsLen--;
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getInterestsTranslation(indexInterest: number, indexTranslation : number): UntypedFormGroup {

        return (this.interestsForm.controls[indexInterest].get('translations') as UntypedFormGroup).controls[indexTranslation] as UntypedFormGroup;
    }

    public getItemTranslationControl(index: number): AbstractControl {

        const translationControls: UntypedFormArray = this.interestsForm.controls[index].get('translations') as UntypedFormArray;

        if(!translationControls.length){

            return null;
        }

        return translationControls.controls.find((control: UntypedFormGroup): boolean => {

            return control.value.locale === this.localeId;

        }) || translationControls[0];
    }

    public getTranslationBuilder(index: number): TranslationBuilder {

        return this.interestsTranslationBuilder.find((builder: TranslationBuilder): boolean => {

            return builder.form === this.getInterestControl(index);
        });
    }

    get form(): UntypedFormGroup {

        return this.parentInterests;
    }

    get interestsForm(): UntypedFormArray {

        return this.form.get('interests') as UntypedFormArray;
    }

    public getInterestControl(index: number): AbstractControl {

        return this.interestsForm.controls[index];
    }

    public openNewPanel(): void {

        setTimeout((): void => {

            this.expensionPanels.last.expanded = true;
        });
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get selectedLocales(): string[] {

        if(!this.form || !this.form.get('locales')){

            return [];
        }

        return this.form.get('locales').value;
    }
}
