import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {User} from "@core/shared/models/user";
import {Offer} from "@core/shared/models/offer";
import {TranslationService} from "@core/shared/services/translation.service";
import {CountryService} from "@core/shared/services/country.service";
import {Observable, Subject} from "rxjs";
import {Country} from "@core/shared/models/country";
import {OfferGiftVoucherComposition} from "@core/shared/models/offer/offer-gift-voucher-composition";
import {PromotionService} from "@core/shared/services/promotion.service";
import {LocationField, LocationFieldType} from "@core/shared/models/location";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {Promotion} from "@core/shared/models/promotion";
import {OfferGiftVoucherRequestService} from "@core/shared/services/offer/offer-gift-voucher/offer-gift-voucher-request.service";
import {CustomerType} from "@core/shared/models/customer/customer-type";
import {CustomerTypeService} from "@core/shared/services/customer/customer-type.service";

@Component({
    selector: 'app-offer-gift-voucher-composition',
    templateUrl: './offer-gift-voucher-request-composition.component.html',
    styleUrls: ['./offer-gift-voucher-request-composition.component.scss']
})
export class OfferGiftVoucherRequestCompositionComponent implements OnInit, AfterViewInit {

    @Input() offer: Offer;

    @Input() data: OfferGiftVoucherComposition;

    @Input() termsAndConditions: number;

    @Input() user: User;

    @Output() compositionSubmitted: EventEmitter<OfferGiftVoucherComposition> = new EventEmitter<OfferGiftVoucherComposition>();

    @Output() compositionUpdated: EventEmitter<void> = new EventEmitter<void>();

    public countAdultUpdatedSubject: Subject<void> = new Subject();

    public countChildUpdatedSubject: Subject<void> = new Subject();

    public countries$: Observable<Country[]>;

    public countAdult: number;

    public countChild: number;

    public validatePromotion: boolean = false;

    public customerTypes: CustomerType[] = [];

    public displayPromotionEmailRequired: boolean = false;

    constructor(
        private _promotionService: PromotionService,
        private _formBuilder: UntypedFormBuilder,
        private _countryService: CountryService,
        private _customerTypeService: CustomerTypeService,
        private _offerGiftVoucherRequestService: OfferGiftVoucherRequestService,
        public formService: FormService,
        public translationService: TranslationService,
    ) {
    }

    ngOnInit(): void {

        this._loadCountries();

        this._initCustomerTypes();

        this._initForm();

        this._initEvents();

        this._updateValidators();

        const items: { subject: Subject<void> }[] = [
            {
                subject: this.countAdultUpdatedSubject
            },
            {
                subject: this.countChildUpdatedSubject
            },
        ];

        items.forEach((item: { subject: Subject<void> }): void => {

            item.subject.subscribe((): void => {

                this.compositionUpdated.emit();

            });
        });

        this.countAdult = this.offer.publics.includes('adult') ? this.offer.presential.adultDefault : null;

        this.countChild = this.offer.publics.includes('child') ? this.offer.presential.childDefault : null;
    }

    ngAfterViewInit() {

        if (this.data) {

            this._initData()
        }
    }

    private _initCustomerTypes(): void {

        this._customerTypeService.getItemsAPI().subscribe((customerTypes: CustomerType[]): void => {

            this.customerTypes = customerTypes;
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            nbAdult: [this._offerGiftVoucherRequestService.defaultNbAdult || this.offer.presential.adultMin],
            nbChild: [this._offerGiftVoucherRequestService.defaultNbChild || this.offer.presential.childMin],
            promotion: [null],
            promotionCode: [null],
            isValidPromotion: [false, [Validators.required]],
            beneficiarySameAsBuyer: [false]
        });

        this.form.addControl('beneficiary', this.createBeneficiaryControl);

        this.formService.submitCallback = () => {

            const data = Object.assign({...this.form.value}, {
                offer: this.offer,
                buyer: Object.assign(this.customerControl.value, {
                    customerType: this.customerControl.value.customerType ? {id: this.customerControl.value.customerType} : null,
                    society: this.customerControl.value.society ? this.customerControl.value.society : null,
                    phone: this.customerControl.get('phone').value?.e164Number.length ? this.customerControl.get('phone').value.e164Number : null
                }),
                recipient: this.form.get('beneficiarySameAsBuyer').value ? 'buyer' : 'beneficiary',
                acceptTermsAndConditions: true,
            });

            if (!this.form.get('beneficiarySameAsBuyer').value) {

                Object.assign(data, {
                        beneficiary: {
                            ...data['beneficiary'],
                            customerType: this.beneficiaryControl.value.customerType ? {id: this.beneficiaryControl.value.customerType} : null,
                            phone: this.beneficiaryControl.get('phone').value?.e164Number.length ? this.beneficiaryControl.get('phone').value.e164Number : null
                        }
                    }
                );
            } else {

                Object.assign(data, {
                    beneficiary: {...data['buyer']}
                })

            }

            this.compositionSubmitted.emit(data);
        }
    }

    private _loadCountries(): void {

        this.countries$ = this._countryService.getItemsAPI();
    }

    private _initEvents() {

        this.form.get('promotionCode').valueChanges.subscribe((): void => {

            this.form.get('isValidPromotion').patchValue(false);

            this.form.get('promotion').patchValue(null);
        });

        this.form.get('beneficiarySameAsBuyer').valueChanges.subscribe((value: boolean) => {

            this._updateValidators();
        });

        this.beneficiaryControl.get('customerType').valueChanges.subscribe(() : void => {

            this.beneficiaryControl.get('society').patchValue(null);
        });

        setTimeout((): void => {

            this.customerControl.get('email').valueChanges.subscribe((): void => {

                this.form.get('isValidPromotion').patchValue(false);

                this.form.get('promotion').patchValue(null);

                this.displayPromotionEmailRequired = this.customerControl.get('email').invalid;
            });
        });
    }

    private _initData() {

        this.form.patchValue(Object.assign({}, {
            nbChild: this.data.nbChild,
            nbAdult: this.data.nbAdult,
            promotionCode: this.data.promotionCode,
            promotion: this.data.promotion,
            isValidPromotion: this.data.isValidPromotion,
            beneficiarySameAsBuyer: this.data.beneficiarySameAsBuyer,
            beneficiary: this.data.beneficiary,
            buyer: {
                ...this.data.buyer,
                customerType: this.data.buyer.customerType ? this.data.buyer.customerType.id : null,
                society: this.data.buyer.society ? this.data.buyer.society : null
            }
        }));
    }

    private _updateValidators() {

        if (this.form.get('beneficiarySameAsBuyer').value) {

            this.form.removeControl('beneficiary');

        } else {

            this.form.addControl('beneficiary', this.createBeneficiaryControl);
        }
    }

    public validatePromotionCode(): void {

        this.form.get('isValidPromotion').patchValue(false);

        this.form.get('promotion').patchValue(null);

        this.displayPromotionEmailRequired = this.customerControl.get('email').invalid;

        if(this.customerControl.get('email').invalid){

            return;
        }

        this.validatePromotion = true;

        const data: object = {
            scope: 'gift-voucher',
            offer: {
                id: this.offer.id
            },
            email: this.customerControl.get('email').value,
            code: this.form.get('promotionCode').value
        };

        this._promotionService.validateItemAPI(data).subscribe((promotion: Promotion): void => {

            this.form.get('isValidPromotion').patchValue(true);

            this.form.get('promotion').patchValue(promotion);

            this.compositionUpdated.emit();
        });
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ];
    }

    get createBeneficiaryControl(): UntypedFormGroup {

        return this._formBuilder.group({
            customerType: [null, [Validators.required]],
            society: ['', [(control: UntypedFormControl): { [key: string]: any } => {

                if(!this.isSocietyRequired){

                    return null;
                }

                if(!!control.value){

                    return null;
                }

                return {
                    'societyRequired' : {
                        valid: false
                    }
                };
            }]],
            civility: [null, [Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
            address: ['', [Validators.required]],
            additionalAddress: [''],
            zipcode: ['', [Validators.required]],
            region: [''],
            city: ['', [Validators.required]],
            country: ['', [Validators.required]],
        });
    }

    get countAdultValues(): number[] {

        const start: number = this.offer.presential.adultMin;

        const end: number = this.offer.presential.adultMax;

        const step: number = this.offer.presential.adultIncrementalStep;

        const arrayLength = step > 0 ? (Math.floor(((end - start) / step)) + 1) : 1;

        return [...Array(arrayLength).keys()].map(x => (x * step) + start);
    }

    get countChildValues(): number[] {

        const start: number = this.offer.presential.childMin;

        const end: number = this.offer.presential.childMax;

        const step: number = this.offer.presential.childIncrementalStep;

        const arrayLength = step > 0 ? (Math.floor(((end - start) / step)) + 1) : 1;

        return [...Array(arrayLength).keys()].map(x => (x * step) + start);
    }

    get customerControl(): UntypedFormGroup {

        return this.form.get('buyer') as UntypedFormGroup;
    }

    get beneficiaryControl(): UntypedFormGroup {

        return this.form.get('beneficiary') as UntypedFormGroup;
    }

    get termsAndConditionsFile(): number {

        return this.termsAndConditions;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get locale(): string {

        return this.user.locale;
    }

    get item(): OfferGiftVoucherComposition {

        return {
            buyer: this.form.get('buyer').value ? Object.assign({...this.form.get('buyer').value}, {
                phone: (this.customerControl.get('phone').value && this.customerControl.get('phone').value?.e164Number.length) ? this.customerControl.get('phone').value.e164Number : null,
                customerType: this.form.get('buyer').get('customerType').value ? {id: this.form.get('buyer').get('customerType').value} : null
            }) : null,
            beneficiary: this.form.get('beneficiary') ? Object.assign({...this.form.get('beneficiary').value}, {
                phone: (this.beneficiaryControl.get('phone').value && this.beneficiaryControl.get('phone').value?.e164Number.length) ? this.beneficiaryControl.get('phone').value.e164Number : null,
                customerType: this.beneficiaryControl.get('customerType').value ? {id: this.beneficiaryControl.get('customerType').value} : null
            }) : null,
            beneficiarySameAsBuyer: this.form.get('beneficiarySameAsBuyer').value,
            nbAdult: this.form.get('nbAdult') ? this.form.get('nbAdult').value : null,
            nbChild: this.form.get('nbChild') ? this.form.get('nbChild').value : null,
            promotion: this.form.get('promotion') ? this.form.get('promotion').value : null,
            promotionCode: this.form.get('promotionCode') ? this.form.get('promotionCode').value : null
        };
    }

    get isPresentialSelectionValid(): boolean {

        if (!this.form) {

            return false;
        }

        let total: number = 0;

        if (this.form.get('nbAdult') && this.form.get('nbAdult').value > 0) {

            total += this.form.get('nbAdult').value;
        }

        if (this.form.get('nbChild') && this.form.get('nbChild').value > 0) {

            total += this.form.get('nbChild').value;
        }

        return total > 0 && this.isValidNbPerson;
    }

    get isValidNbPerson(): boolean {

        const max: number = this.offer.presential.max;

        const nbAdult: number = this.form.get('nbAdult') && this.form.get('nbAdult').value ? this.form.get('nbAdult').value : 0;

        const nbChild: number = this.form.get('nbChild') && this.form.get('nbChild').value ? this.form.get('nbChild').value : 0;

        return !max || (nbAdult + nbChild) <= this.offer.presential.max;
    }

    get isSocietyRequired(): boolean {

        if(!this.beneficiaryControl){

            return false;
        }

        const control: FormControl = this.beneficiaryControl.get('customerType') as FormControl;

        if(!control.value){

            return false;
        }

        const customerType: CustomerType = this.customerTypes.find((item: CustomerType): boolean => {

            return item.id === control.value;
        });

        return customerType ? customerType.societyRequired : false;
    }
}
