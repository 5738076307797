<div class="thumb-input">

    <div class="full">
        <span class="label">{{ 'element.types.iframe.url' | translate }}</span>
        <input type="text" [(ngModel)]="content.source" (ngModelChange)="setContent(content)">
    </div>

    <div>
        <div class="tier ">
            <span class="label">{{ 'element.types.iframe.width' | translate }}</span>
            <input type="number" [(ngModel)]="content.width" (ngModelChange)="setContent(content)">
        </div>

        <div class="tier ">
            <span class="label">{{ 'element.types.iframe.height' | translate }}</span>
            <input type="number" [(ngModel)]="content.height" (ngModelChange)="setContent(content)">
        </div>

        <div class="tier apercu_element">
            <span class="label">&nbsp;</span>
            <button type="button" mat-raised-button color="primary" *ngIf="content.source && content.width && content.height" (click)="displayIframe()">{{ 'element.types.iframe.preview.show' | translate }}</button>
        </div>
    </div>

</div>

