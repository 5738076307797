import {Component, Input} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";

/**
 *
 */
@Component({
    selector: 'app-cms-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class CmsImageComponent{

    /**
     * Valeur du champ texte
     */
    @Input() image : any;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor(
        private _apiService: ApiService) {
    }

    public getSelectedFormat(formats: any)
    {
        if (!Array.isArray(formats))
            return null;

        let matches = formats.filter((format) => {
            return format['selected'];
        });

        if (matches.length == 0)
            return 'landscape';

       let match = matches[0];

        return match['name'];
    }

    public getVisuelCms( idImage: number, format:string): string {
        return !!idImage ? `${this._apiService.getApiUrl(false, false)}/public/cms/picture/${idImage}/filter/${this.getSelectedFormat(format)}` : null;
    }
}
