import {OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";

export const OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER: string = 'offer-creator-search';

export type OfferCreatorModeType = 'catalog' | 'quotation' | 'tender';

export interface OfferCreatorSearchSessionFilter {

    key: OfferCreatorFilterKey;

    value: any;

    extraData?: { [p: string]: any };
}
