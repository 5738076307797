import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@core/shared/services/api.service';
import {FormService} from '@core/shared/services/form.service';
import {ReplaySubject, Subject} from 'rxjs';
import {SocietyService} from '@core/shared/services/society.service';
import {OfferProviderService} from '@core/shared/services/offer/offer-provider.service';
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {Channel} from "@core/shared/models/channel";
import {FileConfig} from "@lib/form/fields/file/file.component";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-offer-network-provider-create',
    templateUrl: './offer-network-provider-create.component.html',
    styleUrls: ['./offer-network-provider-create.component.scss']
})
export class OfferNetworkProviderCreateComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() parentNetworkProvider: UntypedFormGroup;

    @Input() formArray: UntypedFormArray;

    @Input() channels: Channel[];

    @Input() providers: Society[];

    @Input() limitNetworkProvider: number;

    @Input() groupProvider: OfferGroupNetworkProvider;

    @Output() public valueChange: EventEmitter<number[]> = new EventEmitter<number[]>();

    @Output() public keypressSearch: EventEmitter<any> = new EventEmitter();

    public search = new UntypedFormControl();

    public choices: string[];

    public countAddSearch: number = 0;

    public countAddManual: number = 0;

    public addManual: boolean = false;

    public addDisabled: boolean = false;

    public showInfo: boolean = false;

    public selectCtrl: UntypedFormControl = new UntypedFormControl();

    public optionFilterNameCtrl: UntypedFormControl = new UntypedFormControl();

    public optionFilterCityCtrl: UntypedFormControl = new UntypedFormControl();

    public filteredOptions: ReplaySubject<object[]> = new ReplaySubject<object[]>(1);

    protected valuesOptions: any[];

    protected _onDestroy: Subject<void> = new Subject<void>();

    public value: number[] = [];

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _apiService: ApiService,
        private _societyService: SocietyService,
        private _offerProviderService: OfferProviderService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        if (this.groupProvider !== null && this.groupProvider !== undefined) {
            for (let i = 0; i < this.groupProvider.networkProviders.length; i++) {
                this.initNetworkProviderFormControl(this.groupProvider.networkProviders[i].id);

                this.groupProvider.networkProviders[i].channels.forEach((channel , index) =>{
                    /** @ts-ignore **/
                    this.groupProvider.networkProviders[i].channels[index] = channel.id;
                });

                this.groupProvider.networkProviders[i].providers.forEach((provider: Society , index: number) => {
                    /** @ts-ignore **/
                    this.groupProvider.networkProviders[i].providers[index] = provider.id;
                });
            }
            this.networkProviderForm.patchValue(this.groupProvider.networkProviders);
        }

        this._initEvents();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private _initEvents(): void {

        this.networkProviderForm.controls.forEach((control: UntypedFormGroup): void => {

            control.get('displayOnChannel').valueChanges.subscribe((): void => {

                if(!control.get('displayOnChannel').value) {

                    control.get('channels').patchValue([]);
                }
            });

        });
    }

    public initNetworkProviderFormControl(id: any): void {
        if (id) {
            this.networkProviderForm.push(this._formBuilder.group({
                id: [''],
                name: ['', Validators.required],
                logo: [null],
                displayOnChannel: [null, Validators.required],
                parsedSocieties: ['' /**, [Validators.pattern(/^(<key>[^:]+):(<value>[^;]+)$/)]**/],
                restrictedSocieties: [[]],
                channels: [[]],
                providers: [[]]
            }));
        } else {
            this.networkProviderForm.push(this._formBuilder.group({
                name: ['', Validators.required],
                logo: [null],
                displayOnChannel: [null, Validators.required],
                parsedSocieties: ['' /**, [Validators.pattern(/^(<key>[^:]+):(<value>[^;]+)$/)]**/],
                restrictedSocieties: [[]],
                channels: [[]],
                providers: [[]]
            }));
        }

        this.addManual = true;
        this.addDisabled = true;
    }

    public deleteNetworkProvider(index: number): void {

        this.networkProviderForm.removeAt(index);
        this.addDisabled = false;
        if (this.countAddManual !== 0) {
            this.countAddManual--;
        }
    }


    public indexAsString(index: number): string {
        return index.toString();
    }

    public getProviderSocietyControl(index: number): AbstractControl {
        return this.networkProviderForm.controls[index];
    }

    public changeChannels(event, index) {
        this.getProviderSocietyControl(index).get('channels').patchValue(event.value);
    }

    public changeProviders(event, index) {
        this.getProviderSocietyControl(index).get('providers').patchValue(event.value);
    }

    public get configChannels() {
        return {
            id: 'channels',
            attrs: {
                label: this._translateService.instant('networkProvider.list.fields.channels.value'),
                required: false,
                choices: this.channels,
                multiple: true,
            }
        }
    }

    public get configProviders() {
        return {
            id: 'providers',
            attrs: {
                label: this._translateService.instant('networkProvider.list.fields.providers.value'),
                required: false,
                choices: this.providers,
                multiple: true,
            }
        }
    }

    get form(): UntypedFormGroup {
        return this.parentNetworkProvider;
    }

    get networkProviderForm(): UntypedFormArray {
        return this.form.get('networkProviders') as UntypedFormArray;
    }

    get localeId(): string {
        return this._translateService.currentLang;
    }

    public logoConfig: FileConfig = {
        id: 'logo',
        gallery: {
            id: null,
            type: 'file',
            context: 'logo_network_provider'
        },
        required: true,
        uploadApiUrl: this._apiService.getApiUrl(false, true)
    };
}
