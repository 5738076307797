<ng-content></ng-content>
<div class="content-page">
    <div class="nav-cms" *ngIf="enableMultilingual">

        <div class="translation-list">

            <!-- Liste des langues définies pour la page -->

            <div class="translation-item" *ngFor="let translation of getTranslations(true);let first = first;" [ngClass]="isDeletableTranslation(translation) ? 'isDeletable' : ''">
                <button type="button" class="mat-tab-label"
                        (click)="setCurrentTranslation(translation)"
                        [ngClass]="getTranslationButtonClass(translation)">
                    {{ getLocaleById(translation.locale).name }}
                </button>

<!--                 Suppression d'une langue -->

<!--                <button type="button" mat-button class="mat-warn delete deleteTranslation" *ngIf="isDeletableTranslation(translation)" (click)="deleteTranslation(translation)"-->
<!--                        matTooltip="{{ 'translation.delete.action' | translate }}" matTooltipPosition="below">-->
<!--                    <mat-icon>delete</mat-icon>-->
<!--                </button>-->
            </div>


            <!-- Ajout d'une langue -->

<!--            <button type="button" mat-stroked-button-->
<!--                    [disabled]="!hasAvailableTranslation()"-->
<!--                    (click)="addTranslation()">-->
<!--                <mat-icon>add</mat-icon> {{ 'language.action.add' | translate }}-->
<!--            </button>-->
        </div>

    </div>

    <!-- Contenu de la langue affichée -->
    <app-translation
            *ngFor="let translation of getTranslations(false)"
            [translation]="translation"
            [hidden]="!isCurrentLocale(translation)">
    </app-translation>
</div>
