import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../api/api.service";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {ImageService} from "./image.service";
import {ImageConfig} from "./image";
import {CmsService} from "../../../cms.service";
import {OptionsImage} from "../../../cms";
import {Image} from "@lib/media/image/image";
import {Gallery} from "@lib/media/gallery";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'cms-element-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

    @Input() content: Image;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<Image> = new EventEmitter<Image>();

    configInitialized: boolean = false;

    gallery: Gallery;

    options: OptionsImage;

    /**
     *
     * @param _apiService
     * @param _cmsService
     * @param _imageService
     * @param _translateService
     * @param _snackbar
     */
    constructor(
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _imageService: ImageService,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar
    ) {
    }

    ngOnInit() {

        this._imageService.setApiUrl(this.apiUrl);

        let optionsImage: OptionsImage = {
            enableTitle: false,
            enableSubtitle: false,
            enableAlt: false,
            enableLink: false,
            enableTargetBlank: false
        };

        const configOption: OptionsImage = this._cmsService.getOptionsImage();

        optionsImage = Object.assign(configOption, {
            enableTitle: configOption.enableTitle,
            enableSubtitle: configOption.enableSubtitle,
            enableAlt: configOption.enableAlt,
            enableLink: configOption.enableLink,
            enableTargetBlank: configOption.enableTargetBlank
        });

        this.options = optionsImage;



            const context: string = this._cmsService.getContext();

            this._imageService.loadConfig(context).subscribe((config: ImageConfig) => {

                    this._imageService.setConfig(config);

                    this.gallery = config.gallery;

                    this.configInitialized = true;

                },
                (error: HttpErrorResponse) => {

                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                        this._snackbar.open(error.error, messages['action.close']);
                    });
                }
            );

    }

    /**
     * @param image
     */
    add(image: Image): void {

        this.content = image;

        this.update.emit(this.content);
    }

    delete(): void {

        this.content = null;

        this.update.emit(this.content);
    }
}
