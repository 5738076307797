import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {Data} from "@lib/cms/cms";
import {ApiService} from "@core/shared/services/api.service";
import {Category} from "@core/shared/models/category";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryTranslation} from "@core/shared/models/category-translation";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {CategoryService} from "@core/shared/services/category.service";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {PageTranslation} from "@core/shared/models/page-translation";
import {Society} from "@core/shared/models/society";
import {Role} from "@core/shared/models/role";
import {FieldCollection} from "@lib/form/field";
import {Page} from "@core/shared/models/page";
import {Observable, of} from "rxjs";
import {TranslationService} from "@core/shared/services/translation.service";


@Component({
    selector: 'app-category-page-update',
    templateUrl: './page-category-update.component.html',
    styleUrls: ['./page-category-update.component.scss'],
    providers: [
        FormService
    ],
})
export class PageCategoryUpdateComponent implements OnInit {

    public data: Data;

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public pages: Object[] = [];

    public roles : string[] = [];

    public fieldCollection = new FieldCollection();

    public locales$: Observable<LocaleItem[]>;

    /**
     *
     * @param _translateService
     * @param translationService
     * @param _snackBar
     * @param categoryService
     * @param _router
     * @param _formBuilder
     * @param formService
     * @param _apiService
     * @param _activatedRoute
     */
    public constructor(private _translateService: TranslateService,
                       private _translationService: TranslationService,
                       private _snackBar: MatSnackBar, public categoryService: CategoryService, private _router: Router, private _formBuilder: UntypedFormBuilder, public formService: FormService, private _apiService: ApiService, private _activatedRoute: ActivatedRoute) {

        this._buildForm();
    }

    private _initLocales(): void {
        this.locales$ = of(LOCALE_ITEMS);
    }

    public ngOnInit(): void {
        this._initLocales();
        this._initTranslationsForm();

        this.form.get('locales').valueChanges.subscribe((): void => {
            this._handleTranslationControls();

        });

        this._activatedRoute.data.subscribe((data: { category: Category , societies: Society[], pages: Page[]}): void => {
            this.roles = data.category.roles;
            const pageDatas: any = {
                published:data.category.published,
                type_user: data.category.type_user,
                roles: data.category.roles,
                societies: [],
                locales: data.category.locales,
                translations: [],
            };

            data.category.societies.forEach((society: Society) => {
                pageDatas.societies.push(society.id);
            });



            data.category.translations.forEach((translations: CategoryTranslation) => {
                var pages = [];
                translations.pages.forEach((page: Page) => {
                    pages.push(page.id);
                });

                pageDatas.translations.push({
                    titre: translations.titre,
                    description: translations.description,
                    pages: pages
                });


            });

            data.societies.forEach((society: Society) => {
                this.societies.push(
                    {
                        id: society.id,
                        name: society.name
                    });
            });

            data.pages.forEach((page: Page) => {
                this.pages.push(
                    {
                        id: page.id,
                        name: this._translationService.getFallbackTranslation(page.translations).titre
                    });
            });

            this.form.patchValue(pageDatas);
        });

        this.formService.submitCallback = (): void => {

            const data: object = this.form.value;

            this.categoryService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('category.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };


    }

    public patchRolesValue(role){
        if(this.roles.indexOf(role) > -1){
            const index: number = this.roles.indexOf(role);
            this.roles.splice(index, 1);
        }else{
            this.roles.push(role);
        }
        this.form.get('roles').setValue(this.roles);
    }


    public changeSocieties(event){
        this.form.get('societies').patchValue(event.value);
    }

    private _buildForm(): void {
        this.formService.form = this._formBuilder.group({
            published: [''],
            type_user: ['role', Validators.required],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [[]],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true,
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'pages',
                attrs: {
                    label: this._translateService.instant('menu.pages.title.value'),
                    required: false,
                    choices: this.pages,
                    multiple: true,
                }
            }
        })



    }

    public hasRole(role: Role): boolean {
        return this.roles.indexOf(role) >= 0;
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                titre: ['', [Validators.required]],
                description: ['', [Validators.required]],
                pages: [[]],
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    public redirectToList(): void {

        this._router.navigate(['category/list']);
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            hasFontColor: true,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {

        return {
            id: 'published',
            attrs: {
                required: true,
                label: "Publié"
            }
        };
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }


    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get form(): UntypedFormGroup {
        return this.formService.form;
    }

    public changePages(event, locale){
        this.translationBuilder.getItemControlByLocale(locale).get('pages').patchValue(event.value);
    }

    get selectedLocales(): string[] {
        if(!this.form || !this.form.get('locales')){
            return [];
        }

        return this.form.get('locales').value;
    }

    private _handleTranslationControls(): void {
        const locales: string[] = this.form.get('locales').value;
        this.translationBuilder.updateItems(locales);
    }
}
