<div class="translation">

    <!-- Sélection d'un Template pour l'ajout d'une ligne -->

    <div class="liste-templates" id="liste-templates">
        <div>
            <span>{{ 'row.action.add' | translate }}</span>
            <div class="templates">
                <app-template *ngFor="let template of templates" [templateId]="template.id" (addRowEvent)="addRow(template)" [ngStyle]="getTemplateStyle()"></app-template>
            </div>
        </div>
    </div>

    <!-- Affichage des lignes -->

    <div *ngIf="translation.rows.length == 0" class="content_cms_null">
        <span class="info_cms">
        Il n'y a pas de contenu pour cette langue. <br/>Utilisez les boutons ci-dessous pour ajouter une ligne.<mat-icon class="icon_see_below">arrow_downward</mat-icon>
        </span>
    </div>

    <div class="rows" *ngIf="translation.rows.length > 0">
        <app-row *ngFor="let row of translation.rows | orderBy : ['position']"
                 [row]="row"
                 [isIncreaseAllowed]="isIncreaseRowAllowed(row)"
                 [isDecreaseAllowed]="isDecreaseRowAllowed(row)"
                 (decreasePosition)="decreasePositionRow(row)"
                 (increasePosition)="increasePositionRow(row)"
                 (delete)="deleteRow(row)"
        ></app-row>
    </div>
</div>
