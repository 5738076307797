<div class="auth-wrapper">

    <div class="header_auth">
        <div class="box_xl">
            <a [routerLink]="['/signin']" [queryParams]="queryParams" class="back ty-arrow"></a>
            <div class="logo">
                <img *ngIf="(loginLogoUrl$ | async) as sourceUrl" [src]="sourceUrl" alt="Ty-win" title="Ty-win" class="main_logo" />
                <img *ngIf="poweredByTywin" src="assets/images/logo_powered_tywin.png" alt="Powered By Ty-Win" title="Powered By Ty-Win" class="powered">
            </div>
        </div>
        <app-core-locale-switch></app-core-locale-switch>
    </div>

    <div class="auth">
        <div class="row">
            <div class="col_12 bloc_auth">
                <div class="auth_title">
                    <span>{{ 'user.forgetPassword.value' | translate }}</span>
                </div>

                <div class="content_auth">

                    <form [formGroup]="form" (ngSubmit)="formService.submit()">

                        <!-- Identifiant -->

                        <div class="form-group">
                            <input type="text" value="" class="form-control" id="email" formControlName="email" placeholder=" "/>
                            <label for="email">{{ 'user.email.value'|translate }}</label>
                            <mat-error *ngIf="form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Soumission -->

                        <div class="form-group submit">
                            <button class="btn_cta" type="submit">
                                {{ 'user.forgetPassword.submit.action.value'|translate }}
                            </button>
                        </div>

                        <div class="link_auth">
                            <a routerLink="/signin">{{ 'authentication.back.value' | translate }}</a>
                        </div>

                    </form>
                </div>

            </div>

        </div>
    </div>

</div>
