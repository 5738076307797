import { Injectable } from '@angular/core';
import {ITranslation} from "../translation/translation";
import {IRow} from "./row";
import {ITemplate} from "../template/template";
import {IColumn} from "../column/column";

@Injectable()
export class RowService{

    /**
     * Récupération d'une ligne en fonction de sa position pour une langue
     *
     * @param {ITranslation} translation
     * @param {number} rowPosition
     * @returns {IRow}
     */
    getRowByPosition(translation: ITranslation, rowPosition: number): IRow{

        let match = translation.rows.filter((row: IRow) => {
            return row.position === rowPosition;
        }).shift();

        return match || null;
    }

    /**
     * Récupération de la dernière ligne pour une langue
     *
     * @param {ITranslation} translation
     * @returns {IRow}
     */
    getLastRow(translation: ITranslation): IRow{

        if(!translation.rows.length)
            return null;

        let position: number = -1;

        translation.rows.map((row: IRow) => {
            if(row.position > position){
                position++;
            }
        });

        return this.getRowByPosition(translation, position);
    }

    /**
     * Détermine si la ligne est la première pour une langue
     *
     * @param {ITranslation} translation
     * @param {IRow} row
     * @returns {boolean}
     */
    isFirstRow(translation: ITranslation, row: IRow): boolean{
        return row.position === 0;
    }

    /**
     * Détermine si la ligne est la dernière pour une langue
     *
     * @param {ITranslation} translation
     * @param {IRow} row
     * @returns {boolean}
     */
    isLastRow(translation: ITranslation, row: IRow): boolean{
        return this.getLastRow(translation).position === row.position;
    }

    /**
     * Ajout d'une ligne pour une lanque (en dernière position)
     * Les éléments sont également crées en fonction du template passé en paramètre (avec le type ainsi que le contenu vide)
     *
     * @param {ITranslation} translation
     * @param {ITemplate} template
     */
    addRow(translation: ITranslation, template: ITemplate): IRow{

        let position: number = this.getLastRow(translation) ? (this.getLastRow(translation).position + 1) : 0;

        let row: IRow = {
            id: null,
            template: template,
            position: position,
            columns: []
        };

        for (let i: number = 0; i < template.nbElements; i++){

            let column: IColumn = {
                id: null,
                backgroundColor: null,
                position: i,
                colWidth: template.colWidths[i],
                elements: [
                    {
                        id: null,
                        position: 0,
                        type: null,
                        backgroundColor: null,
                        title: null,
                        subtitle: null,
                        content: null,
                    }
                ]
            };

            row.columns.push(column);
        }

        translation.rows.push(row);

        return row;
    }

    /**
     * Suppression d'une ligne pour une langue
     *
     * @param {ITranslation} translation
     * @param {IRow} deletedRow
     */
    deleteRow(translation: ITranslation, deletedRow: IRow): void{

        let deletedPosition: number = deletedRow.position;

        translation.rows = translation.rows.filter((row: IRow) => {
            return row.position !== deletedRow.position;
        });

        translation.rows.map((row: IRow) => {
            if(row.position > deletedPosition){
                row.position--;
            }
        });
    }

    /**
     * Décrémentation de la position d'une ligne pour une langue
     * Sur l'interface la ligne prend donc la place au dessus
     *
     * @param {ITranslation} translation
     * @param {IRow} row
     */
    decreasePositionRow(translation: ITranslation, row: IRow): void{

        if(this.isFirstRow(translation, row))
            return;

        let rowToUp = this.getRowByPosition(translation, row.position - 1);

        rowToUp.position++;
        row.position--;
    }

    /**
     * Incrémentation de la position d'une ligne pour une langue
     * Sur l'interface la ligne prend donc la place en dessous
     *
     * @param {ITranslation} translation
     * @param {IRow} row
     */
    increasePositionRow(translation: ITranslation, row: IRow): void{

        if(this.isLastRow(translation, row))
            return;

        let rowToDown = this.getRowByPosition(translation, row.position + 1);

        rowToDown.position--;
        row.position++;
    }

    /**
     * Détermine si une ligne peut prendre la place au dessus
     *
     * @param {ITranslation} translation
     * @param {IRow} row
     * @returns {boolean}
     */
    isDecreaseRowAllowed(translation: ITranslation, row: IRow): boolean{
        return !this.isFirstRow(translation, row);
    }

    /**
     * Détermine si la ligne peut prendre la place en dessous
     *
     * @param {ITranslation} translation
     * @param {IRow} row
     * @returns {boolean}
     */
    isIncreaseRowAllowed(translation: ITranslation, row: IRow): boolean{
        return !this.isLastRow(translation, row);
    }
}
