import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FormService} from "@core/shared/services/form.service";
import {Subscription} from "@core/shared/models/subscription";

export interface SubscriptionDialogData {

    title: string;

    subscriptions: Subscription[];
}

@Component({
    selector: 'subscription-dialog',
    templateUrl: './subscription-dialog.component.html',
    styleUrls: ['./subscription-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class SubscriptionDialogComponent implements OnInit {

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    public subscriptions: Subscription[];

    constructor(
        public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SubscriptionDialogData,
        public formService: FormService,
    ) {
    }

    public ngOnInit(): void {

        const events: EventEmitter<void>[] = [
            this.confirm,
            this.cancel,
        ];

        this.subscriptions = this.data.subscriptions;

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this.dialogRef.close();
            });
        });
    }
}
