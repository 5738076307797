<div class="channel_pim_preview" [ngClass]="[styleForm.get('font').value]">
    <div class="page_offer_preview">

        <!-- Détails de l'offre -->

        <div class="title_offer">
            <div class="row marges">

                <!-- Offre "Nouveauté" -->

                <div class="col_content y_items_center">
                    <span class="tag">{{ 'offer.preview.content.new.value' | translate }}</span>
                </div>

                <!-- Nom de l'offre -->

                <div class="col y_items_center">
                    <h1 class="title" [ngStyle]="{ 'color': styleForm.get('colorTitle').value }">{{ 'offer.preview.content.title.value' | translate }}</h1>
                </div>
            </div>

            <div class="row marges infos" >
                <div class="col_content y_items_center">

                    <!-- Référence -->

                    <span>{{ 'offer.preview.content.reference.value' | translate }}</span>

                </div>
                <div class="col_content y_items_center">

                    <!-- Type d'offre -->

                    <span class="offer_type ty-house">{{ 'offer.preview.content.type.value' | translate }}</span>

                    <!-- Ville / Pays -->

                    <span>{{ 'offer.preview.content.place.value' | translate }}</span>
                </div>
                <div class="col_content y_items_center">

                    <!-- Durée de l'offre -->

                    <span class="offer_type ty-calendar">{{ 'offer.preview.content.duration.value' | translate }}</span><br/>
                </div>
                <div class="col_content y_items_center">

                    <!-- Présentiel -->

                    <span>{{ 'offer.preview.content.presential.value' | translate }}</span>
                </div>
            </div>
        </div>

        <!-- Visuels -->

        <div class="row offer_pictures">

            <!-- Visuel principal -->

            <div class="col_12 col_md col_left">

                <img src="assets/images/offer/preview/1.jpg" alt="image">

            </div>

            <!-- Visuels secondaires -->

            <div class="col_12 col_md_6 col_right">

                <div class="row h_full">

                    <div class="col_6">
                        <img src="assets/images/offer/preview/1.jpg" alt="image">
                    </div>

                    <div class="col_6">
                        <img src="assets/images/offer/preview/1.jpg" alt="image">
                    </div>

                    <div class="col_6">
                        <img src="assets/images/offer/preview/1.jpg" alt="image">
                    </div>

                    <div class="col_6">
                        <img src="assets/images/offer/preview/1.jpg" alt="image">
                    </div>

                </div>

            </div>

        </div>

        <!-- Durée de l'offre et intérêts -->

        <div class="txt_offer">

            <p class="title" [ngStyle]="{ 'color': styleForm.get('colorSubTitle').value }">{{ 'offer.preview.content.weLike.value' | translate }}</p>

            <p class="interest">

                <!-- Intérêts -->

                <span>{{ 'offer.preview.content.interests.first.value' | translate }}</span><br/>
                <span>{{ 'offer.preview.content.interests.second.value' | translate }}</span><br/>
                <span>{{ 'offer.preview.content.interests.third.value' | translate }}</span><br/>

            </p>
        </div>

        <!-- Description -->

        <div class="txt_offer">

            <h2 class="title" [ngStyle]="{ 'color': styleForm.get('colorSubTitle').value }">{{ 'offer.preview.content.description.label.value' | translate }}</h2>

            <div class="description_offer ck-content">

                <p style="text-align:justify;">
                    {{ 'offer.preview.content.description.value' | translate }}
                </p>

            </div>

        </div>

        <!-- Compris / Non compris dans l'offre -->

        <div class="accordions">

            <!-- Compris dans l'offre -->

            <div class="accordion" #accordion [ngStyle]="{ 'border-color': styleForm.get('colorBackgroundButton').value }">
                <div class="accordion_header">
                        <span [ngStyle]="{ 'color': styleForm.get('colorBackgroundButton').value }">
                            {{ 'offer.preview.content.included.label.value' | translate }}
                        </span>
                </div>
                <div class="accordion_body">
                    <div>
                        <div class="txt_offer">

                            <p class="title_2">{{ 'offer.preview.content.included.title.value' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Non Compris dans l'offre -->

            <div class="accordion" #accordion [ngStyle]="{ 'border-color': styleForm.get('colorBackgroundButton').value }">
                <div class="accordion_header">
                    <span [ngStyle]="{ 'color': styleForm.get('colorBackgroundButton').value }">
                        {{ 'offer.preview.content.notIncluded.label.value' | translate }}
                    </span>
                </div>
                <div class="accordion_body">
                    <div>
                        <div class="txt_offer">
                            <p class="title_2">{{ 'offer.preview.content.notIncluded.title.value' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Programme -->

        <div class="infos_program">
            <div class="txt_offer">
                <h2 class="title" [ngStyle]="{ 'color': styleForm.get('colorSubTitle').value }">{{ 'offer.preview.content.program.label.value' | translate }}</h2>
            </div>

            <!-- Sélection du jour -->

            <div class="nav_slider_program">

                <button type="button" class="btn_cta btn_small" [ngStyle]="{ 'background-color': styleForm.get('colorBackgroundButton').value }">
                    <span [ngStyle]="{ 'color': styleForm.get('colorTextButton').value }">1</span>
                </button>

                <button type="button" class="btn_cta btn_small" [ngStyle]="{ 'background-color': styleForm.get('colorBackgroundButton').value }">
                    <span [ngStyle]="{ 'color': styleForm.get('colorTextButton').value }">2</span>
                </button>

            </div>

            <!-- Slider -->

            <div class="swiper-container slider_program">
                <div class="row no_wrap swiper-wrapper">
                    <div class="col_12 col_md_6 swiper-slide">
                        <span class="day" [ngStyle]="{ 'background-color': styleForm.get('colorHighlighting').value }">{{ 'offer.preview.content.program.day.value' | translate }} 1</span>
                        <div class="row h_full content_program">
                            <div class="col_7">
                                <div class="info_program">
                                    <div class="txt_offer">
                                        <p><strong>{{ 'offer.preview.content.program.day.value' | translate }} 1</strong></p>
                                        <div class="ck-content">
                                            <ul>
                                                <li>{{ 'offer.preview.content.program.day.first.elements.first.value' | translate }}</li>
                                                <li>{{ 'offer.preview.content.program.day.first.elements.second.value' | translate }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col_5">
                                <img src="assets/images/offer/preview/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                    <div class="col_12 col_md_6 swiper-slide">
                        <span class="day" [ngStyle]="{ 'background-color': styleForm.get('colorHighlighting').value }">{{ 'offer.preview.content.program.day.value' | translate }} 2</span>
                        <div class="row h_full content_program">
                            <div class="col_7">
                                <div class="info_program">
                                    <div class="txt_offer">
                                        <p><strong>{{ 'offer.preview.content.program.day.value' | translate }} 2</strong></p>
                                        <div class="ck-content">
                                            <ul>
                                                <li>{{ 'offer.preview.content.program.day.second.elements.first.value' | translate }}</li>
                                                <li>{{ 'offer.preview.content.program.day.second.elements.second.value' | translate }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col_5">
                                <img src="assets/images/offer/preview/1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>

        </div>

        <!-- Localisation(s) -->

        <div>
            <div class="txt_offer">
                <h2 class="title" [ngStyle]="{ 'color': styleForm.get('colorSubTitle').value }">{{ 'offer.preview.content.location.label.value' | translate }}</h2>
            </div>
            <ng-container *ngIf="mapInitialized">
                <div class="map_container" leaflet [leafletOptions]="mapOptions" [leafletLayer]="marker"></div>
            </ng-container>
        </div>

    </div>
</div>

<div #styles></div>
