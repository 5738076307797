import {AfterContentInit, Directive, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
    selector: '[appInputTextParsePrice]'
})
export class InputTextParsePriceDirective implements AfterContentInit {

    @Input()
    control: AbstractControl;

    @Input()
    parsePriceEnabled: boolean = true;

    constructor() {
    }

    @HostListener('blur') onBlur() {

        this._apply();
    }

    ngAfterContentInit(): void {

        this._apply();
    }

    private _apply(): void {

        if(!this.parsePriceEnabled || !this.control){

            return;
        }

        setTimeout((): void => {

            this.control.patchValue((this.control.value !== null) ? this._parsePrice(parseFloat(this.control.value), 2, '', '.') : null);
        });
    }

    /**
     * @param value Montant du prix
     * @param n     Nombre de décimale après la virgule
     * @param s     Délimiteur entre les centaines
     * @param c     Séparateur entre l'entier et les décimales
     */
    private _parsePrice(value: number, n: number = 2, s: string = ' ', c: string = '.'): string {

        if(isNaN(value)){

            return '';
        }

        const re = '\\d(?=(\\d{' + (null || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';

        const num = value.toFixed(Math.max(0, ~~n));

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s));
    }
}
