import {AfterViewInit, Component, EventEmitter, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {CurrencyService} from "@core/shared/services/currency.service";
import {CountryService} from "@core/shared/services/country.service";
import {ApiService} from "@core/shared/services/api.service";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {TransporterService} from "@core/shared/services/transporter.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {UserService} from "@core/shared/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "@core/shared/models/user";
import {REGEX_ONLY_NUMBER, REGEX_PRICE} from "@core/shared/models/regex";
import {Country} from "@core/shared/models/country";
import {TranslationBuilder} from "@core/shared/models/translation";
import {Transporter} from "@core/shared/models/transporter";
import {fromEvent, ReplaySubject} from "rxjs";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {Currency} from "@core/shared/models/currency";
import {MatLegacySelectChange as MatSelectChange} from "@angular/material/legacy-select";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {
    FormTabValidationItem,
    OfferFormTabValidationService
} from "@core/shared/services/form/form-tab-validation.service";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {MatSelectSearchComponent} from "ngx-mat-select-search";
import {debounceTime, map} from "rxjs/operators";
import {parsePrice} from "@core/shared/utils/price";

@Component({
    selector: 'app-page-transporter-update',
    templateUrl: './page-transporter-update.component.html',
    styleUrls: ['./page-transporter-update.component.scss'],
    providers: [FormService, OfferFormTabValidationService]
})
export class PageTransporterUpdateComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('generalData', {static: true}) generalDataRef: TemplateRef<any>;

    @ViewChild('shippingData', {static: true}) shippingDataRef: TemplateRef<any>;

    @ViewChild('pictureData', {static: true}) pictureDataRef: TemplateRef<any>;

    @ViewChild('commentData', {static: true}) commentDataRef: TemplateRef<any>;

    @ViewChild('nameFilter', {static: false}) nameFilterReference: MatSelectSearchComponent;

    public user: User;

    public transporter: Transporter;

    public translationBuilder: TranslationBuilder;

    public optionFilterNameCtrl: UntypedFormControl = new UntypedFormControl();

    public valueChange: EventEmitter<Country[]> = new EventEmitter<Country[]>();

    public filteredOptions: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public allCountries: boolean = false;

    public currencies: Currency[];

    public countries: Country[];

    public value: Country[];

    private valuesOptions: Country[];

    constructor(public formService: FormService,
                public translationService: TranslationService,
                public offerFormTabValidationService: OfferFormTabValidationService,
                private _currencyService: CurrencyService,
                private _countryService: CountryService,
                private _apiService: ApiService,
                private _formBuilder: UntypedFormBuilder,
                private _activatedRoute: ActivatedRoute,
                private _translateService: TranslateService,
                private _transporterService: TransporterService,
                private _snackBar: MatSnackBar,
                private userService: UserService,
                private _router: Router,) {

    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { transporter: Transporter }) => {

            this.transporter = data.transporter;

            this.user = this.userService.currentUser.value;


            this._loadCountries();

            this._initForm();

            this._initCurrencies();

            this._initTabItems();

            this._initEvents();

            this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);

            this._hydrateForm();

        })
    }

    ngAfterViewInit() {

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this._handleClearSelection();

                this._filterOptions();

            });

    }

    private _handleClearSelection(): void {

        const element: HTMLButtonElement = this.nameFilterReference.innerSelectSearch.nativeElement.querySelector('.mat-select-search-clear');

        if (element) {

            element.onclick = (): void => {

                this.optionFilterNameCtrl.patchValue('');

                this._filterOptions();

            };
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            name: ['', Validators.required],
            countries: [[], [(control: UntypedFormControl) => {

                if (!control.value) {
                    return null;
                }

                if (this.allCountries) {

                    return null;
                }

                if (control.value.length >= 1) {

                    return null;
                }

                return {
                    'minLengthArray': {
                        valid: false
                    }
                };

            }]],
            currency: [null],
            prices: this._formBuilder.group({
                vatPercent: [null, [Validators.required, Validators.pattern(REGEX_PRICE)]],
                priceHT: [null, [Validators.required, Validators.pattern(REGEX_ONLY_NUMBER)]],
                priceTTC: [null, [Validators.required, Validators.pattern(REGEX_ONLY_NUMBER)]],
            }),
            deliveryTimeStart: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            deliveryTimeEnd: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            image: this._formBuilder.group({
                image: [null, [Validators.required]]
            }),
            translations: new UntypedFormArray([]),
        });

        this._initTranslationsForm();

        this.formService.submitCallback = () => {

            if (this.allCountries) {

                this.form.get('countries').patchValue(this.countries.map((country: Country) => country.code));
            }

            const data = Object.assign({...this.form.value}, {
                currency: {
                    id: parseInt(this.form.get('currency').value)
                },
                vatPercent: (parseFloat(this.priceControl.get('vatPercent').value) / 100).toFixed(4),
                priceTTC: parseInt((this.priceControl.get('priceTTC').value * 100).toFixed()),
                priceHT: parseInt((this.priceControl.get('priceHT').value * 100).toFixed()),
            });

            this._transporterService.updateItemAPI(this.transporter.id, data).subscribe(() => {

                this._snackBar.open(this._translateService.instant('transporter.edit.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });

        };

    }

    private _initEvents(): void {

        const calculateTTCControls: AbstractControl[] = [
            this.form.get('prices').get('priceHT'),
            this.form.get('prices').get('vatPercent')
        ];

        calculateTTCControls.forEach((control: AbstractControl): void => {

            control.valueChanges.subscribe((): void => {

                this._calculateTTC();
            });

        });
    }

    public handleClosure(): void {

        this.optionFilterNameCtrl.patchValue('');

        this._filterOptions();
    }

    private _initCurrencies(): void {

        this._currencyService.getItemsAPI().subscribe((currencies: Currency[]): void => {

            this.currencies = currencies;
        });
    }

    private _hydrateForm(): void {

        const data: Transporter = {...this.transporter}

        this.form.patchValue(Object.assign(data, {
            prices: {
                vatPercent: data.vatPercent * 100,
                priceTTC: data.priceTTC / 100,
                priceHT: data.priceHT / 100
            },
            currency: data.currency.id
        }));
    }

    private _initValues() {

        this.valuesOptions = this.countries;

        this.filteredOptions.next(this.valuesOptions.slice());

    }

    private _initTranslationsForm(): void {

        const locales: string[] = ['fr', 'en', 'es', 'it', 'de', 'pt', 'nl'];

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                comment: ['']
            });
        };

        this.translationBuilder.updateItems(locales);
    }

    private _loadCountries(): void {


        this._countryService.getItemsAPI().subscribe((countries: Country[]) => {

            this.countries = countries;

            this.value = countries;

            this.valueChange.emit(this.value);

            this._initValues();

            this._hydrateCountries();
        });
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: 'generalData',
                label: 'transporter.generalData.value',
                template: this.generalDataRef
            },
            {
                tag: 'shippingData',
                label: 'transporter.shippingData.value',
                template: this.shippingDataRef
            },
            {
                tag: 'pictureData',
                label: 'transporter.pictureData.value',
                template: this.pictureDataRef
            },
            {
                tag: 'commentData',
                label: 'transporter.commentData.value',
                template: this.commentDataRef
            },

        ];
    }

    private _filterOptions(): void {

        const params: string[] = [];

        const filterBuilder: FilterBuilder = new FilterBuilder();

        if (this.optionFilterNameCtrl.value && this.optionFilterNameCtrl.value.length) {

            filterBuilder.addField(new TextFilterField('translations.name', 'lk', this.optionFilterNameCtrl.value.toLowerCase()));
        }

        if (filterBuilder.hasFilters) {

            params.push(filterBuilder.serializedRequest);
        }

        this._countryService.getItemsAPI(params).subscribe((countries: Country[]) => {

            this.countries = countries;

            this._initValues();

        })
    }

    private _hydrateCountries(): void {

        if (this.countries.length === this.transporter.countries.length) {

            this.allCountries = true;
        }

        this.form.get('countries').patchValue(this.transporter.countries);
    }

    private _calculateTTC(): void {

        const priceHT = this.form.get('prices').get('priceHT').value;

        const vatPercent = this.form.get('prices').get('vatPercent').value;

        if (!priceHT || !vatPercent)
            return;

        const priceTTC = priceHT * (1 + (vatPercent / 100));

        this.form.get('prices').get('priceTTC').patchValue(parsePrice(priceTTC));
    }

    public redirectToList(): void {

        this._router.navigate(['account/transporter/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public selectionChange(event: MatSelectChange): void {

        this.valueChange.emit(event.value);
    }

    public compareCountryCode(a: string, b: string): boolean {

        return a === b;
    }

    public resetCountries(): void {

        if (!this.allCountries) {

            this._filterOptions();

            this._initValues();

        }

        this.form.get('countries').patchValue([]);

        this.form.get('countries').updateValueAndValidity();
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get priceControl(): UntypedFormGroup {

        return this.form.get('prices') as UntypedFormGroup;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items = [];

            items.push({
                tag: 'generalData',
                position: 1,
                controls: [
                    this.form.get('name')
                ]
            });

            items.push({
                tag: 'shippingData',
                position: 2,
                controls: [
                    this.priceControl,
                    this.form.get('deliveryTimeStart'),
                    this.form.get('countries'),
                    this.form.get('currency'),
                    this.form.get('deliveryTimeStart'),
                    this.form.get('deliveryTimeEnd'),
                ]
            });

            items.push({
                tag: 'pictureData',
                position: 3,
                controls: [
                    this.form.get('image')
                ]
            });

            return items;
        };
    }

    get transporterFileConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'transporter_picture',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                label: this._translateService.instant('offer.form.fields.picture.value')
            },
        }
    }


}
