export type Role = 'ROLE_SUPER_ADMIN' | 'ROLE_ADMIN' | 'ROLE_OFFER_CREATOR' | 'ROLE_OFFER_DISTRIBUTOR' | 'ROLE_PROVIDER' | 'ROLE_INSTITUTIONAL' | 'ROLE_FEDERATION' | 'ROLE_USER';

export interface RoleLabel {

    identifier: Role;

    label: string;
}

export const ROLE_LABELS: RoleLabel[] = [
    {
        identifier: 'ROLE_SUPER_ADMIN',
        label: 'role.superAdmin.value'
    },
    {
        identifier: 'ROLE_ADMIN',
        label: 'role.admin.value'
    },
    {
        identifier: 'ROLE_OFFER_CREATOR',
        label: 'role.offerCreator.value'
    },
    {
        identifier: 'ROLE_OFFER_DISTRIBUTOR',
        label: 'role.offerDistributor.value'
    },
    {
        identifier: 'ROLE_PROVIDER',
        label: 'role.provider.value'
    },
    {
        identifier: 'ROLE_INSTITUTIONAL',
        label: 'role.institutional.value'
    },
    {
        identifier: 'ROLE_FEDERATION',
        label: 'role.federation.value'
    },
    {
        identifier: 'ROLE_USER',
        label: 'role.user.value'
    }
];
