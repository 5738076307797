import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";

export interface FilterComponent {

    filter: () => void;

    reset: () => void;
}

export type FilterOperator = 'eq' | 'lk' | 'in' | 'lkin' | 'nin' | 'andin' | 'andlkin' | 'andnin' | 'ne' | 'gte' | 'gt' | 'lte' | 'null' | 'nnull';

export class FilterField {

    public key: string;

    public operator: FilterOperator;

    public value: any;

    public isCollection: boolean;

    constructor(key: string, operator: FilterOperator, isCollection?: boolean) {

        this.key = key;

        this.operator = operator;

        this.isCollection = isCollection || false;

        if(!this.isCollection){

            this._checkValidity();
        }
    }

    private _checkValidity(): void {

        const requiredArguments: string[] = ['key'];

        requiredArguments.forEach((argument: string): void => {

            if(!this[argument]){

                throw new Error(`Le paramètre "${ argument }" n'est pas défini.`);
            }
        });
    }

    get serialize(): string {

        throw new Error('La méthode "serialize" doit être surchargée.');
    }
}

export class FilterBuilder {

    private _fields: FilterField[] = [];

    private _filterCallback: () => void;

    constructor(){}

    public filter(): void {

        this._filterCallback();
    }

    public addField(value: FilterField) {

        this._fields.push(value);
    }

    public removeFieldByKey(key: string) : void {

        const index: number = this.fields.findIndex((field: FilterField): boolean => {

            return field.key === key;
        });

        if(index < 0) {

            return;
        }

        this._fields.splice(index, 1);
    }

    public resetFieldValueByKey(key: string) : void {

        const index: number = this.fields.findIndex((field: FilterField): boolean => {

            return field.key === key;
        });

        if(index < 0) {

            return;
        }

        this._fields[index].value = null;
    }

    public resetFields(): void {

        this._fields.forEach((field: FilterField): void => {

            field.value = null;

            if(field.isCollection){

                (field as FilterCollectionField).clear();
            }
        });
    }

    public getFieldByKey(key: string): FilterField {

        return this.fields.find((field: FilterField): boolean => {

            return field.key === key;
        });
    }

    public getFieldsByKey(key: string): FilterField[] {

        return this.fields.filter((field: FilterField): boolean => {

            return field.key === key;
        });
    }

    public hasFilter(key: string): boolean {

        return !!this.getFieldByKey(key);
    }

    get hasFilters(): boolean {

        const filters: string[] = this.serializedFilters.filter((serializedRequest: string): boolean => {

            return serializedRequest.length > 0;

        });

        return filters.length > 0;
    }

    get serializedFilters(): string[] {

        return this._fields.map((field: FilterField): string => {

            return field.serialize;
        });
    }

    get serializedRequest(): string {

        return this.serializedFilters.filter((serializedRequest: string): boolean => {

            return serializedRequest.length > 0;

        }).join('&');
    }

    get fields(): FilterField[] {

        return this._fields;
    }

    set filterCallback(value: () => void) {

        this._filterCallback = value;
    }
}
