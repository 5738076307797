import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-core-offer-translation-failed-dialog',
    templateUrl: './offer-translation-failed-dialog.component.html',
    styleUrls: ['./offer-translation-failed-dialog.component.scss']
})
export class OfferTranslationFailedDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<OfferTranslationFailedDialogComponent>
    ) {}

    ngOnInit(): void {
    }
}
