import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Offer} from "@core/shared/models/offer";
import {TenderRefuseOfferComponent} from "@core/components/tender/tender-refuse-offer/tender-refuse-offer.component";
import {Tender} from "@core/shared/models/tender";
import {TenderOfferCreator} from "@core/shared/models/tender/tender-offer-creator";

@Component({
    selector: 'app-core-tender-refuse-offer-dialog',
    templateUrl: './tender-refuse-offer-dialog.component.html',
    styleUrls: ['./tender-refuse-offer-dialog.component.scss']
})
export class TenderRefuseOfferDialogComponent implements OnInit {

    @Output() remove: EventEmitter<void> = new EventEmitter();

    @ViewChild(TenderRefuseOfferComponent) tenderRefuseOfferComponent: TenderRefuseOfferComponent;

    public submitDisabled: boolean = false;

    constructor(
        private _dialogRef: MatDialogRef<TenderRefuseOfferDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { tender: Tender, tenderOfferCreator: TenderOfferCreator, offer: Offer }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.remove
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.submitDisabled = true;

        this.tenderRefuseOfferComponent.formService.submit();
    }

    get offer(): Offer {

        return this.data.offer;
    }

    get tender(): Tender {

        return this.data.tender;
    }

    get tenderOfferCreator(): TenderOfferCreator {

        return this.data.tenderOfferCreator;
    }
}