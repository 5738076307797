import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SubscriptionService} from "../services/subscription.service";
import {Subscription} from "../models/subscription";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionItemsResolver  {

    constructor(
        private _subscriptionService: SubscriptionService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._subscriptionService.getItemsSubscription();
    }
}
