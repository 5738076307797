import {
    AfterViewInit,
    Component,
    DoCheck,
    EventEmitter,
    Input, KeyValueDiffer,
    KeyValueDiffers,
    OnInit,
    Output,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import {HttpClient} from "@angular/common/http";

/**
 * Configuration paramètres du champ
 */
export interface SelectConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        label?: string;
        choices?: any[];
        callbackApi?: string;
        callbackData?: CallbackDataConfig;
        groupped?: boolean;
    };
}

/**
 * Configuration callBackData
 */
export interface CallbackDataConfig {
    key: string;
    value: string;
    groupBy?: CallbackDataGroupByConfig
}

/**
 * Configuration callBackDataGroupBy
 */
export interface CallbackDataGroupByConfig {
    field: string;
    label: string;
}

/**
 * Champ select
 */
@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, DoCheck, AfterViewInit {
    /**
     * Paramètres du champ
     */
    @Input() config: SelectConfig = {id: null, attrs: {}};

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    @Input() value: string;

    /**
     * Événement si la valeur change
     */
    @Output() valueChange = new EventEmitter();

    @Output() onOptionsUpdated: EventEmitter<void> = new EventEmitter<void>();

    private differ: KeyValueDiffer<any, any>;


    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor(private _http: HttpClient, private differs: KeyValueDiffers) {
        this.differ = this.differs.find(this.config).create()
    }

    /**
     *
     * @param apiUrl
     * @param apiData
     */
    public getData(apiUrl: string, apiData: object): void {
        const data = [];

        this._http.get<any>(`${apiUrl}`).subscribe((d) => {
            d.forEach((elem, index) => {
                const newData = [];

                if(d.length == 1){
                    this.value = elem[apiData['key']].toString();
                }

                newData[elem[apiData['key']]] = elem[apiData['value']];

                data.push(newData);
            });
        });

        this.config.attrs.choices = data;

        this.onOptionsUpdated.emit();
    }

    /**
     *
     * @param apiUrl
     * @param apiData
     */
    public getGrouppedData(apiUrl: string, apiData: object) {
        const data = [];

        this._http.get<any>(`${apiUrl}`).subscribe((d) => {

            d.forEach((elem, index) => {
                const groupId = elem[apiData['groupBy']['field']]['id'];
                const groupName = elem[apiData['groupBy']['field']][apiData['groupBy']['label']];

                if (!data[groupId]) {
                    data[groupId] = {
                        name: groupName,
                        choices: []
                    };
                }

                data[groupId]['choices'][elem[apiData['key']]] = elem[apiData['value']];
            });

            const result = [];
            for (let k in data) {
                if (data.hasOwnProperty(k)) {
                    result.push(data[k]);
                }
            }

            this.config.attrs.choices = result;
        });
    }


    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {

        if (this.config.attrs && this.config.attrs.groupped) {
            this.getGrouppedData(this.config.attrs.callbackApi, this.config.attrs.callbackData);
        } else if (this.config.attrs && this.config.attrs.callbackApi) {
            this.getData(this.config.attrs.callbackApi, this.config.attrs.callbackData);
        }

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl(''));

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        this.form.controls[this.config.id].setValidators(validators);

    }

    public ngDoCheck(): void {
        const changes = this.differ.diff(this.config.attrs);

        if (changes) {
            changes.forEachChangedItem((r) => {
                if (r.key == 'callbackApi') {
                    this.value = '';
                    this.getData(this.config.attrs.callbackApi, this.config.attrs.callbackData);
                }
            });
        }


    }

    public ngAfterViewInit(): void {

        this.form.valueChanges.subscribe(() => {
            if (this.config.attrs.callbackApi) {
                if (this.form.value[this.config.id]) {
                    if (this.form.value[this.config.id].id !== undefined) {
                        this.form.value[this.config.id] = this.form.value[this.config.id].id;
                        this.value = '' + this.form.value[this.config.id];
                    }
                }
            }
        });
    }

    selectChange(event) {
        this.valueChange.emit(event);
    }

}
