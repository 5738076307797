import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Booking} from "../models/booking";
import {Pagination} from "@core/shared/models/pagination";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {BookingCompositionOption} from "@core/shared/models/booking/booking-composition-option";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {BookingCompositionPermanentOption} from "@core/shared/models/booking/booking-composition-permanent-option";
import {OfferPermanentOption} from "@core/shared/models/offer/offer-permanent-option";

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public createItemAPI(societyId: number, data: object): Observable<Booking> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Booking>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings`, data, {
            headers: headers
        });
    }

    public validateItemAPI(societyId: number, id: number): Observable<Booking> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Booking>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings/${id}/validate`, {}, {
            headers: headers
        });
    }

    public cancelItemAPI(societyId: number, id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings/${id}/cancel`, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<Booking> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Booking>(`${this._apiService.getApiUrl(false, true)}/bookings/${id}`, data,{
            headers: headers
        });
    }

    public getItemAPI(id: number, params?: string[]): Observable<Booking> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/bookings/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<Booking>(url,{
            headers: headers
        });
    }

    public getItemsAPI(params?: string[]): Observable<Booking[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/bookings`;

        params = params || [];
        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Booking[]>(url, {
            headers: headers
        });
    }

    public getSocietyItemsAPI(societyId: number, params?: string[]): Observable<Booking[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Booking[]>(url, {
            headers: headers
        });

    }

    public getPaginationSocietyItemsAPI(societyId: number, params?: string[]): Observable<Pagination<Booking>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Booking>>(url, {
            headers: headers
        });
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Booking>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/bookings`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Booking>>(url, {
            headers: headers
        });
    }

    public getSocietySummaryItemAPI(societyId: number, data: object): Observable<Booking> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Booking>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings/summary`, data, {
            headers: headers
        }).pipe(
            map((item: Booking): Booking => {

                return Object.assign(item, {
                    composition: Object.assign(item.composition, {
                        options: item.composition.options.map((option: BookingCompositionOption): BookingCompositionOption => {

                            return Object.assign(option, {
                                option: plainToClass(OfferOption, option.option)
                            });
                        }),
                        permanentOptions: item.composition.permanentOptions.map((permanentOption: BookingCompositionPermanentOption): BookingCompositionPermanentOption => {

                            return Object.assign(permanentOption, {
                                permanentOption: plainToClass(OfferPermanentOption, permanentOption.permanentOption)
                            });
                        })
                    })
                });
            })
        );
    }

    public exportItemsAPI(societyId: number): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/bookings/export`, {
            responseType: 'blob'
        });

    }

    public adminExportItemsAPI(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/bookings/export`, {
            responseType: 'blob'
        });

    }
}
