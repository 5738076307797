import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {UserService} from '../services/user.service';
import {User} from '../models/user';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UserItemResolver  {

    constructor(
        private _userService: UserService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._userService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
