<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'transporter.own.list.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccess('TRANSPORTER_CREATE_IS_MINE')" class="btn_cta btn_little btn_add">{{ 'transporter.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">

        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('enabled')" >
                    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'width': '140px'}">
                        <app-core-select-boolean-filter #filter [builder]="filterBuilder" [label]="" [key]="'enabled'" [truthyLabel]="'activation.enabled.value' | translate" [falsyLabel]="'activation.disabled.value' | translate"></app-core-select-boolean-filter>
                    </th>
                </ng-container>

                <!-- Nom du transporteur -->

                <ng-container [matColumnDef]="getFilterColumnDef('transporterName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'transporter.name.value' | translate" [key]="'name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Devise -->

                <ng-container [matColumnDef]="getFilterColumnDef('currency')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'transporter.currency.value' | translate" [key]="'currency.id'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="currencies"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Montant de la facture HT -->

                <ng-container [matColumnDef]="getFilterColumnDef('amountHT')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Montant de la facture TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('amountTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

            </ng-container>

            <!-- Ligne des données -->

            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button [disabled]="!isEnable(i)" (click)="update(element.id)" *ngIf="hasAccess('TRANSPORTER_EDIT_IS_MINE')">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="enabled">
                    <th mat-header-cell *matHeaderCellDef>{{'transporter.enabled.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="form-group">
                            <div class="app-toggle">
                                <mat-slide-toggle *ngIf="hasAccess('TRANSPORTER_EDIT_IS_MINE')" matTooltip="{{(element.enabled) ? ('transporter.desactivate.value' | translate) : ('transporter.activate.value' | translate) }}" [checked]="element.enabled" (change)="(element.enabled) ? openDeleteItemDialog($event, element.id) : openActivateItemDialog($event, element.id)"></mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Nom du transporteur -->

                <ng-container matColumnDef="transporterName">
                    <th mat-header-cell *matHeaderCellDef>{{'transporter.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).name }}</td>
                </ng-container>

                <!-- Devise -->

                <ng-container matColumnDef="currency">
                    <th mat-header-cell *matHeaderCellDef>{{'transporter.currency.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).currency.translations).label }}</td>
                </ng-container>

                <!-- Montant HT -->

                <ng-container matColumnDef="amountHT">
                    <th mat-header-cell *matHeaderCellDef>{{'transporter.priceHt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getAmount(getModel(element), getModel(element).priceHT / 100)}}</td>
                </ng-container>

                <!-- Montant TTC -->

                <ng-container matColumnDef="amountTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'transporter.priceTtc.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getAmount(getModel(element), (getModel(element).priceHT + (getModel(element).priceHT * getModel(element).vatPercent)) / 100)}}</td>
                </ng-container>


            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>
