import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {Subscription} from "@core/shared/models/subscription";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {}

    public getItemSubscriptionBySocietyWithoutPagination(id: number, params?: string[]): Observable<SocietySubscription[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/subscriptions`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<SocietySubscription[]>(url,{
            headers: headers
        });
    }

    public getItemsSubscription(params?: string[]): Observable<Subscription[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/subscriptions`;

        params = params || [];

        params.push('disablePagination=1');

        params.push(`sort[rank]=ASC`);

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Subscription[]>(url,{
            headers: headers
        });
    }

    public createItemAPI(data: object, id: number): Observable<SocietySubscription> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<SocietySubscription>(`${this._apiService.getApiUrl(false, true)}/societies/${id}/subscriptions`, data, {
            headers: headers
        });
    }

    public createSummaryItemAPI(data: object, id: number): Observable<SocietySubscription> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<SocietySubscription>(`${this._apiService.getApiUrl(false, true)}/societies/${id}/subscriptions/summary`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: string): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/societies/subscriptions/${id}`,{
            headers: headers
        });
    }
}
