<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <!-- Actions -->

    <div class="page_title" *ngIf="configuration.showSubmitButton">
        <div class="row marges">

            <div class="col_content y_items_center">

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="showMarkupInput; else description">
        <div class="form-group">
            <input type="text" value="" placeholder=" " class="form-control" formControlName="markup" id="markup">
            <label for="markup">{{'markup.form.value' | translate}}</label>
            <mat-error *ngIf="this.form.get('markup').errors && (this.form.get('markup').dirty || this.form.get('markup').touched)">
                <div class="invalid-feedback">
                    <div *ngIf="this.form.get('markup').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    <div *ngIf="this.form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                    <div *ngIf="this.form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
                </div>
            </mat-error>
            <span class="message-help">
                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'markup.catalog.help.value' | translate }}
            </span>
        </div>
    </ng-container>

    <ng-template #description>
        <div class="ck-content" style="margin-bottom : 1em;">
            <p [innerHTML]="('offer.list.item.action.multiple.access.content.value') | translate"></p>
        </div>
    </ng-template>

</form>
