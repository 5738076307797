<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ (isCrudAction('create') ? 'offer.permanentOption.add.value' : 'offer.permanentOption.update.value') | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'offer.permanentOption.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value' | translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>

        <ng-container *ngFor="let tabItem of tabItems">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

    </mat-tab-group>

</form>

<!-- Données génériques -->

<ng-template #generalData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <div class="form_part">

                <p class="title_part">{{'offer.permanentOption.form.display.title.value' | translate}}</p>

                <!-- Référence -->

                <div class="row marges ligne_form ">
                    <div class="col_12">
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="reference" id="reference" required>
                            <label for="reference">{{ 'offer.permanentOption.reference.value' | translate }}</label>
                            <mat-error *ngIf="form.get('reference').errors && (form.get('reference').dirty || form.get('reference').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('reference').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <!-- Langues -->

                <div class="row marges ligne_form">

                    <div class="col_12">
                        <div class="form-group">
                            <mat-select formControlName="locales" multiple>
                                <mat-option *ngFor="let locale of locales$ | async;" [value]="locale.id">{{ locale.label | translate }}</mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'offer.permanentOption.locales.value' | translate }}</mat-label>
                            <mat-error *ngIf="form.get('locales').invalid && (form.get('locales').dirty || form.get('locales').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('locales').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <!-- Nom de l'assureur -->

                <div class="row marges ligne_form ">
                    <div class="col_12">
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="providerName" id="providerName" required>
                            <label for="reference">{{ 'offer.permanentOption.providerName.value' | translate }}</label>
                            <mat-error *ngIf="form.get('providerName').errors && (form.get('providerName').dirty || form.get('providerName').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('providerName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <!-- Visuel commun ou propre à chaque langue -->

                <ng-container *ngIf="form.get('locales').value && form.get('locales').value.length > 1">
                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'offer.translationPictureType.title.value' | translate}}</label>
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" [value]="'common'" formControlName="translationPictureType" class="form-control" id="translationPictureTypeCommon">
                                        <label for="translationPictureTypeCommon">{{'no.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="'dissociated'" formControlName="translationPictureType" class="form-control" id="translationPictureTypeDissociated">
                                        <label for="translationPictureTypeDissociated">{{'yes.value' | translate}}</label>
                                    </div>
                                    <mat-error *ngIf="form.get('translationPictureType').errors && (form.get('translationPictureType').dirty || form.get('translationPictureType').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('translationPictureType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="row marges ligne_form" *ngIf="translationBuilder.itemsControl.length">

                    <div class="col_12">
                        <div class="mat-tab-translations">
                            <ng-container formArrayName="translations">
                                <mat-tab-group [@.disabled]="true">
                                    <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">
                                        <mat-tab label="{{translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">
                                            <ng-container [formGroupName]="translationBuilder.indexAsString(it)">

                                                <!-- Titre -->

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="title" id="title" required="required">
                                                            <label for="title">{{ 'offer.permanentOption.title.value' | translate }}</label>
                                                            <mat-error *ngIf="this.getTranslation(it).get('title').errors && (this.getTranslation(it).get('title').dirty ||this.getTranslation(it).get('title').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="this.getTranslation(it).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Description -->

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <label class="required">{{ 'offer.permanentOption.description.value' | translate }}</label>
                                                            <app-ckeditor [form]="translationBuilder.getItemControl(it)" [config]="descriptionEditorConfig"></app-ckeditor>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Visuel propre à chaque langue -->

                                                <ng-container *ngIf="isTranslationPictureType('dissociated')">

                                                    <div class="row marges ligne_form">

                                                        <div class="col_12">
                                                            <div class="form-group">
                                                                <label>{{ 'offer.permanentOption.image.value' | translate }}</label>
                                                            </div>
                                                            <br>
                                                            <div class="add_picture collection">

                                                                <div class="ligne_form row marges">
                                                                    <div class="col_content">
                                                                        <div class="form-group">
                                                                            <form-field-image [config]="imageConfig" [form]="translationBuilder.getItemControl(it).get('picture')"></form-field-image>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </ng-container>

                                                <!-- Documents CGV/CPV/CPU -->

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <label class="required">{{ 'offer.permanentOption.termsAndConditions.value' | translate }}</label>
                                                            <form-field-file [config]="termsAndConditionsConfig" [form]="translationBuilder.getItemControl(it)" [file]="translationBuilder.getItemControl(it).get('termsAndConditions').value"></form-field-file>
                                                            <div class="message">
                                                                <span class="message-help"><mat-icon>info</mat-icon>{{ 'termsAndConditions.warning.value' | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Document d’informations -->

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <label>{{ 'offer.permanentOption.additionalDocument.value' | translate }}</label>
                                                            <form-field-file [config]="additionalDocumentConfig" [form]="translationBuilder.getItemControl(it)" [file]="translationBuilder.getItemControl(it).get('additionalDocument').value"></form-field-file>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>
                                        </mat-tab>
                                    </div>
                                </mat-tab-group>
                            </ng-container>
                        </div>

                        <!-- Visuel commun à chaque langue -->

                        <ng-container *ngIf="isTranslationPictureType('common')">

                            <ng-container formArrayName="translations">

                                <br>

                                <ng-container [formGroupName]="0">

                                    <!-- Visuel -->

                                    <div class="ligne_form row">
                                        <div class="col_content">
                                            <div class="form-group add_picture">
                                                <label>{{ 'offer.form.action.program.add.image.value' | translate }}</label>
                                                <form-field-image [config]="imageConfig" [form]="translationBuilder.itemsControl.at(0).get('picture')"></form-field-image>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>

                            </ng-container>

                        </ng-container>
                    </div>

                </div>

            </div>

            <ng-container formGroupName="price">

                <div class="form_part">

                    <p class="title_part">{{'offer.permanentOption.form.price.title.value' | translate}}</p>

                    <!-- Type de prix -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'priceType.value' | translate }}</label>
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" [value]="'amount'" formControlName="priceType" class="form-control" id="priceTypeAmount">
                                        <label for="priceTypeAmount">{{ 'priceType.amount.value' | translate }}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="'percent'" formControlName="priceType" class="form-control" id="priceTypePercent">
                                        <label for="priceTypePercent">{{ 'priceType.percent.value' | translate }}</label>
                                    </div>
                                    <mat-error *ngIf="priceControl.get('priceType').errors && (priceControl.get('priceType').dirty || priceControl.get('priceType').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="priceControl.get('priceType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Type d’option assurance -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'priceArea.value' | translate }}</label>
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" [value]="'travel'" formControlName="priceArea" class="form-control" id="priceAreaTravel">
                                        <label for="priceAreaTravel">{{ 'priceArea.travel.value' | translate }}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="'person-price'" formControlName="priceArea" class="form-control" id="priceAreaPersonPrice">
                                        <label for="priceAreaPersonPrice">{{ 'priceArea.personPrice.value' | translate }}</label>
                                    </div>
                                    <mat-error *ngIf="priceControl.get('priceArea').errors && (priceControl.get('priceArea').dirty || priceControl.get('priceArea').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="priceControl.get('priceArea').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                    <ng-container *ngIf="priceControl.get('priceArea').valid && isPriceArea('person-price')">
                                        <div class="message">
                                            <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon> {{ 'priceArea.personPrice.description.value' | translate }}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Type de tarification -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'pricingType.value' | translate }}</label>
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" [value]="'fixed'" formControlName="pricingType" class="form-control" id="pricingTypeFixed">
                                        <label for="pricingTypeFixed">{{ 'pricingType.fixed.value' | translate }}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="'variable'" formControlName="pricingType" class="form-control" id="pricingTypeVariable">
                                        <label for="pricingTypeVariable">{{ 'pricingType.variable.value' | translate }}</label>
                                    </div>
                                    <mat-error *ngIf="priceControl.get('pricingType').errors && (priceControl.get('pricingType').dirty || priceControl.get('pricingType').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="priceControl.get('pricingType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="priceControl.get('pricingType').valid && isPricingType('variable')">

                        <!-- Type de tarif palier -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{ 'priceLevelType.value' | translate }}</label>
                                    <div class="form-group">
                                        <div class="radio">
                                            <input type="radio" [value]="'amount'" formControlName="priceLevelType" class="form-control" id="priceLevelTypeAmount" [attr.disabled]="isPriceArea('travel') ? '' : null">
                                            <label for="priceLevelTypeAmount">{{ 'priceLevelType.amount.value' | translate }}</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" [value]="'person'" formControlName="priceLevelType" class="form-control" id="priceLevelTypePerson" [attr.disabled]="isPriceArea('travel') ? '' : null">
                                            <label for="priceLevelTypePerson">{{ 'priceLevelType.person.value' | translate }}</label>
                                        </div>
                                        <mat-error *ngIf="priceControl.get('priceLevelType').errors && (priceControl.get('priceLevelType').dirty || priceControl.get('priceLevelType').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="priceControl.get('priceLevelType').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>

            </ng-container>

            <div class="form_part">

                <!-- Commentaire -->

                <div class="row marges ligne_form">

                    <div class="col_12">

                        <div class="form-group">
                            <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                            <label for="comment">{{'offer.permanentOption.comment.value' | translate}}</label>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Configuration -->

<ng-template #priceData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <div class="row marges ligne_form">

                <div class="col_12 col_md_8 ligne_form">

                    <!-- Devise -->

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="form-group">
                                <select class="form-control" id="currency" formControlName="currency">
                                    <option [ngValue]="null"></option>
                                    <option *ngFor="let currency of currencies" [ngValue]="currency.id">
                                        {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.code }})
                                    </option>
                                </select>
                                <label class="required" for="currency">{{ 'currency.value' | translate }}</label>
                                <mat-error *ngIf="form.get('currency').errors && (form.get('currency').dirty || form.get('currency').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <ng-container formGroupName="price" *ngIf="form.get('currency').valid && !!getCurrencyById(form.get('currency').value)">

                        <!-- Prix total du séjour -->

                        <ng-container *ngIf="isPriceArea('travel')">

                            <!-- Prix total du séjour | Tarif fixe -->

                            <ng-container *ngIf="isPricingType('fixed')">

                                <!-- Prix total du séjour | Tarif fixe | Montant -->

                                <ng-container *ngIf="isPriceType('amount')">

                                    <ng-container *ngTemplateOutlet="priceCalculationAutomaticSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="pricesSelection"></ng-container>

                                </ng-container>

                                <!-- Prix total du séjour | Tarif fixe | Pourcentage -->

                                <ng-container *ngIf="isPriceType('percent')">

                                    <ng-container *ngTemplateOutlet="percentSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="priceCalculationAutomaticSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="pricesSelection"></ng-container>

                                </ng-container>

                            </ng-container>

                            <!-- Prix total du séjour | Tarif variable -->

                            <ng-container *ngIf="isPricingType('variable')">

                                <!-- Prix total du séjour | Tarif variable | Montant -->

                                <ng-container *ngIf="isPriceType('amount')"></ng-container>

                                <!-- Prix total du séjour | Tarif variable | Pourcentage -->

                                <ng-container *ngIf="isPriceType('percent')">

                                    <ng-container *ngTemplateOutlet="priceCalculationAutomaticSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="insuranceAmountMinimumApplicableLabel"></ng-container>

                                    <ng-container *ngTemplateOutlet="pricesSelection"></ng-container>

                                </ng-container>

                            </ng-container>

                        </ng-container>

                        <!-- Prix par personne -->

                        <ng-container *ngIf="isPriceArea('person-price')">

                            <!-- Prix par personne | Tarif fixe -->

                            <ng-container *ngIf="isPricingType('fixed')">

                                <!-- Prix par personne | Tarif fixe | Montant -->

                                <ng-container *ngIf="isPriceType('amount')">

                                    <ng-container *ngTemplateOutlet="priceCalculationAutomaticSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="pricesSelection"></ng-container>

                                </ng-container>

                                <!-- Prix par personne | Tarif fixe | Pourcentage -->

                                <ng-container *ngIf="isPriceType('percent')">

                                    <ng-container *ngTemplateOutlet="percentSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="priceCalculationAutomaticSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="pricesSelection"></ng-container>

                                </ng-container>

                            </ng-container>

                            <!-- Prix par personne | Tarif variable -->

                            <ng-container *ngIf="isPricingType('variable')">

                                <!-- Prix par personne | Tarif variable | Montant -->

                                <ng-container *ngIf="isPriceType('amount')"></ng-container>

                                <!-- Prix par personne | Tarif variable | Pourcentage -->

                                <ng-container *ngIf="isPriceType('percent')">

                                    <ng-container *ngTemplateOutlet="priceCalculationAutomaticSelection"></ng-container>

                                    <ng-container *ngTemplateOutlet="insuranceAmountMinimumApplicableLabel"></ng-container>

                                    <ng-container *ngTemplateOutlet="pricesSelection"></ng-container>

                                </ng-container>

                            </ng-container>

                        </ng-container>

                        <!-- Label pour le montant minimum de l’assurance (si applicable) -->

                        <ng-template #insuranceAmountMinimumApplicableLabel>

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <label>{{ 'insurance.amount.minimum.applicable.value' | translate }}</label>
                                    </div>
                                </div>
                            </div>

                        </ng-template>

                        <!-- Sélection des prix HT / TTC -->

                        <ng-template #pricesSelection>

                            <div class="row marges ligne_form">

                                <!-- Tarifs HT -->

                                <div class="col_6">

                                    <div class="form-group">
                                        <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="priceHT" id="price-ht" appInputTextParsePrice [control]="priceControl.get('priceHT')">
                                        <label class="required" for="price-ht">
                                            <ng-container *ngIf="isPriceType('amount')">
                                                {{ 'price.ht.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                            </ng-container>
                                            <ng-container *ngIf="isPriceType('percent')">
                                                {{ 'price.ht.public.minimum.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                            </ng-container>
                                        </label>
                                        <mat-error *ngIf="priceControl.get('priceHT').errors && (priceControl.get('priceHT').dirty || priceControl.get('priceHT').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="priceControl.get('priceHT').errors.isDefined">{{ 'form.control.error.defined.value' | translate }}</div>
                                                <div *ngIf="priceControl.get('priceHT').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="priceControl.get('priceHT').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>

                                </div>

                                <!-- Tarifs TTC -->

                                <div class="col_6">

                                    <div class="form-group">
                                        <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="priceTTC" id="price-ttc" appInputTextParsePrice [control]="priceControl.get('priceTTC')">
                                        <label class="required" for="price-ttc">
                                            <ng-container *ngIf="isPriceType('amount')">
                                                {{ 'price.ttc.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                            </ng-container>
                                            <ng-container *ngIf="isPriceType('percent')">
                                                {{ 'price.ttc.public.minimum.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                            </ng-container>
                                        </label>
                                        <mat-error *ngIf="priceControl.get('priceTTC').errors && (priceControl.get('priceTTC').dirty || priceControl.get('priceTTC').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="priceControl.get('priceTTC').errors.isDefined">{{ 'form.control.error.defined.value' | translate }}</div>
                                                <div *ngIf="priceControl.get('priceTTC').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="priceControl.get('priceTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>

                                </div>

                            </div>

                        </ng-template>

                        <!-- Sélection du pourcentage -->

                        <ng-template #percentSelection>

                            <div class="row marges ligne_form">

                                <div class="col_6">

                                    <!-- Montant du pourcentage -->

                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="percent" id="percent" appInputTextParsePrice [control]="priceControl.get('percent')">
                                        <label for="percent">{{ 'percent.toApply.value' | translate }}</label>
                                        <mat-error *ngIf="priceControl.get('percent').errors && (priceControl.get('percent').dirty || priceControl.get('percent').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="priceControl.get('percent').errors.isDefined">{{ 'form.control.error.defined.value' | translate }}</div>
                                                <div *ngIf="priceControl.get('percent').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="priceControl.get('percent').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                        </ng-template>

                        <!-- Sélection pour le calcul automatique des tarifs -->

                        <ng-template #priceCalculationAutomaticSelection>

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <!-- Calcul automatique des tarifs -->

                                    <div class="row marges ligne_form">
                                        <div class="col_12">
                                            <div class="form-group">
                                                <label>{{'offer.price.calculation.automatic.action.value' | translate}}</label>
                                                <div class="radio">
                                                    <input type="radio" [value]="true" formControlName="priceCalculationAutomatic" class="form-control" id="priceCalculationAutomaticYes">
                                                    <label for="priceCalculationAutomaticYes">{{'form.user.fields.yes.value' | translate}}</label>
                                                </div>
                                                <div class="radio">
                                                    <input type="radio" [value]="false" formControlName="priceCalculationAutomatic" class="form-control" id="priceCalculationAutomaticNo">
                                                    <label for="priceCalculationAutomaticNo">{{'form.user.fields.no.value' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="priceControl.get('priceCalculationAutomatic').value">

                                        <div class="row marges ligne_form">
                                            <div class="col_12">

                                                <!-- Sélection du type de référence -->

                                                <div class="form-group">
                                                    <label>{{'offer.price.type.reference.value' | translate}}</label>
                                                    <div class="radio">
                                                        <input type="radio" [value]="'HT'" formControlName="priceTypeReference" class="form-control" id="priceTypeReferenceHT">
                                                        <label for="priceTypeReferenceHT">{{'taxes.excluding.value' | translate}}</label>
                                                    </div>
                                                    <div class="radio">
                                                        <input type="radio" [value]="'TTC'" formControlName="priceTypeReference" class="form-control" id="priceTypeReferenceTTC">
                                                        <label for="priceTypeReferenceTTC">{{'taxes.including.value' | translate}}</label>
                                                    </div>
                                                    <mat-error *ngIf="priceControl.get('priceTypeReference').errors && (priceControl.get('priceTypeReference').dirty || priceControl.get('priceTypeReference').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="priceControl.get('priceTypeReference').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="priceControl.get('priceTypeReference').value">

                                            <div class="row marges ligne_form">

                                                <div class="col_6">

                                                    <!-- Montant de la TVA -->

                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="vatPercent" id="vatPercent" appInputTextParsePrice [control]="priceControl.get('vatPercent')">
                                                        <label for="vatPercent">{{'tva.percent.value' | translate}}</label>
                                                        <mat-error *ngIf="priceControl.get('vatPercent').errors && (priceControl.get('vatPercent').dirty || priceControl.get('vatPercent').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="priceControl.get('vatPercent').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>

                                    </ng-container>

                                    <!-- Tarifs -->

                                    <ng-container *ngIf="form.get('currency').value && priceControl.get('priceCalculationAutomatic').value && priceControl.get('priceTypeReference').value">

                                        <div class="row marges ligne_form">
                                            <div class="col_12">
                                                <button class="btn_cta btn_little" type="button" (click)="calculatePrices()">
                                                    {{ 'offer.price.calculation.automatic.action.value' | translate }}
                                                </button>
                                            </div>
                                        </div>

                                    </ng-container>
                                </div>
                            </div>

                        </ng-template>

                    </ng-container>

                </div>

                <ng-container *ngIf="form.get('currency').valid && !!getCurrencyById(form.get('currency').value)">

                    <!-- Prix sur le séjour -->

                    <ng-container *ngIf="isPriceArea('travel')">

                        <!-- Prix sur le séjour | Tarif variable -->

                        <ng-container *ngIf="isPricingType('variable')">

                            <ng-container *ngIf="priceControl.get('priceType').valid && priceControl.get('priceLevelType').valid">

                                <ng-container *ngTemplateOutlet="priceLevelsSelection"></ng-container>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                    <!-- Prix par personne -->

                    <ng-container *ngIf="isPriceArea('person-price')">

                        <!-- Prix par personne | Tarif variable -->

                        <ng-container *ngIf="isPricingType('variable')">

                            <ng-container *ngIf="priceControl.get('priceType').valid && priceControl.get('priceLevelType').valid">

                                <ng-container *ngTemplateOutlet="priceLevelsSelection"></ng-container>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                </ng-container>

                <ng-template #priceLevelConfigurationLabel>

                    <ng-container *ngIf="isPriceArea('travel')">

                        <ng-container *ngIf="isPriceType('amount')">

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <label>{{ ('priceLevel.configuration.travel.variable.amount.description.value') | translate }}</label>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="isPriceType('percent')">

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <label>{{ ('priceLevel.configuration.travel.variable.percent.description.value') | translate }}</label>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                    <ng-container *ngIf="isPriceArea('person-price')">

                        <ng-container *ngIf="isPriceType('amount')">

                            <ng-container *ngIf="isPriceLevelType('amount')">

                                <div class="row marges ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <label>{{ ('priceLevel.configuration.personPrice.variable.amount.amount.description.value') | translate }}</label>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="isPriceLevelType('person')">

                                <div class="row marges ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <label>{{ ('priceLevel.configuration.personPrice.variable.amount.person.description.value') | translate }}</label>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="isPriceType('percent')">

                            <ng-container *ngIf="isPriceLevelType('amount')">

                                <div class="row marges ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <label>{{ ('priceLevel.configuration.personPrice.variable.percent.amount.description.value') | translate }}</label>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="isPriceLevelType('person')">

                                <div class="row marges ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <label>{{ ('priceLevel.configuration.personPrice.variable.percent.person.description.value') | translate }}</label>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                </ng-template>

                <!-- Sélection des tarifs paliers -->

                <ng-template #priceLevelsSelection>

                    <div class="col_12 ligne_form">

                        <ng-container *ngTemplateOutlet="priceLevelConfigurationLabel"></ng-container>

                        <div class="row marges ligne_form">

                            <div class="col_12 col_md_8">

                                <!-- Tarifs palier -->

                                <div class="ligne_form collection">

                                    <ng-container formArrayName="priceLevels">

                                        <div *ngFor="let _ of priceLevelsControl.controls; index as i" class="price-level form-group removable_item">

                                            <ng-container [formGroupName]="indexAsString(i)">

                                                <div class="row marges ligne_form">

                                                    <!-- Minimum -->

                                                    <div class="col_6">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="min" id="offer-price-level-min-{{ i }}" [attr.disabled]="true">
                                                            <label for="offer-price-level-min-{{ i }}">
                                                                <ng-container *ngIf="isPriceArea('travel')">
                                                                    <ng-container *ngIf="isPriceLevelType('amount')">
                                                                        {{ 'offer.price.level.amount.travel.min.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                    </ng-container>
                                                                    <ng-container *ngIf="isPriceLevelType('person')">
                                                                        {{ 'offer.price.level.person.travel.min.value' | translate }}
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="isPriceArea('person-price')">
                                                                    <ng-container *ngIf="isPriceLevelType('amount')">
                                                                        {{ 'offer.price.level.amount.personPrice.min.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                    </ng-container>
                                                                    <ng-container *ngIf="isPriceLevelType('person')">
                                                                        {{ 'offer.price.level.person.personPrice.min.value' | translate }}
                                                                    </ng-container>
                                                                </ng-container>
                                                            </label>
                                                            <mat-error *ngIf="getPriceLevelControl(i).get('min').errors && (getPriceLevelControl(i).get('min').dirty || getPriceLevelControl(i).get('min').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getPriceLevelControl(i).get('min').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="getPriceLevelControl(i).get('min').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                    <!-- Maximum -->

                                                    <ng-container *ngIf="!getPriceLevelControl(i).get('maxUnlimited').value; else fakeUnlimitedMax">

                                                        <div class="col_6">
                                                            <div class="form-group">
                                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="max" id="offer-price-level-max-{{ i }}" [attr.disabled]="offerPermanentOptionPriceLevelBuilder.isLastControl(i) ? null : true">
                                                                <label for="offer-price-level-max-{{ i }}">
                                                                    <ng-container *ngIf="isPriceArea('travel')">
                                                                        <ng-container *ngIf="isPriceLevelType('amount')">
                                                                            {{ 'offer.price.level.amount.travel.max.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="isPriceLevelType('person')">
                                                                            {{ 'offer.price.level.person.travel.max.value' | translate }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="isPriceArea('person-price')">
                                                                        <ng-container *ngIf="isPriceLevelType('amount')">
                                                                            {{ 'offer.price.level.amount.personPrice.max.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="isPriceLevelType('person')">
                                                                            {{ 'offer.price.level.person.personPrice.max.value' | translate }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </label>
                                                                <mat-error *ngIf="getPriceLevelControl(i).get('max').errors && (getPriceLevelControl(i).get('max').dirty || getPriceLevelControl(i).get('max').touched)">
                                                                    <div class="invalid-feedback">
                                                                        <div *ngIf="getPriceLevelControl(i).get('max').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        <div *ngIf="getPriceLevelControl(i).get('max').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                    </div>
                                                                </mat-error>
                                                            </div>
                                                        </div>

                                                    </ng-container>

                                                    <ng-template #fakeUnlimitedMax>

                                                        <div class="col_6">
                                                            <div class="form-group">
                                                                <input type="text" value="{{ 'offer.price.level.max.unlimited.value' | translate }}" placeholder=" " class="form-control" id="offer-price-level-fake-unlimited-max-{{ i }}" [attr.disabled]="true">
                                                                <label for="offer-price-level-fake-unlimited-max-{{ i }}">
                                                                    <ng-container *ngIf="isPriceArea('travel')">
                                                                        <ng-container *ngIf="isPriceLevelType('amount')">
                                                                            {{ 'offer.price.level.amount.travel.max.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="isPriceLevelType('person')">
                                                                            {{ 'offer.price.level.person.travel.max.value' | translate }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="isPriceArea('person-price')">
                                                                        <ng-container *ngIf="isPriceLevelType('amount')">
                                                                            {{ 'offer.price.level.amount.personPrice.max.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="isPriceLevelType('person')">
                                                                            {{ 'offer.price.level.person.personPrice.max.value' | translate }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </ng-template>
                                                </div>

                                                <!-- Maximum illimité -->

                                                <ng-container *ngIf="offerPermanentOptionPriceLevelBuilder.isLastControl(i)">

                                                    <div class="row marges ligne_form">
                                                        <div class="col_6 col_offset_6">
                                                            <div class="form-group">
                                                                <div class="checkbox">
                                                                    <input type="checkbox" id="maxUnlimited-{{ i }}" formControlName="maxUnlimited">
                                                                    <label for="maxUnlimited-{{ i }}">
                                                                        {{ 'offer.price.level.max.unlimited.value' | translate }}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                                <div class="row marges ligne_form">

                                                    <!-- Valeur additionnelle (en montant) -->

                                                    <ng-container *ngIf="isPriceType('amount')">

                                                        <div class="col_6">
                                                            <div class="form-group">
                                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalValue" id="offer-price-level-additionalValue-{{ i }}" appInputTextParsePrice [control]="getPriceLevelControl(i).get('additionalValue')">
                                                                <label for="offer-price-level-additionalValue-{{ i }}">{{ 'price.ht.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}</label>
                                                                <mat-error *ngIf="getPriceLevelControl(i).get('additionalValue').errors && (getPriceLevelControl(i).get('additionalValue').dirty || getPriceLevelControl(i).get('additionalValue').touched)">
                                                                    <div class="invalid-feedback">
                                                                        <div *ngIf="getPriceLevelControl(i).get('additionalValue').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        <div *ngIf="getPriceLevelControl(i).get('additionalValue').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                    </div>
                                                                </mat-error>
                                                            </div>
                                                        </div>

                                                    </ng-container>

                                                    <!-- Valeur (en montant ou en pourcentage) -->

                                                    <div class="col_6">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="value" id="offer-price-level-value-{{ i }}" appInputTextParsePrice [control]="getPriceLevelControl(i).get('value')">
                                                            <label for="offer-price-level-value-{{ i }}">
                                                                <ng-container *ngIf="isPriceType('amount')">
                                                                    {{ 'price.ttc.fromCurrency.value' | translate:{ currency: getCurrencyById(form.get('currency').value).symbol } }}
                                                                </ng-container>
                                                                <ng-container *ngIf="isPriceType('percent')">
                                                                    {{ 'percent.toApply.value' | translate }}
                                                                </ng-container>
                                                            </label>
                                                            <mat-error *ngIf="getPriceLevelControl(i).get('value').errors && (getPriceLevelControl(i).get('value').dirty || getPriceLevelControl(i).get('value').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getPriceLevelControl(i).get('value').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="getPriceLevelControl(i).get('value').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                </div>

                                                <ng-container *ngIf="getPriceLevelControl(i).errors">

                                                    <div class="row marges ligne_form">

                                                        <div class="form-group">
                                                            <mat-error>
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getPriceLevelControl(i).errors.isIntervalValueInvalid">{{ 'priceLevel.form.error.isIntervalValueInvalid.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>

                                                    </div>

                                                </ng-container>

                                            </ng-container>

                                            <!-- Suppression du palier -->

                                            <button type="button" class="btn_cta btn_red btn_little remove_item" *ngIf="offerPermanentOptionPriceLevelBuilder.isLastControl(i)" (click)="removePriceLevel(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>

                                        </div>

                                    </ng-container>

                                    <!-- Ajout d'un palier -->

                                    <button class="btn_cta btn_little" type="button" [disabled]="!offerPermanentOptionPriceLevelBuilder.isAllControlsValid || !offerPermanentOptionPriceLevelBuilder.addControlAllowed" (click)="addPriceLevel()">{{ 'offer.price.level.add.action.value' | translate }}</button>

                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="priceLevelsControl.errors">

                            <div class="row marges ligne_form">

                                <div class="form-group">
                                    <mat-error>
                                        <div class="invalid-feedback">
                                            <div *ngIf="priceLevelsControl.errors.isRequired">{{ 'priceLevel.form.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </ng-container>

                    </div>

                </ng-template>
            </div>
        </div>

    </ng-container>

</ng-template>

<!-- Sélection de mes offres -->

<ng-template #offerIsMineData>

    <app-core-offer-permanent-option-offer-search
        [user]="user"
        [society]="society"
        [form]="form"
        [controlName]="'offers'"
        [mode]="'offer-permanent-option-personnal-offers'"
        [defaultSelection]="defaultOfferSelection('offer-permanent-option-personnal-offers')"
    ></app-core-offer-permanent-option-offer-search>

</ng-template>

<!-- Sélection des offres de mon catalogue -->

<ng-template #offerCatalogData>

    <app-core-offer-permanent-option-offer-search
        [user]="user"
        [society]="society"
        [form]="form"
        [controlName]="'offerCatalogs'"
        [mode]="'offer-permanent-option-catalog'"
        [defaultSelection]="defaultOfferSelection('offer-permanent-option-catalog')"
    ></app-core-offer-permanent-option-offer-search>

</ng-template>
