import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import {Pagination} from "@core/shared/models/pagination";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable({
    providedIn: 'root'
})
export class OfferLocationService {

    constructor(private _apiService: ApiService,
                private _httpClient: HttpClient) {
    }

    public getItemsAPI(params?: string[]): Observable<OfferLocation[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url = `${this._apiService.getApiUrl(false, true)}/offers/locations`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferLocation[]>(url, {headers: headers})
            .pipe(
                map((items: object[]): OfferLocation[] => {

                    return items.map((item: object): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                })
            );
    }

    public getOfferItemsAPI(offerId: number, params?: string[]): Observable<OfferLocation[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${offerId}/locations`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferLocation[]>(url, {headers: headers})
            .pipe(
                map((items: object[]): OfferLocation[] => {

                    return items.map((item: object): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                })
            );

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<OfferLocation>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/locations`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<OfferLocation>>(url, {headers: headers})
            .pipe(
                map((pagination: Pagination<OfferLocation>): Pagination<OfferLocation> => {

                    return Object.assign(pagination, {
                        items: pagination.items.map((item: OfferLocation): OfferLocation => {

                            return plainToClass(OfferLocation, item);
                        })
                    });
                })
            );
    }


    public getItemsSocietyAPI(societyId: number, params?: string[]): Observable<OfferLocation[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/offers/locations`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferLocation[]>(url, {headers: headers})
            .pipe(
                map((items: object[]): OfferLocation[] => {

                    return items.map((item: object): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                })
            );
    }

    public getPaginationItemsSocietyAPI(idSociety: number, params?: string[]): Observable<Pagination<OfferLocation>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${idSociety}/offers/locations`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<OfferLocation>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<OfferLocation>): Pagination<OfferLocation> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: OfferLocation): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                });
            })
        );
    }

    public getItemsCatalogAPI(params?: string[]): Observable<OfferLocation[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/locations/catalog`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferLocation[]>(url, {headers: headers})
            .pipe(
                map((items: object[]): OfferLocation[] => {

                    return items.map((item: object): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                })
            );
    }

}