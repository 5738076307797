import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {OfferDate} from "../../models/offer/offer-date";
import {ApiService} from "../api.service";

@Injectable({
    providedIn: 'root'
})
export class OfferDateService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(id: number, params?: string[]): Observable<OfferDate[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${id}/dates`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferDate[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferDate[] => {

                return items.map((item: object): OfferDate => {

                    return plainToClass(OfferDate, item);
                });
            })
        );
    }

    public getItemAPI(offerId: number, offerDateId: number): Observable<OfferDate> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${offerId}/dates/${offerDateId}`;

        return this._httpClient.get<OfferDate>(url, {
            headers: headers
        }).pipe(
            map((item: object): OfferDate => {

                return plainToClass(OfferDate, item);
            })
        );
    }

    public createItemAPI(offerId: number, data: object): Observable<OfferDate> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferDate>(`${this._apiService.getApiUrl(false, true)}/offers/${offerId}/dates`, data,{
            headers: headers
        });
    }

    public updateItemAPI(offerId: number, offerDateId: number, data: object): Observable<OfferDate> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<OfferDate>(`${this._apiService.getApiUrl(false, true)}/offers/${offerId}/dates/${offerDateId}`, data,{
            headers: headers
        });
    }
}
