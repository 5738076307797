import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {UserService} from "@core/shared/services/user.service";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {Access, AccessType} from "@core/shared/models/access";
import {PromotionService} from "@core/shared/models/promotion-service";
import {PromotionServiceService} from "@core/shared/services/promotion-service.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {Service} from "@core/shared/models/service";
import {ServiceService} from "@core/shared/services/service.service";

@Component({
    selector: 'app-core-page-promotion-service-list',
    templateUrl: './page-promotion-service-list.component.html',
    styleUrls: ['./page-promotion-service-list.component.scss']
})
export class PagePromotionServiceListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'service',
        'code',
        'societyLastName',
        'societyFirstName',
        'societyEmail',
        'societyName'
    ];

    public filterBuilder: FilterBuilder;

    public items: PromotionService[];

    public filterServices: { id: number, name: string }[] = [];

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _promotionServiceService: PromotionServiceService,
        private _snackBar: MatSnackBar,
        private _userService:UserService,
        private _serviceService: ServiceService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this._loadServices();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._promotionServiceService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: PromotionService[]): void => {

                this.items = data;
            })
        ;
    }

    private _loadServices(): void {

        this._serviceService.getItemsAPI().subscribe((items: Service[]): void => {

            this.filterServices = items.map((item: Service): { id: number, name: string } => {

                return {
                    id: item.id,
                    name: this.translationService.getFallbackTranslation(item.translations).name
                };
            });
        })
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._promotionServiceService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                map(this.mapApiResult)
            ).subscribe((data: PromotionService[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._promotionServiceService.getPaginationItemsAPI(this.itemsApiParams).pipe(
            map(this.mapApiResult)
        ).subscribe((data: PromotionService[]): void => {

            this.items = data;
        });
    }

    public openDeleteItemDialog(id: number): void {

        const item: PromotionService = this.items.find((item: PromotionService): boolean => {

            return item.id === id;
        });

        const title : string = this._translateService.instant('promotionService.delete.value');

        const content : string = this._translateService.instant('promotionService.delete.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._promotionServiceService.deleteItemAPI(item.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('promotionService.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._promotionServiceService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                    map(this.mapApiResult)
                ).subscribe((data: PromotionService[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['/account/service/promotion/create']);
    }

    public getModel(item: PromotionService): PromotionService {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<PromotionService>) => PromotionService[] {

        return (data: Pagination<PromotionService>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public hasAccess(tag: AccessType): boolean{

        return this.currentUser.accesses.some((access : Access) => {

            return access.tag === tag;
        });
    }

    public update(id): void {

        this._router.navigate(['/account/service/promotion/update/', id]);
    }

    public isEditable(index: number): boolean {

        return this.items[index].isUsed === false;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }
}
