<div class="page_service_subscription">

    <form [formGroup]="form">

        <div class="page_title">
            <div class="row marges">
                <div class="col y_items_center">
                    <h1 class="title">{{ 'services.action.edit.value' | translate }}</h1>
                </div>

                <div class="col_content y_items_center">

                    <!-- Retour -->

                    <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToServices()">
                        {{ 'services.list.item.action.service.back.value' | translate }}
                    </button>

                    <!-- Enregistrer -->

                    <ng-container *ngIf="isAccountAdmin">

                        <ng-container *ngIf="isOnlineSale">

                            <button class="btn_cta btn_little"  (click)="giftVoucherFormComponent.formService.submit()">
                                {{ 'giftVoucher.configuration.submit.value' | translate }}
                            </button>

                        </ng-container>

                        <ng-container *ngIf="isAdditionalWebsite">

                            <button class="btn_cta btn_little"  (click)="additionalWebsiteFormComponent.formService.submit()">
                                {{ 'additionalWebsite.configuration.submit.value' | translate }}
                            </button>

                        </ng-container>

                    </ng-container>

                </div>
            </div>
        </div>

        <mat-tab-group #tabGroup>

            <ng-container *ngFor="let tabItem of tabItems">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>{{ tabItem.label | translate }}</span>
                        <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
                </mat-tab>

            </ng-container>
        </mat-tab-group>

    </form>

</div>

<ng-template #serviceData [formGroup]="form">

    <ng-container *ngIf="societyService">

        <ng-container *ngTemplateOutlet="serviceSummary"></ng-container>

        <ng-container *ngIf="!deregistrationInProcess; else deregistrationInProcessTemplate">

            <ng-container *ngTemplateOutlet="societyServiceForm"></ng-container>

            <ng-container *ngIf="isValidBilling && societyServiceSummaries">

                <ng-container *ngTemplateOutlet="billingSummary"></ng-container>

            </ng-container>

            <ng-container *ngTemplateOutlet="cardForm"></ng-container>

            <ng-container *ngIf="isPaymentRequired">

                <ng-container *ngIf="cardAlreadyRegistered; else cardToRegisterTemplate">

                    <ng-container *ngIf="(card$ | async) as card">

                        <div class="create_card_information col_6">
                            <p [innerHTML]="'services.subscription.creditCard.description.value' | translate:{ cardNumber: card.cardNumber, cardExpiryDate: (card.expirationDate | slice:0:2) + '/' + (card.expirationDate | slice:2:4) }"></p>
                        </div>
                        <br>

                    </ng-container>

                </ng-container>

                <ng-template #cardToRegisterTemplate>

                    <div class="create_card_information col_6">
                        <p [innerHTML]="'services.subscription.noCreditCard.description.value' | translate"></p>
                    </div>
                    <br>

                </ng-template>

            </ng-container>

            <ng-container *ngIf="isFormSubmitEnabled">

                <ng-container *ngTemplateOutlet="termsAndConditions"></ng-container>

                <div class="row ligne_form x_start">
                    <button type="submit" class="btn_cta btn_little" [disabled]="form.invalid || paymentInitializeInProcess" (click)="openConfirmUpdateDialog()">{{ (isPaymentRequired ? 'subscription.actions.validatePayment.value' : 'subscription.actions.validate.value') | translate }}</button>
                </div>

            </ng-container>

        </ng-container>

        <ng-template #deregistrationInProcessTemplate>

            <div class="management_disabled">
                <p>{{ 'service.deregistration.inProcess.value' | translate }}</p>
            </div>

        </ng-template>

    </ng-container>

</ng-template>

<ng-template #giftVoucherData>

    <app-gift-voucher-configuration-form [user]="currentUser"></app-gift-voucher-configuration-form>

</ng-template>

<ng-template #additionalWebsiteData>

    <app-core-additional-website-configuration-form [user]="currentUser"></app-core-additional-website-configuration-form>

</ng-template>

<ng-template #serviceSummary [formGroup]="form">

    <div class="recap_service">

        <!-- Récapitulatif du service    -->

        <p class="title"> {{ serviceTranslation.name }}</p>

        <div class="ck-content serviceContent" [innerHTML]="serviceTranslation.paymentDescription"></div>

    </div>

</ng-template>

<ng-template #societyServiceForm [formGroup]="form">

    <p class="title">{{ (isFormSubmitEnabled ? 'services.subscription.update.value' : 'services.subscription.value') | translate }}</p>

    <div class="recap">

        <ng-container *ngIf="societyService.service.min !== societyService.service.max">

            <!-- Choix de la quantité -->
            <div class="subscription_quantity">
                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">
                        <div class="form-group">
                            <select class="form-control" id="quantity" formControlName="quantity" required="required">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let quantity of quantities;" [ngValue]="quantity">{{ quantity }}</option>
                            </select>
                            <label class="required" for="quantity">{{ 'quantity.value' | translate }}</label>
                            <mat-error *ngIf="form.get('quantity').errors && (form.get('quantity').dirty || form.get('quantity').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('quantity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>
            </div>

        </ng-container>

    </div>

    <div class="subscription_frequency_tiles_container">

        <div class="row marges step_content step_form">

            <div class="col_12 form-group-expanded">

                <div class="row marges subscription_frequency_tiles_row">

                    <ng-container *ngFor="let societySummary of societyServiceSummaries; let i = index">

                        <div class="col_12 col_md_6 subscription_frequency_tile_wrapper ">

                            <div class="subscription_frequency_tile" [ngClass]="(!periodicityControl || societySummary.periodicity === periodicityControl.value ) ? 'tile_checked' : ''" (click)="checkPeriodicity(societySummary.periodicity)">

                                <ng-container *ngIf="periodicityControl">

                                    <div class="radio">
                                        <input type="radio" value="{{ societySummary.periodicity }}" formControlName="periodicity" class="form-control" id="periodicity-{{ (societySummary.periodicity === 'Y' ? 'year': 'month') }}">
                                        <label [for]="'periodicity-'+ (societySummary.periodicity === 'Y' ? 'year': 'month')">{{'form.user.subscription.fields.periodicity.'+ (societySummary.periodicity === 'M' ? 'month': 'year') +'.value' | translate}}</label>
                                    </div>

                                </ng-container>

                                <div class="tile_top">
                                    <span class="ty-check"></span>

                                    <ng-container *ngIf="societySummary.service.isWithSubscription">

                                        <span class="freq_name">{{'services.subscription.frequency.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.value' | translate}}</span>
                                        <p>{{ 'services.subscription.frequency.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.description.value' | translate }}</p>

                                    </ng-container>

                                    <ng-container *ngIf="!societySummary.service.isWithSubscription">

                                        <span class="freq_name">{{'services.subscription.frequency.oneshot.value' | translate}}</span>
                                        <p>{{ 'services.subscription.frequency.oneshot.description.value' | translate }}</p>

                                    </ng-container>


                                </div>

                                <div class="tile_bottom">

                                    <div class="prices">

                                        <ng-container *ngIf="!societySummary.service.isWithSubscription">

                                            <div class="price_item row marges">

                                                <div class="col label ">{{ 'services.admissionPrice.value' | translate }}</div>

                                                <div class="col_content price">

                                                    {{ formatPrice(getAdmissionPrice(i), 'TTC') }}
                                                    <span class="price_ht">({{ formatPrice(societySummary.admissionPrice, 'HT') }})</span>

                                                </div>

                                            </div>

                                        </ng-container>

                                        <ng-container *ngIf="societySummary.hasPromotionAdmissionPrice">

                                            <div class="price_item row marges">

                                                <div class="col_label promotion" *ngIf="societySummary.admissionPrice !== societySummary.promotionAdmissionPrice">
                                                    {{ 'services.promotion.admissionPrice.value' | translate: { promotion: societySummary.promotionService.code} }}
                                                </div>

                                                <div class="col_label promotion" *ngIf="societySummary.admissionPrice === societySummary.promotionAdmissionPrice">
                                                    {{ 'services.promotion.admissionPrice.free.value' | translate : {promotion: societySummary.promotionService.code } }}
                                                </div>

                                                <div class="col_content price promotion" *ngIf="societySummary.admissionPrice !== societySummary.promotionAdmissionPrice">

                                                    -{{ formatPrice(societySummary.promotionAdmissionPrice * (1 + societySummary.vatPercent), 'TTC') }}
                                                    <span class="price_ht">(-{{ formatPrice(societySummary.promotionAdmissionPrice, 'HT') }})</span>

                                                </div>

                                            </div>

                                        </ng-container>

                                    </div>

                                    <span>{{ ((societySummary.hasPromotionAdmissionPrice || societySummary.hasPromotionPrice) ? 'services.payment.total_promotion.value' : 'services.payment.total.value') | translate }}</span>

                                    <div class="prices">

                                        <div class="price_item row marges">

                                            <div class="col label" *ngIf="hasReachInvoiceDate(societySummary); else toBePaid">{{ 'services.subscription.frequency.pay_today.value' | translate }}</div>

                                            <ng-template #toBePaid>
                                                <div class="col label">{{ 'services.subscription.frequency.to_be_pay.value' | translate: {date : societySummary.dateNextInvoice | date : 'shortDate' : null : localeId} }}</div>
                                            </ng-template>

                                            <div class="col_content price">

                                                {{ getAmountTTC(i) }}

                                                <span class="price_ht">({{ formatPrice(societySummary.total, 'HT') }})</span>

                                            </div>

                                        </div>

                                        <ng-container *ngIf="societySummary.periodicity !== societyService.periodicity && servicesInPeriodicity(societySummary.periodicity)">

                                            <div class="price_item row marges">
                                                <div class="col label">
                                                    {{ 'services.subscription.frequency.' + (societySummary.periodicity === 'Y' ? 'yearly': 'monthly') + '.to_be_pay.description.value' | translate: {date : servicesInPeriodicity(societySummary.periodicity).dateNextInvoice | date : 'shortDate' : null : localeId} }}
                                                </div>
                                            </div>

                                        </ng-container>

                                        <div class="price_item row marges">

                                            <div class="col label ">{{ 'tva.amount.value' | translate: {percent: societySummary.vatPercent * 100}  }}</div>

                                            <div class="col_content price">

                                                <span class="current_price">{{ (societySummary.total * (societySummary.vatPercent / 100)) | parsePrice }}€ </span>

                                            </div>

                                        </div>

                                        <ng-container *ngIf="societySummary.hasPromotionPrice">

                                            <!-- Promotion sur les abonnements -->
                                            <div class="price_item row marges">

                                                <div class="col_label promotion">
                                                    {{ 'services.promotion.subscriptionPrice.value' | translate: {priceTTC: formatPrice(societySummary.promotionPrice * (1 + societySummary.vatPercent), 'TTC'), priceHT: formatPrice(societySummary.promotionPrice, 'HT')  } }}

                                                </div>

                                            </div>

                                        </ng-container>

                                    </div>

                                    <ng-container *ngIf="service.isWithSubscription && isFormSubmitEnabled && hasInformationsToShow && hasServicesInPeriodicity(periodicity) && isPaymentRequired && (societySummary.periodicity === periodicity)">

                                        <div class="col label">{{ 'services.prorata.' + (periodicity === 'Y' ? 'yearly': 'monthly') + '.value' | translate:{ date: servicesInPeriodicity(periodicity).dateNextInvoice | date : 'shortDate' : null : localeId } }}</div>

                                    </ng-container>

                                    <ng-container *ngIf="societySummary.service.isWithSubscription">

                                        <span>{{'services.payment.total.'+ (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.value' | translate}}</span>

                                        <div class="prices">

                                            <div class="price_item row marges">

                                                <div class="col label ">{{ 'services.form.price.' + (societySummary.periodicity === "M" ? 'monthly': 'yearly') + '.value' | translate }}</div>

                                                <div class="col_content price">

                                                    <!-- Prix de l'abonnement mensuel ou annuel -->

                                                    {{ formatPrice( calculatePrice(societySummary, 'TTC'), 'TTC') }}

                                                    <span class="price_ht">({{ formatPrice( calculatePrice(societySummary, 'HT'), 'HT') }})</span>

                                                </div>

                                            </div>

                                            <div class="price_item row marges">

                                                <div class="col label ">{{ 'tva.amount.value' | translate: {percent: societySummary.vatPercent * 100}  }}</div>

                                                <div class="col_content price">

                                                    <span class="current_price">{{ calculateTVA(societySummary) | parsePrice }}€ </span>

                                                </div>

                                            </div>

                                        </div>

                                        <!-- Promotion sur les abonnements -->
                                        <ng-container *ngIf="societySummary.recurringPromotionPrice && !(societySummary.recurringPromotionDuration === 0)">

                                            <div class="price_item row marges">

                                                <div class="col_label promotion">

                                                    {{ 'services.promotion.subscriptionPrice.value' | translate: {priceTTC: formatPrice(societySummary.recurringPromotionPrice * (1 + societySummary.vatPercent), 'TTC'), priceHT: formatPrice(societySummary.recurringPromotionPrice, 'HT')  } }}

                                                    <!-- Promotion égale à 1 mois/an -->
                                                    <ng-container *ngIf="societySummary.recurringPromotionDuration === 1">
                                                        {{ 'services.promotion.subscriptionPrice.' + (societySummary.periodicity === 'M' ? 'monthly' : 'yearly') + '.unique.value' | translate: { count: societySummary.recurringPromotionDuration } }}
                                                    </ng-container>

                                                    <!-- Promotion supérieure à 1 mois/an -->
                                                    <ng-container *ngIf="societySummary.recurringPromotionDuration > 1">
                                                        {{ 'services.promotion.subscriptionPrice.' + (societySummary.periodicity === 'M' ? 'monthly' : 'yearly') + '.value' | translate: { count: societySummary.recurringPromotionDuration } }}
                                                    </ng-container>

                                                    <!-- Promotion à vie -->
                                                    <ng-container *ngIf="societySummary.recurringPromotionDuration === null">
                                                        {{ 'services.promotion.subscriptionPrice.lifetime.value' | translate }}
                                                    </ng-container>

                                                </div>

                                            </div>

                                        </ng-container>

                                    </ng-container>

                                </div>

                            </div>
                        </div>

                    </ng-container>


                    <ng-container *ngIf="form.get('periodicity')">
                        <mat-error *ngIf="form.get('periodicity').errors && (form.get('periodicity').dirty || form.get('periodicity').touched)">-->
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('periodicity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>


</ng-template>

<ng-template #billingSummary [formGroup]="form">


    <ng-container *ngIf="isFormSubmitEnabled && hasInformationsToShow">

        <div class="recap">

            <div class="ck-content">

                <!-- Récapitulatif du service -->

                <ng-container *ngIf="service.isWithSubscription">

                    <ng-container *ngIf="!hasServicesInPeriodicity(periodicity)">

                        <!-- Date d'anniversaire du service existant -->

                        <p>{{'services.subscription.birthdate.value'| translate }}: {{ societyService.dateNextInvoice | date : 'shortDate' : null : localeId }}</p>

                        <p [innerHTML]="'services.subscription.update.periodicity.already_available.value' | translate"></p>

                    </ng-container>

                </ng-container>

                <ng-container *ngIf="isDowngrade && !isPaymentRequired">

                    <p [innerHTML]="'services.subscription.update.quantity.downgrade.value' | translate">   </p>

                </ng-container>

                <!-- Si le montant à payer est de 0 et que l'utilisateur n'a pas de carte => on prélève 1 euros -->

                <div class="card_warning" *ngIf="(selectedSocietyServiceSummary && selectedSocietyServiceSummary.total === 0) && !cardAlreadyRegistered">

                    <p>{{ 'services.total_null.value' | translate }}</p>

                </div>

            </div>

        </div>


    </ng-container>

</ng-template>

<ng-template #cardForm [formGroup]="form">

    <ng-container *ngIf="!paymentInitializeInProcess && isPaymentRequired && !cardAlreadyRegistered">

        <div class="payment_form" formArrayName="creditCard">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <input type="text" number-card class="form-control" formControlName="cardNumber" id="cardNumber" required="required" [control]="creditCardControl.get('cardNumber')">
                        <label for="cardNumber">{{'payment.fields.cardNumber.value' | translate}}</label>
                        <mat-error
                            *ngIf="creditCardControl.get('cardNumber').errors && (creditCardControl.get('cardNumber').dirty || creditCardControl.get('cardNumber').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="creditCardControl.get('cardNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardNumber').errors.invalidNUmberCard">{{ 'payment.fields.error.invalidNUmberCard.value' | translate }}</div>

                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <div class="form-group">
                        <input type="text" class="form-control" date-card formControlName="cardExpirationDate" id="cardExpirationDate" required="required" [control]="creditCardControl.get('cardExpirationDate')">
                        <label for="cardExpirationDate">{{'payment.fields.cardExpirationDate.value' | translate}}</label>
                        <mat-error
                            *ngIf="creditCardControl.get('cardExpirationDate').errors && (creditCardControl.get('cardExpirationDate').dirty || creditCardControl.get('cardExpirationDate').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="creditCardControl.get('cardExpirationDate').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardExpirationDate').errors.invalidFormatDate">{{ 'payment.fields.error.invalidFormatDate.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardExpirationDate').errors.invalidDatePast">{{ 'payment.fields.error.invalidDatePast.value' | translate }}</div>

                            </div>
                        </mat-error>
                    </div>
                </div>
                <div class="col_6">
                    <div class="form-group">
                        <input type="text" class="form-control" cvv-card formControlName="cardCvx" id="cardCvx" required="required" [control]="creditCardControl.get('cardCvx')">
                        <label for="cardCvx">{{'payment.fields.cardCvx.value' | translate}}</label>
                        <mat-error *ngIf="creditCardControl.get('cardCvx').errors && (creditCardControl.get('cardCvx').dirty || creditCardControl.get('cardCvx').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="creditCardControl.get('cardCvx').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="creditCardControl.get('cardCvx').errors.invalidFormatCvv">{{ 'payment.fields.error.invalidFormatCvv.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </div>

        <ng-container *ngIf="!hasRole('ROLE_OFFER_CREATOR')">
            <p class="card_infos">{{'services.payment.card.information.value' | translate}}</p>
        </ng-container>

    </ng-container>

</ng-template>

<ng-template #termsAndConditions [formGroup]="form">

    <div class="ligne_form">
        <div class="form-group">
            <div class="checkbox">
                <input type="checkbox" id="acceptTermsAndConditions" formControlName="acceptTermsAndConditions">
                <label for="acceptTermsAndConditions">{{'acceptTermsAndConditions.success.action.description.value' | translate}}
                    <a [href]="termsAndConditionsService.getItemAPIUrl(localeId)" target="_blank">{{ 'acceptTermsAndConditions.value' | translate }}</a></label>
                <mat-error
                    *ngIf="form.get('acceptTermsAndConditions').errors && (form.get('acceptTermsAndConditions').dirty || form.get('acceptTermsAndConditions').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('acceptTermsAndConditions').errors.required">{{ 'acceptTermsAndConditions.form.invalid.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
    </div>

</ng-template>


