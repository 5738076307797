import {Component, OnInit} from '@angular/core';
import {User} from "@core/shared/models/user";
import {TranslationBuilder} from "@core/shared/models/translation";
import {HashtagRestrictionVisibility} from "@core/shared/models/hashtag/hashtag-restriction";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {Society} from "@core/shared/models/society";
import {Observable, of} from "rxjs";
import {Role, ROLE_LABELS, RoleLabel} from "@core/shared/models/role";
import {NetworkProvider} from "@core/shared/models/network-provider";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {RegistrationAffiliation} from "@core/shared/models/registration-affiliation";
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {AbstractControl, FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {OfferService} from "@core/shared/services/offer.service";
import {OfferLocationService} from "@core/shared/services/offer/offer-location/offer-location-service";
import {HashtagService} from "@core/shared/services/hashtag.service";
import {SocietyService} from "@core/shared/services/society.service";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {NetworkProviderService} from "@core/shared/services/network/network-provider.service";
import {RegistrationAffiliationService} from "@core/shared/services/registration-affiliation.service";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {UserService} from "@core/shared/services/user.service";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {map, tap} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {Offer} from "@core/shared/models/offer";
import {HashtagOffer} from "@core/shared/models/hashtag/hashtag-offer";
import {allowedLocales} from "@core/shared/utils/locale";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {ViewType} from "@core/components/offer/offer-search/offer-search.component";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import * as ClassicEditor from "@lib/ckeditor";
import {OfferCardService} from "@core/shared/services/offer/offer-card.service";
import {CrudService} from "@core/shared/services/crud.service";
import {Hashtag} from "@core/shared/models/hashtag";
import {HashtagTranslation} from "@core/shared/models/hashtag/hashtag-translation";
import {OfferListService} from "@core/shared/services/offer/offer-list.service";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Access, AccessType} from "@core/shared/models/access";
import {OfferPart} from "@core/components/offer/offer-list/offer-list.component";
import {getOfferListHashtagParts} from "@core/shared/utils/offer/offer-list/offer-list-parts/offer-list-hashtag-parts";
import {SocietyGroup} from "@core/shared/models/society-group";
import {SocietyGroupService} from "@core/shared/services/society-group.service";

@Component({
    selector: 'app-core-hashtag-form',
    templateUrl: './hashtag-form.component.html',
    styleUrls: ['./hashtag-form.component.scss'],
    providers: [
        FormService,
        OfferSearchService,
        OfferListService,
        OfferCardService
    ]
})
export class HashtagFormComponent implements OnInit {

    public currentUser: User;

    public hashtag: Hashtag;

    public offerCatalogs: OfferCatalog[] = [];

    public translationBuilder: TranslationBuilder;

    public maxLengths: { name: number, description: number } = {
        name: 30,
        description: 500
    };

    public editor = ClassicEditor;

    public availableVisiblities: HashtagRestrictionVisibility[] = ['ALL', 'ROLE', 'NETWORK_PROVIDER', 'NETWORK_OFFER_CREATOR', 'REGISTRATION_AFFILIATION', 'SOCIETY_GROUP', 'SOCIETY'];

    // Utilisateur associé

    public associatedUserSearchConfiguration: SelectSearchConfiguration<Society>;

    public associatedUserSearchSourceCallback: (search: string) => Observable<Society[]>;

    // Rôles utilisateur

    public roleSearchConfiguration: SelectSearchConfiguration<RoleLabel>;

    public roleSearchSourceCallback: (search: string) => Observable<RoleLabel[]>;

    // Réseaux de prestataires

    public networkProviderSearchConfiguration: SelectSearchConfiguration<NetworkProvider>;

    public networkProviderSearchSourceCallback: (search: string) => Observable<NetworkProvider[]>;

    // Réseaux de créteurs d'offres

    public networkOfferCreatorSearchConfiguration: SelectSearchConfiguration<NetworkOfferCreator>;

    public networkOfferCreatorSearchSourceCallback: (search: string) => Observable<NetworkOfferCreator[]>;

    // Liens d'affiliation

    public registrationAffiliationSearchConfiguration: SelectSearchConfiguration<RegistrationAffiliation>;

    public registrationAffiliationSearchSourceCallback: (search: string) => Observable<RegistrationAffiliation[]>;

    // Groupes

    public societyGroupSearchConfiguration: SelectSearchConfiguration<SocietyGroup>;

    public societyGroupSearchSourceCallback: (search: string) => Observable<SocietyGroup[]>;

    // Utilisateurs

    public societySearchConfiguration: SelectSearchConfiguration<Society>;

    public societySearchSourceCallback: (search: string) => Observable<Society[]>;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _translateService: TranslateService,
        private _offerService: OfferService,
        private _offerCatalogService: OfferCatalogService,
        private _offerLocationService: OfferLocationService,
        private _hashtagService: HashtagService,
        private _societyService: SocietyService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        private _networkProviderService: NetworkProviderService,
        private _registrationAffiliationService: RegistrationAffiliationService,
        private _societyGroupService: SocietyGroupService,
        public crudService: CrudService,
        public offerSearchService: OfferSearchService,
        public offerCardService: OfferCardService,
        public offerListService: OfferListService,
        public userService: UserService,
        public formService: FormService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this.hashtag = this.crudService.isUpdateAction ? (this._activatedRoute.snapshot.data as { hashtag: Hashtag }).hashtag : null;

        if(this.crudService.isUpdateAction && this.hashtag.society){

            this._hydrateOfferCatalogs(this.hashtag.offers.map((item: HashtagOffer): OfferCatalog => {

                return item.offerCatalog;
            }));
        }

        this._configureCardService();

        this._configureListService();

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            this._initAssociatedUserConfiguration();
        }

        this._initRoleConfiguration();

        this._initNetworkProviderConfiguration();

        this._initNetworkOfferCreatorConfiguration();

        this._initRegistrationAffiliationConfiguration();

        this._initSocietyGroupConfiguration();

        this._initSocietyConfiguration();

        this._initForm();

        this._hydrateForm();

        this._configureOfferSearchService();

        this._handleFormStatus();

        this._initEvents();
    }

    private _configureOfferSearchService(): void {

        this.offerSearchService.viewOfferAllowed = true;

        this.offerSearchService.selectOfferAllowed = this.hasAccessEdit && (this.isMine || this.isTywinHashtag);
    }

    private _configureCardService(): void {

        this.offerCardService.hashtagsDisplayed = true;
    }

    private _configureListService(): void {

        this.offerListService.hydratePartsCallback = (currentParts: OfferPart[], items: Offer[], reset: boolean): OfferPart[] => {

            const filterKey: string = this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? 'hashtag' : 'offer.hashtag';

            const hashtagId: number = this.offerSearchService.filterBuilder.hasFilter(filterKey) ? this.offerSearchService.filterBuilder.getFieldByKey(filterKey).value : null;

            return getOfferListHashtagParts(hashtagId, currentParts, items, reset);
        };
    }

    private _initAssociatedUserConfiguration(): void {

        this.associatedUserSearchConfiguration = {
            multiple: false,
            currentSelectionLabel: 'user.selected.value',
            searchActionLabel: 'user.search.action.value',
            selectOptionActionLabel: 'user.select.action.value',
            itemLabel: (item: Society): string => {

                return `${item.name}`;
            }
        }

        this.associatedUserSearchSourceCallback = (search: string): Observable<Society[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._societyService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<Society>): Society[] => {

                return pagination.items;
            }));
        }
    }

    private _initRoleConfiguration(): void {

        this.roleSearchConfiguration = {
            multiple: true,
            enableSearch: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            currentSelectionLabel: 'role.plural.user.selected.value',
            searchActionLabel: 'role.search.action.value',
            selectOptionActionLabel: 'role.select.action.value',
            itemLabel: (item: RoleLabel): string => {

                return this._translateService.instant(item.label);
            },
            compareOptionsCallback: (a: RoleLabel, b: RoleLabel): boolean => {

                if(!a || !b){

                    return false;
                }

                return a.identifier === b.identifier;
            },
            selectedItemDisplayedCallback: (item: RoleLabel): boolean => {

                if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

                    return true;
                }

                return !(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'] as Role[]).includes(item.identifier);
            },
            deselectionEnabledCallback: (item: RoleLabel): boolean => {

                return !(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'] as Role[]).includes(item.identifier);
            }
        }

        this.roleSearchSourceCallback = (search: string): Observable<RoleLabel[]> => {

            const items: RoleLabel[] = ROLE_LABELS.filter((item: RoleLabel): boolean => {

                return !(['ROLE_USER'] as Role[]).includes(item.identifier);
            });

            if (search && search.length) {

                return of(items.filter((item: RoleLabel): boolean => {

                    return (this._translateService.instant(item.label) as string).toLowerCase().includes(search.toLowerCase());
                }));
            }

            return of(items);
        }
    }

    private _initNetworkProviderConfiguration(): void {

        this.networkProviderSearchConfiguration = {
            multiple: true,
            enableSearch: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            currentSelectionLabel: 'networkProvider.plural.selected.value',
            searchActionLabel: 'networkProvider.search.action.value',
            selectOptionActionLabel: 'networkProvider.select.action.value',
            itemLabel: (item: NetworkProvider): string => {

                return `${item.name}`;
            }
        }

        this.networkProviderSearchSourceCallback = (search: string): Observable<NetworkProvider[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._networkProviderService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<NetworkProvider>): NetworkProvider[] => {

                return pagination.items;
            }));
        }
    }

    private _initNetworkOfferCreatorConfiguration(): void {

        this.networkOfferCreatorSearchConfiguration = {
            multiple: true,
            enableSearch: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            currentSelectionLabel: 'networkOfferCreator.plural.selected.value',
            searchActionLabel: 'networkOfferCreator.search.action.value',
            selectOptionActionLabel: 'networkOfferCreator.select.action.value',
            itemLabel: (item: NetworkOfferCreator): string => {

                return `${item.name}`;
            }
        }

        this.networkOfferCreatorSearchSourceCallback = (search: string): Observable<NetworkOfferCreator[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._networkOfferCreatorService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<NetworkOfferCreator>): NetworkOfferCreator[] => {

                return pagination.items;
            }));
        }
    }

    private _initRegistrationAffiliationConfiguration(): void {

        this.registrationAffiliationSearchConfiguration = {
            multiple: true,
            enableSearch: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            currentSelectionLabel: 'registrationAffiliation.plural.selected.value',
            searchActionLabel: 'registrationAffiliation.search.action.value',
            selectOptionActionLabel: 'registrationAffiliation.select.action.value',
            itemLabel: (item: RegistrationAffiliation): string => {

                return `${item.name}`;
            }
        }

        this.registrationAffiliationSearchSourceCallback = (search: string): Observable<RegistrationAffiliation[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._registrationAffiliationService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<RegistrationAffiliation>): RegistrationAffiliation[] => {

                return pagination.items;
            }));
        }
    }

    private _initSocietyGroupConfiguration(): void {

        this.societyGroupSearchConfiguration = {
            multiple: true,
            enableSearch: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            currentSelectionLabel: 'societyGroup.plural.selected.value',
            searchActionLabel: 'societyGroup.search.action.value',
            selectOptionActionLabel: 'societyGroup.select.action.value',
            itemLabel: (item: SocietyGroup): string => {

                return `${item.name}`;
            }
        }

        this.societyGroupSearchSourceCallback = (search: string): Observable<SocietyGroup[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._societyGroupService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<SocietyGroup>): SocietyGroup[] => {

                return pagination.items;
            }));
        }
    }

    private _initSocietyConfiguration(): void {

        this.societySearchConfiguration = {
            multiple: true,
            enableSearch: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
            currentSelectionLabel: 'user.plural.selected.value',
            searchActionLabel: 'user.search.action.value',
            selectOptionActionLabel: 'user.select.action.value',
            itemLabel: (item: Society): string => {

                return `${item.name}`;
            }
        }

        this.societySearchSourceCallback = (search: string): Observable<Society[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._societyService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<Society>): Society[] => {

                return pagination.items;
            }));
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            tywinHashtag: [true, [Validators.required]],
            translations: new UntypedFormArray([]),
            society: [null],
            restriction: this._formBuilder.group({
                visibilities: [['ALL'] as HashtagRestrictionVisibility[], Validators.required],
                roles: [[], [(control: FormControl) => {

                    if(!this.isSelectedVisibility('ROLE')){

                        return false;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                networkProviders: [[], [(control: FormControl) => {

                    if(!this.isSelectedVisibility('NETWORK_PROVIDER')){

                        return false;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                networkOfferCreators: [[], [(control: FormControl) => {

                    if(!this.isSelectedVisibility('NETWORK_OFFER_CREATOR')){

                        return false;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                registrationAffiliations: [[], [(control: FormControl) => {

                    if(!this.isSelectedVisibility('REGISTRATION_AFFILIATION')){

                        return false;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                societyGroups: [[], [(control: FormControl) => {

                    if(!this.isSelectedVisibility('SOCIETY_GROUP')){

                        return false;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                societies: [[], [(control: FormControl) => {

                    if(!this.isSelectedVisibility('SOCIETY')){

                        return false;
                    }

                    return (control.value as []).length ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]]
            }),
            offers: [[]]
        });

        this._initTranslationBuilder();

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const formValue = {...this.form.getRawValue()};

            const data: object = Object.assign(formValue, {
                restriction: Object.assign(formValue.restriction, {
                    roles: (formValue.restriction.roles as RoleLabel[]).map((item: RoleLabel): Role => {

                        return item.identifier;
                    })
                }),
                offers: (formValue.offers as Offer[]).map((offer: Offer): Partial<HashtagOffer> => {

                    if(this.crudService.isCreateAction){

                        return {
                            offer: offer
                        };
                    }

                    const existingItem: HashtagOffer = this.hashtag.offers.find((hashtagOffer: HashtagOffer): boolean => {

                        return hashtagOffer.offer.id === offer.id;
                    });

                    const item: Partial<HashtagOffer> = existingItem || {
                        offer: offer
                    };

                    return Object.assign(item, {
                        offerCatalog: this.offerCatalogs.find((offerCatalog: OfferCatalog): boolean => {

                            return offerCatalog.offer.id === item.offer.id;

                        }) || null
                    } as Partial<HashtagOffer>);
                })
            });

            if(this.crudService.isCreateAction){

                this._hashtagService.createItemAPI(data).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('hashtag.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.redirectToList();
                });
            }


            if(this.crudService.isUpdateAction){

                this._hashtagService.updateItemAPI(this.hashtag.id, data).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('hashtag.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.redirectToList();
                });
            }
        }
    }

    private _initTranslationBuilder(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                name: [null, [Validators.required, Validators.maxLength(this.maxLengths.name)]],
                description: [null, [Validators.maxLength(this.maxLengths.description)]]
            });
        };

        allowedLocales.forEach((locale: string): void => {

            this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(locale));
        });
    }

    private _hydrateOfferCatalogs(items: OfferCatalog[]): void {

        items.forEach((item: OfferCatalog): void => {

            const alreadyHydrated: boolean = this.offerCatalogs.some((offerCatalog: OfferCatalog): boolean => {

                return offerCatalog.id === item.id;
            });

            if(!alreadyHydrated){

                this.offerCatalogs.push(item);
            }
        });
    }

    private _hydrateForm(): void {

        if(this.crudService.isCreateAction){

            return;
        }

        this.form.patchValue({
            tywinHashtag: !this.hashtag.society,
            society: this.hashtag.society,
            restriction: Object.assign({...this.hashtag.restriction}, {
                roles: this.hashtag.restriction.roles.map((role: Role): RoleLabel => {

                    return ROLE_LABELS.find((roleLabel: RoleLabel): boolean => {

                        return roleLabel.identifier === role;
                    });
                })
            }),
            offers: this.hashtag.offers.map((item: HashtagOffer): Offer => {

                return item.offer;
            })
        });

        this.hashtag.translations.forEach((item: HashtagTranslation): void => {

            const control: FormGroup = this.translationBuilder.getItemControlByLocale(item.locale);

            control.patchValue({
                name: item.name,
                description: item.description
            } as HashtagTranslation);

            if('id' in item){

                control.addControl('id', new UntypedFormControl(item.id, [Validators.required]));
            }
        });

        this.offerSearchService.selectedOffers.next(this.form.get('offers').value);
    }

    private _handleFormStatus(): void {

        if(this.crudService.isCreateAction){

            return;
        }

        if(!this.hasAccessEdit) {

            this.form.disable();
        }

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            if(this.form.get('tywinHashtag').value){

                return;
            }

            this.form.get('tywinHashtag').disable();

            this.form.get('society').disable();

            this.translationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                control.get('description').disable();
            });
        }
        else{

            this.translationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                control.get('name').disable();

                if(!this.isMine){

                    control.get('description').disable();
                }
            });

            this.restrictionControl.disable();
        }
    }

    private _initEvents(): void {

        // Hashtag Ty-Win

        this.form.get('tywinHashtag').valueChanges.subscribe((): void => {

            this.form.get('society').patchValue(null);

            this.resetOfferSelection();

            this.translationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                control.get('description').patchValue(null);
            });
        });

        // Sélection des offres

        this.offerSearchService.selectedOffers.subscribe((selectedItems: Offer[]): void => {

            this.form.get('offers').patchValue(selectedItems);
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/hashtag/list']);
    }

    public handleVisibility(value: HashtagRestrictionVisibility): void {

        if (this.isSelectedVisibility(value)) {

            const visibilities: HashtagRestrictionVisibility[] = this.restrictionControl.get('visibilities').value;

            const index: number = visibilities.findIndex((visibility: HashtagRestrictionVisibility): boolean => {

                return visibility === value;
            });

            visibilities.splice(index, 1);

            this.restrictionControl.get('visibilities').patchValue(visibilities);

        } else {

            const visibilities: HashtagRestrictionVisibility[] = [];

            visibilities.push(value);

            if (value !== 'ALL') {

                visibilities.push(...(this.restrictionControl.get('visibilities').value as HashtagRestrictionVisibility[]).filter((visibility: HashtagRestrictionVisibility): boolean => {

                    return visibility !== 'ALL';
                }));
            }

            this.restrictionControl.get('visibilities').patchValue(visibilities);
        }

        const associations: {[p in HashtagRestrictionVisibility]: AbstractControl[]} = {
            'ALL': [
                this.restrictionControl.get('roles'),
                this.restrictionControl.get('networkProviders'),
                this.restrictionControl.get('networkOfferCreators'),
                this.restrictionControl.get('registrationAffiliations'),
                this.restrictionControl.get('societyGroups'),
                this.restrictionControl.get('societies')
            ],
            'ROLE': [this.restrictionControl.get('roles')],
            'NETWORK_PROVIDER': [this.restrictionControl.get('networkProviders')],
            'NETWORK_OFFER_CREATOR': [this.restrictionControl.get('networkOfferCreators')],
            'REGISTRATION_AFFILIATION': [this.restrictionControl.get('registrationAffiliations')],
            'SOCIETY_GROUP': [this.restrictionControl.get('societyGroups')],
            'SOCIETY': [this.restrictionControl.get('societies')]
        };

        associations[value].forEach((item: AbstractControl): void => {

            item.patchValue([]);

            item.updateValueAndValidity({onlySelf: true, emitEvent: false});
        });

        const additionalActions: {[p in HashtagRestrictionVisibility]: () => void} = {
            'ALL': (): void => {},
            'ROLE': (): void => {

                if((value !== 'ROLE') || !this.isSelectedVisibility('ROLE')){

                    return;
                }

                this.restrictionControl.get('roles').patchValue(ROLE_LABELS.filter((roleLabel: RoleLabel): boolean => {

                    return (['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'] as Role[]).includes(roleLabel.identifier);
                }));

                this.restrictionControl.get('roles').updateValueAndValidity({onlySelf: true, emitEvent: false});
            },
            'NETWORK_PROVIDER': (): void => {},
            'NETWORK_OFFER_CREATOR': (): void => {},
            'REGISTRATION_AFFILIATION': (): void => {},
            'SOCIETY': (): void => {},
            'SOCIETY_GROUP': (): void => {}
        };

        additionalActions[value]();
    }

    public isSelectedVisibility(value: HashtagRestrictionVisibility): boolean {

        if(!this.restrictionControl){

            return false;
        }

        const visibilities: HashtagRestrictionVisibility[] = this.restrictionControl.get('visibilities').value;

        return visibilities.includes(value);
    }

    public isDisabledVisibility(value: HashtagRestrictionVisibility): boolean {

        if(!this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return true;
        }

        if (value === 'ALL') {

            return false;
        }

        return this.isSelectedVisibility('ALL');
    }

    public hasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.currentUser.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    public getVisibilityLabel(value: HashtagRestrictionVisibility): string {

        return `hashtag.visibility.${value.toString().toLowerCase()}.value`;
    }

    public resetOfferSelection(): void {

        this.offerSearchService.selectedOffers.next([]);

        this.form.get('offers').patchValue([]);
    }

    get form(): FormGroup {

        return this.formService.form;
    }

    get restrictionControl(): FormGroup {

        if(!this.form){

            return null;
        }

        return this.form.get('restriction') as FormGroup;
    }

    get isTywinHashtag(): boolean {

        return this.form.get('tywinHashtag').value;
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: false,
                maxLength: this.maxLengths.description
            }
        }
    }

    get loadOffersSourceCallback(): (view: ViewType, params?: string[]) => Observable<Pagination<Offer> | OfferLocation[]> {

        return (view: ViewType, params?: string[]): Observable<Pagination<Offer> | OfferLocation[]> => {

            params.push(view === 'map' ? 'offer.published[eq]=published' : 'published[eq]=published');

            const items: { [p in ViewType]: Observable<Pagination<Offer> | OfferLocation[]> } = {
                list: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? this._offerService.getPaginationItemsAPI(params) : this._offerCatalogService.getPaginationItemsAPI(params).pipe(
                    tap((pagination: Pagination<OfferCatalog>): void => {

                        this._hydrateOfferCatalogs(pagination.items);
                    }),
                    map((pagination: Pagination<OfferCatalog>): Pagination<Offer> => {

                        return {
                            pagesCount: pagination.pagesCount,
                            totalItems: pagination.totalItems,
                            items: pagination.items.map((item: OfferCatalog): Offer => {

                                return item.offer;
                            })
                        };
                    })
                ),
                map: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ? this._offerLocationService.getItemsAPI(params) : this._offerLocationService.getItemsCatalogAPI(params)
            };

            return items[view];
        }
    }

    get isMine(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return false;
        }

        return this.hashtag.society.id === this.currentUser.society.id;
    }

    get offerSearchMode(): ModeType {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return 'hashtag-reservoir';
        }

        return 'hashtag-catalog';
    }

    get hasAccessEdit(): boolean {

        return ['HASHTAG_EDIT', 'HASHTAG_EDIT_IS_MINE'].some((item: AccessType): boolean => {

            return this.hasAccess(item);
        });
    }
}
