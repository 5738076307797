import {Exclude, Expose, Transform} from "class-transformer";
import {DATE_FORMAT} from "@app/data";
import * as moment from "moment";
import {Moment} from "moment";

@Exclude()
export class OfferFirstDateAvailability {

    @Expose()
    @Transform(({value}) => value ? moment(value, DATE_FORMAT).startOf('day') : null, {toClassOnly: true})
    firstDateAvailability: Moment;
}
