<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
    <div class="ck-content">
        <p [innerHTML]="data.content"></p>
    </div>
</mat-dialog-content>

<form [formGroup]="form">
    <div class="form-group">
        <input type="text" value="" placeholder=" " class="form-control" formControlName="markup" id="markup">
        <label for="markup">{{'markup.form.value' | translate}}</label>
        <mat-error *ngIf="this.form.get('markup').errors && (this.form.get('markup').dirty || this.form.get('markup').touched)">
            <div class="invalid-feedback">
                <div *ngIf="this.form.get('markup').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                <div *ngIf="this.form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                <div *ngIf="this.form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
            </div>
        </mat-error>
    </div>
</form>



<mat-dialog-actions>

    <div class="row marges x_center">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="confirmEmit()">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
        </div>
    </div>

</mat-dialog-actions>
