import {Injectable} from '@angular/core';

@Injectable()
export class OfferGiftVoucherRequestService {

    private _defaultNbAdult: number = null;

    private _defaultNbChild: number = null;

    get defaultNbAdult(): number {

        return this._defaultNbAdult;
    }

    set defaultNbAdult(value: number) {

        this._defaultNbAdult = value;
    }

    get defaultNbChild(): number {

        return this._defaultNbChild;
    }

    set defaultNbChild(value: number) {

        this._defaultNbChild = value;
    }
}
