<div class="content_image" *ngIf="image.content.link" >
    <a href="{{ image.content.link }}" target="_blank">
        <img *ngIf="image.content &&  image.content.id" [src]="getVisuelCms(image.content.id,image.content.formats)" [alt]="(image.content.alt) ? image.content.alt : ''" [title]="(image.content.alt) ? image.content.alt : '' " />
        <div *ngIf="image.content.title || image.content.subtitle " class="texte-visuel">
            <p *ngIf="image.content.title" class="titre">{{ image.content.title }}</p>
            <p *ngIf="image.content.subtitle" class="soustitre">{{ image.content.subtitle }}</p>
        </div>
    </a>
</div>

<div class="content_image" *ngIf="!image.content.link" >
    <img *ngIf="image.content &&  image.content.id" [src]="getVisuelCms(image.content.id,image.content.formats)" [alt]="(image.content.alt) ? image.content.alt : ''" [title]="(image.content.alt) ? image.content.alt : '' " />
    <div *ngIf="image.content.title || image.content.subtitle " class="texte-visuel">
        <p *ngIf="image.content.title" class="titre">{{ image.content.title }}</p>
        <p *ngIf="image.content.subtitle" class="soustitre">{{ image.content.subtitle }}</p>
    </div>
</div>



