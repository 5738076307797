<div>
    <ng-container *ngIf="backEnabled">
        <div class="page_title">
            <div class="row marges">
                <div class="col_content y_items_center">
                    <a (click)="backClicked()" class="btn_cta btn_little btn_back">< {{ 'action.back' | translate}}</a>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="page_title">
        <div class="row marges">
            <div class="col">
                <h1 class="title">{{ translationService.getFallbackTranslation(category.translations).titre }}</h1>
                <div class="ck-content" [innerHTML]="sanitize(translationService.getFallbackTranslation(category.translations).description)"></div>
            </div>
        </div>
    </div>

    <div class="row marges">
        <div class="col_4 content_card" *ngFor="let page of getPagesByTranslate(category.translations)">
            <div class="card_page">
                <div class="row h_full direction_y">
                    <div class="col_content card_top ">
                        <div class="visuel">
                            <ng-container>
                                <img [src]="getVisuelPage(page.id, translationService.getFallbackTranslation(page.translations).picture.id)" alt="{{ translationService.getFallbackTranslation(page.translations).titre}}"/>
                            </ng-container>
                        </div>
                    </div>

                    <div class="col card_bottom">
                        <div class="infos_page">
                            <div class="row h_full direction_y">
                                <div class="col infos_wrapper">
                                    <div class="infos list">
                                        <div class="row marges">
                                            <div class="col y_items_start">
                                                <p class="title">{{ translationService.getFallbackTranslation(page.translations).titre}}</p>
                                            </div>
                                        </div>
                                        <div class="description">
                                            <p [innerHTML]="translationService.getFallbackTranslation(page.translations).description|safeHtml"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col_content">
                                    <div class="links_page">
                                        <!-- Visualisation d'une page -->
                                        <ng-container>
                                            <div class="black">
                                                <a [routerLink]="['/page/read', page.id]" [target]="page.target" [queryParams]="{ backEnabled: (page.target === '_self') ? 1 : 0 }">
                                                    <span>{{'page.list.item.action.see.value' | translate}}</span>
                                                </a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
