import {Injectable} from "@angular/core";
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";


@Injectable({
    providedIn: 'root'
})
export class TemplateGiftVoucherService {

    constructor(private _apiService: ApiService,
                private _httpClient: HttpClient) {
    }

    public getItemAPI(id: number): Observable<TemplateGiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<TemplateGiftVoucher>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/templates/${id}`, {
            headers: headers
        }).pipe(
            map((item: object): TemplateGiftVoucher => {

                return plainToClass(TemplateGiftVoucher, item);
            })
        );
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<TemplateGiftVoucher>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/giftVouchers/templates`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<TemplateGiftVoucher>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<TemplateGiftVoucher>): Pagination<TemplateGiftVoucher> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: TemplateGiftVoucher): TemplateGiftVoucher => {

                        return plainToClass(TemplateGiftVoucher, item);
                    })
                });
            })
        );
    }

    public getSocietyTemplateGiftVouchers(societyId: number, offerId: number, params?: string[]): Observable<TemplateGiftVoucher[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/offers/${offerId}/templates`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<TemplateGiftVoucher[]>(url,{
            headers: headers
        }).pipe(
            map((items: object[]): TemplateGiftVoucher[] => {

                return items.map((item: object): TemplateGiftVoucher => {

                    return plainToClass(TemplateGiftVoucher, item);
                })
            })
        );
    }

    public createItemAPI(data: object): Observable<TemplateGiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<TemplateGiftVoucher>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/templates`, data, {
            headers: headers
        }).pipe(
            map((item: object): TemplateGiftVoucher => {

                return plainToClass(TemplateGiftVoucher, item);
            })
        );
    }

    public createSocietyItemAPI(id: number, data: object): Observable<TemplateGiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<TemplateGiftVoucher>(`${this._apiService.getApiUrl(false, true)}/societies/${id}/giftVouchers/templates`, data, {
            headers: headers
        }).pipe(
            map((item: object): TemplateGiftVoucher => {

                return plainToClass(TemplateGiftVoucher, item);
            })
        );
    }

    public updateItemAPI(id: number, data: object): Observable<TemplateGiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<TemplateGiftVoucher>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/templates/${id}`, data, {
            headers: headers
        }).pipe(
            map((item: object): TemplateGiftVoucher => {

                return plainToClass(TemplateGiftVoucher, item);
            })
        );
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/templates/${id}`, {
            headers: headers
        });
    }

}