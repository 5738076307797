<div class="row advantage_content">
    <div class="col_12">
        <p class="content_label medium">
            {{ advantages.label }}
        </p>
        <ul class="row content_list">
            <!--Avantage d'un rôle -->
            <li class="col_12 col_md_6" *ngFor="let advantage of advantages.list">
               <span class="ty-check">  {{advantage.value}} </span>
            </li>
        </ul>
    </div>
</div>
