export const browserInfos = (): object => {

    const screenWidth = window && window.screen ? window.screen.width : '';
    const screenHeight = window && window.screen ? window.screen.height : '';
    const colorDepth = window && window.screen ? window.screen.colorDepth : '';
    const userAgent = window && window.navigator ? window.navigator.userAgent : '';
    const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false;
    const javascriptEnabled = true;
    let language = '';
    if (window && window.navigator) {
        language = window.navigator.language
            ? window.navigator.language
            /** @ts-ignore **/
            : window.navigator.browserLanguage; // Else is for IE <+ 10
    }

    const d = new Date();
    const timeZoneOffset = d.getTimezoneOffset();

    const acceptHeader = 'application/json';

    return {
        "acceptHeader": acceptHeader,
        "javaEnabled": javaEnabled,
        "language": language,
        "colorDepth": colorDepth,
        "screenHeight": screenHeight,
        "screenWidth": screenWidth,
        "timeZoneOffset": timeZoneOffset,
        "userAgent": userAgent,
        "javascriptEnabled": javascriptEnabled
    }
};
