import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Offer, OfferStockType} from "@core/shared/models/offer";
import {Moment} from "moment";
import {OfferDate} from "@core/shared/models/offer/offer-date";
import {Booking} from "@core/shared/models/booking";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {OfferDateService} from "@core/shared/services/offer/offer-date.service";
import {BookingService} from "@core/shared/services/booking.service";
import {DATE_FORMAT} from "@app/data";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {User} from "@core/shared/models/user";
import {parsePrice} from "@core/shared/utils/price";
import {ConfigurationFieldIdentifier} from "@core/shared/models/offer/offer-date/offer-date-configuration";
import {OfferDateStock} from "@core/shared/models/offer/offer-date/offer-date-stock";
import {Currency} from "@core/shared/models/currency";

@Component({
    selector: 'app-core-offer-date-read',
    templateUrl: './offer-date-read.component.html',
    styleUrls: ['./offer-date-read.component.scss']
})
export class OfferDateReadComponent implements OnInit {

    @Input() offer: Offer;

    @Input() date: Moment;

    @Input() offerDateId: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public offerDate: OfferDate = null;

    public bookings: Booking[] = [];

    public bookingOffset: number = 0;

    public bookingPerPage: number = 10;

    public totalBookings: number = 0;

    constructor(
        private _translateService: TranslateService,
        private _userService: UserService,
        private _offerDateService: OfferDateService,
        private _bookingService: BookingService
    ) {}

    ngOnInit(): void {

        this._loadOfferDate();

        this._loadBookings();
    }

    private _loadOfferDate(): void {

        this._offerDateService.getItemAPI(this.offer.id, this.offerDateId).subscribe((item: OfferDate): void => {

            this.offerDate = item;
        });
    }

    private _loadBookings(): void {

        const params: string[] = [
            `sort[createdAt]=desc`,
            `page=${this.bookingOffset + 1}`,
            `limit=${this.bookingPerPage}`,
            'status[in][]=validated',
            'status[in][]=option-set',
            `offer.id[eq]=${this.offer.id}`,
            `composition.dateStart[eq]=${this.date.format(DATE_FORMAT)}`,
            `type[eq]=booking`
        ];

        this._bookingService.getPaginationSocietyItemsAPI(this.currentUser.society.id, params).pipe(
            map((data: Pagination<Booking>): Booking[] => {

                this.totalBookings = data.totalItems;

                return data.items;
            })
        ).subscribe((items: Booking[]): void => {

            this.bookings.push(...items);
        });
    }

    public loadMoreBookings(): void {

        if(!this.hasMoreBookings){

            return;
        }

        this.bookingOffset++;

        this._loadBookings();
    }

    public identifyBooking(index: number, item: Booking): string {

        return `${item.reference}`;
    }

    public parsePrice(value: number, currency: Currency): string {

        if(typeof value !== 'number'){

            return '';
        }

        return `${parsePrice(value)} ${currency.symbol}`;
    }

    public isConfigurationFieldConfigured(type: ConfigurationFieldIdentifier): boolean {

        const items: { value: unknown, valid: (value: unknown) => boolean }[] = [];

        switch (type){

            case 'stock':

                items.push(...[
                    {
                        value: this.offerDate.stocks,
                        valid: (data: OfferDateStock[]) => { return data.length > 0; }
                    },
                    {
                        value: this.offerDate,
                        valid: (data: OfferDate) => { return data.unlimitedStock; }
                    }
                ]);

                break;

            case 'price':

                items.push(...[
                    {
                        value: this.offerDate.adultPriceHT,
                        valid: (data: number) => { return typeof data === 'number'; }
                    },
                    {
                        value: this.offerDate.adultPriceTTC,
                        valid: (data: number) => { return typeof data === 'number'; }
                    },
                    {
                        value: this.offerDate.childPriceHT,
                        valid: (data: number) => { return typeof data === 'number'; }
                    },
                    {
                        value: this.offerDate.childPriceTTC,
                        valid: (data: number) => { return typeof data === 'number'; }
                    }
                ]);

                break;

            case 'releaseDate':

                items.push(...[
                    {
                        value: this.offerDate.releaseDate,
                        valid: (data: number) => { return typeof data === 'number'; }
                    }
                ]);

                break;
        }

        return items.some((item: { value: unknown, valid: (value: unknown) => boolean }): boolean => {

            return item.valid(item.value);
        });
    }

    public isStockManagementType(value: OfferStockType): boolean {

        return this.offer.onlineSale.stockManagementType === value;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get hasMoreBookings(): boolean {

        return this.bookings.length < this.totalBookings;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
