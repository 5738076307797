import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "../../models/pagination";
import {SocietyProvider} from "@core/shared/models/society/society-provider";

@Injectable({
    providedIn: 'root'
})
export class SocietyProviderService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getPaginationSocietyItemsAPI(societyId: number, params?: string[]): Observable<Pagination<SocietyProvider>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/societyProviders`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<SocietyProvider>>(url, {
            headers: headers
        });
    }

    public getSocietyItemsAPI(societyId: number, params?: string[]): Observable<SocietyProvider[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/societyProviders`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<SocietyProvider[]>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number): Observable<SocietyProvider> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<SocietyProvider>(`${this._apiService.getApiUrl(false, true)}/societyProviders/${id}`, {
            headers: headers
        });
    }

    public createItemAPI(societyId: number, data: object): Observable<SocietyProvider> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<SocietyProvider>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/societyProviders`, data,{
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<SocietyProvider> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<SocietyProvider>(`${this._apiService.getApiUrl(false, true)}/societyProviders/${id}`, data,{
            headers: headers
        });
    }

    public sendRequestItemAPI(data: object): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, true)}/societyProviders/request`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/societyProviders/${id}`,{
            headers: headers
        });
    }
}
