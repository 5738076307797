export type OfferOptionPriceLevelTypeType = 'percent' | 'price';

export interface OfferOptionPriceLevelType {

    type: OfferOptionPriceLevelTypeType;

    label: string;
}

export const OFFER_OPTION_PRICE_LEVEL_TYPES: OfferOptionPriceLevelType[] = [
    {
        type: 'percent',
        label: 'offer.price.level.type.percent.value'
    },
    {
        type: 'price',
        label: 'offer.price.level.type.price.value'
    }
];

export type OfferOptionPriceLevelIncreaseAreaType = 'travel' | 'adult-price' | 'child-price' | 'all';

export interface OfferOptionPriceLevelIncreaseArea{

    type: OfferOptionPriceLevelIncreaseAreaType;

    label: string;
}

export const OFFER_OPTION_PRICE_LEVEL_INCREASE_AREAS: OfferOptionPriceLevelIncreaseArea[] = [
    {
        type: 'travel',
        label: 'offer.price.level.increaseArea.travel.value'
    },
    {
        type: 'adult-price',
        label: 'offer.price.level.increaseArea.adultPrice.value'
    },
    {
        type: 'child-price',
        label: 'offer.price.level.increaseArea.childPrice.value'
    },
    {
        type: 'all',
        label: 'offer.price.level.increaseArea.all.value'
    }
];

export interface OfferOptionPriceLevel {

    id: number;

    type: OfferOptionPriceLevelTypeType;

    priceIncreaseArea: OfferOptionPriceLevelIncreaseAreaType;

    min: number;

    max: number;

    value: number;
}
