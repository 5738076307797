import {Directive, Input, OnInit, Self} from '@angular/core';
import {MatLegacyRadioGroup as MatRadioGroup} from "@angular/material/legacy-radio";

/**
 * Gestion de l'affichage des attribut ddes champs radio
 */
@Directive({
    selector: '[radioInput]'
})
export class RadioDirective implements OnInit {
    /**
     * Attributs
     */
    @Input() attrs: object;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor(@Self() private _input: MatRadioGroup) {
    }

    /**
     * Traitement des options
     */
    ngOnInit() {
        for (const [key, value] of Object.entries(this.attrs)) {
            if (key != 'choices' && key != 'label') {
                this._input[key] = value;
            }
        }
    }
}
