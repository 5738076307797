import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import {BookingService} from "../services/booking.service";
import {Booking} from "../models/booking";
import {SocietyService} from "../services/society.service";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {AuthenticationService} from "@core/shared/services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class PageBookingItemResolver  {
    constructor(
        private _bookingService: BookingService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _authenticationService: AuthenticationService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this._bookingService.getItemAPI(parseInt(route.paramMap.get('id')))
    }
}
