<!-- Gestion de l'abonnement -->

<subscription-form [subscriptions]="subscriptions" [data]="societySubscriptions" [isDisabled]="false" [redirectionToSubscriptionViewEnabled]="true"></subscription-form>

<!-- Contact TyWin -->

<div class="ligne_form row x_center">
    <div class="contact_tywin">
        <p [innerHTML]="'subscription.form.contact_tywin.value' | translate"></p>
    </div>
</div>
