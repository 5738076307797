<div class="toggle_month">
    <button type="button" [ngClass]="getNumberMonthsDisplayedElementClasses(1)" (click)="setNumberMonthsDisplayed(1)">{{ 'calendar.view.oneMonth.value' | translate }}</button>
    <button type="button" [ngClass]="getNumberMonthsDisplayedElementClasses(2)" (click)="setNumberMonthsDisplayed(2)">{{ 'calendar.view.twoMonths.value' | translate }}</button>
    <button type="button" [ngClass]="getNumberMonthsDisplayedElementClasses(12)" (click)="setNumberMonthsDisplayed(12)">{{ 'calendar.view.oneYear.value' | translate }}</button>
</div>

<div #calendar></div>

<div class="calendar_legend">
    <div class="legend available">
        <span>{{ 'promotion.status.available.value' | translate }}</span>
    </div>
    <div class="legend closed">
        <span>{{ 'promotion.status.closed.value' | translate }}</span>
    </div>
</div>
