import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Moment} from "moment";
import * as moment from "moment";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {DATE_FORMAT} from "@app/data";
import {OfferAvailabilityService} from "@core/shared/services/offer/offer-availability.service";
import {Offer} from "@core/shared/models/offer";
import {OfferAvailability} from "@core/shared/models/offer/offer-availability";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {OfferDateEngineConfiguration, OfferDateEngineItem} from "@core/shared/models/offer/offer-date/offer-date-engine";
import {User} from "@core/shared/models/user";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferOptionSelectionService} from "@core/shared/services/offer/offer-option/offer-option-selection.service";
import {UserService} from "@core/shared/services/user.service";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Role} from "@core/shared/models/role";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {BookingTypeType} from "@core/shared/models/booking";
import {TranslationService} from "@core/shared/services/translation.service";
import {BookingCompositionParticipant} from "@core/shared/models/booking/booking-composition-participant";
import {OfferFirstDateAvailability} from "@core/shared/models/offer/offer-first-date-availability";

/**
 * Type de données à configurer : Voyageurs / Dates
 */
export type ConfigurationStepIdentifier = 'traveller' | 'date';

export interface ConfigurationStep {

    enabled: boolean;

    valid: () => boolean;
}

@Component({
    selector: 'app-core-offer-date-engine',
    templateUrl: './offer-date-engine.component.html',
    styleUrls: ['./offer-date-engine.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferDateEngineComponent implements OnInit {

    @Input() offer: Offer;

    @Input() currentUser: User;

    @Input() configuration: OfferDateEngineConfiguration;

    @Input() childDatesAllowed: { start: Moment, end: Moment };

    @Input() loadItemsSourceCallback: (offer: Offer, params?: string[]) => Observable<OfferAvailability[]>;

    @Input() loadFirstAvailabilityItemSourceCallback: (offer: Offer, params?: string[]) => Observable<OfferFirstDateAvailability>;

    @Output() compositionUpdated: EventEmitter<{ type: 'date' | 'public' | 'adult' | 'child' | 'child-informations' }> = new EventEmitter<{ type: 'date' | 'public' | 'adult' | 'child' | 'child-informations' }>();

    @Output() dateReinitialized: EventEmitter<void> = new EventEmitter<void>();

    private _type: BookingTypeType = 'booking';

    private _availableStock: boolean = null;

    public countDisplayedMonths: number = 2;

    public start: Moment;

    public months: { label: string, days: Moment[], previousMonthDays: Moment[] }[] = [];

    public availabilities: {[p: string] : OfferAvailability} = {};

    public selection: { start: Moment, end: Moment } = {
        start: null,
        end: null
    };

    public displayedDates : { start: Moment, end: Moment } = {
        start: null,
        end: null
    };

    public calendarStateSubject: BehaviorSubject<'opened' | 'closed'> = new BehaviorSubject<'opened' | 'closed'>('closed');

    public countAdult: number;

    public countChild: number;

    public publicPrice: number = 0;

    public options: OfferOption[] = [];

    public countAdultUpdatedSubject: Subject<void> = new Subject();

    public countChildUpdatedSubject: Subject<void> = new Subject();

    public pricePublicUpdatedSubject: Subject<void> = new Subject();

    public user: User;

    public configurationSteps: {[p in ConfigurationStepIdentifier]: ConfigurationStep} = {
        traveller: null,
        date: null
    };

    public displayChildInformationsValidators: boolean = false;

    constructor(
        private _offerAvailabilityService: OfferAvailabilityService,
        private _offerCatalogService: OfferCatalogService,
        private _offerOptionSelectionService: OfferOptionSelectionService,
        private _formBuilder: UntypedFormBuilder,
        public _userService: UserService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this._initForm();

        moment.locale( this.user.locale );

        this.start = moment().startOf('month');

        this.countAdult = this.offer.publics.includes('adult') ? this.offer.presential.adultDefault : null;

        this.countChild = this.offer.publics.includes('child') ? this.offer.presential.childDefault : null;

        if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER'])){

            this._offerCatalogService.getItemsAPI().subscribe((): void => {

                this._handlePublicPrice();

                this._loadDates(this.start, this.start.clone().add(1, 'months').endOf('month'));
            });

        } else {

            this._handlePublicPrice();

            this._loadDates(this.start, this.start.clone().add(1, 'months').endOf('month'));
        }

        const items: { type: | 'public' | 'adult' | 'child', subject: Subject<void> }[] = [
            {
                type: 'adult',
                subject: this.countAdultUpdatedSubject
            },
            {
                type: 'child',
                subject: this.countChildUpdatedSubject
            },
            {
                type: 'public',
                subject: this.pricePublicUpdatedSubject
            }
        ];

        items.forEach((item: { type: | 'public' | 'adult' | 'child', subject: Subject<void> }): void => {

            item.subject.subscribe((): void => {

                this.compositionUpdated.emit({
                    type: item.type
                });

                if(['public'].includes(item.type)){

                    this._loadDates(this.start, this.start.clone().add(1, 'months').endOf('month'));
                }
            });
        });

        this._initConfigurationSteps();

        this._initOptions();

        if(this.offer.customerTypology.type === 'I'){

            this._initParticipantsControls();

            this.countChildUpdatedSubject.subscribe((): void => {

                this._initParticipantsControls();
            });
        }
    }

    private _initConfigurationSteps(): void {

        // Voyageurs

        this.configurationSteps.traveller = {
            enabled: true,
            valid: (): boolean => {

                return this.participantsControls.valid && this.isValidNbPerson;
            }
        };

        // Dates

        this.configurationSteps.date = {
            enabled: false,
            valid: () : boolean => {

                return this.isValidationDateSelectionValid;
            }
        };
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            participants: new UntypedFormArray([])
        });

        this.participantsControls.valueChanges.subscribe((): void => {

            this.form.updateValueAndValidity();

            this.compositionUpdated.emit({
                type: 'child-informations'
            });
        });
    }

    private _initParticipantsControls(): void {

        this.displayChildInformationsValidators = false;

        this.participantsControls.clear();

        this.participantsControls.clearValidators();

        for (let i: number = 1; i <= this.countChild; i++){

            this.participantsControls.push(this._formBuilder.group({
                birthDay: [null, Validators.required, [(control: UntypedFormControl) => {

                    if (control.value){

                        const currentDate: Moment = control.value as Moment;

                        if (!currentDate.isBetween(this.childDatesAllowed.start, this.childDatesAllowed.end)){

                            return of({ 'birthdateInvalid': {valid: false} });
                        }
                    }

                    return of(null);
                }]]
            }));
        }

        this.participantsControls.updateValueAndValidity();

        this.participantsControls.controls.forEach((control: UntypedFormGroup): void => {

            control.get('birthDay').markAsTouched();
        });
    }

    private _handlePublicPrice(): void {

        if(this.hasRole('ROLE_PROVIDER')) {

            this.publicPrice = 1;

            return;
        }

        this.publicPrice = +this._offerCatalogService.selfItems.getValue().some((item: OfferCatalog): boolean => {

            return item.offer.id === this.offer.id;
        });
    }

    private _initOptions(): void {

        this.options = this._offerOptionSelectionService.items;

        this._offerOptionSelectionService.items$.subscribe((items: OfferOption[]): void => {

            this.options = items;
        });
    }

    private _constructMonths(): void {

        const date: Moment = this.start.clone();

        for(let i = 0; i < this.countDisplayedMonths; i++) {

            this._constructMonth(date.clone());

            date.add(1, 'months');
        }
    }

    private _constructMonth(date: Moment): void {

        const days: Moment[] = this._getDaysOfMonth(date);

        const startOfMonth: Moment = days[0];

        this.months.push({
            label: `${date.format('MMMM')} ${date.format('YYYY')}`,
            days: days,
            previousMonthDays: this._getDaysBetweenDates(startOfMonth.clone().startOf("week"), startOfMonth.clone())
        });
    }

    private _loadDates(start: Moment, end: Moment): void {

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('date', 'gte', start.format(DATE_FORMAT)));

        filterBuilder.addField(new TextFilterField('date', 'lte', end.format(DATE_FORMAT)));

        if(this.offer.publics.includes('adult')){

            filterBuilder.addField(new TextFilterField('nbAdult', 'eq', this.countAdult.toString()));
        }

        if(this.offer.publics.includes('child')){

            filterBuilder.addField(new TextFilterField('nbChild', 'eq', this.countChild.toString()));
        }

        filterBuilder.addField(new TextFilterField('publicPrice', 'eq', this.publicPrice.toString()));

        this.loadItemsSourceCallback(this.offer, filterBuilder.serializedFilters).subscribe((items: OfferAvailability[]): void => {

            this.availabilities = {};

            items.forEach((item: OfferAvailability): void => {

                this.availabilities[item.date.format(DATE_FORMAT)] = item;
            });

            this._clearMonths();

            this._constructMonths();
        });
    }

    private _clearMonths(): void {

        this.months = [];
    }

    private _getDaysOfMonth(date: Moment): Moment[] {

        return Array.from({length: date.daysInMonth()}, (x, i) => date.clone().startOf('month').add(i, 'days'));
    }

    private _getDaysBetweenDates(startDate: Moment, endDate: Moment): Moment[] {

        const startReference = startDate.clone();

        const endReference: Moment = endDate.clone();

        const dates: Moment[] = [];

        while (startReference.isBefore(endReference)) {

            dates.push(startReference.clone());

            startReference.add(1, 'days');
        }

        return dates;
    };

    private _resetDateStep(): void {

        this.selection = {
            start: null,
            end: null
        };

        this.displayedDates = {
            start: null,
            end: null
        };

        this.dateReinitialized.emit();

        this.closeCalendar();
    }

    public navigateToPreviousMonth(): void {

        if(!this.configurationSteps.date.enabled || !this.isNavigationToPreviousMonthAllowed){

            return;
        }

        this.start.subtract(1, 'months');

        this._loadDates(this.start, this.start.clone().add(1, 'months').endOf('month'));
    }

    public navigateToNextMonth(): void {

        if(!this.configurationSteps.date.enabled){

            return;
        }

        this.start.add(1, 'months');

        this._loadDates(this.start, this.start.clone().add(1, 'months').endOf('month'));
    }

    public updateSelection(start: Moment, availability: OfferAvailability): void {

        if(!this.configurationSteps.date.enabled){

            return;
        }

        if(availability.isClosed){

            return;
        }

        this.selection = {
            start: start,
            end: start.clone().add(this.offer.duration.value - 1, 'days')
        };
    }

    public validateSelection(): void {

        if(!this.isValidationDateSelectionValid){

            return;
        }

        this.displayedDates = {
            start: this.selection.start,
            end: this.selection.end
        };

        this._type = (this.currentAvailability.status === 'available') ? 'booking' : 'request';

        this._availableStock = this.currentAvailability.availableStock;

        this.compositionUpdated.emit({
            type: 'date'
        });

        this.closeCalendar();
    }

    public getDayClasses(day: Moment, availability: OfferAvailability): {[p: string] : boolean } {

        return {
            'current': day.isSame(moment(), 'day'),
            'startSelection': !!this.selection.start && day.isSame(this.selection.start, 'day'),
            'endSelection': !!this.selection.end && day.isSame(this.selection.end, 'day'),
            'selection': !!this.selection.start && !!this.selection.end && day.isBetween(this.selection.start, this.selection.end, 'day', '()'),
            'available': availability.isAvailable,
            'onRequest': availability.isOnRequest,
            'closed': availability.isClosed
        };
    }

    public getDayPastilleClasses(availability: OfferAvailability): {[p: string] : boolean } {

        return {
            'legend-available' : availability.isAvailable && availability.availableStock,
            'legend-onbooking' : availability.isAvailable && !availability.availableStock,
            'legend-onrequest' : availability.isOnRequest
        };
    }

    public openCalendar(): void {

        this.calendarStateSubject.next('opened');
    }

    public closeCalendar(): void {

        this.calendarStateSubject.next('closed');
    }

    public toggleCalendar(): void {

        if(!this.configurationSteps.date.enabled){

            return;
        }

        this.isCalendarOpened ? this.closeCalendar() : this.openCalendar();
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public participantsControl(index: number): AbstractControl {

        return this.participantsControls.controls[index];
    }

    public getConfigurationStepLabelClasses(configurationStep: ConfigurationStep): {[p: string]: boolean} {

        return {
            'active': configurationStep && configurationStep.enabled
        };
    }

    public getConfigurationStepDataElementClasses(configurationStep: ConfigurationStep): {[p: string]: boolean} {

        return {
            'disabled': configurationStep && !configurationStep.enabled
        };
    }

    public validateTravellerStep(): void {

        this.displayChildInformationsValidators = true;

        if(!this.configurationSteps.traveller.valid()){

            return;
        }

        this.configurationSteps.traveller.enabled = false;

        this.configurationSteps.date.enabled = true;

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('nbAdult', 'eq', this.offer.publics.includes('adult') ? this.countAdult.toString() : 0));

        filterBuilder.addField(new TextFilterField('nbChild', 'eq', this.offer.publics.includes('child') ? this.countChild.toString() : 0));

        this.loadFirstAvailabilityItemSourceCallback(this.offer, filterBuilder.serializedFilters).subscribe((item: OfferFirstDateAvailability): void => {

            this.start = item.firstDateAvailability.startOf('months');

            this._loadDates(this.start, this.start.clone().add(1, 'months').endOf('month'));
        });
    }

    public updateTravellerStep(): void {

        if(!this.configurationSteps.traveller.valid()){

            return;
        }

        this.configurationSteps.date.enabled = false;

        this.configurationSteps.traveller.enabled = true;

        this._resetDateStep();
    }

    get isCalendarOpened(): boolean {

        return this.calendarStateSubject.value === 'opened';
    }

    get isNavigationToPreviousMonthAllowed(): boolean {

        const now: Moment = moment();

        const date: Moment = this.start.clone();

        date.subtract(1, 'months');

        return date.isSameOrAfter(now.startOf('months'));
    }

    get isValidationDateSelectionValid(): boolean {

        return !!this.selection.start && !!this.selection.end;
    }

    get dayNames(): string[] {

        const items: {[locale: string]: string[]} = {
            fr: ['lu', 'ma', 'me', 'je', 've', 'sa', 'di'],
            en: ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
        };

        const locale: string = this.translationService.getUserLocale();

        return (locale in items) ? items[locale] : ['', '', '', '', '', '', ''];
    }

    get valid(): boolean {

        return Object.keys(this.configurationSteps).every((identifier: ConfigurationStepIdentifier): boolean => {

            return this.configurationSteps[identifier].valid();
        });
    }

    get item(): OfferDateEngineItem {

        return {
            start: this.displayedDates.start,
            end: this.displayedDates.end,
            nbAdult: this.countAdult,
            nbChild: this.countChild,
            publicPrice: this.publicPrice,
            options: this.options,
            participants: this.participants,
            type: this._type,
            availableStock: this._availableStock
        };
    }

    get isMine(): boolean {

        if(!this.user.society){

            return false;
        }

        return this.user.society.id === this.offer.society.id;
    }

    get countAdultValues(): number[] {

        const start: number = this.offer.presential.adultMin;

        const end: number = this.offer.presential.adultMax;

        const step: number = this.offer.presential.adultIncrementalStep;

        const arrayLength = step > 0 ? (Math.floor(((end - start) / step)) + 1) : 1;

        return [...Array(arrayLength).keys()].map(x => (x * step) + start);
    }

    get countChildValues(): number[] {

        const start: number = this.offer.presential.childMin;

        const end: number = this.offer.presential.childMax;

        const step: number = this.offer.presential.childIncrementalStep;

        const arrayLength = step > 0 ? (Math.floor(((end - start) / step)) + 1) : 1;

        return [...Array(arrayLength).keys()].map(x => (x * step) + start);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get participantsControls(): UntypedFormArray {

        return this.form.get('participants') as UntypedFormArray;
    }

    get participants(): BookingCompositionParticipant[] {

        return (this.form.get('participants').value as { birthDay: Moment }[]).map((participants: { birthDay: any }): BookingCompositionParticipant => {

            return {
                birthDay: participants.birthDay ? participants.birthDay.format('YYYY-MM-DD') : null,
                type: 'child',
                firstName: '',
                lastName: ''
            };
        });
    }

    get currentAvailability(): OfferAvailability {

        if(!this.isValidationDateSelectionValid){

            return null;
        }

        return this.availabilities[this.selection.start.format(DATE_FORMAT)];
    }

    get isValidNbPerson(): boolean {

        const max: number = this.offer.presential.max;

        return !max || (this.item.nbAdult + this.item.nbChild) <= this.offer.presential.max;
    }

    set type(value: BookingTypeType) {

        this._type = value;
    }

    set availableStock(value: boolean) {

        this._availableStock = value;
    }
}
