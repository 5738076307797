import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {OfferTranslationRequest} from "@core/shared/models/offer/offer-translation-request";

@Injectable({
    providedIn: 'root'
})
export class OfferTranslationService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {}

    public requestAPI(id: number, data: object): Observable<OfferTranslationRequest> {

        const url = `${this._apiService.getApiUrl(false, true)}/offers/${id}/translations/request`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferTranslationRequest>(url, data, {
            headers: headers
        });
    }

    public requestSummaryAPI(data: object): Observable<OfferTranslationRequest> {

        const url = `${this._apiService.getApiUrl(false, true)}/offers/translations/request/summary`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferTranslationRequest>(url, data, {
            headers: headers
        });
    }
}
