<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.option.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">{{ 'offer.option.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.option.name.value' | translate" [key]="'translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Titre -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.title')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.option.title.value' | translate" [key]="'translations.title'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prix unique HT -->

                <ng-container [matColumnDef]="getFilterColumnDef('uniquePrice.valueHT')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Prix unique TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('uniquePrice.valueTTC')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Montant Adulte TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('price.adult.ttc')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Montant Adulte HT -->

                <ng-container [matColumnDef]="getFilterColumnDef('price.adult.ht')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Markup -->

                <ng-container [matColumnDef]="getFilterColumnDef('markup')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Langues -->

                <ng-container [matColumnDef]="getFilterColumnDef('locales')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'offer.option.locales.value' | translate" [key]="'locales'" [operator]="'andlkin'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="locales"></app-core-select-array-multiple-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="editItem(element.id)" *ngIf="hasAccessEdit()">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="hasAccessDelete()">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button mat-button (click)="openDuplicationDialog(element.id)" *ngIf="hasAccessCreate() && hasAccessEdit()">
                            <mat-icon>launch</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.option.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).name}}</td>
                </ng-container>

                <!-- Titre -->

                <ng-container matColumnDef="translations.title">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.option.title.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).title}}</td>
                </ng-container>

                <!-- Prix unique HT -->

                <ng-container matColumnDef="uniquePrice.valueHT">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.option.uniquePrice.ht.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getParsedUniquePrice(getModel(element).uniquePrice, 'valueHT')}}</td>
                </ng-container>

                <!-- Prix unique HT -->

                <ng-container matColumnDef="uniquePrice.valueTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.option.uniquePrice.ttc.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getParsedUniquePrice(getModel(element).uniquePrice, 'valueTTC')}}</td>
                </ng-container>

                <!-- Montant Adulte TTC -->

                <ng-container matColumnDef="price.adult.ttc">
                    <th mat-header-cell *matHeaderCellDef>{{ getParsedPriceLabel('adult', 'TTC') }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).getParsedPriceByFilter('adult', 'TTC') }}</td>
                </ng-container>

                <!-- Montant Adulte HT -->

                <ng-container matColumnDef="price.adult.ht">
                    <th mat-header-cell *matHeaderCellDef>{{ getParsedPriceLabel('adult', 'HT') }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).getParsedPriceByFilter('adult', 'HT') }}</td>
                </ng-container>

                <!-- Markup -->

                <ng-container matColumnDef="markup">
                    <th mat-header-cell *matHeaderCellDef>{{'markup.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).parsedMarkup}}</td>
                </ng-container>

                <!-- Langues -->

                <ng-container matColumnDef="locales">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.option.locales.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getParsedLocales(getModel(element))}}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>

