import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '@core/shared/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {SocietyService} from '@core/shared/services/society.service';
import {SocietyService as SocietyServiceModel} from '@core/shared/models/society-service';
import {AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {REGEX_EMAIL, REGEX_PERCENT, REGEX_WEBSITE} from '@core/shared/models/regex';
import {ApiService} from '@core/shared/services/api.service';
import {FileConfig} from '@lib/form/fields/file/file.component';
import {forkJoin, Observable, of} from 'rxjs';
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from '@core/shared/models/translation';
import {FormService} from '@core/shared/services/form.service';
import {Country} from '@core/shared/models/country';
import {CountryService} from '@core/shared/services/country.service';
import {DATE_FORMAT, SUPPORTED_LOCALES} from '@app/data';
import {Address, AddressType} from '@core/shared/models/address';
import {Society, SocietyDocumentType} from '@core/shared/models/society';
import {User} from '@core/shared/models/user';
import {Router} from '@angular/router';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {LocationField, LocationFieldType} from '@core/shared/models/location';
import {Role} from '@core/shared/models/role';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TermsAndCondition, TermsAndConditionTranslation} from "@core/shared/models/terms-and-condition";
import {Access, AccessType} from "@core/shared/models/access";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {Subscription} from "@core/shared/models/subscription";
import {SubscriptionService} from "@core/shared/services/subscription.service";
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {InputDateConfig} from "@lib/form/fields/input-date/input-date.component";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {FormTabValidationItem, OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import {FormFieldGroupSelectChoice, FormFieldGroupSelectGroup} from "@core/shared/models/form/field/group-select-field";
import {OfferNetworkProviderService} from "@core/shared/services/offer/offer-network-provider.service";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {OfferNetworkProvider} from "@core/shared/models/offer/offer-network-provider";
import {MatLegacyOption, MatLegacyOptionSelectionChange as MatOptionSelectionChange} from "@angular/material/legacy-core";
import {TranslationService} from "@core/shared/services/translation.service";
import {map, tap} from "rxjs/operators";
import {restrictLocales} from "@core/shared/utils/locale";
import * as _moment from 'moment';
import {ServiceService} from "@core/shared/services/service.service";
import {parsePrice} from "@core/shared/utils/price";
import {Ubos} from "@core/shared/models/Ubo";
import {BankAccount} from "@core/shared/models/bankAccount";
import {Kyc} from "@core/shared/models/Kyc";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";
import {MarketplacePreferenceService} from "@core/shared/services/marketplace-preference.service";
import {OfferPermanentOption} from "@core/shared/models/offer/offer-permanent-option";
import {OfferPermanentOptionService} from "@core/shared/services/offer/offer-permanent-option.service";
import {
    DEFAULT_GROUP_DAY,
    DEFAULT_GROUP_STAY,
    DEFAULT_INDIVIDUAL_DAY,
    DEFAULT_INDIVIDUAL_STAY,
    SocietyCommissionType
} from "@core/shared/models/society-commission";
import {SOCIETY_TYPE_ITEMS, SocietyType, SocietyTypeItem} from "@core/shared/models/society-type-item";
import {SocietyInformation, SocietyInformationTranslation} from "@core/shared/models/society/society-information";
import {SocietyInformationService} from "@core/shared/services/society/society-information.service";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {SocietyChannelConfiguration} from "@core/shared/models/society/society-channel-configuration";
import {SocietyChannelConfigurationTranslation} from "@core/shared/models/society/society-channel-configuration/society-channel-configuration-translation";
import * as ClassicEditor from "@lib/ckeditor";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {OfferDurationType} from "@core/shared/models/offer/offer-duration";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {SocietyGroup} from "@core/shared/models/society-group";
import {NetworkProvider} from "@core/shared/models/network-provider";

const moment:any = _moment;
@Component({
    selector: 'app-core-page-user-update',
    templateUrl: './page-user-update.component.html',
    styleUrls: ['./page-user-update.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService
    ]
})
export class PageUserUpdateComponent implements OnInit {

    @ViewChild('tabGroup', {static: false}) tabGroup: MatTabGroup;

    @ViewChild('societyInformations', {static: false}) societyInformationsRef: TemplateRef<any>;

    @ViewChild('legalRepresentative', {static: false}) legalRepresentativeRef: TemplateRef<any>;

    @ViewChild('contactInformations', {static: false}) contactInformationsRef: TemplateRef<any>;

    @ViewChild('licence', {static: false}) licenceRef: TemplateRef<any>;

    @ViewChild('distributorAdministrationInformations', {static: false}) distributorAdministrationInformationsRef: TemplateRef<any>;

    @ViewChild('deactivateAccount', {static: false}) deactivateAccountRef: TemplateRef<any>;

    @ViewChild('services', {static: false}) servicesRef: TemplateRef<any>;

    @ViewChild('offerPermanentOptions', {static: false}) offerPermanentOptionsRef: TemplateRef<any>;

    public idSociety: number;

    public user: User;

    public roles: Role[] = [];

    public requiredDocuments: SocietyDocumentType[] = [];

    public societyLogoConfig: FileConfig;

    public societyAdministrationLogoConfig: FileConfig;

    public countries$: Observable<Country[]>;

    public locales$: Observable<LocaleItem[]>;

    public types$: Observable<SocietyTypeItem[]>;

    public differentBillingEmail: boolean = false;

    public differentBillingAddress: boolean = false;

    public userId: number;

    public offerCreatorSocieties$: Observable<Society[]>;

    public accesses: Access[] = [];

    public createdAt: string;

    public isAccountAdmin: boolean;

    public disableAddress: boolean = true;

    public disableAddressBilling: boolean = true;

    public subscriptionsSociety: SocietySubscription[] = [];

    public society: Society;

    public networkOfferCreatorMarketplacePreference: NetworkOfferCreator[] = [];

    public subscriptions: Subscription[] = [];

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public restrictedNetworkProviders: FormFieldGroupSelectGroup[] = [];

    public restrictedNetworkOfferCreators$: Observable<NetworkOfferCreator[]>;

    public requiredVatNumberCountryCodes: string[] = [];

    public societyProvider: string;

    public societyServices$: Observable<SocietyServiceModel[]>;

    public offerPermanentOptions$: Observable<OfferPermanentOption[]>;

    public documents$: Observable<{ type: string, status: string, errorStatus: string, errorMessage: string, documentType: string }[]>;

    public societyInformationTranslationBuilder: TranslationBuilder;

    public societySpokenLanguages: string[] = ['fr', 'en'];

    public otherSpokenLanguagesIdentifier: string = '_OTHER_';

    public societyPresentationEditor: any = ClassicEditor;

    public societyChannelConfigurationTranslationBuilder: TranslationBuilder;

    public editor = ClassicEditor;

    public customerTypologies: CustomerTypology[] = [];

    public durationTypes: OfferDurationType[] = [];

    public regions: OfferAttribute[] = [];

    public societyConnexionNbr: number = 0;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _apiService: ApiService,
        private _countryService: CountryService,
        private _dialog: MatDialog,
        private _serviceService: ServiceService,
        private _offerPermanentOptionService: OfferPermanentOptionService,
        private _networkProviderService: OfferNetworkProviderService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        private _marketplacePreferenceService: MarketplacePreferenceService,
        private _societyInformationService: SocietyInformationService,
        private _customerTypologyService: CustomerTypologyService,
        private _offerAttributeService: OfferAttributeService,
        public formService: FormService,
        public _subscriptionService: SubscriptionService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._loadOfferCreatorSocieties();

        this._loadCountries();

        this._loadLocales();

        this._loadTypes();

        this._initForm();

        this._initFileConfigs();

        this.user = this._userService.currentUser.value;

        this._activatedRoute.params.subscribe(params => {

            this.idSociety = params['id'];
        });

        this._activatedRoute.data.subscribe((data: { society: Society, accesses: Access[] }): void => {

            this.accesses = data.accesses;

            this.createdAt = data.society.createdAt;

            this.societyProvider = data.society.reference;

            this.society = data.society;

            this._loadRequiredDocuments();

            this._loadDocuments();

            this._loadSocietyServices();

            this._userService.getItemAPI(data.society.admin.id).subscribe((user: User): void => {

                // Mise à jour des adresses

                user.society.addresses.forEach((address: Address): void => {

                    const addressForm: UntypedFormGroup = this._formBuilder.group({
                        id: [address.id],
                        type: [address.type, [Validators.required]],
                        address: [address.address, [Validators.required]],
                        additionalAddress: [address.additionalAddress],
                        zipcode: [address.zipcode, [Validators.required]],
                        city: [address.city, [Validators.required]],
                        region: [address.region, [Validators.required]],
                        country: [address.country, [Validators.required]]
                    });

                    addressForm.get('country').valueChanges.subscribe((): void => {

                        addressForm.get('region').markAsTouched();
                        addressForm.get('region').updateValueAndValidity();
                    });

                    this.addressesForm.push(addressForm);
                });

                this.roles = user.roles;

                if (this.hasRole('ROLE_OFFER_CREATOR')) {

                    this._initSocietyPresentationEditor();

                    this.societyForm.addControl('information', this._formBuilder.group({
                        id: [null],
                        hasRseLabel: [false, [Validators.required]],
                        spokenLanguages: [[]],
                        hasOtherSpokenLanguages: [false, [Validators.required]],
                        customerTypologies: [[]],
                        durationTypes: [[]],
                        regions: [[]],
                        allRegions: [false, [Validators.required]],
                        translations: new UntypedFormArray([])
                    }));

                    this._initSocietyInformationTranslationForm();

                    this._initCustomerTypologies();

                    this._initDurationTypes();

                    this._initRegions();

                    this._subscriptionService.getItemsSubscription().subscribe((subscriptions : Subscription[]): void => {

                        this.subscriptions = subscriptions;
                    });

                    this._subscriptionService.getItemSubscriptionBySocietyWithoutPagination(data.society.id).subscribe((subscriptionsSociety: SocietySubscription[]): void => {

                        this.subscriptionsSociety = subscriptionsSociety;
                    });
                }

                this._loadCommissionFormControl();

                this.userId = user.id;

                var societyData = data.society;

                if (data.society.restrictedOfferCreators.length) {

                    societyData = Object.assign(data.society, {
                        restrictedOfferCreators: data.society.restrictedOfferCreators.map((item: Society): number => {
                            return item.id;
                        })
                    });
                }

                Object.assign(societyData, {
                    restrictedNetworkProviders: data.society.restrictedNetworkProviders.map((item: OfferNetworkProvider): number => {
                        return item.id;
                    }),
                    restrictedGroupNetworkProviders: data.society.restrictedGroupNetworkProviders.map((item: OfferGroupNetworkProvider): number => {
                        return item.id;
                    }),
                    restrictedNetworkOfferCreators: data.society.restrictedNetworkOfferCreators.map((item: NetworkOfferCreator): number => {
                        return item.id;
                    })
                });

                if(societyData.commission) {

                    const commissionType: SocietyCommissionType = societyData.commission.type;

                    Object.assign(societyData.commission, {
                        individualStay: commissionType === 'custom' && societyData.commission.individualStay ? (societyData.commission.individualStay * 100).toFixed(2) : DEFAULT_INDIVIDUAL_STAY,
                        individualDay: commissionType === 'custom' && societyData.commission.individualDay ? (societyData.commission.individualDay * 100).toFixed(2) : DEFAULT_INDIVIDUAL_DAY,
                        groupStay: commissionType === 'custom' && societyData.commission.groupStay ? (societyData.commission.groupStay * 100).toFixed(2) : DEFAULT_GROUP_STAY,
                        groupDay: commissionType === 'custom' && societyData.commission.groupDay ? (societyData.commission.groupDay * 100).toFixed(2) : DEFAULT_GROUP_DAY
                    });
                }

                this.societyForm.patchValue(societyData);

                this.adminForm.patchValue(user);

                this._initEvents();

                // Mise à jour des documents CGV

                user.society.termsAndConditions.forEach((termsAndCondition: TermsAndCondition): void => {

                    const control: AbstractControl = this._formBuilder.group({
                        id: termsAndCondition.id,
                        label: this.translationService.getFallbackTranslation(termsAndCondition.typology.translations).name,
                        typology: this._formBuilder.group({
                            id: [termsAndCondition.typology.id],
                        }),
                        translations: new UntypedFormArray([])
                    });

                    termsAndCondition.translations.forEach((translation: TermsAndConditionTranslation): void => {

                        (control.get('translations') as UntypedFormArray).push(this._formBuilder.group({
                            id: [translation.id],
                            locale: [translation.locale],
                            phone: [translation.phone],
                            email: [translation.email, Validators.pattern(REGEX_EMAIL)],
                            file: [translation.file, Validators.required]
                        }));
                    });

                    this.termsAndConditionsForm.push(control);
                });

                // Mise à jour de l'email de facturation

                this.differentBillingEmail = !!user.society.billingEmail && user.society.billingEmail !== user.society.email;

                // Mise à jour de l'adresse de facturation

                this.differentBillingAddress = !this.sameAddresses(this.getAddressByType('billing'), this.getAddressByType('mailing'));

                // Mise à jour de l'administration du compte

                if (user.societyAdmin && user.societyAdmin.id === data.society.id) {
                    this.isAccountAdmin = true;
                } else {
                    this.isAccountAdmin = false;
                }

                if (this.hasAllOfThisAccesses(['CHANNEL_CREATE_IS_MINE', 'CHANNEL_DELETE_IS_MINE'])) {

                    this.administrationForm.get('iframeCreation').patchValue(true);
                }

                if (!data.society.restrictedOfferCreators.length) {
                    this.administrationForm.get('offerRestriction').patchValue(false);
                }

                // Initialisation de la configuration des canaux

                this.societyForm.addControl('channelConfiguration', this.createSocietyChannelConfigurationControl(data.society.channelConfiguration));

                this._initTabItems();

                this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);

                this._loadRestrictedNetworkProviders();

                this._loadRestrictedNetworkOfferCreators();

                this._loadMarketplacePreferences(user);

                this._loadOfferPermanentOptions();

                this._changeDetectorRef.detectChanges();
            });

        });

    }

    private _loadCommissionFormControl(): void {

        if(this.isAvailableCommission) {

            this.societyForm.addControl('commission', this._formBuilder.group({
                id: [null],
                type: ['', [(control: UntypedFormControl) => {

                    return (!control.value || !control.value.length) ? { isRequired: {valid: false} } : null;
                }]],
                individualStay: [null, [Validators.pattern(REGEX_PERCENT), (control: UntypedFormControl) => {

                    if(!control.parent || !control.parent.get('type') || control.parent.get('type').value === 'standard') {

                        return null;
                    }

                    return !control.value ? { isRequired: {valid: false} } : null;
                }]],
                individualDay: [null, [Validators.pattern(REGEX_PERCENT), (control: UntypedFormControl) => {

                    if(!control.parent || !control.parent.get('type') || control.parent.get('type').value === 'standard') {

                        return null;
                    }

                    return !control.value ? { isRequired: {valid: false} } : null;
                }]],
                groupStay: [null, [Validators.pattern(REGEX_PERCENT), (control: UntypedFormControl) => {

                    if(!control.parent || !control.parent.get('type') || control.parent.get('type').value === 'standard') {

                        return null;
                    }

                    return !control.value ? { isRequired: {valid: false} } : null;
                }]],
                groupDay: [null, [Validators.pattern(REGEX_PERCENT), (control: UntypedFormControl) => {

                    if(!control.parent || !control.parent.get('type') || control.parent.get('type').value === 'standard') {

                        return null;
                    }

                    return !control.value ? { isRequired: {valid: false} } : null;
                }]],
                incomingPayment: [false]
            }));

            this.societyForm.get('commission').get('type').valueChanges.subscribe((value: SocietyCommissionType): void => {

                this.societyForm.get('commission').patchValue({
                    individualStay: value === 'standard' ? DEFAULT_INDIVIDUAL_STAY : null,
                    individualDay: value === 'standard' ? DEFAULT_INDIVIDUAL_DAY : null,
                    groupStay: value === 'standard' ? DEFAULT_GROUP_STAY : null,
                    groupDay: value === 'standard' ? DEFAULT_GROUP_DAY : null
                });

                this.societyForm.get('commission').updateValueAndValidity();
            });
        }
    }

    private _loadSocietyServices(): void {

        const params: string[] = [
            'isValid[eq]=1'
        ];

        this.societyServices$ = this._serviceService.getItemsServiceBySocietyWithoutPagination(this.society.id, params);
    }

    private _loadOfferPermanentOptions(): void {

        if(!this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])) {

            return;
        }

        this.offerPermanentOptions$ = this._offerPermanentOptionService.getItemsAPI(this.society.id);
    }

    private _loadDocuments(): void {

        const observables: Observable<BankAccount | Kyc | Ubos>[] = [];

        if(this.isRequiredDocument('identity')) {

            observables.push(this._serviceService.getKycItemAPI(this.society.id, 'identity'));
        }

        if(this.isRequiredDocument('registration')) {

            observables.push(this._serviceService.getKycItemAPI(this.society.id, 'registration'));
        }

        if(this.isRequiredDocument('association')) {

            observables.push(this._serviceService.getKycItemAPI(this.society.id, 'association'));
        }

        if(this.isRequiredDocument('shareholder')) {

            observables.push(this._serviceService.getKycItemAPI(this.society.id, 'shareholder'));
        }

        if(this.isRequiredDocument('ubo-declaration')) {

            observables.push(this._serviceService.getUboItemAPI(this.society.id));
        }

        if(this.isRequiredDocument('bank-account')) {

            observables.push(this._serviceService.getBankAccountItemAPI(this.society.id));
        }

        this.documents$ = forkJoin(observables).pipe(map((items) => {

            return items.map((item) => {

                switch(true) {

                    case ('type') in item:

                        return {
                            type: (item as Kyc).type,
                            status: (item as Kyc).status,
                            errorStatus: (item as Kyc).errorStatus,
                            errorMessage: (item as Kyc).errorMessage,
                            documentType: 'kyc'
                        }

                    case ('ubos') in item:

                        return {
                            type: 'ubo',
                            status: (item as Ubos).status,
                            errorStatus: (item as Ubos).errorStatus,
                            errorMessage: (item as Ubos).errorMessage,
                            documentType: 'ubo'
                        }

                    case ('details') in item:

                        return {
                            type: 'bankAccount',
                            status: (item as BankAccount).details ? 'validated' : 'notSubmitted',
                            errorStatus: null,
                            errorMessage: null,
                            documentType: 'bankAccount'
                        }
                }

                return null;

            });
        }));
    }

    private _loadSocietyInformation(): void {

        this._societyInformationService.getItemAPI(this.society.id).subscribe((item: SocietyInformation): void => {

            this.societyInformationForm.patchValue(Object.assign(item, {
                regions: item.allRegions ? [...[{id: null}],...this.regions] : item.regions,
                spokenLanguages: item.hasOtherSpokenLanguages ? item.spokenLanguages.concat(this.otherSpokenLanguagesIdentifier) : item.spokenLanguages
            } as Partial<SocietyInformation>));

            item.translations.forEach((translation: SocietyInformationTranslation): void => {

                const group: UntypedFormGroup = this.societyInformationTranslationBuilder.addItemControl(this.societyInformationTranslationBuilder.getLocaleItem(translation.locale), translation);

                group.addControl('id', this._formBuilder.control(translation.id));
            });

            if(this.hasRole('ROLE_OFFER_CREATOR')){

                this.societyInformationForm.get('hasRseLabel').valueChanges.subscribe((): void => {

                    this.societyInformationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                        control.get('rseLabel').patchValue(null);

                        control.get('rseLabel').markAsTouched();
                    });
                });

                this.societyInformationForm.get('spokenLanguages').valueChanges.subscribe((value: string[]): void => {

                    if(value.includes(this.otherSpokenLanguagesIdentifier)){

                        this.societyInformationForm.get('hasOtherSpokenLanguages').patchValue(true);

                        this.societyInformationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                            control.get('spokenLanguages').markAsTouched();
                        });
                    }
                    else{

                        this.societyInformationForm.get('hasOtherSpokenLanguages').patchValue(false);

                        this.societyInformationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                            control.get('spokenLanguages').patchValue(null);
                        });
                    }

                    this._changeDetectorRef.detectChanges();
                });
            }
        });
    }

    private _loadOfferCreatorSocieties(): void {

        const roleFilter: ArrayFilterField = new ArrayFilterField('admin.roles', 'andlkin', 'ROLE_OFFER_CREATOR');

        this.offerCreatorSocieties$ = this._societyService.getItemsAPI([roleFilter.serialize]);
    }

    private _loadCountries(): void {

        const params: string[] = [
            `paymentSupported[eq]=1`
        ];

        this.countries$ = this._countryService.getItemsAPI(params).pipe(tap((countries: Country[]): void => {

            this.requiredVatNumberCountryCodes = countries.filter((country: Country) => {

                return country.requireSocietyVatNumber;

            }).map((country: Country) => {

                return country.code;

            });

            this.societyForm.get('vatNumber').updateValueAndValidity();

            this.societyForm.get('vatNumber').markAsTouched();

        }));
    }

    private _loadRestrictedNetworkProviders(): void {

        const groupNetworkProviderValue: number[] = [...this.societyForm.get('restrictedGroupNetworkProviders').value];

        const networkProviderValue: number[] = [...this.societyForm.get('restrictedNetworkProviders').value];

        this._networkProviderService.getItemsAPI().subscribe((groupNetworkProviders: OfferGroupNetworkProvider[]): void => {

            this.restrictedNetworkProviders = groupNetworkProviders.map((groupNetworkProvider: OfferGroupNetworkProvider): FormFieldGroupSelectGroup => {

                return {
                    id: groupNetworkProvider.id,
                    name: groupNetworkProvider.label,
                    choices: groupNetworkProvider.networkProviders.map((networkProvider: OfferNetworkProvider): FormFieldGroupSelectChoice => {

                        if(groupNetworkProviderValue.includes(groupNetworkProvider.id) && !networkProviderValue.includes(networkProvider.id)){

                            networkProviderValue.push(networkProvider.id);
                        }

                        return {
                            id: networkProvider.id,
                            name: networkProvider.name
                        };
                    })
                };
            });

            this.societyForm.get('restrictedNetworkProviders').patchValue(networkProviderValue);
        });
    }

    private _loadRestrictedNetworkOfferCreators(): void {

        this.restrictedNetworkOfferCreators$ = this._networkOfferCreatorService.getItemsAPI();
    }

    private _loadMarketplacePreferences(user: User): void {

        const networkOfferCreatorsMarketplacePreference: MarketplacePreference = this._marketplacePreferenceService.getItemByFilterFromCollection('society.networkOfferCreators.id', user.marketplacePreferences);

        if(networkOfferCreatorsMarketplacePreference) {

            const networkOfferCreatorsIds: number[] = (networkOfferCreatorsMarketplacePreference.value as string).replace(/[\[\]]/g, '').split(',').map((item: string): number => { return parseInt(item); });

            const params: string[] = [];

            networkOfferCreatorsIds.forEach((id: number): void => {

                params.push(`id[in][]=${id}`);
            })

            this._networkOfferCreatorService.getItemsAPI(params).subscribe((items: NetworkOfferCreator[]): void => {

                this.networkOfferCreatorMarketplacePreference = items;

            });

        }
    }

    private _loadLocales(): void {

        this.locales$ = of(LOCALE_ITEMS).pipe(
            map((localeItems: LocaleItem[] ):LocaleItem[] => restrictLocales(localeItems) )
        );
    }

    private _loadTypes(): void {

        this.types$ = of(SOCIETY_TYPE_ITEMS);
    }

    private _loadRequiredDocuments(): void {

        this.requiredDocuments = this.society.requiredMangoPayDocuments;
    }

    private _initTabItems(): void {

        const tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

        tabItems.push(...[
            {
                tag: 'societyInformations',
                label: 'form.user.offerCreator.title.societyInfo.value',
                template: this.societyInformationsRef
            },
            {
                tag: 'legalRepresentative',
                label: 'society.legalRepresentative.value',
                template: this.legalRepresentativeRef
            },
            {
                tag: 'contactInformations',
                label: 'form.user.offerCreator.title.contactInfo.value',
                template: this.contactInformationsRef
            },
            {
                tag: 'distributorAdministrationInformations',
                label: 'form.user.offerDistributor.title.account.value',
                template: this.distributorAdministrationInformationsRef
            }
        ]);

        switch (true) {

            // Rôle Créateur d'offre & Distributeur d'offre

            case this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                tabItems.push(...[
                    {
                        tag: 'licence',
                        label: 'form.user.offerCreator.title.licence.value',
                        template: this.licenceRef
                    }
                ]);

                break;

            // Rôle Créateur d'offre

            case this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                tabItems.push(...[
                    {
                        tag: 'licence',
                        label: 'form.user.offerCreator.title.licence.value',
                        template: this.licenceRef
                    }
                ]);

                break;
        }

        tabItems.push(...[
            {
                tag: 'services',
                label: 'service.plural.value',
                template: this.servicesRef
            }
        ]);

        if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])) {

            tabItems.push(...[
                {
                    tag: 'offerPermanentOptions',
                    label: 'form.user.offerCreator.title.permanentOptions.value',
                    template: this.offerPermanentOptionsRef
                }
            ]);
        }

        tabItems.push(...[
            {
                tag: 'deactivateAccount',
                label: 'subscription.form.delete.title.value',
                template: this.deactivateAccountRef
            }
        ]);

        this.tabItems = tabItems;
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((items: CustomerTypology[]): void => {

            const societyTermsAndConditionTypologies: CustomerTypology[] = this.society.termsAndConditions.map((termsAndCondition: TermsAndCondition): CustomerTypology => {

                return termsAndCondition.typology;
            });

            // Filtre des types de clientèles en fonction des documents CGV définis pour la société

            this.customerTypologies = items.filter((customerTypology: CustomerTypology): boolean => {

                return societyTermsAndConditionTypologies.some((item: CustomerTypology): boolean => {

                    return customerTypology.id === item.id;
                });
            });
        });
    }

    private _initDurationTypes(): void {

        this.durationTypes = ['day', 'stay'];
    }

    private _initRegions(): void {

        const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', 'region');

        const params: string[] = [
            `sort[label]=ASC`,
            typeFilter.serialize
        ];

        this._offerAttributeService.getItemsAPI(params).subscribe((regions: OfferAttribute[]): void => {

            this.regions = regions;

            this._loadSocietyInformation();
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            society: this._formBuilder.group({
                admin: this._formBuilder.group({
                    accesses: [[]],
                    admin: [null],
                    civility: ['', Validators.required],
                    username: [''],
                    lastName: ['', Validators.required],
                    firstName: ['', Validators.required],
                    email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
                    switchboardPhone: ['', [Validators.required]],
                    directPhone: [''],
                    cellphone: [''],
                    service: [''],
                    comment: [''],
                    society: [null],
                    societyAdmin: [null],
                    roles: [[]],
                    locale: ['', [Validators.required]],
                    timezone: [null, [Validators.required]]
                }),
                locale: ['', [Validators.required]],
                name: ['', Validators.required],
                mainWebsite: ['', [Validators.required, Validators.pattern(REGEX_WEBSITE)]],
                identificationNumber: ['', [Validators.required]],
                registration: ['', [(control: UntypedFormControl) => {

                    return (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && (!control.value || !control.value.length)) ? {isRequired: {valid: false}} : null;
                }]],
                corporateName: ['', Validators.required],
                type: ['', Validators.required],
                capital: [''],
                businessActivityCode: [''],
                rcsRegistration: [''],
                financialGuarantee: ['', [(control: UntypedFormControl) => {

                    return (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && (!control.value || !control.value.length)) ? {isRequired: {valid: false}} : null;
                }]],
                insurancePolicy: ['', [(control: UntypedFormControl) => {

                    return (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && (!control.value || !control.value.length)) ? {isRequired: {valid: false}} : null;
                }]],
                vatNumber: ['', [(control: AbstractControl): { [key: string]: any } => {

                    if(!this.form) {

                        return null;
                    }

                    const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

                    if(!mailingGroup) {

                        return null;
                    }

                    const countryCode: string = mailingGroup.get('country').value;

                    if(this.requiredVatNumberCountryCodes.includes(countryCode) && (!control.value || !control.value.length)) {

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                logo: [null],
                administrationLogo: [null],
                addresses: new UntypedFormArray([]),
                email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
                billingEmail: ['', [Validators.pattern(REGEX_EMAIL), (control: UntypedFormControl) => {

                    return (this.hasRole('ROLE_OFFER_CREATOR') && this.differentBillingEmail && !control.value.length) ? {isRequired: {valid: false}} : null;
                }]],
                phone: ['', [Validators.required]],
                termsAndConditions: new UntypedFormArray([], [Validators.nullValidator]),
                restrictedOfferCreators: [[]],
                restrictedNetworkOfferCreators: [[]],
                restrictedNetworkProviders: [[]],
                restrictedGroupNetworkProviders: [[]],
                hasAccessPublishedOffer: [false],
                hasAccessRestrictedOffer: [false],
                hasRestrictedNetworkProvider: [false],
                hasRestrictedNetworkOfferCreator: [false],
                hasAccessCreateChannelShowcase: [false],
                hasAccessAPI: [false],
                restrictedOfferQuota: [null, [(control: UntypedFormControl) => {

                    return (!!control.value && control.value < 0) ? {pattern: {valid: false}} : null;
                }]],
                legalRepresentative: this._formBuilder.group({
                    id: [null],
                    civility: ['', [Validators.required]],
                    firstName: ['', [Validators.required]],
                    lastName: ['', [Validators.required]],
                    birthDay: ['', [Validators.required]],
                    nationality: ['', [Validators.required]],
                    address: ['', [Validators.required]],
                    additionalAddress: [''],
                    zipcode: ['', [Validators.required]],
                    city: ['', [Validators.required]],
                    region: ['', [Validators.required]],
                    country: ['', [Validators.required]],
                    phone: ['']
                })
            }),
            administration: this._formBuilder.group({
                iframeCreation: [false],
                offerRestriction: [true]
            })
        });

        this.societyForm.get('legalRepresentative').get('country').valueChanges.subscribe((): void => {

            this.societyForm.get('legalRepresentative').get('region').markAsTouched();
            this.societyForm.get('legalRepresentative').get('region').updateValueAndValidity();
        });

        this.administrationForm.get('iframeCreation').valueChanges.subscribe((value: boolean): void => {

            const accessTypes: AccessType[] = [
                'CHANNEL_CREATE_IS_MINE',
                'CHANNEL_DELETE_IS_MINE'
            ];

            this._handleAccesses(this.adminForm.get('accesses'), accessTypes, value);
        });

        this.administrationForm.get('offerRestriction').valueChanges.subscribe((): void => {
            this.societyForm.get('restrictedOfferCreators').patchValue([]);
        });

        this.societyForm.get('hasAccessRestrictedOffer').valueChanges.subscribe((): void => {
            this.societyForm.get('restrictedOfferQuota').patchValue(null);
        });

        this.societyForm.get('hasRestrictedNetworkProvider').valueChanges.subscribe((): void => {

            this.societyForm.get('restrictedNetworkProviders').patchValue([]);
        });

        this.societyForm.get('hasRestrictedNetworkOfferCreator').valueChanges.subscribe((): void => {

            this.societyForm.get('restrictedNetworkOfferCreators').patchValue([]);
        });

        this.formService.submitCallback = (): void => {

            this._patchAddress();

            this._patchAdminEmail();

            const dataSociety = this.societyForm.value;

            dataSociety.termsAndConditions.forEach((termsAndCondition: TermsAndCondition): void => {
                termsAndCondition.translations.forEach((termsAndConditionTranslation: TermsAndConditionTranslation): void => {
                    //@ts-ignore
                    termsAndConditionTranslation.phone = (termsAndConditionTranslation.phone?.e164Number) ? termsAndConditionTranslation.phone?.e164Number : termsAndConditionTranslation.phone;
                });
            });

            dataSociety.phone = (this.societyForm.get('phone').value?.e164Number) ? this.societyForm.get('phone').value?.e164Number : this.societyForm.get('phone').value;

            dataSociety.legalRepresentative.phone = (this.societyLegalRepresentativeControl.get('phone').value?.e164Number) ? this.societyLegalRepresentativeControl.get('phone').value?.e164Number : this.societyLegalRepresentativeControl.get('phone').value;

            const societyData: object = Object.assign(dataSociety, {
                    restrictedOfferCreators: (this.societyForm.get('restrictedOfferCreators').value as number[]).map((item: number): { id: number } => {
                        return {
                            id: item
                        };
                    }),
                    hasAccessCreateChannelShowcase: this.hasAccess('CHANNEL_CREATE_IS_MINE') ? this.societyForm.get('hasAccessCreateChannelShowcase').value : false,
                    channelConfiguration: this.hasAccess('CHANNEL_CREATE_IS_MINE') && this.societyForm.get('hasAccessCreateChannelShowcase').value ? this.societyForm.get('channelConfiguration').value : null
                } as Partial<Society>);

            Object.assign(dataSociety, {
                restrictedNetworkProviders: (this.societyForm.get('restrictedNetworkProviders').value as number[]).map((id: number): { id: number } => {

                    return {
                        id: id
                    };
                }),
                restrictedGroupNetworkProviders: this.restrictedNetworkProviders.filter((group: FormFieldGroupSelectGroup): boolean => {

                    return group.choices.every((choice: FormFieldGroupSelectChoice): boolean => {

                        return (this.societyForm.get('restrictedNetworkProviders').value as number[]).includes(choice.id);
                    });
                }),
                hasRestrictedNetworkOfferCreator: Boolean((this.societyForm.get('restrictedNetworkOfferCreators').value as number[]).length),
                restrictedNetworkOfferCreators: (this.societyForm.get('restrictedNetworkOfferCreators').value as number[]).map((id: number): { id: number } => {

                    return {
                        id: id
                    };
                })
            });

            if(this.isAvailableCommission) {

                const commissionType: SocietyCommissionType = dataSociety.commission.type;

                Object.assign(dataSociety.commission, {
                    individualStay: commissionType === 'custom' && dataSociety.commission.individualStay ? (dataSociety.commission.individualStay / 100).toFixed(4) : null,
                    individualDay: commissionType === 'custom' && dataSociety.commission.individualDay ? (dataSociety.commission.individualDay / 100).toFixed(4) : null,
                    groupStay: commissionType === 'custom' && dataSociety.commission.groupStay ? (dataSociety.commission.groupStay / 100).toFixed(4) : null,
                    groupDay: commissionType === 'custom' && dataSociety.commission.groupDay ? (dataSociety.commission.groupDay / 100).toFixed(4) : null
                });
            }

            const userData: object = this.adminForm.value;
            // @ts-ignore
            userData.directPhone = (this.adminForm.get('directPhone').value?.e164Number) ? this.adminForm.get('directPhone').value?.e164Number :  this.adminForm.get('directPhone').value;
            // @ts-ignore
            userData.cellphone = (this.adminForm.get('cellphone').value?.e164Number) ? this.adminForm.get('cellphone').value?.e164Number : this.adminForm.get('cellphone').value;
            // @ts-ignore
            userData.switchboardPhone = (this.adminForm.get('switchboardPhone').value?.e164Number) ? this.adminForm.get('switchboardPhone').value?.e164Number : this.adminForm.get('switchboardPhone').value;

            const observables: { society: Observable<Society>, user: Observable<User>, societyInformation?: Observable<SocietyInformation> } = {
                society: this._societyService.updateItemAPI(this.idSociety, societyData),
                user: this._userService.updateItemAPI(this.userId, userData)
            };

            if(this.hasRole('ROLE_OFFER_CREATOR')){

                observables.societyInformation = (this._societyInformationService.updateItemAPI(this.society.id, Object.assign(this.societyInformationForm.value, {
                    regions: this.societyInformationForm.get('allRegions').value ? [] : (this.societyInformationForm.get('regions').value as OfferAttribute[]).filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    }),
                    spokenLanguages: (this.societyInformationForm.get('spokenLanguages').value as string[]).filter((item: string): boolean => {

                        return ![this.otherSpokenLanguagesIdentifier].includes(item);
                    })
                })));
            }

            forkJoin(observables).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.edit.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _initSocietyInformationTranslationForm(): void {

        this.societyInformationTranslationBuilder = new TranslationBuilder(this._formBuilder);

        this.societyInformationTranslationBuilder.form = this.societyInformationForm;

        this.societyInformationTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                presentation: [null],
                spokenLanguages: [null, [(control: FormControl) => {

                    if(!(this.societyInformationForm.get('hasOtherSpokenLanguages').value)){

                        return null;
                    }

                    if(!!control.value && Boolean(control.value.length)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                rseLabel: [null, [(control: FormControl) => {

                    if(!this.societyInformationForm.get('hasRseLabel').value){

                        return null;
                    }

                    if(!!control.value && Boolean(control.value.length)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]]
            });
        };
    }

    private _initEvents(): void {

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        mailingGroup.get('region').valueChanges.subscribe(() => {

            billingGroup.get('region').patchValue( mailingGroup.value.region );

            billingGroup.updateValueAndValidity();

        });

        mailingGroup.get('country').valueChanges.subscribe((): void => {

            this.societyForm.get('vatNumber').updateValueAndValidity();

            this.societyForm.get('vatNumber').markAsTouched();

        });
    }

    private  _patchAddress(): void {
        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        if(!this.differentBillingAddress){

            billingGroup.patchValue({
                address: mailingGroup.value.address,
                additionalAddress: mailingGroup.value.additionalAddress,
                zipcode: mailingGroup.value.zipcode,
                city: mailingGroup.value.city,
                region: mailingGroup.value.region,
                country: mailingGroup.value.country
            });
        }
    }

    private _patchAdminEmail(): void{

        const emailGroup: AbstractControl = this.adminForm.get('email');

        const usernameGroup: AbstractControl = this.adminForm.get('username');

        if (usernameGroup.value !== emailGroup.value){

            usernameGroup.patchValue(emailGroup.value);

        }

    }

    private _initFileConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg'}).subscribe((help: string): void => {

            this.societyLogoConfig = {
                id: 'logo',
                gallery: {
                    id: null,
                    type: "file",
                    context: 'society_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: help
            };
        });

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.societyAdministrationLogoConfig = {
                id: 'administrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_administration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: this._translateService.instant('society.administrationLogo.form.image.help.value')
            };
        });
    }

    private _initSocietyPresentationEditor(): void {

        this.societyPresentationEditor.defaultConfig.toolbar.items = [
            'Heading',
            '|',
            'FontColor',
            '|',
            'Bold',
            'Italic',
            'Underline',
            '|',
            'Alignment',
            'BulletedList',
            'Indent',
            'Outdent',
            '|',
            'Undo',
            'Redo'
        ];

        this.societyPresentationEditor.defaultConfig.fontColor = {
            colors : [
                {
                    "color" : "#000000",
                    "label" : "Noir"
                },
                {
                    "color" : "#1d2028",
                    "label" : "Gris foncé"
                },
                {
                    "color" : "#969696",
                    "label" : "Gris"
                },
                {
                    "color" : "#fafafa",
                    "label" : "Gris clair"
                },
                {
                    "color" : "#FFFFFF",
                    "label" : "Blanc",
                    "hasBorder" : true
                },
                {
                    "color" : "#34e0a1",
                    "label" : "Vert"
                },
                {
                    "color" : "#208d65",
                    "label" : "Vert foncé"
                },
                {
                    "color" : "#d9e2df",
                    "label" : "Vert clair"
                }
            ]
        };

        this.societyPresentationEditor.defaultConfig.heading = {
            options: [
                {
                    model: "heading3",
                    view: {
                        name : "h3",
                        classes : "titre_h3"
                    },
                    title: "ckeditor.heading.title.h3.value",
                    class: "titre_h3"
                },
                {
                    model: "paragraph",
                    title: "ckeditor.heading.title.text.value",
                    class: "txt"
                }
            ]
        };
    }

    private _handleAccesses(control: AbstractControl, accessTypes: AccessType[], value: boolean): void {

        const accesses = this.adminForm.get('accesses').value as Access[];

        if (value) {

            accessTypes.forEach((accessType: AccessType): void => {

                if (this.hasAccess(accessType)) {

                    return;
                }

                const matchAccess: Access = this.accesses.find((access: Access): boolean => {

                    return access.tag === accessType;
                });

                accesses.push(matchAccess);
            });
        } else {

            accessTypes.forEach((accessType: AccessType): void => {

                if (!this.hasAccess(accessType)) {

                    return;
                }

                const accessIndex: number = accesses.findIndex((access: Access): boolean => {

                    return access.tag === accessType;
                });

                accesses.splice(accessIndex, 1);
            });
        }

        control.patchValue(accesses);
    }

    public patchSocietyAdmin(value: object | null): void {

        this.adminForm.patchValue({
            societyAdmin: value
        });
    }

    public selectAddress(index: number): void {

        if ((this.getAddressControl(index).get('type').value as AddressType) === 'mailing') {
            this.disableAddress = false;
        } else {
            this.disableAddressBilling = false;
        }
    }

    public resetAddresses(): void {

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        const childControlsToUpdate: AbstractControl[] = [
            billingGroup.get('address'),
            billingGroup.get('additionalAddress'),
            billingGroup.get('zipcode'),
            billingGroup.get('city'),
            billingGroup.get('region'),
            billingGroup.get('country')
        ];

        childControlsToUpdate.forEach((control: AbstractControl): void => {

            control.clearValidators();
        });

        if (this.differentBillingAddress) {

            billingGroup.get('address').setValidators([Validators.required]);
            billingGroup.get('zipcode').setValidators([Validators.required]);
            billingGroup.get('city').setValidators([Validators.required]);
            billingGroup.get('region').setValidators([Validators.required]);
            billingGroup.get('country').setValidators([Validators.required]);
            billingGroup.patchValue({
                address: '',
                additionalAddress: '',
                zipcode: '',
                city: '',
                region: '',
                country: ''
            });

        } else {

            billingGroup.patchValue({
                address: mailingGroup.value.address,
                additionalAddress: mailingGroup.value.additionalAddress,
                zipcode: mailingGroup.value.zipcode,
                city: mailingGroup.value.city,
                region: mailingGroup.value.region,
                country: mailingGroup.value.country
            });
        }

        childControlsToUpdate.forEach((control: AbstractControl): void => {

            control.updateValueAndValidity();

        });
    }

    public isAddressToDisplay(type: AddressType): boolean {

        switch (type) {

            case "mailing":

                return true;

            case "billing":
                return this.differentBillingAddress;
            default:

                return false;
        }
    }

    public getAddressByType(type: AddressType): Address {

        const addresses: Address[] = this.addressesForm.value;

        return addresses.find((address: Address): boolean => {

            return address.type === type;
        });
    }

    public getAddressControlByType(type: AddressType): AbstractControl {

        return this.addressesForm.controls.find((control: AbstractControl): boolean => {

            return (control.value as Address).type === type;
        });
    }

    public getAddressControlIndexByType(type: AddressType): number {

        return this.addressesForm.controls.findIndex((control: AbstractControl): boolean => {

            return (control.value as Address).type === type;
        });
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getAddressControl(index: number): AbstractControl {

        return this.addressesForm.controls[index];
    }

    public sameAddresses(addressA: Address, addressB: Address): boolean {

        const addressAValues: object = {
            address: addressA.address,
            additionalAddress: addressA.additionalAddress,
            zipcode: addressA.zipcode,
            city: addressA.city,
            region: addressA.region,
            country: addressA.country
        };

        const addressBValues: object = {
            address: addressB.address,
            additionalAddress: addressB.additionalAddress,
            zipcode: addressB.zipcode,
            city: addressB.city,
            region: addressB.region,
            country: addressB.country
        };

        return JSON.stringify(addressAValues) === JSON.stringify(addressBValues);
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ]
    }

    public resetPasswordDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('user.password.reset.value'),
                content: this._translateService.instant('user.password.reset.confirmation.value')
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            const data: object = {
                email: this.adminForm.get('email').value
            };

            this._userService.resetAccountPasswordRequestAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.password.reset.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            });
        });
    }

    public desactivateAccountDialog(): void {

        let title: string = '';

        let content: string = '';

        switch (true) {

            case this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                title = this._translateService.instant('subscription.form.delete.title.value');

                content = this._translateService.instant(`subscription.form.delete.message.offerCreatorDistributor.value`, {
                    lastname: this.adminForm.get('lastName').value,
                    firstname: this.adminForm.get('firstName').value
                });

                break;

            case this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                title = this._translateService.instant('subscription.form.delete.title.value');

                content = this._translateService.instant(`subscription.form.delete.message.offerCreator.value`, {
                    lastname: this.adminForm.get('lastName').value,
                    firstname: this.adminForm.get('firstName').value
                });

                break;

            case this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR'):

                title = this._translateService.instant('subscription.form.delete.title.value');

                content = this._translateService.instant(`subscription.form.delete.message.offerDistributor.value`, {
                    lastname: this.adminForm.get('lastName').value,
                    firstname: this.adminForm.get('firstName').value
                });

                break;

            case this.hasRole('ROLE_PROVIDER'):

                title = this._translateService.instant('subscription.form.delete.title.value');

                content = this._translateService.instant(`subscription.form.delete.message.provider.value`, {
                    lastname: this.adminForm.get('lastName').value,
                    firstname: this.adminForm.get('firstName').value
                });

                break;

            case this.hasOneOfThisRoles(['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']):

                title = this._translateService.instant('subscription.form.delete.title.value');

                content = this._translateService.instant(`subscription.form.delete.message.value`, {
                    lastname: this.adminForm.get('lastName').value,
                    firstname: this.adminForm.get('firstName').value
                });

                break;
        }

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyService.deleteItemAPI(this.idSociety).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('subscription.form.delete.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['account/user/list']);

            });
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/user/list']);
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasAccess(accessType: AccessType): boolean {

        const userAccessType: AccessType[] = (this.adminForm.get('accesses').value as Access[]).map((access: Access): AccessType => {

            return access.tag;
        });

        return userAccessType.indexOf(accessType) >= 0;
    }

    public hasAllOfThisAccesses(accesses: AccessType[]): boolean {

        const notMatches: AccessType[] = accesses.filter((item: AccessType): boolean => {

            return !this.hasAccess(item);
        });

        return !notMatches.length;
    }

    public handleGroupNetworkProviderSelection(items: FormFieldGroupSelectChoice[], event: Event): void {

        const isChecked: boolean = (event.currentTarget as HTMLInputElement).checked;

        const value: number[] = [...this.societyForm.get('restrictedNetworkProviders').value];

        items.forEach((item: FormFieldGroupSelectChoice) => {

            const alreadyChecked: boolean = value.includes(item.id);

            if (isChecked) {

                if (!alreadyChecked) {

                    value.push(item.id);
                }
            } else {

                if (alreadyChecked) {

                    const index: number = value.findIndex((x: number): boolean => {

                        return x === item.id;
                    });

                    value.splice(index, 1);
                }
            }
        });

        this.societyForm.get('restrictedNetworkProviders').patchValue(value);
    }

    public handleNetworkProviderSelection(groupCheckbox: HTMLInputElement, items: FormFieldGroupSelectChoice[], event: MatOptionSelectionChange): void {

        setTimeout((): void => {

            const value: number[] = this.societyForm.get('restrictedNetworkProviders').value;

            if(event.source.selected){

                const allItemsChecked: boolean = items.every((item: FormFieldGroupSelectChoice): boolean => {

                    return value.includes(item.id);
                });

                if(allItemsChecked){

                    groupCheckbox.checked = true;
                }
            }
            else{

                groupCheckbox.checked = false;
            }
        });
    }

    public isRequiredDocument(type: SocietyDocumentType): boolean {

        return this.requiredDocuments.some((item: SocietyDocumentType): boolean => {

            return type === item;
        });
    }

    public getDocumentLabel(type: string): string {

        const societyType: SocietyType = this.society.type;

        const customAssociationLabel: boolean = ['registration', 'association'].includes(type) && societyType === 'association';

        return this._translateService.instant( `mangopay.document.type.${type}.${customAssociationLabel ? (societyType + '.') : ''}value`);
    }

    public userHasOneOfRole(roles: Role[]){

        return roles.some((role: Role): boolean => {

            return this.userHasRole(role);
        });
    }

    public userHasRole(role: Role) {

        return this.user.roles.indexOf(role)  >= 0;
    }

    public formatPrice(price: number, symbol: string) {

        return parsePrice(price / 100) + ` € ${symbol}`;
    }

    public createSocietyChannelConfigurationControl(data: SocietyChannelConfiguration): UntypedFormGroup {

        const group: FormGroup = this._formBuilder.group({
            enableModale: [data ? data.enableModale : false, [Validators.required]],
            translations: new UntypedFormArray([])
        });

        this.societyChannelConfigurationTranslationBuilder = new TranslationBuilder(this._formBuilder);

        this.societyChannelConfigurationTranslationBuilder.form = group;

        this.societyChannelConfigurationTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                id: [null],
                buttonContent: ['', [(control: FormControl) => {

                    if(!group.get('enableModale').value){

                        return null;
                    }

                    if(control.value && (control.value.length > 0)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                modaleTitle: ['', [(control: FormControl) => {

                    if(!group.get('enableModale').value){

                        return null;
                    }

                    if(control.value && (control.value.length > 0)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };

                }]],
                modaleContent: ['', [(control: FormControl) => {

                    if(!group.get('enableModale').value){

                        return null;
                    }

                    if(control.value && (control.value.length > 0)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                redirectionButtonContent: [''],
                redirectionButtonLink: ['', [(control: FormControl) => {

                    if(!group.get('enableModale').value){

                        return null;
                    }

                    if(!control.parent || !control.parent.get('redirectionButtonContent') || (control.parent.get('redirectionButtonContent').value.length <= 0)){

                        return null;
                    }

                    if(control.value && (control.value.length > 0)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]]
            });
        };

        if(data){

            if('id' in data){

                group.addControl('id', new UntypedFormControl(data.id, [Validators.required]));
            }

            data.translations.forEach((translation: SocietyChannelConfigurationTranslation): void => {

                this.societyChannelConfigurationTranslationBuilder.addItemControl(this.societyChannelConfigurationTranslationBuilder.getLocaleItem(translation.locale), translation);
            });
        }
        else{

            SUPPORTED_LOCALES.forEach((locale: string): void => {

                this.societyChannelConfigurationTranslationBuilder.addItemControl(this.societyChannelConfigurationTranslationBuilder.getLocaleItem(locale));
            });
        }

        const resetTranslationsCallback = (): void => {

            this.societyChannelConfigurationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                control.patchValue({
                    id: null,
                    buttonContent: '',
                    modaleTitle: '',
                    modaleContent: '',
                    redirectionButtonContent: '',
                    redirectionButtonLink: ''
                });

                control.updateValueAndValidity();
            });
        };

        this.societyForm.get('hasAccessCreateChannelShowcase').valueChanges.subscribe((): void => {

            group.get('enableModale').patchValue(false);
            group.get('enableModale').updateValueAndValidity();

            resetTranslationsCallback();
        });

        group.get('enableModale').valueChanges.subscribe((): void => {

            resetTranslationsCallback();
        });

        return group;
    }

    public compareById(a: { id: number }, b: { id: number }): boolean {

        return a.id === b.id;
    }

    public handleRegion(option: MatLegacyOption): void {

        const isAllRegionsOption: boolean = option.value.id === null;

        if(isAllRegionsOption){

            this.societyInformationForm.get('regions').patchValue(option.selected ? [...[{id: null}],...this.regions] : []);

            this.societyInformationForm.get('allRegions').patchValue(option.selected);
        }
        else{

            const currentRegions: { id: number }[] = [...(this.societyInformationForm.get('regions').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            const isAllRegion: boolean = (currentRegions.length === this.regions.length);

            if(isAllRegion){

                currentRegions.push({id: null});
            }

            this.societyInformationForm.get('regions').patchValue(currentRegions);

            this.societyInformationForm.get('allRegions').patchValue(isAllRegion);
        }
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get societyForm(): UntypedFormGroup {

        return this.form?.get('society') as UntypedFormGroup;
    }

    get societyInformationForm(): UntypedFormGroup {

        return this.societyForm.get('information') as UntypedFormGroup;
    }

    get adminForm(): UntypedFormGroup {

        return this.societyForm.get('admin') as UntypedFormGroup;
    }

    get termsAndConditionsForm(): UntypedFormArray {

        return this.societyForm.get('termsAndConditions') as UntypedFormArray;
    }

    get addressesForm(): UntypedFormArray {

        return this.societyForm.get('addresses') as UntypedFormArray;
    }

    get administrationForm(): UntypedFormGroup {

        return this.form.get('administration') as UntypedFormGroup;
    }

    get societyLegalRepresentativeControl(): UntypedFormGroup {

        return this.societyForm.get('legalRepresentative') as UntypedFormGroup;
    }

    get societyChannelConfigurationControl(): UntypedFormGroup {

        return this.societyForm.get('channelConfiguration') as UntypedFormGroup;
    }

    get birthDayConfig(): InputDateConfig {

        return {
            id: 'birthDay',
            enableReset: false,
            attrs: {
                required: true,
                placeholder: this._translateService.instant('form.user.fields.birthDay.value'),
                disabled: false,
                max: this.adultAge
            },
            format: DATE_FORMAT
        };
    }

    get adultAge(): string {
       return moment().subtract(18, 'years').format('YYYY-MM-DD');
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items = [];

            const societyInformationsControls: AbstractControl[] = [
                this.societyForm.get('name'),
                this.societyForm.get('corporateName'),
                this.societyForm.get('type'),
                this.societyForm.get('mainWebsite'),
                this.societyForm.get('locale'),
                this.societyForm.get('registration'),
                this.societyForm.get('identificationNumber'),
                this.societyForm.get('financialGuarantee'),
                this.societyForm.get('insurancePolicy'),
                this.societyForm.get('vatNumber'),
                this.societyForm.get('logo'),
                this.societyForm.get('administrationLogo'),
                this.societyForm.get('email'),
                this.societyForm.get('phone'),
                this.societyForm.get('billingEmail'),
                this.societyForm.get('termsAndConditions'),
                this.addressesForm
            ];

            if(this.hasRole('ROLE_OFFER_CREATOR')){

                societyInformationsControls.push(this.societyInformationForm);
            }

            items.push({
                tag: 'societyInformations',
                position: 1,
                controls: societyInformationsControls
            });

            items.push({
                tag: 'legalRepresentative',
                position: 2,
                controls: [
                    this.societyLegalRepresentativeControl
                ]
            });

            items.push({
                tag: 'contactInformations',
                position: 3,
                controls: [
                    this.adminForm
                ]
            });

            items.push({
                tag: 'distributorAdministrationInformations',
                position: 4,
                controls: [
                    this.administrationForm,
                    this.societyForm.get('commission')
                ]
            });

            switch (true) {

                // Rôle Créateur d'offre & Distributeur d'offre

                case this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                    items.push({
                        tag: 'licence',
                        position: 5,
                        controls: []
                    });

                    items.push({
                        tag: 'services',
                        position: 6,
                        controls: []
                    });

                    items.push({
                        tag: 'offerPermanentOptions',
                        position: 7,
                        controls: []
                    });

                    items.push({
                        tag: 'deactivateAccount',
                        position: 8,
                        controls: []
                    });

                    break;

                // Rôle Créateur d'offre

                case this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                    items.push({
                        tag: 'licence',
                        position: 5,
                        controls: []
                    });

                    items.push({
                        tag: 'services',
                        position: 6,
                        controls: []
                    });

                    items.push({
                        tag: 'offerPermanentOptions',
                        position: 7,
                        controls: []
                    });

                    items.push({
                        tag: 'deactivateAccount',
                        position: 8,
                        controls: []
                    });


                    break;

                // Rôle Distributeur

                case this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR'):

                    items.push({
                        tag: 'services',
                        position: 5,
                        controls: []
                    });

                    items.push({
                        tag: 'offerPermanentOptions',
                        position: 6,
                        controls: []
                    });

                    items.push({
                        tag: 'deactivateAccount',
                        position: 7,
                        controls: []
                    });

                    break;

                // Rôle Prestataire

                case this.hasRole('ROLE_PROVIDER'):

                    items.push({
                        tag: 'services',
                        position: 5,
                        controls: []
                    });

                    items.push({
                        tag: 'deactivateAccount',
                        position: 6,
                        controls: []
                    });

                    break;

                // Rôle Institutionnel ou Fédération

                case this.hasOneOfThisRoles(['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']):

                    items.push({
                        tag: 'services',
                        position: 5,
                        controls: []
                    });

                    items.push({
                        tag: 'deactivateAccount',
                        position: 6,
                        controls: []
                    });

                    break;
            }

            return items;
        };
    }

    get formattedNetworkOfferCreatorMarketplacePreference(): string {

        return this.networkOfferCreatorMarketplacePreference.map((item: NetworkOfferCreator): string => {

            return item.name;

        }).join(', ');
    }

    get isAvailableCommission(): boolean {

        return !!this.roles && this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']);
    }

    get presentationEditorConfig(): CkeditorConfig {

        return {
            id: 'presentation',
            editor: this.societyPresentationEditor,
            attrs: {
                required: false,
                maxLength: 1200
            }
        };
    }

    get parsedNetworkOfferCreatorNames(): string {

        if(!this.society){

            return null;
        }

        return this.society.networkOfferCreators.map((item: NetworkOfferCreator): string => {

            return item.name;

        }).join(', ');
    }

    get parsedSocietyGroups(): string {

        if(!this.society){

            return null;
        }

        return this.society.societyGroups.map((item: SocietyGroup): string => {

            return item.name;

        }).join(', ');
    }

    get parsedNetworkProviders(): string {

        if(!this.society){

            return null;
        }

        return this.society.networkProviders.map((item: NetworkProvider): string => {

            return item.name;

        }).join(', ');
    }

    get societyChannelConfigurationModaleContentEditorConfig(): CkeditorConfig {

        return {
            id: 'modaleContent',
            editor: this.editor,
            hasFontColor: true,
            hasColorPicker: true,
            stripTagOnPaste: true,
            attrs: {
                required: false
            },
            validatorCallbacks: [(control: FormControl) => {

                if(!control.parent.parent.parent.get('enableModale').value){

                    return null;
                }

                if(control.value && (control.value.length > 0)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]
        };
    }

    get profileDisplayed(): boolean {

        if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR'])){

            return true;
        }

        if(this.hasOneOfThisRoles(['ROLE_PROVIDER'])){

            return Boolean(this.society.societyGroups.length) || Boolean(this.society.networkProviders.length);
        }

        return Boolean(this.society.societyGroups.length);
    }
}
