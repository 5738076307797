import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {PromotionService} from "@core/shared/models/promotion-service";
import {Promotion} from "@core/shared/models/promotion";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";

@Injectable({
    providedIn: 'root'
})
export class PromotionServiceService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<PromotionService>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/services/promotions`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<PromotionService>>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number, params?: string[]): Observable<PromotionService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/services/promotions/${id}`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<PromotionService>(url, {
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<PromotionService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<PromotionService>(`${this._apiService.getApiUrl(false, true)}/services/promotions`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<PromotionService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<PromotionService>(`${this._apiService.getApiUrl(false, true)}/services/promotions/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/services/promotions/${id}`,{
            headers: headers
        });
    }

}
