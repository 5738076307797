<h1 mat-dialog-title class="x_center">{{ 'invoice.download.value' | translate }}</h1>
<div mat-dialog-content>

    <form [formGroup]="form">

        <!-- Sélection de la période -->

        <div class="form-group date_range">
            <label>{{'offer.period.select.action.value' | translate}}</label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate readonly [placeholder]="'filter.dateInterval.start.value' | translate" formControlName="dateStart" (click)="picker.open()">
                <input matEndDate  readonly [placeholder]="'filter.dateInterval.end.value' | translate" formControlName="dateEnd" (click)="picker.open()">
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <div class="row marges">
                <div class="col_6">
                    <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('dateStart').errors.required">{{ 'offer.period.dateStart.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
                <div class="col_6">
                    <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('dateEnd').errors.required">{{ 'offer.period.dateEnd.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

    </form>

</div>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" [disabled]="form.invalid" (click)="formService.submit()">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>


