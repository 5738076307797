<form autocomplete="off" [formGroup]="form" (ngSubmit)="formSubmit.emit({'form' : form, 'saveAndContinue' : false})">

    <mat-card-actions class="submit mat-card-actions-top" *ngIf="config.save.top" [ngClass]="{'is-sticky': !config.save.bottom}">
        <button mat-raised-button color="accent" [disabled]="!form.valid">{{ 'crud.create.submit.button' | translate }}</button>
        <button mat-raised-button type="button" *ngIf="enableList" (click)="saveAndContinue()" color="accent" [disabled]="!form.valid">{{ 'crud.create.submit_continue.button' | translate }}</button>

    </mat-card-actions>

    <mat-card-actions class="actions_sticky" *ngIf="!config.save.bottom">
        <button mat-raised-button color="accent" [disabled]="!form.valid">{{ 'crud.create.submit.button' | translate }}</button>

        <button mat-raised-button type="button" *ngIf="enableList" (click)="saveAndContinue()" color="accent" [disabled]="!form.valid">{{ 'crud.create.submit_continue.button' | translate }}</button>
    </mat-card-actions>

    <div #content>
        <ng-content></ng-content>
    </div>
    <div *ngIf="!overloaded">
        <div *ngFor="let field of fields">
            <app-input-text *ngIf="fieldService.isText(field.type)" [config]="field.config" [form]="form"></app-input-text>
            <app-input-password *ngIf="fieldService.isPassword(field.type)" [config]="field.config" [form]="form"></app-input-password>
            <app-textarea *ngIf="fieldService.isTextarea(field.type)" [config]="field.config" [form]="form"></app-textarea>
            <app-select *ngIf="fieldService.isSelect(field.type)" [config]="field.config" [form]="form"></app-select>
            <app-input-radio *ngIf="fieldService.isRadio(field.type)" [config]="field.config" [form]="form"></app-input-radio>
            <app-input-checkbox *ngIf="fieldService.isCheckbox(field.type)" [config]="field.config" [form]="form"></app-input-checkbox>
            <app-ckeditor *ngIf="fieldService.isCkeditor(field.type)" [config]="field.config" [form]="form"></app-ckeditor>
            <app-input-date *ngIf="fieldService.isDate(field.type)" [config]="field.config" [form]="form"></app-input-date>
            <app-input-daterange *ngIf="fieldService.isDaterange(field.type)" [config]="field.config" [form]="form"></app-input-daterange>
            <app-input-datetime *ngIf="fieldService.isDatetime(field.type)" [config]="field.config" [form]="form"></app-input-datetime>
            <app-input-time *ngIf="fieldService.isTime(field.type)" [config]="field.config" [form]="form"></app-input-time>
            <app-input-number *ngIf="fieldService.isNumber(field.type)" [config]="field.config" [form]="form"></app-input-number>
            <app-toggle *ngIf="fieldService.isToggle(field.type)" [config]="field.config" [form]="form"></app-toggle>
            <app-select-search *ngIf="fieldService.isSelectSearch(field.type)" [config]="field.config" [form]="form"></app-select-search>
            <form-field-file *ngIf="fieldService.isFile(field.type)" [config]="field.config" [form]="form"></form-field-file>
            <form-field-image *ngIf="fieldService.isImage(field.type)" [config]="field.config" [form]="form"></form-field-image>
            <form-field-image-collection *ngIf="fieldService.isImageCollection(field.type)" [config]="field.config" [form]="form"></form-field-image-collection>
        </div>
    </div>
    <mat-card-actions class="submit mat-card-actions-bottom" *ngIf="config.save.bottom">
        <button mat-raised-button color="accent" [disabled]="!form.valid">{{ 'crud.create.submit.button' | translate }}</button>
        <button mat-raised-button type="button" (click)="saveAndContinue()" color="accent" [disabled]="!form.valid">{{ 'crud.create.submit_continue.button' | translate }}</button>

    </mat-card-actions>
</form>
