import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {UserService} from "@core/shared/services/user.service";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Observable} from "rxjs";
import {User} from "@core/shared/models/user";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";

@Injectable({
    providedIn: 'root'
})
export class PageGiftVoucherItemsResolver {

    constructor(
        private _giftVoucherService: GiftVoucherService,
        private _userService: UserService,
        private _authenticationService: AuthenticationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject): void => {

            this._userService.getItemAPI(this._authenticationService.id).subscribe((user:User): void => {

                if(this._userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

                    this._giftVoucherService.getPaginationItemsAPI().subscribe(resolve, reject)

                } else {

                    this._giftVoucherService.getPaginationSocietyItemsAPI(user.society.id).subscribe(resolve, reject)
                }

            },reject)
        });
    }
}