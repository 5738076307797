<div class="auth-wrapper" *ngIf="display">

    <div class="header_auth">
        <div class="box_xl">
            <div class="logo">
                <img *ngIf="(loginLogoUrl$ | async) as sourceUrl" [src]="sourceUrl" alt="Ty-win" title="Ty-win" class="main_logo" />
                <img *ngIf="poweredByTywin" src="assets/images/logo_powered_tywin.png" alt="Powered By Ty-Win" title="Powered By Ty-Win" class="powered">
            </div>
        </div>
        <app-core-locale-switch></app-core-locale-switch>
    </div>

    <div class="auth">
        <div class="row">
            <div class="col_12 bloc_auth">
                <div class="auth_title">
                    <span>{{ 'signin.connexion.value' | translate }}</span>
                </div>

                <div class="content_auth">

                    <form [formGroup]="form" (ngSubmit)="formService.submit()">

                        <!-- Identifiant -->

                        <div class="form-group">
                            <input type="text" value="" class="form-control" id="username" formControlName="username" placeholder=" "/>
                            <label for="username">{{ 'authentication.connexion.username.value'|translate }}</label>
                            <mat-error *ngIf="form.get('username').errors && (form.get('username').dirty || form.get('username').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('username').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Mot de passe -->

                        <div class="form-group">
                            <div class="form_password">
                                <input type="password" autocomplete="on" value="" class="form-control" id="password" formControlName="password" placeholder=" " #inputPassword />
                                <label for="password">{{ 'authentication.connexion.password.value'|translate }}</label>
                                <mat-icon appShowHidePassword [input]="inputPassword" matSuffix></mat-icon>
                            </div>

                            <mat-error *ngIf="form.get('password').errors && (form.get('password').dirty || form.get('password').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('password').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Soumission -->

                        <div class="form-group submit">
                            <button class="btn_cta" type="submit">
                                {{ 'authentication.connexion.action.value'|translate }}
                            </button>
                        </div>

                        <div class="link_auth">
                            <a [routerLink]="['/forget-password']" [queryParams]="queryParams">{{ 'signin.forgot.value' | translate }}</a>
                        </div>

                        <!-- Pas encore inscrit -->

                        <div class="link_auth">
                            <a [href]="registerUrl" target="_blank" class="register">{{ 'signin.register.value' | translate }}</a>
                        </div>

                    </form>
                </div>

            </div>
<!--            <div class="col_12 bloc_auth">-->
<!--                <div class="content_auth">-->
<!--                    <a class="btn_cta btn_lines">-->
<!--                        <span>{{ 'signin.create.value' | translate }}</span>-->
<!--                    </a>-->
<!--                    <a class="btn_cta btn_lines">-->
<!--                        <span>{{ 'signin.ask.value' | translate }}</span>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>

</div>
