import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {FormService} from "@core/shared/services/form.service";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {environment} from "../../../../../../environments/environment";
import {UserService} from "@core/shared/services/user.service";
import {FileConfig} from "@lib/form/fields/file/file.component";

@Component({
    selector: 'app-core-page-network-offer-creator-update',
    templateUrl: './page-network-offer-creator-update.component.html',
    styleUrls: ['./page-network-offer-creator-update.component.scss'],
    providers: [
        FormService
    ]
})
export class PageNetworkOfferCreatorUpdateComponent implements OnInit {

    public networkOfferCreator: NetworkOfferCreator;

    public registrationLogoConfig: FileConfig;

    public administrationLogoConfig: FileConfig;

    public constructor(
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this._initForm();

        this._activatedRoute.data.subscribe((data: { networkOfferCreator: NetworkOfferCreator }): void => {

            this.networkOfferCreator = data.networkOfferCreator;

            this._hydrateForm(data.networkOfferCreator);
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            name: ['', Validators.required],
            comment: [''],
            offerCreators: [[]],
            parsedSocieties: [''],
            registrationLogo: [null],
            administrationLogo: [null]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {});

            this._networkOfferCreatorService.updateItemAPI(this.networkOfferCreator.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('network.offerCreator.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };

        this._initRegistrationLogoConfigs();

        this._initAdministrationLogoConfigs();
    }

    private _hydrateForm(item: NetworkOfferCreator): void {

        this.form.patchValue(item);
    }

    private _initRegistrationLogoConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.registrationLogoConfig = {
                id: 'registrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'network_offer_creator_registration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true)
            };
        });
    }

    private _initAdministrationLogoConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.administrationLogoConfig = {
                id: 'administrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'network_offer_creator_administration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true)
            };
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/relationship/list'], { queryParams: { targetTab: 'offerCreator'}});
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get registrationIdentifierUrl(): string {

        return `${environment.registerUrl}/${this._userService.currentUser.getValue().locale}/registration/role-choice?choicePreference=${this.networkOfferCreator.registrationIdentifier}`;
    }

    get loginIdentifierUrl(): string {

        return `${window.location.origin}/${this._userService.currentUser.getValue().locale}/signin?choicePreference=${this.networkOfferCreator.registrationIdentifier}`;
    }
}
