import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {validateNumericOnly} from "@core/shared/utils/card-number";

@Component({
    selector: 'app-core-card-form',
    templateUrl: './card-form.component.html',
    styleUrls: ['./card-form.component.scss'],
    providers: [
        FormService
    ]
})
export class CardFormComponent implements OnInit {

    @Input() form: UntypedFormGroup = new UntypedFormGroup({});

    constructor(
        private _formBuilder: UntypedFormBuilder,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.form.addControl('cardNumber', this._formBuilder.control(null, [Validators.required, (control: UntypedFormControl) => {

            let field = {...control};

            let cardNumber = field.value;

            cardNumber = (cardNumber) ? cardNumber.replace(/\s/g, "") : "";

            return validateNumericOnly(cardNumber) === false ? {invalidNumberCard: {valid: false}} : null;
        }]));

        this.form.addControl('cardExpirationDate', this._formBuilder.control(null, [Validators.required, (control: UntypedFormControl) => {

            let field = {...control};

            let cardDate = field.value ? field.value.replace("/", "") : "";

            let currentDate = new Date();

            if (cardDate.length === 4) {

                let year = parseInt(cardDate.substr(2, 2), 10) + 2000;

                let month = parseInt(cardDate.substr(0, 2), 10);

                if (month > 0 && month <= 12) {

                    let currentYear = currentDate.getFullYear();

                    if (currentYear < year){

                        return null;
                    }

                    if (currentYear === year) {

                        let currentMonth = currentDate.getMonth() + 1;

                        if (currentMonth <= month){

                            return null;
                        }
                    }

                    return {invalidDatePast: {valid: false}};
                }
            }

            return {
                invalidFormatDate: {
                    valid: false
                }
            };
        }]));

        this.form.addControl('cardCvx', this._formBuilder.control(null, [Validators.required, (control: UntypedFormControl) => {

            let field = {...control};

            let cvv = field.value ? field.value.trim() : "";

            if (validateNumericOnly(cvv) === true) {

                if (cvv.length === 3) {

                    return null;
                }
            }

            return {
                invalidFormatCvv: {
                    valid: false
                }
            };

        }]));
    }
}
