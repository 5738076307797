export type PriceArea = 'travel' | 'person-price';

export type PricingType = 'fixed' | 'variable';

export type PriceType =  'amount' | 'percent';

export const PRICE_AREAS: PriceArea[] = ['travel', 'person-price'];

export const PRICING_TYPES: PricingType[] = ['fixed', 'variable'];

export const PRICE_TYPES: PriceType[] = ['amount', 'percent'];
