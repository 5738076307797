import {Component, Input} from '@angular/core';
/**
 * Champ input text
 */
@Component({
    selector: 'app-cms-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class CmsButtonComponent{


    /**
     * Valeur du champ texte
     */
    @Input() button : any;



    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor() {
    }


}
