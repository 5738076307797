import {OfferPart} from "@core/components/offer/offer-list/offer-list.component";
import {Offer} from "@core/shared/models/offer";
import {Hashtag} from "@core/shared/models/hashtag";

export const getOfferListHashtagParts: (hashtagId: number, currentParts: OfferPart[], items: Offer[], reset: boolean) => OfferPart[] = (hashtagId: number, currentParts: OfferPart[], items: Offer[], reset: boolean): OfferPart[] => {

    const allOffers: Offer[] = [];

    if(!reset){

        currentParts.forEach((part: OfferPart): void => {

            allOffers.push(...part.items);
        });
    }

    allOffers.push(...items);

    if(!allOffers.length){

        // Aucun résultat pour la recherche

        return [{
            position: 1,
            items: []
        }];
    }

    const offerIndex: number = hashtagId ? allOffers.findIndex((offer: Offer): boolean => {

        return !offer.hashtags.some((hashtag: Hashtag): boolean => {

            return hashtag.id === hashtagId;
        });

    }) : -1;

    const parts: OfferPart[] = [];

    if(hashtagId){

        // Filtre appliqué sur un hashtag

        if(offerIndex > 0){

            // Au moins une des offres est associée au hashtag

            parts.push({
                position: 1,
                items: [...allOffers.slice(0, offerIndex)]
            });

            parts.push({
                position: 2,
                items: [...allOffers.slice(offerIndex)]
            });
        }
        else{

            // Aucune offre n'est associée au hashtag

            parts.push({
                position: 1,
                items: []
            });

            parts.push({
                position: 2,
                items: reset ? items : [...currentParts[0].items, ...items]
            });
        }
    }
    else{

        // Aucun filtre appliqué sur un hashtag

        parts.push({
            position: 1,
            items: reset ? items : [...currentParts[0].items, ...items]
        });
    }

    return parts;
};
