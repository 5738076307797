import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-core-material-tooltip-html',
    templateUrl: './material-tooltip-html.component.html',
    styleUrls: ['./material-tooltip-html.component.scss']
})
export class MaterialTooltipHtmlComponent {

    @Input() content: string = '';
}
