<div [formGroup]="form" class="form-group">
    <mat-label id="radio-group-{{config.id}}"> {{config.attrs.label}}</mat-label>
    <mat-radio-group radioInput aria-label="radio-group-{{config.id}" [attrs]="config.attrs" [formControlName]="config.id">
        <mat-radio-button  *ngFor="let choice of config.attrs.choices " [value]='choice.key'>
            {{choice.value}}
        </mat-radio-button>
    </mat-radio-group>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>
        </div>
    </mat-error>
</div>
