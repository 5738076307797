import {Component, Input} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'app-cms-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class CmsIframeComponent{


    /**
     * Valeur du champ texte
     */
    @Input() iframe : any;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor( private sanitizer:DomSanitizer) {
    }


    public sanitize(string):SafeHtml{
        return this.sanitizer.bypassSecurityTrustResourceUrl(string);
    }
}
