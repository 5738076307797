<h1 mat-dialog-title>{{ 'refusal.reason.value' | translate }}</h1>
<mat-dialog-content>
    <div class="ck-content">
        <p [innerHTML]="data.noReplyReason"></p>
    </div>
</mat-dialog-content>
<mat-dialog-actions>

    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_green_lines" (click)="dialogRef.close()">{{ 'action.close' | translate }}</button>
        </div>
    </div>

</mat-dialog-actions>

