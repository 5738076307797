import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import * as moment_ from 'moment';
const moment = moment_;

/**
 * Configuration paramètres du champ
 */
export interface InputDatetimeConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        label?: string;
        placeholderDate?: string;
        placeholderHeure?: string;
    };
    /**
     * Template bouton annuler
     */
    templatebtnCancel?: TemplateRef<any>;
}

/**
 * Champ input datetime
 */
@Component({
    selector: 'app-input-datetime',
    templateUrl: './input-datetime.component.html',
    styleUrls: ['./input-datetime.component.scss']
})
export class InputDatetimeComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() config: InputDatetimeConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;
    // @Input cancelBtnTmpl: TemplateRef<any>;
    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {
        // this.config.templatebtnCancel =  `<ng-template><button _ngcontent-icr-c34="" class="timepicker-button" type="button"><span _ngcontent-icr-c34="">Annuler</span></button></ng-template>`;



        if(!this.config.attrs.placeholderDate){
            this.config.attrs.placeholderDate = "Date";
        }

        if(!this.config.attrs.placeholderHeure){
            this.config.attrs.placeholderHeure = "Heure";
        }

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id + '[date]', new UntypedFormControl(''));
        this.form.addControl(this.config.id + '[time]', new UntypedFormControl(''));

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        this.form.controls[this.config.id+ '[date]'].setValidators(validators);
        this.form.controls[this.config.id+ '[time]'].setValidators(validators);
    }

    public dateChange(event): void {

        this.form.get(this.config.id + '[date]').setValue(moment(event.value).format('YYYY-MM-DD'));
    }
}
