import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {Transporter} from "@core/shared/models/transporter";
import {Pagination} from "@core/shared/models/pagination";


@Injectable({
    providedIn: 'root'
})
export class TransporterService {

    constructor(private _apiService: ApiService,
                private _httpClient: HttpClient) {
    }

    public getItemAPI(id: number): Observable<Transporter> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Transporter>(`${this._apiService.getApiUrl(false, true)}/transporters/${id}`, {
            headers: headers
        }).pipe(
            map((item: object): Transporter => {

                return plainToClass(Transporter, item);
            })
        );
    }

    public getItemsAPI(societyId: number, params?: string[]): Observable<Transporter[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/transporters`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Transporter[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): Transporter[] => {

                return items.map((item: object): Transporter => {

                    return plainToClass(Transporter, item);
                });
            })
        );
    }

    public getOfferItemsAPI(societyId: number, offerId: number, params?: string[]): Observable<Transporter[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/offers/${offerId}/transporters`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Transporter[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): Transporter[] => {

                return items.map((item: object): Transporter => {

                    return plainToClass(Transporter, item);
                });
            })
        );
    }

    public getPaginationItemsAPI(societyId: number, params?: string[]): Observable<Pagination<Transporter>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/transporters`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<Transporter>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<Transporter>): Pagination<Transporter> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: Transporter): Transporter => {

                        return plainToClass(Transporter, item);
                    })
                });
            })
        );
    }

    public createItemAPI(societyId: number, data: object): Observable<Transporter> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Transporter>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/transporters`, data, {
            headers: headers
        }).pipe(
            map((item: object): Transporter => {

                return plainToClass(Transporter, item);
            })
        );
    }

    public updateItemAPI(id: number, data: object): Observable<Transporter> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Transporter>(`${this._apiService.getApiUrl(false, true)}/transporters/${id}`, data, {
            headers: headers
        }).pipe(
            map((item: object): Transporter => {

                return plainToClass(Transporter, item);
            })
        );
    }

}
