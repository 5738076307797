import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {Moment} from "moment";
import {DATE_FORMAT} from "@app/data";
import {Promotion} from "@core/shared/models/promotion";
import {PromotionPeriodDeleteConfiguration} from "@core/shared/models/promotion/promotion-period/promotion-period-delete";
import {PromotionPeriodService} from "@core/shared/services/promotion/promotion-period.service";

@Component({
    selector: 'app-core-promotion-period-delete',
    templateUrl: './promotion-period-delete.component.html',
    styleUrls: ['./promotion-period-delete.component.scss'],
    providers: [
        FormService
    ]
})
export class PromotionPeriodDeleteComponent implements OnInit {

    @Input() promotion: Promotion;

    @Input() configuration: PromotionPeriodDeleteConfiguration;

    @Output() delete: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _promotionPeriodService: PromotionPeriodService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]],
            status: ['closed', [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                dateStart: (this.form.get('dateStart').value as Moment).format(DATE_FORMAT),
                dateEnd: (this.form.get('dateEnd').value as Moment).format(DATE_FORMAT)
            });

            this._promotionPeriodService.createItemAPI(this.promotion.id, data).subscribe((): void => {

                this.delete.emit();
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
