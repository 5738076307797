import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TenderService} from "@core/shared/services/tender.service";
import {User} from "@core/shared/models/user";
import moment from "moment";
import {Tender, TenderStatus} from "@core/shared/models/tender";
import {Society} from "@core/shared/models/society";
import {Moment} from "moment";
import {DATE_FORMAT} from "@app/data";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {TenderCompositionPublic} from "@core/shared/models/tender/tender-composition";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {TenderDocument} from "@core/shared/models/tender/tender-document";
import {FileService} from "@core/shared/services/file.service";
import {TenderOfferCreator, TenderOfferCreatorStatus} from "@core/shared/models/tender/tender-offer-creator";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {Offer} from "@core/shared/models/offer";
import {ViewType} from "@core/components/offer/offer-search/offer-search.component";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import {TenderOfferCreatorOffer} from "@core/shared/models/tender/tender-offer-creator/tender-offer-creator-offer";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";
import {MatLegacyTab, MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";
import {parsePrice} from "@core/shared/utils/price";
import {OfferCreatorCardService} from "@core/shared/services/offer-creator/offer-creator-card.service";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {OfferCreatorNoReplyReasonDialogComponent} from "@core/components/offer-creator/offer-creator-no-reply-reason-dialog/offer-creator-no-reply-reason-dialog.component";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {OfferCardService} from "@core/shared/services/offer/offer-card.service";
import {OfferItemConfiguration} from "@core/shared/models/offer/offer-item";
import {Access} from "@core/shared/models/access";
import {OfferDateEngineConfiguration, OfferDateEngineItem} from "@core/shared/models/offer/offer-date/offer-date-engine";
import {OfferDateEngineComponent} from "@core/components/offer/offer-date/offer-date-engine/offer-date-engine.component";
import {OfferOptionSelectionService} from "@core/shared/services/offer/offer-option/offer-option-selection.service";
import {OfferAvailability} from "@core/shared/models/offer/offer-availability";
import {OfferFirstDateAvailability} from "@core/shared/models/offer/offer-first-date-availability";
import {OfferAccessRequestCreateDialogComponent} from "@core/components/offer/offer-access-request/offer-access-request-create/offer-access-request-create-dialog/offer-access-request-create-dialog.component";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {TenderRefuseOfferDialogComponent} from "@core/components/tender/tender-refuse-offer/tender-refuse-offer-dialog/tender-refuse-offer-dialog.component";
import {OfferCardCustomAction} from "@core/shared/models/offer/offer-card";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {TenderSelectOfferDialogComponent} from "@core/components/tender/tender-select-offer/tender-select-offer-dialog/tender-select-offer-dialog.component";
import {OfferListService} from "@core/shared/services/offer/offer-list.service";
import {OfferPart} from "@core/components/offer/offer-list/offer-list.component";
import {getOfferListHashtagParts} from "@core/shared/utils/offer/offer-list/offer-list-parts/offer-list-hashtag-parts";

type TabTag = 'generalData' | 'tenderData' | 'offersSelection' | 'offerCreatorsSelection' | 'offerCreatorsResponse' | 'proposedOffers';

type ProposalOfferView = 'search-engine' | 'offer-item';

@Component({
    selector: 'app-core-page-tender-read',
    templateUrl: './page-tender-read.component.html',
    styleUrls: ['./page-tender-read.component.scss'],
    providers: [
        FormService,
        OfferSearchService,
        OfferListService,
        OfferCardService,
        OfferCreatorSearchService,
        OfferCreatorCardService,
        OfferOptionSelectionService
    ]
})
export class PageTenderReadComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild(OfferDateEngineComponent, { static: false }) offerDateEngineComponent: OfferDateEngineComponent;

    protected readonly moment = moment;

    public currentUser: User;

    public tender: Tender;

    public closedAtUpdateRequested: boolean = false;

    public offerCreatorCommentUpdateRequested: boolean = false;

    public minClosedAt: Date;

    public maxClosedAt: Date;

    public collaboratorSearchConfiguration: SelectSearchConfiguration<User>;

    public collaboratorSearchSourceCallback: (search: string) => Observable<User[]>;

    public proposalOfferViewPreChangeCallback: () => void = (): void => {};

    public currentTabTag: TabTag = 'generalData';

    public currentTabIndex: number = 0;

    public currentProposalOfferView: ProposalOfferView = 'search-engine';

    public currentProposedOffer$: Observable<Offer>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _tenderService: TenderService,
        private _fileService: FileService,
        private _offerCreatorService: OfferCreatorService,
        private _offerListService: OfferListService,
        public offerCatalogService: OfferCatalogService,
        public userService: UserService,
        public formService: FormService,
        public offerSearchService: OfferSearchService,
        public offerCardService: OfferCardService,
        public offerCreatorSearchService: OfferCreatorSearchService,
        public offerCreatorCardService: OfferCreatorCardService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this._activatedRoute.data.subscribe((data: { society: Society, tender: Tender }): void => {

            this.tender = data.tender;

            this.minClosedAt = moment().toDate();

            this.maxClosedAt = moment().add(6, 'months').toDate();

            this._configureOfferSearchService();

            this._configureListService();

            this._configureOfferCardService();

            this._initCollaborators();

            if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                this._loadOfferCatalogs();
            }

            this._initForm();

            this._hydrateForm();

            this._initEvents();
        });
    }

    ngAfterViewInit() {

        this.tabGroup.selectedTabChange.subscribe((item: MatTabChangeEvent): void  => {

            const element: HTMLElement = item.tab.content.viewContainerRef.element.nativeElement;

            this.currentTabTag = element.dataset.tag as TabTag;

            this.currentTabIndex = item.index;

            if(this.isOneOfTheseTabTags(['offerCreatorsSelection', 'offerCreatorsResponse'])){

                this._configureOfferCreatorSearchService();

                this._configureOfferCreatorCardService();
            }

            if(this.isOneOfTheseTabTags(['proposedOffers'])){

                // Réinitialisation des filtres

                this.offerSearchService.clearSessionFilters('tender-proposed-offers');

                this.offerSearchService.resetFilter$.next({
                    loadItems: false
                });

                // Filtre sur les offres d'un créateur d'offres

                this.proposalOfferViewPreChangeCallback();

                this._resetProposalOfferViewPreChangeCallback();

                // Chargement des offres

                this.offerSearchService.loadItems$.next({
                    resetOffset: true
                });

                // Mise à jour de la vue active pour l'onglet "Offres proposées"

                this.setCurrentProposalOfferView('search-engine');
            }
        });
    }

    private _configureOfferSearchService(): void {

        if(this.isMine){

            this.offerSearchService.viewOfferAllowed = true;

            this.offerSearchService.selectOfferAllowed = false;
        }

        if((this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.isMine)){

            this.offerSearchService.viewOfferAllowed = true;

            this.offerSearchService.selectOfferAllowed = false;
        }
    }

    private _configureListService(): void {

        this._offerListService.hydratePartsCallback = (currentParts: OfferPart[], items: Offer[], reset: boolean): OfferPart[] => {

            const filterKey: string = 'hashtag';

            const hashtagId: number = this.offerSearchService.filterBuilder.hasFilter(filterKey) ? this.offerSearchService.filterBuilder.getFieldByKey(filterKey).value : null;

            return getOfferListHashtagParts(hashtagId, currentParts, items, reset);
        }
    }

    private _configureOfferCardService(): void {

        this.offerCardService.resetCustomActions();

        this.offerCardService.hashtagsDisplayed = true;

        const customActions: OfferCardCustomAction[] = [];

        if(this.isMine){

            this.offerCardService.goToOfferCallback = (offer: Offer): void => {

                this.setCurrentProposalOfferView('offer-item');

                this.hydrateCurrentProposedOffer(offer);
            };

            this.offerCardService.isOfferHighlightedCallback = (offer: Offer): boolean => {

                return !!this.offerCatalogService.getSelfItemByOffer(offer);
            };

            // Ajouter au catalogue

            customActions.push({
                enabledCallback: (offer: Offer): boolean => {

                    return !this.offerCatalogService.getSelfItemByOffer(offer);
                },
                labelCallback: (): string => {

                    return this._translateService.instant('catalog.add.action.value');
                },
                clickCallback: (offer: Offer): void => {

                    this.openAddOfferToCatalogDialog(offer);
                },
                containerClassesCallback: (): { [p: string]: boolean } => {

                    return {
                        btn_degrade: true
                    };
                },
                buttonClassesCallback: (): { [p: string]: boolean } => {

                    return {};
                },
                buttonTooltipDisplayedCallback: (): boolean => {

                    return false;
                },
                buttonTooltipCallback: (): string => {

                    return null;
                }
            });

            // Refuser l'offre

            customActions.push({
                enabledCallback: (offer: Offer): boolean => {

                    return !this.offerCatalogService.getSelfItemByOffer(offer);
                },
                labelCallback: (): string => {

                    return this._translateService.instant('tender.offer.refused.value');
                },
                clickCallback: (offer: Offer): void => {

                    this.openRefuseOfferDialog(offer);
                },
                containerClassesCallback: (): { [p: string]: boolean } => {

                    return {
                        btn_white: true
                    };
                },
                buttonClassesCallback: (): { [p: string]: boolean } => {

                    return {};
                },
                buttonTooltipDisplayedCallback: (): boolean => {

                    return false;
                },
                buttonTooltipCallback: (): string => {

                    return null;
                }
            });

            // Offre présente dans le catalogue

            customActions.push({
                enabledCallback: (offer: Offer): boolean => {

                    return !!this.offerCatalogService.getSelfItemByOffer(offer);
                },
                labelCallback: (): string => {

                    return this._translateService.instant('offer.catalog.mine.present.value');
                },
                clickCallback: (): void => {},
                containerClassesCallback: (): { [p: string]: boolean } => {

                    return {
                        btn_white: true
                    };
                },
                buttonClassesCallback: (): { [p: string]: boolean } => {

                    return {};
                },
                buttonTooltipDisplayedCallback: (): boolean => {

                    return false;
                },
                buttonTooltipCallback: (): string => {

                    return null;
                }
            });
        }
        else{

            // Sélectionner l'offre

            customActions.push({
                enabledCallback: (offer: Offer): boolean => {

                    return !this.offerSearchService.isSelectedOffer(offer);
                },
                labelCallback: (): string => {

                    return this._translateService.instant('offer.select.action.value');
                },
                clickCallback: (offer: Offer): void => {

                    this.openSelectOfferDialog(offer);
                },
                containerClassesCallback: (): { [p: string]: boolean } => {

                    return {
                        btn_white: true
                    };
                },
                buttonClassesCallback: (): { [p: string]: boolean } => {

                    return {};
                },
                buttonTooltipDisplayedCallback: (): boolean => {

                    return false;
                },
                buttonTooltipCallback: (): string => {

                    return null;
                }
            });

            // Désélectionner l'offre

            customActions.push({
                enabledCallback: (offer: Offer): boolean => {

                    return this.offerSearchService.isSelectedOffer(offer);
                },
                labelCallback: (): string => {

                    return this._translateService.instant('offer.unselect.action.value');
                },
                clickCallback: (offer: Offer): void => {

                    this.unselectOffer(offer);
                },
                containerClassesCallback: (): { [p: string]: boolean } => {

                    return {
                        btn_white: true
                    };
                },
                buttonClassesCallback: (): { [p: string]: boolean } => {

                    return {};
                },
                buttonTooltipDisplayedCallback: (): boolean => {

                    return false;
                },
                buttonTooltipCallback: (): string => {

                    return null;
                }
            });
        }

        this.offerCardService.customActions.push(...customActions);
    }

    private _configureOfferCreatorSearchService(): void {

        this.offerCreatorSearchService.sessionEnabled = false;

        Object.assign(this.offerCreatorSearchService.enabledFilters, {
            [OfferCreatorFilterKey.Destination]: true,
            [OfferCreatorFilterKey.Target]: true,
            [OfferCreatorFilterKey.NetworkOfferCreator]: true,
            [OfferCreatorFilterKey.OfferType]: true,
            [OfferCreatorFilterKey.Name]: true,
            [OfferCreatorFilterKey.InCatalog]: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']),
            [OfferCreatorFilterKey.HasRseLabel]: true,
            [OfferCreatorFilterKey.NotSelected]: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && this.isCurrentTabTag('offerCreatorsSelection'),
            [OfferCreatorFilterKey.ResponseType]: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && this.isCurrentTabTag('offerCreatorsResponse')
        } as { [p in OfferCreatorFilterKey]: boolean });

        this.offerCreatorSearchService.filterPrefix = this.isCurrentTabTag('offerCreatorsResponse') ? 'offerCreator' : null;

        this.offerCreatorSearchService.selectOfferCreatorEnabled = this.offerCreatorSelectionAllowed && this.isCurrentTabTag('offerCreatorsSelection');

        this.offerCreatorSearchService.selectOfferCreatorAllowed = this.offerCreatorSelectionAllowed && this.isCurrentTabTag('offerCreatorsSelection');

        this.offerCreatorSearchService.unselectOfferCreatorAllowed = this.offerCreatorSelectionAllowed && this.isCurrentTabTag('offerCreatorsSelection');

        this.offerCreatorSearchService.isOfferCreatorUnselectionAllowedCallback = (offerCreator: Society): boolean => {

            return !this.isTenderAlreadyReceivedByOfferCreator(offerCreator);
        };

        this.offerCreatorSearchService.offerCreatorUnselectionDisableReasonCallback = (offerCreator: Society): string => {

            if(this.isTenderAlreadyReceivedByOfferCreator(offerCreator)){

                return this._translateService.instant('offerCreator.unselection.tenderAlreadyReceived.description.value');
            }

            return null;
        };

        this.offerCreatorSearchService.isOfferCreatorHighlightedCallback = (offerCreator: Society): boolean => {

            const items: { [p: string]: (offerCreator: Society) => boolean } = {
                'offerCreatorsSelection':  (offerCreator: Society) : boolean => {

                    return !!this.offerCreatorSearchService.selectedOfferCreators$.value.find((item: Society): boolean => {

                        return offerCreator.id === item.id;
                    });
                },
                'offerCreatorsResponse': (offerCreator: Society) : boolean => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    return (['reply', 'no-reply'] as TenderOfferCreatorStatus[]).includes(tenderOfferCreator.status);
                }
            };

            return items[this.currentTabTag](offerCreator);
        };

        this.offerCreatorSearchService.offerCreatorHighlightColor = (offerCreator: Society): string => {

            const items: { [p: string]: (offerCreator: Society) => string } = {
                'offerCreatorsSelection':  () : string => {

                    return 'green';
                },
                'offerCreatorsResponse': (offerCreator: Society) : string => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    return (tenderOfferCreator.status === 'reply') ? 'green' : 'red';
                }
            };

            return items[this.currentTabTag](offerCreator);
        };

        const additionalFilterParams: string[] = [];

        if(this.isCurrentTabTag('offerCreatorsSelection')){

            additionalFilterParams.push(`id[andnin][]=${this.currentUser.society.id}`);
        }

        this.offerCreatorSearchService.additionalFilterParams$.next(additionalFilterParams);

        this.offerCreatorSearchService.selectedOfferCreators$.next(this.isCurrentTabTag('offerCreatorsSelection') ? this.selectedOfferCreators : []);

        this.offerCreatorSearchService.resetTotalOfferCreators();

        this.offerCreatorSearchService.resetFilterFields();
    }

    private _configureOfferCreatorCardService(): void {

        this.offerCreatorCardService.resetCustomActions();

        if(this.isCurrentTabTag('offerCreatorsResponse')){

            this.offerCreatorCardService.customActions.push({
                labelCallback: (offerCreator: Society): string => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    if(tenderOfferCreator.status === 'reply'){

                        return this._translateService.instant('offers.plural.proposed.see.action.value');
                    }

                    if(tenderOfferCreator.status === 'no-reply'){

                        return this._translateService.instant('refusal.reason.value');
                    }

                    return this._translateService.instant('offerCreator.status.to-process.value');
                },
                clickCallback: (offerCreator: Society): void => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    if((tenderOfferCreator.status === 'to-process') || ((tenderOfferCreator.status === 'reply') && !tenderOfferCreator.hasOffers)){

                        return;
                    }

                    if(tenderOfferCreator.status === 'no-reply'){

                        this._dialog.open(OfferCreatorNoReplyReasonDialogComponent, {
                            width: '500px',
                            data: {
                                noReplyReason: tenderOfferCreator.noReplyReason
                            }
                        });

                        return;
                    }

                    this.proposalOfferViewPreChangeCallback = (): void => {

                        (this.offerSearchService.filterBuilder.getFieldByKey(`society.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`society.id`, 'in', offerCreator.id));
                    };

                    this.setCurrentTabTag('proposedOffers');
                },
                containerClassesCallback: (offerCreator: Society): { [p: string]: boolean } => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    return {
                        btn_degrade: tenderOfferCreator.status === 'reply' && tenderOfferCreator.hasOffers,
                        btn_white: tenderOfferCreator.status === 'no-reply'
                    };
                },
                buttonClassesCallback: (offerCreator: Society): { [p: string]: boolean } => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    return {
                        disabled: (tenderOfferCreator.status === 'to-process') || ((tenderOfferCreator.status === 'reply') && !tenderOfferCreator.hasOffers)
                    };
                },
                buttonTooltipDisplayedCallback: (offerCreator: Society): boolean => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    return (tenderOfferCreator.status === 'reply') && !tenderOfferCreator.hasOffers;
                },
                buttonTooltipCallback: (offerCreator: Society): string => {

                    const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                        return tenderOfferCreator.offerCreator.id === offerCreator.id;
                    });

                    if((tenderOfferCreator.status === 'reply') && !tenderOfferCreator.hasOffers){

                        return this._translateService.instant('offerCreator.offer.noSubmit.description.value');
                    }

                    return null;
                }
            });
        }
    }

    private _initCollaborators(): void {

        this.collaboratorSearchConfiguration = {
            multiple: false,
            currentSelectionLabel: 'collaborator.selection.current.value',
            searchActionLabel: 'collaborator.search.action.value',
            selectOptionActionLabel: 'collaborator.option.select.action.value',
            itemLabel: (item: User): string => {

                return `${item.firstName} ${item.lastName}${item.service && item.service.length ? `- ${item.service}` : ''}`;
            }
        }

        this.collaboratorSearchSourceCallback = (search: string): Observable<User[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if(search && search.length){

                params.push(...[
                    `fullName[lk]=${search}`
                ]);
            }

            if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

                return of([]);
            }
            else{

                return this.userService.getPaginationItemSocietyUsers(this.currentUser.id, params).pipe(map((pagination: Pagination<User>): User[] => {

                    return pagination.items;
                }));
            }
        }
    }

    private _loadOfferCatalogs(): void {

        this.offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this.offerCatalogService.selfItems.next(items);
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            status: [{ value: 'in-progress' as TenderStatus, disabled: true }],
            closedAt: [{ value: null, disabled: true }, [Validators.required]],
            responsibleUser: [{ value: null, disabled: true }, [Validators.required]],
            comment: [{ value: null, disabled: true }],
            offerCreatorStatus: [{ value: 'to-process' as TenderOfferCreatorStatus, disabled: true }],
            offerCreatorResponsibleUser: [{ value: null, disabled: true }],
            offerCreatorNoReplyReason: [{ value: null, disabled: true }, [(control: FormControl) => {

                if(!control.parent){

                    return null;
                }

                if(control.parent.get('offerCreatorStatus').value !== ('no-reply' as TenderOfferCreatorStatus)){

                    return null;
                }

                if(!!control.value && Boolean(control.value.length)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]],
            offers: [[]],
            offerCreators: [[]]
        });

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const data: Partial<Tender> = {};

            const successUpdateCallback = (): void => {

                this._snackBar.open(this._translateService.instant('tender.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            };

            if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.isMine){

                const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((item: TenderOfferCreator): boolean => {

                    return item.offerCreator.id === this.currentUser.society.id;
                });

                Object.assign(data, {
                    status: this.form.get('offerCreatorStatus').value,
                    noReplyReason: this.form.get('offerCreatorNoReplyReason').value,
                    responsibleUser: this.form.get('offerCreatorResponsibleUser').value,
                    offers: this.form.get('offers').value
                } as Partial<TenderOfferCreator>);

                this._tenderService.updateOfferCreatorItemAPI(this.tender.id, tenderOfferCreator.id, data).subscribe(successUpdateCallback);
            }

            if(this.isMine){

                Object.assign(data, {
                    status: this.form.get('status').value,
                    responsibleUser: this.form.get('responsibleUser').value,
                    comment: this.form.get('comment').value,
                    offerCreators: this.selectedOfferCreators.map((offerCreator: Society): Partial<TenderOfferCreator> => {

                        const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((tenderOfferCreator: TenderOfferCreator): boolean => {

                            return tenderOfferCreator.offerCreator.id === offerCreator.id;
                        });

                        return tenderOfferCreator || {
                            offerCreator: offerCreator
                        };
                    }),
                } as Partial<Tender>);

                if(this.updateClosedAtAllowed){

                    Object.assign(data, {
                        closedAt: (this.form.get('closedAt').value as Moment).format(DATE_FORMAT)
                    } as Partial<Tender>);
                }

                this._tenderService.updateItemAPI(this.tender.id, data).subscribe(successUpdateCallback);
            }
        };
    }

    private _hydrateForm(): void {

        this.form.patchValue({
            status: this.tender.status,
            closedAt: moment(this.tender.closedAt),
            responsibleUser: this.tender.responsibleUser,
            comment: this.tender.comment
        });

        if(this.isMine){

            this.form.patchValue({
                offerCreators: this.tender.offerCreators.map((item: TenderOfferCreator): Society => {

                    return item.offerCreator;
                })
            });

            this.offerCreatorSearchService.updateFilterMetadata(OfferCreatorFilterKey.NotSelected, {
                selectedOfferCreators: [...this.selectedOfferCreators]
            });

            if(this.updateAllowed){

                this.form.get('status').enable();

                this.form.get('responsibleUser').enable();

                this.form.get('comment').enable();

                if(this.updateClosedAtAllowed){

                    this.form.get('closedAt').enable();
                }
            }
        }

        if(this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.isMine){

            const tenderOfferCreator: TenderOfferCreator = this.tender.offerCreators.find((item: TenderOfferCreator): boolean => {

                return item.offerCreator.id === this.currentUser.society.id;
            });

            this.form.patchValue({
                offerCreatorStatus: tenderOfferCreator.status,
                offerCreatorResponsibleUser: tenderOfferCreator.responsibleUser,
                offerCreatorNoReplyReason: tenderOfferCreator.noReplyReason,
                offers: tenderOfferCreator.offers
            });

            this.offerSearchService.selectedOffers.next(this.selectedOffers);

            if(this.updateAllowed){

                if(tenderOfferCreator.status !== 'reply'){

                    this.form.get('offerCreatorStatus').enable();
                }

                this.form.get('offerCreatorResponsibleUser').enable();

                this.form.get('offerCreatorNoReplyReason').enable();
            }
        }
    }

    private _initEvents(): void {

        // Statut du créateur d'offres

        this.form.get('offerCreatorStatus').valueChanges.subscribe((): void => {

            // Raison du refus

            this.form.get('offerCreatorNoReplyReason').patchValue(null);

            this.form.get('offerCreatorNoReplyReason').updateValueAndValidity();

            this.form.get('offerCreatorNoReplyReason').markAsTouched();

            // Réinitialisation des offres sélectionnées

            this.resetOfferSelection();
        });

        // Sélection des créateurs d'offres

        this.offerCreatorSearchService.selectedOfferCreators$.subscribe((selectedItems: Society[]): void => {

            if(!this.isCurrentTabTag('offerCreatorsSelection')){

                return;
            }

            this.form.get('offerCreators').patchValue(selectedItems);
        });
    }

    private _resetProposalOfferViewPreChangeCallback(): void {

        this.proposalOfferViewPreChangeCallback = (): void => {};
    }

    public redirectToList(): void {

        this._router.navigate(['account/tender/list']);
    }

    public parseAttributes(attributes: OfferAttribute[]): string {

        return attributes.map((attribute: OfferAttribute): string => {

            return this.translationService.getFallbackTranslation(attribute.translations).label;

        }).join(', ');
    }

    public downloadDocument(document: TenderDocument): void {

        this._fileService.openFileInNewTab(document.file.id);
    }

    public resetOfferSelection(): void {

        this.offerSearchService.selectedOffers.next([]);

        this.form.get('offers').patchValue([]);
    }

    public isTenderAlreadyReceivedByOfferCreator(offerCreator: Society): boolean {

        const currentOfferCreators: Society[] = this.tender.offerCreators.map((item: TenderOfferCreator): Society => {

            return item.offerCreator;
        });

        return currentOfferCreators.some((item: Society): boolean => {

            return offerCreator.id === item.id;
        });
    }

    public loadItemsSourceCallback(origin: 'offerCreatorsSelection' | 'offerCreatorsResponse'): (params?: string[]) => Observable<Pagination<Society>> {

        return (params?: string[]): Observable<Pagination<Society>> => {

            const items: { [p in ('offerCreatorsSelection' | 'offerCreatorsResponse')]: Observable<Pagination<Society>> } = {
                offerCreatorsSelection: this._offerCreatorService.getPaginationItemsAPI(params),
                offerCreatorsResponse: this._tenderService.getPaginationOfferCreatorsItemAPI(this.tender.id, [...[`sort[lastResponseAt]=desc`], ...params]).pipe(map((pagination: Pagination<TenderOfferCreator>): Pagination<Society> => {

                    return {
                        pagesCount: pagination.pagesCount,
                        totalItems: pagination.totalItems,
                        items: pagination.items.map((item: TenderOfferCreator): Society => {

                            return item.offerCreator;
                        })
                    };
                }))
            };

            return items[origin];
        }
    }

    public isCurrentTabTag(tag: TabTag): boolean {

        return this.currentTabTag === tag;
    }

    public setCurrentTabTag(tag: TabTag): void {

        this.currentTabTag = tag;

        const tab = this.tabGroup._allTabs.find((item: MatLegacyTab): boolean => {

            const element = item.content.viewContainerRef.element.nativeElement;

            return (element.dataset.tag as TabTag) === tag;
        });

        this.tabGroup.selectedIndex = this.currentTabIndex + tab.position;
    }

    public isOneOfTheseTabTags(tags: TabTag[]): boolean {

        return tags.includes(this.currentTabTag);
    }

    public downloadTenderFile(event: Event): void {

        event.preventDefault();

        this._tenderService.downloadItemAPI(this.tender.id).subscribe((blob) => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            a.href = objectUrl;

            a.download = `${this.tender.reference}_${moment(this.tender.createdAt).format('YYYY_MM_DD')}.pdf`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public isCurrentProposalOfferView(value: ProposalOfferView): boolean {

        return this.currentProposalOfferView === value;
    }

    public setCurrentProposalOfferView(value: ProposalOfferView): void {

        this.currentProposalOfferView = value;
    }

    public hydrateCurrentProposedOffer(offer: Offer): void {

        this.currentProposedOffer$ = this._tenderService.getOfferItemAPI(this.tender.id, offer.id);
    }

    public childDatesOffer(offer: Offer): {start: Moment, end: Moment} {

        return {
            start: moment().subtract(offer.maxChildrenAge, 'year'),
            end: moment().subtract(offer.minChildrenAge, 'year')
        };
    }

    public hasGiftVoucherEnable(offer: Offer): boolean {

        if(!this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])){

            return false;
        }

        const giftVoucherCreateIsMineAccess: Access = this.currentUser.accesses.find((access: Access): boolean => {

            return access.tag === 'GIFT_VOUCHER_CREATE_IS_MINE';
        });

        if(!giftVoucherCreateIsMineAccess){

            return false;
        }

        return offer.giftVoucher && offer.giftVoucher.enable;
    }

    public openAddOfferToCatalogDialog(offer: Offer): void {

        const dialogRef: MatDialogRef<OfferAccessRequestCreateDialogComponent> = this._dialog.open(OfferAccessRequestCreateDialogComponent, {
            width: '600px',
            data: {
                offer: offer,
                society: this.currentUser.society,
                createItemSourceCallback: this.createItemSourceCallback(offer),
                enableMarkupSelection: this.userService.hasRole(this.currentUser, 'ROLE_OFFER_DISTRIBUTOR'),
                enableHashtagSelection: true
            }
        });

        dialogRef.componentInstance.create.subscribe((data: {item: OfferCatalog}): void => {

            this.offerCatalogService.addOfferToSelfItems(data.item);

            this._snackBar.open(this._translateService.instant('tender.offer.item.action.access.accepted.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });
        });
    }

    public openRefuseOfferDialog(offer: Offer): void {

        const dialogRef: MatDialogRef<TenderRefuseOfferDialogComponent> = this._dialog.open(TenderRefuseOfferDialogComponent, {
            width: '500px',
            data: {
                offer: offer,
                tender: this.tender,
                tenderOfferCreator: this.tender.offerCreators.find((item: TenderOfferCreator): boolean => {

                    return item.offerCreator.id === offer.society.id;
                })
            }
        });

        dialogRef.componentInstance.remove.subscribe((): void => {

            this.offerSearchService.loadItems$.next({
                resetOffset: true
            });

            this._snackBar.open(this._translateService.instant('tender.offer.item.action.access.refuse.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });
        });
    }

    public openSelectOfferDialog(offer: Offer): void {

        const dialogRef: MatDialogRef<TenderSelectOfferDialogComponent> = this._dialog.open(TenderSelectOfferDialogComponent, {
            width: '800px',
            data: {
                emitter: this.tender.society
            }
        });

        dialogRef.componentInstance.select.subscribe((data: { comment: string }): void => {

            this.offerSearchService.selectOffer(offer);

            const tenderOfferCreatorOffers: Partial<TenderOfferCreatorOffer>[] = [...this.form.get('offers').value];

            tenderOfferCreatorOffers.push({
                offer: offer,
                comment: data.comment
            });

            this.form.get('offers').patchValue(tenderOfferCreatorOffers);
        });
    }

    public createItemSourceCallback(offer: Offer): (data: Offer) => Observable<OfferCatalog> {

        return (data: Offer) => this._tenderService.createOfferCatalogItemAPI(this.tender.id, offer.id, data);
    }

    public unselectOffer(offer: Offer): void {

        this.offerSearchService.unselectOffer(offer);

        const tenderOfferCreatorOffers: Partial<TenderOfferCreatorOffer>[] = [...this.form.get('offers').value];

        const index: number = tenderOfferCreatorOffers.findIndex((item: TenderOfferCreatorOffer): boolean => {

            return item.offer.id === offer.id;
        });

        tenderOfferCreatorOffers.splice(index, 1);

        this.form.get('offers').patchValue(tenderOfferCreatorOffers);
    }

    public getTenderOfferCreatorOfferFromOffer(offer: Offer): TenderOfferCreatorOffer {

        const tenderOfferCreatorOffers: TenderOfferCreatorOffer[] = [];

        this.tender.offerCreators.forEach((item: TenderOfferCreator): void => {

            tenderOfferCreatorOffers.push(...item.offers);
        })

        return tenderOfferCreatorOffers.find((item: TenderOfferCreatorOffer): boolean => {

            return item.offer.id === offer.id;
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isMine(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return false;
        }

        return this.currentUser.society.id === this.tender.society.id;
    }

    get currentResponsibleUser(): User {

        return this.form.get('responsibleUser').value;
    }

    get currentOfferCreatorResponsibleUser(): User {

        return this.form.get('offerCreatorResponsibleUser').value;
    }

    get updateAllowed(): boolean {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])){

            return false;
        }

        return this.tender.status === 'in-progress';
    }

    get updateClosedAtAllowed(): boolean {

        if(!this.updateAllowed || !this.isMine){

            return false;
        }

        const now: Moment = moment();

        return moment(this.tender.closedAt).isSameOrAfter(now, 'days');
    }

    get parsedPublics(): string {

        return this.tender.composition.publics.map((item: TenderCompositionPublic): string => {

            return this._translateService.instant(`${item}.value`);

        }).join(', ');
    }

    get parsedParticipants(): string {

        const items: string[] = [];

        if(this.tender.composition.publics.includes('adult')){

            items.push(this._translateService.instant('adult.count.interval.value', {
                min: this.tender.composition.minNbAdult,
                max: this.tender.composition.maxNbAdult
            }));
        }

        if(this.tender.composition.publics.includes('child')){

            items.push(this._translateService.instant('child.count.interval.value', {
                min: this.tender.composition.minNbChild,
                max: this.tender.composition.maxNbChild
            }));
        }

        return items.join(', ');
    }

    get parsedPrices(): string {

        if(!this.tender.pricing.currency){

            return null;
        }

        const items: string[] = [];

        if(this.tender.composition.publics.includes('adult')){

            items.push(this._translateService.instant('price.adult.interval.value', {
                min: parsePrice(this.tender.pricing.minAdultPrice  / 100),
                max: parsePrice(this.tender.pricing.maxAdultPrice  / 100),
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        if(this.tender.composition.publics.includes('child')){

            items.push(this._translateService.instant('price.child.interval.value', {
                min: parsePrice(this.tender.pricing.minChildPrice  / 100),
                max: parsePrice(this.tender.pricing.maxChildPrice  / 100),
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        return items.join(', ');
    }

    get parsedGifVoucherPrices(): string {

        if(!this.tender.pricing.currency || !this.tender.pricing.allowGiftVoucher){

            return null;
        }

        const items: string[] = [];

        if(this.tender.composition.publics.includes('adult')){

            items.push(this._translateService.instant('price.adult.interval.value', {
                min: parsePrice(this.tender.pricing.minAdultGiftVoucherPrice  / 100),
                max: parsePrice(this.tender.pricing.maxAdultGiftVoucherPrice  / 100),
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        if(this.tender.composition.publics.includes('child')){

            items.push(this._translateService.instant('price.child.interval.value', {
                min: parsePrice(this.tender.pricing.minChildGiftVoucherPrice  / 100),
                max: parsePrice(this.tender.pricing.maxChildGiftVoucherPrice  / 100),
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        return items.join(', ');
    }

    get parsedMarketingModes(): string {

        const items: string[] = [];

        if(this.tender.pricing.allowBooking){

            items.push(this._translateService.instant(`tender.marketing.mode.booking.value`));
        }

        if(this.tender.pricing.allowRequest){

            items.push(this._translateService.instant(`tender.marketing.mode.request.value`));
        }

        if(this.tender.pricing.allowGiftVoucher){

            items.push(this._translateService.instant(`tender.marketing.mode.giftVoucher.value`));
        }

        if(this.tender.pricing.allowOnlineSale){

            items.push(this._translateService.instant(`tender.marketing.mode.onlineSale.value`));
        }

        return items.join(', ');
    }

    get parsedLocales(): string {

        return this.tender.configuration.locales.map((item: string): string => {

            return this._translateService.instant(`locale.${item}.value`);

        }).join(', ');
    }

    get loadOffersSourceCallback(): (view: ViewType, params?: string[]) => Observable<Pagination<Offer>|OfferLocation[]> {

        return (view: ViewType, params?: string[]): Observable<Pagination<Offer>|OfferLocation[]> => {

            const items: { [p in ViewType]: Observable<Pagination<Offer>|OfferLocation[]> } = {
                list: this._tenderService.getPaginationSocietyOffersItemAPI(this.currentUser.society.id, this.tender.id, params),
                map: this._tenderService.getPaginationSocietyOfferLocationsItemAPI(this.currentUser.society.id, this.tender.id, params)
            };

            return items[view];
        }
    }

    get loadProposedOffersSourceCallback(): (view: ViewType, params?: string[]) => Observable<Pagination<Offer>|OfferLocation[]> {

        return (view: ViewType, params?: string[]): Observable<Pagination<Offer>|OfferLocation[]> => {

            const items: { [p in ViewType]: Observable<Pagination<Offer>|OfferLocation[]> } = {
                list: this._tenderService.getPaginationOffersItemAPI(this.tender.id, params),
                map: this._tenderService.getPaginationOfferLocationsItemAPI(this.tender.id, params)
            };

            return items[view];
        }
    }

    get offerSelectionAllowed(): boolean {

        return this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR') && !this.isMine && (this.tender.hasAccess === 'write');
    }

    get offerCreatorSelectionAllowed(): boolean {

        return this.isMine;
    }

    get submitLabel(): string {

        if(this.offerSelectionAllowed && this.selectedOffers.length){

            return this._translateService.instant('tender.submitAndSendOffer.action.value');
        }

        return this._translateService.instant('form.submit.action.value');
    }

    get selectedOffers(): Offer[] {

        return (this.form.get('offers').value as TenderOfferCreatorOffer[]).map((item: TenderOfferCreatorOffer): Offer => {

            return item.offer;
        });
    }

    get selectedOfferCreators(): Society[] {

        return this.form.get('offerCreators').value as Society[];
    }

    get offerDetailConfiguration(): OfferItemConfiguration {

        return {
            displayOnlyComposition: false,
            displayProviders: false,
            displayOwner: false,
            displayChannels: false
        };
    }

    get bookingRequestLabel(): string {

        if(!this.compositionItem || (this.compositionItem.availableStock === null)){

            return this._translateService.instant('offer.booking.action.value');
        }

        return this._translateService.instant(this.compositionItem.availableStock ? 'offer.booking.action.value' : 'offer.booking.request.action.value');
    }

    get compositionItem(): OfferDateEngineItem {

        if(!this.offerDateEngineComponent || !this.offerDateEngineComponent.valid){

            return null;
        }

        return this.offerDateEngineComponent.item;
    }

    get quotationRequestLabel(): string {

        if(!this.compositionItem || (this.compositionItem.type === null)){

            return this._translateService.instant('booking.type.request.value');
        }

        return this._translateService.instant(this.compositionItem.type === 'booking' ? 'personalization.action.value' : 'booking.type.request.value');
    }

    get offerDateEngineConfiguration(): OfferDateEngineConfiguration {

        return {
            displayDayDetails: true,
            enablePublicPrice: false
        };
    }

    get loadOfferAvailabilityItemsSourceCallback(): (offer: Offer, params?: string[]) => Observable<OfferAvailability[]> {

        return (offer: Offer, params?: string[]): Observable<OfferAvailability[]> => {

            return this._tenderService.getOfferAvailabilityItemsAPI(this.tender.id, offer.id, params);
        }
    }

    get loadOfferFirstAvailabilityItemSourceCallback(): (offer: Offer, params?: string[]) => Observable<OfferFirstDateAvailability> {

        return (offer: Offer, params?: string[]): Observable<OfferFirstDateAvailability> => {

            return this._tenderService.getFirstDateOfferAvailabilityAPI(this.tender.id, offer.id, params);
        }
    }

    get currentTenderOfferCreator(): TenderOfferCreator {

        if(!this.userService.hasRole(this.currentUser, 'ROLE_OFFER_CREATOR')){

            return null;
        }

        return this.tender.offerCreators.find((item: TenderOfferCreator): boolean => {

            return item.offerCreator.id === this.currentUser.society.id;
        });
    }
}


