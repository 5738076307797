import {FilterField, FilterOperator} from "../filter";

export class ArrayFilterField extends FilterField {

    constructor(key: string, operator: FilterOperator, value?: any) {

        super(key, operator);

        if(value){

            this.value = value;
        }
    }

    get serialize(): string {

        if(this.value === null || this.value === undefined){

            return '';
        }

        return `${this.key}[${this.operator}][]=${this.value}`;
    }
}
