import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {OfferOptionDate} from "../../../models/offer/offer-option/offer-option-date";

@Injectable({
    providedIn: 'root'
})
export class OfferOptionDateService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(id: number, params?: string[]): Observable<OfferOptionDate[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offerOptions/${id}/dates`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferOptionDate[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferOptionDate[] => {

                return items.map((item: object): OfferOptionDate => {

                    return plainToClass(OfferOptionDate, item);
                });
            })
        );
    }

    public duplicateItemAPI(sourceId: number, destinationId: number): Observable<void> {

        const url = `${this._apiService.getApiUrl(false, true)}/offerOptions/${sourceId}/dates/duplicate/${destinationId}`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(url, {}, {
            headers: headers
        });
    }
}
