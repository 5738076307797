import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Card} from "@core/shared/models/card";
import {CardService} from "@core/shared/services/card.service";
import {Router} from "@angular/router";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";

@Component({
    selector: 'app-core-page-card-read',
    templateUrl: './page-card-read.component.html',
    styleUrls: ['./page-card-read.component.scss']
})
export class PageCardReadComponent implements OnInit {

    public user: User;

    public card$: Observable<Card>;

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _cardService: CardService
    ) {
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this._initCard();
    }

    private _initCard(): void {

        this.card$ = this._cardService.getSocietyItem(this.user.society.id);
    }

    public redirectToCardCreate(): void {

        this._router.navigate(['account/card/create']);
    }

    public redirectToCardUpdate(): void {

        this._router.navigate(['account/card/update']);
    }
}
