<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'promotion.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">{{ 'promotion.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotion.name.value' | translate" [key]="'translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Type de promotion -->

                <ng-container [matColumnDef]="getFilterColumnDef('type')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'promotion.type.value' | translate" [key]="'type'" [operator]="'eq'" [items]="typeItems" [itemLabelProperty]="'label'" [itemValueProperty]="'value'"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Réduction -->

                <ng-container [matColumnDef]="getFilterColumnDef('value')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Date de creation -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Date de début -->

                <ng-container [matColumnDef]="getFilterColumnDef('dateStart')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'dateStart'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Date de fin -->

                <ng-container [matColumnDef]="getFilterColumnDef('dateEnd')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'dateEnd'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Typologie de public -->

                <ng-container [matColumnDef]="getFilterColumnDef('public')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'promotion.public.value' | translate" [key]="'public'" [operator]="'eq'" [items]="publicItems" [itemLabelProperty]="'label'" [itemValueProperty]="'value'"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Statut -->

                <ng-container [matColumnDef]="getFilterColumnDef('enable')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-boolean-filter #filter [builder]="filterBuilder" [label]="'promotion.status.value' | translate" [key]="'enable'" [truthyLabel]="'activation.enabled.value' | translate" [falsyLabel]="'activation.disabled.value' | translate"></app-core-select-boolean-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="editItem(element.id)" *ngIf="hasAccessEdit()">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="hasAccessDelete()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'promotion.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).translations)?.name}}</td>
                </ng-container>

                <!-- Type de promotion -->

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>{{'promotion.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('promotion.type.' + getModel(element).type + '.value') | translate }}</td>
                </ng-container>

                <!-- Réduction -->

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>{{'promotion.reduction.percent.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).value}}%</td>
                </ng-container>

                <!-- Date de creation -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'promotion.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt.format('L')}}</td>
                </ng-container>

                <!-- Date de début -->

                <ng-container matColumnDef="dateStart">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'promotion.date.start.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).dateStart.format('L')}}</td>
                </ng-container>

                <!-- Date de fin -->

                <ng-container matColumnDef="dateEnd">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'promotion.date.end.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).dateEnd.format('L')}}</td>
                </ng-container>

                <!-- Typologie de public -->

                <ng-container matColumnDef="public">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'promotion.public.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).public ? (('promotion.public.' + getModel(element).public + '.value') | translate) : '' }}</td>
                </ng-container>

                <!-- Statut -->

                <ng-container matColumnDef="enable">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'promotion.status.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('promotion.status.' + (getModel(element).enable ? 'enabled' : 'disabled') + '.value') | translate }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>

