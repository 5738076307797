import {Component, Input, OnInit} from '@angular/core';

interface Advantage {
    value: string;
}

@Component({
  selector: 'app-role-advantages',
  templateUrl: './role-advantage.component.html',
  styleUrls: ['./role-advantage.component.scss']
})
export class RoleAdvantageComponent implements OnInit {

  @Input() advantages: {label: string, list: Advantage[]};

  constructor() { }

  ngOnInit(): void {
  }

}
