<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'markup.list.management.value' | translate }}</h1>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form margin">
        <div class="col">

            <div class="">
                <p>
                    <span>{{'markup.list.roundPrice.value' | translate}}</span>
                </p>
            </div>

            <ng-container *ngIf="isMarkupAvailable">

                <!-- Gestion du markup générique -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="enableMarkup" formControlName="enableMarkup" (ngModelChange)="form.get('markup').patchValue(null)">
                                <label for="enableMarkup">{{ 'markup.generic.choice.value' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Valeur du mark-up -->

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_4 x_start">
                        <div class="col_content y_items_center ligne_form form-group">
                            <div class="row">
                                <div class="col content_input">
                                    <input type="text" value="/" placeholder=" " class="form-control" formControlName="markup" id="markup">
                                    <label for="markup">{{'markup.generic.form.value' | translate}}</label>
                                    <mat-icon class="tooltip_icon_edit" (click)="editGenericMarkup()">edit</mat-icon>
                                </div>
                            </div>
                            <mat-error *ngIf="form.get('markup').errors && (form.get('markup').dirty || form.get('markup').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('markup').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    <div *ngIf="form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_8 x_start">
                        <div class="col_content y_items_center ligne_form">
                            <button class="btn_cta btn_small" type="submit">
                                {{ 'markup.generic.submit.value'|translate }}
                            </button>
                        </div>
                    </div>

                    <div class="col_12 form-group">
                        <p *ngIf='hasRole("ROLE_OFFER_CREATOR")' class="message-help">
                            <mat-icon>info</mat-icon>{{'markup.generic.catalog.infos.double_role.value' | translate}}
                        </p>
                        <p class="message-help" [ngClass]="!!form.get('enableMarkup').value && form.get('markup').value < defaultMarkupPercent ? 'error' : ''">
                            <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.markup.help.recommendations.value' | translate }}
                        </p>
                        <p class="message-help" [innerHTML]="'offer.markup.help.value' | translate"></p>
                    </div>
                </div>

            </ng-container>
        </div>
        <div class="col_content y_items_end">
            <button class="btn_cta btn_small"  type="button" [disabled]="selection.isEmpty() || !userHasAccess('OFFER_EDIT_IS_MINE')" (click)="openGroupedUpdateDialog()">{{ 'markup.update.grouped.action.value' | translate }}</button>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items"  class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!-- Sélection -->

                <ng-container [matColumnDef]="getFilterColumnDef('select')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'name.value' | translate" [key]="'translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prix net -->

                <ng-container [matColumnDef]="getFilterColumnDef('startFromPrice')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Prix public -->

                <ng-container [matColumnDef]="getFilterColumnDef('startFromPricePublic')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <!-- Markup -->

                <ng-container [matColumnDef]="getFilterColumnDef('markup')">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
                    </td>
                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).name}}</td>
                </ng-container>

                <!-- Prix net -->

                <ng-container matColumnDef="startFromPrice">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.markup.startFromPrice.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{formatPrice(getModel(element).isDisplayPriceHT ? getModel(element).startFromPriceHT : getModel(element).startFromPriceTTC, getModel(element).currency?.symbol)}}</td>
                </ng-container>

                <!-- Prix public -->

                <ng-container matColumnDef="startFromPricePublic">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.markup.startFromPrice.public.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{formatPrice((getModel(element).isDisplayPriceHT ? getModel(element).startFromPublicPriceHT : getModel(element).startFromPublicPriceTTC), getModel(element).currency?.symbol)}}</td>
                </ng-container>

                <!-- Markup -->

                <ng-container matColumnDef="markup">
                    <th mat-header-cell *matHeaderCellDef>{{'markup.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).parsedMarkup}}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</form>

