import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {Offer} from "@core/shared/models/offer";
import {User} from "@core/shared/models/user";
import {OfferItemConfiguration} from "@core/shared/models/offer/offer-item";
import {Role} from "@core/shared/models/role";
import {OfferDateEngineComponent} from "@core/components/offer/offer-date/offer-date-engine/offer-date-engine.component";
import { OfferDateEngineConfiguration, OfferDateEngineItem} from "@core/shared/models/offer/offer-date/offer-date-engine";
import {SocietyService} from "@core/shared/services/society.service";
import {UserService} from "@core/shared/services/user.service";
import {Access} from "@core/shared/models/access";
import {OfferOptionSelectionService} from "@core/shared/services/offer/offer-option/offer-option-selection.service";
import {BookingService} from "@core/shared/services/booking.service";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {Booking, BookingTypeType} from "@core/shared/models/booking";
import {DATE_FORMAT} from "@app/data";
import {OfferOptionPresential} from "@core/shared/models/offer/offer-option/offer-option-presential";
import {Observable, of} from "rxjs";
import {OfferOptionService} from "@core/shared/services/offer/offer-option.service";
import {FilterBuilder} from "@core/shared/models/filter";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {BookingCompositionOption} from "@core/shared/models/booking/booking-composition-option";
import {OfferPermanentOptionSelectionService} from "@core/shared/services/offer/offer-option/offer-permanent-option-selection.service";
import {OfferPermanentOption} from "@core/shared/models/offer/offer-permanent-option";
import {OfferPermanentOptionService} from "@core/shared/services/offer/offer-permanent-option.service";
import {BookingSummaryService} from "@core/shared/services/booking/booking-summary.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferOptionPublicType} from "@core/shared/models/offer/offer-option/offer-option-public";
import {tap} from "rxjs/operators";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {File} from "@lib/media/file/file";
import {FileService} from "@core/shared/services/file.service";
import {BookingCompositionPermanentOption} from "@core/shared/models/booking/booking-composition-permanent-option";
import {Moment} from "moment";
import * as moment from "moment";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {Promotion} from "@core/shared/models/promotion";
import {PromotionSelectionService} from "@core/shared/services/promotion/promotion-selection.service";
import {OfferGiftVoucherRequestService} from "@core/shared/services/offer/offer-gift-voucher/offer-gift-voucher-request.service";
import {OfferBookingRequestService} from "@core/shared/services/offer/offer-booking/offer-booking-request.service";
import {OfferAvailability} from "@core/shared/models/offer/offer-availability";
import {OfferAvailabilityService} from "@core/shared/services/offer/offer-availability.service";
import {OfferFirstDateAvailability} from "@core/shared/models/offer/offer-first-date-availability";

export type View = 'date-engine' | 'booking-request' | 'gift-voucher-request';

@Component({
    selector: 'app-core-page-offer-read',
    templateUrl: './page-offer-read.component.html',
    styleUrls: ['./page-offer-read.component.scss'],
    providers: [
        OfferOptionSelectionService,
        OfferPermanentOptionSelectionService,
        PromotionSelectionService,
        BookingSummaryService,
        OfferBookingRequestService,
        OfferGiftVoucherRequestService
    ]
})
export class PageOfferReadComponent implements OnInit, AfterViewInit {

    @ViewChild(OfferDateEngineComponent, { static: false }) offerDateEngineComponent: OfferDateEngineComponent;

    public offer: Offer;

    public user: User;

    public listRedirectionAllowed: boolean = true;

    public view: View = 'date-engine';

    public roles: Role[] = [];

    public options$: Observable<OfferOption[]>;

    public permanentOptions$: Observable<OfferPermanentOption[]>;

    public booking: Booking;

    public locale: string;

    public termsAndConditions: number;

    public hasPermanentOptionsAvailable: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _societyService: SocietyService,
        private _bookingService: BookingService,
        private _offerCatalogService: OfferCatalogService,
        private _offerOptionService: OfferOptionService,
        private _offerPermanentOptionService: OfferPermanentOptionService,
        private _promotionSelectionService: PromotionSelectionService,
        private _translateService: TranslateService,
        private _offerBookingRequestService: OfferBookingRequestService,
        private _offerGiftVoucherRequestService: OfferGiftVoucherRequestService,
        private _offerAvailabilityService: OfferAvailabilityService,
        public offerOptionSelectionService: OfferOptionSelectionService,
        public offerPermanentOptionSelectionService: OfferPermanentOptionSelectionService,
        public bookingSummaryService: BookingSummaryService,
        public _userService: UserService,
        public _fileService: FileService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this.locale = this._translateService.currentLang;

        this.user = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: {  offer: Offer }): void => {

            this.offer = data.offer;

            this._handleListRedirection();

            this._initTermAndConditions();

            this.roles = this.user.roles;

            if(this.offer.translations.length){

                this.locale = this.offer.translations[0].locale;
            }

            this._societyService.currentUserSociety.next(this.user.society);

            if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                this._loadSelfOfferCatalogs();
            }

            this._loadOptions();

            if(this.user.society) {

                this._loadPermanentOptions(this.userFallbackLocale);

                this._offerBookingRequestService.localeUpdated$.subscribe((locale: string): void => {

                    this._loadPermanentOptions(locale);
                });
            }
        });
    }

    ngAfterViewInit(): void {

        this._initCompositionUpdatedSubscription();

        this._initBooking();
    }

    private _handleListRedirection(): void {

        if(!this._activatedRoute.snapshot.queryParams['listRedirectionAllowed']){

            return;
        }

        this.listRedirectionAllowed = Boolean(parseInt(this._activatedRoute.snapshot.queryParams['listRedirectionAllowed']));
    }

    private _initTermAndConditions(): void {

        this._societyService.getTermAndConditionsFromOffer(this.offer.id, this._translateService.currentLang).subscribe((item: number): void => {

            this.termsAndConditions = item;
        });
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    private _initBooking(): void {

        this.booking = {
            offer: this.offer,
            type: this.compositionItem ? this.compositionItem.type : 'booking',
            composition: this.compositionItem ? {
                dateStart: this.compositionItem.start.format(DATE_FORMAT),
                dateEnd: this.compositionItem.end.format(DATE_FORMAT),
                nbAdult: this.compositionItem.nbAdult,
                nbChild: this.compositionItem.nbChild,
                options: this.offerOptionSelectionService.items.map((option: OfferOption): BookingCompositionOption => {

                    return {
                        nbAdult: option.nbAdult,
                        nbChild: option.nbChild,
                        day: option.availableByDay ? (option.day || 1) : null,
                        option: option
                    };
                }),
                permanentOptions: this.offerPermanentOptionSelectionService.items.map((permanentOption: OfferPermanentOption): BookingCompositionPermanentOption => {

                    return {
                        permanentOption: permanentOption
                    };
                }),
                participants: this.compositionItem.participants
            } : null,
            promotion: this._promotionSelectionService.item
        };
    }

    private _loadOptions(): void {

        this.options$ = this._offerOptionService.getOfferItemsAPI(this.offer.id, this.offerOptionsApiParams).pipe(
            tap((items: OfferOption[]): void => {

                items.forEach((item: OfferOption): void => {

                    item.day = 1;

                    if(this.offer.customerTypology.type === 'I'){

                        item.nbAdult = this.isPublicAvailable(item,'adult') ? item.presentialIndividual.adultDefault : 0;

                        item.nbChild = this.isPublicAvailable(item,'child') ? item.presentialIndividual.childDefault : 0;
                    }

                    if(this.offer.customerTypology.type === 'G'){

                        item.nbAdult = this.isPublicAvailable(item,'adult') ? item.presentialGroup.adultDefault : 0;

                        item.nbChild = this.isPublicAvailable(item,'child') ? item.presentialGroup.childDefault : 0;
                    }
                });
            })
        );
    }

    private _loadPermanentOptions(locale: string): void {

        this.permanentOptions$ = locale ? this._offerPermanentOptionService.getOfferItemsAPI(this.offer.id, locale).pipe(
            tap((items: OfferPermanentOption[]): void => {

                this.hasPermanentOptionsAvailable = Boolean(items.length);

            })) : of([]);
    }

    private _initCompositionUpdatedSubscription(): void {

        this.offerDateEngineComponent.compositionUpdated.subscribe((data: { type: 'date' | 'public' | 'adult' | 'child' | 'child-informations' }): void => {

            if(data.type === 'date'){

                this.offerOptionSelectionService.clearItems();

                this._loadOptions();
            }

            this._initBooking();

            this.handleSummary();
        });

        this.offerDateEngineComponent.dateReinitialized.subscribe((): void => {

            this.bookingSummaryService.item = null;
        });
    }

    public redirectToList(): void {

        if('origin' in this._activatedRoute.snapshot.queryParams){

            switch (this._activatedRoute.snapshot.queryParams['origin']) {

                case 'societyDistributor':

                    this._router.navigate(['account/society/distributor/update', this._activatedRoute.snapshot.queryParams['societyDistributor']], {
                        fragment: 'availableOffers'
                    });

                    break;

                case 'societyProvider':

                    this._router.navigate(['account/society/provider/read', this._activatedRoute.snapshot.queryParams['societyProvider']], {
                        fragment: 'availableOffers'
                    });

                    break;

                case 'bookingRead':

                    this._router.navigate(['account/booking/read', this._activatedRoute.snapshot.queryParams['booking']]);

                    break;

                case 'giftVoucherRead':

                    this._router.navigate(['account/gift-voucher/read', this._activatedRoute.snapshot.queryParams['giftVoucher']]);

                    break;

                case 'offerList':

                    const extras: NavigationExtras = {};

                    if(this._activatedRoute.snapshot.queryParams['layoutView']){

                        extras.queryParams = {
                            layoutView: this._activatedRoute.snapshot.queryParams['layoutView']
                        };
                    }

                    this._router.navigate(['account/offer/list', this._activatedRoute.snapshot.queryParams['mode']], extras);

                    break;

                case 'offerAvailabilityRead':

                    this._router.navigate(['account/availability/offer/read', this.offer.id]);

                    break;

                case 'offerAvailabilityUpdate':

                    this._router.navigate(['account/availability/offer/update', this.offer.id]);

                    break;
            }

            return;
        }

        if(this.hasRole('ROLE_OFFER_CREATOR')){

            this._router.navigate(['account/offer/list/personnal-offers']);
        }
        else{

            this._router.navigate(['account/offer/list/reservoir']);
        }
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public validateBookingRequest(type: BookingTypeType){

        if(!this.offerDateEngineComponent.valid){

            return;
        }

        switch (type){

            case 'booking':

                if(this.offerDateEngineComponent.item.type !== 'booking'){

                    return;
                }

                this.offerDateEngineComponent.type = (this.offerDateEngineComponent.currentAvailability.status === 'available') ? 'booking' : 'request';

                this.offerDateEngineComponent.availableStock = this.offerDateEngineComponent.currentAvailability.availableStock;

                break;

            case 'request':

                this.offerDateEngineComponent.type = 'request';

                this.offerDateEngineComponent.availableStock = false;

                break;
        }

        this._initBooking();

        this.setView('booking-request');
    }

    public validateGiftVoucherRequest(): void {

        this._offerGiftVoucherRequestService.defaultNbAdult = this.offerDateEngineComponent.item.nbAdult;

        this._offerGiftVoucherRequestService.defaultNbChild = this.offerDateEngineComponent.item.nbChild;

        this.setView('gift-voucher-request');
    }

    public setView(view: View): void {

        this.view = view;
    }

    public isView(view: View): boolean {

        return this.view === view;
    }

    public isOneOfTheseViews(views: View[]): boolean {

        return views.includes(this.view);
    }

    public isPublicAvailable(option: OfferOption, type: ('adult' | 'child')): boolean {

        return this.offer.publics.includes(type) && option.publics.includes(type);
    }

    public handleOptionSelection(item: OfferOption): void {

        if(this.offerOptionSelectionService.hasItem(item)){

            this.offerOptionSelectionService.getItemById(item.id).day = 1;

            this.offerOptionSelectionService.removeItem(item);
        }
        else {

            this.offerOptionSelectionService.addItem(item);
        }

        this._initBooking();

        this.handleSummary();
    }

    public handleOptionDay(item: OfferOption, event: Event){

        const select: HTMLSelectElement = event.currentTarget as HTMLSelectElement;

        const match: OfferOption = this.offerOptionSelectionService.getItemById(item.id);

        match.day = parseInt(select.value);

        this._initBooking();

        this.handleSummary();
    }

    public handleOptionPresential(item: OfferOption, optionPublic: OfferOptionPublicType, event: Event){

        const select: HTMLSelectElement = event.currentTarget as HTMLSelectElement;

        const match: OfferOption = this.offerOptionSelectionService.getItemById(item.id);

        if(optionPublic === 'adult'){

            match.nbAdult = parseInt(select.value);
        }

        if(optionPublic === 'child'){

            match.nbChild = parseInt(select.value);
        }

        this._initBooking();

        this.handleSummary();
    }

    public handlePermanentOptionSelection(item: OfferPermanentOption): void {

        if(this.offerPermanentOptionSelectionService.hasItem(item)){

            this.offerPermanentOptionSelectionService.removeItem(item);
        }
        else {

            this.offerPermanentOptionSelectionService.addItem(item);
        }

        this._initBooking();

        this.handleSummary();
    }

    public handlePermanentOptionSelectionClear(): void {

        this._initBooking();

        this.handleSummary();
    }

    public handlePromotionSelection(promotion: Promotion): void {

        if(!this.booking.promotion && !promotion){

            return;
        }

        this.booking.promotion = promotion;

        this._initBooking();

        this.handleSummary();
    }

    public handleSummary(): void {

        if(!this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            return;
        }

        if(!this.compositionItem){

            return;
        }

        this._bookingService.getSocietySummaryItemAPI(this._societyService.currentUserSociety.getValue().id, this.booking).subscribe((booking: Booking): void => {

            this.bookingSummaryService.item = booking;
        });
    }

    public handleBackToComposition(): void {

        this.offerDateEngineComponent.type = (this.offerDateEngineComponent.currentAvailability.status === 'available') ? 'booking' : 'request';

        this.offerDateEngineComponent.availableStock = this.offerDateEngineComponent.currentAvailability.availableStock;

        this.setView('date-engine');
    }

    public getChoicePresentialOptionValues(optionPresential: OfferOptionPresential, optionPublic: OfferOptionPublicType): number[] {

        const start: number = optionPublic === 'adult' ? optionPresential.adultMin : optionPresential.childMin;

        const end: number = optionPublic === 'adult' ? optionPresential.adultMax : optionPresential.childMax;

        const step: number = optionPublic === 'adult' ? optionPresential.adultIncrementalStep : optionPresential.childIncrementalStep;

        const arrayLength = Math.floor(((end - start) / step)) + 1;

        return [...Array(arrayLength).keys()].map(x => (x * step) + start);
    }

    public getSummaryPermanentOption(id: number): BookingCompositionPermanentOption|null {

        return this.bookingSummaryService.item.composition.permanentOptions.find((item: BookingCompositionPermanentOption): boolean => {

            return item.permanentOption.id === id;
        });
    }

    public updateLocale(locale: string): void {

        this.locale = locale;
    }

    public hasAccessBookingCreate(): boolean {

        return this.user.accesses.some((access : Access): boolean => {

            return access.tag === 'BOOKING_CREATE_IS_MINE';
        });
    }

    public openOfferPermanentOptionFileInNewTab(file: File): void {

        if(!file || !file.id){

            return;
        }

        this._fileService.openFileInNewTab(file.id);
    }

    get configuration(): OfferItemConfiguration {

        return {
            displayOnlyComposition: this.isOneOfTheseViews(['booking-request']),
            displayProviders: this.hasRole('ROLE_SUPER_ADMIN') || this.hasRole('ROLE_ADMIN') || this.hasRole('ROLE_OFFER_CREATOR'),
            displayOwner: this.hasRole('ROLE_PROVIDER'),
            displayChannels: this.hasRole('ROLE_OFFER_CREATOR')
        };
    }

    get compositionItem(): OfferDateEngineItem {

        if(!this.offerDateEngineComponent || !this.offerDateEngineComponent.valid){

            return null;
        }

        return this.offerDateEngineComponent.item;
    }

    get offerDateEngineConfiguration(): OfferDateEngineConfiguration {

        return {
            displayDayDetails: true,
            enablePublicPrice: this.hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])
        };
    }

    get offerOptionsApiParams(): string[] {

        const filterBuilder: FilterBuilder = new FilterBuilder();

        if(this.compositionItem){

            filterBuilder.addField(new ArrayFilterField('date', 'andin', this.compositionItem.start.format(DATE_FORMAT)));
        }

        return filterBuilder.serializedFilters;
    }

    get isMine(): boolean {

        if(!this.user.society){

            return false;
        }

        return this.user.society.id === this.offer.society.id;
    }

    get choiceDayOptionValues(): number[] {

        const start: number = 1;

        const end: number = this.offer.duration.value;

        const step: number = 1;

        const arrayLength = Math.floor(((end - start) / step)) + 1;

        return [...Array(arrayLength).keys()].map(x => (x * step) + start);
    }

    get backToListLabel(): string {

        if('origin' in this._activatedRoute.snapshot.queryParams){

            switch (this._activatedRoute.snapshot.queryParams['origin']) {

                case 'societyDistributor':

                    return 'society.distributor.availableOffers.back.value';

                case 'societyProvider':

                    return 'society.provider.availableOffers.back.value';

                case 'bookingRead':

                    return 'booking.read.back.value';

                case 'giftVoucherRead':

                    return 'giftVoucher.read.back.value';

                case 'offerList':

                    let label: string = '';

                    switch (this._activatedRoute.snapshot.queryParams['mode'] as ModeType) {

                        case 'personnal-offers':

                            label = 'offer.list.personnalOffers_TO_REPLACE_back.value';

                            break;

                        case 'reservoir':

                            label = 'offer.list.reservoir_TO_REPLACE_back.value';

                            break;

                        case 'catalog':
                        case 'channel':

                            label = 'offer.list.catalog_TO_REPLACE_back.value';

                            break;
                    }

                    switch (true) {

                        case this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']):

                            label = label.replace('_TO_REPLACE_', '.own.');

                            break;

                        case this.hasOneOfThisRoles(['ROLE_PROVIDER']):

                            label = label.replace('_TO_REPLACE_', '.provider.own.');

                            break;

                        default:

                            label = label.replace('_TO_REPLACE_', '.');
                    }

                    return label;

                case 'offerAvailabilityRead':

                    return 'offer.priceAndAvailability.back.value';

                case 'offerAvailabilityUpdate':

                    return 'offer.priceAndAvailability.back.value';
            }
        }
        else{

            return 'offer.list.back.value';
        }
    }

    get hasGiftVoucherEnable(): boolean {

        if(!this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])){

            return false;
        }

        const giftVoucherCreateIsMineAccess: Access = this.user.accesses.find((access: Access): boolean => {

            return access.tag === 'GIFT_VOUCHER_CREATE_IS_MINE';
        });

        if(!giftVoucherCreateIsMineAccess){

            return false;
        }

        return (this.offer.giftVoucher && this.offer.giftVoucher.enable);
    }

    get childDatesOffer(): {start: Moment, end: Moment} {

        return {
            start: moment().subtract(this.offer.maxChildrenAge, 'year'),
            end: moment().subtract(this.offer.minChildrenAge, 'year')
        };
    }

    get bookingRequestLabel(): string {

        if(!this.compositionItem || (this.compositionItem.availableStock === null)){

            return this._translateService.instant('offer.booking.action.value');
        }

        return this._translateService.instant(this.compositionItem.availableStock ? 'offer.booking.action.value' : 'offer.booking.request.action.value');
    }

    get quotationRequestLabel(): string {

        if(!this.compositionItem || (this.compositionItem.type === null)){

            return this._translateService.instant('booking.type.request.value');
        }

        return this._translateService.instant(this.compositionItem.type === 'booking' ? 'personalization.action.value' : 'booking.type.request.value');
    }

    get userFallbackLocale(): string {

        const fallbacks: { [p: string]: string[] } = {
            'fr': ['fr', 'en', 'es', 'it', 'de', 'nl', 'pt'],
            'en': ['en', 'fr', 'es', 'it', 'de', 'nl', 'pt'],
            'es': ['es', 'en', 'fr', 'it', 'pt', 'de', 'nl'],
            'it': ['it', 'en', 'fr', 'es', 'de', 'nl', 'pt'],
            'de': ['de', 'nl', 'en', 'fr', 'es', 'it', 'pt'],
            'pt': ['pt', 'en', 'es', 'fr', 'it', 'de', 'nl'],
            'nl': ['nl', 'de', 'en', 'fr', 'es', 'it', 'pt']
        };

        const fallbackLocales: string[] = fallbacks[this.user.locale];

        const existingLocales: string[] = this.offer.locales;

        return fallbackLocales.reduce((previousValue, currentValue): string => {

            return (!previousValue && existingLocales.includes(currentValue)) ? currentValue : previousValue;

        }, null);
    }

    get loadItemsSourceCallback(): (offer: Offer, params?: string[]) => Observable<OfferAvailability[]> {

        return (offer: Offer, params?: string[]): Observable<OfferAvailability[]> => {

            return this._offerAvailabilityService.getItemsAPI(offer.id, params);
        }
    }

    get loadFirstAvailabilityItemSourceCallback(): (offer: Offer, params?: string[]) => Observable<OfferFirstDateAvailability> {

        return (offer: Offer, params?: string[]): Observable<OfferFirstDateAvailability> => {

            return this._offerAvailabilityService.getFirstDateAvailabilityAPI(offer.id, params);
        }
    }
}

