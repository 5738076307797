import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OfferAttributeService} from '@core/shared/services/offer-attribute.service';
import {OfferAttribute} from "@core/shared/models/offer-attribute";

@Injectable({
    providedIn: 'root'
})
export class OfferAttributeItemResolver  {

    constructor(
        private _offerService: OfferAttributeService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._offerService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
