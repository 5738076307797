import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OfferAvailability} from "../../models/offer/offer-availability";
import {plainToClass} from "class-transformer";
import {OfferFirstDateAvailability} from "@core/shared/models/offer/offer-first-date-availability";

@Injectable({
    providedIn: 'root'
})
export class OfferAvailabilityService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(id: number, params?: string[]): Observable<OfferAvailability[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${id}/availabilities`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferAvailability[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferAvailability[] => {

                return items.map((item: object): OfferAvailability => {

                    return plainToClass(OfferAvailability, item);
                });
            })
        );
    }

    public getFirstDateAvailabilityAPI(id: number, params?: string[]): Observable<OfferFirstDateAvailability> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${id}/availabilities/firstAvailability`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferFirstDateAvailability>(url, {
            headers: headers
        }).pipe(
            map((item: object): OfferFirstDateAvailability => {

                return plainToClass(OfferFirstDateAvailability, item);
            })
        );
    }
}
