import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {OfferPermanentOption} from "@core/shared/models/offer/offer-permanent-option";

@Injectable()
export class OfferPermanentOptionSelectionService {

    public items$: BehaviorSubject<OfferPermanentOption[]> = new BehaviorSubject([]);

    public addItem(item: OfferPermanentOption): void {

        const items: OfferPermanentOption[] = this.items$.getValue();

        items.push(item);

        this.items$.next(items);
    }

    public removeItem(item: OfferPermanentOption): void {

        const items: OfferPermanentOption[] = this.items$.getValue();

        const index: number = items.findIndex((i: OfferPermanentOption): boolean => {

            return i.id === item.id;
        });

        items.splice(index, 1);

        this.items$.next(items);
    }

    public hasItem(item: OfferPermanentOption): boolean {

        const match: OfferPermanentOption = this.items.find((i: OfferPermanentOption): boolean => {

            return i.id === item.id;
        });

        return !!match;
    }

    public clearItems(): void {

        this.items$.next([]);
    }

    get items(): OfferPermanentOption[] {

        return this.items$.getValue();
    }
}
