import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {User} from "@core/shared/models/user";
import {Offer, OfferTranslation} from "@core/shared/models/offer";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "@core/shared/services/translation.service";
import {CountryService} from "@core/shared/services/country.service";
import {OfferGiftVoucher, OfferGiftVoucherComposition} from "@core/shared/models/offer/offer-gift-voucher-composition";
import {ApiService} from "@core/shared/services/api.service";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {parsePrice} from "@core/shared/utils/price";
import {OfferGiftVoucherRequestCompositionComponent} from "@core/components/offer/offer-gift-voucher-request/offer-gift-voucher-composition/offer-gift-voucher-request-composition.component";
import {OfferGiftVoucherRequestConfigurationComponent} from "@core/components/offer/offer-gift-voucher-request/offer-gift-voucher-configuration/offer-gift-voucher-request-configuration.component";

@Component({
    selector: 'app-offer-gift-voucher-request-create',
    templateUrl: './offer-gift-voucher-request-create.component.html',
    styleUrls: ['./offer-gift-voucher-request-create.component.scss'],
    providers: [FormService]
})
export class OfferGiftVoucherRequestCreateComponent implements OnInit, AfterViewInit {

    @ViewChild(OfferGiftVoucherRequestCompositionComponent) offerGiftVoucherComposition: OfferGiftVoucherRequestCompositionComponent;

    @ViewChild(OfferGiftVoucherRequestConfigurationComponent) offerGiftVoucherConfiguration: OfferGiftVoucherRequestConfigurationComponent;

    @Input() currentUser: User;

    @Input() offer: Offer;

    @Input() termsAndConditions: number;

    @Output() backToOfferRequested: EventEmitter<void> = new EventEmitter<void>();

    public offerTranslation: OfferTranslation;

    public locale: string;

    public view: 'composition' | 'configuration' = 'composition';

    public compositionData: any = null;

    public giftVoucher: GiftVoucher;

    public item: { nbAdult: number, nbChild: number } = {nbChild: 0, nbAdult: 0};

    constructor(
        public formService: FormService,
        private _giftVoucherService: GiftVoucherService,
        public translationService: TranslationService,
        private _apiService: ApiService,
        private _countryService: CountryService,
        private _translateService: TranslateService,
        private _formBuilder: UntypedFormBuilder)
    {}

    ngOnInit(): void {

        this.locale = this._translateService.currentLang;

        this.offerTranslation = this.translationService.getFallbackTranslation(this.offer.translations);
    }

    ngAfterViewInit() {

        setTimeout(() => {

            this._initCompositionUpdatedSubscription();

            this.loadGiftVoucherSummary();
        });
    }

    public loadGiftVoucherSummary(): void {

        const generalData = this.compositionData ? this.compositionData : this.offerGiftVoucherComposition.item;

        const data: OfferGiftVoucher = {
            offer: this.offer,
            nbAdult: generalData.nbAdult,
            nbChild: generalData.nbChild,
            buyer: generalData.buyer,
            beneficiary: generalData.beneficiary,
            promotion: generalData.promotion,
            acceptTermsAndConditions: true,
            recipient: generalData.beneficiarySameAsBuyer ? 'buyer' : 'beneficiary',
        }

        if (this.view === 'configuration') {

            const configuration = this.offerGiftVoucherConfiguration.item;

            Object.assign(data, {
                type: configuration.type,
                transporter: configuration.transporter,
                template: configuration.template,
                personalizedNote: configuration.personalizedNote,
                recipient: configuration.notifyBeneficiary ? 'beneficiary' : 'buyer',
            });
        }

        this._giftVoucherService.summarySocietyGiftVoucherItemAPI(data, this.currentUser.society.id).subscribe((data) => {

            this.giftVoucher = data;
        });
    }

    private _initCompositionUpdatedSubscription(): void {

        this.offerGiftVoucherComposition.compositionUpdated.subscribe((): void => {

            this.loadGiftVoucherSummary();
        });
    }

    public initConfigurationForm(data: OfferGiftVoucherComposition) {

        this.compositionData = data;

        this.loadGiftVoucherSummary();

        this.view = 'configuration';
    }

    public setView(view: ('composition' | 'configuration')): void {

        this.view = view;
    }

    public isView(view: ('composition' | 'configuration')): boolean {

        return this.view === view;
    }

    public getVisuelOffer(idOffer: number, idImage: number): string {

        return !!idImage ? `${this._apiService.getApiUrl(false, false)}/public/offers/${idOffer}/pictures/${idImage}/filter/rectangle/1500` : null;
    }

    public formatPrice(price: number, symbol: string): string {

        return price ? parsePrice(price / 100) + symbol : null;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
