import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {User} from '../models/user';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OfferService} from "../services/offer.service";

@Injectable({
    providedIn: 'root'
})
export class OfferItemResolver  {

    constructor(
        private _offerService: OfferService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._offerService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
