import {Role} from "@core/shared/models/role";

export interface LocalePublishedItemAccess {

    name: string;

}

export interface LocalePublishedItem {

    id: string;

    label: string;

    name: string;

    requiredRolesForDisplay: Role[];
}

export const LOCALE_PUBLISHED_ITEMS: LocalePublishedItem[] = [
    {
        id: 'fr',
        label: 'offer.form.fields.published.value',
        name: 'published',
        requiredRolesForDisplay: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']
    },
    {
        id: 'fr',
        label: 'offer.form.fields.draft.value',
        name: 'draft',
        requiredRolesForDisplay: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR']
    },
    {
        id: 'fr',
        label: 'offer.form.fields.restricted.value',
        name: 'restricted',
        requiredRolesForDisplay: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']
    },
];
