import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-core-page-account-layout',
    templateUrl: './page-account-layout.component.html',
    styleUrls: ['./page-account-layout.component.scss']
})
export class PageAccountLayoutComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
