import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {TenderService} from "@core/shared/services/tender.service";

@Injectable({
    providedIn: 'root'
})
export class TenderItemResolver {

    constructor(
        private _tenderService: TenderService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._tenderService.getItemAPI(parseInt(route.paramMap.get('id')))
    }
}
