import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {JwtToken} from "../models/jwt";
import {tap} from "rxjs/operators";
import {Params, Router} from "@angular/router";
import {Role} from "@core/shared/models/role";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public jwtToken: BehaviorSubject<JwtToken> = new BehaviorSubject({
        isAuthenticated: null,
        token: null,
        id: null,
        roles : []
    });

    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _apiService: ApiService,

    ) {
        this._initToken();
    }

    public signin(credentials: { username: string, password: string }): Observable<{ token: string , roles : Role[]}> {

        return this._httpClient.post<{ token: string , roles : Role[]}>(`${this._apiService.getApiUrl(false, true)}/login_check`, credentials).pipe(
            tap((response: { token: string, id: number, roles : Role[] }) => {

                this.jwtToken.next({
                    isAuthenticated: true,
                    token: response.token,
                    id: response.id,
                    roles : response.roles
                });

                localStorage.setItem('jwt', response.token);

                localStorage.setItem('user-id', response.id.toString());

                localStorage.setItem('user-roles', JSON.stringify(response.roles));

            })
        );
    }

    public logout(): void {

        this.jwtToken.next({
            isAuthenticated: false,
            token: null,
            id: null,
            roles : []
        });

        localStorage.removeItem('jwt');

        localStorage.removeItem('user-id');

        const queryParams: Params = {};

        if (localStorage.getItem('choicePreference')){

            queryParams['choicePreference'] = localStorage.getItem('choicePreference');

            localStorage.removeItem('choicePreference');
        }

        if (localStorage.getItem('registrationAffiliation')){

            queryParams['registrationAffiliation'] = localStorage.getItem('registrationAffiliation');

            localStorage.removeItem('registrationAffiliation');
        }

        this._router.navigate(['/signin'], { queryParams: queryParams });
    }

    private _initToken(): void {

        const token = localStorage.getItem('jwt');

        if (token) {

            this.jwtToken.next({
                isAuthenticated: true,
                token: token,
                id: parseInt(localStorage.getItem('user-id')),
                roles: JSON.parse(localStorage.getItem('user-roles'))
            });

        } else {

            this.jwtToken.next({
                isAuthenticated: false,
                token: null,
                id: null,
                roles : []
            });
        }
    }

    get isAuthenticated(): boolean {

        return this.jwtToken.value.isAuthenticated;
    }

    get token(): string {

        return this.jwtToken.value.token;
    }

    get id(): number {

        return this.jwtToken.value.id;
    }

    get roles() : Role[]{

        return this.jwtToken.value.roles;
    }
}
