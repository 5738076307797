import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class OfferProviderService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public createItemAPI(data: object): Observable<any> {

        const url = `${this._apiService.getApiUrl(false, true)}/offers/providers/request`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<any>(url, data, {
            headers: headers
        });
    }
}
