import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@core/shared/services/api.service';
import {FormService} from '@core/shared/services/form.service';
import {fromEvent, ReplaySubject} from 'rxjs';
import {TextFilterField} from '@core/shared/models/filter/text-filter-field';
import {debounceTime, map} from "rxjs/operators";
import {FilterBuilder} from "@core/shared/models/filter";
import {Offer} from '@core/shared/models/offer';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SocietyProviderService} from "@core/shared/services/society/society-provider.service";
import {Society} from "@core/shared/models/society";
import {SocietyProvider} from "@core/shared/models/society/society-provider";
import {MatSelectSearchComponent} from "ngx-mat-select-search";
import {Address} from "@core/shared/models/address";
import {Country, CountryTranslation} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-offer-provider-create',
    templateUrl: './offer-provider-create.component.html',
    styleUrls: ['./offer-provider-create.component.scss']
})
export class OfferProviderCreateComponent implements OnInit, AfterViewInit {

    @Input() parentProviderSocieties: UntypedFormGroup;

    @Input() formName: string;

    @Input() offer : Offer;

    @Input() society : Society;

    @ViewChild('nameFilter', { static: true }) nameFilterReference: MatSelectSearchComponent;

    public countries: Country[];

    public choices: Society[] = [];

    public optionFilterNameCtrl: UntypedFormControl = new UntypedFormControl();

    public filteredOptions: ReplaySubject<Society[]> = new ReplaySubject<Society[]>(1);

    public valuesOptions: Society[] = [];

    constructor(
        private _snackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _apiService: ApiService,
        private _societyProviderService: SocietyProviderService,
        private _countryService: CountryService,
        public translationService: TranslationService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initCountries();

        const societyProviderParams: string[]= [
            `enable[eq]=1`
        ];

        this._societyProviderService.getSocietyItemsAPI(this.society.id, societyProviderParams).pipe(map((items: SocietyProvider[]): Society[] => {

            return items.map((item: SocietyProvider): Society => {

                return item.provider;
            });

        })).subscribe((items: Society[]): void => {

            this.choices = items;

            this._initValues();
        });
    }

    ngAfterViewInit(): void {

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'focus').subscribe((): void => {

            this._filterOptions();
        });

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this._handleClearSelection();

                this._filterOptions();
            })
        ;
    }

    private _initCountries(): void {

        this._countryService.getItemsAPI().subscribe((countries: Country[]): void => {

            this.countries = countries;
        });
    }

    private _initValues(): void {

        this.valuesOptions = this.choices;

        this.filteredOptions.next(this.valuesOptions.slice());
    }

    private _filterOptions(): void {

        const params: string[] = [];

        const filterBuilder: FilterBuilder = new FilterBuilder();

        if(this.optionFilterNameCtrl.value && this.optionFilterNameCtrl.value.length){

            filterBuilder.addField(new TextFilterField('provider.name', 'lk', this.optionFilterNameCtrl.value.toLowerCase()));
        }

        if(filterBuilder.hasFilters){

            params.push(filterBuilder.serializedRequest);
        }

        params.push(`enable[eq]=1`);

        this._societyProviderService.getSocietyItemsAPI(this.society.id, params).pipe(map((items: SocietyProvider[]): Society[] => {

            return items.map((item: SocietyProvider): Society => {

                return item.provider;
            });

        })).subscribe((items: Society[]): void => {

            this.choices = items;

            this._initValues();
        });
    }

    private _handleClearSelection(): void {

        const element: HTMLButtonElement = this.nameFilterReference.innerSelectSearch.nativeElement.querySelector('.mat-select-search-clear');

        if(element){

            element.onclick = (): void => {

                this.optionFilterNameCtrl.patchValue('');

                this._filterOptions();
            };
        }
    }

    public handleClosure(): void {

        this.optionFilterNameCtrl.patchValue('');

        this._filterOptions();
    }

    public getMailingAddress(item: Society): Address {

        return item.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';
        });
    }

    public getCountryTranslation(society: Society): CountryTranslation {

        const item: Country = this.countries?.find((country: Country): boolean => {

            return country.code === this.getMailingAddress(society).country;
        });

        if(!item){

            return null;
        }

        return this.translationService.getFallbackTranslation(item.translations);
    }

    public compareProviders(a: Society, b: Society): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public removeProviderSelection(toDeleteItem: Society): void {

        const items: Society[] = [...this.providersControl.value];

        const index: number = items.findIndex((item: Society): boolean => {

            return item.id === toDeleteItem.id;
        });

        items.splice(index, 1);

        this.providersControl.patchValue(items);
    }

    get form(): UntypedFormGroup {

        return this.parentProviderSocieties;
    }

    get providersControl(): UntypedFormGroup {

        return this.form.get(this.formName) as UntypedFormGroup;
    }

    get selectedProviders(): Society[] {

        return this.form.get(this.formName).value as Society[];
    }
}
