import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormErrorService {

    public handleErrors(form: UntypedFormGroup) {

        const errors = {};

        this.findErrors(form.controls, errors);

        console.log('VALIDATION DU FORMULAIRE :', errors);
    }

    private findErrors(controls: { [key: string]: AbstractControl }, errors: object) {

        Object.keys(controls).forEach((control: string) => {

            if (controls[control] instanceof UntypedFormArray) {

                this.findErrorsOnFormArray(controls[control] as UntypedFormArray, control, errors);

            } else if (controls[control] instanceof UntypedFormGroup) {

                this.findErrorsOnFormGroup(controls[control] as UntypedFormGroup, control, errors);

            } else if (controls[control] instanceof UntypedFormControl) {

                this.findErrorsOnFormControl(controls, control, errors);
            }
        });
    }

    private findErrorsOnFormArray(formArray: UntypedFormArray, formArrayName: string, errors: object) {

        errors[formArrayName] = {};

        if(formArray.errors){

            errors[formArrayName] = this.getErrorMessage(formArray.errors);
        }

        formArray.controls.forEach((control: AbstractControl, key: number) => {

            if(control instanceof UntypedFormArray){

            }
            else if(control instanceof UntypedFormGroup){

                const formGroup: UntypedFormGroup = control as UntypedFormGroup;

                errors[formArrayName][key] = {};

                Object.keys(formGroup.controls).forEach((controlName: string) => {

                    switch (true) {

                        case (formGroup.controls[controlName] instanceof UntypedFormArray):

                            this.findErrorsOnFormArray(formGroup.controls[controlName] as UntypedFormArray, controlName, errors[formArrayName][key]);

                            break;

                        case (formGroup.controls[controlName] instanceof UntypedFormGroup):

                            this.findErrorsOnFormGroup(formGroup.controls[controlName] as UntypedFormGroup, controlName, errors[formArrayName][key]);

                            break;

                        default:

                            this.findErrorsOnFormControl(formGroup.controls, controlName, errors[formArrayName][key]);
                    }
                });
            }
            else{

            }
        });
    }

    private findErrorsOnFormGroup(formGroup: UntypedFormGroup, group: string, errors: object) {

        const controls = formGroup.controls;

        errors[group] = {};

        Object.keys(controls).forEach(control => {

            switch (true) {

                case (controls[control] instanceof UntypedFormArray):

                    this.findErrorsOnFormArray(controls[control] as UntypedFormArray, control, errors[group]);

                    break;

                case (controls[control] instanceof UntypedFormGroup):

                    this.findErrorsOnFormGroup(controls[control] as UntypedFormGroup, control, errors[group]);

                    break;

                default:

                    this.findErrorsOnFormControl(controls, control, errors[group]);
            }
        });
    }

    private findErrorsOnFormControl(controls: { [key: string]: AbstractControl }, control: string, errors: object) {

        if (controls[control].errors) {

            errors[control] = this.getErrorMessage(controls[control].errors);
        }
    }

    private getErrorMessage(errors: ValidationErrors) {

        const fieldErrors: string[] = [];

        Object.keys(errors).forEach(error => {

            fieldErrors.push(error);
        });

        return fieldErrors;
    }
}
