export type OfferPriceLevelTypeType = 'percent' | 'price';

export interface OfferPriceLevelType {

    type: OfferPriceLevelTypeType;

    label: string;
}

export const OFFER_PRICE_LEVEL_TYPES: OfferPriceLevelType[] = [
    {
        type: 'percent',
        label: 'offer.price.level.type.percent.value'
    },
    {
        type: 'price',
        label: 'offer.price.level.type.price.value'
    }
];
