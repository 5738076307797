<div class="page_offer page_offer_creator">

    <div class="back_to_list">

        <div class="col_12 y_items_start">

            <div class="col_6">
                <span *ngIf="listRedirectionAllowed" class="ty-chevron" (click)="redirectToList()">{{ 'offerCreator.list.back.value' | translate }}</span>
            </div>

            <div class="col_6 x_end">
                <ng-container *ngIf="isQuotationCreationAllowed">

                    <!-- Demandes sur-mesures -->

                    <button (click)="openCreateQuotationDialog()" class="btn_cta btn_little btn_add">
                        {{ 'quotation.create' | translate }}
                    </button>

                </ng-container>
            </div>
        </div>
    </div>

    <app-core-offer-creator-item [item]="item"></app-core-offer-creator-item>
</div>
