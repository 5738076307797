<div class="page_title">
    <div class="row marges">
        <div class="col y_items_center">
            <h1 class="title">{{ 'society.provider.read.value' | translate }}</h1>
        </div>
        <div class="col_content y_items_center">
            <!-- Retour -->
            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                {{ 'society.provider.own.list.back.value' | translate }}
            </button>
        </div>
    </div>
</div>

<!-- Gestion des onglets -->

<mat-tab-group #tabGroup>
    <mat-tab [label]="'society.provider.read.providerData.value' | translate">
        <ng-container *ngTemplateOutlet="providerData"></ng-container>
    </mat-tab>
    <mat-tab [label]="'society.provider.read.associatedOffersData.value' | translate">
        <ng-container *ngTemplateOutlet="associatedOffersData"></ng-container>
    </mat-tab>
    <mat-tab [label]="'society.provider.read.commissionData.value' | translate">
        <ng-container *ngTemplateOutlet="commissionData"></ng-container>
    </mat-tab>
</mat-tab-group>

<!-- Données prestataires -->

<ng-template #providerData>

    <div class="ligne_form row marges">
        <div class="col_12">
            <div class="form-group">

                <!-- Nom du prestataire -->

                <p class="read">
                    <strong>{{ 'provider.name.value' | translate }} : </strong>
                    {{ item.provider.name }}
                </p>

                <!-- Email générique de la société -->

                <p class="read">
                    <strong>{{ 'provider.email.value' | translate }} : </strong>
                    {{ item.provider.email }}
                </p>

                <!-- Téléphone générique de la société -->

                <p class="read">
                    <strong>{{ 'provider.phone.value' | translate }} : </strong>
                    {{ item.provider.phone }}
                </p>

                <!-- N° immatriculation entreprise (Siren, Siret…) -->

                <p class="read">
                    <strong>{{ 'provider.identificationNumber.value' | translate }} : </strong>
                    {{ item.provider.identificationNumber }}
                </p>

                <!-- Site web -->

                <p class="read">
                    <strong>{{ 'provider.website.value' | translate }} : </strong>
                    {{ item.provider.mainWebsite }}
                </p>

                <!-- Adresse -->

                <p class="read">
                    <strong>{{ 'provider.address.address.value' | translate }} : </strong>
                    {{ mailingAddress.address }}
                </p>

                <!-- Code postal -->

                <p class="read">
                    <strong>{{ 'provider.address.zipcode.value' | translate }} : </strong>
                    {{ mailingAddress.zipcode }}
                </p>

                <!-- Ville -->

                <p class="read">
                    <strong>{{ 'provider.address.city.value' | translate }} : </strong>
                    {{ mailingAddress.city }}
                </p>

                <!-- Pays -->

                <p class="read">
                    <strong>{{ 'provider.address.country.value' | translate }} : </strong>
                    {{ countryTranslation?.name }}
                </p>
            </div>
        </div>
    </div>

</ng-template>

<!-- Données offres associées -->

<ng-template #associatedOffersData>

    <div>
        <p class="read">
            <strong>{{ 'provider.marketplace.value' | translate }}</strong> : {{ item.totalMarketplaceOffers }}
        </p>
    </div>

    <div *ngFor="let offer of associatedOffers$ | async">

        <p class="read">
            <strong>- <a style="color: #000000" [routerLink]="['/account/offer/read', offer.id]" [queryParams]="{ origin: 'societyProvider', societyProvider: item.id }">{{ translationService.getFallbackTranslation(offer.translations).name }} {{ offer.reference ? '(' + offer.reference + ')' : '' }}</a></strong>
        </p>

    </div>

</ng-template>

<!-- Données commissions -->

<ng-template #commissionData>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'provider.commission.individualStay.value' | translate }}</strong> : {{ commissionIndividualStay }}
                </p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'provider.commission.individualDay.value' | translate }}</strong> : {{ commissionIndividualDay }}
                </p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'provider.commission.groupStay.value' | translate }}</strong> : {{ commissionGroupStay }}
                </p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'provider.commission.groupDay.value' | translate }}</strong> : {{ commissionGroupDay }}
                </p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'provider.commission.incomingPayment.value' | translate }}</strong> : {{ incomingPayment }}
                </p>
            </div>
        </div>
    </div>

</ng-template>
