import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Promotion} from "@core/shared/models/promotion";

@Injectable()
export class PromotionSelectionService {

    public item$: BehaviorSubject<Promotion> = new BehaviorSubject(null);

    get item(): Promotion {

        return this.item$.getValue();
    }
}
