<!-- Affichage de la sélection -->

<ng-container *ngIf="configuration.displaySelection">

    <!-- Sélection multiple -->

    <ng-container *ngIf="configuration.multiple; else singleSelection">
        <div class="row marges" *ngIf="selectedItems && selectedItems.length">
            <div class="col y_items_center">
                <div class="selected_items">
                    <span>{{ configuration.currentSelectionLabel | translate }} :</span>
                    <ul>
                        <li *ngFor="let item of selectedItems; let i = index">
                            {{ configuration.itemLabel(item, i) }}<mat-icon *ngIf="configuration.deselectionEnabledCallback(item) && !controlDisabled" (click)="unselectItem(item, i)">close</mat-icon>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Sélection unique -->

    <ng-template #singleSelection>

        <div class="row marges" *ngIf="selectedItem">
            <div class="col y_items_center">
                <div class="selected_items">
                    <span>{{ configuration.currentSelectionLabel | translate }} :</span>
                    <ul>
                        <li>
                            {{ configuration.itemLabel(selectedItem) }}<mat-icon *ngIf="configuration.deselectionEnabledCallback(selectedItem) && !controlDisabled" (click)="unselectItem(selectedItem)">close</mat-icon>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-template>

</ng-container>

<!-- Recherche -->

<ng-container *ngIf="configuration.enableSearch">

    <div class="form-group" [formGroup]="formGroupReference">
        <mat-select placeholder="{{ configuration.searchActionLabel | translate }}" [formControlName]="formControlNameReference" [compareWith]="configuration.compareOptionsCallback" (closed)="handleClosure()" [multiple]="configuration.multiple">
            <mat-option>
                <ngx-mat-select-search #nameFilter [formControl]="optionFilterNameControl" [placeholderLabel]="configuration.selectOptionActionLabel | translate"></ngx-mat-select-search>
            </mat-option>
            <ng-container *ngIf="(items | async) as items">
                <mat-option disabled="true" *ngIf="!items.length">{{ configuration.noResultLabel | translate }}</mat-option>
                <mat-option *ngFor="let item of items; let i = index" [value]="item" [disabled]="!configuration.deselectionEnabledCallback(item)">
                    {{ configuration.itemLabel(item, i) }}
                </mat-option>
            </ng-container>
        </mat-select>
    </div>

</ng-container>
