<form [formGroup]="form" class="form_booking">

    <div class="row marges" *ngIf="origin !== 'gift-voucher'">
        <div class="col_12">
            <div class="txt_offer">
                <p class="title">{{ 'booking.customer.title.value' | translate }}</p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isOneOfTheseOrigins(['booking', 'gift-voucher'])">

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Sélection du type de société -->

                <div class="form-group">
                    <select class="form-control" id="customerType" formControlName="customerType">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let customerType of customerTypes" [ngValue]="customerType.id">
                            {{ translationService.getFallbackTranslation(customerType.translations).label }}
                        </option>
                    </select>
                    <label for="customerType">{{ 'customer.type.title.value' | translate }}</label>
                    <mat-error *ngIf="form.get('customerType').errors && (form.get('customerType').dirty || form.get('customerType').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('customerType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <ng-container *ngIf="isSocietyRequired">

                <div class="col_12 col_md_6">

                    <!-- Société -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="society" id="society" [required]="isSocietyRequired">
                        <label for="society">{{'society.value' | translate}}</label>
                        <mat-error *ngIf="form.get('society').errors && (form.get('society').dirty || form.get('society').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('society').errors.societyRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </ng-container>

        </div>

        <div class="row marges ligne_form">

            <div class="col_12">

                <!-- Civilité -->

                <div class="form-group">
                    <label class="required">{{'form.user.fields.civility.title.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" value="1" formControlName="civility" class="form-control" id="civility-mister">
                        <label for="civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" value="2" formControlName="civility" class="form-control" id="civility-madam">
                        <label for="civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                    </div>
                    <mat-error
                        *ngIf="form.get('civility').errors && (form.get('civility').dirty || form.get('civility').touched)">
                        <div class="invalid-feedback">
                            <div
                                *ngIf="form.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

    </ng-container>

    <div class="row marges ligne_form">

        <div class="col_12 col_md_6">

            <!-- Nom -->

            <div class="form-group">
                <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName" [attr.required]="isOneOfTheseOrigins(['booking', 'gift-voucher']) ? '' : null">
                <label for="lastName">{{'form.user.fields.lastname.value' | translate}}</label>
                <mat-error *ngIf="form.get('lastName').errors && (form.get('lastName').dirty || form.get('lastName').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('lastName').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
        <div class="col_12 col_md_6">

            <!-- Prénom -->

            <div class="form-group">
                <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName" [attr.required]="isOneOfTheseOrigins(['booking', 'gift-voucher']) ? '' : null">
                <label for="firstName">{{'form.user.fields.firstname.value' | translate}}</label>
                <mat-error *ngIf="form.get('firstName').errors && (form.get('firstName').dirty || form.get('firstName').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('firstName').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isOneOfTheseOrigins(['booking', 'gift-voucher'])">

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Email -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="userEmail" required="required">
                    <label for="userEmail">{{'form.user.fields.email.contact.value' | translate}}</label>
                    <mat-error *ngIf="form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Téléphone -->

                <div class="form-group">

                    <app-core-intl-tel-input [formGroup]="form" [controlName]="'phone'" [required]="true" customPlaceholder="{{'form.user.fields.directPhone.value' | translate}}"></app-core-intl-tel-input>

                    <mat-error *ngIf="form.get('phone').errors && (form.get('phone').pristine || form.get('phone').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

    </ng-container>

    <div class="row marges ligne_form">

        <ng-container *ngIf="isOneOfTheseOrigins(['request'])">

            <div class="col_12 col_md_6">

                <!-- Langue parlée par le client -->

                <div class="form-group">

                    <select class="form-control" id="spokenLanguage" formControlName="spokenLanguage">
                        <option *ngFor="let spokenLanguage of spokenLanguages" [value]="spokenLanguage">
                            {{ translationBuilder.getLocaleLabel(spokenLanguage) | translate }}
                        </option>
                        <option [ngValue]="null">{{ 'other.value' | translate }}</option>
                    </select>
                    <label for="spokenLanguage">{{ 'language.spoken.value' | translate }}</label>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Pays -->

                <ng-container *ngTemplateOutlet="countryControl"></ng-container>
            </div>

        </ng-container>

        <ng-container *ngIf="isOneOfTheseOrigins(['booking', 'gift-voucher'])">

            <div class="col_12 col_md_6">

                <!-- Langue -->

                <div class="form-group">
                    <select class="form-control" id="locale" formControlName="locale" required="required">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let locale of locales" [value]="locale">
                            {{ translationBuilder.getLocaleLabel(locale) | translate }}
                        </option>
                    </select>
                    <label class="required" for="locale">{{ 'language.value' | translate }}</label>
                    <mat-error *ngIf="form.get('locale').errors && (form.get('locale').dirty || form.get('locale').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>

                </div>
            </div>

        </ng-container>

    </div>

    <ng-container *ngIf="isOneOfTheseOrigins(['request'])">

        <div class="row marges ligne_form">

            <ng-container *ngIf="form.get('hasOtherSpokenLanguages').value">

                <div class="col_12 col_md_6">

                    <!-- Autre langue parlée -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="otherSpokenLanguages" id="otherSpokenLanguages">
                        <label for="otherSpokenLanguages">{{ 'language.spoken.other.value' | translate }}</label>
                        <mat-error *ngIf="form.get('otherSpokenLanguages').invalid && (form.get('otherSpokenLanguages').dirty || form.get('otherSpokenLanguages').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('otherSpokenLanguages').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </ng-container>

        </div>

    </ng-container>

    <ng-container *ngIf="isOneOfTheseOrigins(['booking', 'gift-voucher'])">

        <div class="row marges ligne_form">
            <div class="col_12 y_items_center">
                <app-core-google-places-location [fields]="getAddressLocationFields(form)"></app-core-google-places-location>
            </div>
        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Adresse -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                    <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                    <mat-error
                        *ngIf="form.get('address').errors && (form.get('address').dirty || form.get('address').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Complément d'adresse -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                    <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Code postal -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode" required="required">
                    <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                    <mat-error
                        *ngIf="form.get('zipcode').errors && (form.get('zipcode').dirty || form.get('zipcode').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Ville -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city" required="required">
                    <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                    <mat-error
                        *ngIf="form.get('city').errors && (form.get('city').dirty || form.get('city').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Région -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region">
                    <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                    <mat-error
                        *ngIf="form.get('region').errors && (form.get('region').dirty || form.get('region').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Pays -->

                <ng-container *ngTemplateOutlet="countryControl"></ng-container>
            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="isOneOfTheseOrigins(['booking', 'gift-voucher'])">

        <div class="row marges ligne_form">

            <!-- Commentaire -->

            <div class="col_12">
                <div class="form-group">
                    <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                    <label for="comment">{{ 'comment.value' | translate }}</label>
                    <div class="form-group col_content y_items_center">
                    <span class="message-help">
                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}
                    </span>
                    </div>
                    <mat-error *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="isOneOfTheseOrigins(['request'])">

        <ng-content select="[offerCreatorCommentContent]"></ng-content>

    </ng-container>

    <div class="row marges ligne_form">

        <div class="col_12">

            <!-- Acception des conditions générales -->

            <div class="form-group">
                <div class="checkbox">
                    <input type="checkbox" id="acceptTermsAndConditions" formControlName="acceptTermsAndConditions">
                    <label for="acceptTermsAndConditions">{{'acceptTermsAndConditions.success.action.description.value' | translate}} <a (click)="openTermsAndConditionsInNewTab($event)" target="_blank">{{ 'acceptTermsAndConditions.value' | translate }}</a></label>
                    <mat-error
                        *ngIf="form.get('acceptTermsAndConditions').errors && (form.get('acceptTermsAndConditions').dirty || form.get('acceptTermsAndConditions').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('acceptTermsAndConditions').errors.required">{{ 'acceptTermsAndConditions.form.invalid.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="col_12">

            <!-- Acceptation des conditions d'utilisations -->

            <div class="form-group">
                <div class="checkbox">
                    <input type="checkbox" id="acceptConditionsUse" formControlName="acceptConditionsUse">
                    <label for="acceptConditionsUse">{{'acceptTermsAndConditions.success.action.description.value' | translate}} <a (click)="openConditionUseDialog($event)">{{ 'acceptConditionsUse.value' | translate }}</a> {{ 'acceptConditionsUse.description.value' | translate }}</label>
                    <mat-error
                        *ngIf="form.get('acceptConditionsUse').errors && (form.get('acceptConditionsUse').dirty || form.get('acceptConditionsUse').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('acceptConditionsUse').errors.required">{{ 'acceptConditionsUse.form.invalid.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
    </div>

</form>

<ng-template #countryControl>

    <div [formGroup]="form" class="form-group">
        <select class="form-control" id="country" formControlName="country" required="required">
            <option [ngValue]="null"></option>
            <option *ngFor="let country of countries$ | async" [value]="country.code">
                {{ translationService.getFallbackTranslation(country.translations).name }}
            </option>
        </select>
        <label class="required" for="country">{{ 'form.user.fields.country.value' | translate }}</label>
        <mat-error *ngIf="form.get('country').invalid && (form.get('country').dirty || form.get('country').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
            </div>
        </mat-error>
    </div>

</ng-template>
