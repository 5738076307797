import {Injectable} from '@angular/core';
import {IApiFileConfig, IFileConfig, IFileExtensionIconClass, IFileTemplate} from "./file";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * PREFIX URL
     */
    private readonly PREFIX_URL: string = '/file';

    /**
     * CONFIG URL
     */
    private readonly CONFIG_URL: string = '/config';

    private _config: IFileConfig;

    private _allowedFileTypes: string[] = [

        // Image

        'image/*', // .jpg, .jpeg, .png, .gif

        // Adobe

        'application/pdf', // .pdf

        // Archive

        'application/zip', // .zip
        'application/x-rar-compressed', // .rar
        'application/x-tar', // .tar
        'application/x-7z-compressed', // .7z

        // Tableau

        'text/csv', // .csv

        // Microsoft

        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.ms-powerpoint', // .ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx

        // Open Office

        'application/vnd.oasis.opendocument.text', // .odt
        'application/vnd.oasis.opendocument.presentation', // .odp
        'application/vnd.oasis.opendocument.spreadsheet', // .ods
    ];

    private _fileExtensionIconClass: IFileExtensionIconClass[] = [
        {
            extensions: ['jpg', 'jpeg', 'png', 'gif'],
            iconClass: 'fa-file-image-o'
        },
        {
            extensions: ['pdf'],
            iconClass: 'fa-file-pdf-o'
        },
        {
            extensions: ['zip', 'rar', 'tar', '7z'],
            iconClass: 'fa-file-archive-o'
        },
        {
            extensions: ['odt'],
            iconClass: 'fa-file-text-o'
        },
        {
            extensions: ['doc', 'docx'],
            iconClass: 'fa-file-word-o'
        },
        {
            extensions: ['ppt', 'pptx', 'odp'],
            iconClass: 'fa-file-powerpoint-o'
        },
        {
            extensions: ['xls', 'xlsx', 'ods'],
            iconClass: 'fa-file-excel-o'
        }
    ];

    constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    configLoaded(): boolean {

        return (typeof this._config !== 'undefined');
    }

    setConfig(config: IFileConfig): void {

        this._config = config;
    }

    /**
     * Retourne la configuration nécessaire au chargement d'une page
     *
     * @returns {Observable<IApiFileConfig>}
     */
    loadConfig(): Observable<IApiFileConfig> {

        return this._http.get<IApiFileConfig>(`${this.API_URL}${this.PREFIX_URL}${this.CONFIG_URL}`);
    }

    /**
     * Retourne la taille maximale autorisée pour l'upload d'un fichier
     *
     * @returns {number}
     */
    getUploadMaxSize(): number {

        return this._config.uploadMaxSize;
    }

    /**
     * Retourne la liste des Mime Types autorisés
     *
     * @returns {string[]}
     */
    getAllowedFileTypes(): string[] {

        return this._allowedFileTypes;
    }

    /**
     * Retourne la classe d'une icône de fichier en fonction de l'extension
     *
     * @param {string} extension
     *
     * @returns {string}
     */
    getFileIconClassByExtension(extension: string): string {

        const match = this._fileExtensionIconClass.filter((element: IFileExtensionIconClass) => {
            return element.extensions.indexOf(extension) >= 0;
        }).shift();

        if (!match)
            return '';

        return match.iconClass;
    }

    /**
     * Retourne la liste des templates disponibles pour le bouton de visualisation du fichier
     *
     * @returns {IFileTemplate[]}
     */
    get templates(): IFileTemplate[]{

        if(!this._config){

            return [];
        }

        return this._config.templates;
    }
}
