import {Injectable} from "@angular/core";
import {MatLegacyPaginatorIntl as MatPaginatorIntl} from "@angular/material/legacy-paginator";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {

    public constructor(
        private _translateService: TranslateService
    ) {

        super();

        this._translateService.get(['']).subscribe((): void => {

            this.nextPageLabel = this._translateService.instant('paginator.nextPage.value');

            this.previousPageLabel = this._translateService.instant('paginator.previousPage.value');

            this.itemsPerPageLabel = this._translateService.instant('paginator.itemsPerPage.value');

            this.firstPageLabel = this._translateService.instant('paginator.firstPage.value');

            this.lastPageLabel = this._translateService.instant('paginator.lastPage.value');

            this.getRangeLabel = this.getTranslateRange;
        });
    }

    private getTranslateRange(page: number, pageSize: number, length: number): string {

        if (length === 0 || pageSize === 0) {

            return this._translateService.instant('paginator.fromTo.value', {
                from: 0,
                to: length
            });
        }

        length = Math.max(length, 0);

        const startIndex: number = page * pageSize;

        const endIndex: number = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return this._translateService.instant('paginator.interval.value', {
            minStart: startIndex + 1,
            maxStart: endIndex,
            end: length
        });
    }
}
