import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../api/api.service";
import {TranslateService} from "@ngx-translate/core";
import {ImageConfig} from "../image/image";
import {HttpErrorResponse} from "@angular/common/http";
import {SlideshowService} from "./slideshow.service";
import {CmsService} from "../../../cms.service";
import {IApiSlideshowConfig, Slideshow, SlideshowFormat} from "./slideshow";
import {OptionsImage} from "../../../cms";
import {Gallery} from "@lib/media/gallery";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Image, ImageFormat} from "@lib/media/image/image";

@Component({
    selector: 'cms-element-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

    @Input() content: Slideshow;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<Slideshow> = new EventEmitter<Slideshow>();

    configInitialized: boolean = false;

    gallery: Gallery;

    slideshowFormats: SlideshowFormat[] = [];

    options: OptionsImage;

    /**
     * Nombre d'image maximum pouvant être ajoutées au slideshow
     */
    max: number;

    /**
     *
     * @param _apiService
     * @param _cmsService
     * @param _slideshowService
     * @param _translateService
     * @param _snackbar
     */
    constructor(
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _slideshowService: SlideshowService,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar
    ) {
        this.max = 100;
    }

    ngOnInit() {
        if (!this.content) {

            this.content = {
                images: [],
                format: {
                    label : null,
                    format : null
                }
            };

        }

        this._slideshowService.setApiUrl(this.apiUrl);

        let optionsImage: OptionsImage = {
            enableTitle: false,
            enableSubtitle: false,
            enableAlt: false,
            enableLink: false,
            enableTargetBlank: false
        };

        const configOption: OptionsImage = this._cmsService.getOptionsImage();

        optionsImage = Object.assign(configOption, {
            enableTitle: configOption.enableTitle,
            enableSubtitle: configOption.enableSubtitle,
            enableAlt: configOption.enableAlt,
            enableLink: configOption.enableLink,
            enableTargetBlank: configOption.enableTargetBlank
        });

        this.options = optionsImage;

        const context: string = this._cmsService.getContext();

        this._slideshowService.loadConfig(context).subscribe((data: IApiSlideshowConfig) => {

            this._slideshowService.setFormats(data.formats);

            this.slideshowFormats = data.formats;

            if(!this.content.format.format){
                this.content.format = data.formats[0];
            }

        });



            this._slideshowService.loadConfigGallery(context).subscribe((config: ImageConfig) => {

                    this._slideshowService.setConfig(config);

                    this.gallery = config.gallery;

                    this.configInitialized = true;


                },
                (error: HttpErrorResponse) => {

                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                        this._snackbar.open(error.error, messages['action.close']);
                    });
                }
            );

    }

    /**
     * @param images
     */
    add(images: Image[]): void {

        this.content.images = images;

        if (this.content.format) {
            this.changeFormat(this.content.format);
        } else {
            this.update.emit(this.content);
        }


    }

    /**
     * @param images
     */
    delete(images: Image[]): void {

        this.content.images = images;

        this.update.emit(this.content);
    }

    /**
     * @param images
     */
    changePosition(images: Image[]): void {

        this.content.images = images;

        this.update.emit(this.content);
    }

    changeFormat(formatSlideshow: SlideshowFormat) {


        this.content.images.forEach((image: Image): void => {

            image.formats.forEach((formatImage: ImageFormat): void => {

                if (formatImage.name == formatSlideshow.format) {
                    formatImage.selected = true;
                }else{
                    formatImage.selected = false;
                }

            });

            this._slideshowService.setFormatImage(image).subscribe(() => {

            }, (error: HttpErrorResponse) => {
                this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(error.error, messages['action.close']);
                });
            });

        });

        this.update.emit(this.content);
    }
}
