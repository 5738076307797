import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import Swiper, {Navigation, Pagination} from "swiper";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-core-offer-creator-picture-gallery',
    templateUrl: './offer-creator-picture-gallery.component.html',
    styleUrls: ['./offer-creator-picture-gallery.component.scss']
})
export class OfferCreatorPictureGalleryComponent implements AfterViewInit {

    @Input() offerCreator: Society;

    @ViewChild('swiper', {static: true}) swiperRef: ElementRef<HTMLElement>;

    @ViewChild('swiperPagination', {static: true}) swiperPaginationRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonPrev', {static: true}) swiperButtonPrevRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonNext', {static: true}) swiperButtonNextRef: ElementRef<HTMLElement>;

    ngAfterViewInit() {

        this._initSlider();
    }

    private _initSlider(): void {

        new Swiper(this.swiperRef.nativeElement, {
            loop: false,
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: false,
            modules: [
                Navigation,
                Pagination
            ],
            navigation: {
                nextEl: this.swiperButtonNextRef.nativeElement,
                prevEl: this.swiperButtonPrevRef.nativeElement,
            },
            pagination: {
                el: this.swiperPaginationRef.nativeElement,
                clickable: true
            }
        });
    }
}
