<div [formGroup]="form" class="form-group">
    <media-image [options]="config.options" [image]="image" [apiUrl]="config.uploadApiUrl" [gallery]="galleryObject" (onAdd)="add($event)" (onDelete)="delete()"></media-image>
    <p *ngIf="config.help" class="message-help">{{ config.help }}.</p>

    <mat-error>
        <div *ngIf="form.controls[config.id].invalid">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>
        </div>
    </mat-error>
</div>
