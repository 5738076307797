import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {Image} from "@lib/media/image/image";

@Pipe({
    name: 'imageSource'
})
export class ImageSourcePipe implements PipeTransform {

    constructor(
        private _apiService: ApiService
    ){
    }

    transform(image: Image, format: string, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public/media/images/${image.id}/filter/${format}/${width}/${quality}`;
    }
}
