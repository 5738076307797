import {Injectable} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {OfferPart} from "@core/components/offer/offer-list/offer-list.component";

@Injectable()
export class OfferListService {

    public hydratePartsCallback: (currentParts: OfferPart[], items: Offer[], reset: boolean) => OfferPart[] = (currentParts: OfferPart[], items: Offer[], reset: boolean): OfferPart[] => {

        const parts: OfferPart[] = [];

        parts.push({
            position: 1,
            items: reset ? items : [...currentParts[0].items, ...items]
        });

        return parts;
    };
}
