<div class="collection">

    <ng-container [formGroup]="parentIncluds">

        <ng-container formArrayName="trueIncluded">

            <mat-accordion cdkDropList (cdkDropListDropped)="dragAndDrop($event)">
                <mat-expansion-panel cdkDrag class="mat-expansion-panel-spacing" *ngFor="let _ of includsForm.controls; let i = index" [formGroupName]="indexAsString(i)" >
                    <mat-expansion-panel-header class="movable">
                        <mat-panel-title>
                            <mat-icon aria-label="side icon">menu</mat-icon>
                            <span>{{ getItemTranslationControl(i)?.get('title')?.value?.length ? getItemTranslationControl(i).get('title').value : ('offer.included.index.value' | translate: { index: i + 1 }) }}</span>
                            <span class="{{ includsForm.controls[i].valid ? 'valid' : 'invalid' }}"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="getTranslationBuilder(i)?.items.length">
                        <div class="ligne_form removable_item">

                            <div class="mat-tab-translations">

                                <ng-container formArrayName="translations">

                                    <mat-tab-group [@.disabled]="true">
                                        <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                            <mat-tab label="{{ getTranslationBuilder(i).getLocaleLabel(locale) | translate }}">
                                                <ng-container [formGroupName]="getTranslationBuilder(i).getItemIndexByLocale(locale)">


                                                    <div class="row marges">
                                                        <div class="col_6">
                                                            <!-- Titre -->
                                                            <div class="form-group">
                                                                <input type="text" (mousedown)="$event.stopPropagation()" value="" placeholder=" " class="form-control" formControlName="title" id="offer-includ-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}-title" required="required">
                                                                <label for="offer-includ-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}-title">{{ 'offer.location.title.value' | translate }}</label>
                                                                <mat-error *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('title').errors && (getTranslationBuilder(i).getItemControlByLocale(locale).get('title').dirty || getTranslationBuilder(i).getItemControlByLocale(locale).get('title').touched)">
                                                                    <div class="invalid-feedback">
                                                                        <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('title').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthTitle } }}</div>
                                                                    </div>
                                                                </mat-error>
                                                            </div>
                                                        </div>
                                                        <div class="col_6">
                                                            <!-- Description -->
                                                            <div class="form-group">
                                                                <input type="text" (mousedown)="$event.stopPropagation()" value="" placeholder=" " class="form-control" formControlName="description" id="offer-includ-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}-description">
                                                                <label for="offer-includ-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}-description">{{ 'offer.location.description.value' | translate }}</label>
                                                            </div>
                                                            <mat-error *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('description').errors && (getTranslationBuilder(i).getItemControlByLocale(locale).get('description').dirty || getTranslationBuilder(i).getItemControlByLocale(locale).get('description').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('description').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthDescription } }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                </ng-container>
                                            </mat-tab>
                                        </div>
                                    </mat-tab-group>

                                </ng-container>
                            </div>

                            <!-- Suppression -->
                            <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="deleteInclud(i)">
                                <mat-icon>delete</mat-icon>
                            </button>

                        </div>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>

        </ng-container>

    </ng-container>

    <div class="ligne_form">
        <button class="btn_cta btn_little" type="button" (click)="addInclud()">{{ 'offer.form.fields.included.actions.add.value' | translate }}</button>
    </div>

</div>

