<div mat-dialog-title class="dialog-title">
    <h1 mat-dialog-title class="x_center">{{ 'authentication.welcome.title.value' | translate }}</h1>
    <button type="button" style="position: absolute;top: 0;right: 0;" (click)="confirm.emit()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>

    <div class="row marges ligne_form add_cgv">

        <div class="col_12">

            <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR'); else distributorContent">

                <p [innerHTML]="'authentication.welcome.offerCreator.description.value' | translate"></p>

            </ng-container>

            <ng-template #distributorContent>

                <p [innerHTML]="'authentication.welcome.cgv.description.value' | translate"></p>

                <p [innerHTML]="'authentication.welcome.cgv.access.value' | translate"></p>

                <div class="row marges ligne_form" style="font-size: 12px;">

                    <div class="col_12">
                        <div class="row content_cgv">

                            <div class="col_offset_3 col_6 x_center">
                                <button class="btn_cta btn_small"
                                        (click)="goToCgv()">{{ 'authentication.welcome.cgv.button.value' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <br><br>

            </ng-template>

            <br/>
        </div>
    </div>

    <div class="row marges ligne_form" style="font-size: 12px;">

        <div class="col_12">

            <h4 style="text-align: center;" [innerHTML]="'authentication.welcome.description.value' | translate"></h4>

            <br>

            <div class="row content_list">

                <!-- CONTACT SUPPORT -->

                <div class="col_6 x_end">
                    <button class="btn_cta btn_small" (click)="navigateToPage(24)">{{ 'authentication.welcome.support.contact.value' | translate }}</button>
                </div>

                <!-- FAQ -->

                <div class="col_6 x_start">
                    <button class="btn_cta btn_small last" (click)="navigateToCategory(5)">{{ 'authentication.welcome.faq.value' | translate }}</button>
                </div>

            </div>
        </div>
    </div>

</mat-dialog-content>
