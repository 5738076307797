import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import { google } from "google-maps";

@Injectable({
    providedIn: 'root'
})
export class GoogleService {

    constructor(
        private _httpClient: HttpClient,
        private _apiService: ApiService
    ) {}

    public placeAutocompleteAPI(data: object): Observable<google.maps.places.AutocompleteResponse> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<google.maps.places.AutocompleteResponse>(`${this._apiService.getApiUrl(false, true)}/google/place/autocomplete`, data, {
            headers: headers
        });
    }

    public placeDetailsAPI(data: object): Observable<{ result: google.maps.places.PlaceResult }> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<{ result: google.maps.places.PlaceResult }>(`${this._apiService.getApiUrl(false, true)}/google/place/details`, data, {
            headers: headers
        });
    }
}
