import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {Pagination} from "../models/pagination";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "./api.service";
import {Promotion} from "../models/promotion";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {PromotionSubscription} from "@core/shared/models/promotion-subscription";
import {Category} from "@core/shared/models/category";

@Injectable({
    providedIn: 'root'
})
export class PromotionSubscriptionService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<PromotionSubscription>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/subscriptions/promotions`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<PromotionSubscription>>(url, {
            headers: headers
        });

    }

    public getItemAPI(id: number, params?: string[]): Observable<PromotionSubscription> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/subscriptions/promotions/${id}`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<PromotionSubscription>(url, {
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<PromotionSubscription> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<PromotionSubscription>(`${this._apiService.getApiUrl(false, true)}/subscriptions/promotions`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

            const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/subscriptions/promotions/${id}`,{
            headers: headers
        });
    }

}
