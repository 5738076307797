import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-core-offer-catalog-request-view-dialog',
    templateUrl: './offer-catalog-request-view-dialog.component.html',
    styleUrls: ['./offer-catalog-request-view-dialog.component.scss']
})
export class OfferCatalogRequestViewDialogComponent implements OnInit {

    constructor(
        private _dialogRef: MatDialogRef<OfferCatalogRequestViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number }
    ) {
    }

    ngOnInit(): void {
    }

    public close(): void {

        this._dialogRef.close();
    }

    get id(): number {

        return this.data.id;
    }
}
