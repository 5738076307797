<div class="row">
    <div class="actions actions-row">

        <button type="button" mat-stroked-button [disabled]="!isDecreaseAllowed" (click)="decreasePosition.emit()" matTooltip="{{ 'row.move.top.action' | translate }}" matTooltipPosition="right">
            <mat-icon>arrow_upward</mat-icon>
        </button>

        <button type="button" mat-stroked-button [disabled]="!isIncreaseAllowed" (click)="increasePosition.emit()" matTooltip="{{ 'row.move.bottom.action' | translate }}" matTooltipPosition="right">
            <mat-icon>arrow_downward</mat-icon>
        </button>

        <button type="button" mat-stroked-button (click)="delete.emit()" class="delete mat-warn" matTooltip="{{ 'row.delete.action' | translate }}" matTooltipPosition="right">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
    <div class="elements">
        <app-column
                *ngFor="let column of row.columns | orderBy : ['position']"
                [column]="column"
                [template]="row.template"
                [isDecreaseAllowed]="isDecreaseColumnAllowed(column)"
                [isIncreaseAllowed]="isIncreaseColumnAllowed(column)"
                (decreasePosition)="decreaseColumnPosition(column)"
                (increasePosition)="increaseColumnPosition(column)">
        </app-column>
    </div>
</div>
