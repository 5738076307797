import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {ImageGallery, PaginatorConfig, ImageGalleryDialogData, OrderByType} from "./image-gallery-dialog";
import {ImageGalleryDialogService} from "./image-gallery-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {Image} from "../image";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpErrorResponse} from "@angular/common/http";
import {ImageService} from "../image.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Pagination} from "@core/shared/models/pagination";
import {debounceTime, map} from "rxjs/operators";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {fromEvent} from "rxjs";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";

@Component({
    selector: 'lib-image-gallery-dialog',
    templateUrl: './image-gallery-dialog.component.html',
    styleUrls: ['./image-gallery-dialog.component.scss', '../image.component.scss'],
    providers: [
        ImageGalleryDialogService,
        ImageService
    ]
})
export class ImageGalleryDialogComponent implements OnInit, AfterViewInit {

    @ViewChild('search', { static: true }) searchReference: ElementRef<HTMLInputElement>;

    private _totalAvailableAddition: number;

    public onAdd = new EventEmitter<Image>();

    public onRemove = new EventEmitter<Image>();

    public images: ImageGallery[] = [];

    public updateImage = new EventEmitter<Image>();

    public titleFilter: string;

    public paginatorConfig: PaginatorConfig;

    public showAddImage: boolean = false;

    public totalItems: number = 0;

    public constructor(
        public dialogRef: MatDialogRef<ImageGalleryDialogComponent>,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _imageGalleryService: ImageGalleryDialogService,
        private _imageService: ImageService,
        private _snackbar: MatSnackBar,
        private _sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: ImageGalleryDialogData
    ) {
    }

    ngOnInit(): void {

        this._totalAvailableAddition = this.data.totalAvailableAddition;

        this._imageGalleryService.setApiUrl(this.data.apiUrl);

        this._translateService.get(['media.image.gallery.pagination.action.next.title', 'media.image.gallery.pagination.action.previous.title']).subscribe((messages: string[]) => {

            this.paginatorConfig = {
                itemsPerPage: 10,
                currentPage: 1,
                maxSize: 10,
                labels: {
                    next: messages['media.image.gallery.pagination.action.next.title'],
                    previous: messages['media.image.gallery.pagination.action.previous.title']
                }
            };
        });
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            this._initSearchEvent();

            this._loadItems();
        });
    }

    private _initSearchEvent(): void {

        fromEvent(this.searchReference.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this.paginatorConfig.currentPage = 1;

                this._loadItems();
            })
        ;
    }

    private _loadItems(): void {

        this._imageGalleryService.getImagesGallery(this.data.gallery, this.apiParams)
            .pipe(
                map(this.mapPaginationApiResult)
            )
            .subscribe(
                (images: ImageGallery[]) => {

                    this.images = images;

                    this.images.map(this.loadImage.bind(this));
                },
                error => {

                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {

                        this._snackbar.open(error, messages['action.close'], {
                            duration: 5000
                        });
                    });
                }
            );
    }

    public goToPage(page: number): void {

        this.paginatorConfig.currentPage = page;

        this._loadItems();
    }

    public close(): void {

        this.dialogRef.close();
    }

    /**
     * Méthode appelée une fois l'upload de fichier terminé avec succès
     */
    public postUpload(): void {

        this.paginatorConfig.currentPage = 1;

        this._loadItems();
    }

    /**
     * @param {ImageGallery} image
     */
    public deleteImageGallery(image: ImageGallery): void {

        this._translateService.get(['media.image.gallery.delete.confirm.title', 'media.image.gallery.delete.confirm.content']).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title: messages['media.image.gallery.delete.confirm.title'],
                content: messages['media.image.gallery.delete.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._imageGalleryService.deleteImageGallery(this.data.gallery, image).subscribe(
                    () => {

                        this._loadItems();

                        this._translateService.get(['media.image.gallery.delete.success', 'action.close']).subscribe((messages: string[]) => {

                            this._snackbar.open(messages['media.image.gallery.delete.success'], messages['action.close'], {
                                duration: 5000
                            });
                        });
                    },
                    (errorResponse: HttpErrorResponse) => {

                        this._translateService.get(['action.close']).subscribe((messages: string[]) => {

                            this._snackbar.open(errorResponse.error, messages['action.close'], {
                                duration: 5000
                            });
                        });
                    }
                );
            });
        });
    }

    /**
     * @param image
     */
    public addImage(image: ImageGallery): void {

        this._imageGalleryService.getCloneImage(image).subscribe(
            (cloneImage: Image) => {

                if (this.data.type == 'image') {

                    this.data.usedImages.push(cloneImage);
                }

                this._totalAvailableAddition--;

                this.showAddImage = true;

                this.onAdd.emit(cloneImage);
            },
            error => {

                this.showAddImage = false;

                this._translateService.get(['action.close']).subscribe((messages: string[]) => {

                    this._snackbar.open(error, messages['action.close'], {
                        duration: 5000
                    });
                });
            }
        );
    }

    public removeImage(image: Image): void {

        this._imageService.setApiUrl(this._imageGalleryService.getApiUrl());

        this.data.usedImages = this.data.usedImages.filter(
            (elem: Image) => {
                return elem.id !== image.id;
            }
        );

        this._totalAvailableAddition = this._totalAvailableAddition + 1;

        if (this.data.type == 'image') {

            this.updateImage.emit(null);

            this.onRemove.emit(null);

        } else {

            this.updateImage.emit(image);

            this.onRemove.emit(image);
        }
    }


    /**
     * @return boolean
     */
    public isAdditionEnabled(): boolean {

        return this._totalAvailableAddition > 0;
    }

    /**
     * @param image
     */
    public loadImage(image: ImageGallery): void {

        this._imageGalleryService.getBinaryImage(image).subscribe((blob: Blob) => {

            const objectURL = URL.createObjectURL(blob);

            image.url = this._sanitizer.bypassSecurityTrustUrl(objectURL);
        })
    }

    public isUsed(image: ImageGallery): boolean {

        const match: Image = this.data.usedImages.filter((usedImage: Image): boolean => {

            return usedImage.reference && (usedImage.reference.id === image.id);

        }).shift();

        this.getRefImage(image);

        return (typeof match !== 'undefined');
    }

    public getRefImage(image: ImageGallery): Image {

        return this.data.usedImages.filter((usedImage: Image): boolean => {

            return usedImage.reference && (usedImage.reference.id === image.id);

        }).shift();
    }

    public get apiParams(): string[] {

        const params: string[] = [];

        params.push(`sort[id]=desc`);

        params.push(`page=${this.paginatorConfig.currentPage}`);

        params.push(`limit=${this.paginatorConfig.itemsPerPage}`);

        if(this.titleFilter && this.titleFilter.length){

            const titleFilter: TextFilterField = new TextFilterField('galleryImage.image.title', 'lk', this.titleFilter);

            params.push(titleFilter.serialize);
        }

        return params;
    }

    get mapPaginationApiResult(): (data: Pagination<ImageGallery>) => ImageGallery[] {

        return (data: Pagination<ImageGallery>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }
}
