import {StatusType} from "@core/shared/models/status-type";

export const BOOKING_STATUS: StatusType[] =  [
    {
        id: 'to-process',
        label: 'booking.status.to-process.value'
    },
    {
        id: 'canceled',
        label: 'booking.status.canceled.value'
    },
    {
        id: 'validated',
        label: 'booking.status.validated.value'
    },
    {
        id: 'in-progress',
        label: 'booking.status.in-progress.value'
    },
    {
        id: 'option-set',
        label: 'booking.status.option-set.value'
    }
];

export const BOOKING_PAYMENT_STATUS: StatusType[] = [
    {
        id: 'pending',
        label: 'booking.paymentStatus.pending.value'
    },
    {
        id: 'paid',
        label: 'booking.paymentStatus.paid.value'
    },
    {
        id: 'failed',
        label: 'booking.paymentStatus.failed.value'
    }
];