import {AfterViewInit, Component, Input} from '@angular/core';
import {Address} from "@core/shared/models/address";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {Observable} from "rxjs";
import {Role, ROLE_LABELS, RoleLabel} from "@core/shared/models/role";
import {TranslateService} from "@ngx-translate/core";
import {User} from "@core/shared/models/user";

@Component({
    selector: 'app-core-offer-catalog-request-view',
    templateUrl: './offer-catalog-request-view.component.html',
    styleUrls: ['./offer-catalog-request-view.component.scss']
})
export class OfferCatalogRequestViewComponent implements AfterViewInit {

    @Input() id: number;

    item$: Observable<OfferCatalog>;

    constructor(
        private _offerCatalogService: OfferCatalogService,
        private _translateService: TranslateService
    ) {
    }

    ngAfterViewInit(): void {

        this._loadItem();
    }

    private _loadItem(): void {

        this.item$ = this._offerCatalogService.getItemRequestAPI(this.id);
    }

    public getAddressFields(item: OfferCatalog): Address {

        return item.society.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';

        }) || item.society.addresses[0];
    }

    public getRoleLabel(roles: Role[]): string {

        const labels: string[] = roles.map((role: Role): string => {

            const match: RoleLabel = ROLE_LABELS.find((roleLabel: RoleLabel): boolean => {

                return roleLabel.identifier === role;
            });

            return this._translateService.instant(match.label);
        });

        return labels.join(', ');
    }

    public hasOneOfThisRoles(roles: Role[], admin: User): boolean {

        return roles.some((role: Role): boolean => {

            return admin.roles.includes(role);
        });
    }

    public commissionTypeLabel(type: string): string {

        return this._translateService.instant(`distributor.commissionType.${type}.value`);
    }

    public formatCommission(value: number): string {

        return (value * 100).toFixed(2) + this._translateService.instant(`distributor.commissionExtraInfo`);
    }

    public incomingPaymentLabel(value: boolean): string {

        return this._translateService.instant(`${value ? 'yes' : 'no'}.value`);
    }

}
