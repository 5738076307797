<ng-container [formGroup]="form">

    <div class="payment_form">

        <div class="row marges payment_methods x_center">
            <div class="col_content">
                <span class="method_item cb"></span>
            </div>
            <div class="col_content">
                <span class="method_item visa"></span>
            </div>
            <div class="col_content">
                <span class="method_item mastercard"></span>
            </div>
            <div class="col_content">
                <span class="method_item maestro"></span>
            </div>
        </div>

        <!-- Numéro de carte bancaire -->

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <input type="text" number-card class="form-control" formControlName="cardNumber" id="cardNumber" required="required" [control]="form.get('cardNumber')">
                    <label for="cardNumber">{{'payment.fields.cardNumber.value' | translate}}</label>
                    <mat-error *ngIf="form.get('cardNumber').errors && (form.get('cardNumber').dirty || form.get('cardNumber').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('cardNumber').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('cardNumber').errors.invalidNumberCard">{{ 'payment.fields.error.invalidNUmberCard.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <!-- Date d'expiration -->

        <div class="row marges ligne_form">
            <div class="col_6">
                <div class="form-group">
                    <input type="text" class="form-control" date-card formControlName="cardExpirationDate" id="cardExpirationDate" required="required" [control]="form.get('cardExpirationDate')">
                    <label for="cardExpirationDate">{{'payment.fields.cardExpirationDate.value' | translate}}</label>
                    <mat-error *ngIf="form.get('cardExpirationDate').errors && (form.get('cardExpirationDate').dirty || form.get('cardExpirationDate').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('cardExpirationDate').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('cardExpirationDate').errors.invalidFormatDate">{{ 'payment.fields.error.invalidFormatDate.value' | translate }}</div>
                            <div *ngIf="form.get('cardExpirationDate').errors.invalidDatePast">{{ 'payment.fields.error.invalidDatePast.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <!-- CVV -->

            <div class="col_6">
                <div class="form-group">
                    <input type="text" class="form-control" cvv-card formControlName="cardCvx" id="cardCvx" required="required" [control]="form.get('cardCvx')">
                    <label for="cardCvx">{{'payment.fields.cardCvx.value' | translate}}</label>
                    <mat-error *ngIf="form.get('cardCvx').errors && (form.get('cardCvx').dirty || form.get('cardCvx').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('cardCvx').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('cardCvx').errors.invalidFormatCvv">{{ 'payment.fields.error.invalidFormatCvv.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

    </div>

</ng-container>
