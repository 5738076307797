export type OfferPricePublicType = 'child' | 'adult';

export interface OfferPricePublic {

    type: OfferPricePublicType;

    label: string;
}

export const OFFER_PRICE_PUBLICS: OfferPricePublic[] = [
    {
        type: 'adult',
        label: 'offer.public.adult.value'
    },
    {
        type: 'child',
        label: 'offer.public.child.value'
    }
];
