import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyService} from '../services/society.service';
import {Society} from '../models/society';

@Injectable({
    providedIn: 'root'
})
export class SocietyItemsResolver  {

    constructor(
        private _societyService: SocietyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._societyService.getItemsAPI();
    }
}
