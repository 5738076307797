<div [formGroup]="form" class="form-group">
    <mat-label *ngIf="config.attrs.label">{{config.attrs.label}}</mat-label>

    <div class="form-group semi-group">
        <mat-form-field>
            <input matInput placeholder="{{config.attrs.placeholder}}" aria-label="24hr format" [formControlName]="config.id+ '[time]'" [ngxTimepicker]="fullTime" [format]="24"  readonly>
            <ngx-material-timepicker #fullTime></ngx-material-timepicker>
        </mat-form-field>
        <mat-error>
            <div *ngIf="form.controls[config.id+ '[time]'].invalid && (form.controls[config.id+ '[time]'].dirty || form.controls[config.id+ '[time]'].touched)">
                <div *ngIf="form.controls[config.id+ '[time]'].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            </div>
        </mat-error>
    </div>

</div>
