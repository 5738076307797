import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {UserService} from '../services/user.service';
import {User} from '../models/user';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {AuthenticationService} from "../services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class UserSelfItemResolver  {

    constructor(
        private _userService: UserService,
        private _authenticationService: AuthenticationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._userService.getItemAPI(this._authenticationService.id);
    }
}
