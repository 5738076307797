import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from "./confirm-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "@material/material.module";

@NgModule({
    declarations: [
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule
    ],
    exports: [
        ConfirmDialogComponent
    ]
})
export class ConfirmDialogModule {

}
