<div class="form_account">

    <div class="infos_user">
        <div class="row h_full">
            <div class="col y_items_center">
                <span class="name"><span>{{user.firstName[0] + user.lastName[0]}}</span>{{ user.firstName + ' ' + user.lastName }}</span>
            </div>
        </div>
    </div>

    <ng-container>
        <mat-tab-group #tabGroup [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectedIndex">
            <mat-tab #licenceInformationsTab label="{{'subscription.form.licence.value' | translate}}">
                <ng-container *ngTemplateOutlet="licenceInformations"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </ng-container>

    <ng-template #licenceInformations>
        <div class="title_tab">
            <div class="row marges h_full x_between">
                <div class="col_content y_items_center">
                    <span class="title">{{'subscription.form.licence.value' | translate}}</span>
                </div>
            </div>
        </div>

        <div class="content_tab">

            <!-- Formulaire d'abonnement -->

            <div class="ligne_form">
                <subscription-form [subscriptions]="subscriptions" [data]="societySubscriptions" [isDisabled]="true" [redirectionToSubscriptionViewEnabled]="false"></subscription-form>
            </div>

            <div class="ligne_form row marges" id="changeLicence" *ngIf="!societyDelete">

                <!-- Modifier mon abonnement -->

                <ng-container *ngIf="hasAccessPayment && !paymentWaiting && !downgradeWaiting">

                    <div class="col_content" >
                        <button type="button" class="btn_cta btn_little" (click)="updateLicence()">{{'subscription.title.update.value' | translate}}</button>
                    </div>

                </ng-container>

                <!-- Annulation de la demande de downgrade -->

                <div class="col_content" *ngIf="downgradeWaiting">
                    <button type="button" class="btn_cta btn_little" (click)="cancelDowngradeLicence()">{{'subscription.title.cancel_downgrade.value' | translate}}</button>
                </div>
            </div>
            <div *ngIf="societyDelete && societyDeleteDate" class="info_desactivate ligne_form">

                <div class="row">

                    <div class="col_6 y_items_center x_center">
                        <p>{{'subscription.delete.value' | translate : {date: societyDeleteDate | date : 'shortDate' : null : localeId}  }}</p>
                    </div>

                    <!-- Annulation d'une demande de désabonnement pour un créateur --->

                    <div class="col_6 y_items_center x_center">
                        <button type="button" class="btn_cta btn_little" (click)="cancelDeactivationSubscription()">{{'subscription.title.cancel_deactivation.value' | translate}}</button>
                    </div>
                </div>
            </div>

            <!-- Contact TyWin -->

            <div class="ligne_form row x_center">
                <div class="contact_tywin">
                    <p [innerHTML]="'subscription.form.contact_tywin.value' | translate"></p>
                </div>
            </div>
        </div>

    </ng-template>
</div>
