import {Pipe, PipeTransform} from "@angular/core";
import {ApiService} from "@core/shared/services/api.service";
import { TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";

@Pipe({
    name: 'giftVoucherPictureSource'
})
export class GiftVoucherPictureSourcePipe implements PipeTransform {

    constructor(
        private _apiService: ApiService
    ){
    }

    transform(templateGiftVoucher: TemplateGiftVoucher, locale: string, format: string, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public/templates/${templateGiftVoucher.id}/${locale}/picture/filter/${format}/${width}/${quality}`;
    }
}
