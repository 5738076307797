import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2
} from "@angular/core";

@Directive({
    selector: '[appShowHidePassword]'
})
export class ShowHidePasswordDirective implements OnInit{

    @Input() input: HTMLInputElement;

    showPassword: boolean = false;

    constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
    }

    ngOnInit() {

        this._apply();
    }

    @HostListener('click') onClick() {
        this._apply();
    }

    private _apply() {

        this.showPassword = !this.showPassword;
        this._elementRef.nativeElement.innerHTML = this.showPassword ? 'visibility_off' : 'visibility';
        this.input.type = !this.showPassword ? 'text': 'password';
    }

}
