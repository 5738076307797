<div [formGroup]="form" class="form-group">
    <mat-form-field>
        <input matInput appInput [attrs]="config.attrs" [formControlName]="config.id" [id]="config.id" [(ngModel)]="value">
    </mat-form-field>
    <p *ngIf="config.attrs.help" class="message-help">{{ config.attrs.help }}.</p>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            <div *ngIf="form.controls[config.id].errors.minlength" [translate]="'form.validation.error.minLength'" [translateParams]="{value: config.attrs.minlength}"></div>
            <div *ngIf="form.controls[config.id].errors.maxlength" [translate]="'form.validation.error.maxLength'" [translateParams]="{value: config.attrs.maxlength}"></div>
            <div *ngIf="form.controls[config.id].errors.email">{{ 'form.validation.error.email' | translate }}</div>
            <div *ngIf="form.controls[config.id].errors.pattern" [translate]="'form.validation.error.pattern'" [translateParams]="{value: config.attrs.pattern_info}"></div>
        </div>
    </mat-error>
</div>
