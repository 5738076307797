import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {SocietyGroupService} from "@core/shared/services/society-group.service";

@Injectable({
    providedIn: 'root'
})
export class SocietyGroupItemResolver {

    constructor(
        private _societyGroupService: SocietyGroupService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._societyGroupService.getItemAPI(parseInt(route.paramMap.get('id')))
    }
}
