<div [formGroup]="form" class="form-group">
    <div class="form-group">
        <mat-form-field>
            <input matInput placeholder="Nouveau mot de passe" type="password" formControlName="password" value="" class="form-control" [ngClass]="{ 'is-invalid':  f.password.errors }"/>
        </mat-form-field>
        <mat-error>
            <div *ngIf=" f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Le mot de passe est obligatoire</div>
            </div>
        </mat-error>
    </div>
    <div class="form-group">
        <mat-form-field>
            <input matInput placeholder="Confirmer mot de passe" type="password" formControlName="confirm_password" value="" class="form-control" [ngClass]="{ 'is-invalid':  f.confirm_password.errors }"/>
        </mat-form-field>
        <mat-error>
            <div *ngIf=" f.confirm_password.errors" class="invalid-feedback">
                <div *ngIf="f.confirm_password.errors.required">Le mot de passe est obligatoire</div>
            </div>
            <div *ngIf="(f.confirm_password.invalid && (f.confirm_password.dirty || f.confirm_password.touched)) || (f.password.invalid && (f.password.dirty || f.password.touched))">
                <div *ngIf="(f.confirm_password.errors && f.confirm_password.errors.password) || (f.password.errors && f.password.errors.password)">{{ 'form.validation.error.password' | translate }}</div>
            </div>

        </mat-error>

    </div>
</div>
