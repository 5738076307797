<div *ngIf="this.addManual" class="ligne_form">

    <div class="collection">

        <ng-container [formGroup]="parentNetworkProvider">

            <ng-container formArrayName="networkProviders">

                <div *ngFor="let _ of networkProviderForm.controls; let i = index" [formGroupName]="indexAsString(i)" class="ligne_form removable_item">

                    <div class="ligne_form row marges">
                        <div class="col_12">
                            <!-- Nom nouveau reseau -->
                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="offer-provider-societies-{{i}}-name" required="required">
                                <label for="offer-provider-societies-{{i}}-name">{{ 'networkProvider.list.labelNetwork.value' | translate }}</label>
                                <mat-error *ngIf="this.getProviderSocietyControl(i).get('name').errors && (this.getProviderSocietyControl(i).get('name').dirty || this.getProviderSocietyControl(i).get('name').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="this.getProviderSocietyControl(i).get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Logo -->

                            <div class="form-group">
                                <label class="required">{{ 'file.add.action.logo.value' | translate }}</label>
                                <form-field-file [config]="logoConfig" [form]="this.getProviderSocietyControl(i)" [file]="this.getProviderSocietyControl(i).get('logo').value"></form-field-file>
                                <mat-error *ngIf="this.getProviderSocietyControl(i).get('logo').invalid">
                                    <div class="invalid-feedback">
                                        <div *ngIf="this.getProviderSocietyControl(i).get('logo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <app-select-search [config]="configProviders" [form]="getProviderSocietyControl(i)" [value]="getProviderSocietyControl(i).get('providers')?.value" data-index="{{i}}" (valueChange)="changeProviders($event, i)"></app-select-search>
                            </div>
                        </div>

                    </div>
                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                    <textarea class="form-control" formControlName="parsedSocieties"
                                              id="societies"></textarea>

                                    <span class="tooltip_info"> <mat-icon  class="tooltip_icon">info</mat-icon> {{'networkProvider.list.fields.societies.info.value' | translate}}</span>
                                    <label for="societies">{{'networkProvider.list.fields.societies.value' | translate}}</label>



                                <mat-error
                                        *ngIf="this.getProviderSocietyControl(i).get('parsedSocieties').errors && (this.getProviderSocietyControl(i).get('parsedSocieties').dirty || this.getProviderSocietyControl(i).get('parsedSocieties').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="this.getProviderSocietyControl(i).get('parsedSocieties').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Afficher sur les canaux ? -->

                            <div class="form-group">
                                <label>{{'networkProvider.list.fields.displayOnChannel.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="true" formControlName="displayOnChannel" class="form-control" id="displayOnChannel-{{i}}-yes">
                                    <label for="displayOnChannel-{{i}}-yes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="displayOnChannel" class="form-control" id="displayOnChannel-{{i}}-no">
                                    <label for="displayOnChannel-{{i}}-no">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <mat-error *ngIf="getProviderSocietyControl(i).get('displayOnChannel').errors && (getProviderSocietyControl(i).get('displayOnChannel').dirty || getProviderSocietyControl(i).get('displayOnChannel').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="getProviderSocietyControl(i).get('displayOnChannel').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row marges ligne_form" *ngIf="getProviderSocietyControl(i).get('displayOnChannel').value === true">
                        <div class="col_12">
                            <div class="form-group">
                                <app-select-search [config]="configChannels" [form]="getProviderSocietyControl(i)" [value]="getProviderSocietyControl(i).get('channels')?.value" data-index="{{i}}" (valueChange)="changeChannels($event, i)"></app-select-search>
                            </div>
                        </div>

                    </div>


                    <!-- Suppression -->
                    <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="deleteNetworkProvider(i)">
                        <mat-icon>delete</mat-icon>
                    </button>

                </div>

            </ng-container>

        </ng-container>

    </div>
</div>

<div class="row marges ligne_form">
    <div class="col_content y_items_center">
        <button class="btn_cta btn_little" type="button" (click)="initNetworkProviderFormControl(null)">
            {{ 'networkProvider.form.fields.actions.add.value' | translate }}
        </button>
    </div>
</div>
