import {forkJoin, Observable, of} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {map, mergeMap} from "rxjs/operators";

export class AllPaginateItemsAPI<T> {

    constructor(private _paginateRequestCallback: (params: string[]) => Observable<Pagination<T>>, private _params: string[] = [], private _limit: number = 100) {
    }

    private _callbackToRequest(request$: (params: string[]) => Observable<Pagination<T>>): (paginationParams: string[]) => Observable<Pagination<T>>{

        return (paginationParams: string[]): Observable<Pagination<T>> => {

            return request$([...paginationParams, ...this._params]);
        }
    }

    get request$(): Observable<T[]> {

        const paginationStatsParams: string[] = [
            `page=1`,
            `limit=${this._limit}`
        ];

        return this._callbackToRequest(this._paginateRequestCallback)(paginationStatsParams).pipe(mergeMap((pagination: Pagination<T>): Observable<T[]> => {

                if(!pagination.pagesCount){

                    return of([]);
                }

                const pages = Array.from({ length: pagination.pagesCount - 1 }, (_, i) => i + 2);

                const paginationRequests: Observable<T[]>[] = [of(pagination.items), ...pages.map((page: number): Observable<T[]> => {

                    const paginationParams: string[] = [
                        `page=${page}`,
                        `limit=${this._limit}`
                    ];

                    return this._callbackToRequest(this._paginateRequestCallback)(paginationParams).pipe(map((pagination: Pagination<T>): T[] => {

                        return pagination.items;
                    }));
                })];

                return forkJoin(paginationRequests).pipe(map((items: T[][]): any => {

                    return items.reduce((accumulator, currentValue) => accumulator.concat(currentValue), []);
                }));
            })
        )
    }
}
