import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-page-society-provider-create-dialog',
    templateUrl: './page-society-provider-create-dialog.component.html',
    styleUrls: ['./page-society-provider-create-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class PageSocietyProviderCreateDialogComponent implements OnInit {

    @Output() submit: EventEmitter<object> = new EventEmitter<object>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<PageSocietyProviderCreateDialogComponent>,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            reference: ['', [Validators.required]],
            enable: [true, [Validators.required]],
        });

        this.formService.submitCallback = (): void => {

            const data: { enable: boolean, reference: string } = this.form.value;

            this.submit.emit({
                enable: data.enable,
                provider: {
                    reference: data.reference
                }
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
