import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Card} from "@core/shared/models/card";

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public cardRegistrationUrl(url: string, data: string): Observable<any> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Accept', 'text/plain');

        return this._httpClient.post(url, data, {
            headers: headers,
            responseType: "text"
        });
    }
}
