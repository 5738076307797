export type PromotionTypeType = 'offer' | 'code';

export interface PromotionTypeItem {

    label: string;

    value: PromotionTypeType;
}

export const PROMOTION_TYPE_ITEMS: PromotionTypeItem[] = [
    {
        value: 'offer',
        label: 'promotion.type.offer.value'
    },
    {
        value: 'code',
        label: 'promotion.type.code.value'
    }
];
