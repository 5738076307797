import {Component, OnInit, Input} from '@angular/core';
import {FileConfig} from '@lib/form/fields/file/file.component';
import {TranslationBuilder} from '@core/shared/models/translation';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SocietyService} from '@core/shared/services/society.service';
import {ApiService} from '@core/shared/services/api.service';
import {CountryService} from '@core/shared/services/country.service';
import {CustomerTypologyService} from '@core/shared/services/customer-typology.service';
import {FormService} from '@core/shared/services/form.service';
import {map} from 'rxjs/operators';
import {Pagination} from '@core/shared/models/pagination';
import {REGEX_EMAIL, REGEX_PHONE} from '@core/shared/models/regex';
import {CustomerTypology, CustomerTypologyTranslation} from '@core/shared/models/customer-typology';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AuthenticationService} from '@core/shared/services/authentication.service';
import {TermsAndCondition, TermsAndConditionTranslation} from '@core/shared/models/terms-and-condition';
import {AddCustomerTypologyDialogComponent} from '@core/components/customer-typology/add-customer-typology-dialog/add-customer-typology-dialog.component';
import {UserService} from '@core/shared/services/user.service';
import {SocietyAdminRequestService} from '@core/shared/services/society-admin-request.service';
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

    public termsAndConditionsTranslationBuilders: TranslationBuilder[] = [];

    public termsAndConditionsFileConfig: FileConfig;

    public customerTypologies: CustomerTypology[] = [];

    @Input() parentTermsAndConditions: UntypedFormGroup;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _apiService: ApiService,
        private _countryService: CountryService,
        private _customerTypologyService: CustomerTypologyService,
        private _authenticationService: AuthenticationService,
        private _userService: UserService,
        private _societyAdminRequestService: SocietyAdminRequestService,
        public translationService: TranslationService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initTermsAndConditionsFormControl();

        this._initTermsAndConditions();

        this._initFileConfigs();

    }

    private _initTermsAndConditionsFormControl(): void {
        this.termsAndConditionsForm.controls.forEach((control: UntypedFormGroup): void => {

            const termsAndConditionsTranslationBuilder = new TranslationBuilder(this._formBuilder);

            termsAndConditionsTranslationBuilder.form = control;

            termsAndConditionsTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

                return this._formBuilder.group({
                    phone: ['', [Validators.required ]],
                    email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
                    file: [null, Validators.required]
                });
            };

            this.termsAndConditionsTranslationBuilders.push(termsAndConditionsTranslationBuilder);
        });
    }

    private _initTermsAndConditions(): void {

        this._customerTypologyService.getPaginationItemsAPI().pipe(
            map((response: Pagination<CustomerTypology>): CustomerTypology[] => {

                return response.items;
            })
        ).subscribe((typologies: CustomerTypology[]): void => {
            this.customerTypologies = typologies;
        });
    }

    private _initFileConfigs(): void {
        this._translateService.get('file.extension.list.allowed.value', {list: '.pdf'}).subscribe((help: string): void => {

            this.termsAndConditionsFileConfig = {
                id: 'file',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'cgv_file'
                },
                required: true,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: help,
                upload: {
                    allowedTypes: ['application/pdf'].join(',')
                }
            };
        });
    }

    public openAddCustomerTypologyDialog(): void {

        const termsAndConditions: TermsAndCondition[] = this.societyForm.get('termsAndConditions').value;

        const existingItems: CustomerTypology[] = [];

        const availableItems: CustomerTypology[] = [];

        this.customerTypologies.forEach((item: CustomerTypology): void => {

            const match: TermsAndCondition = termsAndConditions.find((termsAndCondition: TermsAndCondition): boolean => {

                return termsAndCondition.typology.id === item.id;
            });

            match ? existingItems.push(item) : availableItems.push(item);
        });

        const dialogRef: MatDialogRef<AddCustomerTypologyDialogComponent> = this._dialog.open(AddCustomerTypologyDialogComponent, {
            width: '500px',
            data: {
                existingItems: existingItems,
                availableItems: availableItems
            }
        });

        dialogRef.componentInstance.select.subscribe((data: { items: CustomerTypology[], duplicatedItem: CustomerTypology }): void => {

            data.items.forEach((item: CustomerTypology): void => {

                const control: AbstractControl = this._formBuilder.group({
                    label: this.translationService.getFallbackTranslation(item.translations).name,
                    typology: this._formBuilder.group({
                        id: [item.id]
                    }),
                    translations: new UntypedFormArray([])
                });

                this.termsAndConditionsForm.push(control);

                const termsAndConditionsTranslationBuilder = new TranslationBuilder(this._formBuilder);

                termsAndConditionsTranslationBuilder.form = control;

                termsAndConditionsTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

                    return this._formBuilder.group({
                        phone: [''],
                        email: ['', Validators.pattern(REGEX_EMAIL)],
                        file: [null, Validators.required]
                    });
                };

                this.termsAndConditionsTranslationBuilders.push(termsAndConditionsTranslationBuilder);

                if (data.duplicatedItem) {

                    const duplicatedControl: AbstractControl = (this.societyForm.get('termsAndConditions') as UntypedFormArray).controls.find((control: AbstractControl): boolean => {

                        return control.value.typology.id === data.duplicatedItem.id;
                    });

                    const duplicatedTermsAndCondition: TermsAndCondition = duplicatedControl.value;

                    duplicatedTermsAndCondition.translations.forEach((translation: TermsAndConditionTranslation): void => {

                        termsAndConditionsTranslationBuilder.addItemControl(termsAndConditionsTranslationBuilder.getLocaleItem(translation.locale));
                    });

                    termsAndConditionsTranslationBuilder.itemsControl.controls.forEach((control: AbstractControl): void => {

                        const duplicatedTranslation: TermsAndConditionTranslation = this.translationService.getFallbackTranslation(duplicatedTermsAndCondition.translations);

                        duplicatedTranslation.phone = this.form.get('phone').value.e164Number;

                        control.patchValue({
                            phone: duplicatedTranslation.phone,
                            email: duplicatedTranslation.email,
                            file: JSON.parse(JSON.stringify(duplicatedTranslation.file))
                        });
                    });
                }
            });
        });
    }

    public deleteTermsAndConditionsControl(index: number): void {

        const builderIndex: number = this.termsAndConditionsTranslationBuilders.findIndex((item: TranslationBuilder): boolean => {

            const termsAndCondition: TermsAndCondition = item.form.value;

            return termsAndCondition.typology.id === this.termsAndConditionsForm.at(index).value.typology.id;
        });

        this.termsAndConditionsTranslationBuilders.splice(builderIndex, 1);

        this.termsAndConditionsForm.removeAt(index);
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getTermsAndConditionsTranslationBuilder(index: number): TranslationBuilder {

        return this.termsAndConditionsTranslationBuilders[index];
    }

    public getFormTermsAndConditionControl(index: number): UntypedFormControl {

        return this.termsAndConditionsForm.controls[index] as UntypedFormControl;
    }

    get form(): UntypedFormGroup {

        return this.parentTermsAndConditions;
    }

    get societyForm(): UntypedFormGroup {

        return this.form.get('society') as UntypedFormGroup;
    }

    get termsAndConditionsForm(): UntypedFormArray {

        return this.societyForm.get('termsAndConditions') as UntypedFormArray;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get canAddCgv(){
        return (this.customerTypologies.length > this.termsAndConditionsForm.value.length);
    }
}
