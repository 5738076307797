<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.own.distributor.plural.value' | translate }}</h1>
            </div>
        </div>

        <div class="subscription_information">
            <p [innerHTML]="'offer.own.distributor.title.description.value' | translate | safeHtml"></p>
            <p><mat-icon class="tooltip_icon">info</mat-icon> {{ 'offer.own.distributor.title.help.value' | translate }}</p>
            <p [innerHTML]="'offer.own.distributor.title.commission.value' | translate"></p>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom du distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'distributor.name.value' | translate" [key]="'distributor.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Rôle utilisateur -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.admin.roles')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'distributor.type.value' | translate" [key]="'distributor.admin.roles'" [operator]="'andlkin'" [itemValueProperty]="'identifier'" [itemLabelProperty]="'label'" [items]="filterRoles"></app-core-select-array-multiple-filter>
                    </th>
                </ng-container>

                <!-- Nom de l'administrateur du compte distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.admin.lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'distributor.admin.lastName.value' | translate" [key]="'distributor.admin.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom de l'administrateur du compte distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.admin.firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'distributor.admin.firstName.value' | translate" [key]="'distributor.admin.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom du distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.website')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'distributor.website.value' | translate" [key]="'distributor.websites.website'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom du distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.addresses.city')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'distributor.address.city.value' | translate" [key]="'distributor.addresses.city'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Type de commission -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.commission.type')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'distributor.commission.type.value' | translate" [key]="'distributor.commission.type'" [operator]="'eq'" [itemValueProperty]="'identifier'" [itemLabelProperty]="'label'" [items]="filterCommissionTypes"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Commission déduite automatiquement -->

                <ng-container [matColumnDef]="getFilterColumnDef('distributor.commission.incomingPayment')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Accès à toutes les offres -->

                <ng-container [matColumnDef]="getFilterColumnDef('automaticCatalogOffersAcceptance')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-boolean-filter #filter [builder]="filterBuilder" [label]="'distributor.automaticCatalogOffersAcceptance.value' | translate" [key]="'automaticCatalogOffersAcceptance'" [truthyLabel]="'distributor.automaticCatalogOffersAcceptance.yes.value' | translate" [falsyLabel]="'distributor.automaticCatalogOffersAcceptance.no.value' | translate"></app-core-select-boolean-filter>
                    </th>
                </ng-container>

                <!-- Nombre d'offres séléctionnées -->

                <ng-container [matColumnDef]="getFilterColumnDef('totalMarketplaceOffers')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="editItem(getModel(element))" *ngIf="hasAccessEdit()">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(getModel(element))" *ngIf="hasAccessDelete()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom du distributeur -->

                <ng-container matColumnDef="distributor.name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).distributor.name}}</td>
                </ng-container>

                <!-- Rôle utilisateur -->

                <ng-container matColumnDef="distributor.admin.roles">
                    <th mat-header-cell *matHeaderCellDef>{{'distributor.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).distributor.admin ? getRoleLabel(getModel(element).distributor.admin.roles) : null}}</td>
                </ng-container>

                <!-- Nom de l'administrateur du compte distributeur -->

                <ng-container matColumnDef="distributor.admin.lastName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.admin.lastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).distributor.admin.lastName}}</td>
                </ng-container>

                <!-- Prénom de l'administrateur du compte distributeur -->

                <ng-container matColumnDef="distributor.admin.firstName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.admin.firstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).distributor.admin.firstName}}</td>
                </ng-container>

                <!-- Site Web du distributeur -->

                <ng-container matColumnDef="distributor.website">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.website.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).distributor.mainWebsite}}</td>
                </ng-container>

                <!-- Ville du distributeur -->

                <ng-container matColumnDef="distributor.addresses.city">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.address.city.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getAddressFields(getModel(element)).city }}</td>
                </ng-container>

                <!-- Type de commission -->

                <ng-container matColumnDef="distributor.commission.type">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.commission.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getCommissionTypeLabel(getModel(element)) }}</td>
                </ng-container>

                <!-- Commission déduite automatiquement -->

                <ng-container matColumnDef="distributor.commission.incomingPayment">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.incomingPayment.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getIncomingPaymentLabel(getModel(element)) }}</td>
                </ng-container>

                <!-- Accès à toutes les offres -->

                <ng-container matColumnDef="automaticCatalogOffersAcceptance">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.automaticCatalogOffersAcceptance.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('distributor.automaticCatalogOffersAcceptance.' + (getModel(element).automaticCatalogOffersAcceptance ? 'yes' : 'no') + '.value') | translate }}</td>
                </ng-container>

                <!-- Nombre d'offres séléctionnées -->

                <ng-container matColumnDef="totalMarketplaceOffers">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.marketplace.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).totalMarketplaceOffers }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>

