import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {InputConfig} from "../input";

/**
 * Configuration des errors de validations
 */
export interface ValidatorFn {
    /**
     * Erreurs retournés par la validation du formulaire
     * @param control Control de champs
     */
    (control: AbstractControl): ValidationErrors | null
}

/**
 * Configuration paramètres du champ
 */
export interface InputPasswordConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
    };
}

/**
 * Champ input text
 */
@Component({
    selector: 'app-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() public config: InputPasswordConfig;

    /**
     * Valeur du champ texte
     */
    @Input() public value: string;

    /**
     * Formulaire
     */
    @Input() public form: UntypedFormGroup;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {


        this.form.addControl('password', new UntypedFormControl(''));
        this.form.addControl('confirm_password', new UntypedFormControl(''));

        /**
         * Validateurs du champs
         */
        const validators1: any[] = [];
        const validators2: any[] = [];

        validators1.push(this.passwordValidator('password'));
        validators2.push(this.passwordValidator('confirm_password'));

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators1.push(Validators.required);
            validators2.push(Validators.required);
        }

        this.form.controls['password'].setValidators(validators1);
        this.form.controls['confirm_password'].setValidators(validators2);
    }

    /**
     * Getter pour accéder aux champs du formulaire de connexion
     */
    get f(): any {
        return this.form.controls;
    }

    /**
     * Validateur custom : date de début antérieure à la date de fin
     * @param id
     */
    public passwordValidator = (id: string): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.comparePassword(id);
        }
    };

    public comparePassword(id: string): any {
      ;
        if (this.form.controls['password'].value !== this.form.controls['confirm_password'].value) {
            return {'password': {value: false}};
        } else {
            this.f.confirm_password.setErrors(null);
            this.f.password.setErrors(null);
            return null;
        }

    }
}
