import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";

@Injectable({
    providedIn: 'root'
})
export class SocietyOfferCreatorItemResolver  {

    constructor(
        private _offerCreatorService: OfferCreatorService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        const id: number = parseInt(route.queryParams['offerCreatorId']);

        return id ? this._offerCreatorService.getItemAPI(parseInt(route.queryParams['offerCreatorId'])) : null;
    }
}
