<ng-container *ngFor="let part of sortedParts; let isLastPart = last;">

    <div class="row marges">

        <ng-container *ngIf="part.items.length; else emptyPart">
            <div class="col_4 content_card" *ngFor="let item of part.items">
                <app-core-offer-card
                    [item]="item"
                    [roles]="roles"
                    [layoutView]="layoutView"
                    [mode]="mode"
                    [identificationNumber]="identificationNumber"
                    [publicPrice]="publicPrice"
                ></app-core-offer-card>
            </div>
        </ng-container>

        <ng-template #emptyPart>

            <div class="col_12">
                <div class="part_no_result">
                    <p>{{ 'offer.list.part.noResult.value' | translate }}</p>
                </div>
            </div>

        </ng-template>
    </div>

    <ng-container *ngIf="!isLastPart">

        <div class="separator"></div>

    </ng-container>

</ng-container>

<div class="row more_offer" *ngIf="hasMoreOffers">
    <div class="col x_center">
        <button class="btn_cta btn_highlight" type="button" (click)="loadMoreOffers()">
            {{ 'offer.more.value' | translate }}
        </button>
    </div>
</div>
