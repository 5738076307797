import {Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {InputConfig} from "../input";

/**
 * Configuration paramètres du champ
 */
export interface InputCheckboxConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        label: string;
        choices: {}

    };
}

/**
 * Champ input checkbox
 */
@Component({
    selector: 'app-input-checkbox',
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit, DoCheck {
    /**
     * Paramètres du champ
     */
    @Input() config: InputCheckboxConfig = {id: null, attrs: {label: null, choices: {}}};
    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    private differ: KeyValueDiffer<any, any>;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor(private differs: KeyValueDiffers) {
        this.differ = this.differs.find(this.config).create();
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    ngOnInit() {

        for(const choice in this.config.attrs.choices) {
            this.form.addControl(this.config.id + '[' +choice +']', new UntypedFormControl(''));
        }
    }

    public ngDoCheck(): void {
        const changes = this.differ.diff(this.config.attrs);

        if (changes) {
            changes.forEachChangedItem((r) => {
                if (r.key == 'choices') {
                    this.config.attrs.choices = r.currentValue;
                    for(const choice in this.config.attrs.choices) {
                        this.form.addControl(this.config.id + '[' +choice +']', new UntypedFormControl(''));
                    }
                }
            });
        }


    }

}
