import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferAttributeType} from '@core/shared/models/offer-attribute-type';
import {Pagination} from '@core/shared/models/pagination';
import {Society} from '@core/shared/models/society';

@Injectable({
    providedIn: 'root'
})
export class OfferAttributeService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public getItemsAPI(params?: string[]): Observable<OfferAttribute[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/offerAttributes`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<OfferAttribute[]>(url, {
            headers: headers
        });

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<OfferAttribute>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/offerAttributes`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<OfferAttribute>>(url, {
            headers: headers
        });

    }

    public getItemTypeAPI(params?: string[]): Observable<OfferAttributeType> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/offerAttributes/types`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<OfferAttributeType>(url, {
            headers: headers
        });

    }

    public getPaginationItemType(): Observable<Pagination<OfferAttributeType>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/offerAttributes/types`;
        return this._httpClient.get<Pagination<OfferAttributeType>>(url, {
            headers: headers
        });

    }

    public getItemAPI(id: number): Observable<OfferAttribute> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<OfferAttribute>(`${this._apiService.getApiUrl(false, true)}/offerAttributes/${id}`,{
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<OfferAttribute> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferAttribute>(`${this._apiService.getApiUrl(false, true)}/offerAttributes`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<OfferAttribute> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<OfferAttribute>(`${this._apiService.getApiUrl(false, true)}/offerAttributes/${id}`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/offerAttributes/${id}`,{
            headers: headers
        });
    }
}
