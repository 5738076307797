import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {OfferService} from "@core/shared/services/offer.service";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {DEFAULT_MARKUP} from "@core/shared/models/data";
import {MIN_MARKUP} from "@core/shared/models/data";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {parseMarkup} from "@core/shared/utils/markup";
import {UserService} from "@core/shared/services/user.service";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-core-markup-update-grouped-dialog',
    templateUrl: './markup-update-grouped-dialog.component.html',
    styleUrls: ['./markup-update-grouped-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class MarkupUpdateGroupedDialogComponent implements OnInit {

    @Input() offer: Offer;

    @Input() typeMarkup: string;

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    @Output() markup: EventEmitter<number> = new  EventEmitter<number>();

    public minMarkupPercent: number = MIN_MARKUP;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offerService: OfferService,
        private _catalogService: OfferCatalogService,
        private _userService: UserService,
        private _dialogRef: MatDialogRef<MarkupUpdateGroupedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { offers: Offer[], typeMarkup: string, catalogs : OfferCatalog[], minMarkupPercent: number, defaultMarkupPercent: number },
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this.typeMarkup = this.data.typeMarkup;

        this._initForm();

        const events: EventEmitter<any>[] = [
            this.create
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    private _initForm(): void {

        const society: Society = this._userService.currentUser.value.society;

        if (this.typeMarkup == 'offer') {

            this.formService.form = this._formBuilder.group({
                offers: [this.data.offers.map((offer: Offer): { id: number } => {
                    return {id: offer.id}
                }), [Validators.required]],
                markup: [this.data.defaultMarkupPercent, [
                    Validators.required,
                    Validators.pattern(REGEX_PRICE),
                    Validators.min(this.minMarkupPercent)]
                ]
            });

        } else {

            this.formService.form = this._formBuilder.group({
                catalogs: [this.data.catalogs.map((catalog: OfferCatalog): { id: number } => {
                    return {id: catalog.id}
                }), [Validators.required]],
                markup: [this.data.defaultMarkupPercent, [
                    Validators.required,
                    Validators.pattern(REGEX_PRICE),
                    Validators.min(this.minMarkupPercent)]
                ]
            });
        }

        this.formService.submitCallback = (): void => {
            var data: object ={};

            if (this.typeMarkup == 'offer') {

                 data = Object.assign(this.form.value, {
                    markup: this.form.get('markup').value / 100,
                    offers: this.form.get('offers').value
                });

            } else {

                 data = Object.assign(this.form.value, {
                    markup: this.form.get('markup').value / 100,
                    catalogs: this.form.get('catalogs').value
                });
            }

            if (this.typeMarkup == 'offer') {

                this._offerService.updateItemsAPI(data).subscribe((): void => {

                    this.create.emit();
                });

            } else {

                this._catalogService.updateItemsAPI(data).subscribe((): void => {
                    this.create.emit();
                });
            }
        };
    }

    public close(): void {

        this.markup.emit(null);

        this._dialogRef.close();
    }

    public submit(): void {

        this.formService.submit();
    }

    public parsedMarkup(markup: number): string {

        return parseMarkup(markup * 100);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    setMarkup($event: any): void {

        if (this.form.get('markup').errors){
          return;
        }

        this.markup.emit($event.target.value);
    }
}
