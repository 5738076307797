<h1 mat-dialog-title>
    {{ 'media.image.gallery.title' | translate }}
</h1>
<div mat-dialog-content>

    <media-file-upload [config]="data.fileUploadConfig" (postUpload)="postUpload()"></media-file-upload>

    <div class="add-image-success" *ngIf="showAddImage">
        <span>{{ 'media.image.gallery.addImage' | translate }}</span>
    </div>

    <div class="disabled-addittion" *ngIf="!isAdditionEnabled()">
        <span>{{ 'media.image.gallery.maxLengthReached' | translate }}</span>
    </div>

    <!-- Champ du filtre par nom d'image -->

    <div class="search-file">
        <input #search type="text" [(ngModel)]="titleFilter" [placeholder]="'Recherche un visuel par nom'"/>
    </div>

    <!-- Liste des images -->

    <div class="gallery-container">
        <div *ngFor="let image of images | paginate: { itemsPerPage: paginatorConfig.itemsPerPage, currentPage: paginatorConfig.currentPage, totalItems: totalItems }" class="thumb-gallery-container">
            <div class="image-element" >
                <div>
                    <div class="visuel">
                        <img [src]="image.url ? image.url : ''" [width]="data.thumbnailWidth" [alt]="image.alt" />
                    </div>
                    <div class="title" [matTooltip]="image.alt" matTooltipPosition="above">
                        <span>{{ image.title }}</span>
                    </div>
                </div>

                <div class="actions">

                    <button type="button" *ngIf="!isUsed(image) " [disabled]="!isAdditionEnabled()" (click)="addImage(image)" >
                        {{ 'media.image.gallery.add.action' | translate }}
                    </button>

                    <button type="button" *ngIf="isUsed(image)" (click)="removeImage(getRefImage(image))">
                        {{ 'media.image.gallery.remove.action' | translate }}
                    </button>

                    <button type="button" (click)="deleteImageGallery(image)" matTooltip="{{ 'media.image.gallery.delete.action' | translate }}" matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Pagination -->

    <pagination-controls
         [directionLinks]="true"
         [autoHide]="true"
         [responsive]="true"
         [maxSize]="paginatorConfig.maxSize"
         [previousLabel]="paginatorConfig.labels.previous"
         [nextLabel]="paginatorConfig.labels.next"
         (pageChange)="goToPage($event)">
    </pagination-controls>
</div>
<div mat-dialog-actions class="row x_center">
    <button type="button" (click)="close()" class="btn_cta btn_little btn_red">{{ 'media.image.gallery.close.action' | translate }}</button>
</div>
