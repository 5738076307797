<h1 mat-dialog-title  class="x_center">{{ 'services.subscription.dialog.delete.title.value' | translate }}</h1>
<mat-dialog-content>

    <div class="red">

        <p *ngIf="isWithSubscription" [innerHTML]="'services.subscription.dialog.delete.content.value' | translate: {date: birthdayDate | date : 'dd/MM/yyyy' : null : localeId} | safeHtml"></p>

        <p><b [innerHTML]="serviceTranslation.deleteDescription | safeHtml"></b></p>

        <p *ngIf="isWithSubscription">{{ 'services.subscription.dialog.delete.description.value' | translate }}</p>

        <p *ngIf="!isWithSubscription && unsubscriptionDelay > 0">{{ 'services.subscription.dialog.delete.description.without_subscription.value' | translate: {hour: unsubscriptionDelay} }}</p>

        <p *ngIf="!isWithSubscription && unsubscriptionDelay === 0">{{ 'services.subscription.dialog.delete.description.without_subscription_now.value' | translate }}</p>

    </div>



</mat-dialog-content>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="back.emit()">{{ 'services.list.item.action.service.back.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="delete.emit()">{{ 'services.list.item.action.service.unsubscribe.action.self.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
