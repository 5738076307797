import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Offer, OfferStockType} from "@core/shared/models/offer";
import {Moment} from "moment";
import {OfferDate, OfferDatePATCH} from "@core/shared/models/offer/offer-date";
import {ConfigurationField, ConfigurationFieldIdentifier} from "@core/shared/models/offer/offer-date/offer-date-configuration";
import {Booking} from "@core/shared/models/booking";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {OfferDateService} from "@core/shared/services/offer/offer-date.service";
import {BookingService} from "@core/shared/services/booking.service";
import {FormService} from "@core/shared/services/form.service";
import {DATE_FORMAT} from "@app/data";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {OfferPricePublicType} from "@core/shared/models/offer/offer-price-public";
import {parsePrice} from "@core/shared/utils/price";
import {OfferDateStock, OfferDateStockType} from "@core/shared/models/offer/offer-date/offer-date-stock";
import {Day, DAYS} from "@core/shared/models/day";
import {User} from "@core/shared/models/user";
import {Access} from "@core/shared/models/access";

@Component({
    selector: 'app-core-offer-date-update',
    templateUrl: './offer-date-update.component.html',
    styleUrls: ['./offer-date-update.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferDateUpdateComponent implements OnInit {

    @Input() offer: Offer;

    @Input() date: Moment;

    @Input() offerDateId: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @Output() offerDateUpdated: EventEmitter<void> = new EventEmitter<void>();

    public offerDate: OfferDate = null;

    public configurationFields: {[p in ConfigurationFieldIdentifier]: ConfigurationField} = {
        stock: null,
        price: null,
        releaseDate: null
    };

    public priceCalculationAutomatic: boolean = false;

    public priceTypeReference: 'HT' | 'TTC' = 'HT';

    public bookings: Booking[] = [];

    public bookingOffset: number = 0;

    public bookingPerPage: number = 10;

    public totalBookings: number = 0;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _offerDateService: OfferDateService,
        private _bookingService: BookingService,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this._initForm();

        this._loadOfferDate();

        this._loadBookings();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]],
            isClosed: [true, [Validators.required]],
            stock: this._formBuilder.group({
                unlimited: [false, [Validators.required]],
                stocks: new UntypedFormArray([])
            }),
            price: this._formBuilder.group({
                adultPriceHT: [null],
                adultPriceTTC: [null],
                childPriceHT: [null],
                childPriceTTC: [null]
            }),
            releaseDate: this._formBuilder.group({
                value: [null]
            })
        });

        this.formService.submitCallback = (): void => {

            const data: OfferDatePATCH = {
                date: this.date.format(DATE_FORMAT),
                isClosed: this.form.get('isClosed').value,
                unlimitedStock: this.stockForm.get('unlimited').value,
                stocks: this.configurationFields.stock.toProcess ? (this.stocksForm.value as []).map((data: { type: OfferDateStockType, commonAllocatedStock: string, adultAllocatedStock: string, childAllocatedStock: string }) : Partial<OfferDateStock> => {

                    return Object.assign({...data}, {
                        commonAllocatedStock: this.isStockManagementType('common') ? parseInt(data.commonAllocatedStock) : null,
                        adultAllocatedStock: this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('adult') ? parseInt(data.adultAllocatedStock) : null,
                        childAllocatedStock: this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('child') ? parseInt(data.childAllocatedStock) : null
                    });
                }) : this.offerDate.stocks,
                adultPriceHT: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('adult') && this.offer.vatPercent ? parseInt((this.priceForm.get('adultPriceHT').value * 100).toFixed()) : (this.offerDate.adultPriceHT * 100),
                adultPriceTTC: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('adult') ? parseInt((this.priceForm.get('adultPriceTTC').value * 100).toFixed()) : (this.offerDate.adultPriceTTC * 100),
                childPriceHT: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('child') && this.offer.vatPercent ? parseInt((this.priceForm.get('childPriceHT').value * 100).toFixed()) : (this.offerDate.childPriceHT * 100),
                childPriceTTC: this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('child') ? parseInt((this.priceForm.get('childPriceTTC').value * 100).toFixed()) : (this.offerDate.childPriceTTC * 100),
                releaseDate: this.configurationFields.releaseDate.toProcess ? parseInt(this.releaseDateForm.get('value').value) : this.offerDate.releaseDate
            } as OfferDatePATCH;

            this._offerDateService.updateItemAPI(this.offer.id, this.offerDate.id, data).subscribe((): void => {

                this.offerDateUpdated.emit();
            });
        };
    }

    private _initConfigurationFields(): void {

        this.configurationFields.stock = {
            allowed: this.hasOnlineSaleAccess && this.offer.onlineSale && this.offer.onlineSale.enable,
            toProcess: false
        };

        this.configurationFields.price = {
            allowed: true,
            toProcess: false
        };

        this.configurationFields.releaseDate = {
            allowed: this.hasOnlineSaleAccess && this.offer.onlineSale && this.offer.onlineSale.enable,
            toProcess: false
        };

        Object.keys(this.configurationFields).forEach((identifier: ConfigurationFieldIdentifier): void => {

            this.configurationFields[identifier].toProcess = this.isConfigurationFieldConfigured(identifier);
        });
    }

    private _initEvents(): void {

        this.form.get('isClosed').valueChanges.subscribe((isClosed: boolean): void => {

            Object.keys(this.configurationFields).forEach((identifier: ConfigurationFieldIdentifier): void => {

                this.configurationFields[identifier].toProcess = (isClosed ? false : this.isConfigurationFieldConfigured(identifier));

                this.handleConfigurationFieldProcess(identifier, true);
            });
        });

        this.stockForm.get('unlimited').valueChanges.subscribe((): void => {

            this.handleConfigurationFieldProcess('stock', true);
        });
    }

    private _loadOfferDate(): void {

        this._offerDateService.getItemAPI(this.offer.id, this.offerDateId).subscribe((item: OfferDate): void => {

            this.offerDate = item;

            this._initConfigurationFields();

            this._hydrateForm(this.offerDate);

            this._initEvents();
        });
    }

    private _loadBookings(): void {

        const params: string[] = [
            `sort[createdAt]=desc`,
            `page=${this.bookingOffset + 1}`,
            `limit=${this.bookingPerPage}`,
            'status[in][]=validated',
            'status[in][]=option-set',
            `offer.id[eq]=${this.offer.id}`,
            `composition.dateStart[eq]=${this.date.format(DATE_FORMAT)}`,
            `type[eq]=booking`
        ];

        this._bookingService.getPaginationSocietyItemsAPI(this.currentUser.society.id, params).pipe(
            map((data: Pagination<Booking>): Booking[] => {

                this.totalBookings = data.totalItems;

                return data.items;
            })
        ).subscribe((items: Booking[]): void => {

            this.bookings.push(...items);
        });
    }

    private _hydrateForm(item: OfferDate): void {

        this.form.patchValue({
            dateStart: item.date,
            dateEnd: item.date,
            isClosed: item.isClosed
        });

        this.stockForm.get('unlimited').patchValue(item.unlimitedStock || false);

        item.stocks.forEach((stock: OfferDateStock): void => {

            this.stocksForm.push(this._createStockControl(stock));
        });

        this.priceForm.patchValue({
            adultPriceHT: item.adultPriceHT,
            adultPriceTTC: item.adultPriceTTC,
            childPriceHT: item.childPriceHT,
            childPriceTTC: item.childPriceTTC
        });

        this.releaseDateForm.patchValue({
            value: item.releaseDate
        });

        Object.keys(this.configurationFields).forEach((identifier: ConfigurationFieldIdentifier): void => {

            this.handleConfigurationFieldProcess(identifier, false);
        });
    }

    private _handleStockControls(applyReset: boolean): void {

        if(!this.hasOnlineSaleAccess){

            return;
        }

        if(applyReset){

            this.stocksForm.clearValidators();

            this.stocksForm.clear();

            if(!this.stockForm.get('unlimited').value){

                this.stocksForm.push(this._createStockControl(this.currentPublicStock || {
                    commonAllocatedStock: 0,
                    commonSoldStock: 0,
                    commonRemainingStock: 0,
                    adultAllocatedStock: 0,
                    adultSoldStock: 0,
                    adultRemainingStock: 0,
                    childAllocatedStock: 0,
                    childSoldStock: 0,
                    childRemainingStock: 0
                }));
            }
        }

        this.stocksForm.controls.forEach((control: UntypedFormGroup): void => {

            // Commun

            if(this.configurationFields.stock.toProcess && this.isStockManagementType('common')) {

                control.get('commonAllocatedStock').setValidators([Validators.pattern(/^[0-9]*$/), (control: UntypedFormControl) => {

                    if(!this.stockForm.get('unlimited').value && Number.isNaN(parseInt(control.value))){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]);
            }

            // Adultes

            if(this.configurationFields.stock.toProcess && this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('adult')) {

                control.get('adultAllocatedStock').setValidators([Validators.pattern(/^[0-9]*$/), (control: UntypedFormControl) => {

                    if(!this.stockForm.get('unlimited').value && Number.isNaN(parseInt(control.value))){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]);
            }

            // Enfants

            if(this.configurationFields.stock.toProcess && this.isStockManagementType('dissociated') && this.isSelectedOfferPricePublic('child')) {

                control.get('childAllocatedStock').setValidators([Validators.pattern(/^[0-9]*$/), (control: UntypedFormControl) => {

                    if(!this.stockForm.get('unlimited').value && Number.isNaN(parseInt(control.value))){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]);
            }
        });

        this.stocksForm.updateValueAndValidity();
    }

    private _handlePriceControls(applyReset: boolean): void {

        // Tarifs adultes

        if(applyReset){

            this.priceForm.get('adultPriceHT').clearValidators();

            this.priceForm.get('adultPriceTTC').clearValidators();

            this.priceForm.get('adultPriceHT').patchValue(this.offerDate.adultPriceHT);

            this.priceForm.get('adultPriceTTC').patchValue(this.offerDate.adultPriceTTC);
        }

        if(this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('adult')){

            // Tarif HT

            if(this.offer.vatPercent){

                this.priceForm.get('adultPriceHT').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
            }

            // Tarif TTC

            this.priceForm.get('adultPriceTTC').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
        }

        this.priceForm.get('adultPriceHT').updateValueAndValidity();

        this.priceForm.get('adultPriceTTC').updateValueAndValidity();

        // Tarifs enfants

        if(applyReset){

            this.priceForm.get('childPriceHT').clearValidators();

            this.priceForm.get('childPriceTTC').clearValidators();

            this.priceForm.get('childPriceHT').patchValue(this.offerDate.childPriceHT);

            this.priceForm.get('childPriceTTC').patchValue(this.offerDate.childPriceTTC);
        }

        if(this.configurationFields.price.toProcess && this.isSelectedOfferPricePublic('child')){

            // Tarif HT

            if(this.offer.vatPercent){

                this.priceForm.get('childPriceHT').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
            }

            // Tarif TTC

            this.priceForm.get('childPriceTTC').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
        }

        this.priceForm.get('childPriceHT').updateValueAndValidity();

        this.priceForm.get('childPriceTTC').updateValueAndValidity();
    }

    private _handleReleaseDateControl(applyReset: boolean): void {

        if(!this.hasOnlineSaleAccess){

            return;
        }

        if(applyReset){

            this.releaseDateForm.get('value').clearValidators();

            this.releaseDateForm.get('value').patchValue(this.offerDate.releaseDate);
        }

        if(this.configurationFields.releaseDate.toProcess) {

            this.releaseDateForm.get('value').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);
        }

        this.releaseDateForm.get('value').updateValueAndValidity();
    }

    private _createStockControl(data: Partial<OfferDateStock>): UntypedFormGroup {

        const group: UntypedFormGroup = this._formBuilder.group({
            type: ['public' as OfferDateStockType, [Validators.required]],
            commonAllocatedStock: [data.commonAllocatedStock],
            commonSoldStock: [data.commonSoldStock],
            commonRemainingStock: [data.commonRemainingStock],
            adultAllocatedStock: [data.adultAllocatedStock],
            adultSoldStock: [data.adultSoldStock],
            adultRemainingStock: [data.adultRemainingStock],
            childAllocatedStock: [data.childAllocatedStock],
            childSoldStock: [data.childSoldStock],
            childRemainingStock: [data.childRemainingStock]
        });

        if('id' in data){

            group.addControl('id', new UntypedFormControl(data.id, [Validators.required]));
        }

        return group;
    }

    public isStockManagementType(value: OfferStockType): boolean {

        return this.offer.onlineSale.stockManagementType === value;
    }

    public handleConfigurationFieldProcess(identifier: ConfigurationFieldIdentifier, applyReset: boolean): void {

        if(!this.configurationFields[identifier].allowed){

            return;
        }

        switch (identifier){

            case 'stock':

                this._handleStockControls(applyReset);

                break;

            case 'price':

                this._handlePriceControls(applyReset);

                break;

            case 'releaseDate':

                this._handleReleaseDateControl(applyReset);

                break;
        }
    }

    public isSelectedOfferPricePublic(type: OfferPricePublicType): boolean {

        return this.offer.publics.includes(type);
    }

    public calculatePrices(): void {

        const TVA: number = this.offer.vatPercent * 100;

        if(this.priceTypeReference === 'HT'){

            if(this.isSelectedOfferPricePublic('adult')){

                const controlReference: AbstractControl = this.priceForm.get('adultPriceHT');

                const controlToCalculate: AbstractControl = this.priceForm.get('adultPriceTTC');

                const HT: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(HT + (HT * TVA / 100), 2, '', '.') : null);
            }

            if(this.isSelectedOfferPricePublic('child')){

                const controlReference: AbstractControl = this.priceForm.get('childPriceHT');

                const controlToCalculate: AbstractControl = this.priceForm.get('childPriceTTC');

                const HT: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(HT + (HT * TVA / 100), 2, '', '.') : null);
            }
        }

        if(this.priceTypeReference === 'TTC'){

            if(this.isSelectedOfferPricePublic('adult')){

                const controlReference: AbstractControl = this.priceForm.get('adultPriceTTC');

                const controlToCalculate: AbstractControl = this.priceForm.get('adultPriceHT');

                const TTC: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(((100 * TTC) / (100 + TVA)), 2, '', '.') : null);
            }

            if(this.isSelectedOfferPricePublic('child')){

                const controlReference: AbstractControl = this.priceForm.get('childPriceTTC');

                const controlToCalculate: AbstractControl = this.priceForm.get('childPriceHT');

                const TTC: number = parseFloat(controlReference.value);

                controlToCalculate.patchValue(!!controlReference.value ? parsePrice(((100 * TTC) / (100 + TVA)), 2, '', '.') : null);
            }
        }
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public loadMoreBookings(): void {

        if(!this.hasMoreBookings){

            return;
        }

        this.bookingOffset++;

        this._loadBookings();
    }

    public identifyBooking(index: number, item: Booking): string {

        return `${item.reference}`;
    }

    public isConfigurationFieldConfigured(type: ConfigurationFieldIdentifier): boolean {

        const items: { value: unknown, valid: (value: unknown) => boolean }[] = [];

        switch (type){

            case 'stock':

                items.push(...[
                    {
                        value: this.offerDate.stocks,
                        valid: (data: OfferDateStock[]) => { return data.length > 0; }
                    },
                    {
                        value: this.offerDate,
                        valid: (data: OfferDate) => { return data.unlimitedStock; }
                    }
                ]);

                break;

            case 'price':

                items.push(...[
                    {
                        value: this.offerDate.adultPriceHT,
                        valid: (data: number) => { return typeof data === 'number'; }
                    },
                    {
                        value: this.offerDate.adultPriceTTC,
                        valid: (data: number) => { return typeof data === 'number'; }
                    },
                    {
                        value: this.offerDate.childPriceHT,
                        valid: (data: number) => { return typeof data === 'number'; }
                    },
                    {
                        value: this.offerDate.childPriceTTC,
                        valid: (data: number) => { return typeof data === 'number'; }
                    }
                ]);

                break;

            case 'releaseDate':

                items.push(...[
                    {
                        value: this.offerDate.releaseDate,
                        valid: (data: number) => { return typeof data === 'number'; }
                    }
                ]);

                break;
        }

        return items.some((item: { value: unknown, valid: (value: unknown) => boolean }): boolean => {

            return item.valid(item.value);
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get stockForm(): UntypedFormGroup {

        return this.form.get('stock') as UntypedFormGroup;
    }

    get priceForm(): UntypedFormGroup {

        return this.form.get('price') as UntypedFormGroup;
    }

    get releaseDateForm(): UntypedFormGroup {

        return this.form.get('releaseDate') as UntypedFormGroup;
    }

    get stocksForm(): UntypedFormArray {

        return this.stockForm.get('stocks') as UntypedFormArray;
    }

    get days(): Day[] {

        return DAYS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isPeriodOpen(): boolean {

        return !this.form.get('isClosed').value as boolean;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get hasOnlineSaleAccess(): boolean {

        return this.currentUser.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }

    get hasMoreBookings(): boolean {

        return this.bookings.length < this.totalBookings;
    }

    get currentPublicStock(): OfferDateStock {

        return this.offerDate.stocks.find((stock: OfferDateStock): boolean => {

            return stock.type === 'public';

        }) || null;
    }
}
