import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {Offer, OfferTranslationPicture} from "@core/shared/models/offer";

@Pipe({
    name: 'offerPictureSource'
})
export class OfferPictureSourcePipe implements PipeTransform {

    constructor(
        private _apiService: ApiService
    ){
    }

    transform(offer: Offer, picture: OfferTranslationPicture, format: string, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public/offers/${offer.id}/pictures/${picture.id}/filter/${format}/${width}/${quality}`;
    }
}
