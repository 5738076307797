<div class="form_account page_service">

    <div class="infos_user">
        <div class="row h_full">
            <div class="col y_items_center" *ngIf="user.value">
                <span class="name"><span>{{user.value.firstName.charAt(0) + user.value.lastName.charAt(0) }}</span>{{ user.value.firstName + ' ' + user.value.lastName }}</span>
            </div>
        </div>
    </div>

    <ng-container>
        <mat-tab-group #tabGroup [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectedIndex">
            <mat-tab label="{{'services.form.service.value' | translate}}">
                <ng-container *ngTemplateOutlet="services"></ng-container>
            </mat-tab>
            <mat-tab *ngIf="hasAccessListFile()" #documentTab>
                <ng-template mat-tab-label>
                    <span>{{'services.form.file.value' | translate}}</span>
                    <img [src]="documentTab.isActive ? 'assets/images/mangopay-logo-white.svg' : 'assets/images/mangopay-logo.svg'" alt="" title=""/>
                </ng-template>
                <ng-container *ngTemplateOutlet="files"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
    <ng-template #services>

        <div class="title_tab">
            <div class="row marges h_full x_between">
                <div class="col_content y_items_center">
                    <span class="title">{{'services.form.service.value' | translate}}</span>
                </div>
            </div>
        </div>

        <div class="content_tab">
            <ng-container *ngIf="user.value">
                <app-service-list [user]="user.value"></app-service-list>
            </ng-container>
        </div>

    </ng-template>
    <ng-template #files>

        <div class="title_tab">
            <div class="row marges h_full x_between">
                <div class="col_content y_items_center">
                    <span class="title">{{'services.form.file.value' | translate}}</span>
                </div>
            </div>
        </div>

        <div class="content_tab content_cards">
            <p [innerHTML]="'services.form.file.infos.value' | translate | safeHtml"></p>

            <div class="content_forms">

                <!-- Représentant légal -->

                <div class="content_form">
                    <div class="header_form">
                        <span class="title">{{ 'society.legalRepresentative.value' | translate }}</span>
                    </div>
                    <div class="body_form">
                        <ng-container [formGroup]="form">

                            <ng-container formGroupName="society">

                                <div formGroupName="legalRepresentative">
                                    <div class="row marges ligne_form">
                                        <div class="col_12">

                                            <!-- Civilité -->

                                            <div class="form-group">
                                                <label>{{'form.user.fields.civility.title.value' | translate}}</label>
                                                <div class="radio">
                                                    <input type="radio" [value]="1" formControlName="civility" class="form-control" id="legalRepresentative-civility-mister">
                                                    <label for="legalRepresentative-civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                                                </div>
                                                <div class="radio">
                                                    <input type="radio" [value]="2" formControlName="civility" class="form-control" id="legalRepresentative-civility-madam">
                                                    <label for="legalRepresentative-civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                                                </div>
                                                <mat-error *ngIf="societyLegalRepresentativeControl.get('civility').errors && (societyLegalRepresentativeControl.get('civility').dirty || societyLegalRepresentativeControl.get('civility').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_4">

                                            <!-- Nom -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="legalRepresentativeLastName" required="required">
                                                <label for="legalRepresentativeLastName">{{'form.user.fields.lastname.value' | translate}}</label>
                                                <mat-error *ngIf="societyLegalRepresentativeControl.get('lastName').errors && (societyLegalRepresentativeControl.get('lastName').dirty || societyLegalRepresentativeControl.get('lastName').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col_12 col_md_4">

                                            <!-- Prénom -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="legalRepresentativeFirstName" required="required">
                                                <label for="legalRepresentativeFirstName">{{'form.user.fields.firstname.value' | translate}}</label>
                                                <mat-error *ngIf="societyLegalRepresentativeControl.get('firstName').errors && (societyLegalRepresentativeControl.get('firstName').dirty || societyLegalRepresentativeControl.get('firstName').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_4">

                                            <!-- Téléphone -->

                                            <div class="form-group">

                                                <app-core-intl-tel-input [formGroup]="societyLegalRepresentativeControl" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.legalRepresentative.phone.value' | translate}}"></app-core-intl-tel-input>

                                                <mat-error *ngIf="societyLegalRepresentativeControl.get('phone').errors && (societyLegalRepresentativeControl.get('phone').dirty || societyLegalRepresentativeControl.get('phone').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="societyLegalRepresentativeControl.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">

                                        <div class="col_12 col_md_4">

                                            <!-- Nationalité -->

                                            <div class="form-group">
                                                <select class="form-control" id="legalRepresentativeNationality" formControlName="nationality" required="required">
                                                    <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                        {{ translationService.getFallbackTranslation(country.translations).name }}
                                                    </option>
                                                </select>
                                                <label class="required" for="legalRepresentativeNationality">{{ 'form.user.fields.nationality.value' | translate }}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('nationality').invalid && (societyLegalRepresentativeControl.get('nationality').dirty || societyLegalRepresentativeControl.get('nationality').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('nationality').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_4">

                                            <!-- Date de naissance -->

                                            <div class="form-group">
                                                <app-input-date [form]="societyLegalRepresentativeControl" [config]="birthDayConfig"></app-input-date>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Adresse -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="legalRepresentativeAddress" required="required">
                                                <label for="legalRepresentativeAddress">{{'form.user.fields.address.value' | translate}}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('address').errors && (societyLegalRepresentativeControl.get('address').dirty || societyLegalRepresentativeControl.get('address').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_6">

                                            <!-- Complément d'adresse -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="legalRepresentativeAdditionalAddress">
                                                <label for="legalRepresentativeAdditionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('additionalAddress').errors && (societyLegalRepresentativeControl.get('additionalAddress').dirty || societyLegalRepresentativeControl.get('additionalAddress').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Code postal -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="legalRepresentativeZipcode" required="required">
                                                <label for="legalRepresentativeZipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('zipcode').errors && (societyLegalRepresentativeControl.get('zipcode').dirty || societyLegalRepresentativeControl.get('zipcode').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        <div *ngIf="societyLegalRepresentativeControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col_12 col_md_6">

                                            <!-- Ville -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="legalRepresentativeCity" required="required">
                                                <label for="legalRepresentativeCity">{{'form.user.fields.city.value' | translate}}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('city').errors && (societyLegalRepresentativeControl.get('city').dirty || societyLegalRepresentativeControl.get('city').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row marges ligne_form">
                                        <div class="col_12 col_md_6">

                                            <!-- Région -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="legalRepresentativeRegion">
                                                <label for="legalRepresentativeRegion">{{'form.user.fields.region.value' | translate}}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('region').errors && (societyLegalRepresentativeControl.get('region').dirty || societyLegalRepresentativeControl.get('region').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('region').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="col_12 col_md_6">

                                            <!-- Pays -->

                                            <div class="form-group">
                                                <select class="form-control" id="legalRepresentativeCountry" formControlName="country" required="required">
                                                    <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                        {{ translationService.getFallbackTranslation(country.translations).name }}
                                                    </option>
                                                </select>
                                                <label class="required" for="legalRepresentativeCountry">{{ 'form.user.fields.country.value' | translate }}</label>
                                                <mat-error
                                                    *ngIf="societyLegalRepresentativeControl.get('country').invalid && (societyLegalRepresentativeControl.get('country').dirty || societyLegalRepresentativeControl.get('country').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="societyLegalRepresentativeControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <p>{{ 'services.form.file.infos.society.value' | translate}} </p>

                            </ng-container>

                        </ng-container>
                    </div>
                </div>

                <!-- création de compte bancaire -->

                <div class="content_form" *ngIf="isRequiredDocument('bank-account')">
                    <div class="header_form">
                        <span class="title">{{ 'services.form.file.bank_account.value' | translate }}</span>
                    </div>
                    <div class="body_form">
                        <form [formGroup]="form" (ngSubmit)="accountSubmit()" class="form_service_file">
                            <ng-container [formGroup]="form">

                                <ng-container formGroupName="society">
                                    <div formGroupName="bankAccount">

                                        <!-- Type de  compte bancaire-->
                                        <div class="row marges ligne_form">
                                            <div class="col_12 col_md_6" formGroupName="details">
                                                <div class="form-group">
                                                    <select class="form-control" id="type" formControlName="type" required="required" (change)="changeType()">
                                                        <option></option>
                                                        <option *ngFor="let type of bankAccountType" [value]="type">
                                                            {{ 'services.form.file.bank_account.type.values.'+ type +'.value' | translate }}
                                                        </option>
                                                    </select>
                                                    <label class="required" for="type">{{ 'services.form.file.bank_account.type.value' | translate }}</label>
                                                    <mat-error
                                                        *ngIf="bankAccountDetailsControl.get('type').invalid && (bankAccountDetailsControl.get('type').dirty || bankAccountDetailsControl.get('type').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="bankAccountDetailsControl.get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="col_12 col_md_6">
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="ownerName" id="ownerName">
                                                    <label class="required" for="ownerName">{{'services.form.file.bank_account.ownerName.value' | translate}}</label>
                                                    <mat-error
                                                        *ngIf="bankAccountControl.get('ownerName').errors && (bankAccountControl.get('ownerName').dirty || bankAccountControl.get('ownerName').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="bankAccountControl.get('ownerName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row marges ligne_form">
                                            <div class="col_12 y_items_center">
                                                <app-core-google-places-location [fields]="getAddressLocationFields(bankAccountAddressControl)"></app-core-google-places-location>
                                            </div>
                                        </div>

                                        <div id="container_adress" formGroupName="address" class="row marges ligne_form">
                                            <div class="col_12">
                                                <div class="row marges ligne_form">
                                                    <div class="col_12 col_md_6">

                                                        <!-- Adresse -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="bankAccountAddress" required="required">
                                                            <label for="bankAccountAddress">{{'form.user.fields.address.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="bankAccountAddressControl.get('address').errors && (bankAccountAddressControl.get('address').dirty || bankAccountAddressControl.get('address').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="bankAccountAddressControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                    <div class="col_12 col_md_6">

                                                        <!-- Complément d'adresse -->
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="bankAccountAdditionalAddress">
                                                            <label for="bankAccountAdditionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="bankAccountAddressControl.get('additionalAddress').errors && (bankAccountAddressControl.get('additionalAddress').dirty || bankAccountAddressControl.get('additionalAddress').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="bankAccountAddressControl.get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form">
                                                    <div class="col_12 col_md_6">

                                                        <!-- Code postal -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="bankAccountZipcode" required="required">
                                                            <label for="bankAccountZipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="bankAccountAddressControl.get('zipcode').errors && (bankAccountAddressControl.get('zipcode').dirty || bankAccountAddressControl.get('zipcode').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="bankAccountAddressControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="bankAccountAddressControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                    <div class="col_12 col_md_6">

                                                        <!-- Ville -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="bankAccountCity" required="required">
                                                            <label for="bankAccountCity">{{'form.user.fields.city.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="bankAccountAddressControl.get('city').errors && (bankAccountAddressControl.get('city').dirty || bankAccountAddressControl.get('city').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="bankAccountAddressControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row marges ligne_form">
                                                    <div class="col_12 col_md_6">

                                                        <!-- Région -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="bankAccountRegion">
                                                            <label  class="required" for="bankAccountRegion">{{'form.user.fields.region.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="bankAccountAddressControl.get('region').errors && (bankAccountAddressControl.get('region').dirty || bankAccountAddressControl.get('region').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="bankAccountAddressControl.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                    <div class="col_12 col_md_6">

                                                        <!-- Pays -->
                                                        <div class="form-group">
                                                            <select class="form-control" id="bankAccountCountry" formControlName="country" required="required">
                                                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                                                </option>
                                                            </select>
                                                            <label class="required" for="bankAccountCountry">{{ 'form.user.fields.country.value' | translate }}</label>
                                                            <mat-error
                                                                *ngIf="bankAccountAddressControl.get('country').invalid && (bankAccountAddressControl.get('country').dirty || bankAccountAddressControl.get('country').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="bankAccountAddressControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="container_details" formGroupName="details" class="row marges ligne_form">

                                            <div class="col_12">

                                                <ng-container *ngIf="bankAccountDetailsControl.get('type').value == 'IBAN'">

                                                    <div class="ligne_form" id="container_iban" formGroupName="iban">
                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="iban" id="iban">
                                                                    <label class="required" for="iban">{{'services.form.file.bank_account.type.values.IBAN.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsIbanControl.get('iban').errors && (bankAccountDetailsIbanControl.get('iban').dirty || bankAccountDetailsIbanControl.get('iban').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsIbanControl.get('iban').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="bic" id="bic">
                                                                    <label class="required" for="bic">{{'services.form.file.bank_account.type.values.BIC.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsIbanControl.get('bic').errors && (bankAccountDetailsIbanControl.get('bic').dirty || bankAccountDetailsIbanControl.get('bic').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsIbanControl.get('bic').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                                <ng-container *ngIf="bankAccountDetailsControl.get('type').value === 'CA'">

                                                    <div class="ligne_form" id="container_ca" formGroupName="ca">
                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="bankName" id="bankName">
                                                                    <label class="required" for="bankName">{{'services.form.file.bank_account.bankName.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsCaControl.get('bankName').errors && (bankAccountDetailsCaControl.get('bankName').dirty || bankAccountDetailsCaControl.get('bankName').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('bankName').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="institutionNumber" id="institutionNumber">
                                                                    <label class="required" for="institutionNumber">{{'services.form.file.bank_account.institutionNumber.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsCaControl.get('institutionNumber').errors && (bankAccountDetailsCaControl.get('institutionNumber').dirty || bankAccountDetailsCaControl.get('institutionNumber').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('institutionNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('institutionNumber').errors.pattern">{{ 'services.form.file.bank_account.institutionNumber.pattern.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="branchCode" id="branchCode">
                                                                    <label class="required" for="branchCode">{{'services.form.file.bank_account.branchCode.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsCaControl.get('branchCode').errors && (bankAccountDetailsCaControl.get('branchCode').dirty || bankAccountDetailsCaControl.get('branchCode').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('branchCode').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('branchCode').errors.pattern">{{ 'services.form.file.bank_account.branchCode.pattern.value' | translate }}</div>

                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="accountNumber" id="accountNumber">
                                                                    <label class="required" for="accountNumber">{{'services.form.file.bank_account.accountNumber.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsCaControl.get('accountNumber').errors && (bankAccountDetailsCaControl.get('accountNumber').dirty || bankAccountDetailsCaControl.get('accountNumber').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('accountNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            <div *ngIf="bankAccountDetailsCaControl.get('accountNumber').errors.pattern">{{ 'services.form.file.bank_account.accountNumber.pattern.value' | translate }}</div>

                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                                <ng-container *ngIf="bankAccountDetailsControl.get('type').value === 'GB'">

                                                    <div class="ligne_form" id="container_gb" formGroupName="gb">
                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="accountNumber" id="accountNumber">
                                                                    <label class="required" for="accountNumber">{{'services.form.file.bank_account.accountNumber.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsGbControl.get('accountNumber').errors && (bankAccountDetailsGbControl.get('accountNumber').dirty || bankAccountDetailsGbControl.get('accountNumber').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsGbControl.get('accountNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="sortCode" id="sortCode">
                                                                    <label class="required" for="sortCode">{{'services.form.file.bank_account.sortCode.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsGbControl.get('sortCode').errors && (bankAccountDetailsGbControl.get('sortCode').dirty || bankAccountDetailsGbControl.get('sortCode').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsGbControl.get('sortCode').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>


                                                <ng-container *ngIf="bankAccountDetailsControl.get('type').value === 'US'">

                                                    <div class="ligne_form" id="container_us" formGroupName="us">
                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="accountNumber" id="accountNumber">
                                                                    <label class="required" for="accountNumber">{{'services.form.file.bank_account.accountNumber.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsUsControl.get('accountNumber').errors && (bankAccountDetailsUsControl.get('accountNumber').dirty || bankAccountDetailsUsControl.get('accountNumber').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsUsControl.get('accountNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="aba" id="aba">
                                                                    <label class="required" for="aba">{{'services.form.file.bank_account.aba.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsUsControl.get('aba').errors && (bankAccountDetailsUsControl.get('aba').dirty || bankAccountDetailsUsControl.get('aba').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsUsControl.get('aba').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            <div *ngIf="bankAccountDetailsUsControl.get('aba').errors.pattern">{{ 'services.form.file.bank_account.aba.pattern.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                                <ng-container *ngIf="bankAccountDetailsControl.get('type').value === 'OTHER'">

                                                    <div class="ligne_form" id="container_other" formGroupName="other">

                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="accountNumber" id="accountNumber">
                                                                    <label class="required" for="accountNumber">{{'services.form.file.bank_account.accountNumber.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsOtherControl.get('accountNumber').errors && (bankAccountDetailsOtherControl.get('accountNumber').dirty || bankAccountDetailsOtherControl.get('accountNumber').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsOtherControl.get('accountNumber').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_12 col_md_6">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="bic" id="bic">
                                                                    <label class="required" for="bic">{{'services.form.file.bank_account.bic.value' | translate}}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsOtherControl.get('bic').errors && (bankAccountDetailsOtherControl.get('bic').dirty || bankAccountDetailsOtherControl.get('bic').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsOtherControl.get('bic').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row marges ligne_form">
                                                            <div class="col_12 col_md_6">
                                                                <!-- Pays -->
                                                                <div class="form-group">
                                                                    <select class="form-control" id="country" formControlName="country">
                                                                        <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                                            {{ translationService.getFallbackTranslation(country.translations).name }}
                                                                        </option>
                                                                    </select>
                                                                    <label class="required" for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                                                                    <mat-error
                                                                        *ngIf="bankAccountDetailsOtherControl.get('country').invalid && (bankAccountDetailsOtherControl.get('country').dirty || bankAccountDetailsOtherControl.get('country').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="bankAccountDetailsOtherControl.get('country').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                            </div>

                                        </div>
                                    </div>

                                </ng-container>

                            </ng-container>

                            <div class="row marges" [ngClass]="bankAccountDetailsControl.get('type').value ? 'mt_24' : ''">

                                <div class="col_12 x_end">

                                       <button class="btn_cta btn_little" type="submit">
                                           {{ 'services.form.submit'|translate }}
                                       </button>

                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <div class="content_form">
                    <div class="header_form">
                        <span class="title">{{ 'services.form.file.kyc.value' | translate }} ({{'services.form.file.kyc.format.value' | translate }})</span>
                    </div>
                    <div class="body_form">
                        <!-- création de KYC - Pièce d’identité du responsable légal-->
                        <div class="row marges ligne_form" *ngIf="isRequiredDocument('identity')">
                            <div class="col_12">
                                <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="submitIdCard()" class="form_service_file">
                                    <div formGroupName="society">

                                        <div class="row marges" formGroupName="kyc">
                                            <div class="col y_items_center">
                                                <!-- Upload d'un fichier -->
                                                <div class="form-group" formGroupName="idCard">
                                                    <label class="title required">{{ 'services.form.file.kyc.idCard.value' | translate }}</label>
                                                    <p>{{'services.form.file.kyc.size.value' | translate: {min: 32, max: 10} }}</p>
                                                    <input type="file" value="" placeholder=" " class="form-control" formControlName="file" (change)="handleFileInput($event.target.files)">
                                                    <p *ngIf="this.statusIdCard" [ngClass]="this.statusIdCard" class="status">{{ 'services.form.file.kyc.idCard.status.value' | translate }}
                                                        : <span>{{  'services.form.file.kyc.idCard.status.' + this.statusIdCard + '.value' | translate }}</span>
                                                        <span *ngIf="statusIdCard && isRefused(statusIdCard)">
                                                            <br/>
                                                             <ng-container *ngIf="errorStatusIdCard">{{ 'services.form.file.kyc.refusalReason.status.' + errorStatusIdCard + '.value' | translate }}</ng-container>
                                                            <ng-container *ngIf="errorMessageIdCard"> : {{ errorMessageIdCard }}</ng-container>
                                                        </span>
                                                    </p>
                                                    <mat-error
                                                        *ngIf="kycIdCardControl.get('file').invalid && (kycIdCardControl.get('file').dirty || kycIdCardControl.get('file').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="kycIdCardControl.get('file').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="col_content y_items_center">
                                                <button class="btn_cta btn_little" type="submit">
                                                    {{ 'services.form.submit'|translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- création de KYC - Preuve d’enregistrement ou KBIS-->
                        <div class="row marges ligne_form" *ngIf="isRequiredDocument('registration')">
                            <div class="col_12">
                                <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="submitKbis()" class="form_service_file">
                                    <div formGroupName="society">

                                        <div class="row marges" formGroupName="kyc">
                                            <div class="col y_items_center">
                                                <!-- Upload d'un fichier -->
                                                <div class="form-group" formGroupName="kbis">
                                                    <label class="title required">{{ 'services.form.file.kyc.kbis.' + societyType + '.value' | translate }}</label>
                                                    <p>{{'services.form.file.kyc.size.value' | translate: {min: 10, max: 10} }}</p>
                                                    <input type="file" value="" placeholder=" " class="form-control" formControlName="file" (change)="handleFileKbisInput($event.target.files)">
                                                    <p *ngIf="this.statusKbis" [ngClass]="this.statusKbis" class="status">{{ 'services.form.file.kyc.idCard.status.value' | translate }}
                                                        : <span>{{  'services.form.file.kyc.idCard.status.' + this.statusKbis + '.value' | translate }}</span>
                                                        <span *ngIf="statusKbis && isRefused(statusKbis)">
                                                            <br/>
                                                            <ng-container *ngIf="errorStatusKbis">{{ 'services.form.file.kyc.refusalReason.status.' + errorStatusKbis + '.value' | translate }}</ng-container>
                                                            <ng-container *ngIf="errorMessageKbis"> : {{ errorMessageKbis }}</ng-container>
                                                        </span>
                                                    </p>
                                                    <mat-error
                                                        *ngIf="kycKbisControl.get('file').invalid && (kycKbisControl.get('file').dirty || kycKbisControl.get('file').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="kycKbisControl.get('file').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="col_content y_items_center">
                                                <button class="btn_cta btn_little" type="submit">
                                                    {{ 'services.form.submit'|translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- création de KYC - Statut de la société-->
                        <div class="row marges ligne_form" *ngIf="isRequiredDocument('association')">
                            <div class="col_12">
                                <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="submitStatus()" class="form_service_file">
                                    <div formGroupName="society">

                                        <div class="row marges" formGroupName="kyc">
                                            <div class="col y_items_center">
                                                <!-- Upload d'un fichier -->
                                                <div class="form-group" formGroupName="status">
                                                    <label class="title required">{{ 'services.form.file.kyc.status.' + societyType + '.value' | translate }}</label>
                                                    <p>{{'services.form.file.kyc.size.value' | translate: {min: 10, max: 10} }}</p>
                                                    <input type="file" value="" placeholder=" " class="form-control" formControlName="file" (change)="handleFileStatusInput($event.target.files)">
                                                    <p *ngIf="this.statusStatus" [ngClass]="this.statusStatus" class="status">{{ 'services.form.file.kyc.idCard.status.value' | translate }}
                                                        : <span>{{  'services.form.file.kyc.idCard.status.' + this.statusStatus + '.value' | translate }}</span>
                                                        <span *ngIf="statusStatus && isRefused(statusStatus)">
                                                            <br/>
                                                            <ng-container *ngIf="errorStatusStatus">{{ 'services.form.file.kyc.refusalReason.status.' + errorStatusStatus + '.value' | translate }}</ng-container>
                                                            <ng-container *ngIf="errorMessageStatus"> : {{ errorMessageStatus }}</ng-container>
                                                        </span>
                                                    </p>
                                                    <mat-error
                                                        *ngIf="kycStatusControl.get('file').invalid && (kycStatusControl.get('file').dirty || kycStatusControl.get('file').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="kycStatusControl.get('file').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="col_content y_items_center">
                                                <button class="btn_cta btn_little" type="submit">
                                                    {{ 'services.form.submit'|translate }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content_form" *ngIf="isRequiredDocument('ubo-declaration')">
                    <div class="header_form">
                        <span class="title">{{ 'services.form.file.ubo.value' | translate }}</span>
                    </div>
                    <div class="body_form">
                        <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="submitUbo()">
                            <div formGroupName="society">
                                <div class="form-group ">
                                    <p *ngIf="this.statusUbo" [ngClass]="this.statusUbo" class="status">{{ 'services.form.file.ubo.infos.status.value' | translate }}
                                        : <span>{{  'services.form.file.ubo.infos.' + this.statusUbo + '.value' | translate }}</span>
                                        <span *ngIf="statusUbo && isRefused(statusUbo)">
                                        <br/>
                                        <ng-container *ngIf="errorStatusUbo">{{ 'services.form.file.ubo.refusalReason.status.' + errorStatusUbo + '.value' | translate }}</ng-container>
                                        <ng-container *ngIf="errorMessageUbo"> : {{ errorMessageUbo }}</ng-container>
                                    </span>
                                    </p>
                                    <br/>
                                </div>

                                <div [hidden]="this.statusUbo === 'waiting' ">
                                    <div class="col_12 collection" formArrayName="ubo">
                                        <div class="ligne_form removable_item" *ngFor="let ubo of uboControls.controls; let j=index">
                                            <ng-container [formGroupName]="j">

                                                <div class="row marges ligne_form">
                                                    <div class="col_12 col_md_6">
                                                        <div class="form-group ">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName">
                                                            <label class="required" for="firstName">{{'services.form.file.ubo.firstName.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('firstName').errors && (uboControls['controls'][j].get('firstName').dirty || uboControls['controls'][j].get('firstName').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('firstName').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                    <div class="col_12 col_md_6">
                                                        <div class="form-group ">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName">
                                                            <label class="required" for="lastName">{{'services.form.file.ubo.lastName.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('lastName').errors && (uboControls['controls'][j].get('lastName').dirty || uboControls['controls'][j].get('lastName').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('lastName').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form">
                                                    <div class="col_12 col_md_6">
                                                        <div class="form-group">
                                                            <select class="form-control" id="nationality" formControlName="nationality" required="required">
                                                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                                                </option>
                                                            </select>
                                                            <label class="required" for="nationality">{{ 'services.form.file.ubo.nationality.value' | translate }}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('nationality').invalid && (uboControls['controls'][j].get('nationality').dirty || uboControls['controls'][j].get('nationality').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('nationality').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                    <div class="col_12 col_md_6">
                                                        <div class="form-group">
                                                            <!-- Date de naissance -->
                                                            <app-input-date [form]="uboControls['controls'][j]" [config]="birthDayUboConfig"></app-input-date>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form" formGroupName="birthPlace">
                                                    <div class="col_12 col_md_6">
                                                        <div class="form-group ">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city">
                                                            <label class="required" for="city">{{'services.form.file.ubo.birthPlace.city.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('birthPlace').get('city').errors && (uboControls['controls'][j].get('birthPlace').get('city').dirty || uboControls['controls'][j].get('birthPlace').get('city').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('birthPlace').get('city').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                    <div class="col_12 col_md_6">
                                                        <div class="form-group ">
                                                            <select class="form-control" id="country" formControlName="country" required="required">
                                                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                                                </option>
                                                            </select>
                                                            <label class="required" for="country">{{ 'services.form.file.ubo.birthPlace.country.value' | translate }}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('birthPlace').get('country').invalid && (uboControls['controls'][j].get('birthPlace').get('country').dirty || uboControls['controls'][j].get('birthPlace').get('country').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('birthPlace').get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form">
                                                    <div class="col_12 y_items_center">
                                                        <app-core-google-places-location [fields]="getAddressLocationFields(uboControls['controls'][j].get('address'))"></app-core-google-places-location>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form" formGroupName="address">
                                                    <div class="col_12 col_md_6 ">

                                                        <!-- Adresse -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                                                            <label  class="required" for="address">{{'form.user.fields.address.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('address').get('address').errors && (uboControls['controls'][j].get('address').get('address').dirty || uboControls['controls'][j].get('address').get('address').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                    <div class="col_12 col_md_6">

                                                        <!-- Complément d'adresse -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="legalRepresentativeAdditionalAddress">
                                                            <label for="legalRepresentativeAdditionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('address').get('additionalAddress').errors && (uboControls['controls'][j].get('address').get('additionalAddress').dirty || uboControls['controls'][j].get('address').get('additionalAddress').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('additionalAddress').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form" formGroupName="address">
                                                    <div class="col_12 col_md_6">
                                                        <!-- Code postal -->
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="legalRepresentativeZipcode" required="required">
                                                            <label class="required" for="legalRepresentativeZipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('address').get('zipcode').errors && (uboControls['controls'][j].get('address').get('zipcode').dirty || uboControls['controls'][j].get('address').get('zipcode').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                    <div class="col_12 col_md_6">
                                                        <!-- Ville -->
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="legalRepresentativeCity" required="required">
                                                            <label  class="required" for="legalRepresentativeCity">{{'form.user.fields.city.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('address').get('city').errors && (uboControls['controls'][j].get('address').get('city').dirty || uboControls['controls'][j].get('address').get('city').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form" formGroupName="address">
                                                    <div class="col_12 col_md_6">

                                                        <!-- Région -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="legalRepresentativeRegion">
                                                            <label class="required" for="legalRepresentativeRegion">{{'form.user.fields.region.value' | translate}}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('address').get('region').errors && (uboControls['controls'][j].get('address').get('region').dirty || uboControls['controls'][j].get('address').get('region').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('region').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                    <div class="col_12 col_md_6">
                                                        <!-- Pays -->
                                                        <div class="form-group">
                                                            <select class="form-control" id="legalRepresentativeCountry" formControlName="country" required="required">
                                                                <option *ngFor="let country of countries$ | async" [value]="country.code">
                                                                    {{ translationService.getFallbackTranslation(country.translations).name }}
                                                                </option>
                                                            </select>
                                                            <label class="required" for="legalRepresentativeCountry">{{ 'form.user.fields.country.value' | translate }}</label>
                                                            <mat-error
                                                                *ngIf="uboControls['controls'][j].get('address').get('country').invalid && (uboControls['controls'][j].get('address').get('country').dirty || uboControls['controls'][j].get('address').get('country').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="uboControls['controls'][j].get('address').get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Suppression d'un UBO -->

                                                <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="removeUbo(j)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                            </ng-container>
                                        </div>

                                        <div class="ligne_form row">
                                            <div class="x_end">
                                                <button class="btn_cta btn_little col_content" type="button" [disabled]="uboControls.controls.length >= maxUboCount" (click)="addUbo(uboControls)">{{ 'services.form.file.ubo.add.value' | translate }}</button>
                                            </div>
                                        </div>
                                        <div class="ligne_form form-group">
                                            <span class="message-help">
                                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'services.form.file.ubo.infos.max.value' | translate:{ count: maxUboCount } }}
                                            </span>
                                        </div>

                                        <div class="ligne_form row x_end">
                                            <ng-container *ngIf="uboControls.controls.length >= 1">
                                                    <div class="col_content">
                                                        <button class="btn_cta btn_little" type="submit" >
                                                            {{ 'services.form.submit'|translate }}
                                                        </button>
                                                    </div>
                                            </ng-container>
                                        </div>

                                        <!-- création de KYC - Déclaration des bénéficiaires -->
                                        <br/><br/>
                                        <div class="row marges ligne_form" *ngIf="isRequiredDocument('shareholder')">
                                            <div class="col_12">
                                                <form enctype="multipart/form-data" [formGroup]="form" (ngSubmit)="submitShareholder()" class="form_service_file">
                                                    <div formGroupName="society">

                                                        <div class="row marges" formGroupName="kyc">
                                                            <div class="col y_items_center">
                                                                <!-- Upload d'un fichier -->
                                                                <div class="form-group" formGroupName="shareholder">
                                                                    <label class="title required">{{ 'services.form.file.kyc.shareholder.value' | translate }}</label>
                                                                    <p>{{'services.form.file.kyc.shareholder.description.value' | translate }} : <a href="{{'services.form.file.kyc.shareholder.description.download.link.value' | translate }}" target="_blank">{{'services.form.file.kyc.shareholder.description.download.value' | translate }}</a></p>
                                                                    <p>{{'services.form.file.kyc.shareholder.beneficiary.value' | translate }} </p>

                                                                    <p>{{'services.form.file.kyc.size.value' | translate: {min: 10, max: 10} }}</p>
                                                                    <input type="file" value="" placeholder=" " class="form-control" formControlName="file" (change)="handleFileShareholderInput($event.target.files)">
                                                                    <p *ngIf="statusShareholder" [ngClass]="statusShareholder" class="status">{{ 'services.form.file.kyc.idCard.status.value' | translate }}
                                                                        : <span>{{  'services.form.file.kyc.idCard.status.' + statusShareholder + '.value' | translate }}</span>
                                                                        <span *ngIf="statusShareholder && isRefused(statusShareholder)">
                                                                            <br/>
                                                                            <ng-container *ngIf="errorStatusShareholder">{{ 'services.form.file.kyc.refusalReason.status.' + errorStatusShareholder + '.value' | translate }}</ng-container>
                                                                            <ng-container *ngIf="errorMessageShareholder"> : {{ errorMessageShareholder }}</ng-container>
                                                                        </span>
                                                                    </p>
                                                                    <mat-error
                                                                        *ngIf="kycShareholderControl.get('file').invalid && (kycShareholderControl.get('file').dirty || kycShareholderControl.get('file').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="kycShareholderControl.get('file').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                            <div class="col_content y_items_center">
                                                                <button class="btn_cta btn_little" type="submit">
                                                                    {{ 'services.form.submit'|translate }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <br/><br/>
                                        <p>{{ 'services.form.file.ubo.infos.value' | translate }}</p>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    </ng-template>
</div>

