import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyService} from "@core/shared/services/society.service";
import {Observable} from "rxjs";
import {ServiceService} from "@core/shared/services/service.service";

@Injectable({
    providedIn: 'root'
})
export class SocietyServiceItemResolver  {

    constructor(
        private _serviceService: ServiceService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._serviceService.getSocietyServiceItemAPI(parseInt(route.paramMap.get('societyServiceId')))

    }
}
