<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'user.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" class="btn_cta btn_little btn_add">{{ 'user.list.button.value' | translate }}</button>
            </div>
            <div class="col_content y_items_center">
                <button (click)="exportSocieties()" class="btn_cta btn_little">{{ 'user.list.society.export.value' | translate }}</button>
            </div>
            <div class="col_content y_items_center">
                <button (click)="exportUsers()" class="btn_cta btn_little">{{ 'user.list.user.export.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="resetFilters()" mat-icon-button>
                        <mat-icon>loop</mat-icon>
                    </button>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('name')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.societyName.value' | translate" [key]="'name'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('subscriptionUrl')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.subscriptionUrl.value' | translate" [key]="'subscriptionUrl'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('email')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.societyEmail.value' | translate" [key]="'email'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('phone')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.societyPhone.value' | translate" [key]="'phone'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('admin.lastName')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.contactLastname.value' | translate" [key]="'admin.lastName'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('admin.firstName')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.contactFirstname.value' | translate" [key]="'admin.firstName'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('admin.email')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.contactEmail.value' | translate" [key]="'admin.email'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('admin.directPhone')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.contactPhone.value' | translate" [key]="'admin.directPhone'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('admin.cellphone')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.list.contactPhoneNumber.value' | translate" [key]="'admin.cellphone'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('admin.roles')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'user.list.roles.value' | translate" [key]="'admin.roles'" [operator]="'andlkin'" [itemValueProperty]="'identifier'" [itemLabelProperty]="'label'" [items]="filterRoles$ | async"></app-core-select-array-multiple-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('licences')">
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('countCollaborators')">
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('countOffers')">
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('countOffersRestricted')">
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('countOffersUnpublished')">
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('countChannels')">
                 <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('totalMarketplaceOffers')">
                <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>

            <!-- Liste des éléments -->

            <!--Actions-->

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                <mat-cell *matCellDef="let element; let i = index">
                    <button mat-button (click)="edit(element.id)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button (click)="openDeleteDialog(element.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!-- Date de création -->

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.createdAt.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | date : 'shortDate' : null : localeId}}</td>
            </ng-container>

            <!-- Nom de la société -->

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.societyName.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).name}}</td>
            </ng-container>


            <!-- URL d'inscription -->

            <ng-container matColumnDef="subscriptionUrl">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.subscriptionUrl.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"><a [href]="getModel(element).subscriptionUrl" target="_blank">{{ getModel(element).subscriptionUrl }}</a></td>
            </ng-container>

            <!--Email générique-->

            <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.societyEmail.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).email}}</td>
            </ng-container>

            <!--Téléphone générique-->

            <ng-container matColumnDef="phone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.societyPhone.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).phone}}</td>
            </ng-container>

            <!--Nom contact principal-->

            <ng-container matColumnDef="admin.lastName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.contactLastname.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{getModel(element).admin?.lastName}} </td>
            </ng-container>

            <!--Prénom contact principal-->

            <ng-container matColumnDef="admin.firstName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.contactFirstname.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{getModel(element).admin?.firstName}} </td>
            </ng-container>

            <!--Email contact principal-->

            <ng-container matColumnDef="admin.email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.contactEmail.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{getModel(element).admin?.email}} </td>
            </ng-container>

            <!--Téléphone fixe contact principal-->

            <ng-container matColumnDef="admin.directPhone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.contactPhone.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{getModel(element).admin?.directPhone}} </td>
            </ng-container>

            <!--Portable contact principal-->

            <ng-container matColumnDef="admin.cellphone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.list.contactPhoneNumber.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{getModel(element).admin?.cellphone}} </td>
            </ng-container>

            <!--Rôle utilisateur-->

            <ng-container matColumnDef="admin.roles">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.roles.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{ getModel(element).admin ? getRoleLabel(getModel(element).admin.roles) : null}}</td>
            </ng-container>

            <ng-container matColumnDef="licences">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.licences.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).countPublishedOffers }} </td>
            </ng-container>

            <!--Nombres de collaborateurs -->
            <ng-container matColumnDef="countCollaborators">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.countCollaborators.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).countCollaborators }} </td>
            </ng-container>

            <!--Nombres offres dans le catalogue -->
            <ng-container matColumnDef="countOffers">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.countOffers.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).countCatalogOffers }} </td>
            </ng-container>

            <!--Nombres d'offres restreintes -->
            <ng-container matColumnDef="countOffersRestricted">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.countOffersRestricted.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).countRestrictedOffers }} </td>
            </ng-container>

            <!--Nombres d'offres en brouillon -->
            <ng-container matColumnDef="countOffersUnpublished">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.countOffersUnpublished.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).countDraftOffers }} </td>
            </ng-container>

            <!--Nombres de canaux crées  -->
            <ng-container matColumnDef="countChannels">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.countChannels.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).countEnableChannels }} </td>
            </ng-container>

            <!-- Nombre d'offres dans la marketplace  -->
            <ng-container matColumnDef="totalMarketplaceOffers">
                <th mat-header-cell *matHeaderCellDef>{{'user.list.marketplace.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{ getModel(element).totalMarketplaceOffers }} </td>
            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Liste des éléments -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>
