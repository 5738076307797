import {ModeType} from "@core/shared/models/offer/offer-list";

export const OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER: string = 'offer-search';

export const OFFER_SEARCH_SESSION_STORAGE_ORIGIN: ModeType[] = [
    'personnal-offers',
    'reservoir',
    'catalog',
    'channel',
    'offer-permanent-option-personnal-offers',
    'offer-permanent-option-catalog',
    'tender-personnal-offers',
    'tender-proposed-offers',
    'hashtag-reservoir',
    'hashtag-catalog'
];

export interface OfferSearchSessionFilter {

    key: string;

    value: any;

    extraData?: { [p: string]: any };
}

export type OfferSearchResponseType = 'addedInCatalog' | 'waiting';
