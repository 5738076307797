<!-- Bibliothéque des services -->

<div class="row marges">

    <div class="col_12 col_lg_6 col_xl_4 content_card" *ngFor="let item of items">

        <app-service-item
            [user]="user"
            [item]="item">
        </app-service-item>

    </div>
</div>

