import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITemplate} from "./template";
import {TemplateService} from "./template.service";

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss']
})

/**
 * Template disponible pour la page
 */
export class TemplateComponent implements OnInit {

    @Input() templateId: string;

    @Output() addRowEvent: EventEmitter<ITemplate> = new EventEmitter<ITemplate>();

    template: ITemplate;

    /**
     * Constructeur
     *
     * @param {TemplateService} _templateService
     */
    constructor(private _templateService: TemplateService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        this.template = this._templateService.getTemplateById(this.templateId);
    }

    /**
     * Ajout d'une ligne
     * Appelle la méthode addRow() du TranslationComponent
     */
    addRow(): void{
        this.addRowEvent.emit(this.template);
    }

    counter(i: number): number[] {
        return new Array(i);
    }
}
