import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiVideoConfig, VideoConfig} from "./video";

@Injectable({
    providedIn: 'root'
})
export class VideoService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * PREFIX URL
     */
    private readonly PREFIX_URL: string = '/video';

    /**
     * CONFIG URL
     */
    private readonly CONFIG_URL: string = '/config';

    private _config: VideoConfig;

    constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    configLoaded(): boolean {

        return (typeof this._config !== 'undefined');
    }

    setConfig(config: VideoConfig): void {

        this._config = config;
    }

    /**
     * Retourne la configuration nécessaire au chargement d'une page
     *
     * @returns {Observable<ApiVideoConfig>}
     */
    loadConfig(): Observable<ApiVideoConfig> {

        return this._http.get<ApiVideoConfig>( `${this.API_URL}${this.PREFIX_URL}${this.CONFIG_URL}`);
    }

    /**
     * Retourne la taille maximale autorisée pour l'upload d'un fichier
     *
     * @returns {number}
     */
    getUploadMaxSize(): number {
        return this._config.uploadMaxSize;
    }
}
