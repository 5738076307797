import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {File} from "@lib/media/file/file";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";

@Pipe({
    name: 'fileSource'
})
export class FileSourcePipe implements PipeTransform {

    constructor(
        private _httpClient: HttpClient,
        private _apiService: ApiService,
        private _sanitizer: DomSanitizer
    ){}

    transform(file: File): Observable<any> {

        const url: string = environment.production ? `${this._apiService.getApiUrl(false, true)}/api/files/${file.id}/inline` : './assets/images/logo_tywin.png';

        return this._httpClient.get(url, { responseType: 'blob' }).pipe(map((blob: Blob): SafeUrl => {

            const imageUrl: string = URL.createObjectURL(blob);

            return this._sanitizer.bypassSecurityTrustUrl(imageUrl);
        }));
    }
}
