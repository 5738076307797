import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaymentInitialize} from "@core/shared/models/payment-initialize";

@Injectable({
    providedIn: 'root'
})
export class PaymentSubscriptionService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public paymentInitialize(id: number): Observable<PaymentInitialize> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/subscriptions/payment/initialize`;

        return this._httpClient.get<PaymentInitialize>(url, {
            headers: headers
        });
    }

    public paymentValidate(id: number, data: object): Observable<any> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/subscriptions/payment/validate`;

        return this._httpClient.post<any>(url, data, {
            headers: headers
        });
    }
}
