import {Injectable} from "@angular/core";
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";
import {SocietyGiftVoucherConfiguration} from "@core/shared/models/gift-voucher/society-gift-voucher-configuration";
import {Pagination} from "@core/shared/models/pagination";

@Injectable({
    providedIn: 'root'
})
export class GiftVoucherService {

    constructor(private _apiService: ApiService,
                private _httpClient: HttpClient) {
    }

    public getItemAPI(id: number): Observable<GiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<GiftVoucher>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/${id}`, {
            headers: headers
        }).pipe(
            map((item: object): GiftVoucher => {

                return plainToClass(GiftVoucher, item);
            })
        );
    }

    public getItemsAPI(id: number, params?: string[]): Observable<GiftVoucher[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/giftVouchers`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<GiftVoucher[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): GiftVoucher[] => {

                return items.map((item: object): GiftVoucher => {

                    return plainToClass(GiftVoucher, item);
                })
            })
        );
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<GiftVoucher>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/giftVouchers`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<GiftVoucher>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<GiftVoucher>): Pagination<GiftVoucher> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: GiftVoucher): GiftVoucher => {

                        return plainToClass(GiftVoucher, item);
                    })
                });
            })
        );
    }

    public getPaginationSocietyItemsAPI(societyId: number, params?: string[]): Observable<Pagination<GiftVoucher>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<GiftVoucher>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<GiftVoucher>): Pagination<GiftVoucher> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: GiftVoucher): GiftVoucher => {

                        return plainToClass(GiftVoucher, item);
                    })
                });
            })
        );
    }

    public getTemplateGiftVouchersBySocietyWithoutPagination(societyId: number, params?: string[]): Observable<TemplateGiftVoucher[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/templates`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<TemplateGiftVoucher[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): TemplateGiftVoucher[] => {

                return items.map((item: object): TemplateGiftVoucher => {

                    return plainToClass(TemplateGiftVoucher, item);
                })
            })
        );
    }

    public summarySocietyGiftVoucherItemAPI(data: object, societyId: number): Observable<GiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<GiftVoucher>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/summary`, data, {
            headers: headers
        }).pipe(
            map((item: object): GiftVoucher => {

                return plainToClass(GiftVoucher, item);
            })
        );
    }

    public getGiftVoucherConfiguration(societyId: number): Observable<SocietyGiftVoucherConfiguration> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<SocietyGiftVoucherConfiguration>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/configuration`, {
            headers: headers
        }).pipe(
            map((item: object): SocietyGiftVoucherConfiguration => {

                return plainToClass(SocietyGiftVoucherConfiguration, item);
            })
        );
    }

    public updateItemAPI(id: number, data: object): Observable<GiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<GiftVoucher>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/${id}`, data, {
            headers: headers
        }).pipe(
            map((item: object): GiftVoucher => {

                return plainToClass(GiftVoucher, item);
            })
        );
    }

    public updateItemsAPI(data: object): Observable<GiftVoucher[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<GiftVoucher[]>(`${this._apiService.getApiUrl(false, true)}/giftVouchers/multiple`, data, {
            headers: headers
        }).pipe(
            map((items: object[]): GiftVoucher[] => {

                return items.map((item: object): GiftVoucher => {

                    return plainToClass(GiftVoucher, item);
                })
            })
        );
    }

    public updateGiftVoucherConfiguration(societyId: number, data): Observable<SocietyGiftVoucherConfiguration> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<SocietyGiftVoucherConfiguration>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/configuration`, data, {
            headers: headers
        }).pipe(
            map((item: object): SocietyGiftVoucherConfiguration => {

                return plainToClass(SocietyGiftVoucherConfiguration, item);
            })
        );

    }

    public createItemAPI(societyId: number, data: object): Observable<GiftVoucher> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<GiftVoucher>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers`, data, {
            headers: headers
        }).pipe(
            map((item: object): GiftVoucher => {

                return plainToClass(GiftVoucher, item);
            })
        );
    }

    public downloadItemAPI(id: number): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/giftVouchers/${id}/download`, {
            responseType: 'blob'
        });
    }

    public detachOfferItemsAPI(offerId: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, true)}/offers/${offerId}/giftVouchers/detach`, {}, {
            headers: headers
        });
    }

    public exportItemsAPI(societyId: number): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/giftVouchers/export`, {
            responseType: 'blob'
        });

    }

    public adminExportItemsAPI(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/giftVouchers/export`, {
            responseType: 'blob'
        });
    }
}
