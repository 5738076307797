import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {fromEvent, Observable, ReplaySubject} from "rxjs";
import {MatSelectSearchComponent} from "ngx-mat-select-search";
import {debounceTime, map} from "rxjs/operators";

export type SelectSearchConfiguration<T> = {

    multiple?: boolean;

    displaySelection?: boolean;

    enableSearch?: boolean;

    currentSelectionLabel?: string;

    searchActionLabel?: string;

    selectOptionActionLabel?: string;

    noResultLabel?: string;

    itemLabel?: (item: T, index?: number) => unknown;

    compareOptionsCallback?: (a: T, b: T) => boolean;

    selectedItemDisplayedCallback?: (item: T) => boolean;

    deselectionEnabledCallback?: (item: T) => boolean;
}

@Component({
    selector: 'app-core-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent<T> implements OnInit, AfterViewInit {

    @Input() formGroupReference: UntypedFormGroup;

    @Input() formControlNameReference: string;

    @Input() configuration: SelectSearchConfiguration<T>;

    @Input() sourceCallback: (search: string) => Observable<T[]>;

    @ViewChild('nameFilter', {static: false}) nameFilterReference: MatSelectSearchComponent;

    private _defaultSelectSearchConfiguration: SelectSearchConfiguration<unknown> = {
        multiple: false,
        displaySelection: true,
        enableSearch: true,
        currentSelectionLabel: 'selectSearch.selection.current.value',
        searchActionLabel: 'selectSearch.search.action.value',
        selectOptionActionLabel: 'selectSearch.option.select.action.value',
        noResultLabel: 'selectSearch.noResult.value',
        itemLabel: (item: unknown, index?: number): unknown => {

            if(typeof item === 'object'){

                const objectItem = item as { id: number };

                return objectItem.id;
            }

            return item;
        },
        compareOptionsCallback: (a: unknown, b: unknown): boolean => {

            if(!a || !b){

                return false;
            }

            if((typeof a === 'object') && (typeof b === 'object')){

                const objectA = a as { id: number };

                const objectB = b as { id: number };

                return objectA.id === objectB.id;
            }

            return a === b;
        },
        selectedItemDisplayedCallback: (item: T): boolean => {

            return true;
        },
        deselectionEnabledCallback: (item: T): boolean => {

            return true;
        }
    }

    public optionFilterNameControl: UntypedFormControl = new UntypedFormControl();

    public items: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);

    ngOnInit(): void {

        this.configuration = Object.assign(this._defaultSelectSearchConfiguration, {...this.configuration} || {});

        if(!this.configuration.enableSearch) {

            return;
        }

        this._filterOptions();
    }

    ngAfterViewInit(): void {

        if(!this.nameFilterReference) {

            return;
        }

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'focus').subscribe((): void => {

            this._filterOptions();
        });

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this._handleClearSelection();

                this._filterOptions();
            })
        ;
    }

    private _filterOptions(): void {

        this.sourceCallback(this.optionFilterNameControl.value ? this.optionFilterNameControl.value.toLowerCase() : null).subscribe((items: T[]): void => {

            this.items.next(items.slice());
        });
    }

    private _handleClearSelection(): void {

        const element: HTMLButtonElement = this.nameFilterReference.innerSelectSearch.nativeElement.querySelector('.mat-select-search-clear');

        if(!element) {

            return;
        }

        element.onclick = (): void => {

            this.optionFilterNameControl.patchValue('');

            this._filterOptions();
        };
    }

    public handleClosure(): void {

        this.optionFilterNameControl.patchValue('');

        this._filterOptions();
    }

    public unselectItem(item: T, index?: number): void {

        if(this.controlDisabled){

            return;
        }

        if(this.configuration.multiple){

            const items: T[] = [...this.formGroupReference.get(this.formControlNameReference).value];

            items.splice(index, 1);

            this.formGroupReference.get(this.formControlNameReference).patchValue(items);
        }
        else{

            this.formGroupReference.get(this.formControlNameReference).patchValue(null);
        }
    }

    get selectedItem(): T {

        return this.formGroupReference.get(this.formControlNameReference).value as T;
    }

    get selectedItems(): T[] {

        const items: T[] = this.formGroupReference.get(this.formControlNameReference).value;

        return items.filter((item: T): boolean => {

            return this.configuration.selectedItemDisplayedCallback(item);
        });
    }

    get controlDisabled(): boolean {

        return this.formGroupReference.get(this.formControlNameReference).status === 'DISABLED';
    }
}
