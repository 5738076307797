import {Exclude, Expose, Transform, TransformFnParams, Type} from "class-transformer";
import {TranslationItem} from "../translation";
import {OfferOptionPublicType} from "@core/shared/models/offer/offer-option/offer-option-public";
import {Image} from "@lib/media/image/image";
import {OfferOptionPrice, OfferOptionPricePublicType, OfferOptionPriceTypeType} from "@core/shared/models/offer/offer-option/offer-option-price";
import {OfferOptionUniquePrice} from "@core/shared/models/offer/offer-option/offer-option-unique-price";
import {OfferOptionPresential} from "@core/shared/models/offer/offer-option/offer-option-presential";
import {OfferOptionPriceLevel} from "@core/shared/models/offer/offer-option/offer-option-price-level";
import {Currency} from "@core/shared/models/currency";
import {Offer} from "@core/shared/models/offer";
import {parsePrice} from "@core/shared/utils/price";
import {parseMarkup} from "@core/shared/utils/markup";
import {OfferPriceTypeType} from "@core/shared/models/offer/offer-price-type";

@Exclude()
export class OfferOption {

    @Expose()
    id: number;

    @Expose()
    locales: string[];

    @Expose()
    publics: OfferOptionPublicType[];

    @Expose()
    comment: string;

    @Expose()
    image: OfferImage;

    @Expose()
    availableByDay: boolean;

    @Expose()
    @Transform((params: TransformFnParams): number => {

        const value: number = params.value;

        return value * 100;

    }, {toClassOnly: true})
    @Transform((params: TransformFnParams): number => {

        const value: number = params.value;

        return value / 100;

    }, {toPlainOnly: true})
    markup: number;

    @Expose()
    @Transform((params: TransformFnParams): OfferOptionPrice[] => {

        const items: OfferOptionPrice[] = params.value;

        if(!items || !items.length){

            return [];
        }

        return items.map((item: OfferOptionPrice): OfferOptionPrice => {

            return Object.assign(item, {
                value: item.value ? (item.value / 100) : null
            })
        });

    }, {toClassOnly: true})
    @Transform((params: TransformFnParams): OfferOptionPrice[] => {

        const items: OfferOptionPrice[] = params.value;

        if(!items || !items.length){

            return [];
        }

        return items.map((item: OfferOptionPrice): OfferOptionPrice => {

            return Object.assign(item, {
                value: item.value ? (item.value * 100) : null
            })
        });

    }, {toPlainOnly: true})
    prices: OfferOptionPrice[];

    @Expose()
    @Transform((params: TransformFnParams): number => {

        const value: number = params.value;

        return value * 100;

    }, {toClassOnly: true})
    vatPercent: number;

    @Expose()
    uniquePrice: OfferOptionUniquePrice;

    @Expose()
    minChildrenAge: number;

    @Expose()
    maxChildrenAge: number;

    @Expose()
    presentialGroup: OfferOptionPresential;

    @Expose()
    presentialIndividual: OfferOptionPresential;

    @Expose()
    priceLevels: OfferOptionPriceLevel[];

    @Expose()
    translations: OfferOptionTranslation[];

    @Expose()
    startFromPrice: number;

    @Expose()
    startFromCurrency: Currency;

    @Expose()
    @Type(() => Offer)
    offers: Offer[];

    day: number = 0;

    nbAdult: number = 0;

    nbChild: number = 0;

    public getPriceByFilter(pricePublic: OfferOptionPricePublicType, priceType: OfferOptionPriceTypeType): OfferOptionPrice {

        return this.prices.find((price: OfferOptionPrice): boolean => {

            return price.public === pricePublic && price.type === priceType;
        });
    }

    public getParsedPriceByFilter(pricePublic: OfferOptionPricePublicType, priceType: OfferOptionPriceTypeType): string {

        const price: OfferOptionPrice = this.getPriceByFilter(pricePublic, priceType);

        if(!price) {

            return null;
        }

        return this.getParsedPrice(price);
    }

    public getParsedPrice(item: OfferOptionPrice): string {

        return !!item.value ? (parsePrice(item.value) + item.currency.symbol) : '';
    }

    get parsedMarkup(): string {

        return !!this.markup ? (parseMarkup(this.markup) + '%') : '';
    }
}

export interface OfferOptionTranslation extends TranslationItem {

    id: number;

    name: string;

    title: string;

    description: string;
}


export interface OfferImage {

    id: number;

    image: Image;
}
