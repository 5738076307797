<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'hashtag.plural.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="createItem()" *ngIf="hasAccessCreate" class="btn_cta btn_little btn_add">{{ 'hashtag.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Hashtag Ty-Win ? -->

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
                    <ng-container [matColumnDef]="getFilterColumnDef('isTywinHashtag')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-select-filter #filter [builder]="filterBuilder" [label]="'hashtag.isTywinHashtag.value' | translate" [key]="'society.id'" [operator]="'null'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isTywinHashtagItems"></app-core-select-filter>
                        </th>
                    </ng-container>
                </ng-container>

                <!-- Société -->

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <ng-container [matColumnDef]="getFilterColumnDef('society')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-text-filter #filter [builder]="filterBuilder" [label]="'form.user.fields.owner.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                        </th>
                    </ng-container>

                </ng-container>

                <!-- Nom  -->

                <ng-container [matColumnDef]="getFilterColumnDef('name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'hashtag.name.value' | translate" [key]="'translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nombre d'offres associées -->

                <ng-container [matColumnDef]="getFilterColumnDef('countOffers')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

            </ng-container>

            <!-- Liste des données -->

            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">

                        <button mat-button *ngIf="!hasAccessEdit && hasAccessRead" (click)="updateItem(element.id)">
                            <mat-icon>visibility</mat-icon>
                        </button>
                        <button mat-button *ngIf="hasAccessEdit" (click)="updateItem(element.id)">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Hashtag Ty-Win ? -->

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <ng-container matColumnDef="isTywinHashtag">
                        <th mat-header-cell *matHeaderCellDef>{{ 'hashtag.isTywinHashtag.value' | translate }}</th>
                        <td mat-cell *matCellDef="let element">{{ (getModel(element).society ? 'no.value' : 'yes.value') | translate }}</td>
                    </ng-container>

                </ng-container>

                <!-- Société -->

                <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <ng-container matColumnDef="society">
                        <th mat-header-cell *matHeaderCellDef>{{ 'form.user.fields.owner.value' | translate }}</th>
                        <td mat-cell *matCellDef="let element">{{ getModel(element).society ? getModel(element).society.name : 'Ty-Win' }}</td>
                    </ng-container>

                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'hashtag.name.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).translations).name }}</td>
                </ng-container>

                <!-- Nombre d'offres associées -->

                <ng-container matColumnDef="countOffers">
                    <th mat-header-cell *matHeaderCellDef>{{ 'offer.plural.associated.count.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).countOffers }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>
