<media-file-upload [config]="fileUploadConfig" (postUpload)="add($event)"></media-file-upload>

<div *ngIf="file" class="file-component">
    <div>
        <div>
            <mat-icon class="icon">attach_file</mat-icon>

            <input type="text" [(ngModel)]="file.originalName" (ngModelChange)="update()"/>

            <button type="button" (click)="downloadItem()" class="" matTooltip="{{ 'element.types.file.watch.action' | translate }}" matTooltipPosition="right">
                <mat-icon>remove_red_eye</mat-icon>
            </button>

            <button type="button" (click)="delete()" class="trash">
                <mat-icon>delete</mat-icon>
            </button>

        </div>
    </div>
</div>
