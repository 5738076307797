import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {SocietyGroupService} from "@core/shared/services/society-group.service";
import {SocietyGroup} from "@core/shared/models/society-group";

@Component({
  selector: 'app-core-society-group-list',
  templateUrl: './society-group-list.component.html',
  styleUrls: ['./society-group-list.component.scss']
})
export class SocietyGroupListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: SocietyGroup[];

    public currentUser: User;

    constructor(
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _societyGroupService: SocietyGroupService,
        public userService: UserService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this._initDisplayedColumns();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        if (this.sort) {

            this.sort.sortChange.subscribe(() => {

                this._resetPagination();
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {

                        return this._loadItems$();
                    }),
                    map(this.mapApiResult),
                ).subscribe((data: SocietyGroup[]): void => {

                this.items = data;
            });
        }

        this._loadItems$();
    }

    private _initDisplayedColumns(): void {

        this.displayedColumns.push('actions');

        this.displayedColumns.push('name');

        this.displayedColumns.push('comment');

        this.displayedColumns.push('createdAt');

        this.displayedColumns.push('updatedAt');
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._loadItems$().pipe(map(this.mapApiResult)).subscribe((data: SocietyGroup[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _loadItems$(): Observable<Pagination<SocietyGroup>> {

        return this._societyGroupService.getPaginationItemsAPI(this.itemsApiParams);
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._loadItems$().pipe(map(this.mapApiResult)).subscribe((data: SocietyGroup[]): void => {

            this.items = data;
        });
    }

    public redirectToUpdate(id: number): void {

        this._router.navigate(['account/society-group/update', id], {queryParams: { origin: 'relationshipList' }});
    }

    public getModel(item: SocietyGroup): SocietyGroup {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('societyGroup.delete.value');

        const content : string = this._translateService.instant('societyGroup.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyGroupService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('societyGroup.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._loadItems$().pipe(map(this.mapApiResult)).subscribe((data: SocietyGroup[]): void => {

                    this.items = data;
                });
            });
        });
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<SocietyGroup>) => SocietyGroup[] {

        return (data: Pagination<SocietyGroup>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
