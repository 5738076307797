import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {Offer} from "@core/shared/models/offer";
import {Tender} from "@core/shared/models/tender";
import {TenderService} from "@core/shared/services/tender.service";
import {TenderOfferCreator} from "@core/shared/models/tender/tender-offer-creator";

@Component({
    selector: 'app-core-tender-refuse-offer',
    templateUrl: './tender-refuse-offer.component.html',
    styleUrls: ['./tender-refuse-offer.component.scss'],
    providers: [
        FormService
    ]
})
export class TenderRefuseOfferComponent implements OnInit {

    @Input() offer: Offer;
    @Input() tender: Tender;
    @Input() tenderOfferCreator: TenderOfferCreator;

    @Output() remove: EventEmitter<void> = new EventEmitter();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _tenderService: TenderService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            refusalReason : ['', [Validators.required, Validators.maxLength(500)]]
        });

        this.formService.submitCallback = (): void => {

            this._tenderService.refuseOfferItemAPI(this.tender.id, this.tenderOfferCreator.id, this.offer.id, this.form.value).subscribe((): void => {

                this.remove.emit();
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}