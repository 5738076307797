import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiConfig, IApiContent} from "./api";

@Injectable()
export class ApiService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * PREFIX URL
     */
    private readonly PREFIX_URL: string = '/cms';

    /**
     * CONFIG URL
     */
    private readonly CONFIG_URL: string = '/config';

    /**
     *
     * @param _http
     */
    public constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }


    /**
     * Retourne l'api prefix
     */
    public getApiUrl(withPrefix: boolean): string {

        if(withPrefix){
            return this.API_URL + this.PREFIX_URL;
        }

        return this.API_URL;
    }

    /**
     * Retourne la configuration nécessaire au chargement d'une page
     *
     * @param {string} context
     * @returns {Observable<Config>}
     */
    public getConfig(context: string): Observable<ApiConfig> {

        return this._http.get<ApiConfig>(`${this.API_URL}${this.PREFIX_URL}${this.CONFIG_URL}`, {
            params: {
                context: context
            }
        });
    }

    /**
     * Retourne le contenu d'une page existante en fonction de son ID
     * La configuration nécessaire au chargement d'une page est également retournée
     *
     * @param {string} contentId
     * @param {string} context
     * @returns {Observable<IApiContent>}
     */
    public getContent(contentId: string, context: string): Observable<IApiContent> {
        return this._http.get<IApiContent>(`${this.API_URL}${this.PREFIX_URL}` + '/load', {
            params: {
                content_id: contentId,
                context: context
            }
        });
    }
}
