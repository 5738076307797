import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Quotation} from "@core/shared/models/quotation";
import {Pagination} from "@core/shared/models/pagination";

@Injectable({
    providedIn: 'root'
})
export class QuotationService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getItemAPI(id: number, params?: string[]): Observable<Quotation> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/quotations/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<Quotation>(url,{
            headers: headers
        });
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Quotation>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/quotations`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Quotation>>(url, {
            headers: headers
        });
    }

    public getPaginationSocietyItemsAPI(societyId: number, params?: string[]): Observable<Pagination<Quotation>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/quotations`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Quotation>>(url, {
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<Quotation> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Quotation>(`${this._apiService.getApiUrl(false, true)}/quotations`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<Quotation> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Quotation>(`${this._apiService.getApiUrl(false, true)}/quotations/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/quotations/${id}`,{
            headers: headers
        });
    }

    public exportItemsAPI(societyId: number): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/quotations/export`, {
            responseType: 'blob'
        });
    }

    public adminExportItemsAPI(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/quotations/export`, {
            responseType: 'blob'
        });
    }
}
