import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";

/**
 * Configuration paramètres du champ
 */
export interface InputTimeConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        label?: string;
        placeholder?: string;
    };
    /**
     * Template bouton annuler
     */
    templatebtnCancel?: TemplateRef<any>;
}

/**
 * Champ input datetime
 */
@Component({
    selector: 'app-input-time',
    templateUrl: './input-time.component.html',
    styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() config: InputTimeConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;
    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {

        if(!this.config.attrs.placeholder){
            this.config.attrs.placeholder = "Heure";
        }

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id + '[time]', new UntypedFormControl(''));

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        this.form.controls[this.config.id+ '[time]'].setValidators(validators);
    }


}
