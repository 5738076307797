import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OfferDateEngineItem} from "@core/shared/models/offer/offer-date/offer-date-engine";
import {FormService} from "@core/shared/services/form.service";
import {Booking} from "@core/shared/models/booking";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BookingService} from "@core/shared/services/booking.service";
import {SocietyService} from "@core/shared/services/society.service";
import {User} from "@core/shared/models/user";
import {Offer} from "@core/shared/models/offer";
import {DATE_FORMAT} from "@app/data";
import {PromotionService} from "@core/shared/services/promotion.service";
import {Promotion} from "@core/shared/models/promotion";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferPermanentOption} from "@core/shared/models/offer/offer-permanent-option";
import {OfferPermanentOptionSelectionService} from "@core/shared/services/offer/offer-option/offer-permanent-option-selection.service";
import {BookingCompositionOption} from "@core/shared/models/booking/booking-composition-option";
import {BookingCompositionPermanentOption} from "@core/shared/models/booking/booking-composition-permanent-option";
import {OfferOptionSelectionService} from "@core/shared/services/offer/offer-option/offer-option-selection.service";
import {Role} from "@core/shared/models/role";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {PromotionSelectionService} from "@core/shared/services/promotion/promotion-selection.service";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {TermsAndCondition, TermsAndConditionTranslation} from "@core/shared/models/terms-and-condition";
import {OfferBookingRequestService} from "@core/shared/services/offer/offer-booking/offer-booking-request.service";
import {BookingCompositionParticipant, OfferStepDateDateCompositionParticipantType} from "@core/shared/models/booking/booking-composition-participant";
import {Observable, of} from "rxjs";
import moment, {Moment} from "moment";
import {BookingFlexibility} from "@core/shared/models/booking/booking-flexibility";
import {BookingFlexibilityService} from "@core/shared/services/booking/booking-flexibility.service";
import {Currency} from "@core/shared/models/currency";
import {CurrencyService} from "@core/shared/services/currency.service";
import {tap} from "rxjs/operators";

type TemplateView = ('backToComposition' | 'summary' | 'request' | 'validation' | 'expiration' | 'confirmation');

@Component({
    selector: 'app-core-offer-booking-request-create',
    templateUrl: './offer-booking-request-create.component.html',
    styleUrls: ['./offer-booking-request-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferBookingRequestCreateComponent implements OnInit, OnChanges {

    @Input() currentUser: User;

    @Input() offer: Offer;

    @Input() compositionItem: OfferDateEngineItem;

    @Input() termsAndConditions: number;

    @Input() hasPermanentOptionsAvailable: boolean;

    @Input() viewLanguage: string;

    @Output() backToCompositionRequested: EventEmitter<void> = new EventEmitter<void>();

    @Output() permanentOptionsSelectionCleared: EventEmitter<void> = new EventEmitter<void>();

    @Output() promotionApplied: EventEmitter<Promotion> = new EventEmitter<Promotion>();

    public locale: string;

    public activeViews: TemplateView[] = ['backToComposition', 'summary', 'request'];

    public booking: Booking = null;

    public bookingFlexibilities$: Observable<BookingFlexibility[]>;

    public currencies$: Observable<Currency[]>;

    public displayPermanentOptions: boolean = false;

    public maxLengthCustomerComment: number = 3000;

    public maxLengthOfferCreatorComment: number = 3000;

    public displayPromotionEmailRequired: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _bookingService: BookingService,
        private _societyService: SocietyService,
        private _translateService: TranslateService,
        private _promotionService: PromotionService,
        private _offerOptionSelectionService: OfferOptionSelectionService,
        private _offerPermanentOptionSelectionService: OfferPermanentOptionSelectionService,
        private _promotionSelectionService: PromotionSelectionService,
        private _offerBookingRequestService: OfferBookingRequestService,
        private _bookingFlexibilityService: BookingFlexibilityService,
        private _currencyService: CurrencyService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this.locale = this._translateService.currentLang;

        this.clearPermanentOptionSelection();

        if(this.compositionItem.type === 'request'){

            this._loadBookingFlexibilities();

            this._loadCurrencies();
        }

        this._initForm();

        this._initEvents();

        this.updateCompositionControl();

        this.handleValidTermsAndConditions(null);
    }

    ngOnChanges(changes: SimpleChanges) {

        if(('hasPermanentOptionsAvailable' in changes) && this.form && this.compositionControl){

            this.form.get('optoutPermanentOptions').updateValueAndValidity();
        }
    }

    private _loadBookingFlexibilities(): void {

        this.bookingFlexibilities$ = this._bookingFlexibilityService.getItemsAPI().pipe(
            tap((items: BookingFlexibility[]): void => {

                this.compositionControl.get('flexibility').patchValue(Boolean(items.length) ? items[0] : null);
            })
        );
    }

    private _loadCurrencies(): void {

        this.currencies$ = this._currencyService.getItemsAPI().pipe(
            tap((items: Currency[]): void => {

                const match: Currency = items.find((item: Currency): boolean => {

                    return item.symbol === '€';
                });

                this.compositionControl.get('currency').patchValue(match || null);
            })
        );
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            type: [this.compositionItem.type, [Validators.required]],
            composition: this._formBuilder.group({
                dateStart: [null, [Validators.required]],
                dateEnd: [null, [Validators.required]],
                nbAdult: [null],
                nbChild: [null],
                publicPrice: [null, [Validators.required]],
                options: [[]],
                permanentOptions: [[]],
                participants: new UntypedFormArray([])
            }),
            comment: [null, [Validators.maxLength(this.maxLengthOfferCreatorComment)]],
            promotionCode: [null],
            isValidPromotion: [false, [Validators.required]],
            promotion: [null],
            sendCustomerEmail: [false],
            optoutPermanentOptions: [false, (control: UntypedFormControl) => {

                if(!this.hasPermanentOptionsAvailable || this.compositionItem.type === 'request'){

                    return null;
                }

                if(this.form && this.compositionControl && Boolean((this.compositionControl.get('permanentOptions').value as []).length)){

                    return null;
                }

                if((control.value as boolean)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]
        });

        if(this.compositionItem.type === 'request'){

            this.form.addControl('referenceResponsibleSociety', this._formBuilder.control(null));

            this.compositionControl.addControl('flexibility', this._formBuilder.control(null, Validators.required));

            this.compositionControl.addControl('currency', this._formBuilder.control(null, Validators.required));

            this.compositionControl.addControl('budget', this._formBuilder.control('', [Validators.required, Validators.pattern(/^[0-9]*$/)]));

            this.compositionControl.addControl('isFlexibleBudget', this._formBuilder.control(false, [Validators.required]));
        }

        if(this.compositionItem.type === 'booking'){

            if(this.compositionItem.availableStock || (this.offer.customerTypology.type === 'I')) {

                this._initParticipantForm();
            }
        }

        if(this.compositionItem.type === 'request'){

            this._initParticipantForm();
        }

        if(this.hasRole('ROLE_OFFER_DISTRIBUTOR') && this.offer.society.id !== this.currentUser.society.id && this.compositionItem.availableStock){

            this.form.addControl('optinPaybackAmount', this._formBuilder.control(false, [Validators.requiredTrue]));
        }

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign({...this.form.value}, {
                offer: {
                    id: this.offer.id
                },
                composition: Object.assign(this.compositionControl.value, {
                    participants: this.participants,
                    budget: this.compositionItem.type === 'request' ? parseFloat(this.compositionControl.get('budget').value) : null,
                }),
                customer: Object.assign(this.customerControl.value, {
                    customerType: this.customerControl.value.customerType ? { id: this.customerControl.value.customerType } : null,
                    society: this.customerControl.value.society ? this.customerControl.value.society : null,
                    phone: this.customerControl.value.phone ? this.customerControl.value.phone.e164Number : null
                }),
                promotion: !!this.form.get('promotion').value ? { id: this.form.get('promotion').value.id } : null,
                sendCustomerEmail: this.form.getRawValue().sendCustomerEmail,
                comment: (this.offer.society.id === this.currentUser.society.id) ? this.form.get('comment').value : null,
                commentResponsibleSociety: (this.offer.society.id !== this.currentUser.society.id) ? this.form.get('comment').value : null
            });

            this._bookingService.createItemAPI(this._societyService.currentUserSociety.getValue().id, data).subscribe((booking: Booking): void => {

                this.booking = booking;

                if(this.compositionItem.availableStock){

                    // Demande de réservation avec achat en ligne
                    // Affichage d'une demande de confirmation

                    this.setActiveViews(['backToComposition', 'summary', 'validation']);
                }
                else{

                    // Demande de réservation sans achat en ligne ou demande de devis
                    // Validation automatique de la réservation

                    this.validateBooking();
                }
            });
        };
    }

    private _initEvents(): void {

        this.form.get('promotionCode').valueChanges.subscribe((): void => {

            this.form.get('isValidPromotion').patchValue(false);

            this.form.get('promotion').patchValue(null);

            this._promotionSelectionService.item$.next(null);

            this.promotionApplied.emit(null);
        });

        this._offerPermanentOptionSelectionService.items$.subscribe((items: OfferPermanentOption[]): void => {

            this.compositionControl.get('permanentOptions').patchValue(items.map((permanentOption: OfferPermanentOption): object => {

                return {
                    permanentOption: {
                        id: permanentOption.id
                    }
                };
            }));
        });

        this.compositionControl.get('permanentOptions').valueChanges.subscribe((): void => {

            this.form.get('optoutPermanentOptions').updateValueAndValidity();
        });

        if(this.compositionItem.type === 'booking') {

            if(this.compositionItem.availableStock || (this.offer.customerTypology.type === 'I')) {

                this.participantsControl.at(0).get('firstName').valueChanges.subscribe((value: string): void => {

                    this.customerControl.get('firstName').patchValue(value);
                });

                this.participantsControl.at(0).get('lastName').valueChanges.subscribe((value: string): void => {

                    this.customerControl.get('lastName').patchValue(value);
                });
            }

            setTimeout((): void => {

                this.customerControl.get('email').valueChanges.subscribe((): void => {

                    this.form.get('isValidPromotion').patchValue(false);

                    this.form.get('promotion').patchValue(null);

                    this.displayPromotionEmailRequired = this.customerControl.get('email').invalid;
                });
            });
        }
    }

    private _initParticipantForm(): void {

        for(let i: number = 0; i < this.compositionItem.nbAdult; i++) {

            this.participantsControl.push(this._createParticipantGroup({
                type: 'adult',
                firstName: null,
                lastName: null,
                birthDay: null
            }, (i + 1)));
        }

        for(let i: number = 0; i < this.compositionItem.nbChild; i++) {

            this.participantsControl.push(this._createParticipantGroup({
                type: 'child',
                firstName: null,
                lastName: null,
                birthDay: null
            }, (i + 1)));
        }
    }

    private _createParticipantGroup(data: BookingCompositionParticipant, rank: number): UntypedFormGroup {

        return this._formBuilder.group({
            type: [data.type, [Validators.required]],
            firstName: [data.firstName, this.compositionItem.type === 'booking' ? [Validators.required] : []],
            lastName: [data.lastName, this.compositionItem.type === 'booking' ? [Validators.required] : []],
            birthDay: [(data.birthDay === null) ? null : moment(data.birthDay), (data.type === 'adult' ? null :  [Validators.required]), [(control: UntypedFormControl) => {

                if (control.value){

                    const currentDate: Moment = control.value as Moment;

                    if (!currentDate.isBetween(this.childDatesOffer.start, this.childDatesOffer.end)){

                        return of({ 'birthdateInvalid': {valid: false} });
                    }
                }

                return of(null);
            }]],
            rank: [rank, [Validators.required]]
        });
    }

    public clearPermanentOptionSelection(): void {

        this._offerPermanentOptionSelectionService.clearItems();

        this.permanentOptionsSelectionCleared.emit();
    }

    public handleBackToCompositionRequest(): void {

        if(this.booking){

            // Annulation de la réservation pour débloquer le stock

            this._bookingService.cancelItemAPI(this._societyService.currentUserSociety.getValue().id, this.booking.id).subscribe((): void => {

                this.booking = null;

                this.clearPermanentOptionSelection();

                this.backToCompositionRequested.emit();
            });
        }
        else{

            this.clearPermanentOptionSelection();

            this.backToCompositionRequested.emit();
        }
    }

    public handleBackToForm(): void {

        this.setActiveViews(['backToComposition', 'request', 'summary']);
    }

    public updateCompositionControl(): void {

        this.compositionControl.patchValue({
            dateStart: this.compositionItem.start.format(DATE_FORMAT),
            dateEnd: this.compositionItem.end.format(DATE_FORMAT),
            nbAdult: this.compositionItem.nbAdult,
            nbChild: this.compositionItem.nbChild,
            publicPrice: this.compositionItem.publicPrice,
            options: this._offerOptionSelectionService.items.map((option: OfferOption): BookingCompositionOption => {

                return {
                    nbAdult: option.nbAdult,
                    nbChild: option.nbChild,
                    day: option.availableByDay ? (option.day || 1) : null,
                    option: option
                };
            }),
            permanentOptions: this._offerPermanentOptionSelectionService.items.map((permanentOption: OfferPermanentOption): BookingCompositionPermanentOption => {

                return {
                    permanentOption: permanentOption
                };
            })
        });

        if((this.offer.customerTypology.type === 'I') && (this.compositionItem.nbChild > 0)){

            this.childParticipantControls.forEach((control: UntypedFormGroup, index: number): void => {

                control.patchValue(Object.assign({...this.compositionItem.participants[index]}, {
                    birthDay: (this.compositionItem.participants[index].birthDay === null) ? null : moment(this.compositionItem.participants[index].birthDay)
                }));
            });
        }
    }

    public validatePromotionCode(): void {

        this.form.get('isValidPromotion').patchValue(false);

        this.form.get('promotion').patchValue(null);

        this.displayPromotionEmailRequired = this.customerControl.get('email').invalid;

        if(this.customerControl.get('email').invalid){

            return;
        }

        const data: object = {
            offer: {
                id: this.offer.id
            },
            composition: this.compositionControl.value,
            email: this.customerControl.get('email').value,
            code: this.form.get('promotionCode').value
        };

        this._promotionService.validateItemAPI(data).subscribe((promotion: Promotion): void => {

            this.form.get('isValidPromotion').patchValue(true);

            this.form.get('promotion').patchValue(promotion);

            this._promotionSelectionService.item$.next(promotion);

            this.promotionApplied.emit(promotion);
        });
    }

    public isActiveView(view: TemplateView): boolean{

        return this.activeViews.includes(view);
    }

    public setActiveViews(activeViews: TemplateView[]): void {

        this.activeViews = activeViews;
    }

    public hasRole(role: Role): boolean {

        return this.currentUser.roles.includes(role);
    }

    public validateBooking(): void {

        this._bookingService.validateItemAPI(this._societyService.currentUserSociety.getValue().id, this.booking.id).subscribe((): void => {

            this.booking = null;

            this.setActiveViews(['backToComposition', 'confirmation']);

        }, () => {

            this.setActiveViews(['expiration']);
        });
    }

    public cancelBooking(): void {

        this._bookingService.cancelItemAPI(this._societyService.currentUserSociety.getValue().id, this.booking.id).subscribe((): void => {

            this.booking = null;

            this.setActiveViews(['backToComposition', 'request', 'summary']);
        });
    }

    public handleCustomerLocaleUpdate(locale: string): void {

        // Documents CGV

        this.handleValidTermsAndConditions(locale);

        // Options assurances

        this.clearPermanentOptionSelection();

        this._offerBookingRequestService.localeUpdated$.next(locale);
    }

    public handleValidTermsAndConditions(locale: string): void {

        if(!this.hasValidSelfTermAndConditions(locale)) {

            this.form.get('sendCustomerEmail').patchValue(false);

            this.form.get('sendCustomerEmail').disable();

        } else {

            this.form.get('sendCustomerEmail').enable();
        }

        this.form.get('sendCustomerEmail').updateValueAndValidity();
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get compositionControl(): UntypedFormGroup {

        return this.form.get('composition') as UntypedFormGroup;
    }

    get participantsControl(): UntypedFormArray {

        return this.compositionControl.get('participants') as UntypedFormArray;
    }

    get adultParticipantControls(): UntypedFormGroup[] {

        return (this.participantsControl.controls as UntypedFormGroup[]).filter((control: UntypedFormGroup): boolean => {

            return control.get('type').value === 'adult';
        });
    }

    get childParticipantControls(): UntypedFormGroup[] {

        return (this.participantsControl.controls as UntypedFormGroup[]).filter((control: UntypedFormGroup): boolean => {

            return control.get('type').value === 'child';
        });
    }

    get customerControl(): UntypedFormGroup {

        if(!this.form) {

            return null;
        }

        return this.form.get('customer') as UntypedFormGroup;
    }

    get termsAndConditionsFile(): number {

        return this.termsAndConditions;
    }

    get submitLabel(): string {

        if(this.compositionItem.type === 'request'){

            return 'offer.request.form.submit';
        }

        return this.compositionItem.availableStock ? 'offer.onlineSale.form.submit' : 'offer.booking.form.submit';
    }

    public hasValidSelfTermAndConditions(locale: string): boolean {

        const typology: CustomerTypology = this.offer.customerTypology;

        return this.currentUser.society.termsAndConditions.some((termsAndCondition: TermsAndCondition): boolean => {

            const hasLocale: boolean = locale !== null ? termsAndCondition.translations.some((translation: TermsAndConditionTranslation): boolean => {

                return translation.locale === locale;

            }) : true;

            return termsAndCondition.typology.id === typology.id && hasLocale;
        });
    }

    get childDatesOffer(): {start: Moment, end: Moment} {

        return {
            start: moment().subtract(this.offer.maxChildrenAge, 'year'),
            end: moment().subtract(this.offer.minChildrenAge, 'year')
        };
    }

    get participants(): BookingCompositionParticipant[] {

        const items: BookingCompositionParticipant[] = (this.participantsControl.value as object[]).map((item: { type: OfferStepDateDateCompositionParticipantType, birthDay: Moment, firstName: string, lastName: string }): BookingCompositionParticipant => {

            return Object.assign({...item}, {
                birthDay: (item.birthDay === null) ? null : item.birthDay.format('YYYY-MM-DD'),
                firstName: (item.firstName && item.firstName.length > 0) ? item.firstName : null,
                lastName: (item.lastName && item.lastName.length > 0) ? item.lastName : null
            });
        });

        return this.compositionItem.type === 'booking' ? items : items.filter((item: BookingCompositionParticipant): boolean => {

            return item.type === 'child';
        });
    }
}
