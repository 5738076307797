import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {ApiService} from "@core/shared/services/api.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private _apiService: ApiService,
        private _authenticationService: AuthenticationService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        const isApiUrl = request.url.startsWith(this._apiService.getApiUrl(false, true));

        if (this._authenticationService.isAuthenticated && isApiUrl) {

            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this._authenticationService.token}` }
            });
        }

        return next.handle(request);
    }
}
