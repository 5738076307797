import {
    AfterContentInit, AfterViewChecked, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FieldService} from "../field";
import {UntypedFormGroup} from "@angular/forms";
import {Field} from "../fields/input";
import {FormConfig} from "../form";

/**
 * Gestion des formulaires
 */
@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterContentInit, AfterViewChecked {

    /**
     * Champs de formulaire
     */
    @Input() public fields: Field[];

    @Input() public enableList: boolean;

    /**
     * Formulaire
     */
    @Input() public form: UntypedFormGroup;


    /**
     * Configuration du formulaire
     */
    @Input() public config: FormConfig;

    /**
     * Evenement au moment de l'envois du formulaire
     */
    @Output() public formSubmit = new EventEmitter();

    /**
     * Content
     */
    @ViewChild('content', {read: ElementRef, static: true}) content: ElementRef;

    /**
     * Surcharge
     */
    public overloaded: boolean = false;

    /**
     * Constructeur
     *
     * @constructor
     *
     * @param _changeDetectorRef
     * @param fieldService
     */
    public constructor(private _changeDetectorRef: ChangeDetectorRef, public fieldService: FieldService) {
    }

    /**
     * @ignore
     */
    public ngOnInit(): void {

        if((typeof this.enableList === 'undefined')){
            this.enableList = true;
        }


        if((typeof this.config === 'undefined')){

            this.config = {
                save: {
                    top: true,
                    bottom: false
                }
            };
        }
    }

    public saveAndContinue():void{
        this.formSubmit.emit({'form' :this.form, 'saveAndContinue':  true});

    }

    /**
     * ngAfterContentInit
     * Affiche le contenu
     */
    public ngAfterContentInit(): void {

        this.overloaded = !!this.content.nativeElement.innerHTML;

    }

    public ngAfterViewChecked(): void {

        this._changeDetectorRef.detectChanges();

        this._stickButton();

    }

    private _stickButton(): void {

        const contentScroll : HTMLElement = document.querySelector('mat-card');
        const stickyContent : HTMLElement = document.querySelector('mat-card-actions.is-sticky');
        const stickyButton : HTMLElement = document.querySelector('.actions_sticky');

        contentScroll.onscroll = (): void => {

            if (contentScroll.scrollTop >= stickyContent.offsetTop - 20){
                stickyButton.classList.add('on');
            }else{
                stickyButton.classList.remove('on');
            }

        }

    }
}
