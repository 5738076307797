import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Menu} from "../models/menu";
import {OfferNetworkProviderService} from "../services/offer/offer-network-provider.service";

@Injectable({
    providedIn: 'root'
})
export class PageOfferNetworkProviderItemsResolver  {

    constructor(
        private _offerNetworkProviderService: OfferNetworkProviderService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._offerNetworkProviderService.getItemsAPI();
    }
}
