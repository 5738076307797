import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-core-offer-creator-no-reply-reason-dialog',
  templateUrl: './offer-creator-no-reply-reason-dialog.component.html',
  styleUrls: ['./offer-creator-no-reply-reason-dialog.component.scss']
})
export class OfferCreatorNoReplyReasonDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<OfferCreatorNoReplyReasonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { noReplyReason: string }
    ) {}
}
