import {Exclude, Expose, Transform, Type} from "class-transformer";
import {TranslationItem} from "../translation";
import {Currency} from "@core/shared/models/currency";
import {OfferPermanentOptionPublicType} from "@core/shared/models/offer/offer-permanent-option/offer-permanent-option-public";
import {OfferPermanentOptionPriceLevel} from "@core/shared/models/offer/offer-permanent-option/offer-permanent-option-price-level";
import {File} from "@lib/media/file/file";
import {Offer} from "@core/shared/models/offer";
import {parsePrice} from "@core/shared/utils/price";
import {TranslationPictureType} from "@core/shared/models/translation/translation-picture";
import {PriceArea, PriceType, PricingType} from "@core/shared/models/price";
import {Image} from "@lib/media/image/image";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";

export type OfferPermanentOptionPriceLevelType = 'amount' | 'person';

@Exclude()
export class OfferPermanentOption {

    @Expose()
    id: number;

    @Expose()
    locales: string[];

    @Expose()
    publics: OfferPermanentOptionPublicType[];

    @Expose()
    comment: string;

    @Expose()
    reference: string;

    @Expose()
    translationPictureType: TranslationPictureType;

    @Expose()
    @Type(() => OfferPermanentOptionPrice)
    price: OfferPermanentOptionPrice;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    startFromPriceHT: number;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    startFromPriceTTC: number;

    @Expose()
    vatPercent: number;

    @Expose()
    @Type(() => OfferPermanentOptionPriceLevel)
    priceLevels: OfferPermanentOptionPriceLevel[];

    @Expose()
    translations: OfferPermanentOptionTranslation[];

    @Expose()
    startFromPrice: number;

    @Expose()
    currency: Currency;

    @Expose()
    @Type(() => OfferPermanentOptionOffer)
    offers: OfferPermanentOptionOffer[];

    @Expose()
    @Type(() => OfferPermanentOptionOfferCatalog)
    offerCatalogs: OfferPermanentOptionOfferCatalog[]

    @Expose()
    totalOffers: number;

    @Expose()
    providerName: string;

    get parsedStartFromPriceTTC(): string {

        return parsePrice(this.startFromPriceTTC) + this.currency.symbol;
    }
}

export interface OfferPermanentOptionTranslation extends TranslationItem {

    id: number;

    title: string;

    description: string;

    picture: OfferPermanentOptionTranslationPicture;

    termsAndConditions: File;

    additionalDocument?: File;
}

export interface OfferPermanentOptionTranslationPicture {

    id: number;

    image: Image;
}

export class OfferPermanentOptionPrice {

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    priceHT: string;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toPlainOnly: true})
    priceTTC: string;

    @Expose()
    @Transform(({value}) => (value !== null) ? (value as number) * 100 : null, {toClassOnly: true})
    @Transform(({value}) => (value !== null) ? (value as number) / 100 : null, {toPlainOnly: true})
    percent: string;

    @Expose()
    priceArea: PriceArea;

    @Expose()
    pricingType: PricingType;

    @Expose()
    priceType: PriceType;

    @Expose()
    priceLevelType: OfferPermanentOptionPriceLevelType;

    public isPriceType(value: PriceType): boolean {

        return this.priceType === value;
    }

    public isPriceLevelType(value: OfferPermanentOptionPriceLevelType): boolean {

        return this.priceLevelType === value;
    }
}

@Exclude()
export class OfferPermanentOptionOffer {

    @Expose()
    @Type(() => Offer)
    offer: Offer;

    @Expose()
    offerPermanentOption: OfferPermanentOption;
}

@Exclude()
export class OfferPermanentOptionOfferCatalog {

    @Expose()
    offerCatalog: OfferCatalog;

    @Expose()
    offerPermanentOption: OfferPermanentOption;
}
