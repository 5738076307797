import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import * as moment_ from 'moment';
import { merge } from 'lodash';
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

const moment = moment_;

/**
 * Configuration des errors de validations
 */
export interface ValidatorFn {

    /**
     * Erreurs retournées par la validation du formulaire
     *
     * @param control Control de champs
     */
    (control: AbstractControl): ValidationErrors | null
}

/**
 * Configuration paramètres du champ
 */
export interface InputDaterangeConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs?: {
        required?: boolean;
        label?: string;
        endRequired?: boolean;
        placeholderStart?: string;
        placeholderEnd?: string;
        output?: string;

        /**
         * Détermine si le contenu des champs de saisie est bloqué pour l'utilisateur
         */
        readonly?: boolean;
    };

    /**
     * Autorise la réinitialisation des champs via un bouton
     */
    enableReset?: boolean;
    resetLabel?: string;
}

/**
 * Configuration par défaut du champ
 */
export const DEFAULT_CONFIG: InputDaterangeConfig = {
    id: null,
    attrs: {
        required: false,
        label: null,
        endRequired: false,
        placeholderStart: 'Date de début',
        placeholderEnd: 'Date de fin',
        output: null,
        readonly: false
    },
    enableReset: false,
    resetLabel: 'Réinitialiser les dates'
};

/**
 * Champ input daterange
 */
@Component({
    selector: 'app-input-daterange',
    templateUrl: './input-daterange.component.html',
    styleUrls: ['./input-daterange.component.scss']
})
export class InputDaterangeComponent implements OnInit {

    /**
     * Erreur
     */
    @Input() error: any = {isError: false, errorMessage: ''};

    /**
     * Paramètres du champ
     */
    @Input() config: InputDaterangeConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    /**
     * Evenement si la valeur change
     */
    @Output() valueChange =  new EventEmitter();

    @ViewChild('start', { static: false }) startReference: ElementRef;

    @ViewChild('end', { static: false }) endReference: ElementRef;

    /**
     * Constructeur
     *
     * @constructor
     */
    public constructor() {
    }

    public ngOnInit(): void {

        // Initialisation de la configuration

        this.config = this.config ? merge(DEFAULT_CONFIG, this.config) : DEFAULT_CONFIG;

        // Construction des champs du formulaire

        this.form.addControl(this.config.id + 'DateStart', new UntypedFormControl(''));

        this.form.addControl(this.config.id + 'DateEnd', new UntypedFormControl(''));

        // Validation des champs du formulaire

        const validatorsStart: ValidatorFn[] = [];

        const validatorsEnd: ValidatorFn[] = [];

        validatorsStart.push(this.dateRangeValidator(this.config.id));

        validatorsEnd.push(this.dateRangeValidator(this.config.id));

        if (this.config.attrs.required) {

            validatorsStart.push(Validators.required);
        }

        if (this.config.attrs.endRequired) {

            validatorsEnd.push(Validators.required);
        }

        this.form.controls[this.config.id + 'DateStart'].setValidators(validatorsStart);

        this.form.controls[this.config.id + 'DateEnd'].setValidators(validatorsEnd);
    }

    /**
     * Comparaison de deux date
     * @param id
     */
    public compareTwoDates(id: string): any {

        if (this.form.controls[id + 'DateEnd'].value) {
            if (new Date(this.form.controls[id + 'DateEnd'].value) < new Date(this.form.controls[id + 'DateStart'].value)) {
                return {'daterange': {value: false}};
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Validateur custom : date de début antérieure à la date de fin
     * @param id
     */
    public dateRangeValidator = (id: string): ValidatorFn => {

        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.compareTwoDates(id);
        };
    };

    /**
     * Mise à jour d'une date
     *
     * @param id
     * @param type
     * @param event
     */
    public updateDateRange(id: string, type: string, event: MatDatepickerInputEvent<string>): void {

        if (type == 'start') {

            this.form.controls[id + 'DateEnd'].updateValueAndValidity({onlySelf: false, emitEvent: true});

            this.form.value[id + 'DateStart'] = moment(event.value).format('YYYY-MM-DD HH:mm:ss');
        }

        if (type == 'end') {

            this.form.controls[id + 'DateStart'].updateValueAndValidity({onlySelf: false, emitEvent: true});

            this.form.value[id + 'DateEnd'] = moment(event.value).format('YYYY-MM-DD HH:mm:ss');
        }

        this.valueChange.emit(event);
    }

    /**
     * Réinitialisation des champs
     */
    reset(): void {

        const controls: AbstractControl[] = [
            this.form.get(this.config.id + 'DateStart'),
            this.form.get(this.config.id + 'DateEnd')
        ];

        controls.forEach((control: AbstractControl): void => {

            control.reset();
        });

        const references: ElementRef[] = [
            this.startReference,
            this.endReference
        ];

        references.forEach((reference: ElementRef): void => {

            this.valueChange.emit({
                value: null,
                targetElement: reference.nativeElement
            });
        });
    }
}
