import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {OfferOptionPeriod} from "../../../models/offer/offer-option/offer-option-period";

@Injectable({
    providedIn: 'root'
})
export class OfferOptionPeriodService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public createItemAPI(id: number, data: object): Observable<OfferOptionPeriod> {

        const url = `${this._apiService.getApiUrl(false, true)}/offerOptions/${id}/periods`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferOptionPeriod>(url, data, {
            headers: headers
        });
    }
}
