import {Component, Input} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {environment} from "../../../../../../environments/environment";

@Component({
    selector: 'app-cms-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss']
})
export class CmsFileComponent {

    /**
     * Valeur du champ texte
     */
    @Input() file: any;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor(private _apiService: ApiService) {
    }

    public getFileUrl(path): string {

        return `${environment.apiUrl}/${path}`;
    }
}
