<div class="page_service_subscription">

    <div class="back_to_services">
        <span class="ty-chevron" (click)="handleBackToServices()">{{ 'services.list.item.action.service.back.value' | translate }}</span>
    </div>

    <h1 class="title">{{ 'services.form.gift_voucher.value' | translate }}</h1>

    <form [formGroup]="form" (ngSubmit)="formService.submit()" >

        <ng-container *ngTemplateOutlet="dataInvoice"></ng-container>

        <ng-container *ngTemplateOutlet="societyData"></ng-container>

        <ng-container *ngTemplateOutlet="mentionData"></ng-container>

        <ng-container *ngTemplateOutlet="termsAndConditions"></ng-container>

        <div class="row ligne_form">
            <div class="col_content">
                <div class="form-group">
                    <button type="submit" class="btn_cta btn_little btn_validate_paiement" [disabled]="form.invalid || submitLoading">{{ 'subscription.actions.validatePayment.value' | translate }}</button>
                </div>
            </div>
        </div>

    </form>

</div>


<ng-template #dataInvoice [formGroup]="form" >

    <div class="row marges ligne_form">

        <div class="col_12">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'services.form.gift_voucher.invoice.value' | translate }}</label>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="enableCustomInvoiceLegalInformation" class="form-control" id="enableCustomInvoiceLegalInformationNo">
                            <label for="enableCustomInvoiceLegalInformationNo">{{ 'services.form.gift_voucher.invoice.society.value' | translate }}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="enableCustomInvoiceLegalInformation" class="form-control" id="enableCustomInvoiceLegalInformationYes">
                            <label for="enableCustomInvoiceLegalInformationYes">{{ 'services.form.gift_voucher.invoice.customInvoiceInformation.value' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</ng-template>

<ng-template #mentionData [formGroup]="form">

    <ng-container  *ngIf="form.get('enableCustomInvoiceLegalInformation').value">

        <div class="row marges ligne_form">
            <div class="col_12 col_md_6">

                <!-- Champs libre -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="customInvoiceLegalInformation" id="customInvoiceLegalInformation" [required]="form.get('enableCustomInvoiceLegalInformation').value">
                    <label for="customInvoiceLegalInformation">{{ 'form.user.fields.customInvoiceLegalInformation.value' | translate }}</label>
                    <mat-error
                        *ngIf="form.get('customInvoiceLegalInformation').errors && (form.get('customInvoiceLegalInformation').dirty || form.get('customInvoiceLegalInformation').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('customInvoiceLegalInformation').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('customInvoiceLegalInformation').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                    <span class="message-help">
                        <mat-icon class="tooltip_icon">info</mat-icon>
                        {{ 'services.form.gift_voucher.add.customInvoiceLegalInformation.value' | translate }}
                    </span>
                </div>
            </div>
        </div>

    </ng-container>


</ng-template>

<ng-template #societyData [formGroup]="societyForm">

    <ng-container  *ngIf="!form.get('enableCustomInvoiceLegalInformation').value">

        <div class="row marges ligne_form">
            <div class="col_12 col_md_6">

                <!-- Nom société -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="nameSociety">
                    <label for="nameSociety">{{'form.user.fields.name.value' | translate}}</label>
                    <mat-error
                        *ngIf="societyForm.get('name').errors && (societyForm.get('name').dirty || societyForm.get('name').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="societyForm.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Numéro de TVA -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="vatNumber" id="vatNumber">
                    <label for="vatNumber">{{'form.user.fields.vatNumber.value' | translate}}</label>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12 col_md_6">

                <!-- Siren -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="identificationNumber" id="siren" required="required">
                    <label for="siren">{{'form.user.fields.siren.value' | translate}}</label>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Immatriculation -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="registration" id="immatriculation">
                    <label for="immatriculation">{{'form.user.fields.immatriculation.value' | translate}}</label>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form" >

            <div class="col_12 col_md_6">

                <!-- Raison sociale -->
                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="corporateName" id="corporateName" required="required">
                    <label for="corporateName">{{'form.user.fields.corporateName.value' | translate}}</label>
                </div>

            </div>

            <div class="col_12 col_md_6">

                <!-- Code APE -->
                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="businessActivityCode" id="businessActivityCode">
                    <label for="businessActivityCode">{{'form.user.fields.businessActivityCode.value' | translate}}</label>
                </div>

            </div>

        </div>

        <div class="row marges ligne_form" >

            <div class="col_12 col_md_6">

                <!-- immatriculation RCS  -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="rcsRegistration" id="rcsRegistration">
                    <label for="rcsRegistration">{{'form.user.fields.rcsRegistration.value' | translate}}</label>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Capital -->
                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="capital" id="capital">
                    <label for="capital">{{'form.user.fields.capital.value' | translate}}</label>
                </div>

            </div>

        </div>

        <div class="row marges ligne_form">

            <ng-container [formGroup]="addressesForm">
                <div class="col_12">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                {{ 'form.user.fields.address.billing.value' | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">

                            <!-- Adresse -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                                <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                            </div>
                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Complément d'adresse -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                                <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row marges ligne_form">
                        <div class="col_12 col_md_6">

                            <!-- Code postal -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode">
                                <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                            </div>
                        </div>
                        <div class="col_12 col_md_6">

                            <!-- Ville -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city">
                                <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6">

                            <!-- Région -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region" required="required">
                                <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                                <mat-error
                                        *ngIf="addressesForm.get('region').invalid && (addressesForm.get('region').dirty || addressesForm.get('region').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="addressesForm.get('region').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_12 col_md_6">

                            <!-- Pays -->

                            <div class="form-group">
                                <select class="form-control" id="country" formControlName="country" required="required">
                                    <option *ngFor="let country of countries$ | async"
                                            [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                                </select>
                                <label class="required" for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

        </div>


    </ng-container>

</ng-template>


<ng-template #termsAndConditions [formGroup]="form">

    <!--    Mes CGV intègrent la vente de bons cadeaux-->

    <div class="ligne_form">
        <div class="form-group">
            <div class="checkbox">
                <input type="checkbox" id="societyTermsAndConditionsIncludeOnlineSale" formControlName="societyTermsAndConditionsIncludeOnlineSale">
                <label for="societyTermsAndConditionsIncludeOnlineSale">{{'societyTermsAndConditionsIncludeOnlineSale.success.action.description.value' | translate}} </label>
                <mat-error *ngIf="form.get('societyTermsAndConditionsIncludeOnlineSale').errors && (form.get('societyTermsAndConditionsIncludeOnlineSale').dirty || form.get('societyTermsAndConditionsIncludeOnlineSale').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('societyTermsAndConditionsIncludeOnlineSale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
    </div>

</ng-template>
