<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.attribute.title.modification.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'offer.attribute.title.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div formGroupName="type" class="form-group">

                <!-- Caractéristiques -->

                <select class="form-control" id="characteristic" formControlName="id" required="required">
                    <option *ngFor="let characteristic of characteristics$ | async"
                            [value]="characteristic.id">{{translationService.getFallbackTranslation(characteristic.translations).label }}</option>
                </select>
                <label class="required"
                       for="characteristic">{{ 'offer.attribute.grid.features.value' | translate }}</label>
                <mat-error
                    *ngIf="typeForm.controls['id'].invalid && (typeForm.controls['id'].dirty || typeForm.controls['id'].touched)">
                    <div class="invalid-feedback">
                        <div
                            *ngIf="typeForm.controls['id'].errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form" *ngIf="translationBuilder.itemsControl.length">

        <div class="col_12">
            <div class="mat-tab-translations">
                <ng-container formArrayName="translations">
                    <mat-tab-group [@.disabled]="true">
                        <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">
                            <mat-tab label="{{translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">
                                <ng-container [formGroupName]="translationBuilder.indexAsString(it)">
                                    <div class="row marges ligne_form">
                                        <div class="col_12">

                                            <!-- Nom -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="label" id="{{it}}-label" required="required">
                                                <label for="{{it}}-label">{{ 'offer.form.fields.attributeName.value' | translate }}</label>
                                                <mat-error *ngIf="translationBuilder.itemsControl.at(it).get('label').errors && (translationBuilder.itemsControl.at(it).get('label').dirty || translationBuilder.itemsControl.at(it).get('label').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="translationBuilder.itemsControl.at(it).get('label').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            </mat-tab>
                        </div>
                    </mat-tab-group>

                </ng-container>
            </div>
        </div>
    </div>

</form>
