<full-calendar [options]="calendarOptions" [deepChangeDetection]="true"></full-calendar>

<div class="calendar_legend">
    <div class="legend available">
        <span>{{ 'offer.period.availability.available.value' | translate }}</span>
    </div>
    <div class="legend closed">
        <span>{{ 'offer.period.availability.closed.value' | translate }}</span>
    </div>
    <div class="legend excluded">
        <span>{{ 'offer.period.availability.excluded.value' | translate }}</span>
    </div>
</div>
