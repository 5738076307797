import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Service} from "@core/shared/models/service";
import {ServiceService} from "@core/shared/services/service.service";

@Injectable({
    providedIn: 'root'
})
export class ServiceItemsResolver  {

    constructor(
        private _serviceService: ServiceService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Service[]> {

        return this._serviceService.getItemsAPI();
    }
}
