<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'networkProvider.edit.action.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">
                <!-- Retour -->
                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'offerGroupNetworkProvider.creation.origin.relationshipList.value' | translate }}
                </button>

                <!-- Soumission -->
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <mat-tab-group>
        <mat-tab [label]="'networkProvider.data.generic.value' | translate">
            <ng-container *ngTemplateOutlet="genericData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'networkProvider.data.restricted.value' | translate">
            <ng-container *ngTemplateOutlet="restrictedData"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<ng-template #genericData>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="label"
                           id="label" required="required">
                    <label for="label">{{'networkProvider.list.fields.label.value' | translate}}</label>
                    <mat-error
                            *ngIf="form.get('label').errors && (form.get('label').dirty || form.get('label').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('titre').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <textarea class="form-control" formControlName="comment"
                              id="comment" required="required"></textarea>
                    <label for="comment">{{'networkProvider.list.fields.comment.value' | translate}}</label>
                    <mat-error
                            *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('titre').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
        <!-- Intérêts -->
        <div class="ligne_form row marges">
            <div class="col_12">
                <div class="form-group">
                    <label>{{ 'networkProvider.list.fields.network.value' | translate }}</label>
                </div>
                <app-offer-network-provider-create [parentNetworkProvider]="form" [channels]="channels" [providers]="providers" [groupProvider]="networkGroup"></app-offer-network-provider-create>
            </div>
        </div>

    </ng-container>

</ng-template>

<ng-template #restrictedData>
    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">
                <p class="read">
                    <strong class="subtitle_green">{{ 'networkProvider.data.restricted.group.value' | translate }}</strong>
                </p>

            </div>
        </div>
    </div>

    <div class="ligne_form row marges">

        <div class="col_6">

            <div class="form-group">
                <div class="read">

                    <div class="txt_offer">

                        <p *ngFor="let society of networkGroup.restrictedSocieties">- {{society.name}}</p>

                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">
                <p class="read">
                    <strong class="subtitle_green">{{ 'networkProvider.data.restricted.network.value' | translate }}</strong>
                </p>

            </div>
        </div>
    </div>

    <div class="ligne_form row marges">

        <div class="col_6">

            <div class="form-group">
                <div class="read">

                    <mat-accordion multi>

                        <mat-expansion-panel *ngFor="let networkProvider of networkProviders">

                            <mat-expansion-panel-header>

                                <mat-panel-title>{{networkProvider.name}} ({{networkProvider.restrictedSocieties.length}})</mat-panel-title>

                            </mat-expansion-panel-header>

                            <div class="txt_offer">

                                <p *ngFor="let society of networkProvider.restrictedSocieties">- {{society.name}}</p>

                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>

                </div>

            </div>
        </div>
    </div>
</ng-template>
