import {Exclude, Expose, Transform} from "class-transformer";
import {Currency} from "@core/shared/models/currency";
import {DATE_FORMAT} from "@app/data";
import * as moment from "moment";
import {Moment} from "moment";

export type OfferAvailabilityStatusType = 'available' | 'on-request' | 'closed';

export type OfferAvailabilityTypeType = 'HT' | 'TTC';

@Exclude()
export class OfferAvailability {

    @Expose()
    @Transform(({value}) => value ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as number) * 100 : null, {toPlainOnly: true})
    price: number;

    @Expose()
    @Transform(({value}) => value ? (value as number) / 100 : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as number) * 100 : null, {toPlainOnly: true})
    promotionPrice: number;

    @Expose()
    type: OfferAvailabilityTypeType;

    @Expose()
    status: OfferAvailabilityStatusType;

    @Expose()
    currency: Currency;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATE_FORMAT).startOf('day') : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATE_FORMAT) : null, {toPlainOnly: true})
    date: Moment;

    @Expose()
    availableStock: boolean;

    get isAvailable(): boolean {

        return this.status === 'available';
    }

    get isOnRequest(): boolean {

        return this.status === 'on-request';
    }

    get isClosed(): boolean {

        return this.status === 'closed';
    }

    get priceTypeLabel(): string {

        if(this.type === 'TTC') {

            return 'offer.price.type.ttc.value';
        }

        if(this.type === 'HT') {

            return 'offer.price.type.ht.value';
        }

        return null;
    }
}
