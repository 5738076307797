import {Injectable, OnInit} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "@core/shared/services/user.service";
import {map} from "rxjs/operators";
import {ServiceService} from "@core/shared/services/service.service";
import {GIFT_VOUCHER_SERVICE_LABEL, GIFT_VOUCHER_URL_ORIGIN, Service} from "@core/shared/models/service";


@Injectable({
    providedIn: 'root'
})
export class ServiceGuard {

    constructor(
        private _router: Router,
        private _userService: UserService,
        private _serviceService: ServiceService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this._serviceService.getItemAPI(route.params['serviceId']).pipe(
            map(service => this._canActivate(service, route, state))
        )
    }

    public _canActivate(service, route: ActivatedRouteSnapshot, state) {

        if(!service) {

            return false;
        }

        if (service.tag === GIFT_VOUCHER_SERVICE_LABEL) {

            // Accès au service Bon cadeau sans passer par l'étape intermédiaire
            if((route.queryParamMap.get('origin') !== GIFT_VOUCHER_URL_ORIGIN )){

                this._router.navigate(['account/service/subscription/gift-voucher', service.id]);

                return false;
            }

            return true;
        }

        // Accès au service depuis autre part que la bibliothèque de service
        return route.queryParamMap.get('origin') === 'list';
    }

}
