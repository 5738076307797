import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService {

    constructor(
        private _apiService: ApiService
    ) {
    }

    public getItemAPIUrl(locale: string): string {

        return `${this._apiService.getApiUrl(false, true)}/public/${locale}/termsAndConditions`;
    }
}
