<h1 mat-dialog-title class="">{{ 'tender.refuseOffer.value' | translate }}</h1>
<mat-dialog-content>
    <app-core-tender-refuse-offer [tender]="tender" [tenderOfferCreator]="tenderOfferCreator" [offer]="offer" (remove)="remove.emit($event)"></app-core-tender-refuse-offer>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row marges x_center">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" [disabled]="submitDisabled" (click)="submit()">{{ 'submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>