<div [formGroup]="form" class="form-group">
    <mat-form-field>
        <input matInput appInput type="number" [readonly]="readOnly" [(ngModel)]="value" [attrs]="config.attrs" [formControlName]="config.id" [max]="(config.attrs.max !== null) ? config.attrs.max : ''" [min]="(config.attrs.min !== null) ? config.attrs.min : ''">
    </mat-form-field>
    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>

            <div *ngIf="form.controls[config.id].errors.min" [translate]="'form.validation.error.min'" [translateParams]="{value: config.attrs.min}"></div>
            <div *ngIf="form.controls[config.id].errors.max" [translate]="'form.validation.error.max'" [translateParams]="{value: config.attrs.max}"></div>
        </div>
    </mat-error>
</div>
