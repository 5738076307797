import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";

/**
 * Configuration paramètres du champ
 */
export interface TextareaConfig extends InputConfig {
    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        placeholder?: string;
        minlength?: number;
        maxlength?: number;
        help?: string;
        disabled?: boolean;
    };
}

/**
 * Champ textarea
 */
@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() config: TextareaConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    ngOnInit() {
        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl(''));

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        if ('minlength' in this.config.attrs) {
            validators.push(Validators.minLength(this.config.attrs.minlength));
        }

        if ('maxlength' in this.config.attrs) {
            validators.push(Validators.maxLength(this.config.attrs.maxlength));
        }

        if('disabled' in this.config.attrs && this.config.attrs.disabled){
            this.form.get(this.config.id).disable();
        }

        this.form.controls[this.config.id].setValidators(validators);
    }

}
