<ng-template [ngIf]="isAuthenticated" [ngIfThen]="isAuthenticatedTemplate" [ngIfElse]="isNotAuthenticatedTemplate">

    <!-- Affichage du template en fonction de l'authentification de l'internaute -->

</ng-template>

<!-- Internaute authentifié -->

<ng-template #isAuthenticatedTemplate>

    <ng-container *ngIf="displayCurrentUserRoles">

        <div class="current_user_roles">
            {{ parsedCurrentUserRoles }}
        </div>

    </ng-container>

    <!-- Header -->

    <app-core-header></app-core-header>

    <mat-drawer-container>

        <mat-drawer mode="side" opened>

            <!-- Navigation -->

            <app-navigation-sidenav [items]="navigationItems" [ngClass]="displayPoweredBy ? 'has_padding' : ''"></app-navigation-sidenav>

        </mat-drawer>

        <mat-drawer-content id="admin-sidenav-content">

            <!-- Layout -->

            <main class="layout">
                <router-outlet></router-outlet>
            </main>

        </mat-drawer-content>

    </mat-drawer-container>

</ng-template>

<!-- Internaute non authentifié -->

<ng-template #isNotAuthenticatedTemplate>

    <!-- Layout -->

    <main class="layout">
        <router-outlet></router-outlet>
    </main>

</ng-template>

<!-- Loader pour les chargements asynchrones -->

<app-core-loader></app-core-loader>
