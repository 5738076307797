import {UntypedFormArray, UntypedFormGroup} from "@angular/forms";
import {OfferPermanentOptionPrice, OfferPermanentOptionPriceLevelType} from "@core/shared/models/offer/offer-permanent-option";
import {Exclude, Expose} from "class-transformer";

@Exclude()
export class OfferPermanentOptionPriceLevel {

    @Expose()
    id: number;

    @Expose()
    min: number;

    @Expose()
    max: number;

    @Expose()
    value: number;

    @Expose()
    additionalValue: number;

    public getFormValueMin(priceConfiguration: OfferPermanentOptionPrice): number {

        if(this.min === null){

            return null;
        }

        return (priceConfiguration.isPriceLevelType('amount')) ? (this.min / 100) : this.min;
    }

    public getFormValueMax(priceConfiguration: OfferPermanentOptionPrice): number {

        if(this.max === null){

            return null;
        }

        return (priceConfiguration.isPriceLevelType('amount')) ? (this.max / 100) : this.max;
    }

    public getFormValueValue(priceConfiguration: OfferPermanentOptionPrice): number {

        if(this.value === null){

            return null;
        }

        return priceConfiguration.isPriceType('amount') ? (this.value / 100) : (this.value * 100);
    }

    public getFormValueAdditionalValue(priceConfiguration: OfferPermanentOptionPrice): number {

        if(this.additionalValue === null){

            return null;
        }

        return priceConfiguration.isPriceType('amount') ? (this.additionalValue / 100) : (this.additionalValue * 100);
    }
}

export class OfferPermanentOptionPriceLevelBuilder {

    private _form: UntypedFormArray;

    private _priceLevelType: OfferPermanentOptionPriceLevelType;

    public isLastControl(index: number): boolean {

        return index === (this.controls.length - 1);
    }

    get form(): UntypedFormArray {

        return this._form;
    }

    set form(value: UntypedFormArray) {

        this._form = value;
    }

    get priceLevelType(): OfferPermanentOptionPriceLevelType {

        return this._priceLevelType;
    }

    set priceLevelType(value: OfferPermanentOptionPriceLevelType) {

        this._priceLevelType = value;
    }

    get controls(): UntypedFormGroup[] {

        return this._form.controls as UntypedFormGroup[];
    }

    get isLastControlWithMaxUnlimited(): boolean {

        if(!this.controls.length){

            return false;
        }

        const control: UntypedFormGroup = this.controls[this.controls.length - 1];

        return control.get('maxUnlimited').value;
    }

    get lastControlWithValidMax(): UntypedFormGroup {

        if(!this.hasControlWithValidMax){

            return null;
        }

        const controls: UntypedFormGroup[] = this.controlsWithValidMax;

        return controls[controls.length - 1];
    }

    get controlsWithValidMax(): UntypedFormGroup[] {

        return this.controls.filter((control: UntypedFormGroup): boolean => {

            return control.get('max').valid;
        });
    }

    get isAllControlsValid(): boolean {

        return this.controls.every((control: UntypedFormGroup): boolean => {

            return control.valid;
        });
    }

    get hasControlWithValidMax(): boolean {

        return this.controls.some((control: UntypedFormGroup): boolean => {

            return control.get('max').valid;
        });
    }

    get addControlAllowed(): boolean {

        if(!this.controls.length){

            return true;
        }

        return !this.isLastControlWithMaxUnlimited;
    }

    get nextMinDefaultValue(): number {

        const value: {[p in OfferPermanentOptionPriceLevelType] : number } = {
            'amount': this.hasControlWithValidMax ? (parseInt(this.lastControlWithValidMax.getRawValue().max) + 1) : 0,
            'person': (this.hasControlWithValidMax ? parseInt(this.lastControlWithValidMax.getRawValue().max) : 0) + 1
        };

        return value[this._priceLevelType];
    }
}
