export type ChannelSortOrderBy =
    'createdAt'
    | 'updatedAt'
    | 'duration'
    | 'catalogs'
    | 'onlineSale'
    | 'giftVoucher';

export type ChannelSortOperator = 'ASC' | 'DESC';

export const CHANNEL_SORT_ORDER_BY: ChannelSortOrderBy[] = [
    'createdAt',
    'updatedAt',
    'duration',
    'catalogs',
    'onlineSale',
    'giftVoucher'
];

export const CHANNEL_SORT_OPERATORS: ChannelSortOperator[] = ['ASC', 'DESC'];

export interface ChannelSort {

    orderBy: ChannelSortOrderBy;
    operator: string;
}
