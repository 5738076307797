import {Pipe, PipeTransform} from "@angular/core";
import {ApiService} from "@core/shared/services/api.service";
import {Offer} from "@core/shared/models/offer";
import {Transporter} from "@core/shared/models/transporter";

@Pipe({
    name: 'offerTransporterPictureSource'
})
export class OfferTransporterPictureSource  implements PipeTransform {

    constructor(
        private _apiService: ApiService
    ){
    }

    transform(transporter: Transporter, offer: Offer, format: string, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public/offers/${offer.id}/giftVouchers/transporters/${transporter.id}/picture/filter/${format}/${width}/${quality}`;
    }
}
