<div [formGroup]="form" class="form-group">
    <mat-label id="daterange-group-{{config.id}}"> {{config.attrs.label}}</mat-label>

    <div class="form-group semi-group">
        <mat-form-field>
            <input [attr.readonly]="config.attrs.readonly" matInput #start appInput [attrs]="config.attrs" (click)="picker.open()" [formControlName]="config.id + 'DateStart'" [matDatepicker]="picker" placeholder="{{ config.attrs.placeholderStart }}" (dateChange)="updateDateRange(config.id,'start', $event)"  [(ngModel)]="form.value[config.id + 'DateStart']" id="start">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-error>
            <div *ngIf="form.controls[config.id+ 'DateStart'].invalid && (form.controls[config.id+ 'DateStart'].dirty || form.controls[config.id+ 'DateStart'].touched)">
                <div *ngIf="form.controls[config.id+ 'DateStart'].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            </div>
        </mat-error>
    </div>

    <div class="form-group semi-group">
        <mat-form-field>
            <input [attr.readonly]="config.attrs.readonly" matInput #end required="{{config.attrs.endRequired}}" (click)="pickerEnd.open()" [formControlName]="config.id + 'DateEnd'" [matDatepicker]="pickerEnd" placeholder="{{config.attrs.placeholderEnd}}" (dateChange)="updateDateRange(config.id,'end', $event)" [(ngModel)]="form.value[config.id + 'DateEnd']"  id="end">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
        <mat-error>
            <div *ngIf="form.controls[config.id + 'DateEnd'].invalid && (form.controls[config.id + 'DateEnd'].dirty || form.controls[config.id+ 'DateEnd'].touched)">
                <div *ngIf="form.controls[config.id+ 'DateEnd'].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            </div>
            <div *ngIf="(form.controls[config.id + 'DateEnd'].invalid && (form.controls[config.id + 'DateEnd'].dirty || form.controls[config.id+ 'DateEnd'].touched)) || (form.controls[config.id + 'DateStart'].invalid && (form.controls[config.id + 'DateStart'].dirty || form.controls[config.id+ 'DateStart'].touched))">
                <div *ngIf="(form.controls[config.id+ 'DateEnd'].errors && form.controls[config.id+ 'DateEnd'].errors.daterange) || (form.controls[config.id+ 'DateStart'].errors && form.controls[config.id+ 'DateStart'].errors.daterange)">{{ 'form.validation.error.daterange' | translate }}</div>
            </div>
        </mat-error>
    </div>

    <button type="button" *ngIf="config.enableReset" (click)="reset()" mat-icon-button matTooltip="{{ 'form.field.action.reset' | translate }}" matTooltipPosition="below">
        <mat-icon class="reset">loop</mat-icon>
        {{ config.resetLabel }}
    </button>

</div>
