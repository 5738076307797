<h1 mat-dialog-title>{{ (userService.hasOneOfThisRoles(userService.currentUser.getValue(), ['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER']) ? 'offer.list.item.action.multiple.access.title.value' : 'markup.choice.value') | translate }}</h1>
<mat-dialog-content>
    <app-core-offer-access-request-multiple-create [configuration]="configuration" [offers]="offers" (create)="create.emit()"></app-core-offer-access-request-multiple-create>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row marges x_center">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" [disabled]="submitDisabled" (click)="submit()">{{ 'submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
