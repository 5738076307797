<mat-nav-list>
    <mat-list-item *ngFor="let item of items">
        <app-navigation-sidenav-item [item]="item"></app-navigation-sidenav-item>
    </mat-list-item>

    <div *ngIf="displayPoweredBy" class="row logo_powered_by">
        <img src="assets/images/logo_powered_tywin.png" alt="Powered By Ty-Win" title="Powered By Ty-Win">
    </div>

</mat-nav-list>
