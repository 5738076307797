<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'page.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" class="btn_cta btn_little btn_add">{{ 'page.list.button.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="resetFilters()" mat-icon-button>
                        <mat-icon>loop</mat-icon>
                    </button>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('translations.title')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'page.list.title.value' | translate" [key]="'translations.titre'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('locales')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'page.list.fields.locales.value' | translate" [key]="'locales'" [operator]="'andlkin'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="locales"></app-core-select-array-multiple-filter>
                </th>
            </ng-container>

            <!--Actions-->

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                <mat-cell *matCellDef="let element; let i = index">
                    <button mat-button matTooltip="{{'actions.edit.label.value' | translate}}" (click)="edit(element.id)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button matTooltip="{{'actions.delete.label.value' | translate}}" (click)="openDeleteItemDialog(element.id)">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <button mat-button  matTooltip="{{'actions.duplicate.label.value' | translate}}" (click)="duplicate(element.id)">
                        <mat-icon>file_copy</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!-- Liste des éléments -->

            <ng-container matColumnDef="translations.title">
                <th mat-header-cell *matHeaderCellDef>{{'page.list.fields.title.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).titre}}</td>

            </ng-container>

            <!-- Langues -->

            <ng-container matColumnDef="locales">
                <th mat-header-cell *matHeaderCellDef>{{'page.list.fields.locales.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getParsedLocales(getModel(element))}}</td>
            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Liste des éléments -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>
