import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {Society} from "@core/shared/models/society";
import {SelectionModel} from "@angular/cdk/collections";
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {merge, Observable} from "rxjs";
import {map, mergeMap, startWith, switchMap} from "rxjs/operators";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {OfferOption, OfferOptionTranslation} from "@core/shared/models/offer/offer-option";
import {OfferOptionService} from "@core/shared/services/offer/offer-option.service";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import {OfferOptionPrice, OfferOptionPricePublicType, OfferOptionPriceTypeType} from "@core/shared/models/offer/offer-option/offer-option-price";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Access} from "@core/shared/models/access";
import {OfferOptionPriceLevel} from "@core/shared/models/offer/offer-option/offer-option-price-level";
import {classToPlain} from "class-transformer";
import {OfferOptionDateService} from "@core/shared/services/offer/offer-option/offer-option-date.service";
import {OfferOptionUniquePrice} from "@core/shared/models/offer/offer-option/offer-option-unique-price";
import {parsePrice} from "@core/shared/utils/price";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-page-offer-option-list',
    templateUrl: './page-offer-option-list.component.html',
    styleUrls: ['./page-offer-option-list.component.scss']
})
export class PageOfferOptionListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public society: Society;

    public totalItems: number = 0;

    public user: User;

    public displayedColumns: string[] = [
        'actions',
        'translations.name',
        'translations.title',
        'uniquePrice.valueHT',
        'uniquePrice.valueTTC',
        'price.adult.ttc',
        'price.adult.ht',
        'markup',
        'locales'
    ];

    public filterBuilder: FilterBuilder;

    public items: OfferOption[] = [];

    public selection = new SelectionModel<OfferOption>(true, []);

    public locales: LocaleItem[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _offerOptionService: OfferOptionService,
        private _offerOptionDateService: OfferOptionDateService,
        private _userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.user = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.society = data.society;

            this._initLocales();

            this._initFilterBuilder();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._offerOptionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams);

                }),
                map(this.mapApiResult),
            ).subscribe((data: OfferOption[]): void => {

                this.items = data;

                this.selection.clear();
            }
        );
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._offerOptionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferOption[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._offerOptionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferOption[]): void => {

            this.items = data;
        });
    }

    public getModel(item: OfferOption): OfferOption {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('offer.option.delete.value');

        const content : string = this._translateService.instant('offer.option.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._offerOptionService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('offer.option.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._offerOptionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferOption[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public openDuplicationDialog(id: number): void {

        const title : string = this._translateService.instant('offer.option.duplication.value');

        const content : string = this._translateService.instant('offer.option.duplication.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._offerOptionService.getItemAPI(id).subscribe((option: OfferOption): void => {

                const data: any = classToPlain(option);

                // Nettoyage des données

                delete data.id;

                delete data.image.id;

                delete data.presentialIndividual.id;

                delete data.presentialGroup.id;

                data.translations.forEach((translation: OfferOptionTranslation): void => {

                    delete translation.id;
                });

                data.prices.forEach((price: OfferOptionPrice): void => {

                    delete price.id;
                });

                data.priceLevels.forEach((priceLevel: OfferOptionPriceLevel): void => {

                    delete priceLevel.id;
                });

                if(data.uniquePrice){

                    delete data.uniquePrice.id;
                }

                // Création de l'option dupliquée

                this._offerOptionService.createItemAPI(this.society.id, data).pipe(
                    mergeMap((option: OfferOption): Observable<OfferOption> => {

                        return this._offerOptionDateService.duplicateItemAPI(id, option.id).pipe(
                            map((): OfferOption => {

                                return option;
                            })
                        );
                    })
                )
                .subscribe((item: OfferOption): void => {

                    this._snackBar.open(this._translateService.instant('offer.option.duplication.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.editItem(item.id);
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/offer/option/create']);
    }

    public editItem(id: number): void{

        this._router.navigate(['account/offer/option/update', id]);
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    getParsedLocales(item: OfferOption): string {

        return item.locales.map((locale: string): string => {

            return this._translateService.instant(`locale.${locale}.value`);

        }).join(', ');
    }

    getParsedPriceLabel(pricePublic: OfferOptionPricePublicType, priceType: OfferOptionPriceTypeType): string {

        return this._translateService.instant(`offer.option.price.amount.${pricePublic}.${priceType.toLowerCase()}.value`);
    }

    public getParsedUniquePrice(item: OfferOptionUniquePrice, property: 'valueHT' | 'valueTTC'): string {

        return !!item ? (parsePrice(item[property] / 100) + item.currency.symbol) : '';
    }

    get mapApiResult(): (data: Pagination<OfferOption>) => OfferOption[] {

        return (data: Pagination<OfferOption>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public hasAccessCreate(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'OFFER_OPTION_CREATE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessEdit(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'OFFER_OPTION_EDIT_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessDelete(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'OFFER_OPTION_DELETE_IS_MINE';
        });

        return (accesses.length > 0)
    }
}
