import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {BookingService} from "../services/booking.service";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {AuthenticationService} from "@core/shared/services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class PageBookingItemsResolver  {

    constructor(
        private _bookingService: BookingService,
        private _userService: UserService,
        private _authenticationService: AuthenticationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject): void => {
            this._userService.getItemAPI(this._authenticationService.id).subscribe((user:User): void => {

                if(this._userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

                    this._bookingService.getPaginationItemsAPI().subscribe(resolve, reject)
                } else {

                    this._bookingService.getPaginationSocietyItemsAPI(user.society.id).subscribe(resolve, reject)
                }
            },reject)
        });
    }
}
