import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

    transform(value: Date|string|number): string|null;
    transform(value: null|undefined): null;
    transform(value: Date|string|number|null|undefined): string|null;
    transform(value: Date|string|number|null|undefined): string|null {

        return super.transform(value, 'dd/MM/yyyy');
    }
}
