import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {filter, map} from "rxjs/operators";
import {Role} from "@core/shared/models/role";
import {Access} from "@core/shared/models/access";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionGuard  {
    constructor(
        private _router: Router,
        private _userService: UserService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._userService.currentUser.pipe(
            filter(user => !!user),
            map(user => this._canActivate(user, route, state))
        );
    }

    public hasRole(user, role: Role): boolean {
        return user.roles.indexOf(role) >= 0;
    }

    public hasAccessPayment(user) {
        var accesses = user.accesses.filter((access: Access) => {
            return access.tag == 'PAYMENT_SOCIETY_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public _canActivate(user, route: ActivatedRouteSnapshot, state) {
        if (!user) {
            return false;
        }

        if (!this.hasAccessPayment(user)) {
            return true;
        }

        if ((this.hasRole(user, 'ROLE_OFFER_CREATOR') || this.hasRole(user, 'ROLE_OFFER_DISTRIBUTOR')) &&
            !user.society.isValidAccount && (state.url != '/account/subscription/payment') && !state.url.startsWith('/account/subscription/payment-response/')) {
            this._router.navigate(['account/subscription/payment']);

            return false;
        }

        return true;
    }
}
