import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileUploadComponent} from "./file-upload/file-upload.component";
import {TranslateModule} from "@ngx-translate/core";
import {CropperDialogComponent} from './image/cropper-dialog/cropper-dialog.component';
import {ImageComponent} from './image/image.component';
import {NgArrayPipesModule} from "ngx-pipes";
import {ImageGalleryDialogComponent} from './image/image-gallery-dialog/image-gallery-dialog.component';
import {HttpClientModule} from "@angular/common/http";
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule} from "@angular/forms";
import {ImageCollectionComponent} from './image/image-collection/image-collection.component';
import {FileComponent} from './file/file.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";
import {MaterialModule} from "@material/material.module";

@NgModule({
    declarations: [
        FileUploadComponent,
        CropperDialogComponent,
        ImageComponent,
        ImageGalleryDialogComponent,
        ImageCollectionComponent,
        FileComponent
    ],
    exports: [
        FileUploadComponent,
        FileComponent,
        ImageComponent,
        ImageCollectionComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        TranslateModule,
        NgArrayPipesModule,
        NgxPaginationModule,
        FormsModule
    ]
})
export class MediaModule {

}
