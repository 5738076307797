import {Component, Input, OnInit} from '@angular/core';
import {Observable, of, Subject} from "rxjs";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OFFER_ATTRIBUTES_TYPE_TAGS, OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {minLengthArrayValidator} from "@core/shared/validators/min-length-array.validator";
import {maxLengthArrayValidator} from "@core/shared/validators/max-length-array.validator";
import {OfferAttributeOffer} from "@core/shared/models/offer-attribute-offer";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "@core/shared/services/translation.service";

interface AttributeObservableData {

    tag: OfferAttributeTypeTagType;

    observable: Observable<OfferAttribute[]>;
}

@Component({
    selector: 'app-core-offer-attributes-form',
    templateUrl: './offer-attributes-form.component.html',
    styleUrls: ['./offer-attributes-form.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferAttributesFormComponent implements OnInit {

    @Input() parentFormSubmitSubscription: Subject<object>;

    @Input() formArray: UntypedFormArray;

    @Input() attributesFormReference: UntypedFormGroup;

    @Input() defaultOfferAttributesOffer: OfferAttributeOffer[] = [];

    public attributeObservables: AttributeObservableData[];

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offerAttributeService: OfferAttributeService,
        private _translateService: TranslateService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this._initAttributes();

        this._initData();
    }

    private _initForm(): void {

        this.formService.form = this.attributesFormReference;

        //this.formService.form.addControl('touristsDestinations', this._formBuilder.control([], [minLengthArrayValidator(1)]));

        this.formService.form.addControl('region', this._formBuilder.control([], [minLengthArrayValidator(1)]));

        this.formService.form.addControl('themes', this._formBuilder.control([], [minLengthArrayValidator(1), maxLengthArrayValidator(1)]));

        this.formService.form.addControl('types', this._formBuilder.control([], [minLengthArrayValidator(1)]));

        this.formService.form.addControl('activities', this._formBuilder.control([]));

        this.formService.form.addControl('accommodations', this._formBuilder.control([]));

        this.formService.form.addControl('restoration', this._formBuilder.control([], [maxLengthArrayValidator(1)]));

        this.form.valueChanges.subscribe((): void => {

            this.formArray.clear();

            const attributes: OfferAttribute[] = [];

            Object.keys(this.form.controls).forEach((key: string): void => {

                attributes.push(...this.form.get(key).value);
            });

            attributes.forEach((attribute: OfferAttribute): void => {

                const control: UntypedFormGroup = this._formBuilder.group({
                    attribute: this._formBuilder.group({
                        id: [attribute.id]
                    })
                });

                this.formArray.push(control);
            });
        });

        this.parentFormSubmitSubscription.subscribe((): void => {

            this.formService.checkValidity();
        });
    }

    private _initAttributes(): void {

        this.attributeObservables = [];

        this.attributeObservables.push(...this.attributeTypeTags.map((tag: OfferAttributeTypeTagType): AttributeObservableData => {

            const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', tag);

            return {
                tag: tag,
                observable: this._offerAttributeService.getItemsAPI([
                    typeFilter.serialize
                ])
            };
        }));
    }

    private _initData(): void {

        const defaultOfferAttributesOffer: OfferAttributeOffer[] = [...new Map(this.defaultOfferAttributesOffer.map((item: OfferAttributeOffer) => [item.attribute.id, item])).values()];

        defaultOfferAttributesOffer.forEach((offerAttributeOffer: OfferAttributeOffer): void => {

            const control: UntypedFormGroup = this._formBuilder.group({
                attribute: this._formBuilder.group({
                    id: [offerAttributeOffer.attribute.id]
                })
            });

            this.formArray.push(control);
        });

        const defaultAttributes: OfferAttribute[] = defaultOfferAttributesOffer.map((offerAttributeOffer: OfferAttributeOffer): OfferAttribute => {

            return offerAttributeOffer.attribute;
        });

        Object.keys(this.form.controls).forEach((key: string): void => {

            const attributes: OfferAttribute[] = defaultAttributes.filter((attribute: OfferAttribute): boolean => {

                return (attribute.type.tag === key);
            });

            this.form.get(key).patchValue(attributes);
        });
    }

    public getAttributeObservable(tag: OfferAttributeTypeTagType): Observable<OfferAttribute[]> {

        const match = this.attributeObservables.find((attributeObservable: AttributeObservableData ): boolean => {

            return attributeObservable.tag === tag;
        });

        return match ? match.observable : of([]);
    }

    public compareAttributes(a: OfferAttribute, b: OfferAttribute): boolean {

        return a.id === b.id;
    }

    get attributeTypeTags(): OfferAttributeTypeTagType[] {

        return OFFER_ATTRIBUTES_TYPE_TAGS;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
