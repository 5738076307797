<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'promotion.update.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'promotion.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>
        <mat-tab [label]="'offer.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalDataTemplate"></ng-container>
        </mat-tab>
        <mat-tab #periodsTab [label]="'promotion.period.management.plural.value' | translate" *ngIf="isType('offer') && form.get('allowBooking').value">
            <ng-container *ngTemplateOutlet="periodsTemplate"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalDataTemplate>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Type de promotion -->

                <div class="form-group">
                    <select class="form-control" id="type" formControlName="type" required="required">
                        <option value=""></option>
                        <option *ngFor="let item of promotionTypeItems$ | async" [value]="item.value">
                            {{ item.label | translate }}
                        </option>
                    </select>
                    <label for="type">{{ 'promotion.type.value' | translate }}</label>
                    <mat-error *ngIf="form.get('type').errors && (form.get('type').dirty || form.get('type').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>

            </div>

        </div>

        <ng-container *ngIf="form.get('type').value">

            <div class="row marges ligne_form">

                <div class="col_12 col_md_6">

                    <div class="mat-tab-translations">

                        <ng-container formArrayName="translations">

                            <mat-tab-group [@.disabled]="true">

                                <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">

                                    <mat-tab label="{{translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">

                                        <ng-container [formGroupName]="translationBuilder.indexAsString(it)">
                                            <div class="row marges ligne_form">

                                                <div class="col_12">

                                                    <!-- Nom -->

                                                    <div class="form-group">
                                                        <input type="text" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                                                        <label for="name">{{ 'promotion.name.value' | translate }}</label>
                                                        <mat-error *ngIf="getTranslation(it).get('name').errors && (getTranslation(it).get('name').dirty || getTranslation(it).get('name').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getTranslation(it).get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </mat-tab>

                                </div>

                            </mat-tab-group>

                        </ng-container>

                    </div>

                </div>

            </div>

            <!-- Configuration Bon cadeau / Réservation -->

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{'promotion.marketing.configuration.value' | translate}}</label>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" id="allowBooking" formControlName="allowBooking">
                            <label for="allowBooking">{{ 'booking.value' | translate }}</label>
                        </div>
                        <div class="checkbox">
                            <input type="checkbox" id="allowGiftVoucher" formControlName="allowGiftVoucher" [attr.disabled]="!hasGiftVoucherAccess ? '' : null">
                            <label for="allowGiftVoucher">{{ 'giftVoucher.value' | translate }}</label>
                        </div>
                    </div>
                    <ng-container *ngIf="form.errors && form.errors.isMarketingConfigurationRequired">

                        <div class="form-group">
                            <mat-error>
                                <div class="invalid-feedback">
                                    <div>{{ 'promotion.marketing.configuration.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </ng-container>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 col_md_6 y_items_center">
                    <div class="row">
                        <div class="col_12">

                            <div class="row marges ligne_form">
                                <div class="col_12">

                                    <!-- Fuseau horaire -->

                                    <div class="form-group">
                                        <app-core-timezone-select [form]="form" [required]="true"></app-core-timezone-select>
                                    </div>
                                </div>
                            </div>

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <div class="row marges">
                                        <div class="col_6">

                                            <!-- Date de début -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="dateStartPicker" formControlName="dateStart" (click)="dateStartPicker.open()" id="dateStart" placeholder=" ">
                                                <label for="dateStart">{{'promotion.date.start.value' | translate}}</label>
                                                <mat-datepicker #dateStartPicker></mat-datepicker>
                                                <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('dateStart').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                        <div class="col_6">

                                            <!-- Heure de début -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput [placeholder]=" " aria-label="24hr format" formControlName="timeStart" [ngxTimepicker]="fullTimeStart" [format]="24" readonly>
                                                <label>{{ 'promotion.time.start.value' | translate }}</label>
                                                <ngx-material-timepicker #fullTimeStart></ngx-material-timepicker>
                                                <mat-error *ngIf="form.get('timeStart').errors && (form.get('timeStart').dirty || form.get('timeStart').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('timeStart').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="row marges ligne_form">

                                <div class="col_12">

                                    <div class="row marges">

                                        <div class="col_6">

                                            <!-- Date de fin -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="dateEndPicker" formControlName="dateEnd" (click)="dateEndPicker.open()" id="dateEnd" placeholder=" ">
                                                <label for="dateEnd">{{'promotion.date.end.value' | translate}}</label>
                                                <mat-datepicker #dateEndPicker></mat-datepicker>
                                                <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('dateEnd').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>
                                        <div class="col_6">

                                            <!-- Heure de fin -->

                                            <div class="form-group">
                                                <input type="text" class="datetimepicker" matInput [placeholder]=" " aria-label="24hr format" formControlName="timeEnd" [ngxTimepicker]="fullTimeEnd" [format]="24" readonly>
                                                <label>{{ 'promotion.time.end.value' | translate }}</label>
                                                <ngx-material-timepicker #fullTimeEnd></ngx-material-timepicker>
                                                <mat-error *ngIf="form.get('timeEnd').errors && (form.get('timeEnd').dirty || form.get('timeEnd').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="form.get('timeEnd').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col_12 col_md_6 y_items_center" *ngIf="isType('offer')">
                    <div class="form-group">
                        <span class="message-help">
                            <mat-icon  class="tooltip_icon">info</mat-icon>
                            {{'promotion.date.update.help.value' | translate}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <ng-container *ngIf="isType('code')">

                    <div class="col_12 col_md_6">

                        <!-- Code -->

                        <div class="form-group">
                            <input type="text" placeholder=" " class="form-control" formControlName="code" id="code">
                            <label for="code">{{ 'promotion.code.value' | translate }}</label>
                            <mat-error *ngIf="form.get('code').errors && (form.get('code').dirty || form.get('code').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('code').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </ng-container>

                <div class="col_12 col_md_6">

                    <!-- % de la promotion -->

                    <div class="form-group">
                        <input type="text" placeholder=" " class="form-control" formControlName="value" id="value" required="required">
                        <label for="value">{{'promotion.percent.value' | translate}}</label>
                        <mat-error *ngIf="form.get('value').errors && (form.get('value').dirty || form.get('value').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('value').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('value').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="this.isType('code')">

                <div class="row marges ligne_form">

                    <div class="col_12">

                        <!-- Quota illimité -->

                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" class="form-control" id="unlimitedQuota" [(ngModel)]="unlimitedQuota" [ngModelOptions]="{ standalone: true }" (ngModelChange)="resetQuota()">
                                <label for="unlimitedQuota">{{'promotion.quota.unlimited.value' | translate}}</label>
                            </div>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="!unlimitedQuota">

                    <!-- Quota -->

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6">

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="quota" id="quota">
                                <label for="value">{{'promotion.quota.value' | translate}}</label>
                                <mat-error *ngIf="form.get('quota').errors && (form.get('quota').dirty || form.get('quota').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('quota').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('quota').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                    </div>

                </ng-container>

            </ng-container>

            <ng-container *ngIf="isType('offer')">

                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR')">

                    <!-- Promotion ciblée -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="isRestricted" formControlName="isRestricted">
                                    <label for="isRestricted">{{ 'promotion.targeted.value' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="form.get('isRestricted').value">

                        <div class="row ligne_form">
                            <app-core-promotion-partner-society-collection-select [parentForm]="form" [formName]="'distributors'" [society]="society">
                                <div formError>
                                    <mat-error *ngIf="form.get('distributors').invalid && (form.get('distributors').dirty || form.get('distributors').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('distributors').errors.minLengthArray">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </app-core-promotion-partner-society-collection-select>
                        </div>

                    </ng-container>

                </ng-container>

                <div class="ligne_form row marges">

                    <div class="col_12 col_md_6">

                        <!-- Langues -->

                        <div class="form-group">
                            <mat-select formControlName="locales" multiple>
                                <mat-option *ngFor="let locale of locales$ | async;" [value]="locale.id">
                                    {{ locale.label | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-label>{{ 'promotion.locales.value' | translate }}</mat-label>
                            <mat-error *ngIf="form.get('locales').invalid && (form.get('locales').dirty || form.get('locales').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('locales').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                </div>

                <ng-container *ngIf="form.get('locales').value.length">

                    <ng-container *ngTemplateOutlet="selectOffersTemplate"></ng-container>

                </ng-container>

            </ng-container>

            <ng-container *ngIf="isType('code')">

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_6">

                        <!-- Typologie de public -->

                        <div class="form-group">
                            <select class="form-control" id="public" formControlName="public">
                                <option value=""></option>
                                <option *ngFor="let item of promotionPublicItems$ | async" [value]="item.value">
                                    {{ item.label | translate }}
                                </option>
                            </select>
                            <label for="type">{{ 'promotion.public.value' | translate }}</label>
                            <mat-error *ngIf="form.get('public').errors && (form.get('public').dirty || form.get('public').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('public').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                </div>

                <ng-container *ngIf="isPublic('customer')">

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <!-- Tous les clients -->

                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" id="allCustomers" [(ngModel)]="allCustomers" [ngModelOptions]="{ standalone: true }" (ngModelChange)="resetCustomers()">
                                    <label for="allCustomers">{{'promotion.customer.plural.all.value' | translate}}</label>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="!allCustomers">

                        <div class="col_12">

                            <div class="form-group">
                                <textarea class="form-control" formControlName="parsedCustomers" id="customers"></textarea>
                                <label for="customers">{{'promotion.customer.plural.value' | translate}}</label>
                                <span class="message-help">
                                    <mat-icon  class="tooltip_icon">info</mat-icon>
                                    <div [innerHTML]="'promotion.customer.info.value' | translate"></div>
                                </span>
                                <mat-error
                                    *ngIf="form.get('parsedCustomers').errors && (form.get('parsedCustomers').dirty || form.get('parsedCustomers').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('parsedCustomers').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('parsedCustomers').errors.parsedEmailPattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>
                    </div>

                    <ng-container *ngTemplateOutlet="selectOffersTemplate"></ng-container>

                </ng-container>

                <ng-container *ngIf="isPublic('distributor')">

                    <div class="ligne_form row marges">

                        <div class="col_12 col_md_6 y_items_center">

                            <!-- Tous les distributeurs -->

                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" id="allDistributors" [(ngModel)]="allDistributors" [ngModelOptions]="{ standalone: true }" (ngModelChange)="resetDistributors()">
                                    <label for="allDistributors">{{'promotion.distributor.plural.all.value' | translate}}</label>
                                </div>
                            </div>

                        </div>

                    </div>


                    <ng-container *ngIf="!allDistributors">

                        <div class="ligne_form row marges">

                            <div class="col_12 y_items_center">

                                <!-- Distributeurs -->

                                <div class="form-group">
                                    <mat-select formControlName="distributors" multiple>
                                        <mat-option *ngFor="let distributor of distributors$ | async;" [value]="distributor.id">
                                            {{ distributor.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'promotion.distributor.plural.value' | translate }}</mat-label>
                                    <mat-error *ngIf="form.get('distributors').errors && (form.get('distributors').dirty || form.get('distributors').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('distributors').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                    </ng-container>

                    <ng-container *ngTemplateOutlet="selectOffersTemplate"></ng-container>

                </ng-container>

                <ng-container *ngIf="isPublic('networkProvider')">

                    <div class="ligne_form row marges">

                        <div class="col_12 col_md_6 y_items_center">

                            <!-- Tous les réseaux de prestataire -->

                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" class="form-control" id="allNetworkProviders" [(ngModel)]="allNetworkProviders" [ngModelOptions]="{ standalone: true }" (ngModelChange)="resetNetworkProviders()">
                                    <label for="allNetworkProviders">{{'promotion.networkProvider.plural.all.value' | translate}}</label>
                                </div>
                            </div>

                        </div>

                    </div>

                    <ng-container *ngIf="!allNetworkProviders">

                        <div class="ligne_form row marges">

                            <div class="col_12 y_items_center">

                                <!-- Réseaux de prestataire -->

                                <div class="form-group">
                                    <mat-select formControlName="networkProviders" multiple>
                                        <mat-option *ngFor="let networkProvider of networkProviders$ | async;" [value]="networkProvider.id">
                                            {{ networkProvider.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'promotion.networkProvider.plural.value' | translate }}</mat-label>
                                    <mat-error *ngIf="form.get('networkProviders').errors && (form.get('networkProviders').dirty || form.get('networkProviders').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('networkProviders').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                    </ng-container>

                    <ng-container *ngTemplateOutlet="selectOffersTemplate"></ng-container>

                </ng-container>

            </ng-container>

            <div class="row marges ligne_form">

                <div class="col_12">

                    <!-- Commentaire -->

                    <div class="form-group">
                        <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                        <label for="comment">{{'promotion.comment.value' | translate}}</label>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <div class="col_12">

                    <!-- Activation -->

                    <div class="form-group">
                        <label>{{'promotion.enable.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="enable" class="form-control" id="enableNo">
                            <label for="enableNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="enable" class="form-control" id="enableYes">
                            <label for="enableYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <mat-error *ngIf="form.get('enable').errors && (form.get('enable').dirty || form.get('enable').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('enable').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

    </ng-container>

</ng-template>

<ng-template #selectOffersTemplate>

    <ng-container [ngSwitch]="true">

        <ng-container *ngSwitchCase="hasAllOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
            <ng-container *ngTemplateOutlet="selectCreatedOffersTemplate"></ng-container>
            <ng-container *ngIf="isType('offer') || (isType('code') && isOneOfThisPublics(['customer', 'distributor']))">
                <ng-container *ngTemplateOutlet="selectCatalogOffersTemplate"></ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR')">
            <ng-container *ngTemplateOutlet="selectCreatedOffersTemplate"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="hasRole('ROLE_OFFER_DISTRIBUTOR') && !hasRole('ROLE_OFFER_CREATOR')">
            <ng-container *ngIf="isType('offer') || (isType('code') && isOneOfThisPublics(['customer', 'distributor']))">
                <ng-container *ngTemplateOutlet="selectCatalogOffersTemplate"></ng-container>
            </ng-container>
        </ng-container>

    </ng-container>

</ng-template>

<ng-template #selectCreatedOffersTemplate>

    <ng-container [formGroup]="form">
        <div class="ligne_form row marges">

            <div class="col_12 col_md_6 y_items_center">

                <!-- Toutes mes offres -->

                <div class="form-group">
                    <div class="checkbox">
                        <input type="checkbox" class="form-control" id="allowAllCreatedOffers" formControlName="allowAllCreatedOffers" (change)="handleAllowCreatedOffers()">
                        <label for="allowAllCreatedOffers">{{ 'promotion.createdOffers.value' | translate }}</label>
                    </div>
                </div>

            </div>

            <div class="col_12 col_md_6 y_items_center">
                <div class="form-group">
                    <span class="message-help">
                        <mat-icon  class="tooltip_icon">info</mat-icon>
                        {{ 'promotion.createdOffers.help.value' | translate }}
                    </span>
                </div>
            </div>

        </div>

        <div class="ligne_form row marges">

            <div class="col_12 y_items_center">

                <!-- Sélection des offres -->
                <div class="form-group">
                    <app-select-search [config]="configCreatedOffers" [form]="form" [value]="createdOffersValue" (valueChange)="changeCreatedOffers($event)"></app-select-search>
                    <mat-error *ngIf="form.get('createdOffers').errors && (form.get('createdOffers').dirty || form.get('createdOffers').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('createdOffers').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                        </div>
                    </mat-error>
                    <ng-container *ngIf="isType('offer')">
                        <span class="message-help">
                            <mat-icon  class="tooltip_icon">info</mat-icon>
                            {{ 'promotion.offer.filter.locale.help.value' | translate }}
                        </span>
                    </ng-container>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<ng-template #selectCatalogOffersTemplate>

    <ng-container [formGroup]="form">
        <div class="ligne_form row marges">

            <div class="col_12 col_md_6 y_items_center">

                <!-- Toutes les offres que je distribue -->

                <div class="form-group">
                    <div class="checkbox">
                        <input type="checkbox" class="form-control" id="allowAllCatalogOffers" formControlName="allowAllCatalogOffers" (change)="handleAllowCatalogOffers()">
                        <label for="allowAllCatalogOffers">{{ 'promotion.catalogOffers.value' | translate }}</label>
                    </div>
                </div>

            </div>

            <div class="col_12 col_md_6 y_items_center">
                <div class="form-group">
                    <span class="message-help">
                        <mat-icon  class="tooltip_icon">info</mat-icon>
                        {{ 'promotion.catalogOffers.help.value' | translate }}
                    </span>
                </div>
            </div>

        </div>

        <div class="ligne_form row marges">

            <div class="col_12 y_items_center">

                <!-- Sélection des offres -->

                <div class="form-group">
                    <app-select-search [config]="configCatalogOffers" [form]="form" [value]="catalogOffersValue" (valueChange)="changeCatalogOffers($event)"></app-select-search>
                    <mat-error *ngIf="form.get('catalogOffers').errors && (form.get('catalogOffers').dirty || form.get('catalogOffers').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('catalogOffers').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                        </div>
                    </mat-error>
                    <ng-container *ngIf="isType('offer')">
                        <span class="message-help">
                            <mat-icon  class="tooltip_icon">info</mat-icon>
                            {{ 'promotion.offer.filter.locale.help.value' | translate }}
                        </span>
                    </ng-container>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Choix des périodes -->

<ng-template #periodsTemplate>

    <!-- Calendrier -->

    <div class="period-container row marges">

        <!-- Ajout d'une période -->

        <div class="col_content">
            <button type="button" class="btn_cta btn_little" (click)="openCreatePeriodDialog()">{{ 'promotion.period.create.action.value' | translate }}</button>
        </div>

        <!-- Suppression d'une période -->

        <div class="col_content">
            <button type="button" class="btn_cta btn_little" (click)="openDeletePeriodDialog()">{{ 'promotion.period.delete.action.value' | translate }}</button>
        </div>

    </div>

    <div class="calendar-container">

        <app-core-promotion-period-calendar [promotion]="promotion"></app-core-promotion-period-calendar>

    </div>

</ng-template>
