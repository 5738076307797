import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";

/**
 * Configuration paramètres du champ
 */
export interface InputRadioConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        label: string;
        choices: [{
            key : string|number,
            value : string|number
        }],
        required?: boolean;
    };
}

/**
 * Champ input radio
 */
@Component({
    selector: 'app-input-radio',
    templateUrl: './input-radio.component.html',
    styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() config: InputRadioConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;


    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    ngOnInit() {

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl());
        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        this.form.controls[this.config.id].setValidators(validators);
    }

}
