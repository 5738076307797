import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Pagination} from '@core/shared/models/pagination';
import {Menu} from "../models/menu";

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemAPI(id: number, params?: string[]): Observable<Menu> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/menu/load/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<Menu>(url,{
            headers: headers
        });
    }

    public getPaginationItemAPI(id: number, params?: string[]): Observable<Pagination<Menu>> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/menus/${id}`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<Menu>>(url, {
            headers: headers
        });

    }

    public getItemsAPI(params?: string[]): Observable<Menu[]> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/menu/list`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Menu[]>(url, {
            headers: headers
        });

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Menu>> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/menus`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Menu>>(url, {
            headers: headers
        });

    }

    public updateItemAPI(id: number, data: object): Observable<Menu> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Menu>(`${this._apiService.getApiUrl(false, true)}/menu/update/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/menus/${id}`,{
            headers: headers
        });
    }

    public postItemsAPI(data): Observable<Object[]> {

        let url: string = `${this._apiService.getApiUrl(false, true)}/menu/save/list`;

        return this._httpClient.post<Object[]>(url, data);

    }

    public postItemAPI(data): Observable<Object[]> {

        let url: string = `${this._apiService.getApiUrl(false, true)}/menu/save`;

        return this._httpClient.post<Object[]>(url, data);

    }

    public duplicateItemAPI(id: number): Observable<number> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<number>(`${this._apiService.getApiUrl(false, true)}/duplicate-menu/${id}`, {
            headers: headers
        });
    }
}
