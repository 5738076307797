import {Component, OnInit} from '@angular/core';
import {FieldCollection} from "@lib/form/field";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {FormService} from "@core/shared/services/form.service";
import {Society} from "@core/shared/models/society";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {SocietyService} from "@core/shared/services/society.service";
import {UserService} from "@core/shared/services/user.service";

@Component({
    selector: 'page-reference-read',
    templateUrl: './page-reference-read.component.html',
    styleUrls: ['./page-reference-read.component.scss'],
    providers: [
        FormService
    ]
})
export class PageReferenceReadComponent implements OnInit {

    public society: Society;

    public fieldCollection = new FieldCollection();

    public withEmailCopy: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _societyService: SocietyService,
        private _translateService: TranslateService,
        private _userService: UserService,
        public formService: FormService
    ) {
    }

    ngOnInit() {

        this._buildForm();

        this._societyService.getItemAPI(this._userService.currentUser.value.society.id).subscribe((data: Society): void => {

            this.society = data;
        });
    }

    private _buildForm(): void {

        this.formService.form = this._formBuilder.group({
            email: ['', [Validators.pattern(REGEX_EMAIL), Validators.required]],
            emailCopy: [null, [(control: UntypedFormControl) => {

                if(!this.form || !this.withEmailCopy){

                    return null;
                }

                if(!control.value || control.value.length < 1) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }

                if(REGEX_EMAIL.test(control.value)){

                    return null;
                }

                return {
                    'isPatternInvalid': {
                        valid: false
                    }
                };
            }]],
            locale: ['', [Validators.required]],
            comment: [''],
        });

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'locale',
                attrs: {
                    required: true,
                    placeholder: this._translateService.instant('language.value'),
                    choices: [
                        {
                            'fr': this._translateService.instant('locale.fr.value')
                        },
                        {
                            'en': this._translateService.instant('locale.en.value')
                        }
                    ]
                }
            }
        })
    }

    public sendShareReference(): void {

        const data: object = this.form.value;

        this._societyService.createReferenceShareAPI(data).subscribe((): void => {

            this._snackBar.open(this._translateService.instant('reference.share.success.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });
        })
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
