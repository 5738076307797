<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <h1 mat-dialog-title class="x_center">{{ 'relationship.creation.value' | translate }}</h1>

    <mat-dialog-content>

        <br>

        <div class="row marges ligne_form">
            <div class="col_12">
                <ng-container *ngFor="let type of types">
                    <div class="form-group">
                        <div class="radio">
                            <input type="radio" [value]="type" formControlName="type" class="form-control" id="type-{{type}}">
                            <label for="type-{{type}}">{{ ('relationship.type.' + type + '.value') | translate}}</label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <mat-error style="padding: 0">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('type').errors && form.get('type').errors.required && (form.get('type').dirty || form.get('type').touched)">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions>

        <div class="row x_center marges">
            <div class="col_content">
                <button type="button" class="btn_cta btn_little btn_green_lines" (click)="cancel()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
            </div>
            <div class="col_content">
                <button type="submit" class="btn_cta btn_little">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
            </div>
        </div>

    </mat-dialog-actions>
</form>
