import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterBuilder, FilterComponent, FilterOperator} from "@core/shared/models/filter";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";

@Component({
    selector: 'app-core-select-array-multiple-filter',
    templateUrl: './select-array-multiple-filter.component.html',
    styleUrls: ['./select-array-multiple-filter.component.scss']
})
export class SelectArrayMultipleFilterComponent implements OnInit, OnChanges, FilterComponent {

    @Input() builder: FilterBuilder;

    @Input() label: string;

    @Input() key: string;

    @Input() itemValueProperty: string;

    @Input() itemLabelProperty: string;

    @Input() itemTypeProperty: string;

    @Input() items: object[] = [];

    @Input() operator: FilterOperator;

    @Input() disableAutoSubmit: boolean;

    @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    public values: any[] = [];

    public filterCollectionField: FilterCollectionField;

    constructor() {

    }

    ngOnInit(): void {

        this.filterCollectionField = new FilterCollectionField(this.key);

        this.filterCollectionField.choices = this.items;

        this.builder.addField(this.filterCollectionField);
    }

    ngOnChanges(changes: SimpleChanges) {

        if(('items' in changes) && !changes.items.isFirstChange()){

            this.filterCollectionField.choices = changes.items.currentValue;
        }
    }

    public hydrateValue(): void {

        this.filterCollectionField.clear();

        this.values.forEach((value: string): void => {

            this.filterCollectionField.fields.push(new ArrayFilterField(this.key, this.operator, value));
        });
    }

    public filter(): void {

        this.hydrateValue();

        this.selectionChanged.emit(this.values);

        if (this.disableAutoSubmit) {

            return;
        }

        this.builder.filter();
    }

    public reset(): void {

        this.values = [];

        this.filterCollectionField.clear();
    }

    public getLabelPropertyValue(item: object) {

        return this.itemLabelProperty.split('.').reduce((carry, key) => carry[key], item);
    }

    public getKeyPropertyValue(item: object) {

        return this.itemValueProperty.split('.').reduce((carry, key) => carry[key], item);
    }
}
