export type SocietyCommissionType = 'standard' | 'custom';

export const DEFAULT_INDIVIDUAL_STAY: number = 8.33;

export const DEFAULT_INDIVIDUAL_DAY: number = 4.16;

export const DEFAULT_GROUP_STAY: number = 4.16;

export const DEFAULT_GROUP_DAY: number = 4.16;

export interface SocietyCommission {

    id: number;

    type: SocietyCommissionType;

    individualStay?: number;

    individualStayTTC?: number;

    individualDay?: number;

    individualDayTTC?: number;

    groupStay?: number;

    groupStayTTC?: number;

    groupDay?: number;

    groupDayTTC?: number;

    incomingPayment?: boolean;
}
