import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Router} from "@angular/router";
import {User} from "@core/shared/models/user";
import {Role} from "@core/shared/models/role";

@Component({
    selector: 'introduction-dialog',
    templateUrl: './introduction-dialog.component.html',
    styleUrls: ['./introduction-dialog.component.scss']
})
export class IntroductionDialogComponent implements OnInit {

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _dialogRef: MatDialogRef<IntroductionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { user: User },
        private _router: Router
    ) {}

    ngOnInit(): void {

        this.confirm.subscribe((): void => {

            this._dialogRef.close();
        });
    }

    public goToCgv(): void {

        this._dialogRef.close();

        this._router.navigate(['/account'], {fragment: 'cgv'});
    }

    public navigateToPage(page: number): void {

        this._router.navigate(['page/read', page], { queryParams: { backEnabled: 0 }});

        this._dialogRef.close();
    }

    public navigateToCategory(page: number): void {

        this._router.navigate(['category/read', page], { queryParams: { backEnabled: 0 }});

        this._dialogRef.close();
    }

    public hasRole(role: Role): boolean {

        return this.data.user.roles.includes(role);
    }
}
