import { Injectable } from '@angular/core';
import {ITemplate} from "./template";

@Injectable()
export class TemplateService{

    private templates: ITemplate[];

    /**
     * Mise à jour des templates disponibles pour la page
     *
     * @param {ITemplate[]} templates
     */
    setTemplates(templates: ITemplate[]): void{
        this.templates = templates;
    }

    /**
     * Récupération de l'ensemble des templates disponibles pour la page
     *
     * @returns {ITemplate[]}
     */
    getTemplates(): ITemplate[]{
        return this.templates;
    }

    /**
     * Récupération d'un template fonction de l'ID
     *
     * @param {string} id
     * @returns {ITemplate}
     */
    getTemplateById(id: string): ITemplate{

        let match = this.templates.filter((template: ITemplate) => {
            return template.id === id;
        }).shift();

        return match || null;
    }
}
