import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FormService} from "@core/shared/services/form.service";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {User} from "@core/shared/models/user";
import {TranslationBuilder} from "@core/shared/models/translation";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-template-gift-voucher-dialog',
    templateUrl: './template-gift-voucher-dialog.component.html',
    styleUrls: ['./template-gift-voucher-dialog.component.scss'],
    providers: [FormService]
})
export class TemplateGiftVoucherDialogComponent implements OnInit {

    public user: User;

    public translationBuilder: TranslationBuilder;

    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    @Output() templatesUpdated: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public formService: FormService,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _templateVoucherService: TemplateGiftVoucherService,
        private _dialogRef: MatDialogRef<TemplateGiftVoucherDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { user: User, template: TemplateGiftVoucher }
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this.back.subscribe(() => {

            this._dialogRef.close();
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            type: ['', [Validators.required]],
            validType: [null, [Validators.requiredTrue]],
            isDematerialized: [false],
            isPhysical: [false],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.formService.submitCallback = () => {

            const data = Object.assign({...this.form.value}, {
                translations: this.translationBuilder.itemsControl.controls.map((control: AbstractControl): object => {

                    const value: object = {...control.value};

                    if(!value['id']){

                        delete value['id'];
                    }

                    return value;
                })
            });

            delete data['validType'];

            if (this.data.template) {

                this._templateVoucherService.updateItemAPI(this.data.template.id, data).subscribe(() => {

                    this._snackBar.open(this._translateService.instant('giftVoucher.template.action.edit.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.templatesUpdated.emit();

                    this._dialogRef.close();
                });

            } else {

                this._templateVoucherService.createSocietyItemAPI(this.data.user.society.id, data).subscribe(() => {

                    this._snackBar.open(this._translateService.instant('giftVoucher.template.action.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.templatesUpdated.emit();

                    this._dialogRef.close();
                });
            }
        }
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
