<h1 mat-dialog-title class="x_center">{{ 'gdpr.value' | translate }} &#9415;</h1>

<mat-dialog-content>

 <div class="condition_use" [innerHTML]="'gdpr.html.value'|translate: {societyName: societyName }|safeHtml"></div>

</mat-dialog-content>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="confirm.emit()">{{ 'authentication.welcome.btn.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>


