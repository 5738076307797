import {Injectable} from "@angular/core";
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaymentInitialize} from "@core/shared/models/payment-initialize";

@Injectable({
    providedIn: 'root'
})
export class PaymentServiceService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public paymentInitialize(id): Observable<PaymentInitialize> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/services/payment/initialize`;

        return this._httpClient.get<PaymentInitialize>(url, {
            headers: headers
        });
    }

    public paymentValidate(id, data): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/services/payment/validate`;

        return this._httpClient.post<any>(url, data, {
            headers: headers
        });
    }

    public cardRegistrationUrl(url, data): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Accept', 'text/plain');

        return this._httpClient.post(url, data, {
            headers: headers,
            responseType: "text"
        });
    }

}
