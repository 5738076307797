import {Component, Input, OnInit} from '@angular/core';
import {FilterBuilder, FilterComponent, FilterField, FilterOperator} from '@core/shared/models/filter';
import {Offer} from '@core/shared/models/offer';
import {User} from '@core/shared/models/user';
import {UserService} from '@core/shared/services/user.service';
import {AuthenticationService} from '@core/shared/services/authentication.service';
import {OfferService} from '@core/shared/services/offer.service';
import * as moment from 'moment';
import {Role} from '@core/shared/models/role';
import {Moment} from 'moment';
import {OfferCatalogService} from '@core/shared/services/offer/offer-catalog.service';
import {OfferCatalog, OfferCatalogStatusType} from '@core/shared/models/offer/offer-catalog';
import {TextFilterField} from '@core/shared/models/filter/text-filter-field';
import {ModeType} from '@core/shared/models/offer/offer-list';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {Pagination} from '@core/shared/models/pagination';
import {map, tap} from 'rxjs/operators';
import {DEFAULT_OFFER_PER_PAGE} from '@core/shared/data/offer';
import {OfferSearchService} from '@core/shared/services/offer/offer-search.service';
import {FilterCollectionField} from '@core/shared/models/filter/filter-collection';
import {ArrayFilterField} from '@core/shared/models/filter/array-filter-field';
import {OfferSearchResponseType, OfferSearchSessionFilter} from '@core/shared/models/offer/offer-search';
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";
import {MarketplacePreferenceService} from "@core/shared/services/marketplace-preference.service";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {ViewType} from "@core/components/offer/offer-search/offer-search.component";
import {OfferListService} from "@core/shared/services/offer/offer-list.service";

export interface OfferPart {

    position: number;

    items: Offer[];
}

@Component({
    selector: 'app-core-offer-list',
    templateUrl: './offer-list.component.html',
    styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {

    @Input() filterBuilder: FilterBuilder;

    @Input() roles: Role[];

    @Input() mode: ModeType;

    @Input() publicPrice: number;

    @Input() locale: UntypedFormControl;

    @Input() referenced: UntypedFormControl;

    @Input() allowBooking: AbstractControl;

    @Input() allowGiftVoucher: AbstractControl;

    @Input() allowRequest: AbstractControl;

    @Input() allowOnlineSale: AbstractControl;

    @Input() layoutView: 'list' | 'map';

    @Input() identificationNumber: string;

    @Input() loadItemsSourceCallback: (view: ViewType, params?: string[]) => Observable<Pagination<unknown>>;

    public offerOffset: number = 0;

    public offerPerPage: number = DEFAULT_OFFER_PER_PAGE;

    public totalOffers: number = 0;

    public items: Offer[] = [];

    public user: User;

    public parts: OfferPart[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _userService: UserService,
        private _authenticationService: AuthenticationService,
        private _offerService: OfferService,
        private _offerCatalogService: OfferCatalogService,
        private _offerSearchService: OfferSearchService,
        private _offerListService: OfferListService,
        private _marketplacePreferenceService: MarketplacePreferenceService
    ) {
    }

    ngOnInit(): void {

        this._userService.getItemAPI(this._authenticationService.id).subscribe((user: User): void => {

            this.user = user;

            if (this._offerSearchService.hasSessionFilters(this.mode)) {

                this._initSessionFilters();
            }

            this._initQueryParamSessionFilters();

            if (!this._offerSearchService.hasSessionFilters(this.mode)) {

                this._initDefaultSessionFilters();
            }

            this.loadItems(true);

            this._initEvents();
        });

        if (this.locale) {

            this.locale.valueChanges.subscribe((): void => {

                this.loadItems(true);
            });
        }

        if (this.referenced) {

            this.referenced.valueChanges.subscribe((): void => {

                this.loadItems(true);
            });
        }

        if (this.allowBooking) {

            this.allowBooking.valueChanges.subscribe((): void => {

                this.loadItems(true);
            });
        }

        if (this.allowGiftVoucher) {

            this.allowGiftVoucher.valueChanges.subscribe((): void => {

                this.loadItems(true);
            });
        }

        if (this.allowRequest) {

            this.allowRequest.valueChanges.subscribe((): void => {

                this.loadItems(true);
            });
        }

        if (this.allowOnlineSale) {

            this.allowOnlineSale.valueChanges.subscribe((): void => {

                this.loadItems(true);
            });
        }

        this._offerSearchService.updateFilter$.subscribe((data: { key: string, value: any }): void => {

            const conditions: boolean[] = [
                data.key === 'isDistributor',
                this.hasOneOfThisMode(['offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog']) && data.key === 'selectedOffers' && this._offerSearchService.restrictPermanentOptionOffer
            ];

            const itemsToLoad: boolean = conditions.some((condition: boolean): boolean => {

                return Boolean(condition);
            });

            if(itemsToLoad){

                this.loadItems(true);
            }
        });
    }

    private _initEvents(): void {

        // Filtre du moteur de recherche

        this.filterBuilder.filterCallback = (): void => {

            this.loadItems(true);
        };

        // Chargement manuel des données

        this._offerSearchService.loadItems$.subscribe((configuration): void => {

            this.loadItems(configuration.resetOffset);
        });
    }

    private _initSessionFilters(): void {

        this.filterBuilder.resetFields();

        // Publication

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}published`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}published`).value = this._offerSearchService.getSessionFilter(this.mode, `published`).value;
        }

        // Nouveautés

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value = this._offerSearchService.getSessionFilter(this.mode, `createdAt`).value;

        // Offres ajoutées à son catalogue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}offerAdded`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`).value = this._offerSearchService.getSessionFilter(this.mode, `offerAdded`).value;

        }

        // Vente en ligne

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value = this._offerSearchService.getSessionFilter(this.mode, `onlineSale.enable`).value;

        // Bon cadeaux

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value = this._offerSearchService.getSessionFilter(this.mode, `giftVoucher.enable`).value;

        // Non présent dans le catalogue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}notInCatalog`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `notInCatalog`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value = this._offerSearchService.getSessionFilter(this.mode, `notInCatalog`).value;
            }
        }

        // Offre ciblée

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isRestricted`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `isRestricted`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value = this._offerSearchService.getSessionFilter(this.mode, `isRestricted`).value;
            }
        }

        // Promotions

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}promotions`).value = this._offerSearchService.getSessionFilter(this.mode, `promotions`).value;

        // Nom

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}translations.name`).value = this._offerSearchService.getSessionFilter(this.mode, `translations.name`).value;

        // Référence

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}reference`).value = this._offerSearchService.getSessionFilter(this.mode, `reference`).value;

        // Langues

        (this._offerSearchService.getSessionFilter(this.mode, `locales`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}locales`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}locales`, 'lkin', value));
        });

        // Prestataires

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}providerSocieties.id`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.id`)){

                (this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.id`).value as string[]).forEach((value: string): void => {

                    (this.filterBuilder.getFieldByKey(`${this.prefixFilter}providerSocieties.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}providerSocieties.id`, 'in', value));
                });
            }
        }

        // Créateur d'offre

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}society.id`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `society.id`)){

                (this._offerSearchService.getSessionFilter(this.mode, `society.id`).value as string[]).forEach((value: string): void => {

                    (this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}society.id`, 'in', value));
                });
            }
        }

        // Dates

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}date`).value = this._offerSearchService.getSessionFilter(this.mode, `date`).value;

        // Maximum adultes

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value = this._offerSearchService.getSessionFilter(this.mode, `adult`).value;

        // Maximum enfants

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value = this._offerSearchService.getSessionFilter(this.mode, `child`).value;

        // Ville

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}locations.city`).value = this._offerSearchService.getSessionFilter(this.mode, `locations.city`).value;

        // Durée

        (this._offerSearchService.getSessionFilter(this.mode, `duration.id`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}duration.id`, 'in', value));
        });

        // Typologie de clientèle

        (this._offerSearchService.getSessionFilter(this.mode, `customerTypology.id`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}customerTypology.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}customerTypology.id`, 'in', value));
        });

        //Destinations

        // (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.destinations`).value as string[]).forEach((value: string): void => {
        //
        //     (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.destinations`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.destinations`, 'in', value));
        // });

        //Hébergements

        (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.accommodations`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.accommodations`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.accommodations`, 'in', value));
        });

        //Restaurations

        (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.restoration`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.restoration`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.restoration`, 'in', value));
        });

        //Thématiques

        (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.themes`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.themes`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.themes`, 'in', value));
        });

        //Activités

        (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.activities`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.activities`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.activities`, 'in', value));
        });

        //Régions

        (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.region`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.region`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.region`, 'in', value));
        });

        //Types de séjour

        (this._offerSearchService.getSessionFilter(this.mode, `attributes.attribute.types`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.types`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.types`, 'in', value));
        });

        // Groupe de réseaux de prestataires

        if(this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.networkProviders.groupNetworkProvider.id`)) {

            (this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.networkProviders.groupNetworkProvider.id`).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(`${this.prefixFilter}providerSocieties.networkProviders.groupNetworkProvider.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}providerSocieties.networkProviders.groupNetworkProvider.id`, 'in', value));
            });
        }

        // Prestataires restreints

        if(this._offerSearchService.getSessionFilter(this.mode, `restrictedProviderSocieties.id`)){

            (this._offerSearchService.getSessionFilter(this.mode, `restrictedProviderSocieties.id`).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(`${this.prefixFilter}restrictedProviderSocieties.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}restrictedProviderSocieties.id`, 'in', value));
            });
        }

        // Réseaux de créateurs d'offres

        if(this._offerSearchService.getSessionFilter(this.mode, `society.networkOfferCreators.id`)) {

            (this._offerSearchService.getSessionFilter(this.mode, `society.networkOfferCreators.id`).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.networkOfferCreators.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}society.networkOfferCreators.id`, 'in', value));
            });
        }

        // Vigilance

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isOnAlert`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}isOnAlert`).value = this._offerSearchService.getSessionFilter(this.mode, `isOnAlert`).value;
        }

        // Date de mise à jour

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}updatedAt`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `updatedAt`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}updatedAt`).value = this._offerSearchService.getSessionFilter(this.mode, `updatedAt`).value;
            }
        }

        // Option assurance

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOption`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `permanentOption`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value = this._offerSearchService.getSessionFilter(this.mode, `permanentOption`).value;
            }
        }

        // Options assurances

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.id`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.id`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.id`).value;
            }
        }

        // Options assurances en fonction de la langue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.offerPermanentOption.locales`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value = this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.offerPermanentOption.locales`).value;
            }
        }

        // Type de réponse

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}responseType`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value = this._offerSearchService.getSessionFilter(this.mode, `responseType`).value;
        }
    }

    private _initDefaultSessionFilters(): void {

        // Réseaux de créateur d'offres

        if(this.hasOneOfThisMode(['reservoir', 'hashtag-reservoir'])){

            const networkOfferCreatorsMarketplacePreference: MarketplacePreference = this._marketplacePreferenceService.getItemByFilterFromCollection('society.networkOfferCreators.id', this.user.marketplacePreferences);

            if(networkOfferCreatorsMarketplacePreference){

                const networkOfferCreatorsIds: number[] = (networkOfferCreatorsMarketplacePreference.value as string).replace(/[\[\]]/g, '').split(',').map((item: string): number => { return parseInt(item); });

                const filterCollectionField: FilterCollectionField = this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.networkOfferCreators.id`) as FilterCollectionField;

                networkOfferCreatorsIds.forEach((id: number): void => {

                    filterCollectionField.fields.push(new ArrayFilterField(networkOfferCreatorsMarketplacePreference.filter, networkOfferCreatorsMarketplacePreference.operator, id as unknown as string));
                });
            }
        }

        this._storeSessionFilters();
    }

    private _initQueryParamSessionFilters(): void {

        const queryParamsFilters: string[] = this._activatedRoute.snapshot.queryParams['filters'] ? this._activatedRoute.snapshot.queryParams['filters'].split('|') : [];

        if(!Boolean(queryParamsFilters.length)){

            return;
        }

        this.filterBuilder.resetFields();

        queryParamsFilters.forEach((queryParamsFilter: string): void => {

            const key: string = queryParamsFilter.split('[')[0];

            const operator: FilterOperator = queryParamsFilter.match(/\[(.*?)\]/)[1] as FilterOperator;

            const value: string = queryParamsFilter.split('=')[1];

            const filterField: FilterField = this.filterBuilder.getFieldByKey(`${this.prefixFilter}${key}`);

            switch(key){

                case 'society.id':

                    (filterField as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}${key}`, operator, value));

                    this._offerSearchService.updateFilter$.next({
                        key: key,
                        value: [parseInt(value)]
                    });

                    break;

                case 'locales':

                    (filterField as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}${key}`, operator, value));

                    this._offerSearchService.updateFilter$.next({
                        key: key,
                        value: [value]
                    });

                    break;
            }
        });

        this._storeSessionFilters();
    }

    private _storeSessionFilters(): void {

        const sessionFilters: OfferSearchSessionFilter[] = [
            {
                key: `promotions`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}promotions`).value || null
            },
            {
                key: `offerAdded`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`) ? this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`).value : null
            },
            {
                key: `createdAt`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value || null
            },
            {
                key: `onlineSale.enable`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value || null
            },
            {
                key: `giftVoucher.enable`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value || null
            },
            {
                key: `translations.name`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}translations.name`).value || null
            },
            {
                key: `reference`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}reference`).value || null
            },
            {
                key: `locales`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}locales`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            },
            {
                key: `date`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}date`).value || null
            },
            {
                key: `adult`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value || null
            },
            {
                key: `child`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value || null
            },
            {
                key: `locations.city`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}locations.city`).value || null
            },
            {
                key: `duration.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            },
            {
                key: `customerTypology.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}customerTypology.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            },
            {
                key: `attributes.attribute.destinations`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.destinations`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `attributes.attribute.accommodations`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.accommodations`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `attributes.attribute.restoration`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.restoration`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `attributes.attribute.themes`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.themes`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `attributes.attribute.activities`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.activities`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `attributes.attribute.region`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.region`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `attributes.attribute.types`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.types`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `providerSocieties.networkProviders.groupNetworkProvider.id`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}providerSocieties.networkProviders.groupNetworkProvider.id`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `society.networkOfferCreators.id`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}society.networkOfferCreators.id`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `duration.value`,
                value: 1,
                extraData: {
                    operator: this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.value`).operator
                }
            }
        ];

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}updatedAt`)) {

            sessionFilters.push({
                key: `updatedAt`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}updatedAt`).value || null
            });
        }


        if (this.filterBuilder.hasFilter(`${this.prefixFilter}published`)){

            sessionFilters.push({
                key: `published`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}published`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}providerSocieties.id`)){

            sessionFilters.push({
                key: `providerSocieties.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}providerSocieties.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}society.id`)){

            sessionFilters.push({
                key: `society.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.id`) as FilterCollectionField).fields.map((field: FilterField): number => {

                    return parseInt(field.value);
                })
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isOnAlert`)){

            sessionFilters.push({
                key: `isOnAlert`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}isOnAlert`).value || null
            });
        }

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}restrictedProviderSocieties.id`)){

            sessionFilters.push({
                key: `restrictedProviderSocieties.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}restrictedProviderSocieties.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}notInCatalog`)){

            sessionFilters.push({
                key: `notInCatalog`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isRestricted`)){

            sessionFilters.push({
                key: `isRestricted`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOption`)){

            sessionFilters.push({
                key: `permanentOption`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.id`)){

            sessionFilters.push({
                key: `permanentOptions.id`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`)){

            sessionFilters.push({
                key: `permanentOptions.offerPermanentOption.locales`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}responseType`)){

            sessionFilters.push({
                key: `responseType`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value || null
            });
        }

        this._offerSearchService.setSessionFilters(this.mode, sessionFilters);

        this._offerSearchService.refreshFiltersState$.next();
    }

    private _loadOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    public resetFilters(filterComponents: FilterComponent[], loadItems: boolean): void {

        this.filterBuilder.resetFields();

        filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        if(loadItems){

            this.loadItems(true);
        }
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public loadItems(resetOffset: boolean): void {

        this._storeSessionFilters();

        if (resetOffset){

            this.offerOffset = 0;
        }

        if (this.hasOneOfThisMode(['personnal-offers', 'offer-permanent-option-personnal-offers']) && this.user){

            this._offerService.getPaginationItemsSocietyAPI(this.user.society.id, this.itemsApiParams)
                .pipe(
                    map(this.mapPaginationApiResult)
                )
                .subscribe((items: Offer[]) => {

                    const offerCatalogs: OfferCatalog[] = this._offerCatalogService.selfItems.getValue();

                    if (!offerCatalogs.length) {

                        this._loadOfferCatalogs();
                    }

                    this.hydrateOffers(items, resetOffset);
                })
            ;
        }

        if (this.isMode('reservoir')){

            this._offerService.getPaginationItemsAPI(this.itemsApiParams)
                .pipe(
                    map(this.mapPaginationApiResult)
                )
                .subscribe((items: Offer[]) => {

                    this.hydrateOffers(items, resetOffset);
                })
            ;
        }

        if (this.hasOneOfThisMode(['channel', 'catalog', 'offer-permanent-option-catalog'])){

            this._offerCatalogService.getPaginationItemsAPI(this.itemsApiParams)
                .pipe(tap((pagination: Pagination<OfferCatalog>): void => {

                        if(resetOffset) {

                            this._offerCatalogService.selfItems.next([]);
                        }

                        const items: OfferCatalog[] = this._offerCatalogService.selfItems.getValue();

                        items.push(...pagination.items);

                        this._offerCatalogService.selfItems.next(items);

                    }),
                    map((pagination: Pagination<OfferCatalog>): Pagination<Offer> => {

                        return {
                            pagesCount: pagination.pagesCount,
                            totalItems: pagination.totalItems,
                            items: pagination.items.map((item: OfferCatalog): Offer => {

                                item.offer.dateEndHighlighting = item.dateEndHighlighting;

                                return item.offer;
                            })
                        };
                    }),
                    map(this.mapPaginationApiResult)
                )
                .subscribe((items: Offer[]): void => {

                    this.hydrateOffers(items, resetOffset);
                })
            ;
        }

        if(this.hasOneOfThisMode(['tender-personnal-offers', 'tender-proposed-offers', 'hashtag-reservoir', 'hashtag-catalog'])){

            this.loadItemsSourceCallback('list', this.itemsApiParams).pipe(map(this.mapPaginationApiResult))
                .subscribe((items: Offer[]): void => {

                    this.hydrateOffers(items, resetOffset);
                })
            ;
        }
    }

    public hydrateOffers(items: Offer[], reset: boolean): void {

        this.parts = this._offerListService.hydratePartsCallback(this.parts, items, reset);
    }

    public loadMoreOffers(): void {

        this.offerOffset++;

        this.loadItems(false);
    }

    public isMode(mode: ModeType): boolean {

        return this.mode === mode;
    }

    public hasOneOfThisMode(modes: ModeType[]): boolean {

        return modes.some((mode: ModeType) => {

            return this.isMode(mode);
        });

    }

    get sortedParts(): OfferPart[] {

        return this.parts.sort((a: OfferPart, b: OfferPart): number => {

            return a.position - b.position;
        });
    }

    get itemsApiParams(): string[] {

        const extrasData = this._offerSearchService.extrasData$.getValue();

        const params: string[] = [];

        let sort: string = `sort[updatedAt]=desc`;

        params.push(`page=${this.offerOffset + 1}`);

        params.push(`limit=${this.offerPerPage}`);

        // Adaptation du filtre sur la date de création (utilisé pour définir les offres "Nouveautés")

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}createdAt`)){

            const now: Moment = moment();

            now.subtract(30, 'days');

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value = now.format('YYYY-MM-DD HH:mm:ss');

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).operator = 'gte';

            } else {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value = null;
            }
        }

        // Vente en ligne

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}onlineSale.enable`)){

            if (!this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value  = null;
            }
            else{

                switch (this.mode){

                    case 'reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'offer-permanent-option-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'channel':

                        if(('isDistributor' in extrasData) && !extrasData['isDistributor']){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'hashtag-reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'hashtag-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;
                }
            }
        }

        // Bon cadeaux

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}giftVoucher.enable`)){

            if (!this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value  = null;
            }
            else{

                switch (this.mode){

                    case 'reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'offer-permanent-option-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'channel':

                        params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);

                        break;

                    case 'hashtag-reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'hashtag-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;
                }
            }
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}offerAdded`) && this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`).value) {

            const status: OfferCatalogStatusType = 'accepted';

            const statusFilter: TextFilterField = new TextFilterField('catalogs.status', 'eq', status);

            params.push(statusFilter.serialize);

            const catalogFilter: TextFilterField = new TextFilterField('catalogs.society.id', 'eq', this.user.society.id);

            params.push(catalogFilter.serialize);

        }

        // Pas dans le catalogue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}notInCatalog`)) {

            if(this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value) {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value = this.user.society.id;
            }
            else {
                this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value  = null;
            }
        }

        // Type de réponse

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}responseType`) && this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value) {

            const value: OfferSearchResponseType = this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value;

            if(value === 'addedInCatalog'){

                const status: OfferCatalogStatusType = 'accepted';

                const statusFilter: TextFilterField = new TextFilterField('catalogs.status', 'eq', status);

                params.push(statusFilter.serialize);

                const addedInCatalogFilter: TextFilterField = new TextFilterField('catalogs.society.id', 'eq', this.user.society.id);

                params.push(addedInCatalogFilter.serialize);
            }

            if(value === 'waiting'){

                const waitingFilter: TextFilterField = new TextFilterField(`${this.prefixFilter}notInCatalog`, 'eq', this.user.society.id);

                params.push(waitingFilter.serialize);
            }
        }

        // Offre ciblée

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isRestricted`)) {

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value) {

                if(this.isMode('channel')){

                    params.push(`offer.society.id[eq]=${this.user.society.id}`);
                }
            }
            else{

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value = null;
            }
        }

        // Option assurance

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOption`)) {

            const selectedOffers = this._offerSearchService.selectedOffers.getValue();

            if([null, undefined].includes(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value)) {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value = null;
            }
            else if(Boolean(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value)){

                if(selectedOffers.length){

                    params.push(...selectedOffers.map((selectedOffer: Offer): string => {

                        return `${this.prefixFilter}id[in][]=${selectedOffer.id}`;
                    }));
                }
                else{

                    params.push(`${this.prefixFilter}id[in][]=null`);
                }
            }
        }

        // Options assurances

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.id`)) {

            if([null, undefined].includes(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = null;
            }
            else if (Boolean(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value)){

                if(this.hasOneOfThisMode(['catalog', 'hashtag-catalog']) && this.hasRole('ROLE_OFFER_DISTRIBUTOR')) {

                    params.push(`permanentOptions.id[nnull]=1`);

                } else {

                    this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = 1;

                    this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).operator = 'nnull';
                }
            }
            else {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = null;
            }
        }

        // Options assurances en fonction de la langue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`)) {

            if([null, undefined].includes(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value = null;
            }
            else if (Boolean(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value)){

                // Filtre sur les options assurances

                const isDistributor: boolean = ('isDistributor' in extrasData) ? extrasData['isDistributor'] : false;

                if(isDistributor){

                    params.push(`permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`)
                }
                else{

                    params.push(`offer.permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`)
                }
            }
        }

        // Nombre d'adulte

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}adult`)) {

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value) {

                params.push(`${this.prefixFilter}adultMax[gte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value}`);

                params.push(`${this.prefixFilter}adultMin[lte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value}`);
            }
        }

        // Nombre d'enfant

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}child`)) {

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value) {

                params.push(`${this.prefixFilter}childMax[gte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value}`);

                params.push(`${this.prefixFilter}childMin[lte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value}`);
            }
        }

        // Offres sans nuitée / avec nuitée

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.value`).value = 1;

        // Prise en compte des filtres effectués par l'internaute

        if ( this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        // Filtre automatique sur le statut des offres

        if (this.hasOneOfThisMode(['catalog', 'channel', 'offer-permanent-option-catalog', 'hashtag-catalog'])){

            const status: OfferCatalogStatusType = 'accepted';

            const statusFilter: TextFilterField = new TextFilterField('status', 'eq', status);

            params.push(statusFilter.serialize);
        }

        // Filtre automatique pour ne pas avoir les offres qui appartiennent à la société dans le catalogue

        if(this.hasOneOfThisMode(['offer-permanent-option-catalog'])){

            params.push(`offer.society.id[ne]=${this.user.society.id}`);
        }

        // Filtre automatique sur la langue des offres

        if (this.locale && this.locale.value){

            const localeFilter: TextFilterField = new TextFilterField(`${this.prefixFilter}locales`, 'lk', this.locale.value);

            params.push(localeFilter.serialize);
        }

        // Filtre automatique sur la société des offres

        if (this.referenced && this.referenced.value){

            const societyFilter: TextFilterField = new TextFilterField(`${this.prefixFilter}society.id`, 'eq', this.user.society.id.toString());

            params.push(societyFilter.serialize);
        }

        if(this.allowBooking && this.allowGiftVoucher && this.allowRequest && this.allowOnlineSale) {

            if(this.allowGiftVoucher.value && !this.allowBooking.value && !this.allowRequest.value && !this.allowOnlineSale.value && this._offerSearchService.displayOnlyGiftVoucherOffer){

                params.push(`${this.prefixFilter}giftVoucher.enable[eq]=1`);

                params.push(`${this.prefixFilter}giftVoucher.isAvailableOnlineSale[eq]=1`);
            }
        }

        if(this._offerSearchService.displayOnlyOnlineSaleOffer){

            const isDistributor: boolean = ('isDistributor' in extrasData) ? extrasData['isDistributor'] : false;

            if(isDistributor){

                params.push(`permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`);

                params.push(`offer.onlineSale.enable[eq]=1`);
            }
            else{

                params.push(`offer.permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`);

                params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
            }
        }

        // Tri sur un hashtag

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}hashtag`) && this.filterBuilder.getFieldByKey(`${this.prefixFilter}hashtag`).value) {

            sort = `sort[${this.prefixFilter}hashtags.id]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}hashtag`).value}`;
        }

        params.push(sort);

        let splitParams: string[] = [];

        params.forEach((param: string): void => {

            splitParams.push(...param.split('&'));
        });

        // Nettoyage des données obsolètes

        splitParams = splitParams.filter((param: string): boolean => {

            const toDeleteConditions: boolean[] = [
                this.hasOneOfThisMode(['offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog']) && param.includes('permanentOption'),
                this.isMode('channel') && param.includes('permanentOptions.offerPermanentOption.locales[eq]'),
                this.hasOneOfThisMode(['catalog', 'hashtag-catalog']) && this.hasRole('ROLE_OFFER_DISTRIBUTOR') && param.includes(`${this.prefixFilter}permanentOptions.id`)
            ];

            const toDeleteParam: boolean = toDeleteConditions.some((condition: boolean): boolean => {

                return Boolean(condition);
            });

            return !toDeleteParam;
        });

        // Retour des données

        return splitParams.map((param: string): string => {

            switch(true){

                case (param.includes(`restrictedProviderSocieties.id`)):

                    param = param.replace(/restrictedProviderSocieties.id/g, 'providerSocieties.id')

                    break;
            }

            return param;
        });
    }

    get prefixFilter(): string {

        return this.hasOneOfThisMode(['catalog', 'channel', 'offer-permanent-option-catalog', 'hashtag-catalog']) ? 'offer.' : '';
    }

    get mapPaginationApiResult(): (data: Pagination<Offer>) => Offer[] {

        return (data: Pagination<Offer>) => {

            this.totalOffers = data.totalItems;
            this._offerSearchService.totalFilteredOffers=this.totalOffers;

            return data.items;
        };
    }

    get hasMoreOffers(): boolean {

        const countItems = this.parts.reduce((count: number, part: OfferPart): number => count + part.items.length, 0);

        return countItems < this.totalOffers;
    }

    get getLocale(): string {

        return this.locale?.value ? this.locale.value : this.user.locale;
    }
}
