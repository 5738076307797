<div class="ligne_form">
    <div class="provider_description">
        {{ 'offer.provider.description.value' | translate }}
    </div>
</div>

<!-- Sélection -->

<div class="ligne_form">
    <div class="row marges" *ngIf="selectedProviders && selectedProviders.length">
        <div class="col y_items_center">
            <div class="selected_offers">
                <span>{{ 'offer.provider.selection.list.value' | translate }} :</span>
                <ul>
                    <li *ngFor="let provider of selectedProviders">{{ provider.name }}<mat-icon (click)="removeProviderSelection(provider)">close</mat-icon></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Recherche -->

<div class="ligne_form" [formGroup]="parentProviderSocieties">
    <div class="form-group">
        <mat-select placeholder="{{'offer.form.fields.provider.actions.choose.value' | translate}}" [formControlName]="formName" [compareWith]="compareProviders" (closed)="handleClosure()" multiple="true">

            <mat-option>
                <ngx-mat-select-search #nameFilter [formControl]="optionFilterNameCtrl"
                                       [placeholderLabel]="'offer.form.fields.provider.actions.search.name.value' | translate"
                                       [noEntriesFoundLabel]="'offer.form.fields.provider.actions.search.result.no.value' | translate"
                ></ngx-mat-select-search>
            </mat-option>
            <mat-option disabled="true">{{'offer.form.fields.provider.actions.options.value' | translate}}</mat-option>
            <ng-container *ngIf="(filteredOptions | async) as options">
                <mat-option *ngFor="let option of options" [value]="option">
                    {{ option.name }} | {{ getMailingAddress(option)?.address }}, {{ getMailingAddress(option)?.zipcode }} {{ getMailingAddress(option)?.city }}, {{ getCountryTranslation(option)?.name }}
                </mat-option>
            </ng-container>
        </mat-select>
    </div>
</div>

<div class="row marges ligne_form">
    <div class="col y_items_center">
        <div class="form-group">
            <div class="message-help">
                <mat-icon>info</mat-icon> <span [innerHTML]="('offer.provider.referencing.description.value' | translate)"></span>
            </div>
        </div>
    </div>
</div>

<div class="row marges ligne_form">
    <div class="col y_items_center">
        <div class="ck-content">

            <p class="titre_h3" [innerHTML]="'offer.provider.remuneration.title.value' | translate"></p>

            <p [innerHTML]="'offer.provider.remuneration.description.value' | translate"></p>
        </div>
    </div>
</div>

