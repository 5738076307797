<div #swiper class="swiper-container">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let picture of translatedItemFields.pictures">
            <ng-container>
                <img [src]="offer | offerPictureSource: picture : 'rectangle' : 800 : 90" alt="image" />
            </ng-container>
        </div>
    </div>
    <div #swiperPagination class="swiper-pagination"></div>
    <div #swiperButtonPrev class="swiper-button-prev"></div>
    <div #swiperButtonNext class="swiper-button-next"></div>
</div>
