import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard  {

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService
    ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if(this._authenticationService.isAuthenticated){

            return true;
        }

        this._router.navigate(['/signin']);

        return false;
    }
}
