import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SubscriptionService} from "../services/subscription.service";
import {Subscription} from "../models/subscription";
import {User} from "@core/shared/models/user";
import {SocietyService} from "@core/shared/services/society.service";
import {Society} from "@core/shared/models/society";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionSocietyItemsResolver  {

    constructor(
        private _subscriptionService: SubscriptionService,
        private _societyService: SocietyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {
            this._societyService.getItemSelfAPI().subscribe((society:Society) => {
                this._subscriptionService.getItemSubscriptionBySocietyWithoutPagination(society.id).subscribe(resolve, reject)
            },reject)
        });

    }
}
