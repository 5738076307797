<form [formGroup]="parentForm" (submit)="submit.emit()" [class]="(parentForm.get('type').value === 'generic') ? 'gift-voucher-generique-tab' : 'gift-voucher-multilingue-tab'">
    <div class="form_part" >
        <!-- Type du template (Générique ou multilingue)  -->

        <div class="row marges ligne_form" >
            <div class="col_12">
                <div class="form-group">
                    <label>{{'giftVoucher.template.form.fields.type.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="'multilingual'" formControlName="type" class="form-control" id="isGenericNo">
                        <label for="isGenericNo">{{'giftVoucher.template.form.fields.type.multilingual.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="'generic'" formControlName="type" class="form-control" id="isGenericYes">
                        <label for="isGenericYes">{{'giftVoucher.template.form.fields.type.generic.value' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>


        <!--  Sélection des langues pour le multilingue  -->

        <ng-container *ngIf="parentForm.get('type').value === 'multilingual'">
            <div class="ligne_form row marges">
                <div class="col_6">
                    <div class="form-group">
                        <mat-select formControlName="locales" multiple>
                            <mat-option  *ngFor="let locale of locales$ | async" [value]="locale.id">{{ locale.label | translate }}</mat-option>
                        </mat-select>
                        <mat-label class="required">{{ 'collaborator.list.fields.locale.value' | translate }}</mat-label>
                        <mat-error *ngIf="parentForm.get('locales').invalid && (parentForm.get('locales').dirty || parentForm.get('locales').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="parentForm.get('locales').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

        <!--  Nom du bon cadeau  -->

        <ng-container *ngIf="translationBuilder.items.length">

            <div class="ligne_form row marges " >
                <div class="col_12">
                    <div class="mat-tab-translations">
                        <ng-container formArrayName="translations">
                            <mat-tab-group [@.disabled]="true">
                                <div *ngFor="let locale of selectedLocales">
                                    <mat-tab    label="{{ translationBuilder.getLocaleLabel(locale) | translate }}">
                                        <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                            <div class="row marges ligne_form">
                                                <div class="col_12">
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="label"
                                                               id="label" required="required" appInputCapitalize>
                                                        <label for="label">{{ 'offer.form.fields.offerName.value' | translate }}</label>
                                                        <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('label').errors && (translationBuilder.getItemControlByLocale(locale).get('label').dirty || translationBuilder.getItemControlByLocale(locale).get('label').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('label').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('label').errors.pattern">{{ 'offer.form.control.name.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-tab>
                                </div>
                            </mat-tab-group>

                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>

    <!-- Type de bon cadeau -->

    <div class="form_part">

        <p class="title_part">{{'giftVoucher.template.type.value' | translate}}</p>

        <div class="row marges ligne_form">

            <div class="col_12">
                <div class="form-group">

                    <!-- E-Bon cadeau  -->

                    <div class="checkbox inline">
                        <input type="checkbox" id="isDematerialized" formControlName="isDematerialized">
                        <label for="isDematerialized">{{ 'giftVoucher.template.form.fields.dematerialized.value' | translate }}</label>
                    </div>

                    <!-- Bon cadeau physique  -->

                    <div class="checkbox inline">
                        <input type="checkbox" id="isPhysical" formControlName="isPhysical" >
                        <label for="isPhysical">{{ 'giftVoucher.template.form.fields.physical.value' | translate }}</label>
                    </div>
                </div>

                <ng-container *ngIf="!this.parentForm.get('validType').value">
                    <div class="form-group">
                        <mat-error>
                            <div class="invalid-feedback">
                                <div>{{ 'channel.giftVoucher.errors.one_must_be_selected.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </ng-container>

            </div>
        </div>

    </div>

    <!-- Image -->

    <ng-container *ngIf="translationBuilder.items.length">

        <div class="form_part">

            <p class="title_part">{{'giftVoucher.template.picture.value' | translate}}</p>

            <div class="ligne_form row marges">
                <div class="col_12">
                    <div class="mat-tab-translations">
                        <ng-container formArrayName="translations">
                            <mat-tab-group [@.disabled]="true">
                                <div *ngFor="let locale of selectedLocales">
                                    <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                        <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                            <div class="ligne_form row">
                                                <div class="col_content">
                                                    <div class="form-group add_picture">
                                                        <label>{{ 'offer.form.action.program.add.image.value' | translate }}</label>
                                                        <form-field-image [config]="templateFileConfig" [form]="translationBuilder.getItemControlByLocale(locale).get('picture').get('image')" (imageAdded)="translationPictureCopyright.readOnly = false" (imageDeleted)="translationPictureCopyright.readOnly = true"></form-field-image>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ligne_form row">
                                                <div class="col_6">
                                                    <div class="form-group" formGroupName="picture">
                                                        <input #translationPictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="copyright_template" required="required" [readOnly]="true">
                                                        <label for="copyright_template">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                        <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').errors && (translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').dirty || translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').touched)" class="no_padding">
                                                            <div class="invalid-feedback ">
                                                                <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('picture').get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </mat-tab>
                                </div>
                            </mat-tab-group>

                        </ng-container>
                    </div>
                </div>
            </div>

        </div>

    </ng-container>

</form>
