<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="panel_title">
        <div class="row marges">
            <div class="col y_items_center">
                <div>
                    <p class="title">{{ 'offer.period.create.action.value' | translate }}</p>
                </div>
            </div>

            <div class="col_content y_items_center">
                <button class="btn_cta btn_lines btn_little" type="button" (click)="close.emit()">
                    {{ 'cancel.value' | translate }}
                </button>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value' | translate }}
                </button>
            </div>

        </div>
    </div>

    <div class="form_part">

        <!-- Période -->

        <p class="title_part">{{ 'period.value' | translate}}</p>

        <div class="row marges ligne_form">

            <div class="col_12">

                <div class="form-group date_range">
                    <label>{{'period.value' | translate}}</label>
                    <mat-date-range-input [rangePicker]="periodPicker" [min]="minDate">
                        <input class="datetimepicker" matStartDate readonly [placeholder]="'filter.dateInterval.start.value' | translate" formControlName="dateStart" (click)="periodPicker.open()">
                        <input class="datetimepicker" matEndDate  readonly [placeholder]="'filter.dateInterval.end.value' | translate" formControlName="dateEnd" (click)="periodPicker.open()">
                    </mat-date-range-input>
                    <mat-date-range-picker #periodPicker></mat-date-range-picker>
                    <div class="row marges">
                        <div class="col_6">
                            <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('dateStart').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                        <div class="col_6">
                            <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('dateEnd').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row marges ligne_form">

            <div class="col_12">

                <!-- Jours exclus -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{'excludedDay.plural.value' | translate}}</label>
                            <div class="checkbox inline" *ngFor="let day of days">
                                <input type="checkbox" id="day-{{day.index}}" [value]="day.index" (change)="handleExcludedDay($event)">
                                <label for="day-{{day.index}}">{{ day.label | translate }}</label>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Type de période -->

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <label>{{'period.type.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="false" formControlName="isAvailable" class="form-control" id="isAvailableNo">
                        <label for="isAvailableNo">{{ 'period.type.closed.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="true" formControlName="isAvailable" class="form-control" id="isAvailableYes">
                        <label for="isAvailableYes">{{ 'period.type.opened.value' | translate}}</label>
                    </div>
                    <mat-error *ngIf="form.get('isAvailable').errors && (form.get('isAvailable').dirty || form.get('isAvailable').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('isAvailable').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>
    </div>

</form>
