<ng-container *ngIf="parentForm.get('locales').value.length">

    <div class="ligne_form" *ngIf="programsForm.controls.length > limitProgram">
        <mat-error>
            <div class="invalid-feedback">
                <div>{{ 'offer.form.action.program.error.value' | translate: { nbPrograms: (programsForm.controls.length - limitProgram) } }}</div>
            </div>
        </mat-error>
    </div>

    <ng-container *ngIf="programsForm.controls.length">

        <div class="ligne_form collection">

            <ng-container [formGroup]="parentForm">

                <ng-container formArrayName="programs">

                    <mat-accordion>
                        <mat-expansion-panel class="mat-expansion-panel-spacing" *ngFor="let _ of programsForm.controls; let i = index" [formGroupName]="indexAsString(i)">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span>{{ ('offer.program.index.value' | translate: { index: i + 1 }) }}</span>
                                    <span class="{{ programsForm.controls[i].valid ? 'valid' : 'invalid' }}"></span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class="ligne_form removable_item">

                                <div class="mat-tab-translations">

                                    <ng-container formArrayName="translations">

                                        <mat-tab-group [@.disabled]="true">
                                            <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                                <mat-tab label="{{ getTranslationBuilder(i).getLocaleLabel(locale) | translate }}">
                                                    <ng-container [formGroupName]="getTranslationBuilder(i).getItemIndexByLocale(locale)">

                                                        <!-- Titre -->

                                                        <div class="ligne_form row">
                                                            <div class="col_12">
                                                                <div class="form-group">
                                                                    <input appInputCapitalize type="text" value="" placeholder=" " class="form-control" formControlName="title" id="offer-program-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}--title" required="required">
                                                                    <label for="offer-program-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}--title">{{ 'offer.form.fields.title.value' | translate }}</label>
                                                                    <mat-error *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('title').errors && (getTranslationBuilder(i).getItemControlByLocale(locale).get('title').dirty || getTranslationBuilder(i).getItemControlByLocale(locale).get('title').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Description -->

                                                        <div class="ligne_form row">
                                                            <div class="col_12">
                                                                <div class="form-group">
                                                                    <label>{{ 'offer.location.description.value' | translate }} *</label>
                                                                    <app-ckeditor [form]="getTranslationBuilder(i).getItemControlByLocale(locale)" [config]="descriptionEditorConfig"></app-ckeditor>
                                                                    <div class="form-group col_content y_items_center">
                                                                        <span class="message-help">
                                                                            <mat-icon class="tooltip_icon">info</mat-icon>
                                                                            {{ 'offer.descriptionProgram.info.value' | translate:{ maxCharacters: descriptionEditorConfig.attrs.maxLength } }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                       <ng-container *ngIf="parentForm.get('hasProgramsPictures').value && isTranslationPictureType('dissociated')">

                                                           <!-- Visuel -->

                                                           <div class="ligne_form row">
                                                               <div class="col_content">
                                                                   <div class="form-group add_picture">
                                                                       <label>{{ 'offer.form.action.program.add.image.value' | translate }}</label>
                                                                       <form-field-image [config]="programsFileConfig" [form]="getTranslationBuilder(i).getItemControlByLocale(locale).get('picture').get('image')" (imageAdded)="programTranslationPictureCopyright.readOnly = false" (imageDeleted)="programTranslationPictureCopyright.readOnly = true"></form-field-image>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div class="ligne_form row">
                                                               <div class="col_6">
                                                                   <div class="form-group" formGroupName="picture">
                                                                       <input #programTranslationPictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="copyright_offer" required="required" [readOnly]="true">
                                                                       <label for="copyright_offer">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                                       <mat-error *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('picture').get('copyright').errors && (getTranslationBuilder(i).getItemControlByLocale(locale).get('picture').get('copyright').dirty || getTranslationBuilder(i).getItemControlByLocale(locale).get('picture').get('copyright').touched)">
                                                                           <div class="invalid-feedback">
                                                                               <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('picture').get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                           </div>
                                                                       </mat-error>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                       </ng-container>

                                                    </ng-container>
                                                </mat-tab>
                                            </div>
                                        </mat-tab-group>

                                        <ng-container *ngIf="parentForm.get('hasProgramsPictures').value && isTranslationPictureType('common')">

                                            <br>

                                            <ng-container [formGroupName]="0">

                                                <!-- Visuel -->

                                                <div class="ligne_form row">
                                                    <div class="col_content">
                                                        <div class="form-group add_picture">
                                                            <label>{{ 'offer.form.action.program.add.image.value' | translate }}</label>
                                                            <form-field-image [config]="programsFileConfig" [form]="getTranslationBuilder(i).itemsControl.at(0).get('picture').get('image')" (imageAdded)="programTranslationPictureCopyright.readOnly = false" (imageDeleted)="programTranslationPictureCopyright.readOnly = true"></form-field-image>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ligne_form row">
                                                    <div class="col_6">
                                                        <div class="form-group" formGroupName="picture">
                                                            <input #programTranslationPictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="copyright_offer" required="required" [readOnly]="true">
                                                            <label for="copyright_offer">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                            <mat-error *ngIf="getTranslationBuilder(i).itemsControl.at(0).get('picture').get('copyright').errors && (getTranslationBuilder(i).itemsControl.at(0).get('picture').get('copyright').dirty || getTranslationBuilder(i).itemsControl.at(0).get('picture').get('copyright').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getTranslationBuilder(i).itemsControl.at(0).get('picture').get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>

                                        </ng-container>

                                    </ng-container>
                                </div>

                                <!-- Suppression d'un programme -->

                                <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="deleteProgram(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>

                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                </ng-container>

            </ng-container>

        </div>

    </ng-container>

    <div class="row marges ligne_form">
        <div class="col_12">
            <button class="btn_cta btn_little" type="button" (click)="addProgram();openNewPanel();" [disabled]="programsForm.controls.length >= limitProgram">
                {{ 'offer.form.action.program.add.value' | translate }}
            </button>
        </div>
    </div>

</ng-container>
