import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {Moment} from "moment";
import Calendar from "js-year-calendar";
import 'js-year-calendar/locales/js-year-calendar.fr';
import {FilterBuilder} from "@core/shared/models/filter";
import {UserService} from "@core/shared/services/user.service";
import {OfferDateService} from "@core/shared/services/offer/offer-date.service";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {DATE_FORMAT} from "@app/data";
import {map} from "rxjs/operators";
import {OfferDate} from "@core/shared/models/offer/offer-date";
import {User} from "@core/shared/models/user";
import {Access} from "@core/shared/models/access";
import {OfferAvailabilityCalendarDataSource, OfferAvailabilityCalendarDatesInterval} from "@core/shared/models/offer/offer-availability/offer-availability-calendar";
import {parsePrice} from "@core/shared/utils/price";
import {OfferPricePublicType} from "@core/shared/models/offer/offer-price-public";
import CalendarDayEventObject from "js-year-calendar/dist/interfaces/CalendarDayEventObject";

@Component({
    selector: 'app-core-offer-availability-calendar',
    templateUrl: './offer-availability-calendar.component.html',
    styleUrls: ['./offer-availability-calendar.component.scss']
})
export class OfferAvailabilityCalendarComponent implements OnInit, AfterViewInit {

    @Input() offer: Offer;

    @Output() dateClicked: EventEmitter<{ date: Moment, offerDateId: number }> = new EventEmitter<{ date: Moment, offerDateId: number }>();

    @ViewChild('calendar', { static: true }) calendarRef: ElementRef<HTMLDivElement>;

    public datesInterval: OfferAvailabilityCalendarDatesInterval = {
        start: null,
        end: null
    };

    public calendar: Calendar<OfferAvailabilityCalendarDataSource>;

    public filterBuilder: FilterBuilder;

    public priceType: ('HT' | 'TTC') = 'TTC';

    constructor(
        private _userService: UserService,
        private _offerDateService: OfferDateService,
        private _translateService: TranslateService
    ) {
    }

    ngOnInit() {

        this._initFilterBuilder();
    }

    ngAfterViewInit() {

        this._initCalendar();
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this.refreshCalendar(false);
        };
    }

    private _initCalendar(): void {

        this.calendar = new Calendar<OfferAvailabilityCalendarDataSource>(this.calendarRef.nativeElement, {
            language: this._translateService.currentLang,
            weekStart: 1,
            style: 'custom',
            loadingTemplate: null,
            numberMonthsDisplayed: 2,
            startDate: moment().startOf('month').toDate(),
            // @ts-ignore
            dataSource: (data: { startDate: Date, endDate: Date }): Promise<DataSource[]> => {

                this.datesInterval = {
                    start: moment(data.startDate),
                    end: moment(data.endDate)
                };

                return this.dataSourcePromise;
            },
            customDayRenderer: (element: HTMLDivElement): void => {

                element.classList.add('day-label');
            },
            customDataSourceRenderer: (element: HTMLDivElement, currentDate: Date, events: OfferAvailabilityCalendarDataSource[]): void => {

                const event: OfferAvailabilityCalendarDataSource|null = events[0];

                if(event.isClosed){

                    element.parentElement.insertAdjacentHTML('beforeend', `
                        <div class="infos">
                            <p class="closed">${ this._translateService.instant('offer.date.closed.value') }</p>
                        </div>
                    `);
                }
                else{

                    element.parentElement.insertAdjacentHTML('beforeend', `
                        <div class="infos">
                            ${ this.hasAccessOnlineSale && this.filterBuilder.getFieldByKey('totalAllocatedStock').value ? `<p><img src="assets/images/total-stock-icon.svg" alt="info">${ event.unlimitedStock ? '<span class="unlimited">∞</span>' : (event.totalAllocatedStock || '-') }</p>` : '' }
                            ${ this.hasAccessOnlineSale && this.filterBuilder.getFieldByKey('totalRemainingStock').value ? `<p><img src="assets/images/remaining-stock-icon.svg" alt="info">${ event.unlimitedStock ? '<span class="unlimited">∞</span>' : `<span class="${ event.totalRemainingStock < 0 ? 'negative' : '' }">` + (event.totalRemainingStock || '-') + '</span>' }</p>` : '' }                            ${ this.isSelectedOfferPricePublic('adult') && this.filterBuilder.getFieldByKey('adultPrice').value ? `<p><img src="assets/images/adult-icon.svg" alt="info">${ this.getParsedDataSourcePrice(event, 'adult') }</p>` : '' }
                            ${ this.isSelectedOfferPricePublic('child') && this.filterBuilder.getFieldByKey('childPrice').value ? `<p><img src="assets/images/child-icon.svg" alt="info">${ this.getParsedDataSourcePrice(event, 'child') }</p>` : '' }
                            ${ this.hasAccessOnlineSale && this.filterBuilder.getFieldByKey('releaseDate').value ? `<p><img src="assets/images/release-date-icon.svg" alt="info">${ event.releaseDate || '-' }</p>` : '' }
                        </div>
                    `);

                }
            },
            clickDay: (calendarEvent: CalendarDayEventObject<OfferAvailabilityCalendarDataSource>): void => {

                this.dateClicked.emit({
                    date: moment(calendarEvent.date),
                    offerDateId: calendarEvent.events.length ? calendarEvent.events[0].id : null
                });
            }
        });
    }

    public setNumberMonthsDisplayed(numberMonthDisplayed: number): void {

        this.calendar.setNumberMonthsDisplayed(numberMonthDisplayed);

        if(this.isYearView){

            this.calendar.setStartDate(moment().startOf('year').toDate());
        }
        else{

            this.calendar.setStartDate(moment().startOf('month').toDate());
        }
    }

    public refreshCalendar(reloadSource: boolean): void {

        if(reloadSource){

            this.calendar.setStartDate(this.datesInterval.start.clone().toDate());
        }
        else{

            this.calendar.render();
        }
    }

    public getNumberMonthsDisplayedElementClasses(numberMonthDisplayed: number): {[p: string]: boolean} {

        return {
            'actif' : this.calendar?.getNumberMonthsDisplayed() === numberMonthDisplayed
        };
    }

    public getParsedDataSourcePrice(dataSource: OfferAvailabilityCalendarDataSource, publicType: 'adult' | 'child'): string {

        switch (true){

            case (publicType === 'adult' && this.priceType === 'HT'):

                return (dataSource.adultPriceHT !== null) ? `${ parsePrice(dataSource.adultPriceHT) } ${ this.offer.currency.symbol }` : '-';

            case (publicType === 'adult' && this.priceType === 'TTC'):

                return (dataSource.adultPriceTTC !== null) ? `${ parsePrice(dataSource.adultPriceTTC) } ${ this.offer.currency.symbol }` : '-';

            case (publicType === 'child' && this.priceType === 'HT'):

                return (dataSource.childPriceHT !== null) ? `${ parsePrice(dataSource.childPriceHT) } ${ this.offer.currency.symbol }` : '-';

            case (publicType === 'child' && this.priceType === 'TTC'):

                return (dataSource.childPriceTTC !== null) ? `${ parsePrice(dataSource.childPriceTTC) } ${ this.offer.currency.symbol }` : '-';
        }
    }

    public isSelectedOfferPricePublic(type: OfferPricePublicType): boolean {

        return this.offer.publics.includes(type);
    }

    get dataSourcePromise(): Promise<OfferAvailabilityCalendarDataSource[]>{

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('date', 'gte', this.datesInterval.start.format(DATE_FORMAT)));

        filterBuilder.addField(new TextFilterField('date', 'lte', this.datesInterval.end.format(DATE_FORMAT)));

        return this._offerDateService.getItemsAPI(this.offer.id, filterBuilder.serializedFilters).pipe(
            map((items: OfferDate[]): OfferAvailabilityCalendarDataSource[] => {

                return items.map((item: OfferDate): OfferAvailabilityCalendarDataSource => {

                    return Object.assign({}, item, {
                        startDate: item.date.toDate(),
                        endDate: item.date.toDate()
                    });
                });
            })
        ).toPromise();
    }

    get isYearView(): boolean {

        return this.calendar.getNumberMonthsDisplayed() === 12;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get hasAccessOnlineSale(): boolean {

        return this.currentUser.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }
}
