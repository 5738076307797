<div class="collection">

    <ng-container [formGroup]="parentInterests" *ngIf="interestsLen !== 0">

        <ng-container formArrayName="interests">

            <mat-accordion>
                <mat-expansion-panel class="mat-expansion-panel-spacing" *ngFor="let _ of interestsForm.controls; let i = index" [formGroupName]="indexAsString(i)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>{{ getItemTranslationControl(i)?.get('label')?.value?.length ? getItemTranslationControl(i).get('label').value : ('offer.interest.index.value' | translate: { index: i + 1 }) }}</span>
                            <span class="{{ interestsForm.controls[i].valid ? 'valid' : 'invalid' }}"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="getTranslationBuilder(i)?.items.length">
                        <div class="ligne_form removable_item">

                            <div class="mat-tab-translations">
                                <ng-container formArrayName="translations">

                                    <mat-tab-group [@.disabled]="true">
                                        <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                            <mat-tab label="{{ getTranslationBuilder(i).getLocaleLabel(locale) | translate }}">
                                                <ng-container [formGroupName]="getTranslationBuilder(i).getItemIndexByLocale(locale)">

                                                    <!-- Intérêts -->
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="label" id="offer-interest-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}-label"
                                                               [limit]="100" [placeholderElement]="formControlInterest" appInputCountLimit required="required">
                                                        <label for="offer-interest-{{i}}-translation-{{ getTranslationBuilder(i).getItemIndexByLocale(locale) }}-label">{{ 'offer.form.fields.interests.value' | translate }}</label>
                                                        <div class="message">
                                                            <span class="message-help">
                                                                <mat-icon class="tooltip_icon">info</mat-icon>
                                                                {{ 'form.validation.error.maxLength' | translate: { value: 100 } }}
                                                            </span>
                                                            <span>
                                                                <span #formControlInterest class="form-control-input" ></span>
                                                                {{ 'form.input.type' | translate }}
                                                            </span>
                                                        </div>
                                                        <mat-error *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('label').errors && (getTranslationBuilder(i).getItemControlByLocale(locale).get('label').dirty || getTranslationBuilder(i).getItemControlByLocale(locale).get('label').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('label').errors.pattern">{{ 'offer.form.control.interests.error.pattern.value' | translate }}</div>
                                                                <div *ngIf="getTranslationBuilder(i).getItemControlByLocale(locale).get('label').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>

                                                </ng-container>
                                            </mat-tab>
                                        </div>
                                    </mat-tab-group>

                                </ng-container>
                            </div>

                            <!-- Suppression d'un intérêt -->
                            <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="deleteInterest(i)">
                                <mat-icon>delete</mat-icon>
                            </button>

                        </div>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>

        </ng-container>
    </ng-container>

    <div class="ligne_form">
        <button class="btn_cta btn_little" type="button" (click)="addInterest()" [disabled]="limitInterest === interestsLen || interestsLen > limitInterest">
            {{ 'offer.form.fields.interests.actions.add.value' | translate }}
        </button>
    </div>

    <div class="ligne_form" *ngIf="interestsLen > limitInterest">
        <mat-error>
            <div class="invalid-feedback">
                <div>{{getErrorMaxLabel()}}</div>
            </div>
        </mat-error>
    </div>
</div>


