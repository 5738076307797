<h1 mat-dialog-title>{{ 'translation.select.value' | translate }}</h1>
<mat-dialog-content>
    <div class="form-group">
        <mat-select [(ngModel)]="item">
            <mat-option>-</mat-option>
            <mat-option *ngFor="let localeItem of items" [value]="localeItem">
                {{ localeItem.label | translate }}
            </mat-option>
        </mat-select>
        <mat-label>{{ 'translation.select.value' | translate }}</mat-label>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" [disabled]="!item" (click)="select.emit(this.item)">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>

