<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'administrator.list.management.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">
                <button (click)="addItem()" class="btn_cta btn_little btn_add">{{ 'administrator.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="resetFilters()" mat-icon-button>
                        <mat-icon>loop</mat-icon>
                    </button>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('firstName')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.lastName.value' | translate" [key]="'firstName'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('lastName')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.firstName.value' | translate" [key]="'lastName'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('email')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'user.email.value' | translate" [key]="'email'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <!--Actions-->

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                <mat-cell *matCellDef="let element; let i = index">
                    <button mat-button (click)="editItem(element.id)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button (click)="openDeleteItemDialog(element.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!-- Nom -->

            <ng-container matColumnDef="lastName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.lastName.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).lastName}}</td>
            </ng-container>

            <!-- Prénom -->

            <ng-container matColumnDef="firstName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.firstName.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).firstName}}</td>
            </ng-container>

            <!-- Email -->

            <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'user.email.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).email}}</td>
            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Liste des éléments -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>
