<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'transporter.update.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'transporter.own.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit" >
                    {{ 'form.submit.action.value' | translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

<!--    <mat-tab-group>-->
<!--        <mat-tab [label]="'offer.generalData.value' | translate">-->
<!--            <ng-container *ngTemplateOutlet="generalData"></ng-container>-->
<!--        </mat-tab>-->
<!--    </mat-tab-group>-->
    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>

        <ng-container *ngFor="let tabItem of tabItems">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

    </mat-tab-group>


</form>


<!-- Données générales -->

<ng-template #generalData [formGroup]="form">

    <div class="row marges ligne_form">

        <!-- Nom transporteur  -->
        <div class="col_6">
            <div class="form-group">
                <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                <label for="name">{{'form.user.fields.transporterName.value' | translate}}</label>
                <mat-error *ngIf="form.get('name').errors && (form.get('name').dirty || form.get('name').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>

    </div>

</ng-template>

<ng-template #shippingData [formGroup]="form">

    <div class="row marges ligne_form">

        <div class="col_12">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        {{ 'transporter.delivery.location.value' | translate }}
                    </div>
                </div>
            </div>

            <div class="ligne_form row marges">

                <div class="col_12 col_md_6 y_items_center">

                    <!-- Tous les pays  -->

                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" class="form-control" id="allCountries" [(ngModel)]="allCountries" [ngModelOptions]="{ standalone: true }" (ngModelChange)="resetCountries()">
                            <label for="allCountries">{{'transporter.country.plural.all.value' | translate}}</label>
                        </div>
                    </div>

                </div>

            </div>

            <div class="row marges ligne_form">

                <!-- Pays -->

                <div class="col_12 col_md_6">

                    <div class="form-group">
                        <mat-select (closed)="handleClosure()" formControlName="countries"  placeholder="{{ 'form.user.fields.country.value' | translate }}" [(ngModel)]="value" (selectionChange)="selectionChange($event)" [disabled]="allCountries" [compareWith]="compareCountryCode" multiple="true">

                            <mat-option>
                                <ngx-mat-select-search #nameFilter [formControl]="optionFilterNameCtrl"
                                                       [placeholderLabel]="'transporter.form.fields.country.actions.search.name.value' | translate"
                                                       [noEntriesFoundLabel]="'transporter.form.fields.country.actions.search.result.no.value' | translate"
                                ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option disabled="true">{{'offer.form.fields.provider.actions.options.value' | translate}}</mat-option>
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.code">
                                {{ translationService.getFallbackTranslation(option.translations).name  }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <div class="row marges ligne_form">

        <div class="col_12">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        {{ 'transporter.delivery.date.value' | translate }}
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <div class="col_6 col_md_3">

                    <!-- Date de début -->

                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="deliveryTimeStart" id="deliveryTimeStart" required="required">
                        <label for="deliveryTimeStart">{{'transporter.delivery.date.start.value' | translate}}</label>
                        <mat-error *ngIf="form.get('deliveryTimeStart').errors && (form.get('deliveryTimeStart').dirty || form.get('deliveryTimeStart').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('deliveryTimeStart').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('deliveryTimeStart').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                <div *ngIf="form.get('deliveryTimeStart').errors.isGreaterThanEnd">{{ 'transporter.form.fields.error.greater_than_end.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>

                <div class="col_6 col_md_3">

                    <!-- Date de fin -->

                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="deliveryTimeEnd"  id="deliveryTimeEnd" required="required" >
                        <label for="deliveryTimeEnd">{{'transporter.delivery.date.end.value' | translate}}</label>
                        <mat-error *ngIf="form.get('deliveryTimeEnd').errors && (form.get('deliveryTimeEnd').dirty || form.get('deliveryTimeEnd').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('deliveryTimeEnd').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('deliveryTimeEnd').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                <div *ngIf="form.get('deliveryTimeEnd').errors.isLowerThanStart">{{ 'transporter.form.fields.error.lower_than_start.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="row marges ligne_form">

        <div class="col_12">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        {{ 'transporter.delivery.pricing.value' | translate }}
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <!-- Devise -->

                <div class="col_12 col_md_6">
                    <div class="form-group">
                        <select class="form-control" id="currency" formControlName="currency" required="required">
                            <option value=""></option>
                            <option *ngFor="let currency of currencies" [value]="currency.id">
                                {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.code }})
                            </option>
                        </select>
                        <label class="required" for="currency">{{ 'currency.value' | translate }}</label>
                        <mat-error *ngIf="form.get('currency').errors && (form.get('currency').dirty || form.get('currency').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form" formGroupName="prices">

                <!-- Montant TVA -->

                <div class="col_3">
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="vatPercent" id="vatPercent" required="required" appInputTextParseMarkup [control]="priceControl.get('vatPercent')">
                        <label for="vatPercent">{{'tva.percent.value' | translate}}</label>
                        <mat-error *ngIf="priceControl.get('vatPercent').errors && (priceControl.get('vatPercent').dirty || priceControl.get('vatPercent').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="priceControl.get('vatPercent').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="priceControl.get('vatPercent').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <!-- Montant HT -->

                <div class="col_3">
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="priceHT" id="priceHT" required="required" appInputTextParsePrice [control]="priceControl.get('priceHT')">
                        <label for="priceHT">{{'form.user.fields.priceHt.value' | translate}}</label>
                        <mat-error *ngIf="priceControl.get('priceHT').errors && (priceControl.get('priceHT').dirty || priceControl.get('priceHT').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="priceControl.get('priceHT').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="priceControl.get('priceHT').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

                <!-- Montant TTC -->

                <div class="col_3">
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="priceTTC" id="priceTTC" required="required" disabled>
                        <label for="priceTTC">{{'form.user.fields.priceTtc.value' | translate}}</label>
                        <mat-error *ngIf="priceControl.get('priceTTC').errors && (priceControl.get('priceTTC').dirty || priceControl.get('priceTTC').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="priceControl.get('priceTTC').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="priceControl.get('priceTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </div>

        </div>

    </div>


</ng-template>


<ng-template #pictureData [formGroup]="form">

    <!-- Logo -->

    <div class="row marges ligne_form">
        <div class="col_3">
            <div class="form-group add_picture">
                <label class="required">{{ 'transporter.form.action.add.image.value' | translate }}</label>
                <form-field-image [config]="transporterFileConfig" [form]="form.get('image')" ></form-field-image>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #commentData [formGroup]="form">

    <!-- Commentaire multilingues -->

    <div class="row marges ligne_form">

        <div class="col_12">

            <div class="mat-tab-translations">

                <ng-container formArrayName="translations">

                    <mat-tab-group [@.disabled]="true">

                        <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">

                            <mat-tab label="{{translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">

                                <ng-container [formGroupName]="translationBuilder.indexAsString(it)">
                                    <div class="row marges ligne_form">

                                        <div class="col_12">

                                            <!-- Commentaire -->

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="comment" id="comment" >
                                                <label for="comment">{{ 'transporter.form.fields.comment.value' | translate }}</label>
                                                <mat-error *ngIf="getTranslation(it).get('comment').errors && (getTranslation(it).get('comment').dirty || getTranslation(it).get('comment').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="getTranslation(it).get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                            </mat-tab>

                        </div>

                    </mat-tab-group>

                </ng-container>

            </div>

        </div>

    </div>

</ng-template>
