import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {Observable, of} from "rxjs";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";

@Component({
    selector: 'app-page-society-provider-send-request-dialog',
    templateUrl: './page-society-provider-send-request-dialog.component.html',
    styleUrls: ['./page-society-provider-send-request-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class PageSocietyProviderSendRequestDialogComponent implements OnInit {

    @Output() submit: EventEmitter<object> = new EventEmitter<object>();

    public locales$: Observable<LocaleItem[]>;

    public withEmailCopy: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<PageSocietyProviderSendRequestDialogComponent>,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this._initLocales();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
            emailCopy: [null, [(control: UntypedFormControl) => {

                if(!this.form || !this.withEmailCopy){

                    return null;
                }

                if(!control.value || control.value.length < 1) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }

                if(REGEX_EMAIL.test(control.value)){

                    return null;
                }

                return {
                    'isPatternInvalid': {
                        valid: false
                    }
                };
            }]],
            locale: [null, [Validators.required]],
            comment: [''],
        });

        this.formService.submitCallback = (): void => {

            const data: object = this.form.value;

            this.submit.emit(data);
        };
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
