import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {GiftVoucherPeriodCalendarComponent} from "@core/components/gift-voucher/gift-voucher-period/gift-voucher-period-calendar/gift-voucher-period-calendar.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {UserService} from "@core/shared/services/user.service";
import {Society} from "@core/shared/models/society";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'app-core-page-gift-voucher-period',
    templateUrl: './page-gift-voucher-period.component.html',
    styleUrls: ['./page-gift-voucher-period.component.scss']
})
export class PageGiftVoucherPeriodComponent implements OnInit {

    @ViewChild(GiftVoucherPeriodCalendarComponent) periodCalendar: GiftVoucherPeriodCalendarComponent;

    public giftVoucher: GiftVoucher;

    public affectedGiftVouchers$: Observable<GiftVoucher[]>;

    public periodCreateConfiguration: { enable: boolean } = {
        enable: false
    };

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _giftVouchersService: GiftVoucherService,
        private _userService: UserService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { giftVoucher: GiftVoucher }): void => {

            this.giftVoucher = data.giftVoucher;

            this._loadAffectedGiftVouchers();
        });
    }

    private _loadAffectedGiftVouchers(): void {

        const society: Society = this._userService.currentUser.getValue().society;

        const params: string[] = [
            `offerData.id[eq]=${this.giftVoucher.offerData.id}`
       ];

        this.affectedGiftVouchers$ = this._giftVouchersService.getItemsAPI(society.id, params);
    }

    public redirectToList(): void {

        this._router.navigate(['account/gift-voucher/list']);
    }

    public openCreatePeriodPanel(): void {

        this.closePanels();

        document.querySelector('body').classList.add('has_panel_open');

        setTimeout((): void => {

            this.periodCreateConfiguration = {
                enable: true
            };
        });
    }

    public closePanels(): void {

        document.querySelector('body').classList.remove('has_panel_open');

        this.periodCreateConfiguration = {
            enable: false
        };
    }

    public handlePeriodCreated(): void {

        this._snackBar.open(this._translateService.instant('offer.period.update.success.value'), this._translateService.instant('notification.close.action.value'), {
            duration: 5000
        });

        this.closePanels();

        this.periodCalendar.refreshCalendar();
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
