<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <ng-container *ngIf="isAdmin()">
                    <h1 class="title">{{ 'booking.admin.list.management.value' | translate }}</h1>
                </ng-container>
                <ng-container *ngIf="!isAdmin()">
                    <h1 class="title">{{ 'booking.list.management.value' | translate }}</h1>
                </ng-container>
            </div>
            <div class="col_content y_items_center" *ngIf="hasAccessExportBooking()">
                <button (click)="exportBookings()" class="btn_cta btn_little">{{ 'booking.list.booking.export.value' | translate }}</button>
            </div>
            <ng-container *ngIf="isAdmin()">
                <button (click)="adminExportBookings()" class="btn_cta btn_little">{{ 'booking.list.booking.adminExport.value' | translate }}</button>
            </ng-container>
        </div>
        <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
            <div class="subscription_information">
                <p [innerHTML]="'booking.comissioning.description.value' | translate"></p>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="!hasData">
        <div class="text_content no_result"><p><strong>{{ 'booking.list.noresult.value' | translate }}</strong></p></div>
    </ng-container>

    <ng-container *ngIf="hasData">
        <div class="content_table">
            <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

                <!-- Filtres -->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <ng-container *ngIf="isAdmin()" [matColumnDef]="getFilterColumnDef('ownerSociety.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.ownerSociety.name.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('status')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.status.creator.value' | translate" [key]="'status'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="status"></app-core-select-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" [matColumnDef]="getFilterColumnDef('distributorStatus')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.status.distributor.value' | translate" [key]="'distributorStatus'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="status"></app-core-select-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" [matColumnDef]="getFilterColumnDef('paymentId')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.paymentId.value' | translate" [key]="'paymentId'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('type')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.type.value' | translate" [key]="'type'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="types" ></app-core-select-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('giftVoucher')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.giftVoucher.value' | translate" [key]="'giftVoucher.id'" [operator]="'null'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="giftVoucherStatus"></app-core-select-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('giftVoucher.code')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.giftVoucher.code.value' | translate" [key]="'giftVoucher.code'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('website')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.website.value' | translate" [key]="'channel.marketplace.website'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleSociety.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.responsibleSocietyName.value' | translate" [key]="'responsibleSociety.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleUser.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.responsibleUser.value' | translate" [key]="'responsibleUser.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleUser.service')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.responsibleUserService.value' | translate" [key]="'responsibleUser.service'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('society.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.channel.owner.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('channel.translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.channel.name.value' | translate" [key]="'channel.translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('offerData.translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.offerName.value' | translate" [key]="'offerData.translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('offerData.societyName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.offer.societyName.value' | translate" [key]="'offerData.societyName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('reference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.reference.value' | translate" [key]="'reference'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_DISTRIBUTOR'])" [matColumnDef]="getFilterColumnDef('referenceResponsibleSociety')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.referenceResponsibleSociety.value' | translate" [key]="'referenceResponsibleSociety'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" [matColumnDef]="getFilterColumnDef('customer.lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.customerName.value' | translate" [key]="'customer.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" [matColumnDef]="getFilterColumnDef('customer.society')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.customer.society.value' | translate" [key]="'customer.society'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('composition.dateStart')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'composition.dateStart'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('composition.dateEnd')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'composition.dateEnd'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" [matColumnDef]="getFilterColumnDef('composition.totalNetPriceTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" [matColumnDef]="getFilterColumnDef('composition.totalNetPriceHT')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('composition.totalPriceTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('composition.totalPriceHT')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('commission')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('commissionTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('amountCommissionTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('isPaidCommission')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="view(element.id)">
                            <mat-icon *ngIf="getModel(element).access === 'read'">visibility</mat-icon>
                            <mat-icon *ngIf="getModel(element).access === 'write'">edit</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Liste des éléments -->

                <ng-container *ngIf="isAdmin()" matColumnDef="ownerSociety.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.ownerSociety.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society ? getModel(element).society.name : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.status.creator.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).status ? ('booking.status.' + getModel(element).status + '.value' | translate) : '' }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" matColumnDef="distributorStatus">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.status.distributor.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).distributorStatus ? ('booking.status.' + getModel(element).distributorStatus + '.value' | translate) : '' }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" matColumnDef="paymentId">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.paymentId.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).paymentId }}</td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'booking.type.' + (isOnlineSale(getModel(element))  ? 'onlineSale' : getModel(element).type) + '.value' | translate}} </td>
                </ng-container>

                <ng-container matColumnDef="giftVoucher">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.giftVoucher.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{  'booking.read.fields.giftVoucher.isGiftVoucher.' + (getModel(element).giftVoucher ? 'yes' : 'no') + '.value' | translate }}</td>
                </ng-container>

                <ng-container matColumnDef="giftVoucher.code">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.giftVoucher.code.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ (getModel(element).giftVoucher ? getModel(element).giftVoucher.code : null) }}</td>
                </ng-container>

                <ng-container matColumnDef="website">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.website.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ (getModel(element).channel && getModel(element).channel.marketplace) ? getModel(element).channel.marketplace.website : null }}</td>
                </ng-container>

                <ng-container matColumnDef="responsibleSociety.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.responsibleSocietyName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).responsibleSociety?.name}}</td>
                </ng-container>

                <ng-container matColumnDef="responsibleUser.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.responsibleUser.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).responsibleUser ? getResponsibleFullName(getModel(element).responsibleUser) : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="responsibleUser.service">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.responsibleUserService.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).responsibleUser ? getModel(element).responsibleUser.service : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="society.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.channel.owner.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).society?.name}}</td>
                </ng-container>

                <ng-container matColumnDef="channel.translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.channel.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).channel) ? translationService.getFallbackTranslation(getModel(element).channel.translations).name : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="offerData.translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.offerName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).offerData) ? translationService.getFallbackTranslation(getModel(element).offerData.translations).name : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="offerData.societyName">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.offer.societyName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).offerData) ? getModel(element).offerData.societyName : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.reference.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).reference}}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_DISTRIBUTOR'])" matColumnDef="referenceResponsibleSociety">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.referenceResponsibleSociety.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).referenceResponsibleSociety}}</td>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" matColumnDef="customer.lastName">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.customerName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).customer?.lastName}}</td>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" matColumnDef="customer.society">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.customer.society.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).customer?.society}}</td>
                </ng-container>

                <ng-container matColumnDef="composition.dateStart">
                    <th mat-header-cell *matHeaderCellDef>{{ 'booking.list.fields.dateStart.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).composition?.dateStart | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <ng-container matColumnDef="composition.dateEnd">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.dateEnd.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).composition?.dateEnd | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <ng-container  *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" matColumnDef="composition.totalNetPriceTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.netPriceTtc.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ parsePrice(getModel(element).composition.totalNetPriceTTC, getModel(element).composition.currency) }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" matColumnDef="composition.totalNetPriceHT">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.netPriceHt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ parsePrice(getModel(element).composition.totalNetPriceHT, getModel(element).composition.currency) }}</td>
                </ng-container>

                <ng-container matColumnDef="composition.totalPriceTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.priceTtc.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{parsePrice(getModel(element).composition.totalPriceTTC, getModel(element).composition.currency)}}</td>
                </ng-container>

                <ng-container matColumnDef="composition.totalPriceHT">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.priceHt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{parsePrice(getModel(element).composition.totalPriceHT, getModel(element).composition.currency)}}</td>
                </ng-container>

                <ng-container matColumnDef="commission">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.commission.ht.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).commission ? parsedMarkup(getModel(element).commission * 100) : 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="commissionTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.commission.ttc.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).commissionTTC ? parsedMarkup(getModel(element).commissionTTC * 100) : 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="amountCommissionTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.amountCommissionTTC.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).amountCommissionTTC ? parsePrice(getModel(element).amountCommissionTTC, getModel(element).composition.currency) : 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="isPaidCommission">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.isPaidCommission.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ (getModel(element).isPaidCommission ? 'yes' : 'no') + '.value' | translate }}</td>
                </ng-container>

                <!-- Entêtes de colonne -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <!-- Filtres -->

                <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

                <!-- Liste des éléments -->

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!--Pagination-->

        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
    </ng-container>

    <div class="deletable_information" *ngIf="!isAdmin()">
        <p [innerHTML]="'booking.list.info.deletable.value' | translate"></p>
    </div>

</div>
