import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IButton, IButtonConfig, IButtonTemplate} from "./button";
import {ButtonService} from "./button.service";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: [
        '../../element.component.scss'
    ],
})
export class ButtonComponent implements OnInit {

    @Input() content: IButton;

    @Input() title: string;

    @Input() help: string;

    @Input() enableLink: boolean;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<IButton> = new EventEmitter<IButton>();

    config: IButtonConfig;

    configInitialized: boolean = false;

    /**
     * @param _translateService
     * @param _buttonService
     * @param _snackbar
     */
    constructor(
        private _translateService: TranslateService,
        private _buttonService: ButtonService,
        private _snackbar: MatSnackBar
    ) {}

    ngOnInit() {

        this._buttonService.setApiUrl(this.apiUrl);

        if (!this.content) {

            this.content = {
                title: this.title || null,
                link: null,
                targetBlank: false,
                template: null
            };
        }

        if(this._buttonService.configLoaded()){

            this.config = this._buttonService.getConfig();

            this.configInitialized = true;
        }
        else{

            this._buttonService.loadConfig().subscribe((data: any) => {

                    data = Object.assign(data, {
                            help: data.help,
                            enableLink: data.enableLink
                        });

                        this._buttonService.setConfig(data);

                        this.config = this._buttonService.getConfig();

                        this.configInitialized = true;


                },
                (error : HttpErrorResponse) => {
                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                        this._snackbar.open(error.error, messages['action.close']);
                    });
                }
            );
        }
    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {IButton} button
     */
    setContent(button: IButton) {
        this.update.emit(button); // On met à jour le contenu de l'élément
    }

    /**
     * Utilisé pour afficher les templates disponibles pour le bouton de visualisation du bouton
     *
     * @param {IButtonTemplate} first
     * @param {IButtonTemplate} second
     *
     * @returns {boolean}
     */
    compareByOptionClass(first: IButtonTemplate, second: IButtonTemplate) {

        if (!first && !second)
            return true;

        return first && second && first.class === second.class;
    }

    /**
     * Retourne la classe pour la prévisualisation du bouton
     *
     * @param {IButtonTemplate} currentTemplate
     * @returns {string}
     */
    getPreviousButtonClass(currentTemplate: IButtonTemplate) {

        if (!currentTemplate)
            return '';

        const match = this._buttonService.templates.filter((template: IButtonTemplate) => {
            return currentTemplate.class === template.class;
        }).shift();

        return match.class || '';
    }

    get templates(): IButtonTemplate[] {

        return this._buttonService.templates;
    }
}
