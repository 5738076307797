<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'relationship.plural.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="createItemDialog()" class="btn_cta btn_little btn_add">{{ 'relationship.creation.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">

        <!-- Gestion des onglets -->

        <mat-tab-group #tabGroup>
            <mat-tab [label]="'offerCreator.plural.network.plural.value' | translate" #offerCreatorTab>
                <ng-container *ngTemplateOutlet="offerCreator"></ng-container>
            </mat-tab>
            <mat-tab [label]="'provider.plural.network.plural.value' | translate" #providerTab>
                <ng-container *ngTemplateOutlet="provider"></ng-container>
            </mat-tab>
            <mat-tab [label]="'group.plural.value' | translate" #societyGroupTab>
                <ng-container *ngTemplateOutlet="societyGroup"></ng-container>
            </mat-tab>
            <mat-tab [label]="'affiliation.link.plural.value' | translate" #affiliationTab>
                <ng-container *ngTemplateOutlet="affiliation"></ng-container>
            </mat-tab>
        </mat-tab-group>

    </div>

</div>

<ng-template #offerCreator>

    <app-core-network-offer-creator-list></app-core-network-offer-creator-list>

</ng-template>

<ng-template #provider>

    <app-core-offer-group-network-provider-list></app-core-offer-group-network-provider-list>

</ng-template>

<ng-template #societyGroup>

    <app-core-society-group-list></app-core-society-group-list>

</ng-template>

<ng-template #affiliation>

    <app-core-registration-affiliation-list></app-core-registration-affiliation-list>

</ng-template>
