import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "@core/shared/services/api.service";
import {Observable, of} from "rxjs";
import {User} from "@core/shared/models/user";
import { TemplateGiftVoucher, TemplateGiftVoucherTranslation } from "@core/shared/models/gift-voucher/template-gift-voucher";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-template-gift-voucher-form',
    templateUrl: './template-gift-voucher-form.component.html',
    styleUrls: ['./template-gift-voucher-form.component.scss']
})
export class TemplateGiftVoucherFormComponent implements OnInit {

    @Input() user: User;

    @Input() item: TemplateGiftVoucher;

    @Input() parentForm: UntypedFormGroup;

    @Input() translationBuilder: TranslationBuilder;

    @Output() submit: EventEmitter<void> = new EventEmitter<void>();

    @Input() locales: LocaleItem[];

    public locale: string;

    public locales$: Observable<LocaleItem[]>;

    public templateFileConfig: ImageConfig;

    public isValidType: boolean = false;

    constructor(
        public translationService: TranslationService,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _apiService: ApiService,
    ) {}

    ngOnInit(): void {

        this.locale = this._translateService.currentLang;

        this._initFileConfig();

        this._initLocales();

        this._initEvent();

        this._initTranslations();

        if (this.item) {

            if(!this.item.translations.length){

                this.locale = this.item.translations[0].locale;
            }

            this._initData();
        }
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _initFileConfig(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg'}).subscribe((): void => {

            this.templateFileConfig = {
                id: 'image',
                gallery_context: 'template_gift_voucher_picture',
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: this._translateService.instant('giftVoucher.template.form.image.help.value'),
                options: {
                    enableTitle: false,
                    enableSubtitle: false,
                    enableAlt: false,
                    enableLink: false,
                    enableTargetBlank: false
                }
            };
        });
    }

    private _initData(): void {

        const data: TemplateGiftVoucher = {...this.item};

        const locales = this.localeItems;

        const items = data.translations.filter((template) => {

            return locales.includes(template.locale);
        });

        this.parentForm.patchValue(Object.assign(data, {locales: locales}));

        this.translationBuilder.updateItems(locales);

        items.forEach((translation: TemplateGiftVoucherTranslation): void => {

            const control: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(translation.locale);

            control.patchValue(Object.assign({}, {
                id: translation.id,
                label: translation.label,
                picture: {
                    image: translation.picture.image,
                    copyright: translation.picture.copyright
                }
            }));
        });

    }

    private _initEvent(): void {

        this.parentForm.get('type').valueChanges.subscribe((value: 'multilingual' | 'generic') => {

            if (value === 'generic') {

                const locales: string[] = [this.user.locale];

                this.parentForm.get('locales').patchValue(locales);

                this.translationBuilder.updateItems(locales);

            } else {

                this.parentForm.get('locales').patchValue([]);

                this.translationBuilder.updateItems([]);
            }
        });

        this.parentForm.get('isDematerialized').valueChanges.subscribe(() => {

            this._updateTypeValidation();

        });

        this.parentForm.get('isPhysical').valueChanges.subscribe(() => {

            this._updateTypeValidation();

        });

        this.parentForm.get('locales').valueChanges.subscribe((): void => {

            this._handleTranslationControls();
        });
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.parentForm.get('locales').value;

        this.translationBuilder.updateItems(locales);

        if(this.item){

            this.item.translations.forEach((translation: TemplateGiftVoucherTranslation): void => {

                const existingItem: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(translation.locale);

                if(existingItem){

                    this.translationBuilder.getItemControlByLocale(translation.locale).patchValue({
                        id: translation.id
                    });
                }
            });
        }
    }

    private _initTranslations(): void {

        this.translationBuilder.form = this.parentForm;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                id: [],
                label: ['', [Validators.required, Validators.pattern(/^(.{0,60})$/)]],
                picture: this._formBuilder.group({
                    copyright: [],
                    image: this._formBuilder.group({
                        image: ['', [Validators.required]]
                    })
                })
            });
        };

    }

    private _updateTypeValidation(): void {

        const controls: AbstractControl[] = [
            this.parentForm.get('isDematerialized'),
            this.parentForm.get('isPhysical'),
        ];

        this.isValidType = controls.some((childControl: AbstractControl): boolean => {

            return childControl.value;
        });

        this.parentForm.get('validType').patchValue(this.isValidType);
    }

    get localeItems(): string[] {

        if (this.item.type === 'generic') {

            return [ this.translationService.getFallbackTranslation(this.item.translations).locale ];
        }

        return this.item.translations.map((translation: TemplateGiftVoucherTranslation) => {
            return translation.locale;
        });

    }

    get selectedLocales(): string[] {

        if (!this.parentForm || !this.parentForm.get('locales')) {

            return [];
        }

        return this.parentForm.get('locales').value;
    }
}
