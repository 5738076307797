<h1 mat-dialog-title>{{ 'offer.period.create.value' | translate }}</h1>
<mat-dialog-content>
    <app-core-promotion-period-create [promotion]="promotion" [configuration]="configuration" (create)="create.emit()"></app-core-promotion-period-create>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="submit()">{{ 'submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
