import {Component, Input, ViewChild} from '@angular/core';
import {OfferCreatorFilterComponent, OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferCreatorModeType, OfferCreatorSearchSessionFilter} from "@core/shared/models/offer/offer-creator-search";
import {FilterBuilder, FilterField} from "@core/shared/models/filter";
import {map} from "rxjs/operators";
import {OfferDurationType} from "@core/shared/models/offer/offer-duration";
import {Pagination} from "@core/shared/models/pagination";
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";
import {Observable} from "rxjs";

@Component({
    selector: 'app-core-offer-creator-search',
    templateUrl: './offer-creator-search.component.html',
    styleUrls: ['./offer-creator-search.component.scss']
})
export class OfferCreatorSearchComponent {

    @Input() mode: OfferCreatorModeType;

    @Input() loadItemsSourceCallback: (params?: string[]) => Observable<Pagination<Society>>;

    @ViewChild(OfferCreatorFilterComponent, { static: true }) offerCreatorFilterComponent: OfferCreatorFilterComponent;

    public currentUser: User = null;

    public itemOffset: number = 0;

    public itemPerPage: number = 21;

    public items: Society[] = [];

    public sourceLoaded: boolean = false;

    constructor(
        private _translateService: TranslateService,
        private _userService: UserService,
        private _offerCreatorSearchService: OfferCreatorSearchService
    ) {}

    ngOnInit() {

        this.currentUser = this._userService.currentUser.value;

        this._initFilterBuilder();

        setTimeout((): void => {

            if(this._offerCreatorSearchService.sessionEnabled){

                if (this._offerCreatorSearchService.hasSessionFilters) {

                    this._initFiltersValues();
                }
                else{

                    this._initDefaultFiltersValues();
                }
            }

            this._loadItems(true);
        });
    }

    private _initFilterBuilder(): void {

        this.filterBuilder.filterCallback = (): void => {

            this._loadItems(true);
        };

        this._offerCreatorSearchService.resetFilter$.subscribe((): void => {

            this.filterBuilder.resetFields();

            this._loadItems(true);
        });
    }

    private _initDefaultFiltersValues(): void {

        this.currentUser.marketplacePreferences.forEach((item: MarketplacePreference): void => {

            switch (item.filter){

                case 'society.networkOfferCreators.id':

                    const ids: number[] = (item.value as string).replace(/[\[\]]/g, '').split(',').map((item: string): number => { return parseInt(item); });

                    ids.forEach((id: number): void => {

                        (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.NetworkOfferCreator) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.NetworkOfferCreator, 'in', id));
                    });

                    break;
            }
        });
    }

    private _initFiltersValues(): void {

        this.filterBuilder.resetFields();

        // Destinations

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Destination) && this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Destination)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Destination).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Destination) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.Destination, 'in', value));
            });
        }

        // Cibles

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Target) && this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Target)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Target).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Target) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.Target, 'in', value));
            });
        }

        // Réseaux de créateurs d'offres

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.NetworkOfferCreator) && this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.NetworkOfferCreator)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.NetworkOfferCreator).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.NetworkOfferCreator) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.NetworkOfferCreator, 'in', value));
            });
        }

        // Type d'offres

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.OfferType) && this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.OfferType)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.OfferType).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.OfferType) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.OfferType, 'in', value));
            });
        }

        // Avec / Sans abonnement

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.HasSubscription) && this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.HasSubscription)){

            (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.HasSubscription) as FilterField).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.HasSubscription).value;
        }

        // Nom

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Name) && this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Name)){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Name).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Name).value;
        }

        // Présent dans mon catalogue

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.InCatalog) && this._offerCreatorSearchService.hasSessionFilter((OfferCreatorFilterKey.InCatalog))){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.InCatalog).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.InCatalog).value;
        }

        // Labellisé RSE

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.HasRseLabel) && this._offerCreatorSearchService.hasSessionFilter((OfferCreatorFilterKey.HasRseLabel))){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.HasRseLabel).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.HasRseLabel).value;
        }

        // Créateurs pas encore sélectionnés

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.NotSelected) && this._offerCreatorSearchService.hasSessionFilter((OfferCreatorFilterKey.NotSelected))){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.NotSelected).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.NotSelected).value;
        }

        // Type de réponse

        if (this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.ResponseType) && this._offerCreatorSearchService.hasSessionFilter((OfferCreatorFilterKey.ResponseType))){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.ResponseType).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.ResponseType).value;
        }
    }

    private _storeSessionFilters(): void {

        const sessionFilters: OfferCreatorSearchSessionFilter[] = [];

        // Destinations

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Destination)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.Destination,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.Destination) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Cibles

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Target)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.Target,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.Target) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Réseaux de créateurs d'offres

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.NetworkOfferCreator)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.NetworkOfferCreator,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.NetworkOfferCreator) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Type d'offres

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.OfferType)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.OfferType,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.OfferType) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            });
        }

        // Nom

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Name)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.Name,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Name).value || null
            });
        }

        // Présent dans mon catalogue

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.InCatalog)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.InCatalog,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.InCatalog).value || null
            });
        }

        // Labellisé RSE

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.HasRseLabel)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.HasRseLabel,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.HasRseLabel).value || null
            });
        }

        // Créateurs pas encore sélectionnés

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.NotSelected)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.NotSelected,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.NotSelected).value || null
            });
        }

        // Avec / Sans abonnement

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.HasSubscription)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.HasSubscription,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.HasSubscription).value ?? null
            });
        }

        // Type de réponse

        if(this._offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.ResponseType)){

            sessionFilters.push({
                key: OfferCreatorFilterKey.ResponseType,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.ResponseType).value || null
            });
        }

        // Enregistrement de la session

        this._offerCreatorSearchService.sessionFilters = sessionFilters;
    }

    private _loadItems(resetOffset: boolean): void {

        if(this._offerCreatorSearchService.sessionEnabled){

            this._storeSessionFilters();
        }

        if (resetOffset) {

            this.itemOffset = 0;
        }

        this.sourceLoaded = false;

        this.loadItemsSourceCallback(this.itemsApiParams).pipe(map(this.mapItemPaginationApiResult))
            .subscribe((items: Society[]): void => {

                this.hydrateItems(items, resetOffset);

                this.sourceLoaded = true;
            })
        ;
    }

    public hydrateItems(items: Society[], reset: boolean): void {

        if (reset) {

            this.items = [];
        }

        this.items.push(...items);
    }

    public removeFilter(field: FilterField): void {

        const key: OfferCreatorFilterKey = field.key as OfferCreatorFilterKey;

        switch (key){

            case OfferCreatorFilterKey.Destination:
            case OfferCreatorFilterKey.Target:
            case OfferCreatorFilterKey.NetworkOfferCreator:
            case OfferCreatorFilterKey.OfferType:

                const fields: FilterCollectionField[] = this.filterBuilder.getFieldsByKey(key) as FilterCollectionField[];

                fields.forEach((collectionField: FilterCollectionField): void => {

                    const fieldIndex: number = collectionField.fields.findIndex((item: ArrayFilterField): boolean => {

                        return item.value === field.value;
                    });

                    if(fieldIndex >= 0){

                        collectionField.fields.splice(fieldIndex, 1);
                    }
                });

                break;

            default:

                this.filterBuilder.resetFieldValueByKey(key);
        }

        if(this._offerCreatorSearchService.sessionEnabled){

            this._storeSessionFilters();
        }

        this.offerCreatorFilterComponent.initFilters();

        this.filterBuilder.filter();
    }

    public loadMoreItems(): void {

        this.itemOffset++;

        this._loadItems(false);
    }

    get itemsApiParams(): string[] {

        const params: string[] = [];

        params.push(`sort[name]=asc`);

        params.push(`page=${this.itemOffset + 1}`);

        params.push(`limit=${this.itemPerPage}`);

        params.push(...this.mapFilterParams);

        params.push(...this._offerCreatorSearchService.additionalFilterParams$.getValue());

        return params;
    }

    get mapFilterParams(): string[] {

        const params: string[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: OfferCreatorFilterKey = field.key as OfferCreatorFilterKey;

            switch (key){

                case OfferCreatorFilterKey.OfferType:

                    const values: OfferDurationType[] = (field as FilterCollectionField).fields.map((offerTypeField: FilterField): OfferDurationType => {

                        return offerTypeField.value;
                    });

                    values.forEach((value: string): void => {

                        params.push((this._offerCreatorSearchService.filterPrefix ? `${this._offerCreatorSearchService.filterPrefix}.` : '') + `offers.duration.type[lkin][]=${ value }`);
                    });

                    break;

                case OfferCreatorFilterKey.InCatalog:

                    if(Boolean(field.value)){

                        params.push((this._offerCreatorSearchService.filterPrefix ? `${this._offerCreatorSearchService.filterPrefix}.` : '') + `${key}[${field.operator}]=${this.currentUser.society.id}`);
                    }

                    break;

                case OfferCreatorFilterKey.HasRseLabel:

                    if(Boolean(field.value)){

                        params.push((this._offerCreatorSearchService.filterPrefix ? `${this._offerCreatorSearchService.filterPrefix}.` : '') + field.serialize);
                    }

                    break;

                case OfferCreatorFilterKey.HasSubscription:

                    params.push((this._offerCreatorSearchService.filterPrefix ? `${this._offerCreatorSearchService.filterPrefix}.` : '') + `subscriptions.subscription.maxOffer[${field.value ? 'gt' : 'eq'}]=0`);

                    break;

                case OfferCreatorFilterKey.NotSelected:

                    if(Boolean(field.value)){

                        const metadata: { selectedOfferCreators: Society[] } = this._offerCreatorSearchService.getFilterMetadata(OfferCreatorFilterKey.NotSelected);

                        metadata.selectedOfferCreators.forEach((item: Society): void => {

                            params.push((this._offerCreatorSearchService.filterPrefix ? `${this._offerCreatorSearchService.filterPrefix}.` : '') +`id[andnin][]=${item.id}`);
                        });
                    }

                    break;

                case OfferCreatorFilterKey.ResponseType:

                    params.push(`status[${field.operator}]=${field.value}`);

                    break;

                default:

                    params.push((this._offerCreatorSearchService.filterPrefix ? `${this._offerCreatorSearchService.filterPrefix}.` : '') + field.serialize);
            }
        });

        return params;
    }

    get currentFilters(): { field: FilterField, formattedValue: string }[] {

        const items: { field: FilterField, formattedValue: string }[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: OfferCreatorFilterKey = field.key as OfferCreatorFilterKey;

            switch (key){

                case OfferCreatorFilterKey.Destination:
                case OfferCreatorFilterKey.Target:
                case OfferCreatorFilterKey.NetworkOfferCreator:
                case OfferCreatorFilterKey.OfferType:

                    const fields: FilterField[] = (field as FilterCollectionField).fields;

                    const choices: { id: number, label: string }[] = (field as FilterCollectionField).choices;

                    fields.forEach((field: FilterField): void => {

                        const choice: { id: number, label: string } = choices.find((choice: { id: number, label: string }): boolean => {

                            return choice.id === field.value;
                        });

                        items.push({
                            field: field,
                            formattedValue: choice ? choice.label : null
                        });
                    });

                    break;

                case OfferCreatorFilterKey.InCatalog:

                    if(Boolean(field.value)){

                        items.push({
                            field: field,
                            formattedValue: this._translateService.instant('offerCreator.inCatalog.mine.value')
                        });
                    }

                    break;

                case OfferCreatorFilterKey.HasRseLabel:

                    if(Boolean(field.value)){

                        items.push({
                            field: field,
                            formattedValue: this._translateService.instant('rse.labelled.value')
                        });
                    }

                    break;

                case OfferCreatorFilterKey.HasSubscription:

                    items.push({
                        field: field,
                        formattedValue: this._translateService.instant(field.value ? `society.subscriptions.withSubscription.value` : `society.subscriptions.withoutSubscription.value`)
                    });

                    break;

                case OfferCreatorFilterKey.NotSelected:

                    if(Boolean(field.value)){

                        items.push({
                            field: field,
                            formattedValue: this._translateService.instant('offerCreator.plural.notSelected.yet.value')
                        });
                    }

                    break;

                case OfferCreatorFilterKey.ResponseType:

                    items.push({
                        field: field,
                        formattedValue: `${this._translateService.instant('offerCreator.filter.responseType.value')} : ${this._translateService.instant(`response.type.${ field.value }.value`)}`
                    });

                    break;

                default:

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant(`offerCreator.filter.${key}.value`) } : ${ field.isCollection ? (field as FilterCollectionField).fields.map((field: FilterField): string => {

                            return field.value.toString();

                        }).join(', ') : field.value }`
                    });
            }
        });

        return items;
    }

    get mapItemPaginationApiResult(): (data: Pagination<Society>) => Society[] {

        return (data: Pagination<Society>) => {

            this._offerCreatorSearchService.totalOfferCreators = data.totalItems;

            return data.items;
        };
    }

    get hasMoreItems(): boolean {

        return this.items.length < this.totalItems;
    }

    get filterBuilder(): FilterBuilder {

        return this._offerCreatorSearchService.filterBuilder;
    }

    get totalItems(): number {

        return this._offerCreatorSearchService.totalOfferCreators;
    }
}
