import {AbstractControl} from "@angular/forms";

export const maxLengthArrayValidator = (max: number) => {

    return (control: AbstractControl): { [key: string]: any } => {

        if (control.value.length <= max) {

            return null;
        }

        return {
            'maxLengthArray': {
                valid: false
            }
        };
    }
};
