import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";

@Injectable({
    providedIn: 'root'
})
export class OfferCreatorItemResolver {

    constructor(
        private _offerCreatorService: OfferCreatorService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._offerCreatorService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
