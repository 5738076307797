import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {Tender} from "@core/shared/models/tender";
import {TenderOfferCreator} from "@core/shared/models/tender/tender-offer-creator";
import {Offer} from "@core/shared/models/offer";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {OfferAvailability} from "@core/shared/models/offer/offer-availability";
import {OfferFirstDateAvailability} from "@core/shared/models/offer/offer-first-date-availability";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";

@Injectable({
    providedIn: 'root'
})
export class TenderService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getItemAPI(id: number, params?: string[]): Observable<Tender> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<Tender>(url,{
            headers: headers
        });
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Tender>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Tender>>(url, {
            headers: headers
        });
    }

    public getPaginationSocietyItemsAPI(societyId: number, params?: string[]): Observable<Pagination<Tender>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/tenders`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Tender>>(url, {
            headers: headers
        });
    }

    public getPaginationSocietyOffersItemAPI(societyId: number, tenderId: number, params?: string[]): Observable<Pagination<Offer>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/tenders/${tenderId}/offers`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Offer>>(url, {
            headers: headers
        });
    }

    public getPaginationOffersItemAPI(tenderId: number, params?: string[]): Observable<Pagination<Offer>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offers`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Offer>>(url, {
            headers: headers
        });
    }

    public getPaginationOfferCreatorsItemAPI(tenderId: number, params?: string[]): Observable<Pagination<TenderOfferCreator>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offerCreators`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<TenderOfferCreator>>(url, {
            headers: headers
        });
    }

    public getPaginationSocietyOfferLocationsItemAPI(societyId: number, tenderId: number, params?: string[]): Observable<OfferLocation[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/tenders/${tenderId}/offers/locations`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferLocation[]>(url, {headers: headers})
            .pipe(
                map((items: object[]): OfferLocation[] => {

                    return items.map((item: object): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                })
            );
    }

    public getPaginationOfferLocationsItemAPI(tenderId: number, params?: string[]): Observable<OfferLocation[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offers/locations`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferLocation[]>(url, {headers: headers})
            .pipe(
                map((items: object[]): OfferLocation[] => {

                    return items.map((item: object): OfferLocation => {

                        return plainToClass(OfferLocation, item);
                    })
                })
            );
    }

    public getOfferItemAPI(tenderId: number, offerId: number): Observable<Offer> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offers/${offerId}`;

        return this._httpClient.get<Offer>(url,{
            headers: headers
        });
    }

    public getOfferAvailabilityItemsAPI(tenderId: number, offerId: number, params?: string[]): Observable<OfferAvailability[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offers/${offerId}/availabilities`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferAvailability[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferAvailability[] => {

                return items.map((item: object): OfferAvailability => {

                    return plainToClass(OfferAvailability, item);
                });
            })
        );
    }

    public getFirstDateOfferAvailabilityAPI(tenderId: number, offerId: number, params?: string[]): Observable<OfferFirstDateAvailability> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offers/${offerId}/availabilities/firstAvailability`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferFirstDateAvailability>(url, {
            headers: headers
        }).pipe(
            map((item: object): OfferFirstDateAvailability => {

                return plainToClass(OfferFirstDateAvailability, item);
            })
        );
    }

    public createItemAPI(data: object): Observable<Tender> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Tender>(`${this._apiService.getApiUrl(false, true)}/tenders`, data, {
            headers: headers
        });
    }

    public createOfferCatalogItemAPI(tenderId: number, offerId: number, data: object): Observable<OfferCatalog> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferCatalog>(`${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offers/${offerId}/catalog`, data, {
            headers: headers
        });
    }

    public refuseOfferItemAPI(tenderId: number, tenderOfferCreatorId: number, offerId: number, data: object): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offerCreators/${tenderOfferCreatorId}/offers/${offerId}`,{
            headers: headers,
            body: data
        });
    }

    public updateItemAPI(id: number, data: object): Observable<Tender> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Tender>(`${this._apiService.getApiUrl(false, true)}/tenders/${id}`, data, {
            headers: headers
        });
    }

    public updateOfferCreatorItemAPI(tenderId: number, tenderOfferCreatorId: number, data: object): Observable<TenderOfferCreator> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<TenderOfferCreator>(`${this._apiService.getApiUrl(false, true)}/tenders/${tenderId}/offerCreators/${tenderOfferCreatorId}`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/tenders/${id}`,{
            headers: headers
        });
    }

    public downloadItemAPI(id: number): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/tenders/${id}/download/pdf`, {
            responseType: 'blob'
        });
    }

    public exportItemsAPI(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/tenders/export`, {
            responseType: 'blob'
        });
    }
}
