export interface Pagination<T> {

    items: T[];

    totalItems: number;

    pagesCount: number;
}

export const DEFAULT_PAGE_SIZE: number = 10;

export const DEFAULT_PAGE_SIZE_OPTIONS: number[] = [5, 10, 25, 50];
