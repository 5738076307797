import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "../../models/pagination";
import {SocietyDistributor} from "../../models/society/society-distributor";

@Injectable({
    providedIn: 'root'
})
export class SocietyDistributorService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public getPaginationItemsAPI(societyId: number, params?: string[]): Observable<Pagination<SocietyDistributor>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/distributors`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<SocietyDistributor>>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number): Observable<SocietyDistributor> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<SocietyDistributor>(`${this._apiService.getApiUrl(false, true)}/societies/distributors/${id}`, {
            headers: headers
        });

    }

    public updateItemAPI(id: number, data: object): Observable<SocietyDistributor> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<SocietyDistributor>(`${this._apiService.getApiUrl(false, true)}/societies/distributors/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/societies/distributors/${id}`,{
            headers: headers
        });
    }
}
