import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';
import {ApiService} from "../../../api/api.service";
import {FileService} from "./file.service";
import {IFile, IFileTemplate} from "./file";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {FileUploadConfig, IApiFileUpload} from "@lib/media/file-upload/file-upload";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {environment} from "../../../../../../environments/environment";

@Component({
    selector: 'app-file',
    templateUrl: './file.component.html',
    styleUrls: ['../../element.component.scss']
})
export class FileComponent implements OnInit {

    @Input() content: IFile;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<IFile> = new EventEmitter<IFile>();

    fileUploadConfig: FileUploadConfig = null;

    configInitialized: boolean = false;

    /**
     * @param _apiService
     * @param _fileService
     * @param _translateService
     * @param _snackbar
     */
    constructor(
        private _apiService: ApiService,
        private _fileService: FileService,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar
    ) {
    }

    ngOnInit() {

        this._fileService.setApiUrl(this.apiUrl);

        // Initialisation de la configuration pour l'upload de fichier

        if (this._fileService.configLoaded()) {

            this.fileUploadConfig = this._getFileUploadConfig();

            this.configInitialized = true;
        } else {

            this._fileService.loadConfig().subscribe((data: any) => {

                    this._fileService.setConfig(data);

                    this.fileUploadConfig = this._getFileUploadConfig();

                    this.configInitialized = true;

                },
                (error: HttpErrorResponse) => {
                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                        this._snackbar.open(error.error, messages['action.close']);
                    });
                }
            );
        }
    }

    private _getFileUploadConfig(): FileUploadConfig {

        return {
            multiple: false,
            allowedTypes: this._fileService.getAllowedFileTypes().join(),
            maxSize: this._fileService.getUploadMaxSize(),
            uploadApi: {
                url: this._apiService.getApiUrl(true) + '/upload/file'
            },
            uploadBtnMsg: 'element.types.file.choice.action.value'
        };
    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {IFile} file
     */
    setContent(file: IFile) {

        this.update.emit(file); // On met à jour le contenu de l'élément
    }

    /**
     * Méthode appelée une fois l'upload de fichier terminé
     *
     * @param {IApiFileUpload} apiResult
     */
    postUpload(apiResult: IApiFileUpload): void {

        if (apiResult.error) {

            this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                this._snackbar.open(apiResult.msg, messages['action.close']);
            });

            return;
        }

        this.content = apiResult.file as IFile; // On met à jour le fichier du composant

        this.content.name = this.content.originalName;

        this.setContent(apiResult.file as IFile);
    }

    /**
     * Retourne le chemin absolu d'un fichier
     *
     * @param {IFile} file
     * @returns {string}
     */
    getFilePath(file: IFile): string {
        return environment.apiUrl + '/' + file.path;
    }

    /**
     * Retourne les templates disponibles pour le bouton de visualisation du fichier
     *
     * @returns {IFileTemplate[]}
     */
    get templates(): IFileTemplate[] {

        return this._fileService.templates;
    }

    /**
     * Utilisé pour afficher les templates disponibles pour le bouton de visualisation du fichier
     *
     * @param {IFileTemplate} first
     * @param {IFileTemplate} second
     * @returns {boolean}
     */
    compareByOptionClass(first: IFileTemplate, second: IFileTemplate) {

        if (!first && !second)
            return true;

        return first && second && first.class === second.class;
    }

    /**
     * Retourne la classe pour la prévisualisation du bouton
     *
     * @param {IFileTemplate} currentTemplate
     * @returns {string}
     */
    getPreviousButtonClass(currentTemplate: IFileTemplate) {

        if (!currentTemplate)
            return '';

        const match = this.templates.filter((template: IFileTemplate) => {
            return currentTemplate.class === template.class;
        }).shift();

        return match ? (match.class || '') : '';
    }
}
