<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center" *ngIf="!isAdmin()">
                <h1 class="title">{{ 'giftVoucher.list.management.value' | translate }}</h1>
            </div>
            <div class="col y_items_center" *ngIf="isAdmin()">
                <h1 class="title">{{ 'giftVoucher.admin.list.management.value' | translate }}</h1>
            </div>
            <div  class="col_content y_items_center" *ngIf="isAdmin()">
                <button (click)="adminExportGiftVoucher()" class="btn_cta btn_little">{{ 'adminGiftVoucher.export.value' | translate }}</button>
            </div>
            <div class="col_content y_items_center" *ngIf="hasAccessExportGiftVoucher()">
                <button (click)="exportGiftVouchers()" class="btn_cta btn_little">{{ 'giftVoucher.list.booking.export.value' | translate }}</button>
            </div>
        </div>

    </div>


    <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR') && hasOnlineSaleAccess">

        <div class="row marges ligne_form margin">
            <div class="col_content">
                <button class="btn_cta btn_small" [disabled]="!hasItems || !hasValidItems(allowToUpdateRenewalDuration)" (click)="openRenewalGiftVoucherDialog()">
                    {{ 'giftVoucher.configuration.submit.duration.value'|translate }}
                </button>
            </div>
            <div class="col_content">
                <button class="btn_cta btn_small" [disabled]="!hasItems || !hasValidItems(allowToUpdateRenewalPrice)" (click)="openRenewalPriceGiftVoucherDialog()">
                    {{ 'giftVoucher.configuration.submit.renewal.value'|translate }}
                </button>
            </div>
        </div>

        <ng-container *ngIf="hasRenewedItems()">
            <div class="row marges margin ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <p class="read failed">
                            <strong>{{ 'giftVoucher.renewal.invalid.value' | translate }}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="!hasData">
        <div class="text_content no_result"><p><strong>{{ 'giftVoucher.list.noresult.value' | translate }}</strong></p></div>
    </ng-container>

    <ng-container *ngIf="hasData">
        <div class="content_table">
            <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

                <!-- Filtres -->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Sélection -->

                <ng-container [matColumnDef]="getFilterColumnDef('select')" *ngIf="!isAdmin()">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Nom du propriétaire -->

                <ng-container *ngIf="isAdmin()" [matColumnDef]="getFilterColumnDef('ownerSociety.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.ownerSociety.name.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="isAdmin()" [matColumnDef]="getFilterColumnDef('offerCreator.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.offerCreator.name.value' | translate" [key]="'offerOwnerSociety.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Numéro dossier -->

                <ng-container [matColumnDef]="getFilterColumnDef('reference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.reference.value' | translate" [key]="'reference'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Numéro du bon cadeau -->

                <ng-container [matColumnDef]="getFilterColumnDef('code')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.code.value' | translate" [key]="'code'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom de l'offre -->

                <ng-container [matColumnDef]="getFilterColumnDef('offer.translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.name.value' | translate" [key]="'offer.translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Date de la demande -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Date limite de validité -->

                <ng-container [matColumnDef]="getFilterColumnDef('expiryDate')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'expiryDate'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!--ID Paiement -->

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" [matColumnDef]="getFilterColumnDef('paymentId')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.paymentId.value' | translate" [key]="'paymentId'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom de l'acheteur -->

                <ng-container *ngIf="!isAdmin()" [matColumnDef]="getFilterColumnDef('buyer.lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.buyerLastName.value' | translate" [key]="'buyer.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom de l'acheteur -->

                <ng-container *ngIf="!isAdmin()" [matColumnDef]="getFilterColumnDef('buyer.firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.buyerFirstName.value' | translate" [key]="'buyer.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom du bénéficiaire -->

                <ng-container *ngIf="!isAdmin()" [matColumnDef]="getFilterColumnDef('beneficiary.lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.beneficiaryLastName.value' | translate" [key]="'beneficiary.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom du bénéficiaire -->

                <ng-container *ngIf="!isAdmin()" [matColumnDef]="getFilterColumnDef('beneficiary.firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.beneficiaryFirstName.value' | translate" [key]="'beneficiary.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Montant TTC payé par le client -->

                <ng-container [matColumnDef]="getFilterColumnDef('totalPrice')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.totalPrice.value' | translate" [key]="'totalPrice'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prix NET TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('totalNetPrice')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.totalNetPrice.value' | translate" [key]="'totalNetPrice'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Commission -->

                <ng-container [matColumnDef]="getFilterColumnDef('commission')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Commission TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('commissionTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Montant commission TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('amountCommissionTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Commission payé en ligne -->

                <ng-container [matColumnDef]="getFilterColumnDef('isPaidCommission')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Nom du canal -->

                <ng-container [matColumnDef]="getFilterColumnDef('channel.translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.channel.name.value' | translate" [key]="'channel.translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Site web du canal -->

                <ng-container [matColumnDef]="getFilterColumnDef('channel.marketplace')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.channel.marketplace.value' | translate" [key]="'channel.marketplace.website'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Status du bon cadeau -->

                <ng-container [matColumnDef]="getFilterColumnDef('status')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.status.value' | translate" [key]="'status'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="status"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Prolongation -->

                <ng-container [matColumnDef]="getFilterColumnDef('isRenewable')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.renewable.value' | translate" [key]="'isRenewable'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isRenewableItems"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Montant du renouvellement -->

                <ng-container [matColumnDef]="getFilterColumnDef('customerRenewalPrice')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Renouvellé -->

                <ng-container [matColumnDef]="getFilterColumnDef('isRenewed')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.renewed.value' | translate" [key]="'isRenewed'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isRenewedItems"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Détaché -->

                <ng-container [matColumnDef]="getFilterColumnDef('isDetached')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.detached.value' | translate" [key]="'isDetached'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isDetachedItems"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Date de détachement -->

                <ng-container [matColumnDef]="getFilterColumnDef('detachedAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'detachedAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Nom de l'offre détachée -->

                <ng-container [matColumnDef]="getFilterColumnDef('offerData.translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.list.fields.offerData.value' | translate" [key]="'offerData.translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom du collaborateur  -->

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleUser.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.responsibleUser.value' | translate" [key]="'responsibleUser.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom du service  -->

                <ng-container [matColumnDef]="getFilterColumnDef('responsibleUser.service')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'booking.list.fields.responsibleUserService.value' | translate" [key]="'responsibleUser.service'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="view(element.id)" class="force-hover">
                            <mat-icon *ngIf="!hasAccessEdit()">visibility</mat-icon>
                            <mat-icon *ngIf="hasAccessEdit()">edit</mat-icon>
                        </button>
                        <button *ngIf="isInCustomerRelationshipCharge(getModel(element)) && getModel(element).isDetached" mat-button (click)="handlePeriods(element.id)" class="force-hover">
                            <mat-icon>calendar_today</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Liste des éléments -->

                <ng-container matColumnDef="select" *ngIf="!isAdmin()">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [disabled]="!isRenewalUpdateEnabled(row)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container *ngIf="isAdmin()" matColumnDef="ownerSociety.name">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.ownerSociety.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).society ? getModel(element).society.name : '' }}</td>
                </ng-container>

                <ng-container *ngIf="isAdmin()" matColumnDef="offerCreator.name">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.offerCreator.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).offerOwnerSociety.name ? getModel(element).offerOwnerSociety.name : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.reference.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).reference}}</td>
                </ng-container>

                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.code.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).code}}</td>
                </ng-container>

                <ng-container matColumnDef="offer.translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.offerName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).offer) ? translationService.getFallbackTranslation(getModel(element).offer.translations).name : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <ng-container matColumnDef="expiryDate">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.expiryDate.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).expiryDate | date : 'shortDate' : null : localeId}}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])" matColumnDef="paymentId">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.paymentId.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).paymentId) ? getModel(element).paymentId  : ''}}</td>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" matColumnDef="buyer.lastName">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.buyerLastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).buyer.lastName}}</td>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" matColumnDef="buyer.firstName">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.buyerFirstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).buyer.firstName}}</td>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" matColumnDef="beneficiary.lastName">
                    <th mat-header-cell *matHeaderCellDef>{{'invoice.beneficiaryLastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).beneficiary.lastName}}</td>
                </ng-container>

                <ng-container *ngIf="!isAdmin()" matColumnDef="beneficiary.firstName">
                    <th mat-header-cell *matHeaderCellDef>{{'invoice.beneficiaryFirstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).beneficiary.firstName}}</td>
                </ng-container>

                <ng-container matColumnDef="totalPrice">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.totalPrice.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).totalPrice / 100 | parsePrice }} {{ getCurrencySymbol(element) }}</td>
                </ng-container>

                <ng-container matColumnDef="totalNetPrice">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.totalNetPrice.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ isInCustomerRelationshipCharge(getModel(element)) || isAdmin() ? ((getModel(element).totalNetPrice / 100 | parsePrice) + ' ' + getCurrencySymbol(element)) : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="commission">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.commission.ht.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).commission ? parsedMarkup(getModel(element).commission * 100) : 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="commissionTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.commission.ttc.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).commissionTTC ? parsedMarkup(getModel(element).commissionTTC * 100) : 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="amountCommissionTTC">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.amountCommissionTTC.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).amountCommissionTTC ? ((getModel(element).amountCommissionTTC / 100 | parsePrice) + ' ' + getCurrencySymbol(element)) : 'N/A' }}</td>
                </ng-container>

                <ng-container matColumnDef="isPaidCommission">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.isPaidCommission.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ (getModel(element).isPaidCommission ? 'yes' : 'no') + '.value' | translate }}</td>
                </ng-container>

                <ng-container matColumnDef="channel.translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.channel.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).channel) ? translationService.getFallbackTranslation(getModel(element).channel.translations).name : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="channel.marketplace">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.channel.marketplace.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ (getModel(element).channel && getModel(element).channel.marketplace) ? getModel(element).channel.marketplace.website : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.status.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'giftVoucher.status.' + getModel(element).status + '.value' | translate }}</td>
                </ng-container>

                <ng-container matColumnDef="isRenewable">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.renewable.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'giftVoucher.list.fields.renewable.' + getModel(element).isRenewable + '.value' | translate  }}</td>
                </ng-container>

                <ng-container matColumnDef="customerRenewalPrice">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.renewalPrice.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).customerRenewalPrice / 100 | parsePrice }} {{ getCurrencySymbol(element) }}</td>
                </ng-container>

                <ng-container matColumnDef="isRenewed">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.renewed.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'giftVoucher.list.fields.renewed.' + getModel(element).isRenewed + '.value' | translate  }}</td>
                </ng-container>

                <ng-container matColumnDef="isDetached">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.detached.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'giftVoucher.list.fields.detached.' + getModel(element).isDetached + '.value' | translate  }}</td>
                </ng-container>

                <ng-container matColumnDef="detachedAt">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.detachedAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).isDetached ? (getModel(element).detachedAt | date : 'shortDate' : null : localeId) : null }}</td>
                </ng-container>

                <ng-container matColumnDef="offerData.translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.list.fields.offerData.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).isDetached && getModel(element).offerData) ? translationService.getFallbackTranslation(getModel(element).offerData.translations).name : ''}}</td>
                </ng-container>

                <ng-container matColumnDef="responsibleUser.name">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.responsibleUser.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).responsibleUser ? getResponsibleFullName(getModel(element).responsibleUser) : '' }}</td>
                </ng-container>

                <ng-container matColumnDef="responsibleUser.service">
                    <th mat-header-cell *matHeaderCellDef>{{'booking.list.fields.responsibleUserService.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).responsibleUser ? getModel(element).responsibleUser.service : '' }}</td>
                </ng-container>

                <!-- Entêtes de colonne -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <!-- Filtres -->

                <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

                <!-- Liste des éléments -->

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!--Pagination-->

        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
    </ng-container>

</div>
