<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'promotionSubscription.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">{{ 'promotionSubscription.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Code -->

                <ng-container [matColumnDef]="getFilterColumnDef('name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.code.value' | translate" [key]="'name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Name -->

                <ng-container [matColumnDef]="getFilterColumnDef('lastname')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.name.value' | translate" [key]="'lastname'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Firstname -->

                <ng-container [matColumnDef]="getFilterColumnDef('firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.firstName.value' | translate" [key]="'firstname'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Email -->

                <ng-container [matColumnDef]="getFilterColumnDef('email')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.email.value' | translate" [key]="'email'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Societé existante -->

                <ng-container [matColumnDef]="getFilterColumnDef('society')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.society.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>


                <!-- Societé existante -->

                <ng-container [matColumnDef]="getFilterColumnDef('mentionedSociety')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.mentionedSociety.value' | translate" [key]="'customer.societyName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('isUsed')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

<!--                &lt;!&ndash; Statut &ndash;&gt;-->

<!--                <ng-container [matColumnDef]="getFilterColumnDef('state')">-->
<!--                    <th mat-header-cell *matHeaderCellDef>-->
<!--                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'promotionSubscription.state.value' | translate" [key]="'state'" [operator]="'eq'" [items]="states" [itemLabelProperty]="'label'" [itemValueProperty]="'value'"></app-core-select-filter>-->
<!--                    </th>-->
<!--                </ng-container>-->

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">

                        <button mat-button (click)="goToView(element.id)">
                            <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="hasAccessDelete() && element.isDeletable">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Code -->

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.code.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).name}}</td>
                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="lastname">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getLastName(getModel(element))}}</td>
                </ng-container>

                <!-- Prénom -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.firstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getFirstName(getModel(element))}}</td>
                </ng-container>

                <!-- Email -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.email.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getEmail(getModel(element))}}</td>
                </ng-container>

                <!-- Société existante -->
                <ng-container matColumnDef="society">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.society.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).society) ? getModel(element).society.name : ''}}</td>
                </ng-container>

                <!-- Société mentionnée -->
                <ng-container matColumnDef="mentionedSociety">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.mentionedSociety.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{(getModel(element).customer) ? getModel(element).customer.societyName : ''}}</td>
                </ng-container>

                <!-- Utilisé  -->
                <ng-container matColumnDef="isUsed">
                    <th mat-header-cell *matHeaderCellDef>{{'promotionSubscription.used.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'promotionSubscription.used.' + isPromotionSubscriptionUsed(getModel(element)) + '.value' | translate }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>

