import {Component, OnInit} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {FileConfig} from "@lib/form/fields/file/file.component";

@Component({
    selector: 'app-core-page-network-offer-creator-create',
    templateUrl: './page-network-offer-creator-create.component.html',
    styleUrls: ['./page-network-offer-creator-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageNetworkOfferCreatorCreateComponent implements OnInit {

    public origin: { key: string, backLabel: string } = null;

    public registrationLogoConfig: FileConfig;

    public administrationLogoConfig: FileConfig;

    public constructor(
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        public formService: FormService
    ) {}

    ngOnInit(): void {

        this._initOrigin();

        this._initForm();
    }

    private _initOrigin(): void {

        this.origin = {
            key: this._activatedRoute.snapshot.queryParams['origin'],
            backLabel: `networkOfferCreator.creation.origin.${this._activatedRoute.snapshot.queryParams['origin']}.value`
        };
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            name: ['', Validators.required],
            comment: [''],
            offerCreators: [[]],
            parsedSocieties: [''],
            registrationLogo: [null],
            administrationLogo: [null]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {});

            this._networkOfferCreatorService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('network.offerCreator.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToOrigin();
            });
        };

        this._initRegistrationLogoConfigs();

        this._initAdministrationLogoConfigs();
    }

    private _initRegistrationLogoConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.registrationLogoConfig = {
                id: 'registrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'network_offer_creator_registration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true)
            };
        });
    }

    private _initAdministrationLogoConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.administrationLogoConfig = {
                id: 'administrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'network_offer_creator_administration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true)
            };
        });
    }

    public redirectToOrigin(): void {

        switch (this.origin.key) {

            case 'relationshipList':

                this._router.navigate(['account/relationship/list'], { queryParams: { targetTab: 'offerCreator'}});

                break;
        }
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
