import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {icon, Layer, MapOptions, marker, tileLayer} from "leaflet";

type ViewType = 'map' | 'visual';

@Component({
    selector: 'app-core-channel-offer-card-preview',
    templateUrl: './channel-offer-card-preview.component.html',
    styleUrls: ['./channel-offer-card-preview.component.scss']
})
export class ChannelOfferCardPreviewComponent implements OnInit {

    @Input() styleForm: UntypedFormGroup;

    public showMoreOpened: boolean = false;

    public viewOfferHover: boolean = false;

    public view: ViewType = 'visual';

    public mapOptions: MapOptions;

    ngOnInit(): void {

        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18
                })
            ],
            zoom: 5,
            center: {
                lat: 46.85,
                lng: 2.3518
            }
        };
    }

    public setView(value: ViewType): void {

        this.view = value;
    }

    public isView(value: ViewType): boolean {

        return this.view === value;
    }

    get marker(): Layer {

        return marker({lat: 46.85, lng: 2.3518}, {
            icon: icon({
                iconSize: [ 29, 45 ],
                iconAnchor: [ 16, 25 ],
                iconUrl: 'assets/images/icons/marker-icon.svg',
                shadowUrl: null
            })
        });
    }
}
