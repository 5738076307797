<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'reference.management.value' | translate }}</h1>
            </div>
        </div>
    </div>
</div>

<div class="ligne_form row marges">

    <div class="col_12 x_center">
        <div class="self_reference">
            {{ 'reference.myReference.value' | translate }} : <span class="self_reference_value">{{ society?.reference }}</span>
        </div>
    </div>

    <div class="col_12">
        <div class="form-group">
            <p class="title-form">
                {{ 'reference.title.value' | translate }}
            </p>

            <p class="read">
                {{ 'reference.description.value' | translate }}
            </p>

            <br/>

            <div class="text_content">
                <p>
                    <strong>{{ 'reference.step.title.value' | translate }}</strong>
                </p>

                <p>
                    {{ 'reference.step.step_1.value' | translate }}
                </p>

                <p>
                    {{ 'reference.step.step_2.value' | translate }}
                </p>

                <p>
                    {{ 'reference.step.step_3.value' | translate }}
                </p>

                <p>
                    {{ 'reference.step.step_4.value' | translate }}
                </p>

                <br/>

                <p>
                    <strong>
                        {{ 'reference.pricing.value' | translate }}
                    </strong>
                </p>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="ligne_form row marges">
        <div class="col_12">
            <div class="form-group">
                <p class="title-form">
                    {{ 'reference.form.title.value' | translate }}
                </p>
                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <app-select [config]="fieldCollection.getFieldConfig('locale')" [value]="form.get('locale').value" [form]="form"></app-select>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="email"
                                   id="email" required="required">
                            <label for="email">{{ 'reference.form.email.recipient.value' | translate }}</label>
                            <mat-error
                                *ngIf="form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="withEmailCopy" name="withEmailCopy" [(ngModel)]="withEmailCopy" [ngModelOptions]="{standalone: true}" (ngModelChange)="form.get('emailCopy').patchValue(null)">
                                <label for="withEmailCopy">{{ 'reference.form.email.copy.receive.value' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="withEmailCopy">
                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Email de copie -->

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="emailCopy" id="emailCopy">
                                <label for="emailCopy">{{ 'reference.form.email.copy.value' | translate }}</label>
                                <mat-error *ngIf="form.get('emailCopy').errors && (form.get('emailCopy').dirty || form.get('emailCopy').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('emailCopy').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('emailCopy').errors.isPatternInvalid">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <textarea class="form-control" formControlName="comment" id="comment" name="comment"></textarea>
                            <label for="comment">{{ 'reference.form.comment.value' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_content">
                        <button class="btn_cta btn_little" type="button" (click)="sendShareReference()" [disabled]="form.invalid">
                            {{ 'reference.form.submit.value' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
