import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Menu} from "../models/menu";
import {CategoryService} from "../services/category.service";

@Injectable({
    providedIn: 'root'
})
export class PageCategoryItemsResolver  {

    constructor(
        private _categoryService: CategoryService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._categoryService.getItemsAPI();
    }
}
