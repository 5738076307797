<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'administrator.edit.action.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'administrator.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>


    <div class="row marges ligne_form">
        <div class="col_12 col_md_6">
            <!-- Nom -->
            <div class="form-group">
                <input type="text" value="" class="form-control" id="lastName" formControlName="lastName" placeholder=" "/>
                <label for="lastName">{{ 'user.lastName.value'|translate }}</label>
                <mat-error *ngIf="form.get('lastName').errors && (form.get('lastName').dirty || form.get('lastName').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
        <div class="col_12 col_md_6">
            <!-- Prénom -->
            <div class="form-group">
                <input type="text" value="" class="form-control" id="firstName" formControlName="firstName" placeholder=" "/>
                <label for="firstName">{{ 'user.firstName.value'|translate }}</label>
                <mat-error *ngIf="form.get('firstName').errors && (form.get('firstName').dirty || form.get('firstName').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="form.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </div>
    </div>

</form>
