<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="panel_title">
        <div class="row marges">
            <div class="col y_items_center">
                <div>
                    <p class="title">{{ 'offer.date.create.action.value' | translate }}</p>
                    <p>{{ (date.format('YYYY-MM-DD') | date : 'fullDate' : null: localeId) | titlecase }}</p>
                </div>
            </div>

            <div class="col_content y_items_center">
                <button class="btn_cta btn_lines btn_little" type="button" (click)="close.emit()">
                    {{ 'cancel.value' | translate }}
                </button>
            </div>
            <div class="col_content y_items_center">
                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value' | translate }}
                </button>
            </div>

        </div>
    </div>

    <div class="form_part">

        <!-- Période -->

        <p class="title_part">{{ 'period.value' | translate}}</p>

        <div class="row marges ligne_form">

            <div class="col_12">

                <!-- Type de période -->

                <div class="form-group">
                    <label>{{ 'period.type.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="true" formControlName="isClosed" class="form-control" id="isClosedYes">
                        <label for="isClosedYes">{{ 'period.type.closed.value' | translate }}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="false" formControlName="isClosed" class="form-control" id="isClosedNo">
                        <label for="isClosedNo">{{ 'period.type.opened.value' | translate }}</label>
                    </div>
                    <div class="message-help" *ngIf="form.get('isClosed').value">
                        <mat-icon>info</mat-icon> <span>{{ 'period.type.help.date.value' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isPeriodOpen">

        <ng-container formGroupName="price">

            <div class="form_part">

                <!-- Tarifs par personne -->

                <p class="title_part">{{ 'price.plural.byPerson.value' | translate}}</p>

                <div class="ligne_form row marges">

                    <div class="col_12">

                        <div class="form-group">
                            <div class="app-toggle">
                                <mat-slide-toggle id="toggle-configuration-price" [disabled]="!configurationFields.price.allowed" [(ngModel)]="configurationFields.price.toProcess" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleConfigurationFieldProcess('price', true)"></mat-slide-toggle>
                                <label for="toggle-configuration-price">{{ 'price.plural.byPerson.value' | translate }}</label>
                            </div>
                        </div>

                    </div>

                </div>

                <ng-container *ngIf="configurationFields.price.toProcess">

                    <ng-container *ngIf="offer.vatPercent">

                        <!-- Calcul automatique des tarifs -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{'offer.price.calculation.automatic.action.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="true" [(ngModel)]="priceCalculationAutomatic" [ngModelOptions]="{standalone: true}" class="form-control" id="priceCalculationAutomaticYes">
                                        <label for="priceCalculationAutomaticYes">{{'form.user.fields.yes.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="false" [(ngModel)]="priceCalculationAutomatic" [ngModelOptions]="{standalone: true}" class="form-control" id="priceCalculationAutomaticNo">
                                        <label for="priceCalculationAutomaticNo">{{'form.user.fields.no.value' | translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="priceCalculationAutomatic">

                            <!-- Sélection du type de référence -->

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <label>{{'offer.price.type.reference.value' | translate}}</label>
                                        <div class="radio">
                                            <input type="radio" [value]="'HT'" [(ngModel)]="priceTypeReference" [ngModelOptions]="{standalone: true}" class="form-control" id="priceTypeReferenceHT">
                                            <label for="priceTypeReferenceHT">{{'taxes.excluding.value' | translate}}</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" [value]="'TTC'" [(ngModel)]="priceTypeReference" [ngModelOptions]="{standalone: true}" class="form-control" id="priceTypeReferenceTTC">
                                            <label for="priceTypeReferenceTTC">{{'taxes.including.value' | translate}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col_12">

                                    <div class="form-group">
                                        <div class="message">
                                        <span class="message-help">
                                            <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.price.calculation.automatic.description.value' | translate:{ value: offer.vatPercent * 100 } }}
                                        </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                    <!-- Tarifs adultes -->

                    <ng-container *ngIf="isSelectedOfferPricePublic('adult')">

                        <div class="row marges ligne_form">

                            <!-- Tarifs HT -->

                            <ng-container *ngIf="offer.vatPercent">

                                <div class="col_6">

                                    <div class="form-group">
                                        <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="adultPriceHT" id="price-adult-ht" appInputTextParsePrice [control]="priceForm.get('adultPriceHT')">
                                        <label for="price-adult-ht">
                                            {{ 'offer.price.adult.ht.value' | translate:{ currency: offer.currency.symbol } }}
                                        </label>
                                        <mat-error *ngIf="priceForm.get('adultPriceHT').errors && (priceForm.get('adultPriceHT').dirty || priceForm.get('adultPriceHT').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="priceForm.get('adultPriceHT').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="priceForm.get('adultPriceHT').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>

                                </div>

                            </ng-container>

                            <!-- Tarifs TTC -->

                            <div class="col_6">

                                <div class="form-group">
                                    <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="adultPriceTTC" id="price-adult-ttc" appInputTextParsePrice [control]="priceForm.get('adultPriceTTC')">
                                    <label for="price-adult-ttc">
                                        {{ 'offer.price.adult.ttc.value' | translate:{ currency: offer.currency.symbol } }}
                                    </label>
                                    <mat-error *ngIf="priceForm.get('adultPriceTTC').errors && (priceForm.get('adultPriceTTC').dirty || priceForm.get('adultPriceTTC').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="priceForm.get('adultPriceTTC').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="priceForm.get('adultPriceTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                    </ng-container>

                    <!-- Tarifs enfants -->

                    <ng-container *ngIf="isSelectedOfferPricePublic('child')">

                        <div class="row marges ligne_form">

                            <!-- Tarifs HT -->

                            <ng-container *ngIf="offer.vatPercent">

                                <div class="col_6">

                                    <div class="form-group">
                                        <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="childPriceHT" id="price-child-ht" appInputTextParsePrice [control]="priceForm.get('childPriceHT')">
                                        <label for="price-child-ht">
                                            {{ 'offer.price.child.ht.value' | translate:{ currency: offer.currency.symbol } }}
                                        </label>
                                        <mat-error *ngIf="priceForm.get('childPriceHT').errors && (priceForm.get('childPriceHT').dirty || priceForm.get('childPriceHT').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="priceForm.get('childPriceHT').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="priceForm.get('childPriceHT').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>

                                </div>

                            </ng-container>

                            <!-- Tarifs TTC -->

                            <div class="col_6">

                                <div class="form-group">
                                    <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="childPriceTTC" id="price-child-ttc" appInputTextParsePrice [control]="priceForm.get('childPriceTTC')">
                                    <label for="price-child-ttc">
                                        {{ 'offer.price.child.ttc.value' | translate:{ currency: offer.currency.symbol } }}
                                    </label>
                                    <mat-error *ngIf="priceForm.get('childPriceTTC').errors && (priceForm.get('childPriceTTC').dirty || priceForm.get('childPriceTTC').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="priceForm.get('childPriceTTC').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="priceForm.get('childPriceTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                    </ng-container>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="message">
                                    <span class="message-help" >
                                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.price.rounded.description.value' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <button class="btn_cta btn_little" type="button" (click)="calculatePrices()" *ngIf="offer.vatPercent && priceCalculationAutomatic && priceTypeReference">
                                {{ 'offer.price.calculation.automatic.action.value' | translate }}
                            </button>
                        </div>
                    </div>

                </ng-container>

            </div>

        </ng-container>

        <ng-container *ngIf="configurationFields.stock.allowed">

            <div class="form_part">

                <!-- Stocks -->

                <p class="title_part">{{ 'stock.plural.value' | translate}}</p>

                <div class="ligne_form row marges">

                    <div class="col_12">

                        <div class="form-group">
                            <div class="app-toggle">
                                <mat-slide-toggle id="toggle-configuration-stock" [disabled]="!configurationFields.stock.allowed" [(ngModel)]="configurationFields.stock.toProcess" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleConfigurationFieldProcess('stock', true)"></mat-slide-toggle>
                                <label for="toggle-configuration-stock">{{ 'stock.plural.value' | translate }}</label>
                            </div>
                        </div>

                    </div>

                    <div class="col_12">

                        <div class="form-group">
                            <div class="message">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'stock.info.value' | translate }}
                            </span>
                            </div>
                        </div>

                    </div>

                    <ng-container *ngIf="!hasOnlineSaleAccess">

                        <div class="col_12">

                            <div class="form-group">
                                <div class="message">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'stock.serviceRequired.value' | translate }}
                            </span>
                                </div>
                            </div>

                        </div>

                    </ng-container>

                </div>

                <ng-container *ngIf="configurationFields.stock.toProcess">

                    <ng-container formGroupName="stock">

                        <!-- Sélection du type de stock (réel/illimité) -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" [value]="false" formControlName="unlimited" class="form-control" id="unlimitedNo">
                                        <label for="unlimitedNo">{{'stock.type.real.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="true" formControlName="unlimited" class="form-control" id="unlimitedYes">
                                        <label for="unlimitedYes">{{'stock.type.unlimited.value' | translate}}</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <ng-container *ngIf="!this.stockForm.get('unlimited').value">

                            <ng-container formArrayName="stocks">

                                <div *ngFor="let stockControl of stocksForm.controls; let i = index" class="row marges ligne_form">

                                    <ng-container [formGroupName]="indexAsString(i)">

                                        <ng-container *ngIf="isStockManagementType('common')">

                                            <div class="col_6">

                                                <div class="form-group">
                                                    <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="commonAllocatedStock" id="common-allocated-stock">
                                                    <label for="common-allocated-stock">
                                                        {{ 'stock.common.value' | translate }}
                                                    </label>
                                                    <mat-error *ngIf="stockControl.get('commonAllocatedStock').errors && (stockControl.get('commonAllocatedStock').dirty || stockControl.get('commonAllocatedStock').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="stockControl.get('commonAllocatedStock').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                            <div *ngIf="stockControl.get('commonAllocatedStock').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>

                                            </div>

                                        </ng-container>

                                        <ng-container *ngIf="isStockManagementType('dissociated') && isSelectedOfferPricePublic('adult')">

                                            <div class="col_6">

                                                <div class="form-group">
                                                    <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="adultAllocatedStock" id="adult-allocated-stock">
                                                    <label for="adult-allocated-stock">
                                                        {{ 'stock.adult.value' | translate }}
                                                    </label>
                                                    <mat-error *ngIf="stockControl.get('adultAllocatedStock').errors && (stockControl.get('adultAllocatedStock').dirty || stockControl.get('adultAllocatedStock').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="stockControl.get('adultAllocatedStock').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                            <div *ngIf="stockControl.get('adultAllocatedStock').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>

                                            </div>

                                        </ng-container>

                                        <ng-container *ngIf="isStockManagementType('dissociated') && isSelectedOfferPricePublic('child')">

                                            <div class="col_6">

                                                <div class="form-group">
                                                    <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="childAllocatedStock" id="child-allocated-stock">
                                                    <label for="child-allocated-stock">
                                                        {{ 'stock.child.value' | translate }}
                                                    </label>
                                                    <mat-error *ngIf="stockControl.get('childAllocatedStock').errors && (stockControl.get('childAllocatedStock').dirty || stockControl.get('childAllocatedStock').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="stockControl.get('childAllocatedStock').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                            <div *ngIf="stockControl.get('childAllocatedStock').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>

                                            </div>

                                        </ng-container>

                                    </ng-container>

                                </div>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                </ng-container>

            </div>

        </ng-container>

        <ng-container *ngIf="configurationFields.releaseDate.allowed" formGroupName="releaseDate">

            <div class="form_part">

                <!-- Relâchement des allotements -->

                <p class="title_part">{{ 'allocation.plural.release.value' | translate}}</p>

                <div class="ligne_form row marges">

                    <div class="col_12">

                        <div class="form-group">
                            <div class="app-toggle">
                                <mat-slide-toggle id="toggle-configuration-release-date" [disabled]="!configurationFields.releaseDate.allowed" [(ngModel)]="configurationFields.releaseDate.toProcess" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleConfigurationFieldProcess('releaseDate', true)"></mat-slide-toggle>
                                <label for="toggle-configuration-release-date">{{ 'allocation.plural.release.value' | translate }}</label>
                            </div>
                        </div>

                    </div>

                    <ng-container *ngIf="!hasOnlineSaleAccess">

                        <div class="col_12">

                            <div class="form-group">
                                <div class="message">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'allocation.release.serviceRequired.value' | translate }}
                            </span>
                                </div>
                            </div>

                        </div>

                    </ng-container>

                </div>

                <ng-container *ngIf="configurationFields.releaseDate.toProcess">

                    <div class="row marges ligne_form">

                        <div class="col_6">

                            <div class="form-group">
                                <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="value" id="release-date">
                                <label for="release-date">
                                    {{ 'allocation.release.action.value' | translate }}
                                </label>
                                <mat-error *ngIf="releaseDateForm.get('value').errors && (releaseDateForm.get('value').dirty || releaseDateForm.get('value').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="releaseDateForm.get('value').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="releaseDateForm.get('value').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                    </div>

                </ng-container>

            </div>

        </ng-container>

    </ng-container>

    <div class="form_part">

        <!-- Réservations confirmées -->

        <p class="title_part">{{ 'booking.booked.plural.value' | translate}}</p>

        <div class="ligne_form row marges">

            <div class="col_12" *ngFor="let booking of bookings; trackBy: identifyBooking">
                - {{ booking.reference }} | {{ booking.customer.firstName }} {{ booking.customer.lastName }}<ng-container *ngIf="booking.composition.nbAdult">&nbsp;| {{ 'booking.read.fields.adult.count.value' | translate }} : {{ booking.composition.nbAdult }}</ng-container><ng-container *ngIf="booking.composition.nbChild">&nbsp;| {{ 'booking.read.fields.child.count.value' | translate }} : {{ booking.composition.nbChild }}</ng-container>
            </div>

            <ng-container *ngIf="!bookings.length">
                <div class="col_12">
                    <p>{{ 'booking.booked.find.noResult.value' | translate }}</p>
                </div>
            </ng-container>

        </div>

        <div class="ligne_form row marges more_booking" *ngIf="hasMoreBookings">
            <div class="col_12 x_center">
                <button class="btn_cta btn_highlight" type="button" (click)="loadMoreBookings()">
                    {{ 'booking.more.value' | translate }}
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!hasOnlineSaleAccess">

        <div class="form_part">

            <div class="stock_info">

                <span>
                    {{ 'offer.manage.stock.subscribe.service.value' | translate }} <a [routerLink]="['/account/service/list']">{{ 'user.account.services.value' | translate }}</a>.
                </span>

            </div>

        </div>

    </ng-container>

    <ng-container *ngIf="hasOnlineSaleAccess && (!this.offer.onlineSale || !this.offer.onlineSale.enable)">

        <div class="form_part">

            <div class="stock_info">

                    <span>
                        {{ 'offer.manage.stock.enable.service.value' | translate }}
                    </span>

            </div>

        </div>

    </ng-container>

</form>
