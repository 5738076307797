import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly _prefixPath: string = '';

    constructor(private _translateService: TranslateService) {
    }

    public getApiUrl(withLocalePath: boolean, withPrefixPath: boolean): string {

        return environment.apiUrl + (withLocalePath ? this.localePath : '') + (withPrefixPath ? this.prefixPath : '');
    }

    get localePath(): string {

        return `/${this._translateService.currentLang}`;
    }

    get prefixPath(): string {

        return this._prefixPath;
    }
}
