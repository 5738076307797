<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <!-- Actions -->

    <div class="page_title" *ngIf="configuration.showSubmitButton">
        <div class="row marges">

            <div class="col_content y_items_center">

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!configuration.enableMarkupSelection && !(configuration.enableHashtagSelection && form.get('hashtags'))">

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <p class="read"><strong>{{ 'offer.list.item.action.access.content.value' | translate }}</strong></p>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="configuration.enableMarkupSelection && form.get('markup')">

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <p class="read"><strong>{{ 'markup.offer.association.action.value' | translate }}</strong></p>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="markup" id="markup">
                    <label for="markup">{{'markup.form.value' | translate}}</label>
                    <mat-error *ngIf="this.form.get('markup').errors && (this.form.get('markup').dirty || this.form.get('markup').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="this.form.get('markup').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="this.form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            <div *ngIf="this.form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
                        </div>
                    </mat-error>
                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'markup.catalog.help.value' | translate }}</span>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="configuration.enableHashtagSelection && form.get('hashtags')">

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <p class="read"><strong>{{ 'hashtag.offer.association.action.value' | translate }}</strong></p>
                </div>
            </div>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12">
                <app-core-select-search [configuration]="hashtagSearchConfiguration" [sourceCallback]="hashtagSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'hashtags'"></app-core-select-search>
            </div>
        </div>

    </ng-container>
</form>
