import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";


@Injectable({
    providedIn: 'root'
})
export class TemplateGiftVoucherResolver  {

    constructor(
        private _giftVoucherService: TemplateGiftVoucherService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._giftVoucherService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
