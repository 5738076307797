import {Component, Input, OnInit} from '@angular/core';
import {WebsiteService} from "@core/shared/services/website.service";
import {SocietyWebsite} from "@core/shared/models/society/society-website";
import {User} from "@core/shared/models/user";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Router} from "@angular/router";
import {REGEX_WEBSITE} from "@core/shared/models/regex";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-core-additional-website-configuration-form',
    templateUrl: './additional-website-configuration-form.component.html',
    styleUrls: ['./additional-website-configuration-form.component.scss'],
    providers: [
        FormService
    ]
})
export class AdditionalWebsiteConfigurationFormComponent implements OnInit {

    @Input() user: User;

    private _websites: SocietyWebsite[] = [];

    constructor(
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _websiteService: WebsiteService,
        private _translateService: TranslateService,
        public formService: FormService
    )
    {}

    ngOnInit(): void {

        this._initForm();

        this._loadWebsites();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            websites: new UntypedFormArray([], [(control: UntypedFormArray) => {

                const websiteItems: Partial<SocietyWebsite>[] = (control.getRawValue() as Partial<SocietyWebsite>[]).filter((item: Partial<SocietyWebsite>): boolean => {

                    return !!item.website;
                });

                const websites: string[] = websiteItems.map((item: Partial<SocietyWebsite>): string => {

                    return item.website;
                });

                const uniqueWebsites: string[] = [...new Set(websites)];

                if(uniqueWebsites.length === websites.length){

                    return null;
                }

                return {
                    'invalidUnicity': true
                };
            }])
        });

        this.formService.submitCallback = () => {

            const data: Partial<SocietyWebsite>[] = (this.websitesControl.value as Partial<SocietyWebsite>[]).filter((item: Partial<SocietyWebsite>): boolean => {

                return !item.isMain && Boolean(item.website && item.website.length);
            });

            this._websiteService.updateSocietyItemsAPI(this.user.society.id, data).subscribe(() => {

                this._snackBar.open(this._translateService.instant('additionalWebsite.configuration.action.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._redirectToServices();
            });
        }
    }

    private _loadWebsites(): void {

        this._websiteService.getSocietyItemsAPI(this.user.society.id).subscribe((items: SocietyWebsite[]): void => {

            this._websites = items.sort((a: SocietyWebsite, b: SocietyWebsite): number => {

                return Number(b.isMain) - Number(a.isMain);
            });

            this._hydrateForm();
        });
    }

    private _hydrateForm(): void {

        this._websites.forEach((item: SocietyWebsite): void => {

            this.websitesControl.push(this._createWebsiteGroup(item));
        });

        const countAvailableSlots: number = this.user.society.countAllowedWebsites - this._websites.filter((item: SocietyWebsite): boolean => {

            return !item.isMain;

        }).length;

        for(let i: number = 0; i < countAvailableSlots; i++){

            this.websitesControl.push(this._createWebsiteGroup({
                isMain: false,
                website: null
            }));
        }
    }

    private _createWebsiteGroup(data: Partial<SocietyWebsite>): UntypedFormGroup {

        const group: UntypedFormGroup = this._formBuilder.group({
            isMain: new UntypedFormControl(data.isMain, [Validators.required]),
            website: new UntypedFormControl({ value: data.website, disabled: data.isMain }, [Validators.pattern(REGEX_WEBSITE)])
        });

        if('id' in data){

            group.addControl('id', new UntypedFormControl(data.id, [Validators.required]));
        }

        return group;
    }

    private _redirectToServices(): void {

        this._router.navigate(['account/service/list']);
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public clearWebsite(control: AbstractControl): void {

        control.patchValue(null);

        control.updateValueAndValidity();
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get websitesControl(): UntypedFormArray {

        return this.form.get('websites') as UntypedFormArray;
    }

    get mainWebsiteControl(): UntypedFormGroup {

        return this.websitesControl.controls.find((control: UntypedFormGroup): boolean => {

            return (control.value as Partial<SocietyWebsite>).isMain;

        }) as UntypedFormGroup;
    }

    get additionalWebsitesControls(): UntypedFormGroup[] {

        return this.websitesControl.controls.filter((control: UntypedFormGroup): boolean => {

            return !(control.value as Partial<SocietyWebsite>).isMain;

        }) as UntypedFormGroup[];
    }
}
