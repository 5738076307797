import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'activationState'
})
export class ActivationPipe implements PipeTransform {

    constructor(
        private _translateService: TranslateService
    ){}

    transform(value: boolean, ...args: unknown[]): unknown {

        return this._translateService.instant(`activation.${ value ? 'enabled' : 'disabled' }.value`);
    }
}
