import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyService} from '../services/society.service';
import {Society} from '../models/society';
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";

@Injectable({
    providedIn: 'root'
})
export class SocietyProviderItemsResolver  {

    constructor(
        private _societyService: SocietyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        const roleFilter: ArrayFilterField = new ArrayFilterField('admin.roles', 'andlkin', 'ROLE_PROVIDER');

        return this._societyService.getItemsAPI([roleFilter.serialize]);
    }
}
