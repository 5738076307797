<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ (crudService.isCreateAction ? 'societyGroup.creation.value' : 'societyGroup.update.value') | translate }}</h1>
            </div>

            <!-- Actions -->

            <div class="col_content y_items_center">

                <ng-container *ngIf="origin">

                    <!-- Retour à l'origine -->

                    <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToOrigin()">
                        {{ origin.backLabel | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="offerFormTabValidationService.currentItemIsLastItem">

                    <!-- Enregistrer -->

                    <button class="btn_cta btn_little" type="submit">
                        {{ 'form.submit.action.value'| translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="!offerFormTabValidationService.currentItemIsLastItem">

                    <!-- Passer à l'étape suivante -->

                    <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isCurrentItemValid" (click)="offerFormTabValidationService.goToNextItem()">
                        {{ 'form.tab.next.value' | translate }}
                    </button>

                </ng-container>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>

        <ng-container *ngFor="let tabItem of tabItems">

            <mat-tab [disabled]="!offerFormTabValidationService.isFirstItem(tabItem.tag) && !offerFormTabValidationService.isPreviousItemValid(tabItem.tag)">
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

    </mat-tab-group>

</form>

<ng-template #generalData>

    <ng-container [formGroup]="form">

        <ng-container *ngIf="crudService.isUpdateAction">
            <div class="row marges ligne_form">
                <div class="col_12">
                    <span style="font-weight: bold">{{ 'societyGroup.uniqueIdentifier.registration.value.uniqueIdentifier.registration.value' | translate }} : <a [href]="registrationIdentifierUrl" target="_blank">{{ registrationIdentifierUrl }}</a></span>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <span style="font-weight: bold">{{ 'societyGroup.uniqueIdentifier.registration.value.uniqueIdentifier.login.value' | translate }} : <a [href]="loginIdentifierUrl" target="_blank">{{ loginIdentifierUrl }}</a></span>
                </div>
            </div>
        </ng-container>

        <div class="row marges ligne_form">

            <!-- Nom du groupe -->

            <div class="col_12">

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="societyName">
                    <label class="required" for="societyName">{{ 'group.name.value' | translate }}</label>
                    <mat-error *ngIf="form.get('name').errors && (form.get('name').dirty || form.get('name').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>

            </div>
        </div>

        <div class="row marges ligne_form">

            <!-- Commentaire interne -->

            <div class="col_12">
                <div class="form-group">
                    <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                    <label class="required" for="comment">{{ 'comment.internal.value' | translate }}</label>
                    <div class="form-group col_content y_items_center">
                        <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengths.comment } }}</span>
                    </div>
                    <mat-error *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengths.comment } }}</div>
                            <div *ngIf="form.get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form">

            <!-- Logo tunnel d'inscription -->

            <div class="col_6">
                <div class="form-group add_picture big">
                    <div class="row">
                        <div class="form-group">
                            <label>{{ 'societyGroup.registrationLogo.value' | translate }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col_content">
                            <form-field-file *ngIf="registrationLogoConfig" [config]="registrationLogoConfig" [form]="form" [file]="form.get('registrationLogo').value"></form-field-file>
                            <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'societyGroup.registrationLogo.info.value' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Logo interface -->

            <div class="col_6">
                <div class="form-group add_picture big">
                    <div class="row">
                        <div class="form-group">
                            <label>{{ 'societyGroup.administrationLogo.value' | translate }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col_content">
                            <form-field-file *ngIf="administrationLogoConfig" [config]="administrationLogoConfig" [form]="form" [file]="form.get('administrationLogo').value"></form-field-file>
                            <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'societyGroup.administrationLogo.info.value' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

</ng-template>

<ng-template #associatedSocieties>

    <ng-container [formGroup]="form">


        <div class="form_part">

            <div class="title_part">
                {{ 'user.plural.association.value' | translate }}
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'subscriber.plural.future.plural.value' | translate }}</label>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <textarea class="form-control" formControlName="parsedSocieties" id="societies"></textarea>
                        <span class="tooltip_info" style="display: flex;margin-top: 8px;align-items: center;"><mat-icon class="tooltip_icon">info</mat-icon><span>&nbsp;{{'subscriber.future.form.help.value' | translate}}</span></span>
                        <label for="societies">{{ 'subscriber.plural.future.plural.value' | translate }}</label>
                        <mat-error *ngIf="form.get('parsedSocieties').errors && (form.get('parsedSocieties').dirty || form.get('parsedSocieties').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('parsedSocieties').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'network.association.value' | translate }}</label>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <div class="app-toggle">
                            <mat-slide-toggle [(ngModel)]="networkAssociationEnabled" [ngModelOptions]="{standalone: true}" (change)="resetNetworkAssociation()">
                                {{ 'network.association.action.value' | translate }}
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="networkAssociationEnabled">

                <!-- Réseaux de prestataires -->

                <div class="ligne_form row_marges">
                    <div class="col_12">
                        <div class="form-group">
                            <p class="read">
                                <strong class="subtitle_green">{{ 'networkProvider.plural.value' | translate }}</strong>
                            </p>
                            <div style="margin-top: 12px">
                                <app-core-select-search [configuration]="networkProviderSearchConfiguration" [sourceCallback]="networkProviderSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'networkProviders'"></app-core-select-search>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Réseaux de créateurs d'offres -->

                <div class="ligne_form row_marges">
                    <div class="col_12">
                        <div class="form-group">
                            <p class="read">
                                <strong class="subtitle_green">{{ 'networkOfferCreator.plural.value' | translate }}</strong>
                            </p>
                            <div style="margin-top: 12px">
                                <app-core-select-search [configuration]="networkOfferCreatorSearchConfiguration" [sourceCallback]="networkOfferCreatorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'networkOfferCreators'"></app-core-select-search>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

        </div>

        <div class="form_part">

            <div class="title_part">
                {{ 'user.plural.selection.value' | translate }}
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'selection.filter.value' | translate }}</label>
                    </div>
                </div>
            </div>

            <div class="bloc_offers bloc_societies">

                <div class="bloc_offers_filters bloc_society_filters">

                    <div class="filters_checked">

                        <ng-container *ngFor="let filter of currentFilters">

                            <ng-container *ngIf="filter.formattedValue">
                                <span class="filter" (mousedown)="removeFilter(filter.field)">
                                    <span>{{ filter.formattedValue }}</span>
                                    <span class="close"><mat-icon>close</mat-icon></span>
                                </span>
                            </ng-container>

                        </ng-container>

                    </div>

                    <div class="filters" style="padding: 24px 0 24px 0; border-bottom: none;">

                        <div class="filter_item">
                            <app-core-select-array-multiple-filter #rolesFilter
                                [builder]="filterBuilder"
                                [label]="'role.plural.value' | translate"
                                [key]="SocietyFilterKey.Roles" [operator]="'in'"
                                [itemValueProperty]="'id'"
                                [itemLabelProperty]="'name'"
                                [items]="roleFilterItems"
                            ></app-core-select-array-multiple-filter>
                        </div>

                        <div class="filter_item">
                            <app-core-select-array-multiple-filter #networkProvidersFilter
                                [builder]="filterBuilder"
                                [label]="'networkProvider.plural.value' | translate"
                                [key]="SocietyFilterKey.NetworkProviders" [operator]="'in'"
                                [itemValueProperty]="'id'"
                                [itemLabelProperty]="'name'"
                                [items]="networkProviderFilterItems"
                            ></app-core-select-array-multiple-filter>
                        </div>

                        <div class="filter_item">
                            <app-core-select-array-multiple-filter #networkOfferCreatorsFilter
                                [builder]="filterBuilder"
                                [label]="'networkOfferCreator.plural.value' | translate"
                                [key]="SocietyFilterKey.NetworkOfferCreators" [operator]="'in'"
                                [itemValueProperty]="'id'"
                                [itemLabelProperty]="'name'"
                                [items]="networkOfferCreatorFilterItems"
                            ></app-core-select-array-multiple-filter>
                        </div>

                        <div class="filter_item">
                            <app-core-select-array-multiple-filter #societyGroupsFilter
                                [builder]="filterBuilder"
                                [label]="'group.plural.value' | translate"
                                [key]="SocietyFilterKey.SocietyGroups" [operator]="'in'"
                                [itemValueProperty]="'id'"
                                [itemLabelProperty]="'name'"
                                [items]="societyGroupFilterItems"
                            ></app-core-select-array-multiple-filter>
                        </div>

                        <div class="filter_item">
                            <app-core-text-filter #nameFilter
                                [builder]="filterBuilder"
                                [label]="'society.name.value' | translate"
                                [key]="SocietyFilterKey.Name"
                                [operator]="'lk'"
                            ></app-core-text-filter>
                        </div>

                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <label>{{ 'user.plural.selection.value' | translate }}</label>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="((crudService.isCreateAction && filterBuilder.hasFilters) || (crudService.isUpdateAction && (societyGroup.societies.length || filterBuilder.hasFilters))); else noFilter">

                <ng-container *ngIf="societies$.getValue().length">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <button type="button" (click)="selectAllSocieties()">{{ 'check.all.action.value' | translate }}</button> |
                                <button type="button" (click)="unselectAllSocieties()">{{ 'uncheck.all.action.value' | translate }}</button>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <!-- Sociétés sélectionnées -->

                <ng-container *ngIf="selectedSocieties.length">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ selectedSocieties.length | translationFromQuantity: null : 'user.plural.selected.singular.value' : 'user.plural.selected.count.value' }} : {{ parsedSelectedSocieties }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges">

                        <div class="col y_items_center">

                            <div class="selected_offers">

                                <ul>
                                    <li *ngFor="let society of selectedSocieties">{{ society.name }}<mat-icon (click)="unselectSociety(society)">close</mat-icon></li>
                                </ul>

                            </div>
                        </div>

                    </div>

                </ng-container>

                <ng-container *ngIf="(societies$ | async) as societies">

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <!-- Résultat de la recherche -->

                            <ng-container *ngIf="societies.length; else noResult">

                                <div class="society-search-list">

                                    <div *ngFor="let society of societies" class="society-search-item">

                                        <div class="form-group">
                                            <div class="checkbox">
                                                <input type="checkbox" id="society-{{ society.id }}" [checked]="isSocietySelected(society)" (change)="handleSocietySelection(society)">
                                                <label for="society-{{ society.id }}">{{ getParsedSociety(society) }}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </ng-container>

                            <!-- Aucun résultat -->

                            <ng-template #noResult>
                                <div class="search_no_result">
                                    <p>{{ 'society.search.noResult.value' | translate }}</p>
                                </div>
                            </ng-template>

                        </div>

                    </div>

                </ng-container>

            </ng-container>

            <ng-template #noFilter>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                <span>{{ 'societyGroup.form.filter.empty.description.value' | translate }}</span>
                            </span>
                        </div>
                    </div>
                </div>

            </ng-template>

        </div>

    </ng-container>

</ng-template>
