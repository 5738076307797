import {Component, Input, OnInit} from '@angular/core';
import {OfferPricePublicType} from "@core/shared/models/offer/offer-price-public";
import {OfferPriceTypeType} from "@core/shared/models/offer/offer-price-type";
import {UntypedFormArray, UntypedFormGroup} from "@angular/forms";

@Component({
    selector: 'app-core-offer-price-form',
    templateUrl: './offer-price-form.component.html',
    styleUrls: ['./offer-price-form.component.scss']
})
export class OfferPriceFormComponent implements OnInit {

    @Input() form: UntypedFormGroup;

    @Input() formArrayName: string;

    @Input() pricesControl: UntypedFormArray;

    @Input() priceType: OfferPriceTypeType;

    @Input() pricePublic: OfferPricePublicType;

    @Input() currency: string;

    @Input() index: number;

    constructor() {
    }

    ngOnInit(): void {
    }

    public getPriceControlByFilter(offerPricePublic: OfferPricePublicType, offerPriceType: OfferPriceTypeType): UntypedFormGroup {

        const controls: UntypedFormGroup[] = this.pricesControl.controls as UntypedFormGroup[];

        return controls.find((control: UntypedFormGroup): boolean => {

            const conditions: boolean[] = [];

            if(offerPricePublic){

                conditions.push(control.value.public === offerPricePublic);
            }

            if(offerPriceType){

                conditions.push(control.value.type === offerPriceType);
            }

            return conditions.every((value: boolean) => {

                return value === true;
            });
        });
    }

    get DOMIdentifier(): string {

        return `price-${this.pricePublic}-${this.priceType.toLowerCase()}`;
    }

    get labelIdentifier(): string {

        return `offer.price.${this.pricePublic}.${this.priceType.toLowerCase()}.value`;
    }
}
