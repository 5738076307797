import {Component, Input} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {BookingSummaryService} from "@core/shared/services/booking/booking-summary.service";
import {parsePrice} from "@core/shared/utils/price";
import {BookingComposition} from "@core/shared/models/booking/booking-composition";
import {ApiService} from "@core/shared/services/api.service";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-offer-booking-summary-read',
    templateUrl: './offer-booking-summary-read.component.html',
    styleUrls: ['./offer-booking-summary-read.component.scss']
})
export class OfferBookingSummaryReadComponent {

    @Input() offer: Offer;

    constructor(
        private _apiService: ApiService,
        public translationService: TranslationService,
        public bookingSummaryService: BookingSummaryService
    ) {
    }

    public getOfferVisualSource(offerId: number, imageId: number): string {

        return !!imageId ? `${this._apiService.getApiUrl(false, false)}/public/offers/${offerId}/pictures/${imageId}/filter/rectangle/1500` : null;
    }

    public formatPrice(price: number, symbol: string): string {

        return price ? parsePrice(price / 100) + symbol : null;
    }

    get composition(): BookingComposition {

        return this.bookingSummaryService.item.composition;
    }
}
