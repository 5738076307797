import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from "./components/sidenav/sidenav.component";
import {SidenavItemComponent} from "./components/sidenav-item/sidenav-item.component";
import {MaterialModule} from "@material/material.module";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        SidenavComponent,
        SidenavItemComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        SidenavComponent
    ]
})
export class NavigationModule {
}
