import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyDistributor} from "../../models/society/society-distributor";
import {SocietyDistributorService} from "../../services/society/society-distributor.service";

@Injectable({
    providedIn: 'root'
})
export class SocietyDistributorItemResolver  {

    constructor(
        private _societyDistributorService: SocietyDistributorService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._societyDistributorService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
