import {Component, OnInit} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {Observable, of} from "rxjs";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {TranslateService} from "@ngx-translate/core";
import {ImageConfig} from '@lib/form/fields/image/image.component';
import {ApiService} from "@core/shared/services/api.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Router} from "@angular/router";

@Component({
    selector: 'app-page-template-gift-voucher-create',
    templateUrl: './page-template-gift-voucher-create.component.html',
    styleUrls: ['./page-template-gift-voucher-create.component.scss'],
    providers: [FormService]
})
export class PageTemplateGiftVoucherCreateComponent implements OnInit {

    public user: User;

    public translationBuilder: TranslationBuilder;

    constructor(public formService: FormService,
                public userService: UserService,
                private _router: Router,
                private _snackBar: MatSnackBar,
                private _translateService: TranslateService,
                private _apiService: ApiService,
                private _formBuilder: UntypedFormBuilder,
                private _giftVoucherService: TemplateGiftVoucherService) {
    }

    ngOnInit(): void {

        this.user = this.userService.currentUser.value;

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            type: ['', [Validators.required]],
            validType: [null, [Validators.requiredTrue]],
            isDematerialized: [false],
            isPhysical: [false],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.formService.submitCallback = () => {

            const data = Object.assign({...this.form.value});

            delete data['validType'];

            this._giftVoucherService.createItemAPI(data).subscribe(() => {

                this._snackBar.open(this._translateService.instant('giftVoucher.template.action.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });

        }
    }

    public redirectToList(): void {

        this._router.navigate(['account/template-gift-voucher/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

}
