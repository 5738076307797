import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ImageService} from "../image.service";
import {Image, ImageFormat, OptionsImage} from "../image";
import {Gallery} from "../../gallery";
import {FileUploadConfig} from "../../file-upload/file-upload";
import {TranslateService} from "@ngx-translate/core";
import {ImageGalleryDialogData} from "../image-gallery-dialog/image-gallery-dialog";
import {ImageGalleryDialogComponent} from "../image-gallery-dialog/image-gallery-dialog.component";
import {CropperDialogData, CropperDialogResult} from "../cropper-dialog/cropper-dialog";
import {CropperDialogComponent} from "../cropper-dialog/cropper-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ApiService} from "@core/shared/services/api.service";

@Component({
    selector: 'media-image-collection',
    templateUrl: './image-collection.component.html',
    styleUrls: ['./image-collection.component.scss', '../image.component.scss'],
    providers: [
        ImageService
    ]
})
export class ImageCollectionComponent implements OnInit, OnChanges {

    @Input() apiUrl: string;

    @Input() images: Image[];

    @Input() gallery: Gallery;

    @Input() max: number;

    @Input() options: OptionsImage;

    @Output() onAdd: EventEmitter<Image[]> = new EventEmitter<Image[]>();

    @Output() onDelete: EventEmitter<Image[]> = new EventEmitter<Image[]>();

    @Output() onUpdate: EventEmitter<Image> = new EventEmitter<Image>();

    @Output() onPositionChanged: EventEmitter<Image[]> = new EventEmitter<Image[]>();

    fileUploadConfig: FileUploadConfig;

    thumbnailWidth: number;

    thumbnailQuality: number;

    /**
     *
     * @param dialog
     * @param _translateService
     * @param _snackbar
     * @param _imageService
     * @param _apiService
     */
    constructor(
        public dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar,
        private _imageService: ImageService,
        private _apiService: ApiService
    ) {
        this.thumbnailWidth = 120;

        this.thumbnailQuality = 75;
    }

    ngOnInit() {

        this._imageService.setApiUrl(this.apiUrl);

        if((typeof this.images === 'undefined') || !this.images){
            this.images = [];
        }

    }

    ngOnChanges(changes: SimpleChanges): void {

        if(!changes.gallery){
            return;
        }

        this.gallery = changes.gallery.currentValue;

        if(this.gallery){
            this.fileUploadConfig = {
                allowedTypes: 'image/png;image/jpeg',
                maxSize: 10000000,
                multiple: true,
                uploadApi: {
                    url: `${ this._imageService.getApiUrl() }/galleries/${ this.gallery.id }/images`
                }
            }
        }

    }

    openGalleryDialog(): void {

        const data: ImageGalleryDialogData = {
            apiUrl: this.apiUrl,
            gallery: this.gallery,
            fileUploadConfig: this.fileUploadConfig,
            totalAvailableAddition: this.max - this.images.length,
            thumbnailWidth: this.thumbnailWidth,
            usedImages: this.images,
            type : 'image_collection'
        };

        const dialogRef = this.dialog.open(ImageGalleryDialogComponent, {
            width: '800px',
            data: data
        });

        dialogRef.componentInstance.onAdd.subscribe((image: Image) => {
            image.position = this.images.length + 1;
            image.title = null;
            this.onSelectedFormat(image.formats[0], image);

            this.images.push(image);

            this.onAdd.emit(this.images);
        });

        dialogRef.componentInstance.updateImage.subscribe((image: Image) => {

            this.images = this.images.filter(
                (elem: Image) => {
                    return elem.id !== image.id
                }
            );

        });
    }

    openCropperDialog(image: Image, format: ImageFormat): void {

        const source: string = `${this._apiService.getApiUrl(false, false)}/public/cms${ this._imageService.getMediaUrl() }${ this._imageService.getImageUrl() }/${ image.id }`;

        const data: CropperDialogData = {
            source: source,
            options: {
                data: format.cropperData,
                aspectRatio: format.ratio
            }
        };

        const dialogRef = this.dialog.open(CropperDialogComponent, {
            width: '800px',
            data: data
        });

        dialogRef.afterClosed().subscribe((result: CropperDialogResult) => {

            if((typeof result === 'undefined') || !result.apply){
                return;
            }

            format.cropperData = result.data;

            this._imageService.crop(image).subscribe(
                (): void => {

                    this._imageService.incrementIterator();
                }
            );
        });
    }

    delete(image: Image): void {

        const deletedPosition: number = image.position;

        this.images.splice(this.images.indexOf(image), 1);

        this.images.map((image: Image) => {

            if(image.position > deletedPosition){
                image.position--;
            }
        });

        this.onDelete.emit(this.images);

    }

    /**
     * @param image
     * @param format
     */
    getThumbnailPath(image: Image, format: ImageFormat): string {

        return this._imageService.getPath(image, format, this.thumbnailWidth, this.thumbnailQuality) + '?' + this._imageService.getIterator();
    }

    /**
     * Retourne une image d'une collection d'image en fonction de sa position
     *
     * @param {number} position
     *
     * @returns {Image}
     */
    getImageByPosition(position: number): Image {

        const match = this.images.filter((image: Image) => {
            return image.position === position;
        }).shift();

        return match || null;
    }

    /**
     * Retourne la dernière image d'une collection d'image
     *
     * @returns {Image}
     */
    getLastImage(): Image {

        if(!this.images.length){
            return null;
        }

        return this.getImageByPosition(this.images.length);
    }


    /**
     * Détermine si une image est la première pour une collection d'image
     *
     * @param {Image} image
     *
     * @returns {boolean}
     */
    isFirstImage(image: Image): boolean {

        return image.position === 1;
    }

    /**
     * Détermine si une image est la dernière pour une collection d'image
     *
     * @param {Image} image
     *
     * @returns {boolean}
     */
    isLastImage(image: Image): boolean {
        if(!this.getLastImage()){
            return false;
        }

        return this.getLastImage().position === image.position;
    }

    /**
     * Décrémentation de la position d'une image pour une collection d'image
     *
     * Sur l'interface l'image prend donc la place au dessus
     *
     * @param {Image} image
     */
    decreasePosition(image: Image): void {

        if(this.isFirstImage(image))
            return;

        const imageToUp = this.getImageByPosition(image.position - 1);

        imageToUp.position++;

        image.position--;

        this.onPositionChanged.emit(this.images);
    }

    /**
     * Incrémentation de la position d'une image pour une collection d'image
     *
     * Sur l'interface l'image prend donc la place en dessous
     *
     * @param {Image} image
     */
    increasePosition(image: Image): void {

        if(this.isLastImage(image)){
            return;
        }

        const imageToDown = this.getImageByPosition(image.position + 1);

        imageToDown.position--;

        image.position++;

        this.onPositionChanged.emit(this.images);
    }

    /**
     * Détermine si une image peut prendre la place au dessus
     *
     * @param {Image} image
     *
     * @returns {boolean}
     */
    isDecreaseAllowed(image: Image): boolean{

        return !this.isFirstImage(image);
    }

    /**
     * Détermine si uneimage peut prendre la place en dessous
     *
     * @param {Image} image
     *
     * @returns {boolean}
     */
    isIncreaseAllowed(image: Image): boolean {

        return !this.isLastImage(image);
    }

    update(image: Image): void {
        this.onUpdate.emit(image);
    }

    public onSelectedFormat(format: ImageFormat, image: Image): void {

        image.formats.forEach((formatImage: ImageFormat): void => {

            if(this.options.format){
                if (formatImage.name == this.options.format) {
                    formatImage.selected = true;
                } else {
                    formatImage.selected = false;
                }
            }else{
                if (formatImage.name == format.name) {
                    formatImage.selected = true;
                } else {
                    formatImage.selected = false;
                }
            }

        });


        this._imageService.setFormatImage(image).subscribe(() => {

        }, (error: HttpErrorResponse) => {
            this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                this._snackbar.open(error.error, messages['action.close']);
            });
        });

        this.onUpdate.emit(image);
    }

}
