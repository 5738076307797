<div class="form-group">
    <input type="text" [id]="searchAutocompleteIdentifier" #autocomplete placeholder=" " [matAutocomplete]="auto">
    <label [for]="searchAutocompleteIdentifier">{{ configuration.placeholder | translate }}</label>
    <button type="button" class="autocomplete-trigger" (click)="search()"></button>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event.option.value)">
    <mat-option *ngFor="let item of items; let i = index" [value]="item">
        <span>{{ getLabel(item, i) }}</span>
    </mat-option>
</mat-autocomplete>
