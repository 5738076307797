<ng-container *ngIf="isActiveView('backToComposition')">

    <div class="back_to_compo">

        <span class="ty-chevron" (click)="handleBackToCompositionRequest()">{{ 'offer.date.composition.back.value' | translate }}</span>

    </div>

</ng-container>

<ng-container *ngIf="!isActiveView('confirmation')">

    <div id="form-offer-booking">

        <div class="row marges">

            <div class="col_12 col_sm_7 col_md_8">

                <form [formGroup]="form" [hidden]="!isActiveView('request')" (ngSubmit)="formService.submit()">

                    <ng-container *ngIf="compositionItem.type === 'booking'">

                        <ng-container *ngTemplateOutlet="permanentOptions"></ng-container>

                        <ng-container *ngIf="compositionItem.availableStock || (offer.customerTypology.type === 'I')">

                            <ng-container *ngTemplateOutlet="participantTemplate"></ng-container>

                        </ng-container>

                    </ng-container>

                    <ng-container *ngIf="compositionItem.type === 'request'">

                        <div class="row marges ligne_form">

                            <div class="col_12 col_md_6">

                                <!-- Référence -->

                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="referenceResponsibleSociety" id="referenceResponsibleSociety">
                                    <label for="referenceResponsibleSociety">{{'reference.responsibleSociety.value' | translate}}</label>
                                </div>
                            </div>


                            <ng-container formGroupName="composition">

                                <div class="col_12 col_md_6">

                                    <!-- Flexibilité -->

                                    <div class="form-group">
                                        <select class="form-control" id="flexibility" formControlName="flexibility" required="required">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let flexibility of bookingFlexibilities$ | async" [ngValue]="flexibility">
                                                {{ translationService.getFallbackTranslation(flexibility.translations).label }}
                                            </option>
                                        </select>
                                        <label class="required" for="flexibility">{{ 'dates.plural.flexibility.value' | translate }}</label>
                                        <mat-error *ngIf="compositionControl.get('flexibility').invalid && (compositionControl.get('flexibility').dirty || compositionControl.get('flexibility').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="compositionControl.get('flexibility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                        </div>

                        <div formGroupName="composition" class="row marges ligne_form">

                            <div class="col_12 col_md_6">

                                <!-- Budget -->

                                <div class="form-group">
                                    <input type="text" placeholder=" " class="form-control" formControlName="budget" id="budget" required="required">
                                    <label for="budget">{{'budget.perPerson.value' | translate}}</label>
                                    <mat-error *ngIf="compositionControl.get('budget').errors && (compositionControl.get('budget').dirty || compositionControl.get('budget').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="compositionControl.get('budget').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="compositionControl.get('budget').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col_12 col_md_6">

                                <!-- Devise -->

                                <div class="form-group">
                                    <select class="form-control" id="currency" formControlName="currency" required="required">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let currency of currencies$ | async" [ngValue]="currency">
                                            {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.code }})
                                        </option>
                                    </select>
                                    <label class="required" for="flexibility">{{ 'currency.value' | translate }}</label>
                                    <mat-error *ngIf="compositionControl.get('currency').invalid && (compositionControl.get('currency').dirty || compositionControl.get('currency').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="compositionControl.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>

                        <ng-container formGroupName="composition">

                            <div class="row marges ligne_form">

                                <div class="col_12 col_md_6">

                                    <!-- Budget fixe ou approximatif -->

                                    <div class="form-group">
                                        <div class="radio">
                                            <input type="radio" [value]="false" formControlName="isFlexibleBudget" class="form-control" id="isFlexibleBudgetNo">
                                            <label for="isFlexibleBudgetNo">{{ 'budget.fixed.value' | translate }}</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" [value]="true" formControlName="isFlexibleBudget" class="form-control" id="isFlexibleBudgetYes">
                                            <label for="isFlexibleBudgetYes">{{ 'budget.flexible.value' | translate }}</label>
                                        </div>
                                        <mat-error *ngIf="compositionControl.get('isFlexibleBudget').errors && (compositionControl.get('isFlexibleBudget').dirty || compositionControl.get('isFlexibleBudget').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="compositionControl.get('isFlexibleBudget').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="form.get('customer')">

                            <ng-container formGroupName="customer">

                                <div class="row marges ligne_form">

                                    <!-- Commentaire du client -->

                                    <div class="col_12">
                                        <div class="form-group">
                                            <textarea placeholder=" " class="form-control" formControlName="comment" id="customerComment"></textarea>
                                            <label for="customerComment">{{ 'client.mine.project.description.value' | translate }}</label>
                                            <div class="form-group col_content y_items_center">
                                                <span class="message-help">
                                                    <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthCustomerComment } }}
                                                </span>
                                            </div>
                                            <mat-error *ngIf="customerControl.get('comment').errors && (customerControl.get('comment').dirty || customerControl.get('comment').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="customerControl.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthCustomerComment } }}</div>
                                                </div>
                                            </mat-error>
                                        </div>
                                    </div>

                                </div>

                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="hasPermanentOptionsAvailable">

                            <div class="row marges ligne_form">
                                <div class="col_12">

                                    <!-- Assurance oui / non -->

                                    <div class="form-group">
                                        <label>{{ 'permanentOptions.value' | translate }}</label>
                                        <div class="radio">
                                            <input type="radio" [value]="true" [(ngModel)]="displayPermanentOptions" [ngModelOptions]="{standalone: true}" (ngModelChange)="clearPermanentOptionSelection()" class="form-control" id="permanentOptionsYes">
                                            <label for="permanentOptionsYes">{{ 'form.user.fields.yes.value' | translate }}</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" [value]="false" [(ngModel)]="displayPermanentOptions" [ngModelOptions]="{standalone: true}" (ngModelChange)="clearPermanentOptionSelection()" class="form-control" id="permanentOptionsNo">
                                            <label for="permanentOptionsNo">{{ 'form.user.fields.no.value' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="displayPermanentOptions">

                            <br>

                            <ng-container *ngTemplateOutlet="permanentOptions"></ng-container>

                        </ng-container>

                        <br>

                    </ng-container>

                    <ng-container *ngTemplateOutlet="customerTemplate"></ng-container>

                    <ng-container *ngIf="compositionItem.type === 'booking'">
                        <ng-container *ngTemplateOutlet="promotionTemplate"></ng-container>
                    </ng-container>

                    <ng-container *ngIf="hasRole('ROLE_OFFER_DISTRIBUTOR') && offer.society.id !== currentUser.society.id && compositionItem.availableStock">

                        <!-- Engagement à reverser la somme due au créateur d'offre car la commande est automatiquement confirmée -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" id="optinPaybackAmount" formControlName="optinPaybackAmount">
                                        <label for="optinPaybackAmount">{{ 'offer.booking.asDistributor.optinPaybackAmount.description.value' | translate }}</label>
                                        <mat-error *ngIf="form.get('optinPaybackAmount').errors && (form.get('optinPaybackAmount').dirty || form.get('optinPaybackAmount').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="form.get('optinPaybackAmount').errors.required">{{ 'offer.booking.asDistributor.optinPaybackAmount.invalid.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="compositionItem.type === 'booking' && hasPermanentOptionsAvailable && (compositionControl.get('permanentOptions').value.length === 0)">

                        <div class="row marges ligne_form">
                            <div class="col_12">

                                <!-- J’ai pris connaissance du conseil d'assurance et des conditions générales mais je ne souhaite pas souscrire d'assurance -->

                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" id="optoutPermanentOptions" formControlName="optoutPermanentOptions">
                                        <label for="optoutPermanentOptions">{{ 'booking.permanentOptions.optout.value' | translate }}</label>
                                        <mat-error *ngIf="form.get('optoutPermanentOptions').errors && (form.get('optoutPermanentOptions').dirty || form.get('optoutPermanentOptions').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="form.get('optoutPermanentOptions').errors.isRequired">{{ 'booking.permanentOptions.optout.invalid.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="compositionItem.type === 'booking'">

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <!-- Envoi de l'email au client -->
                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" id="sendCustomerEmail" formControlName="sendCustomerEmail">
                                        <label for="sendCustomerEmail">{{ 'booking.sendCustomerEmail.value' | translate }}</label>
                                    </div>
                                    <div class="message-help" *ngIf="form.get('sendCustomerEmail').disabled">
                                        <mat-icon>info</mat-icon> <span>{{ 'booking.errors.termsAndConditions.value' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <!-- Soumission -->

                    <div class="row marges ligne_form margin">
                        <div class="col_12">
                            <button class="btn_cta btn_little" type="submit">
                                {{ submitLabel|translate }}
                            </button>
                        </div>
                    </div>

                </form>

                <ng-container *ngIf="isActiveView('validation')">

                    <ng-container *ngTemplateOutlet="validationTemplate"></ng-container>

                </ng-container>

                <ng-container *ngIf="isActiveView('expiration')">

                    <ng-container *ngTemplateOutlet="expirationTemplate"></ng-container>

                </ng-container>

            </div>

            <ng-container *ngIf="isActiveView('summary')">

                <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>

            </ng-container>

        </div>

    </div>

</ng-container>

<ng-container *ngIf="isActiveView('confirmation')">

    <ng-container *ngTemplateOutlet="confirmationTemplate"></ng-container>

</ng-container>

<ng-template #participantTemplate>

    <ng-container [formGroup]="form">

        <ng-container formGroupName="composition">

            <ng-container formArrayName="participants">

                <br/>

                <div class="ligne_form ligne_form_grey">

                    <div class="txt_offer">
                        <p class="title">{{ 'booking.composition.participant.list.value' | translate }}</p>
                    </div>

                    <div class="marges ligne_form">

                        <ng-container *ngFor="let _ of participantsControl.controls; index as i">

                            <ng-container [formGroupName]="indexAsString(i)">

                                <ng-container *ngIf="participantsControl.at(i).get('type').value === 'adult'">

                                    <div class="participant col_12 row marges ligne_form">

                                        <div class="col_12">

                                            <div class="form-group">
                                                <label>{{ 'booking.composition.participant.adult.value' | translate: {rank: participantsControl.at(i).get('rank').value} }}</label>
                                            </div>

                                        </div>

                                        <div class="col_6">

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" id="participant-adult-lastName-{{i}}" formControlName="lastName" required="required">
                                                <label for="participant-adult-lastName-{{i}}">{{ 'booking.composition.participant.lastName.value' | translate }}</label>
                                                <mat-error *ngIf="participantsControl.at(i).get('lastName').errors && (participantsControl.at(i).get('lastName').dirty || participantsControl.at(i).get('lastName').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="participantsControl.at(i).get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>

                                        <div class="col_6">

                                            <div class="form-group">
                                                <input type="text" value="" placeholder=" " class="form-control" id="participant-adult-firstName-{{i}}" formControlName="firstName" required="required">
                                                <label for="participant-adult-firstName-{{i}}">{{ 'booking.composition.participant.firstName.value' | translate }}</label>
                                                <mat-error *ngIf="participantsControl.at(i).get('firstName').errors && (participantsControl.at(i).get('firstName').dirty || participantsControl.at(i).get('firstName').touched)">
                                                    <div class="invalid-feedback">
                                                        <div *ngIf="participantsControl.at(i).get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                    </div>
                                                </mat-error>
                                            </div>

                                        </div>

                                        <br/><br/>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="participantsControl.at(i).get('type').value === 'child'">

                                    <div class="participant row marges ligne_form">

                                        <div class="col_12 row marges ligne_form">

                                            <div class="col_12">

                                                <div class="form-group">
                                                    <label>{{ 'booking.composition.participant.child.value' | translate: {rank: participantsControl.at(i).get('rank').value} }} <span *ngIf="!!participantsControl.at(i).get('birthDay').value">({{ participantsControl.at(i).get('birthDay').value | date : 'dd/MM/yyyy' }})</span></label>
                                                </div>

                                            </div>

                                            <div class="col_6">

                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" id="participant-child-lastName-{{i}}" formControlName="lastName" required="required">
                                                    <label for="participant-child-lastName-{{i}}">{{ 'booking.composition.participant.lastName.value' | translate }}</label>
                                                    <mat-error *ngIf="participantsControl.at(i).get('lastName').errors && (participantsControl.at(i).get('lastName').dirty || participantsControl.at(i).get('lastName').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="participantsControl.at(i).get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>

                                            </div>

                                            <div class="col_6">

                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" id="participant-child-firstName-{{i}}" formControlName="firstName" required="required">
                                                    <label for="participant-child-firstName-{{i}}">{{ 'booking.composition.participant.firstName.value' | translate }}</label>
                                                    <mat-error *ngIf="participantsControl.at(i).get('firstName').errors && (participantsControl.at(i).get('firstName').dirty || participantsControl.at(i).get('firstName').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="participantsControl.at(i).get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>

                                            </div>

                                        </div>

                                        <div class="col_12 row marges ligne_form">

                                            <div class="col_6">

                                                <div class="form-group">
                                                    <input type="text"  placeholder=" " class="form-control datetimepicker" id="participant-child-birthDay-{{i}}" formControlName="birthDay" required="required" matInput readonly [matDatepicker]="birthDatePicker" (click)="birthDatePicker.open()">
                                                    <label for="participant-child-birthDay-{{i}}">{{ 'offer.form.fields.birthdate.value' | translate }}</label>
                                                    <mat-error *ngIf="participantsControl.at(i).get('birthDay').errors && (participantsControl.at(i).get('birthDay').dirty || participantsControl.at(i).get('birthDay').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="participantsControl.at(i).get('birthDay').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            <div *ngIf="participantsControl.at(i).get('birthDay').errors.birthdateInvalid">{{ 'form.control.error.birthdate.invalid.value' | translate }}</div>
                                                        </div>
                                                    </mat-error>
                                                    <mat-datepicker #birthDatePicker></mat-datepicker>
                                                </div>

                                            </div>
                                        </div>

                                        <br/><br/>
                                    </div>

                                </ng-container>

                            </ng-container>

                        </ng-container>

                    </div>

                </div>

                <br/>

            </ng-container>

        </ng-container>

    </ng-container>

</ng-template>

<ng-template #customerTemplate>

    <app-core-customer-form-create [origin]="compositionItem.type" [currentUser]="currentUser" [locales]="offer.locales" [parentForm]="form" [controlName]="'customer'" [termsAndConditionsFileIdentifier]="termsAndConditionsFile" [viewLanguage]="viewLanguage" (localeUpdated)="handleCustomerLocaleUpdate($event)">

        <!-- Projection de contenu pour le commentaire du créateur d'offre -->

        <div class="row marges ligne_form" offerCreatorCommentContent>

            <ng-container [formGroup]="form">

                <div class="col_12">
                    <div class="form-group">
                        <textarea placeholder=" " class="form-control" formControlName="comment" id="offerCreatorComment"></textarea>
                        <label for="offerCreatorComment">{{ 'comment.offerCreator.value' | translate:{ name: offer.society.name } }}</label>
                        <div class="form-group col_content y_items_center">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthOfferCreatorComment } }}
                            </span>
                        </div>
                        <mat-error *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthOfferCreatorComment } }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </ng-container>

        </div>

    </app-core-customer-form-create>

</ng-template>

<ng-template #summaryTemplate>

    <div id="summary" class="col_12 col_sm_5 col_md_4 order_first order_last_sm open">

        <div>
            <app-core-offer-booking-summary-read [offer]="offer"></app-core-offer-booking-summary-read>
        </div>

    </div>

</ng-template>

<ng-template #validationTemplate>

    <div class="row">

        <div class="col_12">

            <div class="ck-content">

                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR'); else bookingValidationAsPartner">

                    <div [innerHTML]="((booking.status === 'waiting-payment') ? 'offer.booking.validation.available.value' : 'offer.booking.validation.unavailable.value') | translate"></div>

                </ng-container>

                <ng-template #bookingValidationAsPartner>

                    <div [innerHTML]="((booking.status === 'waiting-payment') ? 'offer.booking.validation.available.asPartner.value' : 'offer.booking.validation.unavailable.asPartner.value') | translate:{ offerCreator: booking.offerOwnerSociety.name }"></div>

                </ng-template>

                <br>

                <p><strong>{{ 'offer.booking.validation.continue.request.value' | translate }}</strong></p>

                <br>

                <div class="row marges">

                    <div class="col_content">
                        <button type="button" class="btn_cta btn_little" (click)="validateBooking()">{{ 'offer.booking.validate.value' | translate }}</button>
                    </div>

                    <div class="col_content">
                        <button type="button" class="btn_cta btn_lines btn_little" (click)="cancelBooking()">{{ 'offer.booking.cancel.value' | translate }}</button>
                    </div>

                </div>

            </div>

        </div>

    </div>

</ng-template>

<ng-template #expirationTemplate>

    <div class="row">

        <div class="col_12">

            <div class="txt_offer failed">

                <p class="title">{{ 'offer.booking.expiration.description.value' | translate:{ duration: 1 } }}</p>

            </div>

        </div>
    </div>

    <div class="row ligne_form margin">

        <div class="col_12">

            <div class="col_content">
                <button type="button" class="btn_cta btn_little" (click)="handleBackToForm()">{{ 'form.back.value' | translate }}</button>
            </div>

        </div>
    </div>

</ng-template>

<ng-template #confirmationTemplate>

    <div class="txt_offer confirmation">

        <ng-container *ngIf="compositionItem.type === 'booking'">

            <ng-container *ngIf="compositionItem.availableStock">

                <p class="title">{{ 'booking.request.onlineSale.success.value' | translate }}</p>

                <p>{{ 'offer.booking.availableStock.success.description.value' | translate }}</p>

            </ng-container>

            <ng-container *ngIf="!compositionItem.availableStock">

                <p class="title">{{ 'booking.request.booking.success.value' | translate }}</p>

                <p>{{ 'offer.booking.unavailableStock.success.description.value' | translate }}</p>

            </ng-container>

        </ng-container>

        <ng-container *ngIf="compositionItem.type === 'request'">

            <p class="title">{{ 'booking.request.quotation.success.value' | translate }}</p>

            <p>{{ 'booking.request.quotation.success.description.value' | translate }}</p>

        </ng-container>
    </div>

</ng-template>

<ng-template #promotionTemplate>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form">

            <div class="col y_items_center">

                <!-- Code Promotion -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="promotionCode" id="promotionCode">
                    <label for="promotionCode">{{'booking.request.booking.promotion.code.choice.action.value' | translate}}</label>
                </div>
            </div>

            <ng-container *ngIf="form.get('promotionCode').value && !!form.get('promotionCode').value.length">

                <div class="col_content y_items_center">
                    <button class="btn_cta btn_little" type="button" (click)="validatePromotionCode()">
                        {{ 'booking.request.booking.promotion.code.validate.action.value'|translate }}
                    </button>
                </div>

            </ng-container>
        </div>

        <ng-container *ngIf="displayPromotionEmailRequired">
            <div class="row">
                <div class="col y_items_center">
                    <div class="form-group">
                        <mat-error>
                            <div class="invalid-feedback">
                                <div>{{ 'booking.request.promotion.email.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>
            <br>
        </ng-container>

        <ng-container *ngIf="form.get('isValidPromotion').value">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <p class="read confirm">
                            <strong>{{ 'booking.request.booking.promotion.code.validate.success.value' | translate: {code: form.get('promotionCode').value} }}</strong>
                        </p>
                    </div>
                </div>
            </div>

        </ng-container>

    </ng-container>

</ng-template>

<ng-template #permanentOptions>
    <ng-content select="[permanentOptionsContent]"></ng-content>
</ng-template>
