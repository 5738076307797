<h1 mat-dialog-title>
    {{ 'media.image.cropper.title' | translate }}
</h1>
<div mat-dialog-content>
    <div>
        <span *ngIf="!initialized" class="load">chargement...</span>
        <div class="actions" *ngIf="initialized">
            <div>
                <button type="button" mat-stroked-button (click)="setDragMode('crop')" matTooltip="{{ 'media.image.cropper.crop.action' | translate }}" matTooltipPosition="left"><mat-icon>crop</mat-icon></button>
                <button type="button" mat-stroked-button (click)="setDragMode('move')" matTooltip="{{ 'media.image.cropper.move.action' | translate }}" matTooltipPosition="left"><mat-icon>zoom_out_map</mat-icon></button>

                <button type="button" mat-stroked-button (click)="zoom(0.1)" matTooltip="{{ 'media.image.cropper.zoomIn.action' | translate }}" matTooltipPosition="left"><mat-icon>zoom_in</mat-icon></button>
                <button type="button" mat-stroked-button (click)="zoom(-0.1)" matTooltip="{{ 'media.image.cropper.zoomOut.action' | translate }}" matTooltipPosition="left"><mat-icon>zoom_out</mat-icon></button>
            </div>
        </div>
        <div class="img-container">
            <img #image [src]="data.source" crossorigin>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button type="button" (click)="cancel()" class="btn_cta btn_little btn_red">{{ 'media.image.cropper.cancel.action' | translate }}</button>
        </div>
        <div class="col_content">
            <button type="button" (click)="confirm()" class="btn_cta btn_little">{{ 'media.image.cropper.confirm.action' | translate }}</button>
        </div>
    </div>
</div>
