<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ (crudService.isCreateAction ? 'hashtag.create.value' : 'hashtag.update.value') | translate }}</h1>
            </div>

            <!-- Actions -->

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'hashtag.list.back.value' | translate }}
                </button>

                <!-- Enregistrer -->

                <button class="btn_cta btn_little" type="submit" *ngIf="hasAccessEdit">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
        <ng-container *ngIf="crudService.isUpdateAction || isTywinHashtag || isMine">
            <mat-tab [label]="'offer.list.selection.value' | translate">
                <ng-container *ngTemplateOutlet="offersSelection"></ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalData>

    <ng-container [formGroup]="form">

        <ng-container *ngIf="userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

            <div class="row marges ligne_form">

                <!-- Hashtag Ty-Win -->

                <div class="col_12">
                    <div class="form-group">
                        <label>{{'hashtag.tywin.question.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="tywinHashtag" class="form-control" id="tywinHashtagYes">
                            <label for="tywinHashtagYes">{{'yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="tywinHashtag" class="form-control" id="tywinHashtagNo">
                            <label for="tywinHashtagNo">{{'no.value' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!isTywinHashtag">

                <div class="ligne_form row_marges">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="form_part">
                                <p class="title_part">{{ 'user.associated.value' | translate }}</p>
                            </div>
                            <div style="margin-top: 12px">
                                <app-core-select-search [configuration]="associatedUserSearchConfiguration" [sourceCallback]="associatedUserSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'society'"></app-core-select-search>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

        </ng-container>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="mat-tab-translations">
                    <ng-container formArrayName="translations">

                        <mat-tab-group [@.disabled]="true">

                            <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">

                                <mat-tab label="{{ translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">

                                    <ng-container [formGroupName]="translationBuilder.indexAsString(it)">

                                        <!-- Nom -->

                                        <div class="row marges ligne_form">

                                            <div class="col_12">
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="{{it}}-name">
                                                    <label for="{{it}}-name">{{'name.value' | translate}}</label>
                                                    <mat-error *ngIf="translationBuilder.getItemControl(it).get('name').errors && (translationBuilder.getItemControl(it).get('name').dirty || translationBuilder.getItemControl(it).get('name').touched)">
                                                        <div class="invalid-feedback">
                                                            <div *ngIf="translationBuilder.getItemControl(it).get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            <div *ngIf="translationBuilder.getItemControl(it).get('name').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengths.name } }}</div>
                                                        </div>
                                                    </mat-error>
                                                </div>
                                            </div>

                                        </div>

                                        <!-- Description -->

                                        <ng-container *ngIf="(crudService.isCreateAction && isTywinHashtag) || crudService.isUpdateAction || isMine">

                                            <div class="row marges ligne_form">
                                                <div class="col_12">
                                                    <div class="form-group">
                                                        <label>{{ ('hashtag.description' + (isTywinHashtag ? '' : '.society') +'.value') | translate }}</label>
                                                        <app-ckeditor [form]="translationBuilder.getItemControl(it)" [config]="descriptionEditorConfig"></app-ckeditor>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>

                                    </ng-container>
                                </mat-tab>
                            </div>

                        </mat-tab-group>

                    </ng-container>
                </div>
            </div>
        </div>

        <!-- Visibilités -->

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <label>{{ 'hashtag.visibility.value' | translate }}</label>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form" style="margin-top: 12px">
            <div class="col_12">
                <div class="form-group">
                    <ng-container *ngFor="let visibility of availableVisiblities">
                        <div class="checkbox min-size">
                            <input type="checkbox" id="visibility-{{visibility}}" [checked]="isSelectedVisibility(visibility)" [disabled]="isDisabledVisibility(visibility)" (change)="handleVisibility(visibility)">
                            <label for="visibility-{{visibility}}" [ngStyle]="{ fontWeight: visibility === 'ALL' ? 'bold' : 'initial' }">{{ getVisibilityLabel(visibility) | translate }}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('visibilities').errors">
            <div class="col_12">
                <div class="form-group">
                    <mat-error style="padding: 0">
                        <div class="invalid-feedback">
                            <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isSelectedVisibility('ROLE')">

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'role.user.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="roleSearchConfiguration" [sourceCallback]="roleSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'roles'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('roles').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="isSelectedVisibility('NETWORK_PROVIDER')">

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'networkProvider.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="networkProviderSearchConfiguration" [sourceCallback]="networkProviderSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'networkProviders'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('networkProviders').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="isSelectedVisibility('NETWORK_OFFER_CREATOR')">

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'networkOfferCreator.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="networkOfferCreatorSearchConfiguration" [sourceCallback]="networkOfferCreatorSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'networkOfferCreators'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('networkOfferCreators').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="isSelectedVisibility('REGISTRATION_AFFILIATION')">

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'registrationAffiliation.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="registrationAffiliationSearchConfiguration" [sourceCallback]="registrationAffiliationSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'registrationAffiliations'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('registrationAffiliations').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="isSelectedVisibility('SOCIETY_GROUP')">

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'societyGroup.plural.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="societyGroupSearchConfiguration" [sourceCallback]="societyGroupSearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'societyGroups'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('societyGroups').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="isSelectedVisibility('SOCIETY')">

            <div class="ligne_form row_marges">
                <div class="col_12">
                    <div class="form-group">
                        <div class="form_part">
                            <p class="title_part">{{ 'user.plural.selection.value' | translate }}</p>
                        </div>
                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="societySearchConfiguration" [sourceCallback]="societySearchSourceCallback" [formGroupReference]="restrictionControl" [formControlNameReference]="'societies'"></app-core-select-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form" style="margin-top: 12px" *ngIf="restrictionControl.get('societies').errors">
                <div class="col_12">
                    <div class="form-group">
                        <mat-error style="padding: 0">
                            <div class="invalid-feedback">
                                <div>{{ 'visibility.configuration.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </ng-container>

    </ng-container>

</ng-template>

<!-- Sélection des offres -->

<ng-template #offersSelection>

    <ng-container [formGroup]="form">

        <div [formGroup]="form" class="tab_content_overflow">

            <div class="row marges">

                <div class="col y_items_center">

                    <div class="selected_offers" *ngIf="offerSearchService.selectedOffers.value.length">

                        <span>{{ 'offer.list.selected.value' | translate }} :</span>

                        <ul>
                            <li *ngFor="let offer of offerSearchService.selectedOffers.value">{{ translationService.getFallbackTranslation(offer.translations).name }}<mat-icon *ngIf="offerSearchService.selectOfferAllowed" (click)="offerSearchService.unselectOffer(offer)">close</mat-icon></li>
                        </ul>

                    </div>
                </div>

                <div class="col_content y_items_center" *ngIf="offerSearchService.selectedOffers.value.length">
                    <button type="button" class="btn_cta btn_little" (click)="resetOfferSelection() ">{{ 'offer.list.selection.reset.action.value' | translate}}</button>
                </div>

            </div>

            <app-core-offer-search
                [user]="currentUser"
                [society]="currentUser.society"
                [mode]="offerSearchMode"
                [loadItemsSourceCallback]="loadOffersSourceCallback"
            ></app-core-offer-search>

        </div>

    </ng-container>

</ng-template>
