import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {ServiceService} from "@core/shared/services/service.service";
import {Observable} from "rxjs";
import {Service} from "@core/shared/models/service";


@Injectable({
    providedIn: 'root'
})
export class ServiceItemResolver  {

    constructor(
        private _servicePlusService: ServiceService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Service> | Promise<Service> | Service {

        return this._servicePlusService.getItemAPI(parseInt(route.paramMap.get('serviceId')));
    }
}
