import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OfferPeriodDeleteConfiguration} from "@core/shared/models/offer/offer-period/offer-period-delete";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {Moment} from "moment";
import {DATE_FORMAT} from "@app/data";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferOptionPeriodService} from "@core/shared/services/offer/offer-option/offer-option-period.service";

@Component({
    selector: 'app-core-offer-option-period-delete',
    templateUrl: './offer-option-period-delete.component.html',
    styleUrls: ['./offer-option-period-delete.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferOptionPeriodDeleteComponent implements OnInit {

    @Input() option: OfferOption;

    @Input() configuration: OfferPeriodDeleteConfiguration;

    @Output() delete: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offerOptionPeriodService: OfferOptionPeriodService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                dateStart: (this.form.get('dateStart').value as Moment).format(DATE_FORMAT),
                dateEnd: (this.form.get('dateEnd').value as Moment).format(DATE_FORMAT)
            });

            this._offerOptionPeriodService.createItemAPI(this.option.id, data).subscribe((): void => {

                this.delete.emit();
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
