import {Component,  Input, OnInit} from '@angular/core';
import {ADDITIONAL_WEBSITE_SERVICE_LABEL, GIFT_VOUCHER_SERVICE_LABEL, Service, ServiceTranslation} from "@core/shared/models/service";
import {TranslationService} from "@core/shared/services/translation.service";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {AboutServiceDialogComponent} from "@core/components/service/about-service-dialog/about-service-dialog.component";
import {User} from "@core/shared/models/user";
import {Role} from "@core/shared/models/role";
import {Router} from "@angular/router";
import {parsePrice} from "@core/shared/utils/price";
import {SocietyService} from "@core/shared/models/society-service";
import {TranslateService} from "@ngx-translate/core";
import {ServiceService} from "@core/shared/services/service.service";
import { ServiceSubscriptionDeleteDialogComponent } from "@core/components/service/service-subscription-delete-dialog/service-subscription-delete-dialog.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {OfferService} from "@core/shared/services/offer.service";
import {Offer} from "@core/shared/models/offer";
import { ServiceRequestDocumentDialogComponent } from "@core/components/service/request-document-dialog/service-request-document-dialog.component";
import {forkJoin, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {Access, AccessType} from "@core/shared/models/access";

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent implements OnInit {

    @Input() item: Service;

    @Input() user: User;

    private _societyServices: SocietyService[] = [];

    public serviceTranslation: ServiceTranslation;

    public countGiftVoucher: number = 0;

    public countOnlineSale: number = 0;

    constructor(
        private _dialog: MatDialog,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _serviceService: ServiceService,
        private _offerService: OfferService,
        public translationService: TranslationService
    ) {}

    ngOnInit(): void {

        this._loadSocietyServices();

        if(this.isGiftVoucherService && this.hasAccessToGiftVoucher && this.hasAccess('OFFER_LIST')){

            this._loadGiftVoucher();
        }

        this.serviceTranslation = this.translationService.getFallbackTranslation(this.item.translations);
    }

    private _loadSocietyServices(): void {

        const params: string[] = [
            `service.id[eq]=${this.item.id}`
        ];

        this._serviceService.getItemsServiceBySocietyWithoutPagination(this.user.society.id, params).subscribe((societyServices: SocietyService[]) => {

            this._societyServices = societyServices;
        });
    }

    private _loadGiftVoucher(): void {

        const observables: Observable<number>[] = [];

        // Offres disponibles en bon cadeau

        const giftVoucherParams: string[]= [
            `giftVoucher.enable[eq]=1`
        ]

        observables.push(this._offerService.getPaginationItemsAPI(giftVoucherParams).pipe(map((data: Pagination<Offer>): number => {

            return data.totalItems;
        })));

        // Offres disponibles en vente en ligne

        const onlineSaleParams: string[]= [
            `onlineSale.enable[eq]=1`
        ];

        observables.push(this._offerService.getPaginationItemsAPI(onlineSaleParams).pipe(map((data: Pagination<Offer>): number => {

            return data.totalItems;
        })));

        forkJoin(observables).subscribe((data: [number, number]): void => {

            this.countGiftVoucher = data[0];

            this.countOnlineSale = data[1];
        });
    }

    public openAboutServiceDialog(): void {

        const dialogRef: MatDialogRef<AboutServiceDialogComponent> = this._dialog.open(AboutServiceDialogComponent, {
            width: '600px',
            data: {
                service: this.item,
                hasValidMangoPayAccount: this.hasValidMangoPayAccount,
                isRegistrationEnabled: this.isAccountAdmin() && this.isRegistrationEnabled,
                user: this.user
            }
        });

        dialogRef.componentInstance.select.subscribe((): void => {

            this.subscribe();

            dialogRef.close();
        });
    }

    public subscribe(): void {

        if(!this.isAccountAdmin()){

            return;
        }

        if(!this.isRegistrationEnabled){

            return;
        }

        if(this.item.isValidatedMangoPayAccountRequired && !this.hasValidMangoPayAccount){

            return;
        }

        if(this.isGiftVoucherService && this.isAccountAdmin()){

           this._router.navigate(['account/service/subscription/gift-voucher', this.item.id]);
        }
        else {

            this._router.navigate(['account/service/subscription/create', this.item.id],{ queryParams : { origin: 'list' }});
        }

    }

    public openDeleteSubscriptionDialog(): void {

        if(this.hasDeregistrationInProcess){

            return;
        }

        const dialogRef: MatDialogRef<ServiceSubscriptionDeleteDialogComponent> = this._dialog.open(ServiceSubscriptionDeleteDialogComponent, {
            width: '1000px',
            data: {
                user: this.user,
                service: this.item,
                societyService: this.societyServiceItem
            }
        });

        dialogRef.componentInstance.delete.subscribe((): void => {


            if(this.societyServiceItem && !this.societyServiceItem.toDelete){

                this._serviceService.deleteSocietyServiceItemAPI(this.societyServiceItem.id).subscribe((): void => {

                    let message: string = '';

                    switch(true) {

                        case this.societyServiceItem.service.isWithSubscription :

                            message = this._translateService.instant('user.account.services.deactivate.success.value');
                            break;

                        case !this.societyServiceItem.service.isWithSubscription && this.societyServiceItem.service.unsubscriptionDelay === 0 :

                            message = this._translateService.instant('user.account.services.deactivate.without_subscription.now.success.value');

                            break;

                        case !this.societyServiceItem.service.isWithSubscription && this.societyServiceItem.service.unsubscriptionDelay > 0 :

                            message = this._translateService.instant('user.account.services.deactivate.without_subscription.success.value', { hour : this.societyServiceItem.service.unsubscriptionDelay});

                            break;
                    }

                    this._snackBar.open(message, this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this._loadSocietyServices();
                });
            }

            dialogRef.close();

        });
    }

    public cancelDeactivationService(): void {

        const title: string = this._translateService.instant('services.list.item.action.service.cancel_deactivation.value');

        const content: string = this._translateService.instant(`services.list.item.action.service.cancel_deactivation.confirm.value`);

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title,
                content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            if(this.societyServiceItem && this.societyServiceItem.toDelete){

                this._serviceService.cancelDeleteSocietyServiceItemAPI(this.societyServiceItem.id).subscribe((data): void => {

                    this._snackBar.open(this._translateService.instant('user.account.services.deactivate.cancel.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this._loadSocietyServices();

                });

            }

        });

    }

    public openRequestDocumentDialog() : void {

        const dialogRef: MatDialogRef<ServiceRequestDocumentDialogComponent> = this._dialog.open(ServiceRequestDocumentDialogComponent, {
            width: '500px',
            data: {
                user: this.user
            }
        });



    }

    public formatPrice(price: number, symbol: string): string {

        return parsePrice(price / 100) + ` € ${symbol}`;
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public manageService(): void {

        if(!this.isManagementEnabled && !this.isConfigurable){

            return;
        }

        this._router.navigate(['account/service/subscription/update', this.societyServiceItem.id]);
    }

    public isAccountAdmin(): boolean {

        return !!this.user.societyAdmin;
    }

    public hasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.user.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    get hasValidMangoPayAccount(): boolean {

        return this.user.society.isValidMangoPayAccount === true;
    }

    get isRegistrationEnabled(): boolean {

        return (this._societyServices.length === 0) || this._societyServices.every((societyService: SocietyService): boolean => {

            return societyService.paymentStatus === 0 && (societyService.paymentId === null);
        });
    }

    get isManagementEnabled(): boolean {

        return this.isAccountAdmin() && this._societyServices.some((societyService): boolean => {

            return (societyService.paymentStatus === 1);
        });
    }

    get hasDeregistrationInProcess(): boolean {

        return this._societyServices.some((societyService): boolean => {

            return (societyService.paymentStatus === 1) && societyService.isValid && societyService.toDelete;
        });
    }

    get hasSubscriptionCreationInProcess(): boolean {

        return this._societyServices.some((societyService): boolean => {

            const conditions: boolean[] = [
                !societyService.service.isValidatedMangoPayAccountRequired && !societyService.isValid,
                societyService.paymentStatus === 0 && (societyService.paymentId !== null)
            ];

            return conditions.some((condition: boolean): boolean => {

                return Boolean(condition);
            });
        });
    }

    get societyServiceItem(): SocietyService {

        return this._societyServices.find((societyService: SocietyService): boolean => {

            return (societyService.paymentStatus === 1);
        });
    }

    get hasDisableSubscription(): boolean {

        if(this.isGiftVoucherService && !this.isAccountAdmin()){

            return true;
        }

        return this.item.isValidatedMangoPayAccountRequired && !this.hasValidMangoPayAccount;
    }

    get subscriptionTooltipLabel(): string|null {

        switch (true){

            case !this.isAccountAdmin():

                return 'service.disable.adminContact.value';

            case this.hasSubscriptionCreationInProcess:

                return 'service.subscription.inProcess.value';

            default:

                return null;
        }
    }

    get managementTooltipLabel(): string|null {

        switch (true){

            case !this.isAccountAdmin():

                return 'service.disable.adminContact.value';

            case this.hasSubscriptionCreationInProcess:

                return 'service.subscription.inProcess.value';

            case this.hasDeregistrationInProcess:

                return 'service.deregistration.inProcess.value';

            default:

                return null;
        }
    }

    get deregistrationTooltipLabel(): string|null {

        switch (true){

            case !this.isAccountAdmin():

                return 'service.disable.adminContact.value';

            case this.item.isWithSubscription:

                return 'services.subscription.delete.tooltip.value';

            default:

                return null;
        }
    }

    get hasAccessToGiftVoucher(): boolean {

        return this.hasRole('ROLE_OFFER_DISTRIBUTOR') || (this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR'));
    }

    get isGiftVoucherService(): boolean {

        return this.item.tag === GIFT_VOUCHER_SERVICE_LABEL;
    }

    get isAdditionalWebsiteService(): boolean {

        return this.item.tag === ADDITIONAL_WEBSITE_SERVICE_LABEL;
    }

    get isConfigurable(): boolean {

        const items: boolean[] = [
            this.isGiftVoucherService,
            this.isAdditionalWebsiteService
        ];

        return items.some((item: boolean): boolean => {

            return Boolean(item);
        });
    }
}
