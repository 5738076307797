import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-core-tender-select-offer-dialog',
    templateUrl: './tender-select-offer-dialog.component.html',
    styleUrls: ['./tender-select-offer-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class TenderSelectOfferDialogComponent implements OnInit {

    @Output() select: EventEmitter<{ comment: string }> = new EventEmitter();

    public commentMaxLength: number = 1500;

    public addComment: boolean = true;

    constructor(
        private _dialogRef: MatDialogRef<TenderSelectOfferDialogComponent>,
        private _formBuilder: FormBuilder,
        public formService: FormService,
        @Inject(MAT_DIALOG_DATA) public data: { emitter: Society }
    ) {
    }

    ngOnInit() {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            comment: [null, [(control: FormControl) => {

                if(!this.addComment){

                    return null
                }

                if(control.value && Boolean(control.value.length)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };

            }, Validators.maxLength(this.commentMaxLength)]]
        });

        this.formService.submitCallback = (): void => {

            this.select.emit({
                comment: (this.form.get('comment').value && this.form.get('comment').value.length) ? this.form.get('comment').value : null
            });
        };

        const events: EventEmitter<any>[] = [
            this.select
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public handleComment(addComment: boolean): void {

        this.addComment = addComment;

        this.form.get('comment').patchValue(null);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    public close(): void {

        this._dialogRef.close();
    }
}
