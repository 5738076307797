import {UntypedFormGroup} from "@angular/forms";

export const offerAttributesValidator = (attributesFormReference: UntypedFormGroup) => {

    return (): { [key: string]: any } => {

        if(attributesFormReference.valid){

            return null;
        }

        return {
            'attributesRequired': {
                valid: false
            }
        };
    }
};
