<div class="page_title">
    <div class="row marges">
        <div class="col y_items_center">
            <div>
                <h1 class="title">{{ 'offer.priceAndAvailability.self.list.value' | translate }}</h1>
                <div class="offer_name">
                    <a [routerLink]="['/account/offer/read', offer.id]" [queryParams]="{ origin: 'offerAvailabilityRead' }">{{ translationService.getFallbackTranslation(offer.translations).name }}</a>
                    <span *ngIf="offer.reference" class="offer-reference">&nbsp;| {{ 'reference.value' | translate }} : {{ offer.reference }}</span>
                </div>
            </div>
        </div>

        <div class="col_content y_items_center">

            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                {{ 'offer.priceAndAvailability.list.back.value' | translate }}
            </button>

            <button class="btn_cta btn_little" type="button" (click)="redirectToUpdate()">
                {{ 'offer.priceAndAvailability.update.action.value' | translate }}
            </button>

        </div>
    </div>
</div>

<!-- Calendrier -->

<div class="bloc_availability calendar-container">

    <app-core-offer-availability-calendar [offer]="offer" (dateClicked)="openReadDatePanel($event)">
        <ng-container stopSale>
            <div class="col_content y_items_start" *ngIf="offer.onlineSale && offer.onlineSale.enable">
                <div class="stop_vente">
                    <label for="toggle-stop-sale">{{ 'sale.stop.value' | translate }}</label>
                    <mat-slide-toggle id="toggle-stop-sale" [checked]="offer.onlineSale.stopSale" [disabled]="true"></mat-slide-toggle>
                </div>
            </div>
        </ng-container>
    </app-core-offer-availability-calendar>

</div>

<!-- Détails d'une date -->

<ng-container *ngIf="dateReadConfiguration.enable">

    <div class="offer_date_panel">

        <app-core-offer-date-read [offer]="offer" [date]="dateReadConfiguration.date" [offerDateId]="dateReadConfiguration.offerDateId" (close)="closeReadDatePanel()"></app-core-offer-date-read>

    </div>

</ng-container>
