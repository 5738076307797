import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";

@Injectable({
    providedIn: 'root'
})
export class NetworkOfferCreatorItemResolver  {

    constructor(
        private _networkOfferCreatorService: NetworkOfferCreatorService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._networkOfferCreatorService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
