<h1 mat-dialog-title>
    {{ 'language.add' | translate }}
</h1>
<div mat-dialog-content>

    <!-- Langues disponibles -->

    <div *ngFor="let locale of availableLocales" class="langue-supp">
        <label for="locale_{{ locale.id }}" class="input-checkbox">
            <input id="locale_{{ locale.id }}" type="checkbox" [checked]="locale.checked" (change)="locale.checked = !locale.checked"/>
            <span class="label">{{ locale.name }}</span>
        </label>
    </div>

</div>
<div mat-dialog-actions>
    <div class="duplicate-bloc">
        <label for="duplicate" class="input-checkbox">
            <input type="checkbox" id="duplicate" class="duplicate" [(ngModel)]="duplicate"/>
            <span class="label">Dupliquer</span>
        </label>
        <select *ngIf="content" [(ngModel)]="duplicateLocaleId" (change)="checkedDuplicate()">
            <option [ngValue]="null" disabled selected>Choisir une langue</option>
            <option *ngFor="let translation of content.translations" [ngValue]="translation.locale">
                {{ getLocaleById(translation.locale).name }}
            </option>
        </select>
    </div>
    <button class="btn_cta btn_small btn_lines" (click)="close()">Annuler</button>
    <button class="btn_cta btn_small" (click)="save()" [disabled]="!isRegistrable()">Enregistrer</button>
</div>
