<div [formGroup]="form" class="form-group">
    <mat-label *ngIf="config.attrs.label">{{config.attrs.label}}</mat-label>

    <div class="form-group semi-group">
        <mat-form-field>
            <input matInput appInput [attrs]="config.attrs" (click)="picker.open()"  [formControlName]="config.id+ '[date]'" [matDatepicker]="picker" placeholder="{{config.attrs.placeholderDate}}" (dateChange)="dateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-error>
            <div *ngIf="form.controls[config.id+ '[date]'].invalid && (form.controls[config.id + '[date]'].dirty || form.controls[config.id+ '[date]'].touched)">
                <div *ngIf="form.controls[config.id+ '[date]'].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            </div>
        </mat-error>
    </div>

    <div class="form-group semi-group">
        <mat-form-field>
            <input matInput placeholder="{{config.attrs.placeholderHeure}}" aria-label="24hr format" [formControlName]="config.id+ '[time]'" [ngxTimepicker]="fullTime" [format]="24"  readonly>
            <ngx-material-timepicker #fullTime></ngx-material-timepicker>
        </mat-form-field>
        <mat-error>
            <div *ngIf="form.controls[config.id+ '[time]'].invalid && (form.controls[config.id+ '[time]'].dirty || form.controls[config.id+ '[time]'].touched)">
                <div *ngIf="form.controls[config.id+ '[time]'].errors.required">{{ 'form.validation.error.required' | translate }}</div>
            </div>
        </mat-error>
    </div>

</div>
