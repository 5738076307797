<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'registrationAffiliation.add.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToOrigin()">
                    {{ origin.backLabel | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>

    <mat-tab-group [@.disabled]="true">
        <mat-tab label="{{'registrationAffiliation.generalData.value' | translate}}">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                        <label for="name">{{'registrationAffiliation.list.fields.name.value' | translate}}</label>
                        <mat-error
                            *ngIf="form.get('name').errors && (form.get('name').dirty || form.get('name').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon> {{ 'registrationAffiliation.form.name.help.value' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <textarea class="form-control" formControlName="comment" id="comment"></textarea>
                        <label for="comment">{{'registrationAffiliation.list.fields.comment.value' | translate}}</label>
                        <mat-error
                            *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <div class="form-group add_picture big">
                        <div class="row">
                            <div class="form-group">
                                <label>{{'registrationAffiliation.registrationLogo.value' | translate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col_content">
                                <form-field-file [config]="registrationLogoConfig" [form]="form" [file]="form.get('registrationLogo').value"></form-field-file>
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'registrationAffiliation.registrationLogo.info.value' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col_6">
                    <div class="form-group add_picture big">
                        <div class="row">
                            <div class="form-group">
                                <label>{{'registrationAffiliation.administrationLogo.value' | translate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col_content">
                                <form-field-file [config]="administrationLogoConfig" [form]="form" [file]="form.get('administrationLogo').value"></form-field-file>
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'registrationAffiliation.administrationLogo.info.value' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</form>
