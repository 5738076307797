export type PromotionStatusType = 'available' | 'closed';

export interface PromotionStatus {

    label: string;

    value: PromotionStatusType;
}

export const PROMOTION_STATUS_ITEMS: PromotionStatus[] = [
    {
        label: 'promotion.status.available.value',
        value: 'available'
    },
    {
        label: 'promotion.status.closed.value',
        value: 'closed'
    }
];
