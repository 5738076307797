import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {Society} from "@core/shared/models/society";
import {SocietyPicture} from "@core/shared/models/society/society-picture";

@Pipe({
    name: 'offerCreatorPictureSource'
})
export class OfferCreatorPictureSourcePipe implements PipeTransform {

    constructor(
        private _apiService: ApiService
    ){
    }

    transform(offerCreator: Society, picture: SocietyPicture, format: string, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public/offerCreators/${offerCreator.id}/pictures/${picture.id}/filter/${format}/${width}/${quality}`;
    }
}
