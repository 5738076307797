<div class="back_to_list">
    <span class="ty-chevron" (click)="backToOfferRequested.emit()">{{ 'offer.back.value' | translate }}</span>
</div>

<div id="offer-gift-voucher">

    <ng-container *ngIf="isView('composition')">

        <app-offer-gift-voucher-composition [offer]="offer"
                                            [data]="compositionData"
                                            [user]="currentUser"
                                            [termsAndConditions]="termsAndConditions"
                                            (compositionSubmitted)="initConfigurationForm($event)">

            <ng-container recapItem *ngTemplateOutlet="recapItem"></ng-container>

        </app-offer-gift-voucher-composition>

    </ng-container>

    <ng-container *ngIf="isView('configuration')">

        <app-offer-gift-voucher-configuration [offer]="offer"
                                              (backToCompositionRequested)="setView('composition')"
                                              [giftVoucher]="giftVoucher"
                                              [user]="currentUser"
                                              [generalData]="compositionData"
                                              (backToOfferRequested)="backToOfferRequested.emit()"
                                              (reloadSummaryRequested)="loadGiftVoucherSummary()">

            <ng-container recapItem *ngTemplateOutlet="recapItem"></ng-container>

        </app-offer-gift-voucher-configuration>

    </ng-container>

</div>


<ng-template #recapItem>

    <div>

        <!-- Titre -->

        <div class="title ck-content">
            <p class="titre_h4">{{ offerTranslation.name }}</p>
        </div>

        <!-- Visuel -->

        <div class="visuel" *ngIf="offerTranslation.pictures.length">
            <img [src]="getVisuelOffer(offer.id, offerTranslation.pictures[0].id)" alt="{{offerTranslation.name}}"/>
        </div>

        <div class="infos" *ngIf="giftVoucher">
            <!-- Description -->
            <div class="teaser">
                <p [innerHTML]="offerTranslation.teaser | safeHtml"></p>
            </div>

            <!-- Composition -->

            <div class="row">
                <div class="col_12">
                    <p class="label">{{ 'offer.giftVoucher.composition.value'| translate }}</p>
                </div>
                <div class="col_12 x_end nbPerson">

                    <span *ngIf="giftVoucher.nbAdult && giftVoucher.nbAdult > 0">{{ 'offer.giftVoucher.presential.adult.value' | translate:{count: giftVoucher.nbAdult} }}</span>

                    <span *ngIf="giftVoucher.nbChild && giftVoucher.nbChild > 0">{{ 'offer.giftVoucher.presential.child.value' | translate:{count: giftVoucher.nbChild} }}</span>

                </div>
            </div>

            <!-- Date limite de validité -->

            <div class="row">
                <div class="col_12">
                    <p class="label">{{ 'offer.giftVoucher.validity.duration.value'| translate }}</p>
                </div>
                <div class="col_12 x_end">
                    <p class="value">{{ giftVoucher.expiryDate | date : 'dd/MM/yyyy' : null : localeId }}</p>
                </div>
            </div>

            <!-- Format de réception -->

            <ng-container *ngIf="giftVoucher.type">

                <div class="row">
                    <div class="col_12">
                        <p class="label">{{ 'offer.giftVoucher.receptionFormat.value'|translate }}</p>
                    </div>
                    <div class="col_12 x_end">
                        <p class="value">{{ 'offer.giftVoucher.type.' + giftVoucher.type + '.value' | translate }}</p>
                    </div>
                </div>

            </ng-container>

            <!-- Prix du bon cadeau -->

            <ng-container *ngIf="giftVoucher.price && giftVoucher.price > 0">

                <div class="row">
                    <div class="col_12">
                        <p class="label">{{ 'offer.giftVoucher.price.value'| translate }}</p>
                    </div>
                    <div class="col_12 x_end">
                        <p class="value price {{ giftVoucher.promotionPrice < giftVoucher.price ? 'old_price'  : ''}}">{{ formatPrice(giftVoucher.price, giftVoucher.currency.symbol) }} {{ 'offer.price.type.ttc.value'|translate }}</p>
                        <ng-container *ngIf="giftVoucher.promotionPrice < giftVoucher.price">
                            <p class="value price">{{ formatPrice(giftVoucher.promotionPrice, giftVoucher.currency.symbol) }} {{ 'offer.price.type.ttc.value'|translate }}</p>
                        </ng-container>
                    </div>
                </div>

            </ng-container>

            <!-- Coût de livraison -->

            <ng-container *ngIf="giftVoucher.transporter && giftVoucher.transporterPriceTTC && giftVoucher.transporterPriceTTC > 0">

                <div class="row">
                    <div class="col_12">
                        <p class="label">{{ 'offer.giftVoucher.transporter.price.value'|translate }}</p>
                    </div>
                    <div class="col_12 x_end">
                        <p class="value">{{ formatPrice(giftVoucher.transporterPriceTTC, giftVoucher.currency.symbol) }} {{ 'offer.price.type.ttc.value'|translate }}
                    </div>
                </div>

            </ng-container>

            <!-- Total -->

            <ng-container *ngIf="giftVoucher.totalPrice && giftVoucher.totalPrice > 0">

                <div class="row">
                    <div class="col_12">
                        <p class="label">{{ 'offer.giftVoucher.totalPrice.value'| translate }}</p>
                    </div>
                    <div class="col_12 x_end">
                        <p class="value price">{{ formatPrice(giftVoucher.totalPrice, giftVoucher.currency.symbol) }} {{ 'offer.price.type.ttc.value'|translate }}</p>
                    </div>
                </div>

            </ng-container>

        </div>

    </div>

</ng-template>
