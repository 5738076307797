import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {User} from '@core/shared/models/user';
import {UserService} from '@core/shared/services/user.service';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {SocietyService} from '@core/shared/services/society.service';
import {SubscriptionService} from '@core/shared/services/subscription.service';
import {Subscription} from '@core/shared/models/subscription';
import {FormService} from '@core/shared/services/form.service';
import {Access} from "@core/shared/models/access";
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {concat} from "rxjs";
import {toArray} from "rxjs/operators";
import {MatLegacyTab as MatTab, MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";

@Component({
    selector: 'app-page-subscription-update',
    templateUrl: './page-subscription-list.component.html',
    styleUrls: ['./page-subscription-list.component.scss'],
    providers: [
        FormService
    ]
})
export class PageSubscriptionListComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('licenceInformationsTab', {static: true}) licenceInformationsTabRef: MatTab;

    public user: User;

    public selectedIndex: number;

    public societySubscriptions: SocietySubscription[] = [];

    public subscriptions: Subscription[] = [];

    public createdAt: string[] = [];

    public societyDelete: boolean = false;

    public societyDeleteDate: string = null;

    public paymentWaiting: boolean = false;

    public downgradeWaiting: boolean = false;

    public subscriptionsToDowngrade: number[] = [];

    constructor(
        private _userService: UserService,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _subscriptionService: SubscriptionService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        public formService: FormService
    ) {
        if (window.history.state.template === 'licenceInformations') {

            this.selectedIndex = 1;
        }
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { subscriptions: Subscription[], societySubscriptions: SocietySubscription[] }): void => {

            this.subscriptions = data.subscriptions;

            this.societySubscriptions = data.societySubscriptions;

            let countNoValid: number = 0;

            let countToPay: number = 0;

            data.societySubscriptions.forEach((subscription: SocietySubscription): void => {

                if(!subscription.isValid && subscription.paymentStatus === 0){

                    countNoValid++;
                }

                if(subscription.paymentStatus === 2){

                    this.subscriptionsToDowngrade.push(subscription.id);

                    countToPay++;
                }
            });

            this.paymentWaiting = (countNoValid > 0);

            this.downgradeWaiting = (countToPay > 0);

            if(this.currentValidSocietySubscription && this.currentValidSocietySubscription.toDelete){

                this.societyDelete = true;

                this.societyDeleteDate = this.currentValidSocietySubscription.toDeleteAt;
            }
        });
    }

    ngAfterViewInit() {

        if('targetTab' in this._activatedRoute.snapshot.queryParams){

            switch (this._activatedRoute.snapshot.queryParams['targetTab']){

                case 'licenceInformations':

                    this.tabGroup.selectedIndex = this.licenceInformationsTabRef.position;

                    break;
            }
        }

    }

    public updateLicence(): void {

        this._router.navigate(['account/subscription/licence/update'])
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public cancelDowngradeLicence(): void {

        const title: string = this._translateService.instant('subscription.title.cancel_downgrade.value');

        const content: string = this._translateService.instant('subscription.title.cancel_downgrade.confirm.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            const arrayToForkJoin = [];

            this.subscriptionsToDowngrade.forEach((subscriptionId ) => {

                arrayToForkJoin.push(this._subscriptionService.deleteItemAPI(subscriptionId.toString()));
            });

            concat(
                ...arrayToForkJoin
            ).pipe(toArray()).subscribe(() => {

                this._snackBar.open(this._translateService.instant('subscription.title.cancel_downgrade.success.value'), this._translateService.instant('notification.close.action.value'), {
                            duration: 5000
                });

                this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
            });
        });
    }

    /**
     * Annulation de la demande de désactivation d'abonnement
     */
    public cancelDeactivationSubscription(): void {

        const title: string = this._translateService.instant('subscription.title.cancel_deactivation.value');

        const content: string = this._translateService.instant(`subscription.title.cancel_deactivation.confirm.value`);

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title,
                content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyService.cancelDeleteItemAPI(this.user.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.account.program.reactivate.success.value'),
                    this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
            });
        });
    }

    get currentValidSocietySubscription(): SocietySubscription {

        return this.societySubscriptions.find((item: SocietySubscription): boolean => {

            return item.isValid && (item.paymentStatus === 1);
        });
    }

    get hasAccessPayment(): boolean {

        const accesses = this.user.accesses.filter((access: Access) => {

            return access.tag === 'PAYMENT_SOCIETY_IS_MINE';
        });

        return (accesses.length > 0);
    }
}
