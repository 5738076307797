import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "../api.service";
import {GiftVoucherDate} from "@core/shared/models/gift-voucher/gift-voucher-date";

@Injectable({
    providedIn: 'root'
})
export class GiftVoucherDateService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(offerDataId: number, params?: string[]): Observable<GiftVoucherDate[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/giftVouchers/offers/data/${offerDataId}/availabilities`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<GiftVoucherDate[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): GiftVoucherDate[] => {

                return items.map((item: object): GiftVoucherDate => {

                    return plainToClass(GiftVoucherDate, item);
                });
            })
        );
    }
}
