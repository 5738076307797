import {Field, FieldConfig, InputType} from "./fields/input";

/**
 * Collection de champs
 */
export class FieldCollection {

    /**
     * Champs du formulaire
     */
    private _fields: Field[] = [];

    /**
     * Ajout d'un champ au formulaire
     *
     * @param field Champ
     */
    public addField(field: Field): void {
        this._fields.push(field);
    }

    /**
     * Récupération d'un champ
     *
     * @param id Id du champ
     */
    public getField(id: string): Field {

        return this._fields.find((field: Field): boolean => {
            return field.config.id === id;
        });
    }

    /**
     * Récupération des champs du formulaire
     */
    public getFields(): Field[] {

        return this._fields;
    }

    /**
     * Récupération de la configuration d'un champ
     *
     * @param id
     */
    public getFieldConfig(id: string): FieldConfig {

        const field: Field = this.getField(id);

        if(!field){
            return null;
        }

        return field.config;
    }
}

/**
 * Helper pour les champs de formulaire
 */
export class FieldService {

    /**
     * Vérification si le champ est de type text
     * @param type
     */
    public isText(type: InputType): boolean {
        return type === 'text';
    }

    /**
     * Vérification si le champ est de type password
     * @param type
     */
    public isPassword(type: InputType): boolean {
        return type === 'password';
    }

    /**
     * Vérification si le champ est de type textarea
     * @param type
     */
    public isTextarea(type: InputType): boolean {
        return type === 'textarea';
    }

    /**
     * Vérification si le champ est de type select
     * @param type
     */
    public isSelect(type: InputType): boolean {
        return type === 'select';
    }

    /**
     * Vérification si le champ est de type radio
     * @param type
     */
    public isRadio(type: InputType): boolean {
        return type === 'radio';
    }

    /**
     * Vérification si le champ est de type checkbox
     * @param type
     */
    public isCheckbox(type: InputType): boolean {
        return type === 'checkbox';
    }

    /**
     * Vérification si le champ est de type date
     * @param type
     */
    public isDate(type: InputType): boolean {
        return type === 'date';
    }

    /**
     * Vérification si le champ est de type daterange
     * @param type
     */
    public isDaterange(type: InputType): boolean {
        return type === 'daterange';
    }

    /**
     * Vérification si le champ est de type datetime
     * @param type
     */
    public isDatetime(type: InputType): boolean {
        return type === 'datetime';
    }

    /**
     * Vérification si le champ est de type time
     * @param type
     */
    public isTime(type: InputType): boolean {
        return type === 'time';
    }

    /**
     * Vérification si le champ est de type ckeditor
     * @param type
     */
    public isCkeditor(type: InputType): boolean {
        return type === 'ckeditor';
    }

    /**
     * Vérification si le champ est de type number
     * @param type
     */
    public  isNumber(type: InputType): boolean {
        return type === 'number';
    }

    /**
     * Vérification si le champ est de type toggle
     * @param type
     */
    public  isToggle(type: InputType): boolean {
        return type === 'toggle';
    }

    /**
     * Vérification si le champ est de type select 2
     * @param type
     */
    public  isSelectSearch(type: InputType): boolean {
        return type === 'select-search';
    }

    /**
     * Vérification si le champ est de type image
     *
     * @param type
     */
    public isImage(type: InputType): boolean {

        return type === 'image';
    }

    /**
     * Vérification si le champ est de type image collection
     *
     * @param type
     */
    public isImageCollection(type: InputType): boolean {

        return type === 'image_collection';
    }

    /**
     * Vérification si le champ est de type file
     *
     * @param type
     */
    public isFile(type: InputType): boolean {

        return type === 'file';
    }
}
