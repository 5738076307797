import {WeekDay} from "@angular/common";

export interface Day {

    index: WeekDay;

    label: string;
}

export const DAYS: Day[] = [
    {
        index: WeekDay.Monday,
        label: 'day.monday.value'
    },
    {
        index: WeekDay.Tuesday,
        label: 'day.tuesday.value'
    },
    {
        index: WeekDay.Wednesday,
        label: 'day.wednesday.value'
    },
    {
        index: WeekDay.Thursday,
        label: 'day.thursday.value'
    },
    {
        index: WeekDay.Friday,
        label: 'day.friday.value'
    },
    {
        index: WeekDay.Saturday,
        label: 'day.saturday.value'
    },
    {
        index: WeekDay.Sunday,
        label: 'day.sunday.value'
    }
];
