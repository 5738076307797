<h1 mat-dialog-title class="x_center">{{ 'giftVoucher.configuration.form.fields.template.' + (data.template ? 'edit' : 'add') + '.value' | translate }}</h1>

<mat-dialog-content>

    <app-template-gift-voucher-form
            [item]="data.template"
            [user]="data.user"
            [parentForm]="form"
            [translationBuilder]="translationBuilder">
    </app-template-gift-voucher-form>

</mat-dialog-content>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="back.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
    </div>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="formService.submit()" [disabled]="form.invalid">{{ 'form.submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
