import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {InputConfig} from "../input";
/**
 * Configuration paramètres du champ
 */
export interface ToggleConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        label?: string;
        defaultValue? : boolean;
    };
}

/**
 * Champ toggle
 */
@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
    /**
     * Paramètres du champ
     */
    @Input() config: ToggleConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    @Output() valueToggleChange = new EventEmitter();

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {

        if(this.config.attrs.defaultValue){
            this.form.addControl(this.config.id, new UntypedFormControl(true));

        }else{
            this.form.addControl(this.config.id, new UntypedFormControl(false));

        }

    }
}
