<div class="page_title">
    <div class="row marges">
        <div class="col y_items_center">
            <div>
                <h1 class="title">{{ 'offer.priceAndAvailability.update.action.value' | translate }}</h1>

            </div>
        </div>

        <div class="col_content y_items_center">


            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToOfferUpdate()">
                {{ 'offer.list.item.action.update.value' | translate }}
            </button>

            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToOfferView()">
                {{ 'offer.list.item.action.see.value' | translate }}
            </button>

            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToRead()">
                {{ 'back.value' | translate }}
            </button>

        </div>
    </div>
</div>
<div class="bloc_availability">

    <div class="row marges actions_availability">

        <div class="col y_items_center">
            <p>{{ 'offer.period.create.help.value' | translate }}</p>
        </div>

        <div class="col_content y_items_center">

            <button class="btn_cta btn_little" type="button" (click)="openCreatePeriodPanel()">
                {{ 'offer.period.create.action.value' | translate }}
            </button>
        </div>

        <div class="col_content y_items_center">

            <button class="btn_cta btn_lines btn_little" type="button" (click)="openDeletePeriodDialog()">
                {{ 'offer.period.delete.action.value' | translate }}
            </button>

        </div>

    </div>

    <div class="row marges">
        <div class="col_12">

            <!-- Calendrier -->

            <div class="calendar-container">

                <app-core-offer-availability-calendar [offer]="offer" (dateClicked)="handleDateConfiguration($event)">
                    <ng-container stopSale>
                        <div class="col_content y_items_start" *ngIf="offer.onlineSale && offer.onlineSale.enable">
                            <div class="stop_vente">
                                <label for="toggle-stop-sale">{{ 'sale.stop.value' | translate }}</label>
                                <mat-slide-toggle id="toggle-stop-sale" [(ngModel)]="stopSale" (click)="openUpdateStopSaleDialog($event)"></mat-slide-toggle>
                            </div>
                        </div>
                    </ng-container>
                </app-core-offer-availability-calendar>

            </div>

        </div>
    </div>

</div>

<!-- Création d'une période -->

<ng-container *ngIf="periodCreateConfiguration.enable">

    <div class="offer_date_panel">

        <app-core-offer-period-create [offer]="offer" (close)="closePanels()" (periodCreated)="handlePeriodCreated()"></app-core-offer-period-create>

    </div>

</ng-container>

<!-- Création d'une date -->

<ng-container *ngIf="dateCreateConfiguration.enable">

    <div class="offer_date_panel">

        <app-core-offer-date-create [offer]="offer" [date]="dateCreateConfiguration.date" (close)="closePanels()" (offerDateCreated)="handleDateCreated()"></app-core-offer-date-create>

    </div>

</ng-container>

<!-- Mise à jour d'une date -->

<ng-container *ngIf="dateUpdateConfiguration.enable">

    <div class="offer_date_panel">

        <app-core-offer-date-update [offer]="offer" [date]="dateUpdateConfiguration.date" [offerDateId]="dateUpdateConfiguration.offerDateId" (close)="closePanels()" (offerDateUpdated)="handleDateUpdated()"></app-core-offer-date-update>

    </div>

</ng-container>
