import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Location, LocationField, LocationFieldType} from "@core/shared/models/location";
import {MatLegacyAutocompleteTrigger as MatAutocompleteTrigger} from "@angular/material/legacy-autocomplete";
import {fromEvent} from "rxjs";
import {map} from "rxjs/operators";
import {GoogleService} from "@core/shared/services/google.service";

@Component({
    selector: 'app-core-google-places-location',
    templateUrl: './google-places-location.component.html',
    styleUrls: ['./google-places-location.component.scss']
})
export class GooglePlacesLocationComponent implements AfterViewInit {

    /**
     * Liste des champs de localisation
     */
    @Input() fields: LocationField[] = [];

    /**
     * Événement déclenché lors de la sélection d'une localisation
     */
    @Output() onSelection: EventEmitter<Location> = new EventEmitter<Location>();

    /**
     * Accesseur pour le champ de saisie d'une localisation
     */
    @ViewChild('location', { static: true }) location: ElementRef;

    @ViewChild(MatAutocompleteTrigger, { static: true, read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

    /**
     * Liste des localisations retournées par l'API
     */
    private _items: google.maps.places.AutocompletePrediction[] = [];

    constructor(
        private _googleService: GoogleService
    ){
    }

    /**
     * @ignore
     */
    ngAfterViewInit(): void {

        this._initEvents();
    }

    private _initEvents(): void {

        fromEvent(this.location.nativeElement, 'keypress').subscribe((event: KeyboardEvent): void => {

            if(event.code === 'Enter'){

                event.preventDefault();
            }
        });

        fromEvent(this.location.nativeElement, 'keyup').subscribe((event: KeyboardEvent): void => {

            if(event.key === 'Enter'){

                if(!this.location.nativeElement.value.length){

                    return;
                }

                this.search();
            }
        });
    }

    private _retrieveComponentAddressByTypes(components: google.maps.GeocoderAddressComponent[], types: string[]): google.maps.GeocoderAddressComponent {

        const matches: google.maps.GeocoderAddressComponent[] = components.filter((component: google.maps.GeocoderAddressComponent): boolean => {

            return component.types.some((type: string): boolean => {

                return types.includes(type);
            });
        });

        if(!matches.length){

            return null;
        }

        return matches[0];
    }

    /**
     * Retourne une liste de champs en fonction du type
     *
     * @param type
     */
    private _getFieldsByType(type: LocationFieldType): LocationField[] {

        return this.fields.filter((field: LocationField): boolean => {

            return field.type === type;
        });
    }

    /**
     * Réinitialise la liste des localisations retournées par l'API
     */
    private _clearItems(): void {

        this._items = [];
    }

    /**
     * Sélectionne une localisation
     *
     * @param prediction google.maps.places.AutocompletePrediction
     */
    public select(prediction: google.maps.places.AutocompletePrediction): void {

        this.location.nativeElement.value = '';

        this._googleService.placeDetailsAPI({ placeId: prediction.place_id }).subscribe((data: { result: google.maps.places.PlaceResult }): void => {

            const components: google.maps.GeocoderAddressComponent[] = data.result.address_components;

            const location: Location = {
                label: data.result.formatted_address,
                housenumber: this._retrieveComponentAddressByTypes(components, ['street_number'])?.long_name || null,
                street: this._retrieveComponentAddressByTypes(components, ['route', 'intersection'])?.long_name || null,
                postcode: this._retrieveComponentAddressByTypes(components, ['postal_code'])?.long_name || null,
                city: this._retrieveComponentAddressByTypes(components, ['locality', 'sublocality', 'postal_town'])?.long_name || null,
                department: this._retrieveComponentAddressByTypes(components, ['administrative_area_level_2', 'administrative_area_level_3', 'administrative_area_level_4', 'administrative_area_level_5', 'administrative_area_level_6', 'administrative_area_level_7'])?.long_name || null,
                region: this._retrieveComponentAddressByTypes(components, ['administrative_area_level_1'])?.long_name || null,
                countryISO: this._retrieveComponentAddressByTypes(components, ['country'])?.short_name || null,
                // @ts-ignore
                latitude: data.result.geometry.location.lat,
                // @ts-ignore
                longitude: data.result.geometry.location.lng
            };

            this._getFieldsByType(LocationFieldType.Housenumber).forEach((field: LocationField): void => {

                field.reference.setValue(location.housenumber);
            });

            this._getFieldsByType(LocationFieldType.Street).forEach((field: LocationField): void => {

                field.reference.setValue(`${location.housenumber ? (location.housenumber + ' ') : ''}${location.street}`);
            });

            this._getFieldsByType(LocationFieldType.Postcode).forEach((field: LocationField): void => {

                field.reference.setValue(location.postcode);
            });

            this._getFieldsByType(LocationFieldType.City).forEach((field: LocationField): void => {

                field.reference.setValue(location.city);
            });

            this._getFieldsByType(LocationFieldType.Department).forEach((field: LocationField): void => {

                field.reference.setValue(location.department);
            });

            this._getFieldsByType(LocationFieldType.Region).forEach((field: LocationField): void => {

                field.reference.setValue(location.region);
            });

            this._getFieldsByType(LocationFieldType.CountryISO).forEach((field: LocationField): void => {

                field.reference.setValue(location.countryISO);
            });

            this._getFieldsByType(LocationFieldType.Latitude).forEach((field: LocationField): void => {

                field.reference.setValue(location.latitude);
            });

            this._getFieldsByType(LocationFieldType.Longitude).forEach((field: LocationField): void => {

                field.reference.setValue(location.longitude);
            });

            this._clearItems();

            this.location.nativeElement.value = location.label;

            this.onSelection.emit(location);
        });
    }

    public search(): void {

        this.inputAutoComplete.closePanel();

        if(this.location.nativeElement.value.length){

            this._googleService.placeAutocompleteAPI({ query: this.location.nativeElement.value })
                .pipe(
                    map(this.apiTransform)
                )
                .subscribe((items: google.maps.places.AutocompletePrediction[]): void => {

                    this._items = items;

                    this.inputAutoComplete.openPanel();
                })
            ;
        }
        else{

            this._items = [];
        }
    }

    /**
     * Retourne la méthode de transformation permettant de retourner une liste de localisations conformes
     */
    get apiTransform(): (data: google.maps.places.AutocompleteResponse) => google.maps.places.AutocompletePrediction[] {

        return (data: google.maps.places.AutocompleteResponse): google.maps.places.AutocompletePrediction[] => {

            return data.predictions;
        }
    }

    /**
     * Retourne la liste des localisations retournées par l'API
     */
    get items(): google.maps.places.AutocompletePrediction[] {

        return this._items;
    }

    /**
     * Met à jour la liste des localisations retournées par l'API
     *
     * @param value
     */
    set items(value: google.maps.places.AutocompletePrediction[]) {

        this._items = value;
    }
}
