<div class="card_service">
    <div class="row h_full direction_y">
        <div class="col infos_wrapper">
            <div class="title">
                <div class="row h_full title_content">

                    <div class="col y_items_center">
                        <div>
                            <p>{{ serviceTranslation.name }}</p>
                        </div>
                    </div>

                    <!-- Souscription à MangoPay obligatoire -->

                    <ng-container *ngIf="item.isValidatedMangoPayAccountRequired">
                        <div class="col_content y_items_center">
                            <div class="logo">
                                <img src="assets/images/mangopay-logo.svg" alt="" title="" matTooltip="{{ 'services.list.item.mangopay.required.value' | translate }}" matTooltipPosition="above"/>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
            <div class="infos">

                <p class="description" [innerHTML]="serviceTranslation.description"></p>

                <ng-container *ngIf="isGiftVoucherService && hasAccessToGiftVoucher && hasAccess('OFFER_LIST')">

<!--                    <ng-container *ngIf="(countGiftVoucher > 0) && (countOnlineSale > 0)">-->

<!--                        <p class="description">{{ 'services.list.item.count.gift_voucher_and_online_sale.value' | translate: {countGiftVoucher: countGiftVoucher, countOnlineSale: countOnlineSale} }}</p>-->

<!--                    </ng-container>-->

                    <ng-container *ngIf="(countGiftVoucher > 0) && (countOnlineSale < 1)">

                        <p class="description">{{ 'services.list.item.count.gift_voucher.value' | translate: {count: countGiftVoucher} }}</p>

                    </ng-container>

<!--                    <ng-container *ngIf="(countGiftVoucher < 1) && (countOnlineSale > 0)">-->

<!--                        <p class="description">{{ 'services.list.item.count.online_sale.value' | translate: {count: countOnlineSale} }}</p>-->

<!--                    </ng-container>-->

                </ng-container>

                <div *ngIf="item.hasAdmissionPrice || item.isWithSubscription" class="price_wrapper" [ngClass]="!item.hasAdmissionPrice && !item.isWithSubscription ? 'disabled' : ''">

                    <div class="prices">

                        <!--  Frais d'activation  -->

                        <ng-container *ngIf="item.hasAdmissionPrice">

                            <p class="price">{{ 'services.admissionPrice.value' | translate }} : {{ formatPrice(item.admissionPriceHT, ('price.type.ht.value' | translate)) }}</p>

                        </ng-container>

                        <!--  Frais mensuel / Annuel   -->

                        <ng-container *ngIf="item.isWithSubscription">

                            <p class="price">{{'services.subscription.self.value' | translate }}</p>
                            <p class="price">{{ formatPrice(item.monthlyPriceHT, ('price.type.ht.value' | translate)) }} <span>{{ 'services.list.item.perMonth.value' | translate }}</span></p>
                            <p class="price">{{ formatPrice(item.yearlyPriceHT, ('price.type.ht.value' | translate)) }} <span>{{ 'services.list.item.perYear.value' | translate }}</span></p>

                        </ng-container>

                    </div>

                </div>

                <ng-container *ngIf="serviceTranslation.priceDescription">

                    <div class="prices">
                        <b [innerHTML]="serviceTranslation.priceDescription | safeHtml"></b>
                    </div>

                </ng-container>

            </div>
        </div>

        <div class="col_content">
            <div class="links_service">

                <!-- En savoir plus -->
                <div>
                    <button (click)="openAboutServiceDialog()">
                        <span>{{'services.list.item.action.service.more.value' | translate}}</span>
                    </button>
                </div>

                <!-- Souscription au service  -->

                <ng-container *ngIf="isRegistrationEnabled">

                    <div matTooltip="{{ subscriptionTooltipLabel | translate }}" matTooltipPosition="above" [matTooltipDisabled]="!subscriptionTooltipLabel">
                        <button [disabled]="subscriptionTooltipLabel" (click)="hasDisableSubscription ? openRequestDocumentDialog() :  subscribe()">
                            <span>{{'services.list.item.action.service.subscribe.value' | translate}}</span>
                        </button>
                    </div>

                </ng-container>

                <ng-container *ngIf="hasSubscriptionCreationInProcess">

                    <div matTooltip="{{ subscriptionTooltipLabel | translate }}" matTooltipPosition="above" [matTooltipDisabled]="!subscriptionTooltipLabel">
                        <button [disabled]="true">
                            <span>{{'services.list.item.action.service.subscribe.value' | translate}}</span>
                        </button>
                    </div>

                </ng-container>

                <!-- Gestion du service  -->

                <ng-container *ngIf="!isRegistrationEnabled">

                    <div matTooltip="{{ managementTooltipLabel | translate }}" matTooltipPosition="above" [matTooltipDisabled]="!managementTooltipLabel">

                        <button [disabled]="!isManagementEnabled" (click)="manageService()">

                            <span>{{'services.list.item.action.service.management.action.self.value' | translate}}</span>

                        </button>

                    </div>

                </ng-container>

                <!-- Désinscription au service -->

                <ng-container *ngIf="!isRegistrationEnabled">

                    <div>

                        <ng-container *ngIf="!hasDeregistrationInProcess">

                            <div matTooltip="{{ deregistrationTooltipLabel | translate }}" matTooltipPosition="above" matTooltipDisabled="{{ !deregistrationTooltipLabel }}">

                                <button class="" [disabled]="!isManagementEnabled" (click)="openDeleteSubscriptionDialog()">
                                    <span>{{'services.list.item.action.service.unsubscribe.action.self.value' | translate}}</span>
                                </button>

                            </div>

                        </ng-container>

                        <ng-container *ngIf="hasDeregistrationInProcess">

                            <!----  Annuler d'une demande de désabonnement --->

                            <div *ngIf="item.isWithSubscription" matTooltip="{{ 'services.subscription.delete.tooltip.value' | translate }}" matTooltipPosition="above" >

                                <button (click)="cancelDeactivationService()" >
                                    <span>{{'services.list.item.action.service.cancel_deactivation.value' | translate}}</span>
                                </button>

                            </div>

                            <button *ngIf="!item.isWithSubscription" (click)="cancelDeactivationService()" >
                                <span>{{'services.list.item.action.service.cancel_deactivation.value' | translate}}</span>
                            </button>

                        </ng-container>

                    </div>

                </ng-container>


            </div>
        </div>
    </div>
</div>

