import {Component} from '@angular/core';
import {CrudService} from "@core/shared/services/crud.service";

@Component({
    selector: 'app-core-page-hashtag-create',
    templateUrl: './page-hashtag-create.component.html',
    styleUrls: ['./page-hashtag-create.component.scss'],
    providers: [
        CrudService
    ]
})
export class PageHashtagCreateComponent {

    constructor(
        private _crudService: CrudService
    ) {}

    ngOnInit() {

        this._crudService.setAction('create');
    }
}
