<ng-container [formGroup]="form">

    <!-- Destinations touristique -->

    <!--<div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="touristsDestinations">
            <mat-option *ngFor="let attribute of getAttributeObservable('touristsDestinations') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label class="required">{{ 'offer.form.fields.touristicDestination.value' | translate }}</mat-label>
        <mat-error *ngIf="form.get('touristsDestinations').errors && (form.get('touristsDestinations').dirty || form.get('touristsDestinations').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('touristsDestinations').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
            </div>
        </mat-error>
    </div>-->

    <!-- Régions -->

    <div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="region">
            <mat-option *ngFor="let attribute of getAttributeObservable( 'region') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label class="required">{{ 'offer.form.fields.region.value' | translate: { min: 1 } }}</mat-label>
        <mat-error *ngIf="form.get('region').errors && (form.get('region').dirty || form.get('region').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('region').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
            </div>
        </mat-error>
    </div>

    <!-- Thèmes -->

    <div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="themes">
            <mat-option *ngFor="let attribute of getAttributeObservable( 'themes') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label class="required">{{ 'offer.form.fields.theme.value' | translate: { min: 1, max: 1 } }}</mat-label>
        <mat-error *ngIf="form.get('themes').errors && (form.get('themes').dirty || form.get('themes').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('themes').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                <div *ngIf="form.get('themes').errors.maxLengthArray">{{ 'form.control.error.maxLengthArray.value' | translate: { max: 1 } }}</div>
            </div>
        </mat-error>
    </div>

    <!-- Types d’activité -->

    <div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="types">
            <mat-option *ngFor="let attribute of getAttributeObservable( 'types') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label class="required">{{ 'offer.form.fields.activity.type.value' | translate: { min: 1 } }}</mat-label>
        <mat-error *ngIf="form.get('types').errors && (form.get('types').dirty || form.get('types').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('types').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
            </div>
        </mat-error>
    </div>

    <!-- Activités -->

    <div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="activities">
            <mat-option *ngFor="let attribute of getAttributeObservable('activities') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label >{{ 'offer.form.fields.activity.value' | translate }}</mat-label>
        <mat-error *ngIf="form.get('activities').errors && (form.get('activities').dirty || form.get('activities').touched)">
            <div class="invalid-feedback"></div>
        </mat-error>
    </div>

    <!-- Hébergements -->

    <div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="accommodations">
            <mat-option *ngFor="let attribute of getAttributeObservable( 'accommodations') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label>{{ 'offer.form.fields.accomodation.value' | translate }}</mat-label>
    </div>

    <!-- Restauration -->

    <div class="form-group">
        <mat-select multiple [compareWith]="compareAttributes" formControlName="restoration">
            <mat-option *ngFor="let attribute of getAttributeObservable( 'restoration') | async;" [value]="attribute">
                {{ translationService.getFallbackTranslation(attribute.translations).label }}
            </mat-option>
        </mat-select>
        <mat-label>{{ 'offer.form.fields.restauration.value' | translate: { max: 1 } }}</mat-label>
        <mat-error *ngIf="form.get('restoration').errors && (form.get('restoration').dirty || form.get('restoration').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('restoration').errors.maxLengthArray">{{ 'form.control.error.maxLengthArray.value' | translate: { max: 1 } }}</div>
            </div>
        </mat-error>
    </div>

</ng-container>
