import {UntypedFormControl} from "@angular/forms";

export const offerPresentialAdultDefaultValidator = (control: UntypedFormControl) => {

    if(control.parent && control.parent.get('adultMin')){

        if(parseInt(control.value) < parseInt(control.parent.get('adultMin').value)){

            return {
                'isLowerThanMin': {
                    valid: false
                }
            };
        }
    }

    if(control.parent && control.parent.get('adultMax')){

        if(parseInt(control.value) > parseInt(control.parent.get('adultMax').value)){

            return {
                'isGreaterThanMax': {
                    valid: false
                }
            };
        }
    }

    return null;
};

export const offerPresentialChildDefaultValidator = (control: UntypedFormControl) => {

    if(control.parent && control.parent.get('childMin')){

        if(parseInt(control.value) < parseInt(control.parent.get('childMin').value)){

            return {
                'isLowerThanMin': {
                    valid: false
                }
            };
        }
    }

    if(control.parent && control.parent.get('childMax')){

        if(parseInt(control.value) > parseInt(control.parent.get('childMax').value)){

            return {
                'isGreaterThanMax': {
                    valid: false
                }
            };
        }
    }

    return null;
};

export const offerPresentialAdultIncrementalStepValidator = (control: UntypedFormControl) => {

    if(control.parent && control.parent.get('adultMax')){

        if(parseInt(control.value) > parseInt(control.parent.get('adultMax').value)){

            return {
                'maxLowerThanIncrementalStep': {
                    valid: false
                }
            };
        }
    }

    if(control.parent && control.parent.get('adultDefault') && control.parent.get('adultMin')){

        if(parseInt(control.value) > 0 && ((parseInt(control.parent.get('adultDefault').value) + parseInt(control.parent.get('adultMin').value)) % parseInt(control.value) !== 0)){

            return {
                'defaultValueWrong': {
                    valid: false
                }
            };
        }
    }

    if(control.parent && control.parent.get('adultMax') && control.parent.get('adultMin')){

        if(parseInt(control.value) > 0 && ((parseInt(control.parent.get('adultMax').value) + parseInt(control.parent.get('adultMin').value)) % parseInt(control.value) !== 0)){

            return {
                'maxValueWrong': {
                    valid: false
                }
            };
        }
    }

    return null;
};

export const offerPresentialChildIncrementalStepValidator = (control: UntypedFormControl) => {

    if(control.parent && control.parent.get('childMax')){

        if(parseInt(control.value) > parseInt(control.parent.get('childMax').value)){

            return {
                'maxLowerThanIncrementalStep': {
                    valid: false
                }
            };
        }
    }

    if(control.parent && control.parent.get('childDefault') && control.parent.get('childMin')){

        if(parseInt(control.value) > 0 && ((parseInt(control.parent.get('childDefault').value) + parseInt(control.parent.get('childMin').value)) % parseInt(control.value) !== 0)){

            return {
                'defaultValueWrong': {
                    valid: false
                }
            };
        }
    }

    if(control.parent && control.parent.get('childMax') && control.parent.get('childMin')){

        if(parseInt(control.value) > 0 && ((parseInt(control.parent.get('childMax').value) + parseInt(control.parent.get('childMin').value)) % parseInt(control.value) !== 0)){

            return {
                'maxValueWrong': {
                    valid: false
                }
            };
        }
    }

    return null;
};


