import {AfterContentInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
    selector: '[appInputCountLimit]'
})
export class InputTextCountLimitDirective implements AfterContentInit{

    @Input() limit: number;

    @Input() placeholderElement: HTMLElement;

    @Input() formControlReference: AbstractControl;

    constructor(private _elementRef: ElementRef) {
    }

    @HostListener('input') onInput(): void {

        this._apply();
    }

    @HostListener('paste') onPaste(): void {

        this._apply();
    }

    ngAfterContentInit(): void {

        this._apply();

        if(this.formControlReference){

            this.formControlReference.valueChanges.subscribe((): void => {

                this._apply();
            });
        }
    }

    private _apply(): void {

        const input: HTMLInputElement = this._elementRef.nativeElement;

        this.placeholderElement.textContent = `${input.value.length}/${this.limit}`;
    }
}
