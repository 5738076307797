import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CustomerTypology, CustomerTypologyTranslation} from "@core/shared/models/customer-typology";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-core-add-customer-typology-dialog',
    templateUrl: './add-customer-typology-dialog.component.html',
    styleUrls: ['./add-customer-typology-dialog.component.scss']
})
export class AddCustomerTypologyDialogComponent implements OnInit {

    @Output() select: EventEmitter<{items: CustomerTypology[], duplicatedItem: CustomerTypology}> = new EventEmitter<{items: CustomerTypology[], duplicatedItem: CustomerTypology}>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    items: CustomerTypology[] = [];

    duplicatedItem: CustomerTypology;

    constructor(
        private _dialogRef: MatDialogRef<AddCustomerTypologyDialogComponent>,
        private _translateService: TranslateService,
        public translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: { existingItems: CustomerTypology[], availableItems: CustomerTypology[] }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.select,
            this.cancel
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    get existingItems(): CustomerTypology[] {

        return this.data.existingItems;
    }

    get availableItems(): CustomerTypology[] {

        return this.data.availableItems;
    }
}
