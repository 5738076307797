<div class="row marges">

    <div class="col_12 col_sm_7 col_md_8 voucher_composition" id="core">

        <form [formGroup]="form" (submit)="formService.submit()">

            <!-- Informations liées au bon cadeau -->

            <div class="row ligne_form">
                <div class="col_12">
                    <div class="ck-content">
                        <p class="titre_h4">{{ 'offer.giftVoucher.generalData.value' | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">

                <!-- Nombre d'adultes -->

                <ng-container *ngIf="offer.publics.includes('adult')">

                    <div class="col_12 col_md_6">

                        <div class="form-group">
                            <mat-select (selectionChange)="countAdultUpdatedSubject.next()" formControlName="nbAdult">
                                <mat-option  [value]="count" *ngFor="let count of countAdultValues" >{{ count }}</mat-option>
                            </mat-select>

                            <mat-label class="required">{{ 'offer.dateEngine.adult.count.value' | translate }}</mat-label>
                        </div>

                    </div>

                </ng-container>

                <!-- Nombre d'enfants -->

                <ng-container *ngIf="offer.publics.includes('child')">

                    <div class="col_12 col_md_6">

                        <div class="form-group">
                            <mat-select formControlName="nbChild" (selectionChange)="countChildUpdatedSubject.next()">
                                <mat-option [value]="count" *ngFor="let count of countChildValues">{{ count }}</mat-option>
                            </mat-select>

                            <mat-label class="required">{{ 'offer.dateEngine.child.count.value' | translate }}</mat-label>
                        </div>

                    </div>

                </ng-container>

                <ng-container *ngIf="offer.publics.includes('child')">
                    <div class="col_12">
                        <p class="info_step icon_info">
                            {{ 'offer.booking.composition.child.age.interval.value'|translate:{minChildrenAge: (offer.minChildrenAge), maxChildrenAge: (offer.maxChildrenAge|formatAge)} }}
                        </p>
                    </div>
                </ng-container>

            </div>

            <div class="row marge ligne_form">
                <div class="col_12">
                    <mat-error *ngIf="!isValidNbPerson">
                        <div class="invalid-feedback">
                            <div>{{ 'form.control.error.nbPerson.max_exceed.value' | translate: {max: (offer.presential.max)} }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <ng-container *ngTemplateOutlet="buyerTemplate"></ng-container>

            <ng-container *ngTemplateOutlet="promotionTemplate"></ng-container>

            <ng-container *ngTemplateOutlet="customerTemplate"></ng-container>

            <!-- Validation des informations -->

            <div class="row marges ligne_form margin" *ngIf="!isPresentialSelectionValid">
                <div class="col_12">
                    <div class="message">
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ 'offer.giftVoucher.presential.selection.invalid.value' | translate }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form margin">
                <div class="col_12">
                    <button class="btn_cta btn_little" type="submit" [disabled]="(form && form.invalid) || !isPresentialSelectionValid">
                        {{ 'offer.giftVoucher.action.validate.value' | translate }}
                    </button>
                </div>
            </div>


        </form>

    </div>

    <div class="col_12 col_sm_5 col_md_4 order_first order_last_sm" id="summary">

        <ng-content select="[recapItem]"></ng-content>

    </div>

</div>

<!-- Données acheteur -->

<ng-template #buyerTemplate [formGroup]="form">

    <br>

    <div class="row ligne_form">
        <div class="col_12">
            <div class="ck-content">
                <p class="titre_h4">{{ 'offer.giftVoucher.buyerData.value' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="row ligne_form">
        <div class="col_12">
            <app-core-customer-form-create [origin]="'gift-voucher'" [currentUser]="user" [locales]="offer.locales" [parentForm]="form" [controlName]="'buyer'" [termsAndConditionsFileIdentifier]="termsAndConditionsFile"></app-core-customer-form-create>
        </div>
    </div>

</ng-template>

<!-- Promotion -->

<ng-template #promotionTemplate [formGroup]="form">

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>{{ 'offer.giftVoucher.promotion.value' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row marges ligne_form">

                <div class="col y_items_center">

                    <!-- Code Promotion -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="promotionCode" id="promotionCode">
                        <label for="promotionCode">{{'booking.request.booking.promotion.code.choice.action.value' | translate}}</label>
                    </div>
                </div>

                <ng-container *ngIf="form.get('promotionCode').value && !!form.get('promotionCode').value.length">

                    <div class="col_content y_items_center">
                        <button class="btn_cta btn_little" type="button" (click)="validatePromotionCode()">
                            {{ 'booking.request.booking.promotion.code.validate.action.value'|translate }}
                        </button>
                    </div>

                </ng-container>

            </div>

            <ng-container *ngIf="displayPromotionEmailRequired">
                <div class="row">
                    <div class="col y_items_center">
                        <div class="form-group">
                            <mat-error>
                                <div class="invalid-feedback">
                                    <div>{{ 'booking.request.promotion.email.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
                <br>
            </ng-container>

             <ng-container *ngIf="validatePromotion">

                <div class="row marges ligne_form" [ngSwitch]="form.get('isValidPromotion').value">
                    <div class="col_12">
                        <div class="form-group">
                            <p class="read confirm" *ngSwitchCase="true">
                                <strong >{{ 'booking.request.booking.promotion.code.validate.success.value' | translate: {code: form.get('promotionCode').value} }}</strong>
                            </p>
                            <p class="read failed" *ngSwitchCase="false">
                                <strong >{{ 'offer.giftVoucher.promotion.failed.value' | translate }}</strong>
                            </p>
                        </div>
                    </div>
                </div>

            </ng-container>

        </mat-expansion-panel>
    </mat-accordion>

</ng-template>

<!-- Données bénéficiaire -->

<ng-template #customerTemplate [formGroup]="form">

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form-group">
                <div class="checkbox">
                    <input type="checkbox" id="buyer_is_beneficiary" formControlName="beneficiarySameAsBuyer" >
                    <label for="buyer_is_beneficiary">{{ 'offer.giftVoucher.isBeneficiary.value' | translate }}</label>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!form.get('beneficiarySameAsBuyer').value" formGroupName="beneficiary">

        <div class="row ligne_form">
            <div class="col_12">
                <div class="ck-content">
                    <p class="titre_h4">{{ 'offer.giftVoucher.beneficiaryData.value' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Sélection du type de société -->

                <div class="form-group">
                    <select class="form-control" id="beneficiaryCustomerType" formControlName="customerType">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let customerType of customerTypes" [ngValue]="customerType.id">
                            {{ translationService.getFallbackTranslation(customerType.translations).label }}
                        </option>
                    </select>
                    <label for="beneficiaryCustomerType">{{ 'customer.type.title.value' | translate }}</label>
                    <mat-error *ngIf="beneficiaryControl.get('customerType').errors && (beneficiaryControl.get('customerType').dirty || beneficiaryControl.get('customerType').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('customerType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <ng-container *ngIf="isSocietyRequired">

                <div class="col_12 col_md_6">

                    <!-- Société -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="society" id="beneficiarySociety">
                        <label for="beneficiarySociety">{{'society.value' | translate}}</label>
                        <mat-error *ngIf="beneficiaryControl.get('society').errors && (beneficiaryControl.get('society').dirty || beneficiaryControl.get('society').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="beneficiaryControl.get('society').errors.societyRequired">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </ng-container>

        </div>

        <div class="row marges ligne_form">

            <div class="col_12">

                <!-- Civilité -->

                <div class="form-group">
                    <label class="required">{{'form.user.fields.civility.title.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" value="1" formControlName="civility" class="form-control" id="beneficiary-civility-mister">
                        <label for="beneficiary-civility-mister">{{'form.user.fields.civility.mister.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" value="2" formControlName="civility" class="form-control" id="beneficiary-civility-madam">
                        <label for="beneficiary-civility-madam">{{'form.user.fields.civility.madam.value' | translate}}</label>
                    </div>
                    <mat-error
                        *ngIf="beneficiaryControl.get('civility').errors && (beneficiaryControl.get('civility').dirty || beneficiaryControl.get('civility').touched)">
                        <div class="invalid-feedback">
                            <div
                                *ngIf="beneficiaryControl.get('civility').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form" >

            <div class="col_12 col_md_6">

                <!-- Nom -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName" id="lastName" required="required">
                    <label for="lastName">{{'form.user.fields.lastname.value' | translate}}</label>
                    <mat-error *ngIf="beneficiaryControl.get('lastName').errors && (beneficiaryControl.get('lastName').dirty || beneficiaryControl.get('lastName').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
            <div class="col_12 col_md_6">

                <!-- Prénom -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName" id="firstName" required="required">
                    <label for="firstName">{{'form.user.fields.firstname.value' | translate}}</label>
                    <mat-error *ngIf="beneficiaryControl.get('firstName').errors && (beneficiaryControl.get('firstName').dirty || beneficiaryControl.get('firstName').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Email -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="email" required="required">
                    <label for="email">{{'form.user.fields.email.contact.value' | translate}}</label>
                    <mat-error *ngIf="beneficiaryControl.get('email').errors && (beneficiaryControl.get('email').dirty || beneficiaryControl.get('email').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="beneficiaryControl.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Téléphone -->

                <div class="form-group">

                    <app-core-intl-tel-input [formGroup]="beneficiaryControl" [controlName]="'phone'" [required]="true" customPlaceholder="{{'form.user.fields.directPhone.value' | translate}}"></app-core-intl-tel-input>

                    <mat-error *ngIf="beneficiaryControl.get('phone').errors && (beneficiaryControl.get('phone').pristine || beneficiaryControl.get('phone').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="beneficiaryControl.get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12 y_items_center">
                <app-core-google-places-location [fields]="getAddressLocationFields(beneficiaryControl)"></app-core-google-places-location>
            </div>
        </div>


        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Adresse -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="address" id="address" required="required">
                    <label for="address">{{'form.user.fields.address.value' | translate}}</label>
                    <mat-error
                            *ngIf="beneficiaryControl.get('address').errors && (beneficiaryControl.get('address').dirty || beneficiaryControl.get('address').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('address').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Complément d'adresse -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalAddress" id="additionalAddress">
                    <label for="additionalAddress">{{'form.user.fields.additionalAddress.value' | translate}}</label>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Code postal -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="zipcode" id="zipcode" required="required">
                    <label for="zipcode">{{'form.user.fields.zipcode.value' | translate}}</label>
                    <mat-error
                            *ngIf="beneficiaryControl.get('zipcode').errors && (beneficiaryControl.get('zipcode').dirty || beneficiaryControl.get('zipcode').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('zipcode').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="beneficiaryControl.get('zipcode').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Ville -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="city" id="city" required="required">
                    <label for="city">{{'form.user.fields.city.value' | translate}}</label>
                    <mat-error
                            *ngIf="beneficiaryControl.get('city').errors && (beneficiaryControl.get('city').dirty || beneficiaryControl.get('city').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('city').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

        </div>

        <div class="row marges ligne_form">

            <div class="col_12 col_md_6">

                <!-- Région -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="region" id="region">
                    <label for="region">{{'form.user.fields.region.value' | translate}}</label>
                </div>
            </div>

            <div class="col_12 col_md_6">

                <!-- Pays -->

                <div class="form-group">
                    <select class="form-control" id="country" formControlName="country" required="required">
                        <option value="" disabled selected>{{ 'form.user.fields.country.value' | translate }}</option>
                        <option *ngFor="let country of countries$ | async" [value]="country.code">{{ translationService.getFallbackTranslation(country.translations).name }}</option>
                    </select>
                    <label class="required" for="country">{{ 'form.user.fields.country.value' | translate }}</label>
                    <mat-error
                            *ngIf="beneficiaryControl.get('country').invalid && (beneficiaryControl.get('country').dirty || beneficiaryControl.get('country').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="beneficiaryControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>

        </div>

    </ng-container>


</ng-template>
