import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Pagination} from '@core/shared/models/pagination';
import {Category} from "@core/shared/models/category";

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemAPI(id: number, params?: string[]): Observable<Category> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/category/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<Category>(url,{
            headers: headers
        });
    }

    public getPaginationItemAPI(id: number, params?: string[]): Observable<Pagination<Category>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/categories/${id}`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<Category>>(url, {
            headers: headers
        });

    }



    public getItemsAPI(params?: string[]): Observable<Category[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/categories`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Category[]>(url, {
            headers: headers
        });

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Category>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/categories`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Category>>(url, {
            headers: headers
        });

    }



    public updateItemAPI(id: number, data: object): Observable<Category> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Category>(`${this._apiService.getApiUrl(false, true)}/categories/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/categories/${id}`,{
            headers: headers
        });
    }

    public duplicateItemAPI(id: number): Observable<number> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<number>(`${this._apiService.getApiUrl(false, true)}/duplicate-categories/${id}`, {
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<Category> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Category>(`${this._apiService.getApiUrl(false, true)}/categories`, data, {
            headers: headers
        });
    }




}
