<ng-container *ngIf="isView('configuration')">

    <form [formGroup]="form" (submit)="formService.submit()" id="configuration-step-form">

        <div class="back_to_compo margin" id="back-to-general-data-step-trigger">
            <span class="ty-chevron"  (click)="handleBackToCompositionRequest()">{{ 'offer.giftVoucher.back.value' | translate }}</span>
        </div>

        <div class="row marges">

            <div class="col_12 col_sm_5 col_md_4 order_first order_last_sm" id="summary">

                <ng-content select="[recapItem]"></ng-content>

            </div>

            <div class="col_12 col_sm_7 col_md_8 voucher_composition" id="core">

                <!--    Récapitulatif -->

                <div class="content_data_summary" id="general-data-summary">
                    <div class="content_header ck-content">
                        <span class="titre_h4">{{ 'offer.giftVoucher.recap.value' | translate }}</span>
                    </div>
                    <div class="content_body">
                        <div class="row marges">

                            <!-- Données acheteur  -->

                            <div class="col_12 col_md_6 y_items_start">
                                <div class="data">
                                    <div class="data_header">
                                        <span>{{ 'offer.giftVoucher.buyerData.value' | translate }}</span>
                                    </div>
                                    <div class="data_body">

                                        <!-- Société -->

                                        <ng-container *ngIf="generalData.buyer.society && generalData.buyer.customerType && customerType">

                                            <div class="row marges">
                                                <div class="col_content x_start y_items_start">
                                                    <span>{{ 'customer.society.type.value' | translate }}</span>
                                                </div>
                                                <div class="col x_end y_items_start">
                                                    <span>{{ translationService.getFallbackTranslation(customerType.translations).label }}</span>
                                                </div>
                                            </div>

                                        </ng-container>

                                        <!-- Type de société -->

                                        <ng-container *ngIf="generalData.buyer.society">

                                            <div class="row marges">
                                                <div class="col_content x_start y_items_start">
                                                    <span>{{ 'booking.read.fields.customerSociety.value' | translate }}</span>
                                                </div>
                                                <div class="col x_end y_items_start">
                                                    <span>{{ generalData.buyer.society }}</span>
                                                </div>
                                            </div>

                                        </ng-container>

                                        <!-- Nom -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.lastname.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.lastName }}</span>
                                            </div>
                                        </div>

                                        <!-- Prénom -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.firstname.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.firstName }}</span>
                                            </div>
                                        </div>

                                        <!-- Email -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.email.contact.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.email }}</span>
                                            </div>
                                        </div>


                                        <!-- Téléphone -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.directPhone.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.phone }}</span>
                                            </div>
                                        </div>

                                        <!-- Adresse -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.address.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.address }}</span>
                                            </div>
                                        </div>

                                        <!-- Complément d'adresse -->

                                        <ng-container *ngIf="generalData.buyer.additionalAddress">

                                            <div class="row marges">
                                                <div class="col_content x_start y_items_start">
                                                    <span>{{ 'form.user.fields.additionalAddress.value' | translate }}</span>
                                                </div>
                                                <div class="col x_end y_items_start">
                                                    <span>{{ generalData.buyer.additionalAddress }}</span>
                                                </div>
                                            </div>

                                        </ng-container>

                                        <!-- Code postale -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.zipcode.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.zipcode }}</span>
                                            </div>
                                        </div>

                                        <!-- Ville -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.city.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.city }}</span>
                                            </div>
                                        </div>

                                        <!-- Région -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.region.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.region }}</span>
                                            </div>
                                        </div>

                                        <!-- Pays -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.country.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.buyer.country }}</span>
                                            </div>
                                        </div>

                                        <!-- Commentaire -->

                                        <ng-container *ngIf="generalData.buyer.comment">

                                            <div class="row marges">
                                                <div class="col_content x_start y_items_start">
                                                    <span>{{ 'form.user.fields.comment.value' | translate }}</span>
                                                </div>
                                                <div class="col x_end y_items_start">
                                                    <span>{{ generalData.buyer.comment }}</span>
                                                </div>
                                            </div>

                                        </ng-container>

                                    </div>
                                </div>
                            </div>

                            <!-- Bonnées destinataire  -->

                            <div class="col_12 col_md_6 y_items_start">
                                <div class="data">
                                    <div class="data_header">
                                        <span>{{ 'offer.giftVoucher.beneficiaryData.value' | translate }}</span>
                                    </div>
                                    <div class="data_body">

                                        <!-- Nom -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.lastname.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.lastName }}</span>
                                            </div>
                                        </div>

                                        <!-- Prénom -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.firstname.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.firstName}}</span>
                                            </div>
                                        </div>

                                        <!-- Email -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.email.contact.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.email }}</span>
                                            </div>
                                        </div>

                                        <!-- Téléphone -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.directPhone.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.phone }}</span>
                                            </div>
                                        </div>

                                        <!-- Adresse -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.address.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.address }}</span>
                                            </div>
                                        </div>

                                        <!-- Complément d'adresse -->

                                        <ng-container *ngIf="generalData.beneficiary.additionalAddress">
                                            <div class="row marges">
                                                <div class="col_content x_start y_items_start">
                                                    <span>{{ 'form.user.fields.additionalAddress.value' | translate }}</span>
                                                </div>
                                                <div class="col x_end y_items_start">
                                                    <span> {{ generalData.beneficiary.additionalAddress }}</span>
                                                </div>
                                            </div>

                                        </ng-container>

                                        <!-- Code postale -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.zipcode.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.zipcode }}</span>
                                            </div>
                                        </div>

                                        <!-- Ville -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.city.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.city }}</span>
                                            </div>
                                        </div>

                                        <!-- Région -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.region.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.region }}</span>
                                            </div>
                                        </div>

                                        <!-- Pays -->

                                        <div class="row marges">
                                            <div class="col_content x_start y_items_start">
                                                <span>{{ 'form.user.fields.country.value' | translate }}</span>
                                            </div>
                                            <div class="col x_end y_items_start">
                                                <span>{{ generalData.beneficiary.country }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content_data_summary">
                    <div class="content_header ck-content">
                        <span class="titre_h4">{{ 'offer.giftVoucher.transporter.value' | translate }}</span>
                    </div>
                    <div class="content_body">

                        <!-- E-bon cadeau -->

                        <ng-container *ngIf="offer.giftVoucher.isDematerialized">

                            <div class="voucher_choice">
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" value="dematerialized" id="dematerializedType" formControlName="type">
                                        <label class="extra" for="dematerializedType">{{ 'giftVoucher.template.form.fields.dematerialized.description.value' | translate }}</label>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <!-- bon cadeau physique -->

                        <ng-container *ngIf="offer.giftVoucher.isPhysical">

                            <div class="voucher_choice">
                                <div class="form-group">
                                    <div class="radio">
                                        <input type="radio" value="physical" id="physicalType" formControlName="type">
                                        <label class="extra" for="physicalType">{{ 'giftVoucher.template.form.fields.physical.description.value' | translate : {offerCreatorName: offer.society.name } }}</label>
                                    </div>
                                </div>

                                <ng-container *ngIf="isPhysical">

                                    <div id="transporters-container" class="content_choice">

                                        <ng-container *ngIf="(transporters$ | async) as transporters">

                                            <ng-container *ngIf="transporters.length;else noTransportersTemplate">

                                                <div class="transporter-element form-group" *ngFor="let transporter of transporters;let i = index;">
                                                    <input type="radio" value="{{ transporter.id }}" class="form-control" formControlName="transporter" name="transporter" id="transporter-{{ i }}">
                                                    <div class="transporter">
                                                        <div class="row marges y_items_center">
                                                            <div class="col_content">
                                                                <img [src]="transporter | offerTransporterPictureSource: offer : 'rectangle' : 100 : 90" alt="{{ transporter.name }}"/>
                                                            </div>
                                                            <div class="col">

                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="infos">
                                                                            <p>{{ transporter.name }}</p>

                                                                            <ng-container *ngIf="transporter.deliveryTimeStart && transporter.deliveryTimeEnd">

                                                                                <ng-container *ngIf="transporter.deliveryTimeStart === transporter.deliveryTimeEnd">
                                                                                    <p>{{ 'offer.giftVoucher.transporter.deliveryTime.value' | translate :{days: transporter.deliveryTimeStart} }}</p>
                                                                                </ng-container>

                                                                                <ng-container *ngIf="transporter.deliveryTimeStart !== transporter.deliveryTimeEnd">
                                                                                    <p>{{ 'offer.giftVoucher.transporter.deliveryTime.interval.value' | translate :{
                                                                                        minDays: transporter.deliveryTimeStart,
                                                                                        maxDays: transporter.deliveryTimeEnd
                                                                                    } }}</p>
                                                                                </ng-container>

                                                                            </ng-container>
                                                                            <p class="price">
                                                                                <span>{{ formatPrice(transporter.priceTTC, '') }}/TTC</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <!--  Commentaire d'un transporteur  -->
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <span class="comment"> {{ translationService.getFallbackTranslation(transporter.translations).comment }}</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <label for="transporter-{{ i }}" class="choice-element"></label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>

                                            <ng-template #noTransportersTemplate>

                                                <span>{{ 'offer.giftVoucher.transporter.unavailable.value' | translate }}</span>

                                            </ng-template>

                                        </ng-container>

                                    </div>

                                </ng-container>

                            </div>

                        </ng-container>

                        <!-- Envoi d'un mail au bénéficiaire -->

                        <ng-container *ngIf="!generalData.beneficiarySameAsBuyer">

                            <div id="send-mail-to-beneficiary-container" class="content_choice" *ngIf="isPhysical || isDematerialized">

                                <div class="form-group">
                                    <div class="checkbox">
                                        <input type="checkbox" id="notifyBeneficiary" name="notifyBeneficiary" formControlName="notifyBeneficiary">
                                        <label for="notifyBeneficiary">{{ 'offer.giftVoucher.notifyBeneficiary.' + (isDematerialized ? 'dematerialized' : 'physical') + '.value' |translate }}</label>
                                    </div>
                                </div>

                            </div>

                        </ng-container>

                    </div>
                </div>

            </div>

            <div class="col_12 order_last">

                <div class="templates">

                    <ng-container *ngIf="isPhysical || isDematerialized">

                        <!-- Sélection du template -->

                        <div class="ck-content">
                            <p class="titre_h4">{{'offer.giftVoucher.template.value' | translate}}</p>
                        </div>

                        <div class="row marges"  id="templates-container">

                            <div class="col_6 col_md_4 mt-10" *ngFor="let template of templates">
                                <div class="template-element form-group" [ngClass]="this.form.get('template').value === template.id ? 'selected' : ''">
<!--                                    <div class="flags">-->
<!--                                        <span class="flag flag-{{translationService.getFallbackTranslation(template.translations).locale}}"></span>-->
<!--                                    </div>-->
                                    <img [src]="template | giftVoucherPictureSource: translationService.getFallbackTranslation(template.translations).locale : 'landscape' : 250 : 90"/>
                                    <div>
                                        <button type="button" class="choice-element" id="picture-{{ template.id }}" (click)="patchTemplatesValue(template.id)">
                                            <span class="label btn_cta btn_small">{{ 'giftVoucher.configuration.form.fields.select.template.' + (isTemplateSelected(template) ? 'unchecked' : 'checked') + '.value' | translate }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </ng-container>

                    <!-- Nom du bénéficiaire personnalisé -->

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="form-group">
                                <input type="text" placeholder=" " class="form-control" formControlName="personalizedBeneficiaryName" id="personalizedBeneficiaryName">
                                <label for="personalizedBeneficiaryName">{{'offer.giftVoucher.message.beneficiaryName.value' | translate}}</label>
                                <mat-error *ngIf="form.get('personalizedBeneficiaryName').errors && (form.get('personalizedBeneficiaryName').dirty || form.get('personalizedBeneficiaryName').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('personalizedBeneficiaryName').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength: 100 } }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                    </div>

                    <!-- Nom de l'acheteur personnalisé -->

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="form-group">
                                <input type="text" placeholder=" " class="form-control" formControlName="personalizedBuyerName" id="personalizedBuyerName">
                                <label for="personalizedBuyerName">{{'offer.giftVoucher.message.buyerName.value' | translate}}</label>
                                <mat-error *ngIf="form.get('personalizedBuyerName').errors && (form.get('personalizedBuyerName').dirty || form.get('personalizedBuyerName').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('personalizedBuyerName').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength: 100 } }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                    </div>

                    <!-- Mot personnalisé -->

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="form-group">
                                <textarea placeholder=" " class="form-control" formControlName="personalizedNote" id="personalizedNote" appInputTextStripTags [placeholderElement]="formControlMessage" appInputCountLimit [limit]="400"></textarea>
                                <label for="personalizedNote">{{'offer.giftVoucher.message.content.value' | translate}}</label>
                                <span class="message">
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'form.validation.error.maxLength' | translate: { value: 400 } }}
                                    </span>
                                    <span>
                                        <span #formControlMessage class="form-control-input" ></span>
                                        {{ 'form.input.type' | translate }}
                                    </span>
                                </span>
                                <mat-error *ngIf="form.get('personalizedNote').errors && (form.get('personalizedNote').dirty || form.get('personalizedNote').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('personalizedNote').errors.pattern">{{ 'form.control.error.maxLength.value' | translate: { maxLength: 400 } }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                    </div>

                </div>

                <!-- Validation des informations -->

                <div class="row marges ligne_form margin">
                    <div class="col_12">
                        <button class="btn_cta btn_little" type="submit" [disabled]="form.invalid">
                            {{ 'offer.giftVoucher.action.validateOrder.value' | translate }}
                        </button>
                    </div>
                </div>

            </div>

        </div>

    </form>

</ng-container>


<ng-container *ngIf="isView('confirmation')">

    <ng-container *ngTemplateOutlet="confirmationTemplate"></ng-container>

</ng-container>

<ng-template #confirmationTemplate>

    <div class="row marges">

        <div class="col_12">

            <div class="txt_offer confirmation">
                <p class="title">{{ 'offer.giftVoucher.request.success.value' | translate }}</p>
                <p>{{ getConfirmationMessage }}</p>
            </div>

        </div>

    </div>

</ng-template>
