import {FilterField} from "../filter";

export class FilterCollectionField extends FilterField {

    public fields: FilterField[] = [];

    public choices: any[] = [];

    constructor(key: string) {

        super(key, null, true);
    }

    public clear(): void {

        this.fields = [];
    }

    get serialize(): string {

        const serializes: string[] = this.fields.map((field: FilterField): string => {

            return field.serialize;
        });

        return serializes.join('&');
    }
}
