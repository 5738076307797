import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {Moment} from "moment";
import {DATE_FORMAT} from "@app/data";
import {Day, DAYS} from "@core/shared/models/day";
import {Promotion} from "@core/shared/models/promotion";
import {PromotionPeriodCreateConfiguration} from "@core/shared/models/promotion/promotion-period/promotion-period-create";
import {PromotionPeriodService} from "@core/shared/services/promotion/promotion-period.service";
import {PROMOTION_STATUS_ITEMS, PromotionStatus} from "@core/shared/models/promotion/promotion-status";
import {Observable, of} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-core-promotion-period-create',
    templateUrl: './promotion-period-create.component.html',
    styleUrls: ['./promotion-period-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PromotionPeriodCreateComponent implements OnInit {

    @Input() promotion: Promotion;

    @Input() configuration: PromotionPeriodCreateConfiguration;

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _promotionPeriodService: PromotionPeriodService,
        private _translateService: TranslateService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]],
            status: [null, [Validators.required]],
            excludedDays: [[]]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                dateStart: (this.form.get('dateStart').value as Moment).format(DATE_FORMAT),
                dateEnd: (this.form.get('dateEnd').value as Moment).format(DATE_FORMAT),
            });

            this._promotionPeriodService.createItemAPI(this.promotion.id, data).subscribe((): void => {

                this.create.emit();
            });
        };
    }

    public handleExcludedDay(event: any): void{

        const control: UntypedFormControl = this.form.get('excludedDays') as UntypedFormControl;

        const controlValue: number[] = control.value;

        const value: number = parseInt(event.target.value);

        if(event.target.checked){

            controlValue.push(value);
        }
        else{

            const index: number = controlValue.findIndex((i: number): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue.sort());
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get days(): Day[] {

        return DAYS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get statusItems$(): Observable<PromotionStatus[]> {

        return of(PROMOTION_STATUS_ITEMS);
    }
}
