<div class="page_offer">

    <ng-container *ngIf="listRedirectionAllowed">

        <div class="back_to_list" *ngIf="isOneOfTheseViews(['date-engine', 'booking-request'])">
            <span class="ty-chevron" (click)="redirectToList()">{{ backToListLabel | translate }}</span>
        </div>

    </ng-container>

    <ng-container [ngSwitch]="true">

        <!-- Demande de réservation | Demande de devis -->

        <ng-container *ngSwitchCase="isView('booking-request')">
            <ng-container *ngTemplateOutlet="bookingRequestTemplate"></ng-container>
        </ng-container>

        <!-- Offrir -->

        <ng-container *ngSwitchCase="isView('gift-voucher-request')">
            <ng-container *ngTemplateOutlet="giftVoucherRequestTemplate"></ng-container>
        </ng-container>

    </ng-container>

    <!-- Détails de l'offre -->

    <ng-container *ngIf="isOneOfTheseViews(['date-engine', 'booking-request'])">

        <app-core-offer-item [isMine]="isMine" [roles]= "roles" [item]="offer" [configuration]="configuration" (onLocaleUpdated)="updateLocale($event)">

            <!-- Projection de contenu pour la composition -->

            <div compositionContent [hidden]="!isView('date-engine')">

                <ng-container *ngTemplateOutlet="dateEngineTemplate"></ng-container>

            </div>

            <!-- Projection de contenu pour les options -->

            <div optionsContent>
                <ng-container *ngIf="(options$ | async) as options">

                    <ng-container *ngIf="options.length > 0">

                        <div class="txt_offer">
                            <p class="title">{{ 'offer.stay.personalization.value' | translate}}</p>
                        </div>

                        <div class="option_offer" *ngFor="let option of options$ | async">
                            <div class="option">
                                <div class="row">
                                    <div class="col_3" *ngIf="option.image?.image">
                                        <div class="visuel">
                                            <img [src]="option.image.image | imageSource: 'rectangle' : 200 : 90" alt="{{ translationService.getFallbackTranslation(option.translations).title }}" title="{{ translationService.getFallbackTranslation(option.translations).title }}" />
                                        </div>
                                    </div>
                                    <div class="col_9">
                                        <div class="infos">
                                            <div class="txt_offer">
                                                <p class="title">{{ translationService.getFallbackTranslation(option.translations).title }}</p>
                                                <div [innerHTML]="translationService.getFallbackTranslation(option.translations).description|safeHtml"></div>
                                            </div>
                                            <div class="row marges x_between">

                                                <ng-container *ngIf="offerOptionSelectionService.hasItem(option)">

                                                    <!-- Typologie Individuel -->

                                                    <div *ngIf="offer.customerTypology.type === 'I'" class="col y_items_center">

                                                        <div class="row marges">

                                                            <!-- Public Adulte -->

                                                            <ng-container *ngIf="isPublicAvailable(option, 'adult')">
                                                                <div class="col_4 y_items_center">
                                                                    <div class="form-group">
                                                                        <select [value]="option.nbAdult" (change)="handleOptionPresential(option, 'adult', $event)">
                                                                            <option *ngFor="let count of getChoicePresentialOptionValues(option.presentialIndividual, 'adult')" [ngValue]="count" [selected]="count === option.nbAdult">{{ count }}</option>
                                                                        </select>
                                                                        <label>{{ (offerOptionSelectionService.getItemById(option.id).nbAdult > 1 ? 'offer.option.nbAdult.plural.value' : 'offer.option.nbAdult.value')|translate:{ count : offerOptionSelectionService.getItemById(option.id).nbAdult } }}</label>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <!-- Public Enfant -->

                                                            <ng-container *ngIf="isPublicAvailable(option, 'child')">
                                                                <div class="col_4 y_items_center">
                                                                    <div class="form-group">
                                                                        <select [value]="option.nbChild" (change)="handleOptionPresential(option, 'child', $event)">
                                                                            <option *ngFor="let count of getChoicePresentialOptionValues(option.presentialIndividual, 'child')" [ngValue]="count" [selected]="count === option.nbChild">{{ count }}</option>
                                                                        </select>
                                                                        <label>
                                                                        <span>
                                                                            {{ (offerOptionSelectionService.getItemById(option.id).nbChild > 1 ? 'offer.option.nbChild.plural.value' : 'offer.option.nbChild.value')|translate:{ count : offerOptionSelectionService.getItemById(option.id).nbChild } }}
                                                                            <span class="green" *ngIf="!offer.onRequest">{{ 'offer.presential.child.age.interval.value'|translate:{min : option.minChildrenAge, max : option.maxChildrenAge} }}</span>
                                                                        </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                        </div>

                                                    </div>

                                                    <!-- Typologie Groupe -->

                                                    <div *ngIf="offer.customerTypology.type === 'G'" class="col y_items_center">

                                                        <div class="row marges">

                                                            <!-- Public Adulte -->

                                                            <ng-container *ngIf="isPublicAvailable(option, 'adult')">
                                                                <div class="col_4 y_items_center">
                                                                    <div class="form-group">
                                                                        <select [value]="option.nbAdult" (change)="handleOptionPresential(option, 'adult', $event)">
                                                                            <option *ngFor="let count of getChoicePresentialOptionValues(option.presentialGroup, 'adult')" [ngValue]="count" [selected]="count === option.nbAdult">{{ count }}</option>
                                                                        </select>
                                                                        <label>{{ (offerOptionSelectionService.getItemById(option.id).nbAdult > 1 ? 'offer.option.nbAdult.plural.value' : 'offer.option.nbAdult.value')|translate:{ count : offerOptionSelectionService.getItemById(option.id).nbAdult } }}</label>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <!-- Public Enfant -->

                                                            <ng-container *ngIf="isPublicAvailable(option, 'child')">
                                                                <div class="col_4 y_items_center">
                                                                    <div class="form-group">
                                                                        <select [value]="option.nbChild" (change)="handleOptionPresential(option, 'child', $event)">
                                                                            <option *ngFor="let count of getChoicePresentialOptionValues(option.presentialGroup, 'child')" [ngValue]="count" [selected]="count === option.nbChild">{{ count }}</option>
                                                                        </select>
                                                                        <label>
                                                                        <span>
                                                                            {{ (offerOptionSelectionService.getItemById(option.id).nbChild > 1 ? 'offer.option.nbChild.plural.value' : 'offer.option.nbChild.value')|translate:{ count : offerOptionSelectionService.getItemById(option.id).nbChild } }}
                                                                            <span class="green" *ngIf="!offer.onRequest">{{ 'offer.presential.child.age.interval.value'|translate:{min : option.minChildrenAge, max : option.maxChildrenAge} }}</span>
                                                                        </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                        </div>
                                                    </div>

                                                    <!-- Choix du jour -->

                                                    <ng-container *ngIf="option.availableByDay">
                                                        <div class="col_2 y_items_center">
                                                            <div class="form-group">
                                                                <select [value]="option.day" (change)="handleOptionDay(option, $event)">
                                                                    <option *ngFor="let count of choiceDayOptionValues" [ngValue]="count" [selected]="count === option.day">{{ count }}</option>
                                                                </select>
                                                                <label>{{ 'offer.option.day.value'|translate }} <span>{{ offerOptionSelectionService.getItemById(option.id).day }}</span></label>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                </ng-container>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row x_end check_option">
                                <div class="col_content">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <input type="checkbox" id="startFromPrice-{{option.id}}" (change)="handleOptionSelection(option)">
                                            <label for="startFromPrice-{{option.id}}">
                                                <span>{{ 'offer.option.startFromPrice.value' | translate: { price: ((option.startFromPrice / 100) | parsePrice), currency: option.startFromCurrency.symbol, type: ((offer.isDisplayPriceHT ? 'offer.price.type.ht.value' : 'offer.price.type.ttc.value') | translate) } }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </ng-container>
            </div>

        </app-core-offer-item>

    </ng-container>

</div>

<ng-template #dateEngineTemplate>

    <div class="choice_date">

        <!-- Moteur de date -->

        <div class="row marges">

            <div class="col_12">
                <app-core-offer-date-engine [childDatesAllowed]="childDatesOffer" [currentUser]="user" [offer]="offer" [configuration]="offerDateEngineConfiguration" [loadItemsSourceCallback]="loadItemsSourceCallback" [loadFirstAvailabilityItemSourceCallback]="loadFirstAvailabilityItemSourceCallback"></app-core-offer-date-engine>
            </div>

        </div>

        <ng-container *ngIf="bookingSummaryService.item">

            <div class="row marges x_end">
                <div class="col_12">
                    <div class="recap_date">

                        <!-- Options -->

                        <div class="row marges" *ngFor="let bookingCompositionOption of bookingSummaryService.item.composition.options">
                            <div class="col y_items_start">
                                <span class="name">{{ translationService.getFallbackTranslation(bookingCompositionOption.option.translations).title }}</span>
                            </div>
                            <div class="col_content y_items_start">
                                <span class="price">
                                    {{ (bookingCompositionOption.priceTTC / 100) | parsePrice }} {{ offer.currency.symbol }} {{ 'offer.price.type.ttc.value'|translate }}

                                    <span *ngIf="bookingCompositionOption.priceHT" class="price-ht">
                                         ({{ (bookingCompositionOption.priceHT / 100) | parsePrice }} {{ offer.currency.symbol }}  {{ 'offer.price.type.ht.value'|translate }})
                                    </span>

                                </span>
                            </div>
                        </div>

                        <!-- Total -->

                        <ng-container *ngIf="bookingSummaryService.item.composition && bookingSummaryService.item.type === 'booking'">

                            <div class="row marges total">
                                <div class="col x_end y_items_end">
                                    <span class="name">{{ 'stay.price.total.value' | translate }}</span>
                                </div>
                                <div class="col_content x_end y_items_end">
                                    <div>
                                        <span class="price" *ngIf="bookingSummaryService.item.composition.promotionPriceTTC < bookingSummaryService.item.composition.priceTTC">
                                            <span class="old_price">
                                            {{ (bookingSummaryService.item.composition.priceTTC / 100) | parsePrice }} {{ offer.currency.symbol }} {{ 'offer.price.type.ttc.value'|translate }}
                                                <span *ngIf="bookingSummaryService.item.composition.priceHT" class="price-ht">
                                                ({{ (bookingSummaryService.item.composition.priceHT / 100) | parsePrice }} {{ offer.currency.symbol }}  {{ 'offer.price.type.ht.value'|translate }})
                                            </span>
                                        </span>
                                        </span>
                                        <span class="price">
                                            {{ (bookingSummaryService.item.composition.promotionPriceTTC / 100) | parsePrice }} {{ offer.currency.symbol }} {{ 'offer.price.type.ttc.value'|translate }}
                                            <span *ngIf="bookingSummaryService.item.composition.promotionPriceHT" class="price-ht">
                                                ({{ (bookingSummaryService.item.composition.promotionPriceHT / 100) | parsePrice }} {{ offer.currency.symbol }}  {{ 'offer.price.type.ht.value'|translate }})
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </div>

        </ng-container>

        <div class="row {{ hasAccessBookingCreate() ? 'x_end' : 'x_start' }} validate_date">

            <ng-container *ngIf="hasGiftVoucherEnable && !isView('gift-voucher-request')">

                <!-- Bon cadeau -->

                <button type="button" class="btn_cta btn_lines btn_little" (click)="validateGiftVoucherRequest()">
                    {{ 'offer.gift.value' | translate }}
                </button>

            </ng-container>

            <ng-container *ngIf="hasAccessBookingCreate()">

                <!-- Demande de réservation -->

                <button type="button" class="btn_cta btn_little btn_booking" [disabled]="(!offerDateEngineComponent?.valid) || (offerDateEngineComponent?.item.type !== 'booking')" (click)="validateBookingRequest('booking')">
                    {{ bookingRequestLabel }}
                </button>

                <ng-container *ngIf="offer.isAllowBookingRequest">

                    <!-- Demande de devis -->

                    <button type="button" class="btn_cta btn_little btn_booking" [disabled]="!offerDateEngineComponent?.valid" (click)="validateBookingRequest('request')">
                        {{ quotationRequestLabel }}
                    </button>

                </ng-container>

            </ng-container>

        </div>

    </div>

</ng-template>

<ng-template #bookingRequestTemplate>

    <app-core-offer-booking-request-create [offer]="offer" [currentUser]="user" [termsAndConditions]="termsAndConditions" [compositionItem]="compositionItem" [hasPermanentOptionsAvailable]="hasPermanentOptionsAvailable" [viewLanguage]="locale" (backToCompositionRequested)="handleBackToComposition()" (permanentOptionsSelectionCleared)="handlePermanentOptionSelectionClear()" (promotionApplied)="handlePromotionSelection($event)">

        <!-- Projection de contenu pour les options permanentes -->

        <div permanentOptionsContent *ngIf="(permanentOptions$ | async)?.length > 0">

            <div *ngIf="compositionItem.type === 'booking'" class="txt_offer">
                <p class="title">{{ 'offer.permanentOptions.value' | translate}}</p>
            </div>

            <div class="option_offer" *ngFor="let permanentOption of permanentOptions$ | async">
                <div class="option">
                    <div class="row">
                        <div class="col_3" *ngIf="translationService.getFallbackTranslation(permanentOption.translations).picture?.image">
                            <div class="visuel">
                                <img [src]="translationService.getFallbackTranslation(permanentOption.translations).picture.image | imageSource: 'rectangle' : 200 : 90" alt="{{ translationService.getFallbackTranslation(permanentOption.translations).title }}" />
                            </div>
                        </div>
                        <div class="col_9">
                            <div class="infos">
                                <div class="txt_offer">
                                    <p class="title x_between_lg">
                                        {{ translationService.getFallbackTranslation(permanentOption.translations).title }}
                                        <ng-container *ngIf="getSummaryPermanentOption(permanentOption.id)">
                                            <span *ngIf="!!getSummaryPermanentOption(permanentOption.id).priceTTC">{{ (getSummaryPermanentOption(permanentOption.id).priceTTC / 100) | parsePrice }}{{ offer.currency.symbol }} {{ 'offer.displayPrice.ttc.value' | translate }}</span>
                                            <span *ngIf="getSummaryPermanentOption(permanentOption.id).onRequest">{{ 'offer.booking.permanentOptions.onRequest' | translate }}</span>
                                        </ng-container>
                                    </p>
                                    <div [innerHTML]="translationService.getFallbackTranslation(permanentOption.translations).description|safeHtml"></div>
                                </div>
                                <div class="x_end">
                                    <p>
                                        <a class="terms_and_condition" (click)="openOfferPermanentOptionFileInNewTab(translationService.getFallbackTranslation(permanentOption.translations).termsAndConditions)" target="_blank">{{ 'offer.permanentOptions.termsAndConditions.value' | translate }}</a>

                                        <ng-container *ngIf="translationService.getFallbackTranslation(permanentOption.translations).additionalDocument">
                                            - <a class="terms_and_condition" (click)="openOfferPermanentOptionFileInNewTab(translationService.getFallbackTranslation(permanentOption.translations).additionalDocument)" target="_blank">{{ 'offer.permanentOptions.additionalDocument.value' | translate }}</a>
                                        </ng-container>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row x_end check_option">
                    <div class="col_content">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="permanentOptionStartFromPrice-{{permanentOption.id}}" (change)="handlePermanentOptionSelection(permanentOption)">
                                <label for="permanentOptionStartFromPrice-{{permanentOption.id}}">
                                    <span>{{ 'offer.permanentOption.select.value' | translate }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-core-offer-booking-request-create>

</ng-template>

<ng-template #giftVoucherRequestTemplate>
    <app-offer-gift-voucher-request-create (backToOfferRequested)="setView('date-engine')" [offer]="offer" [currentUser]="user" [termsAndConditions]="termsAndConditions" ></app-offer-gift-voucher-request-create>
</ng-template>
