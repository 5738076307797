import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {User} from "@core/shared/models/user";
import {Channel} from "@core/shared/models/channel";
import {Society} from "@core/shared/models/society";
import {SelectionModel} from "@angular/cdk/collections";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {TranslateService} from "@ngx-translate/core";
import {ChannelService} from "@core/shared/services/channel.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {ChannelTranslation} from "@core/shared/models/channel-translation";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import {Access, AccessType} from "@core/shared/models/access";
import {Currency} from "@core/shared/models/currency";
import {CurrencyService} from "@core/shared/services/currency.service";
import {UserService} from "@core/shared/services/user.service";
import {Role} from "@core/shared/models/role";
import {environment} from "../../../../../environments/environment";
import {TranslationService} from "@core/shared/services/translation.service";
import {SocietyService} from "@core/shared/services/society.service";

@Component({
    selector: 'app-core-page-channel-list',
    templateUrl: './page-channel-list.component.html',
    styleUrls: ['./page-channel-list.component.scss']
})
export class PageChannelListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'translations.name',
        'translations.title',
        'marketplace',
        'enable',
        'isDistributor',
        'currency',
        'locale',
        'countOffers',
        'createdAt',
        'updatedAt',
        'integrationType',
        'comment'
    ];

    public filterBuilder: FilterBuilder;

    public user: User;

    public items: Channel[] = [];

    public society: Society;

    public collaborator: User[] = [];

    public locale: string;

    public locales: LocaleItem[] = [];

    public currencies: { id: number, label: string }[] = [];

    public societies: Society[];

    public selection = new SelectionModel<Channel>(true, []);

    public types: object[] = [
        {
            id: 'js',
            label: this._translateService.instant('channel.restricted.typeIntegration.js.value')
        },
        {
            id: 'sub_domain',
            label: this._translateService.instant('channel.restricted.typeIntegration.subDomain.value')
        },
        {
            id: 'api',
            label: this._translateService.instant('channel.restricted.typeIntegration.api.value')
        }
    ];

    public isDistributorsItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('channel.restricted.isDistributor.yes.value')
        },
        {
            id: 0,
            label: this._translateService.instant('channel.restricted.isDistributor.no.value')
        }
    ];

    public isEnableItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('channel.restricted.isEnable.yes.value')
        },
        {
            id: 0,
            label: this._translateService.instant('channel.restricted.isEnable.no.value')
        }
    ];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _channelService: ChannelService,
        private _snackBar: MatSnackBar,
        private _currencyService: CurrencyService,
        private _userService: UserService,
        private _societyService: SocietyService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.user = this._userService.currentUser.value;

        if(this.hasRole('ROLE_SUPER_ADMIN') || this.hasRole('ROLE_ADMIN')){
            this.displayedColumns.splice(1, 0, 'society.name');
        }

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.society = data.society;

            this._initLocales();

            this._initCurrencies();

            this._initFilterBuilder();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    if(this.hasRole('ROLE_SUPER_ADMIN') || this.hasRole('ROLE_ADMIN')){
                        return this._channelService.getPaginationItemsAPI(this.itemsApiParams);
                    }else{
                        return this._channelService.getPaginationSocietyItemAPI(this.society.id, this.itemsApiParams);
                    }


                }),
                map(this.mapApiResult),
            ).subscribe((data: Channel[]): void => {

                this.items = data;

                this.selection.clear();
            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            if(this.hasRole('ROLE_SUPER_ADMIN') || this.hasRole('ROLE_ADMIN')){
                this._channelService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

                    this.items = data;
                });
            }else{
                this._channelService.getPaginationSocietyItemAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

                    this.items = data;
                });
            }

        };
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    private _initCurrencies(): void {

        this._currencyService.getItemsAPI().subscribe((currencies: Currency[]): void => {
            this.currencies = currencies.map((currency: Currency) => {

                return {
                    id: currency.id,
                    label: this.translationService.getFallbackTranslation(currency.translations).label
                };
            });

        });
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._channelService.getPaginationSocietyItemAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

            this.items = data;
        });
    }

    public getModel(item: Channel): Channel {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const channel: Channel = this.items.find((item: Channel): boolean => {

            return item.id === id;
        });

        const translation: ChannelTranslation = this.translationService.getFallbackTranslation(channel.translations);

        const title: string = this._translateService.instant('channel.restricted.actions.delete.value');

        const content: string = this._translateService.instant('channel.restricted.actions.delete.content.value', {
            channel: translation.name
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._channelService.deleteItemAPI(channel.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('channel.restricted.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._societyService.getItemAPI(this.society.id).subscribe((item: Society): void => {

                    this.society = item;
                });

                this._channelService.getPaginationSocietyItemAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/channel/create']);
    }

    public editItem(id: number): void {

        this._router.navigate(['account/channel/update', id]);
    }

    public viewItem(id: number): void {
        this._router.navigate(['account/channel/read', id]);
    }

    public getLocaleItem(id: string): LocaleItem {

        return LOCALE_ITEMS.find((locale: LocaleItem): boolean => {

            return locale.id === id;
        });
    }

    public userHasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.user.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }
        else{

            if(this.hasRole('ROLE_SUPER_ADMIN') || this.hasRole('ROLE_ADMIN')){

                params.push(`sort[updatedAt]=DESC`);

            } else {

                params.push(`sort[createdAt]=DESC`);
            }
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Channel>) => Channel[] {

        return (data: Pagination<Channel>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public hasAccessCreate(): boolean {
        var accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'CHANNEL_CREATE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessEdit(): boolean {
        var accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'CHANNEL_EDIT_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessDelete(): boolean {
        var accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'CHANNEL_DELETE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public getIntegrationType(channel: Channel): string {

        if (channel.enableJS) {

            return this._translateService.instant('channel.restricted.typeIntegration.js.value');
        }

        if (channel.enableSubDomain) {

            return this._translateService.instant('channel.restricted.typeIntegration.subDomain.value');
        }

        if (channel.enableApi) {

            return this._translateService.instant('channel.restricted.typeIntegration.api.value');
        }

        return null;
    }

    public getIsDistributor(channel: Channel): string {
        return this._translateService.instant(`channel.restricted.isDistributor.${channel.isDistributor ? 'yes' : 'no'}.value`);
    }

    public getIsEnable(channel: Channel): string {

        return this._translateService.instant(`channel.restricted.isEnable.${channel.enable ? 'yes' : 'no'}.value`);
    }

    public goToLink(channel: Channel): string {

        if(channel.enableSubDomain) {

            return `https://${channel.subDomain}${environment.channelSubDomainSuffix}`;

        } else {

            return null;
        }
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }
}
