import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OfferPeriodCreateConfiguration} from "@core/shared/models/offer/offer-period/offer-period-create";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {Moment} from "moment";
import {DATE_FORMAT} from "@app/data";
import {Day, DAYS} from "@core/shared/models/day";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferOptionPeriodService} from "@core/shared/services/offer/offer-option/offer-option-period.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-core-offer-option-period-create',
    templateUrl: './offer-option-period-create.component.html',
    styleUrls: ['./offer-option-period-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferOptionPeriodCreateComponent implements OnInit {

    @Input() option: OfferOption;

    @Input() configuration: OfferPeriodCreateConfiguration;

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _offerOptionPeriodService: OfferOptionPeriodService,
        private _translateService: TranslateService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            dateStart: ['', [Validators.required]],
            dateEnd: ['', [Validators.required]],
            excludedDays: [[]]
        });

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                dateStart: (this.form.get('dateStart').value as Moment).format(DATE_FORMAT),
                dateEnd: (this.form.get('dateEnd').value as Moment).format(DATE_FORMAT)
            });

            this._offerOptionPeriodService.createItemAPI(this.option.id, data).subscribe((): void => {

                this.create.emit();
            });
        };
    }

    public handleExcludedDay(event: any): void{

        const control: UntypedFormControl = this.form.get('excludedDays') as UntypedFormControl;

        const controlValue: number[] = control.value;

        const value: number = parseInt(event.target.value);

        if(event.target.checked){

            controlValue.push(value);
        }
        else{

            const index: number = controlValue.findIndex((i: number): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue.sort());
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get days(): Day[] {

        return DAYS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
