import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";

@Component({
    selector: 'app-core-offer-catalog-request-revoke-dialog',
    templateUrl: './offer-catalog-request-revoke-dialog.component.html',
    styleUrls: ['./offer-catalog-request-revoke-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferCatalogRequestRevokeDialogComponent implements OnInit {

    @Output() confirm: EventEmitter<{ comment: string }> = new EventEmitter<{ comment: string }>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _dialogRef: MatDialogRef<OfferCatalogRequestRevokeDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        const events: EventEmitter<unknown>[] = [
            this.confirm,
            this.cancel,

        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            comment: [null]
        });

        this.formService.submitCallback = (): void => {

            this.confirm.emit({
                comment: this.form.get('comment').value
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
