import {Exclude, Expose} from "class-transformer";

export type OfferDateStockType = 'public';

@Exclude()
export class OfferDateStock {

    @Expose()
    id: number;

    @Expose()
    type: OfferDateStockType;

    @Expose()
    commonAllocatedStock: number;

    @Expose()
    commonRemainingStock: number;

    @Expose()
    commonSoldStock: number;

    @Expose()
    adultAllocatedStock: number;

    @Expose()
    adultRemainingStock: number;

    @Expose()
    adultSoldStock: number;

    @Expose()
    childAllocatedStock: number;

    @Expose()
    childRemainingStock: number;

    @Expose()
    childSoldStock: number;
}
