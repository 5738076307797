import {Injectable} from '@angular/core';
import {Moment} from "moment";
import {DATE_FORMAT} from "../../../../../../data";
import {EventInput} from "@fullcalendar/core";

@Injectable({
    providedIn: 'root'
})
export class OfferOptionPeriodCalendarService {

    public createDateEvent(identifier: number, date: Moment, className: string): EventInput {

        return {
            display: 'background',
            date: date.clone().format(DATE_FORMAT),
            className: className,
            extendedProps: {
                identifier: identifier
            }
        };
    }
}
