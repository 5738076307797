import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Offer, OfferTranslation} from "@core/shared/models/offer";
import {TranslationService} from "@core/shared/services/translation.service";
import Swiper, {Navigation, Pagination} from "swiper";

@Component({
    selector: 'app-core-offer-picture-gallery',
    templateUrl: './offer-picture-gallery.component.html',
    styleUrls: ['./offer-picture-gallery.component.scss']
})
export class OfferPictureGalleryComponent implements AfterViewInit {

    @Input() offer: Offer;

    @ViewChild('swiper', {static: true}) swiperRef: ElementRef<HTMLElement>;

    @ViewChild('swiperPagination', {static: true}) swiperPaginationRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonPrev', {static: true}) swiperButtonPrevRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonNext', {static: true}) swiperButtonNextRef: ElementRef<HTMLElement>;

    constructor(public translationService: TranslationService) {
    }

    ngAfterViewInit() {

        this._initSlider();
    }

    private _initSlider(): void {

        new Swiper(this.swiperRef.nativeElement, {
            loop: false,
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: false,
            modules: [
                Navigation,
                Pagination
            ],
            navigation: {
                nextEl: this.swiperButtonNextRef.nativeElement,
                prevEl: this.swiperButtonPrevRef.nativeElement,
            },
            pagination: {
                el: this.swiperPaginationRef.nativeElement,
                clickable: true
            }
        });
    }

    get translatedItemFields(): OfferTranslation {

        return this.translationService.getFallbackTranslation(this.offer.translations);
    }
}
