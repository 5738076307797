import {Injectable} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {AllPaginateItemsAPI} from "@core/shared/models/all-paginate-items-api";

@Injectable({
    providedIn: 'root'
})
export class NetworkOfferCreatorService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {}

    public getItemsAPI(params?: string[]): Observable<NetworkOfferCreator[]> {

        return new AllPaginateItemsAPI<NetworkOfferCreator>(this.getPaginationItemsAPI.bind(this), params).request$;
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<NetworkOfferCreator>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/networkOfferCreators`;

        params = params || [];

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<NetworkOfferCreator>>(url, {
            headers: headers
        });
    }

    public getItemAPI(id: number, params?: string[]): Observable<NetworkOfferCreator> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/networkOfferCreators/${id}`;

        if(!!params && params.length > 0){

            url = `${url}?${params}`;
        }

        return this._httpClient.get<NetworkOfferCreator>(url,{
            headers: headers
        });
    }

    public createItemAPI(data: object): Observable<NetworkOfferCreator> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<NetworkOfferCreator>(`${this._apiService.getApiUrl(false, true)}/networkOfferCreators`, data, {
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<NetworkOfferCreator> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<NetworkOfferCreator>(`${this._apiService.getApiUrl(false, true)}/networkOfferCreators/${id}`, data,{
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/networkOfferCreators/${id}`,{
            headers: headers
        });
    }
}
