<h1 mat-dialog-title>{{ 'customerTypology.add.value' | translate }}</h1>
<mat-dialog-content>

    <div class="form-group">
        <label>{{ 'customerTypology.select.action.value' | translate }} :</label>
        <div class="form-group">
            <mat-select [(ngModel)]="items" multiple>
                <mat-option *ngFor="let customerTypologyItem of availableItems" [value]="customerTypologyItem">
                    {{ translationService.getFallbackTranslation(customerTypologyItem.translations).name  }}
                </mat-option>
            </mat-select>
            <mat-label>{{ 'customerTypology.select.action.value' | translate }}</mat-label>
        </div>
    </div>

    <ng-container *ngIf="existingItems.length">

        <div class="form-group">
            <label>{{ 'customerTypology.duplicate.action.value' | translate }} :</label>
            <div class="form-group">
                <mat-select [(ngModel)]="duplicatedItem">
                    <mat-option>-</mat-option>
                    <mat-option *ngFor="let customerTypologyItem of existingItems" [value]="customerTypologyItem">
                        {{ translationService.getFallbackTranslation(customerTypologyItem.translations).name }}
                    </mat-option>
                </mat-select>
                <mat-label>{{ 'customerTypology.select.action.value' | translate }}</mat-label>
            </div>
        </div>

    </ng-container>

</mat-dialog-content>
<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" [disabled]="!items.length" (click)="select.emit({items: items, duplicatedItem: duplicatedItem})">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>

