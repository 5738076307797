import { TitleCasePipe } from '@angular/common';
import {AfterContentInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appInputCapitalize]',
})
export class InputCapitalizeDirective implements AfterContentInit {

    constructor(private _elementRef: ElementRef) { }

    @HostListener('input') onInput(): void {

        this._apply();
    }

    ngAfterContentInit(): void {

        this._apply();
    }

    private _apply(): void {

        const input: HTMLInputElement = this._elementRef.nativeElement;

        input.value = input.value.charAt(0).toUpperCase() + input.value.substr(1);
    }
}
