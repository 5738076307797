import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {PromotionPeriod} from "../../models/promotion/promotion-period";

@Injectable({
    providedIn: 'root'
})
export class PromotionPeriodService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public createItemAPI(id: number, data: object): Observable<PromotionPeriod> {

        const url = `${this._apiService.getApiUrl(false, true)}/promotions/${id}/periods`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'text')
            .set('Accept', 'application/json');

        return this._httpClient.post<PromotionPeriod>(url, data, {
            headers: headers
        });
    }
}
