import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICustomButton, IElement, IElementBackgroundColor} from "./element";
import {ElementService} from "./element.service";
import {IAllowedType} from "../template/template";
import {TranslateService} from "@ngx-translate/core";
import {ElementType, isType} from "./type/type";
import {ApiService} from "../api/api.service";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-element',
    templateUrl: './element.component.html',
    styleUrls: ['./element.component.scss']
})

/**
 * Element d'une ligne
 */
export class ElementComponent implements OnInit {

    @Input() element: IElement;

    @Input() allowedTypes: IAllowedType[];

    @Input() isDecreaseAllowed: boolean;

    @Input() isIncreaseAllowed: boolean;

    @Input() deleteable: boolean;

    @Output() decreaseContent = new EventEmitter();

    @Output() increaseContent = new EventEmitter();

    @Output() delete = new EventEmitter();

    backgroundColors: IElementBackgroundColor[] = [];

    customButtons: ICustomButton[] = [];

    /**
     *
     * @param _translateService
     * @param _dialog
     * @param _elementService
     * @param _snackbar
     * @param _apiService
     */
    public constructor(
        private _translateService: TranslateService,
        private _dialog: MatDialog,
        private _elementService: ElementService,
        private _snackbar: MatSnackBar,
        private _apiService: ApiService
    ) {}

    /**
     * Initialisation du composant
     */
    public ngOnInit():void  {

        this.backgroundColors = this._elementService.getBackgroundColors();
        this.customButtons = this._elementService.getCustomButtons();
    }

    /**
     * Modale de confirmation pour la réinitialisation du type ainsi que du contenu de l'élément
     */
    resetContent(): void {

        this._translateService.get(['element.reset.confirm.title', 'element.reset.confirm.content']).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title : messages['element.reset.confirm.title'],
                content: messages['element.reset.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._elementService.clearElement(this.element);

                this._translateService.get(['element.reset.success', 'action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(messages['element.reset.success'], messages['action.close']);
                });
            });
        });
    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {any} content
     */
    setContent(content: object): void {

        this.element.content = content;
    }

    /**
     * Détermine si l'élément est d'un type spécifique
     *
     * @param type
     */
    isType(type: ElementType){

        return isType(type, this.element);
    }

    public getApiUrl(){
        return this._apiService.getApiUrl(true);
    }
}
