import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {Observable, of} from "rxjs";
import {TIMEZONES} from "@core/shared/data/timezone";

@Component({
    selector: 'app-core-timezone-select',
    templateUrl: './timezone-select.component.html',
    styleUrls: ['./timezone-select.component.scss']
})
export class TimezoneSelectComponent implements OnInit {

    @Input() form: UntypedFormGroup;

    @Input() formControlName: string = 'timezone';

    @Input() label: string = 'timezone.label.value';

    @Input() required: boolean = false;

    @Input() requiredErrorTranslation: string = 'timezone.error.required.value';

    public items$: Observable<{label: string, value: string}[]>;

    constructor() {
    }

    ngOnInit(): void {

        this._initItems();
    }

    private _initItems(): void {

        this.items$ = of(TIMEZONES);
    }
}
