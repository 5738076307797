<div class="auth-wrapper">

    <div class="header_auth">
        <div class="box_xl">
            <img src="assets/images/logo_tywin.png" alt="Ty-win" title="Ty-win" class="logo" />
        </div>
    </div>

    <div class="auth">
        <div class="row">
            <div class="col_12 bloc_auth">
                <div class="auth_title">
                    <span>{{ 'user.validateAccount.value' | translate }}</span>
                </div>

                <div class="content_auth">

                    <form [formGroup]="form" (ngSubmit)="formService.submit()">

                        <!-- Mot de passe -->

                        <div class="form-group">
                            <input type="password" value="" class="form-control" id="password" formControlName="password" placeholder=" "/>
                            <label for="password">{{ 'user.password.value'|translate }}</label>
                            <mat-error *ngIf="form.get('password').errors && (form.get('password').dirty || form.get('password').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('password').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('password').errors.minlength">{{ 'form.control.error.minLength.value' | translate:{ minLength: minLengthPassword } }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Confirmation du mot de passe -->

                        <div class="form-group">
                            <input type="password" value="" class="form-control" id="confirmPassword" formControlName="confirmPassword" placeholder=" "/>
                            <label for="confirmPassword">{{ 'user.confirmPassword.value'|translate }}</label>
                            <mat-error *ngIf="form.get('confirmPassword').errors && (form.get('confirmPassword').dirty || form.get('confirmPassword').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('confirmPassword').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('confirmPassword').errors.mustMatch">{{ 'user.validateAccount.password.error.mustMatch.value' | translate:{ minLength: minLengthPassword } }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Soumission -->

                        <div class="form-group submit">
                            <button class="btn_cta" type="submit">
                                {{ 'form.submit.action.value'|translate }}
                            </button>
                        </div>


                    </form>
                </div>

            </div>
        </div>
    </div>

</div>
