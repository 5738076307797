<div class="availability-offer-list">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.priceAndAvailability.self.list.value' | translate }}</h1>
            </div>
        </div>
    </div>
    <div>

        <div class="row marges">
            <div class="col_12 col_md_4">

                <!-- Recherche d'une offre -->

                <app-core-search-autocomplete [sourceCallback]="searchOfferAutocompleteSourceCallback" [configuration]="searchOfferAutocompleteConfiguration"></app-core-search-autocomplete>
            </div>
        </div>

        <br>

        <div class="row marges">
            <div class="col_12">
                <div class="form-group">
                    <div class="message">
                    <span class="message-help">
                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.priceAndAvailability.display.onlyBooking.value' | translate }}
                    </span>
                    </div>
                </div>
            </div>
        </div>

        <br>

        <!-- Affichage des offres -->

        <ng-container *ngIf="(offers$ | async) as offers">

            <div class="row marges">
                <div class="col_12">

                    <ng-container *ngIf="searchQuery; else ownOffersLabel">

                        <div class="row">
                            <div class="col_content y_items_center">
                                <h2 *ngIf="searchQuery">{{ 'search.query.result.value' | translate:{ query: searchQuery } }}</h2>
                            </div>
                            <div class="col_content y_items_center">
                                <button mat-icon-button type="button" class="reset-query" (click)="resetSearchQuery()">
                                    <mat-icon>loop</mat-icon>
                                </button>
                            </div>
                        </div>

                    </ng-container>

                    <ng-template #ownOffersLabel>

                        <h2 *ngIf="!searchQuery">{{ 'offer.list.own.value' | translate }}</h2>

                    </ng-template>

                    <!-- Résultat de la recherche -->

                    <ng-container *ngIf="offers.length; else noResult">
                        <div class="offer-search-list">
                            <div *ngFor="let offer of offers" class="offer-search-item">
                                <a [routerLink]="['/account/availability/offer/read', offer.id]">{{ translationService.getFallbackTranslation(offer.translations).name }}</a>
                                <span *ngIf="offer.reference" class="offer-reference">
                                    {{ 'reference.value' | translate }} : {{ offer.reference }}
                                </span>
                                <img *ngIf="offer.onlineSale && offer.onlineSale.enable" src="assets/images/online-sale-green.svg" alt="{{ 'offer.onlineSale.value' | translate }}" matTooltip="{{ 'offer.onlineSale.status.availableOnlineSale.value' | translate }}" />
                            </div>
                        </div>
                    </ng-container>

                    <!-- Aucun résultat -->

                    <ng-template #noResult>
                        <ng-container *ngIf="!isLoading$.getValue()">
                            <div class="search_no_result">
                                <p>{{ 'offer.search.noResult.value' | translate }}</p>
                            </div>
                        </ng-container>
                    </ng-template>
                </div>

            </div>

            <div class="row more_offer" *ngIf="offers.length && hasMoreOffers">
                <div class="col x_center">
                    <button class="btn_cta btn_highlight" type="button" (click)="loadMoreOffers()">
                        {{ 'offer.more.value' | translate }}
                    </button>
                </div>
            </div>

        </ng-container>
    </div>
</div>
