import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {BankAccount} from "@core/shared/models/bankAccount";
import {Service} from "@core/shared/models/service";
import {plainToClass} from "class-transformer";
import { SocietyService } from "@core/shared/models/society-service";
import {map} from "rxjs/operators";
import {Kyc} from "@core/shared/models/Kyc";
import {Ubos} from "@core/shared/models/Ubo";

@Injectable({
    providedIn: 'root'
})
export class ServiceService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {

    }

    public createBankAccountItemAPI(data: object, society): Observable<BankAccount> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<BankAccount>(`${this._apiService.getApiUrl(false, true)}/societies/${society}/legalDocuments/bankAccount`, data, {
            headers: headers
        });
    }

    public getBankAccountItemAPI(society): Observable<BankAccount> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<BankAccount>(`${this._apiService.getApiUrl(false, true)}/societies/${society}/legalDocuments/bankAccount`, {
            headers: headers
        });
    }

    public getKycItemAPI(society, type): Observable<Kyc> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Kyc>(`${this._apiService.getApiUrl(false, true)}/societies/${society}/legalDocuments/kyc/${type}`, {
            headers: headers
        });
    }

    public getUboItemAPI(society): Observable<Ubos> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Ubos>(`${this._apiService.getApiUrl(false, true)}/societies/${society}/legalDocuments/ubos/declaration`, {
            headers: headers
        });
    }

    public createUboItemAPI(data: object, society): Observable<BankAccount> {
        var finalData =  {
            ubos : data
        }

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<BankAccount>(`${this._apiService.getApiUrl(false, true)}/societies/${society}/legalDocuments/ubos/declaration`, finalData, {
            headers: headers
        });
    }

    public createKycItemAPI(data: object,fileToUpload, society): Observable<BankAccount> {
        /** @ts-ignore **/
        var type = data.type;
        const headers: HttpHeaders = new HttpHeaders()
            .set('Accept', '*/*');

        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        return this._httpClient.post<BankAccount>(`${this._apiService.getApiUrl(false, true)}/societies/${society}/legalDocuments/kyc/${type}`, formData, {
            headers: headers
        });
    }

    public getItemsAPI(params?: string[]): Observable<Service[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/services`;

        params = params || [];

        params.push('disablePagination=1');

        params.push('sort[position]=ASC');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Service[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): Service[] => {

                return items.map((item: object): Service => {

                    return plainToClass(Service, item);
                });
            })
        );

    }

    public getItemsServiceBySocietyWithoutPagination(id: number, params?: string[]): Observable<SocietyService[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/services`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<SocietyService[]>(url,{
            headers: headers
        }).pipe(
            map((items: object[]): SocietyService[] => {

                return items.map((item: object): SocietyService => {

                    return plainToClass(SocietyService, item);
                })
            })
        );
    }

    public getItemAPI(id: number): Observable<Service> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/services/${id}`;

        return this._httpClient.get<Service>(url, {
            headers: headers
        }).pipe(
            map((item: object): Service => {

                return plainToClass(Service, item);
            })
        );
    }

    public createSocietyServiceItemAPI(data: object, societyId: number): Observable<SocietyService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<SocietyService>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/services`, data, {
            headers: headers
        });
    }

    public cancelDeleteSocietyServiceItemAPI(id: number): Observable<SocietyService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<any>(`${this._apiService.getApiUrl(false, true)}/societies/services/${id}/delete/cancel`, {
            headers
        })
        .pipe(
            map((item: object): SocietyService => {

                return plainToClass(SocietyService, item);
            })
        );
    }

    public summarySocietyServiceItemAPI(data: object, societyId: number): Observable<SocietyService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<SocietyService>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/services/summary`, data, {
            headers: headers
        });
    }

    public deleteSocietyServiceItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<any>(`${this._apiService.getApiUrl(false, true)}/societies/services/${id}`, {
            headers
        });
    }

    public getSocietyServiceItemAPI(id: number): Observable<SocietyService> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/services/${id}`;

        return this._httpClient.get<SocietyService>(url,{
            headers: headers
        }).pipe(
            map((item: object): SocietyService => {

                return plainToClass(SocietyService, item);
            })
        );
    }
}
