import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "@core/shared/services/user.service";
import {CurrencyService} from "@core/shared/services/currency.service";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {OfferDurationService} from "@core/shared/services/offer/offer-duration.service";
import {FormService} from "@core/shared/services/form.service";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {FormTabValidationItem, OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TenderService} from "@core/shared/services/tender.service";
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {TenderCompositionPublic} from "@core/shared/models/tender/tender-composition";
import {REGEX_ONLY_NUMBER} from "@core/shared/models/regex";
import {Observable, of} from "rxjs";
import {OfferDuration} from "@core/shared/models/offer/offer-duration";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {MatLegacyOption} from "@angular/material/legacy-core";
import {OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {map, tap} from "rxjs/operators";
import {DATE_FORMAT, SUPPORTED_LOCALES} from "@app/data";
import {Currency} from "@core/shared/models/currency";
import moment, {Moment} from "moment";
import {FileConfig} from "@lib/form/fields/file/file.component";
import {ApiService} from "@core/shared/services/api.service";
import {Tender} from "@core/shared/models/tender";
import {TenderDocument} from "@core/shared/models/tender/tender-document";
import {FileService} from "@core/shared/services/file.service";
import {TenderCompositionDate} from "@core/shared/models/tender/tender-composition/tender-composition-date";
import {OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";
import {Pagination} from "@core/shared/models/pagination";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";
import {OfferCreatorCardService} from "@core/shared/services/offer-creator/offer-creator-card.service";
import {minLengthArrayValidator} from "@core/shared/validators/min-length-array.validator";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";

type TemplateView = ('form' | 'confirmation');

type FormPanelTag = ('generalData' | 'offerContent' | 'marketingParameter' | 'insurance' | 'tenderParameter');

type MarketingMode = ('giftVoucher' | 'booking' | 'request' | 'onlineSale');

interface FormPanel {

    tag: FormPanelTag;

    controls: AbstractControl[];

    valid: boolean;

    open: boolean;
}

interface AttributeObservableData {

    tag: OfferAttributeTypeTagType;

    observable: Observable<OfferAttribute[]>;
}

@Component({
    selector: 'app-core-page-tender-create',
    templateUrl: './page-tender-create.component.html',
    styleUrls: ['./page-tender-create.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService,
        OfferCreatorSearchService,
        OfferCreatorCardService
    ]
})
export class PageTenderCreateComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: false}) tabGroup: MatTabGroup;

    @ViewChild('offerCreators', {static: false}) offerCreatorsRef: TemplateRef<any>;

    @ViewChild('formData', {static: false}) formDataRef: TemplateRef<any>;

    @ViewChild('summary', {static: false}) summaryRef: TemplateRef<any>;

    protected readonly moment = moment;

    public activeViews: TemplateView[] = ['form'];

    public currentUser: User;

    public origin: { key: string, backLabel: string } = null;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public durations$: Observable<OfferDuration[]>;

    public regions: OfferAttribute[] = [];

    public customerTypologies: CustomerTypology[] = [];

    public purposeRequestMaxLength: number = 1500;

    public destinationsCommentMaxLength: number = 500;

    public restorationsCommentMaxLength: number = 500;

    public accommodationsCommentMaxLength: number = 500;

    public contentOfferCommentMaxLength: number = 1500;

    public commentMaxLength: number = 500;

    public panels: FormPanel[] = [];

    public attributeObservables$: AttributeObservableData[];

    public currencies$: Observable<Currency[]>;

    public locales: string[] = SUPPORTED_LOCALES;

    public marketingModes: MarketingMode[] = ['giftVoucher', 'onlineSale', 'booking', 'request'];

    public maxLengthOwnerReference: number = 35;

    public minClosedAt: Date;

    public maxClosedAt: Date;

    public documentConfig: FileConfig;

    public totalOfferCreators: number = 0;

    constructor(
        private _dialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _apiService: ApiService,
        private _currencyService: CurrencyService,
        private _fileService: FileService,
        private _offerAttributeService: OfferAttributeService,
        private _offerDurationService: OfferDurationService,
        private _customerTypologyService: CustomerTypologyService,
        private _translateService: TranslateService,
        private _offerCreatorService: OfferCreatorService,
        private _tenderService: TenderService,
        public userService: UserService,
        public formService: FormService,
        public offerCreatorSearchService: OfferCreatorSearchService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this.minClosedAt = moment().add(1, 'day').toDate();

        this.maxClosedAt = moment().add(6, 'months').toDate();

        this._configureSearchService();

        this._initOrigin();

        this._initCustomerTypologies();

        this._initDurations();

        this._initAttributes();

        this._initCurrencies();

        this._initForm();

        this._initEvents();

        this._initPanels();

        this._initDocumentConfig();
    }

    ngAfterViewInit() {

        this._initTabItems();

        this._initFormTabValidation();

        this._changeDetectorRef.detectChanges();
    }

    private _configureSearchService(): void {

        this.offerCreatorSearchService.sessionEnabled = true;

        Object.assign(this.offerCreatorSearchService.enabledFilters, {
            [OfferCreatorFilterKey.Destination]: true,
            [OfferCreatorFilterKey.Target]: true,
            [OfferCreatorFilterKey.NetworkOfferCreator]: true,
            [OfferCreatorFilterKey.OfferType]: true,
            [OfferCreatorFilterKey.Name]: true,
            [OfferCreatorFilterKey.InCatalog]: this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']),
            [OfferCreatorFilterKey.HasRseLabel]: true,
        } as { [p in OfferCreatorFilterKey]: boolean });

        this.offerCreatorSearchService.selectOfferCreatorEnabled = true;

        this.offerCreatorSearchService.selectOfferCreatorAllowed = true;

        this.offerCreatorSearchService.unselectOfferCreatorAllowed = true;

        this.offerCreatorSearchService.isOfferCreatorHighlightedCallback = (offerCreator: Society): boolean => {

            if(this.offerCreatorSearchService.allOfferCreatorsSelected){

                return true;
            }

            return !!this.offerCreatorSearchService.selectedOfferCreators$.value.find((item: Society): boolean => {

                return offerCreator.id === item.id;
            });
        };

        this.offerCreatorSearchService.offerCreatorHighlightColor = (): string => {

            return 'green';
        };

        this.offerCreatorSearchService.additionalFilterParams$.next([
            `id[andnin][]=${this.currentUser.society.id}`
        ]);
    }

    private _initOrigin(): void {

        this.origin = this._activatedRoute.snapshot.queryParams['origin'] ? {
            key: this._activatedRoute.snapshot.queryParams['origin'],
            backLabel: `tender.creation.origin.${this._activatedRoute.snapshot.queryParams['origin']}.value`
        } : null;
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: 'offerCreators',
                label: 'offerCreator.plural.selection.value',
                template: this.offerCreatorsRef
            },
            {
                tag: 'formData',
                label: 'form.value',
                template: this.formDataRef
            },
            {
                tag: 'summaryData',
                label: 'summaryAndSend.value',
                template: this.summaryRef
            }
        ];
    }

    private _initFormTabValidation(): void {

        this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            allOfferCreators: [false, [Validators.required]],
            offerCreators: [[], [(control: UntypedFormControl) => {

                if(!control.parent){

                    return null;
                }

                if(control.parent.get('allOfferCreators').value){

                    return null;
                }

                if (control.value.length >= 1) {

                    return null;
                }

                return {
                    'minLengthArray': {
                        valid: false
                    }
                };
            }]],
            ownerReference: [null, [Validators.required, Validators.maxLength(this.maxLengthOwnerReference)]],
            closedAt: [null, [Validators.required]],
            remindBeforeExpire: [true, [Validators.required]],
            marketingDateStart: [null],
            purposeRequest: [null, [Validators.required, Validators.maxLength(this.purposeRequestMaxLength)]],
            comment: [null, [Validators.maxLength(this.commentMaxLength)]],
            configuration: this._formBuilder.group({
                customerTypology: [null, [Validators.required]],
                regions: [[], [(control: UntypedFormControl) => {

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                types: [[], [(control: UntypedFormControl) => {

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                themes: [[], [(control: UntypedFormControl) => {

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                activities: [[]],
                accommodations: [[], [(control: UntypedFormControl) => {

                    if(!this.nightIncluded) {

                        return null;
                    }

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                restorations: [[], [(control: UntypedFormControl) => {

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                locales: [[this.currentUser.locale], [Validators.required]],
                contentOfferComment: [null, [Validators.maxLength(this.contentOfferCommentMaxLength)]],
                destinationsComment: [null, [Validators.maxLength(this.destinationsCommentMaxLength)]],
                accommodationsComment: [null, [Validators.maxLength(this.accommodationsCommentMaxLength)]],
                restorationsComment: [null, [Validators.maxLength(this.restorationsCommentMaxLength)]],
                hasInsurance: [false, [Validators.required]],
                insuranceComment: [null, [(control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    const hasInsuranceControl: FormControl = control.parent.get('hasInsurance') as FormControl;

                    if(!hasInsuranceControl){

                        return null;
                    }

                    const hasInsurance: boolean = hasInsuranceControl.value;

                    if(hasInsurance){

                        return (control.value as boolean) ? null : {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                    else{

                        return null;
                    }
                }]]
            }),
            composition: this._formBuilder.group({
                publics: [[], (control: UntypedFormControl) => {

                    if (control.value.length >= 1) {

                        return null;
                    }

                    return {
                        'minLengthArray': {
                            valid: false
                        }
                    };
                }],
                minNbAdult: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('adult')){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxNbAdult').value && control.parent.get('maxNbAdult').value.length){

                        if(parseInt(control.parent.get('maxNbAdult').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxNbAdult: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('adult')){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minNbAdult').value && control.parent.get('minNbAdult').value.length){

                        if(parseInt(control.parent.get('minNbAdult').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                minNbChild: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxNbChild').value && control.parent.get('maxNbChild').value.length){

                        if(parseInt(control.parent.get('maxNbChild').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxNbChild: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minNbChild').value && control.parent.get('minNbChild').value.length){

                        if(parseInt(control.parent.get('minNbChild').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                minChildrenAge: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxChildrenAge').value && control.parent.get('maxChildrenAge').value.length){

                        if(parseInt(control.parent.get('maxChildrenAge').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxChildrenAge: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minChildrenAge').value && control.parent.get('minChildrenAge').value.length){

                        if(parseInt(control.parent.get('minChildrenAge').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                minDuration: [null, [Validators.required, (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!control.value){

                        return null;
                    }

                    if(control.parent.get('maxDuration').value){

                        if((control.parent.get('maxDuration').value as OfferDuration).value < (control.value as OfferDuration).value){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxDuration: [null, [Validators.required, (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!control.value){

                        return null;
                    }

                    if(control.parent.get('minDuration').value){

                        if((control.parent.get('minDuration').value as OfferDuration).value > (control.value as OfferDuration).value){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                dates: new UntypedFormArray([], [minLengthArrayValidator(1)]),
            }),
            pricing: this._formBuilder.group({
                marketingModes: [[], [(control: UntypedFormControl) => {

                    if (control.value.length < 1) {

                        return {
                            'minLengthArray': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                comment: [null, [Validators.maxLength(this.commentMaxLength)]],
                currency: [null, [Validators.required]],
                priceType: ['TTC', [(control: UntypedFormControl) => {

                    if(!this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                        return null;
                    }

                    return control.value ? null : {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                minAdultPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('adult')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxAdultPrice').value && control.parent.get('maxAdultPrice').value.length){

                        if(parseInt(control.parent.get('maxAdultPrice').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxAdultPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('adult')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minAdultPrice').value && control.parent.get('minAdultPrice').value.length){

                        if(parseInt(control.parent.get('minAdultPrice').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                minChildPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxChildPrice').value && control.parent.get('maxChildPrice').value.length){

                        if(parseInt(control.parent.get('maxChildPrice').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxChildPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minChildPrice').value && control.parent.get('minChildPrice').value.length){

                        if(parseInt(control.parent.get('minChildPrice').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                minAdultGiftVoucherPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('adult')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['giftVoucher'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxAdultGiftVoucherPrice').value && control.parent.get('maxAdultGiftVoucherPrice').value.length){

                        if(parseInt(control.parent.get('maxAdultGiftVoucherPrice').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxAdultGiftVoucherPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('adult')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['giftVoucher'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minAdultGiftVoucherPrice').value && control.parent.get('minAdultGiftVoucherPrice').value.length){

                        if(parseInt(control.parent.get('minAdultGiftVoucherPrice').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                minChildGiftVoucherPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['giftVoucher'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('maxChildGiftVoucherPrice').value && control.parent.get('maxChildGiftVoucherPrice').value.length){

                        if(parseInt(control.parent.get('maxChildGiftVoucherPrice').value) < parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
                maxChildGiftVoucherPrice: [null, [Validators.pattern(REGEX_ONLY_NUMBER), (control: UntypedFormControl) => {

                    if(!control.parent){

                        return null;
                    }

                    if(!this.isSelectedPublic('child')){

                        return;
                    }

                    if(!this.isOneOfTheseMarketingModes(['giftVoucher'])){

                        return null;
                    }

                    if(!control.value || !control.value.length){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    if(control.parent.get('minChildGiftVoucherPrice').value && control.parent.get('minChildGiftVoucherPrice').value.length){

                        if(parseInt(control.parent.get('minChildGiftVoucherPrice').value) > parseInt(control.value)){

                            return {
                                'isInvalidInterval': {
                                    valid: false
                                }
                            };
                        }
                    }

                    return null;
                }]],
            }),
            documents: new UntypedFormArray([])
        });

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const formValue = {...this.form.getRawValue()};

            const data: object = Object.assign(formValue, {
                offerCreators: (formValue.offerCreators as Society[]).map((offerCreator: Society): { offerCreator: Society } => {

                    return {
                        offerCreator: offerCreator
                    };
                }),
                closedAt: (formValue.closedAt as Moment).format(DATE_FORMAT),
                marketingDateStart: formValue.marketingDateStart ? (formValue.marketingDateStart as Moment).format(DATE_FORMAT) : null,
                pricing: Object.assign(formValue.pricing, {
                    priceType: this.isOneOfTheseMarketingModes(['booking', 'onlineSale']) ? formValue.pricing.priceType : null,
                    allowRequest: this.isOneOfTheseMarketingModes(['request']),
                    allowGiftVoucher: this.isOneOfTheseMarketingModes(['giftVoucher']),
                    allowBooking: this.isOneOfTheseMarketingModes(['booking']),
                    allowOnlineSale: this.isOneOfTheseMarketingModes(['onlineSale']),
                    minAdultPrice: formValue.pricing.minAdultPrice ? parseInt((formValue.pricing.minAdultPrice * 100).toFixed()) : null,
                    maxAdultPrice: formValue.pricing.maxAdultPrice ? parseInt((formValue.pricing.maxAdultPrice * 100).toFixed()) : null,
                    minChildPrice: formValue.pricing.minChildPrice ? parseInt((formValue.pricing.minChildPrice * 100).toFixed()) : null,
                    maxChildPrice: formValue.pricing.maxChildPrice ? parseInt((formValue.pricing.maxChildPrice * 100).toFixed()) : null,
                    minAdultGiftVoucherPrice: formValue.pricing.minAdultGiftVoucherPrice ? parseInt((formValue.pricing.minAdultGiftVoucherPrice * 100).toFixed()) : null,
                    maxAdultGiftVoucherPrice: formValue.pricing.maxAdultGiftVoucherPrice ? parseInt((formValue.pricing.maxAdultGiftVoucherPrice * 100).toFixed()) : null,
                    minChildGiftVoucherPrice: formValue.pricing.minChildGiftVoucherPrice ? parseInt((formValue.pricing.minChildGiftVoucherPrice * 100).toFixed()) : null,
                    maxChildGiftVoucherPrice: formValue.pricing.maxChildGiftVoucherPrice ? parseInt((formValue.pricing.maxChildGiftVoucherPrice * 100).toFixed()) : null
                }),
                configuration: Object.assign(formValue.configuration, {
                    regions: (formValue.configuration.regions as OfferAttribute[]).filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    }),
                    accommodations: (formValue.configuration.accommodations as OfferAttribute[]).filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    }),
                    restorations: (formValue.configuration.restorations as OfferAttribute[]).filter((item: OfferAttribute): boolean => {

                        return item.id !== null;
                    })
                }),
                composition: Object.assign(formValue.composition, {
                    dates: (formValue.composition.dates as { dateStart: Moment, dateEnd: Moment }[]).map((item: { dateStart: Moment, dateEnd: Moment }): Partial<TenderCompositionDate> => {

                        return {
                            dateStart: item.dateStart.format(DATE_FORMAT),
                            dateEnd: item.dateEnd ? item.dateEnd.format(DATE_FORMAT) : null
                        };
                    })
                })
            });

            this._tenderService.createItemAPI(data).subscribe((): void => {

                this.setActiveViews(['confirmation']);
            });
        };
    }

    private _initEvents(): void {

        this.form.valueChanges.subscribe(this._updatePanelsValidation.bind(this));

        this.offerCreatorSearchService.selectedOfferCreators$.subscribe((selectedItems: Society[]): void => {

            this.form.get('offerCreators').patchValue(selectedItems);
        });

        this.compositionControl.get('publics').valueChanges.subscribe((publics: TenderCompositionPublic[]): void => {

            const adultControls: AbstractControl[] = [
                this.compositionControl.get('minNbAdult'),
                this.compositionControl.get('maxNbAdult'),
                this.pricingControl.get('minAdultPrice'),
                this.pricingControl.get('maxAdultPrice'),
                this.pricingControl.get('minAdultGiftVoucherPrice'),
                this.pricingControl.get('maxAdultGiftVoucherPrice')
            ];

            adultControls.forEach((control: AbstractControl): void => {

                if(!publics.includes('adult')){

                    control.patchValue(null);
                }

                control.updateValueAndValidity();

                control.markAsTouched();
            });

           const childControls: AbstractControl[] = [
               this.compositionControl.get('minNbChild'),
               this.compositionControl.get('maxNbChild'),
               this.compositionControl.get('minChildrenAge'),
               this.compositionControl.get('maxChildrenAge'),
               this.pricingControl.get('minChildPrice'),
               this.pricingControl.get('maxChildPrice'),
               this.pricingControl.get('minChildGiftVoucherPrice'),
               this.pricingControl.get('maxChildGiftVoucherPrice')
           ];

            childControls.forEach((control: AbstractControl): void => {

                if(!publics.includes('child')){

                    control.patchValue(null);
                }

               control.updateValueAndValidity();

               control.markAsTouched();
           });
        });

        this.compositionControl.get('minNbAdult').valueChanges.subscribe((): void => {

            this.compositionControl.get('maxNbAdult').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('maxNbAdult').valueChanges.subscribe((): void => {

            this.compositionControl.get('minNbAdult').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('minNbChild').valueChanges.subscribe((): void => {

            this.compositionControl.get('maxNbChild').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('maxNbChild').valueChanges.subscribe((): void => {

            this.compositionControl.get('minNbChild').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('minChildrenAge').valueChanges.subscribe((): void => {

            this.compositionControl.get('maxChildrenAge').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('maxChildrenAge').valueChanges.subscribe((): void => {

            this.compositionControl.get('minChildrenAge').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('minDuration').valueChanges.subscribe((): void => {

            this.compositionControl.get('maxDuration').updateValueAndValidity({ onlySelf: true, emitEvent: false });

            this.configurationControl.get('accommodations').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.compositionControl.get('maxDuration').valueChanges.subscribe((): void => {

            this.compositionControl.get('minDuration').updateValueAndValidity({ onlySelf: true, emitEvent: false });

            this.configurationControl.get('accommodations').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

        this.configurationControl.get('hasInsurance').valueChanges.subscribe((): void => {

            this.configurationControl.get('insuranceComment').patchValue(null);

            this.configurationControl.get('insuranceComment').updateValueAndValidity();

            this.configurationControl.get('insuranceComment').markAsTouched();
        });

        this.pricingControl.get('marketingModes').valueChanges.subscribe((): void => {

            this.pricingControl.get('priceType').patchValue('TTC');

            const publics: TenderCompositionPublic[] = this.compositionControl.get('publics').value;

            const adultPriceControls: AbstractControl[] = [
                this.pricingControl.get('minAdultPrice'),
                this.pricingControl.get('maxAdultPrice')
            ];

            adultPriceControls.forEach((control: AbstractControl): void => {

                if(publics.includes('adult') && !this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                    control.patchValue(null);
                }

                control.updateValueAndValidity();

                control.markAsTouched();
            });

            const childPriceControls: AbstractControl[] = [
                this.pricingControl.get('minChildPrice'),
                this.pricingControl.get('maxChildPrice')
            ];

            childPriceControls.forEach((control: AbstractControl): void => {

                if(publics.includes('child') && !this.isOneOfTheseMarketingModes(['onlineSale', 'booking'])){

                    control.patchValue(null);
                }

                control.updateValueAndValidity();

                control.markAsTouched();
            });

            const adultGiftVoucherPriceControls: AbstractControl[] = [
                this.pricingControl.get('minAdultGiftVoucherPrice'),
                this.pricingControl.get('maxAdultGiftVoucherPrice')
            ];

            adultGiftVoucherPriceControls.forEach((control: AbstractControl): void => {

                if(publics.includes('adult') && !this.isOneOfTheseMarketingModes(['giftVoucher'])){

                    control.patchValue(null);
                }

                control.updateValueAndValidity();

                control.markAsTouched();
            });

            const childGiftVoucherPriceControls: AbstractControl[] = [
                this.pricingControl.get('minChildGiftVoucherPrice'),
                this.pricingControl.get('maxChildGiftVoucherPrice')
            ];

            childGiftVoucherPriceControls.forEach((control: AbstractControl): void => {

                if(publics.includes('child') && !this.isOneOfTheseMarketingModes(['giftVoucher'])){

                    control.patchValue(null);
                }

                control.updateValueAndValidity();

                control.markAsTouched();
            });
        });
    }

    private _initPanels(): void {

        const items: { tag: FormPanelTag, controls: AbstractControl[] }[]  = [
            {
                tag: 'generalData',
                controls: [
                    this.configurationControl.get('customerTypology'),
                    this.compositionControl.get('publics'),
                    this.compositionControl.get('minNbAdult'),
                    this.compositionControl.get('maxNbAdult'),
                    this.compositionControl.get('minNbChild'),
                    this.compositionControl.get('maxNbChild'),
                    this.compositionControl.get('minChildrenAge'),
                    this.compositionControl.get('maxChildrenAge')
                ]
            },
            {
                tag: 'offerContent',
                controls: [
                    this.compositionControl.get('minDuration'),
                    this.compositionControl.get('maxDuration'),
                    this.configurationControl.get('regions'),
                    this.configurationControl.get('types'),
                    this.configurationControl.get('themes'),
                    this.configurationControl.get('accommodations'),
                    this.configurationControl.get('restorations'),
                    this.configurationControl.get('activities'),
                    this.configurationControl.get('locales'),
                    this.configurationControl.get('accommodationsComment'),
                    this.configurationControl.get('restorationsComment'),
                    this.configurationControl.get('contentOfferComment')
                ]
            },
            {
                tag: 'marketingParameter',
                controls: [
                    this.pricingControl.get('marketingModes'),
                    this.pricingControl.get('currency'),
                    this.pricingControl.get('priceType'),
                    this.pricingControl.get('minAdultPrice'),
                    this.pricingControl.get('maxAdultPrice'),
                    this.pricingControl.get('minChildPrice'),
                    this.pricingControl.get('maxChildPrice'),
                    this.pricingControl.get('minAdultGiftVoucherPrice'),
                    this.pricingControl.get('maxAdultGiftVoucherPrice'),
                    this.pricingControl.get('minChildGiftVoucherPrice'),
                    this.pricingControl.get('maxChildGiftVoucherPrice'),
                    this.compositionControl.get('dates')
                ]
            },
            {
                tag: 'insurance',
                controls: [
                    this.configurationControl.get('hasInsurance'),
                    this.configurationControl.get('insuranceComment')
                ]
            },
            {
                tag: 'tenderParameter',
                controls: [
                    this.form.get('ownerReference'),
                    this.form.get('closedAt'),
                    this.form.get('remindBeforeExpire'),
                    this.form.get('marketingDateStart'),
                    this.form.get('comment'),
                    this.documentsControl
                ]
            }
        ];

        this.panels = items.map((item: FormPanel, index: number): FormPanel => {

            return Object.assign(item, {
                valid: false,
                open: index === 0
            } as Partial<FormPanel>);
        });

        this._updatePanelsValidation();
    }

    private _updatePanelsValidation(): void {

        this.panels.forEach((item: FormPanel): void => {

            item.valid = item.controls.every((control: AbstractControl): boolean => {

                return control.valid;
            });
        });
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((items: CustomerTypology[]): void => {

            this.customerTypologies = items;
        });
    }

    private _initDurations(): void {

        const params: string[] = [
            `sort[position]=ASC`
        ];

        this.durations$ = this._offerDurationService.getItemsAPI(params);
    }

    private _initAttributes(): void {

        this.attributeObservables$ = [];

        this.attributeObservables$.push(...(['region', 'accommodations', 'restoration', 'activities', 'types', 'themes'] as OfferAttributeTypeTagType[]).map((tag: OfferAttributeTypeTagType): AttributeObservableData => {

            const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', tag);

            return {
                tag: tag,
                observable: this._offerAttributeService.getItemsAPI([`sort[label]=ASC`, typeFilter.serialize]).pipe(
                    tap((items: OfferAttribute[]): void => {

                        switch (tag){

                            case 'region':

                                this.regions = items;

                                break;
                        }
                    })
                )
            };
        }));
    }

    private _initCurrencies(): void {

        this.currencies$ = this._currencyService.getItemsAPI().pipe(
            tap((currencies: Currency[]): void => {

                const defaultCurrency: Currency = currencies.find((currency: Currency): boolean => {

                    return currency.code === 'EUR';
                });

                this.pricingControl.get('currency').patchValue(defaultCurrency);
            }),
            map((currencies: Currency[]): Currency[] => {

                return currencies.filter((currency: Currency): boolean => {

                    return !['TWD','AED'].includes(currency.code);
                });
            })
        );
    }

    private _initDocumentConfig(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.pdf'}).subscribe((help: string): void => {

            this.documentConfig = {
                id: 'file',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_logo'
                },
                help: help,
                uploadMaxFileSize: null,
                required: true,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                upload: {
                    allowedTypes: ['application/pdf'].join(',')
                }
            }
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/tender/list']);
    }

    public openLeaveWithoutSubmitDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('tender.leaveWithoutSubmit.value'),
                content: this._translateService.instant('tender.leaveWithoutSubmit.description.value')
            } as ConfirmDialogData
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this.redirectToOrigin();
        });
    }

    public redirectToOrigin(): void {

        switch (this.origin.key) {

            case 'offerCreatorList':

                this._router.navigate(['account/offer-creator/list']);

                break;

            case 'catalog':

                this._router.navigate(['account/offer/list/catalog']);

                break;

            case 'tender':

                this._router.navigate(['account/tender/list']);

                break;
        }
    }

    public setActiveViews(activeViews: TemplateView[]): void {

        this.activeViews = activeViews;
    }

    public isActiveView(view: TemplateView): boolean {

        return this.activeViews.includes(view);
    }

    public openAllOfferCreatorsSelection(event: Event): void {

        if(this.form.get('allOfferCreators').value){

            this.form.get('allOfferCreators').patchValue(false);

            this.resetOfferCreatorSelection();

            this.offerCreatorSearchService.allOfferCreatorsSelected = false;

            this.offerCreatorSearchService.selectOfferCreatorEnabled = true;
        }
        else{

            event.preventDefault();

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '600px',
                data: {
                    title: this._translateService.instant('tender.offerCreator.all.selection.title.value'),
                    content: `<p>${this._translateService.instant('tender.offerCreator.all.selection.description.value')}</p>`
                }
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this.form.get('allOfferCreators').patchValue(true);

                this.resetOfferCreatorSelection();

                this.offerCreatorSearchService.allOfferCreatorsSelected = true;

                this.offerCreatorSearchService.selectOfferCreatorEnabled = false;
            });
        }
    }

    public resetOfferCreatorSelection(): void {

        this.offerCreatorSearchService.selectedOfferCreators$.next([]);
    }

    public compareById(a: { id: number }, b: { id: number }): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public handleMarketingMode(value: string, checked: boolean): void {

        const control: UntypedFormControl = this.pricingControl.get('marketingModes') as UntypedFormControl;

        const controlValue: MarketingMode[] = control.value;

        if (checked) {

            controlValue.push(value as MarketingMode);

        } else {

            const index: number = controlValue.findIndex((i: MarketingMode): boolean => {

                return i === (value as MarketingMode);
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);

        control.updateValueAndValidity();

        control.markAsTouched();
    }

    public isSelectedMarketingMode(value: MarketingMode): boolean {

        if(!this.form || !this.pricingControl){

            return false;
        }

        return this.pricingControl.get('marketingModes').value.includes(value);
    }

    public handlePublicSelection(event: any): void {

        const control: UntypedFormControl = this.compositionControl.get('publics') as UntypedFormControl;

        const controlValue: TenderCompositionPublic[] = control.value;

        const value: TenderCompositionPublic = event.target.value;

        if (event.target.checked) {

            controlValue.push(value);

        } else {

            const index: number = controlValue.findIndex((i: TenderCompositionPublic): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);

        control.updateValueAndValidity();

        control.markAsTouched();
    }

    public isSelectedPublic(type: TenderCompositionPublic): boolean {

        if(!this.form || !this.compositionControl){

            return false;
        }

        return this.compositionControl.get('publics').value.includes(type);
    }

    public getPanel(tag: FormPanelTag): FormPanel {

        return this.panels.find((panel: FormPanel): boolean => {

            return panel.tag === tag;
        });
    }

    public isValidPanel(tag: FormPanelTag): boolean {

        return this.getPanel(tag).valid;
    }

    public isOpenPanel(tag: FormPanelTag): boolean {

        return this.getPanel(tag).open;
    }

    public togglePanelOpening(tag: FormPanelTag): void {

        const panel: FormPanel = this.getPanel(tag);

        panel.open = !panel.open;
    }

    public handleRegion(option: MatLegacyOption): void {

        const isAllRegionsOption: boolean = option.value.id === null;

        if(isAllRegionsOption){

            this.configurationControl.get('regions').patchValue(option.selected ? [...[{id: null}],...this.regions] : []);
        }
        else{

            const currentRegions: { id: number }[] = [...(this.configurationControl.get('regions').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            const isAllRegion: boolean = (currentRegions.length === this.regions.length);

            if(isAllRegion){

                currentRegions.push({id: null});
            }

            this.configurationControl.get('regions').patchValue(currentRegions);
        }
    }

    public handleRestoration(option: MatLegacyOption): void {

        const isNoneOption: boolean = option.value.id === null;

        if(isNoneOption){

            this.configurationControl.get('restorations').patchValue([{id: null}]);
        }
        else{

            const restorations: { id: number }[] = [...(this.configurationControl.get('restorations').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            this.configurationControl.get('restorations').patchValue(restorations);
        }
    }

    public handleAccommodation(option: MatLegacyOption): void {

        const isNoneOption: boolean = option.value.id === null;

        if(isNoneOption){

            this.configurationControl.get('accommodations').patchValue([{id: null}]);
        }
        else{

            const accommodations: { id: number }[] = [...(this.configurationControl.get('accommodations').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            this.configurationControl.get('accommodations').patchValue(accommodations);
        }
    }

    public getAttributeObservable(tag: OfferAttributeTypeTagType): Observable<OfferAttribute[]> {

        const match: AttributeObservableData = this.attributeObservables$.find((attributeObservable: AttributeObservableData ): boolean => {

            return attributeObservable.tag === tag;
        });

        return match ? match.observable : of([]);
    }

    public isOneOfTheseMarketingModes(value: MarketingMode[]): boolean {

        if(!this.form || !this.pricingControl){

            return false;
        }

        const marketingModes: MarketingMode[] = this.pricingControl.get('marketingModes').value;

        return marketingModes.some((marketingMode: MarketingMode): boolean => {

            return value.includes(marketingMode);
        });
    }

    public createDate(): void {

        this.datesControl.push(this._formBuilder.group({
            dateStart: [null, Validators.required],
            dateEnd: [null]
        }));
    }

    public deleteDate(index: number): void {

        this.datesControl.removeAt(index);
    }

    public getDateControl(index: number): AbstractControl {

        return this.datesControl.controls[index];
    }

    public createDocument(): void {

        this.documentsControl.push(this._formBuilder.group({
            file: [null, Validators.required]
        }));
    }

    public deleteDocument(index: number): void {

        this.documentsControl.removeAt(index);
    }

    public getDocumentControl(index: number): FormGroup {

        return this.documentsControl.controls[index] as FormGroup;
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public parseAttributes(attributes: OfferAttribute[]): string {

        return attributes.map((attribute: OfferAttribute): string => {

            if(attribute.id === null){

                return this._translateService.instant('withoutPreference.value');
            }

            return this.translationService.getFallbackTranslation(attribute.translations).label;

        }).join(', ');
    }

    public downloadDocument(document: TenderDocument): void {

        this._fileService.openFileInNewTab(document.file.id);
    }

    get form(): FormGroup {

        return this.formService.form;
    }

    get configurationControl(): FormGroup {

        return this.form.get('configuration') as FormGroup;
    }

    get compositionControl(): FormGroup {

        return this.form.get('composition') as FormGroup;
    }

    get pricingControl(): FormGroup {

        return this.form.get('pricing') as FormGroup;
    }

    get documentsControl(): FormArray {

        return this.form.get('documents') as FormArray;
    }

    get datesControl(): FormArray {

        return this.compositionControl.get('dates') as FormArray;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items: Partial<FormTabValidationItem>[] = [];

            // Créateurs d'offres

            items.push({
                tag: 'offerCreators',
                controls: [
                    this.form.get('offerCreators')
                ]
            });

            // Données du formulaire

            items.push({
                tag: 'formData',
                controls: [
                    this.form
                ]
            });

            // Récapitulatif et envoi

            items.push({
                tag: 'summaryData',
                controls: []
            });

            return items.map((item: Partial<FormTabValidationItem>, index: number): FormTabValidationItem => {

                return Object.assign(item, {
                    position: index + 1
                } as FormTabValidationItem);
            });
        };
    }

    get nightIncluded(): boolean {

        if(!this.form || !this.compositionControl){

            return false;
        }

        const minDuration: OfferDuration = this.compositionControl.get('minDuration').value;

        const maxDuration: OfferDuration = this.compositionControl.get('maxDuration').value;

        return minDuration && maxDuration ? (minDuration.value > 1 || maxDuration.value > 1) : false;
    }

    get tender(): Tender {

        return Object.assign({...this.form.getRawValue()}, {}) as Tender;
    }

    get selectedOfferCreators(): Society[] {

        return this.form.get('offerCreators').value as Society[];
    }

    get parsedPublics(): string {

        return this.tender.composition.publics.map((item: TenderCompositionPublic): string => {

            return this._translateService.instant(`${item}.value`);

        }).join(', ');
    }

    get parsedParticipants(): string {

        const items: string[] = [];

        if(this.tender.composition.publics.includes('adult')){

            items.push(this._translateService.instant('adult.count.interval.value', {
                min: this.tender.composition.minNbAdult,
                max: this.tender.composition.maxNbAdult
            }));
        }

        if(this.tender.composition.publics.includes('child')){

            items.push(this._translateService.instant('child.count.interval.value', {
                min: this.tender.composition.minNbChild,
                max: this.tender.composition.maxNbChild
            }));
        }

        return items.join(', ');
    }

    get parsedMarketingModes(): string {

        const marketingModes: MarketingMode[] = this.pricingControl.get('marketingModes').value;

        return marketingModes.map((item: MarketingMode): string => {

            return this._translateService.instant(`marketing.mode.${item}.value`);

        }).join(', ');
    }

    get parsedLocales(): string {

        const locales: string[] = this.configurationControl.get('locales').value;

        return locales.map((item: string): string => {

            return this._translateService.instant(`locale.${item}.value`);

        }).join(', ');
    }

    get parsedPrices(): string {

        if(!this.tender.pricing.currency){

            return null;
        }

        const items: string[] = [];

        if(this.tender.composition.publics.includes('adult')){

            items.push(this._translateService.instant('price.adult.interval.value', {
                min: this.tender.pricing.minAdultPrice,
                max: this.tender.pricing.maxAdultPrice,
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        if(this.tender.composition.publics.includes('child')){

            items.push(this._translateService.instant('price.child.interval.value', {
                min: this.tender.pricing.minChildPrice,
                max: this.tender.pricing.maxChildPrice,
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        return items.join(', ');
    }

    get parsedGifVoucherPrices(): string {

        if(!this.tender.pricing.currency || !this.isOneOfTheseMarketingModes(['giftVoucher'])){

            return null;
        }

        const items: string[] = [];

        if(this.tender.composition.publics.includes('adult')){

            items.push(this._translateService.instant('price.adult.interval.value', {
                min: this.tender.pricing.minAdultGiftVoucherPrice,
                max: this.tender.pricing.maxAdultGiftVoucherPrice,
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        if(this.tender.composition.publics.includes('child')){

            items.push(this._translateService.instant('price.child.interval.value', {
                min: this.tender.pricing.minChildGiftVoucherPrice,
                max: this.tender.pricing.maxChildGiftVoucherPrice,
                symbol: this.tender.pricing.currency.symbol
            }));
        }

        return items.join(', ');
    }

    get allRegionsSelected(): boolean {

        const regions: OfferAttribute[] = this.tender.configuration.regions;

        return regions.some((attribute: OfferAttribute): boolean => {

            return attribute.id === null;
        });
    }

    get loadItemsSourceCallback(): (params?: string[]) => Observable<Pagination<Society>> {

        return (params?: string[]): Observable<Pagination<Society>> => {

            return this._offerCreatorService.getPaginationItemsAPI(params).pipe(
                tap((pagination: Pagination<Society>): void => {

                    this.totalOfferCreators = pagination.totalItems;
                })
            );
        }
    }
}
