<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'networkProvider.list.title.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" class="btn_cta btn_little btn_add">{{ 'networkProvider.list.button.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                <th mat-header-cell *matHeaderCellDef>
                    <button (click)="resetFilters()" mat-icon-button>
                        <mat-icon>loop</mat-icon>
                    </button>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('label')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'networkProvider.list.label.value' | translate" [key]="'label'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('comment')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-text-filter #filter [builder]="filterBuilder" [label]="'networkProvider.list.comment.value' | translate" [key]="'comment'" [operator]="'lk'"></app-core-text-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                </th>
            </ng-container>

            <ng-container [matColumnDef]="getFilterColumnDef('updatedAt')">
                <th mat-header-cell *matHeaderCellDef>
                    <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                </th>
            </ng-container>

            <!--Actions-->

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                <mat-cell *matCellDef="let element; let i = index">
                    <button mat-button (click)="edit(element.id)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button (click)="openDeleteItemDialog(element.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <!-- Liste des éléments -->

            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef>{{'networkProvider.list.fields.label.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).label}}</td>
            </ng-container>

            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>{{'networkProvider.list.fields.comment.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).comment}}</td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>{{'createdAt.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | customDate }}</td>
            </ng-container>

            <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef>{{'updatedAt.value' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{getModel(element).updatedAt | customDate }}</td>
            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Liste des éléments -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>
