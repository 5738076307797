import {Injectable} from '@angular/core';
import {FilterBuilder} from "@core/shared/models/filter";
import {BehaviorSubject, Subject} from "rxjs";
import {OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER, OfferCreatorSearchSessionFilter} from "@core/shared/models/offer/offer-creator-search";
import {JwtToken} from "@core/shared/models/jwt";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Society} from "@core/shared/models/society";
import {OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";

@Injectable()
export class OfferCreatorSearchService {

    private _totalOfferCreators: number = 0;

    public sessionEnabled: boolean = false;

    public filterBuilder: FilterBuilder = new FilterBuilder();

    public filterPrefix: string = null;

    public filtersMetadata$: BehaviorSubject<{ [p in OfferCreatorFilterKey]: any }> = new BehaviorSubject({
        [OfferCreatorFilterKey.Destination]: null,
        [OfferCreatorFilterKey.Target]: null,
        [OfferCreatorFilterKey.NetworkOfferCreator]: null,
        [OfferCreatorFilterKey.OfferType]: null,
        [OfferCreatorFilterKey.Name]: null,
        [OfferCreatorFilterKey.InCatalog]: null,
        [OfferCreatorFilterKey.HasRseLabel]: null,
        [OfferCreatorFilterKey.NotSelected]: null,
        [OfferCreatorFilterKey.HasSubscription]: null,
        [OfferCreatorFilterKey.ResponseType]: null
    });

    public enabledFilters: { [p in OfferCreatorFilterKey]: boolean } = {
        [OfferCreatorFilterKey.Destination]: false,
        [OfferCreatorFilterKey.Target]: false,
        [OfferCreatorFilterKey.NetworkOfferCreator]: false,
        [OfferCreatorFilterKey.OfferType]: false,
        [OfferCreatorFilterKey.Name]: false,
        [OfferCreatorFilterKey.InCatalog]: false,
        [OfferCreatorFilterKey.HasRseLabel]: false,
        [OfferCreatorFilterKey.NotSelected]: false,
        [OfferCreatorFilterKey.HasSubscription]: false,
        [OfferCreatorFilterKey.ResponseType]: false
    };

    public resetFilter$: Subject<void> = new Subject();

    public selectedOfferCreators$: BehaviorSubject<Society[]> = new BehaviorSubject([]);

    public selectOfferCreatorEnabled: boolean = false;

    public selectOfferCreatorAllowed: boolean = false;

    public unselectOfferCreatorAllowed: boolean = false;

    public allOfferCreatorsSelected: boolean = false;

    public isOfferCreatorHighlightedCallback: (offerCreator: Society) => boolean = () => { return false };

    public offerCreatorHighlightColorCallback: (offerCreator: Society) => string = () => { return null };

    public isOfferCreatorSelectionAllowedCallback: (offerCreator: Society) => boolean = () => { return true };

    public isOfferCreatorUnselectionAllowedCallback: (offerCreator: Society) => boolean = () => { return true };

    public offerCreatorSelectionDisableReasonCallback: (offerCreator: Society) => string = () => { return null };

    public offerCreatorUnselectionDisableReasonCallback: (offerCreator: Society) => string = () => { return null };

    public additionalFilterParams$: BehaviorSubject<string[]> = new BehaviorSubject([]);

    constructor(
        private _authenticationService: AuthenticationService
    ) {
        this._handleSession();
    }

    private _handleSession(): void {

        this._authenticationService.jwtToken.subscribe((value: JwtToken): void => {

            if(!value.isAuthenticated){

                sessionStorage.removeItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER);
            }
        });
    }

    public getSessionFilter(key: string): OfferCreatorSearchSessionFilter {

        const filters: OfferCreatorSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return null;
        }

        return filters.find((sessionFilter: OfferCreatorSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public hasSessionFilter(key: string): boolean {

        const filters: OfferCreatorSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return false;
        }

        return !!filters.find((sessionFilter: OfferCreatorSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public selectOfferCreator(offerCreator: Society): void {

        if(!this.selectOfferCreatorAllowed || !this.isOfferCreatorSelectionAllowed(offerCreator)){

            return;
        }

        const items: Society[] = this.selectedOfferCreators$.value;

        items.push(offerCreator);

        this.selectedOfferCreators$.next(items);
    }

    public unselectOfferCreator(offerCreator: Society): void {

        if(!this.unselectOfferCreatorAllowed || !this.isOfferCreatorUnselectionAllowed(offerCreator)){

            return;
        }

        const items: Society[] = this.selectedOfferCreators$.value;

        const index: number = items.findIndex((item: Society): boolean => {

            return offerCreator.id === item.id;
        });

        items.splice(index, 1);

        this.selectedOfferCreators$.next(items);
    }

    public isSelectedOfferCreator(offerCreator: Society): boolean {

        const match: Society = this.selectedOfferCreators$.value.find((item: Society): boolean => {

            return offerCreator.id === item.id;
        });

        return !!match;
    }

    public isOfferCreatorHighlighted(offerCreator: Society): boolean {

        return this.isOfferCreatorHighlightedCallback(offerCreator);
    }

    public offerCreatorHighlightColor(offerCreator: Society): string {

        return this.offerCreatorHighlightColorCallback(offerCreator);
    }

    public isOfferCreatorSelectionAllowed(offerCreator: Society): boolean {

        return this.isOfferCreatorSelectionAllowedCallback(offerCreator);
    }

    public isOfferCreatorUnselectionAllowed(offerCreator: Society): boolean {

        return this.isOfferCreatorUnselectionAllowedCallback(offerCreator);
    }

    public offerCreatorSelectionDisableReason(offerCreator: Society): string {

        return this.offerCreatorSelectionDisableReasonCallback(offerCreator);
    }

    public offerCreatorUnselectionDisableReason(offerCreator: Society): string {

        return this.offerCreatorUnselectionDisableReasonCallback(offerCreator);
    }

    public getFilterMetadata(key: OfferCreatorFilterKey): any {

        const filtersMetadata = this.filtersMetadata$.getValue();

        return filtersMetadata[key];
    }

    public updateFilterMetadata(key: OfferCreatorFilterKey, value: any): void {

        const filtersMetadata = this.filtersMetadata$.getValue();

        filtersMetadata[key] = value;

        this.filtersMetadata$.next(filtersMetadata);
    }

    public isFilterEnabled(key: OfferCreatorFilterKey): boolean {

        return this.enabledFilters[key];
    }

    public resetTotalOfferCreators(): void {

        this._totalOfferCreators = 0;
    }

    public resetFilterFields(): void {

        this.filterBuilder.resetFields();
    }

    get hasSessionFilters(): boolean {

        return !!sessionStorage.getItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER);
    }

    get sessionFilters(): OfferCreatorSearchSessionFilter[] {

        return JSON.parse(sessionStorage.getItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER));
    }

    set sessionFilters(value: OfferCreatorSearchSessionFilter[]) {

        sessionStorage.setItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER, JSON.stringify(value));
    }

    get totalOfferCreators(): number {

        return this._totalOfferCreators;
    }

    set totalOfferCreators(value: number) {

        this._totalOfferCreators = value;
    }
}
