import {StatusType} from "@core/shared/models/status-type";

export const TENDER_STATUS: StatusType[] =  [
    {
        id: 'in-progress',
        label: 'status.in-progress.value'
    },
    {
        id: 'finished',
        label: 'status.finished.value'
    },
    {
        id: 'stopped',
        label: 'status.stopped.value'
    },
    {
        id: 'expired',
        label: 'status.expired.value'
    }
];
