import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";

@Component({
    selector: 'app-core-offer-option-presential-form',
    templateUrl: './offer-option-presential-form.component.html',
    styleUrls: ['./offer-option-presential-form.component.scss']
})
export class OfferOptionPresentialFormComponent implements OnInit {

    @Input() form: UntypedFormGroup;

    @Input() control: UntypedFormGroup;

    @Input() DOMIdentifier: string;

    @Input() enableMaxField: boolean;

    public presentialMax: number = 19;

    constructor() {
    }

    ngOnInit(): void {

    }
}
