import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {of} from "rxjs";
import {catchError, last, map, tap} from 'rxjs/operators';
import {HttpEventType, HttpErrorResponse} from '@angular/common/http';
import {FileUploadConfig, FileUploadModel} from "./file-upload";
import {FileUploadService} from "./file-upload.service";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {File} from "../file/file";

@Component({
    selector: 'media-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        FileUploadService
    ],
    animations: [
        trigger('fadeInOut', [
            state('in', style({opacity: 100})),
            transition('* => void', [
                animate(300, style({opacity: 0}))
            ])
        ])
    ]
})
export class FileUploadComponent implements OnInit {

    @Input() config: FileUploadConfig;

    @Output() postUpload: EventEmitter<File> = new EventEmitter<File>();

    @ViewChild('file', {static: false}) fileRef: ElementRef<HTMLInputElement>;

    files: Array<FileUploadModel> = [];

    constructor(
        private _fileService: FileUploadService,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar
    ) {

    }

    ngOnInit() {

        this.config = Object.assign(this._fileService.getConfig(), this.config);
    }

    initUpload(): void {

        const fileElement: HTMLInputElement = this.fileRef.nativeElement;

        fileElement.onchange = () => {

            for (let index = 0; index < fileElement.files.length; index++) {

                const file = fileElement.files[index];

                this.files.push({
                    data: file,
                    state: 'in',
                    inProgress: false,
                    progress: 0,
                    canRetry: false,
                    canCancel: true
                });
            }

            this.uploadFiles();
        };

        fileElement.click();
    }

    cancelFile(file: FileUploadModel) {

        if(file.sub){

            file.sub.unsubscribe();
        }

        this.removeFileFromArray(file);
    }

    retryFile(file: FileUploadModel) {

        file.canRetry = false;

        this.uploadFile(file);
    }

    private uploadFile(file: FileUploadModel) {

        const fd = new FormData();

        fd.append('file', file.data);

        file.inProgress = true;

        if(file.data.size > this.config.maxSize){

            file.inProgress = false;

            file.canRetry = true;

            this._snackbar.open(`Le fichier ne peut excéder ${this.config.maxSize / 1000}Ko.`, null, {
                duration: 5000
            });

            return;
        }

        file.sub = this._fileService.uploadFiles(this.config.uploadApi, fd).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        file.progress = Math.round(event.loaded * 100 / event.total);
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            tap(message => {
            }),
            last(),
            catchError((error: HttpErrorResponse) => {

                file.inProgress = false;
                file.canRetry = true;

                return of(`${file.data.name} upload failed.`);
            })
        ).subscribe(
            (event: any) => {
                if (typeof (event) === 'object') {

                    this.removeFileFromArray(file); // On supprime le fichier de la liste des fichiers à uploader

                    this.postUpload.emit(event.body);
                }
            },
            (error: any) => {

                file.inProgress = false;
                file.canRetry = true;
            }
        );
    }

    private uploadFiles() {

        const fileElement: HTMLInputElement = this.fileRef.nativeElement;

        fileElement.value = '';

        this.files.forEach((file: FileUploadModel) => {

            this.uploadFile(file);
        });
    }

    private removeFileFromArray(file: FileUploadModel) {

        const index = this.files.indexOf(file);

        if (index > -1) {
            this.files.splice(index, 1);
        }
    }

    public getDisplayedSize(bytes: number): string {

        const i: number = Math.floor(Math.log(bytes) / Math.log(1000));

        const sizes: string[] = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

        return (bytes / Math.pow(1000, i)).toFixed(0) + ' ' + sizes[i];
    }
}
