import {Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {featureGroup, LatLngBounds, Layer, Map, MapOptions, tileLayer} from "leaflet";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {Offer} from "@core/shared/models/offer";
import {FilterBuilder, FilterComponent, FilterField} from "@core/shared/models/filter";
import {LeafletDirective} from "@asymmetrik/ngx-leaflet";
import {Role} from "@core/shared/models/role";
import * as moment from "moment";
import {Moment} from "moment";
import {OfferCatalogStatusType} from "@core/shared/models/offer/offer-catalog";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {AbstractControl, UntypedFormControl} from "@angular/forms";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {OfferSearchResponseType, OfferSearchSessionFilter} from "@core/shared/models/offer/offer-search";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferLocationService} from "@core/shared/services/offer/offer-location/offer-location-service";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";
import {MarketplacePreferenceService} from "@core/shared/services/marketplace-preference.service";
import {tap} from 'rxjs/operators';
import {Observable} from "rxjs";
import {ViewType} from "@core/components/offer/offer-search/offer-search.component";

@Component({
    selector: 'app-core-offer-map',
    templateUrl: './offer-map.component.html',
    styleUrls: ['./offer-map.component.scss']
})
export class OfferMapComponent implements OnInit {

    @Input() filterBuilder: FilterBuilder;

    @Input() mode: ModeType;

    @Input() roles: Role[];

    @Input() publicPrice: number;

    @Input() layoutView: 'list' | 'map';

    @Input() identificationNumber : string;

    @Input() locale: UntypedFormControl;

    @Input() referenced: UntypedFormControl;

    @Input() allowBooking: AbstractControl;

    @Input() allowGiftVoucher: AbstractControl;

    @Input() allowRequest: AbstractControl;

    @Input() allowOnlineSale: AbstractControl;

    @Input() loadItemsSourceCallback: (view: ViewType, params?: string[]) => Observable<OfferLocation[]>;

    @ViewChild(LeafletDirective, { static: true }) directive: LeafletDirective;

    public mapOptions: MapOptions;

    public markers: Layer[] = [];

    public user: User;

    public locations: OfferLocation[] = [];

    public displayedOffer: Offer = null;

    public displayLocation: OfferLocation = null;

    constructor(
        private _userService: UserService,
        private _authenticationService: AuthenticationService,
        private _offerSearchService: OfferSearchService,
        private _offerLocationService: OfferLocationService,
        private _ngZone: NgZone,
        private _marketplacePreferenceService: MarketplacePreferenceService
    ) {
    }

    ngOnInit(): void {

        this._initMap();

        this._userService.getItemAPI(this._authenticationService.id).subscribe((user: User): void => {

            this.user = user;

            if (this._offerSearchService.hasSessionFilters(this.mode)) {

                this._initSessionFilters();
            }
            else {

                this._initDefaultSessionFilters();
            }

            this.loadItems();

            this._initEvents();
        });

        if(this.locale) {

            this.locale.valueChanges.subscribe((): void => {

                this.loadItems();
            });
        }

        if (this.referenced) {

            this.referenced.valueChanges.subscribe((): void => {

                this.loadItems();
            });
        }

        if (this.allowBooking) {

            this.allowBooking.valueChanges.subscribe((): void => {

                this.loadItems();
            });
        }

        if (this.allowGiftVoucher) {

            this.allowGiftVoucher.valueChanges.subscribe((): void => {

                this.loadItems();
            });
        }

        if (this.allowRequest) {

            this.allowRequest.valueChanges.subscribe((): void => {

                this.loadItems();
            });
        }

        if (this.allowOnlineSale) {

            this.allowOnlineSale.valueChanges.subscribe((): void => {

                this.loadItems();
            });
        }

        this._offerSearchService.updateFilter$.subscribe((data: { key: string, value: any }): void => {

            const conditions: boolean[] = [
                data.key === 'isDistributor',
                this.hasOneOfThisMode(['offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog']) && data.key === 'selectedOffers'
            ];

            const itemsToLoad: boolean = conditions.some((condition: boolean): boolean => {

                return Boolean(condition);
            });

            if(itemsToLoad){

                this.loadItems();
            }
        });
    }

    private _initEvents(): void {

        // Filtre du moteur de recherche

        this.filterBuilder.filterCallback = (): void => {

            this.loadItems();
        };

        // Chargement manuel des données

        this._offerSearchService.loadItems$.subscribe((): void => {

            this.loadItems();
        });
    }

    private _initSessionFilters(): void {

        this.filterBuilder.resetFields();

        // Publication

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}published`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}published`).value = this._offerSearchService.getSessionFilter(this.mode,`published`).value;
        }

        // Nouveautés

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value = this._offerSearchService.getSessionFilter(this.mode,`createdAt`).value;

        // Offres ajoutées à son catalogue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}offerAdded`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`).value = this._offerSearchService.getSessionFilter(this.mode, `offerAdded`).value;

        }

        // Vente en ligne

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value = this._offerSearchService.getSessionFilter(this.mode, `onlineSale.enable`).value;

        // Bon cadeaux

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value = this._offerSearchService.getSessionFilter(this.mode, `giftVoucher.enable`).value;

        // Non présent dans le catalogue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}notInCatalog`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `notInCatalog`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value= this._offerSearchService.getSessionFilter(this.mode, `notInCatalog`).value;
            }
        }

        // Offre ciblée

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isRestricted`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `isRestricted`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value = this._offerSearchService.getSessionFilter(this.mode, `isRestricted`).value;
            }
        }

        // Promotions

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}promotions`).value = this._offerSearchService.getSessionFilter(this.mode,`promotions`).value;

        // Nom

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}translations.name`).value = this._offerSearchService.getSessionFilter(this.mode,`translations.name`).value;

        // Référence

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}reference`).value = this._offerSearchService.getSessionFilter(this.mode,`reference`).value;

        // Langues

        (this._offerSearchService.getSessionFilter(this.mode,`locales`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}locales`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}locales`, 'lkin', value));
        });

        // Prestataires

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}providerSocieties.id`)){

            if(this._offerSearchService.getSessionFilter(this.mode,`providerSocieties.id`)){

                (this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.id`).value as string[]).forEach((value: string): void => {

                    (this.filterBuilder.getFieldByKey(`${this.prefixFilter}providerSocieties.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}providerSocieties.id`, 'in', value));
                });
            }
        }

        // Créateur d'offre

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}society.id`)){

            if(this._offerSearchService.getSessionFilter(this.mode,`society.id`)){

                (this._offerSearchService.getSessionFilter(this.mode,`society.id`).value as string[]).forEach((value: string): void => {

                    (this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}society.id`, 'in', value));
                });
            }
        }

        // Dates

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}date`).value = this._offerSearchService.getSessionFilter(this.mode,`date`).value;

        // Maximum adultes

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value = this._offerSearchService.getSessionFilter(this.mode,`adult`).value;

        // Maximum enfants

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value = this._offerSearchService.getSessionFilter(this.mode,`child`).value;

        // Ville

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}locations.city`).value = this._offerSearchService.getSessionFilter(this.mode,`locations.city`).value;

        // Durée

        (this._offerSearchService.getSessionFilter(this.mode,`duration.id`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}duration.id`, 'in', value));
        });

        // Typologie de clientèle

        (this._offerSearchService.getSessionFilter(this.mode,`customerTypology.id`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}customerTypology.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}customerTypology.id`, 'in', value));
        });

        //Destinations

        // (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.destinations`).value as string[]).forEach((value: string): void => {
        //
        //     (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.destinations`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.destinations`, 'in', value));
        // });

        //Hébergements

        (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.accommodations`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.accommodations`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.accommodations`, 'in', value));
        });

        //Restaurations

        (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.restoration`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.restoration`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.restoration`, 'in', value));
        });

        //Thématiques

        (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.themes`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.themes`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.themes`, 'in', value));
        });

        //Activités

        (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.activities`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.activities`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.activities`, 'in', value));
        });

        //Régions

        (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.region`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.region`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.region`, 'in', value));
        });

        //Types de séjour

        (this._offerSearchService.getSessionFilter(this.mode,`attributes.attribute.types`).value as string[]).forEach((value: string): void => {

            (this.filterBuilder.getFieldByKey(`${this.prefixFilter}attributes.attribute.types`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}attributes.attribute.types`, 'in', value));
        });

        // Groupe de réseaux de prestataires

        if(this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.networkProviders.groupNetworkProvider.id`)) {

            (this._offerSearchService.getSessionFilter(this.mode, `providerSocieties.networkProviders.groupNetworkProvider.id`).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(`${this.prefixFilter}providerSocieties.networkProviders.groupNetworkProvider.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}providerSocieties.networkProviders.groupNetworkProvider.id`, 'in', value));
            });
        }

        // Prestataires restreints

        if(this._offerSearchService.getSessionFilter(this.mode, `restrictedProviderSocieties.id`)){

            (this._offerSearchService.getSessionFilter(this.mode, `restrictedProviderSocieties.id`).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(`${this.prefixFilter}restrictedProviderSocieties.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}restrictedProviderSocieties.id`, 'in', value));
            });
        }

        // Réseaux de créateurs d'offres

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}society.networkOfferCreators.id`)){

            if(this._offerSearchService.getSessionFilter(this.mode,`society.networkOfferCreators.id`)){

                (this._offerSearchService.getSessionFilter(this.mode,`society.networkOfferCreators.id`).value as string[]).forEach((value: string): void => {

                    (this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.networkOfferCreators.id`) as FilterCollectionField).fields.push(new ArrayFilterField(`${this.prefixFilter}society.networkOfferCreators.id`, 'in', value));
                });
            }
        }

        // Vigilance

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}isOnAlert`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}isOnAlert`).value = this._offerSearchService.getSessionFilter(this.mode, `isOnAlert`).value;
        }

        // Date de mise à jour

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}updatedAt`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `updatedAt`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}updatedAt`).value = this._offerSearchService.getSessionFilter(this.mode, `updatedAt`).value;
            }
        }

        // Option assurance

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOption`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `permanentOption`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value = this._offerSearchService.getSessionFilter(this.mode, `permanentOption`).value;
            }
        }

        // Options assurances

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.id`)){

            if (this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.id`)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.id`).value;
            }
        }

        // Type de réponse

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}responseType`)){

            this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value = this._offerSearchService.getSessionFilter(this.mode, `responseType`).value;
        }
    }

    private _initDefaultSessionFilters(): void {

        // Réseaux de créateur d'offres

        if(this.hasOneOfThisMode(['reservoir', 'hashtag-reservoir'])){

            const networkOfferCreatorsMarketplacePreference: MarketplacePreference = this._marketplacePreferenceService.getItemByFilterFromCollection('society.networkOfferCreators.id', this.user.marketplacePreferences);

            if(networkOfferCreatorsMarketplacePreference){

                const networkOfferCreatorsIds: number[] = (networkOfferCreatorsMarketplacePreference.value as string).replace(/[\[\]]/g, '').split(',').map((item: string): number => { return parseInt(item); });

                const filterCollectionField: FilterCollectionField = this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.networkOfferCreators.id`) as FilterCollectionField;

                networkOfferCreatorsIds.forEach((id: number): void => {

                    filterCollectionField.fields.push(new ArrayFilterField(networkOfferCreatorsMarketplacePreference.filter, networkOfferCreatorsMarketplacePreference.operator, id as unknown as string));
                });
            }
        }

        this._storeSessionFilters();
    }

    private _storeSessionFilters(): void {

        const sessionFilters: OfferSearchSessionFilter[] = [
            {
                key: `promotions`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}promotions`).value || null
            },
            {
                key: `offerAdded`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`) ? this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`).value : null
            },
            {
                key: `createdAt`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value || null
            },
            {
                key: `onlineSale.enable`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value || null
            },
            {
                key: `giftVoucher.enable`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value || null
            },
            {
                key: `translations.name`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}translations.name`).value || null
            },
            {
                key: `reference`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}reference`).value || null
            },
            {
                key: `locales`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}locales`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            },
            {
                key: `date`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}date`).value || null
            },
            {
                key: `adult`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value || null
            },
            {
                key: `child`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value || null
            },
            {
                key: `locations.city`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}locations.city`).value || null
            },
            {
                key: `duration.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            },
            {
                key: `customerTypology.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}customerTypology.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            },
            {
                key: `attributes.attribute.destinations`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.destinations`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `attributes.attribute.accommodations`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.accommodations`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `attributes.attribute.restoration`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.restoration`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `attributes.attribute.themes`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.themes`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `attributes.attribute.activities`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.activities`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `attributes.attribute.region`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.region`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `attributes.attribute.types`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}attributes.attribute.types`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    })
                }))
            },
            {
                key: `providerSocieties.networkProviders.groupNetworkProvider.id`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}providerSocieties.networkProviders.groupNetworkProvider.id`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `society.networkOfferCreators.id`,
                value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(`${this.prefixFilter}society.networkOfferCreators.id`) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                    return collectionField.fields.map((field: FilterField): string => {

                        return field.value;
                    });
                }))
            },
            {
                key: `duration.value`,
                value: 1,
                extraData: {
                    operator: this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.value`).operator
                }
            },
        ];

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}published`)){

            sessionFilters.push({
                key: `published`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}published`).value || null
            });
        }

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}society.id`)){

            sessionFilters.push({
                key: `society.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}society.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}providerSocieties.id`)){

            sessionFilters.push({
                key: `providerSocieties.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}providerSocieties.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            });
        }

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}isOnAlert`)){

            sessionFilters.push({
                key: `isOnAlert`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}isOnAlert`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}updatedAt`)) {

            sessionFilters.push({
                key: `updatedAt`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}updatedAt`).value || null
            });
        }

        if(this.filterBuilder.hasFilter(`${this.prefixFilter}restrictedProviderSocieties.id`)){

            sessionFilters.push({
                key: `restrictedProviderSocieties.id`,
                value: (this.filterBuilder.getFieldByKey(`${this.prefixFilter}restrictedProviderSocieties.id`) as FilterCollectionField).fields.map((field: FilterField): string => {

                    return field.value;
                })
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}notInCatalog`)){

            sessionFilters.push({
                key: `notInCatalog`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value || null
            });
        }

        // Offre ciblée

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isRestricted`)){

            sessionFilters.push({
                key: `isRestricted`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value || null
            });
        }

        // Option assurance

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOption`)){

            sessionFilters.push({
                key: `permanentOption`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value || null
            });
        }

        // Options assurances

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.id`)){

            sessionFilters.push({
                key: `permanentOptions.id`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value || null
            });
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}responseType`)){

            sessionFilters.push({
                key: `responseType`,
                value: this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value || null
            });
        }

        this._offerSearchService.setSessionFilters(this.mode, sessionFilters);

        this._offerSearchService.refreshFiltersState$.next();
    }

    private _initMap(): void {

        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18
                })
            ],
            zoom: 5,
            center: {
                lat: 46.85,
                lng: 2.3518
            }
        };
    }

    private loadItems(): void {

        this._storeSessionFilters();

        this.resetDisplayedOffer();

        if(this.hasOneOfThisMode(['personnal-offers', 'offer-permanent-option-personnal-offers'])){

            this._offerLocationService.getItemsSocietyAPI(this.user.society.id, this.itemsApiParams).pipe(tap((items: OfferLocation[]): void => {

                this._offerSearchService.totalFilteredOffers = items.length;

            })).subscribe((items: OfferLocation[]) => {

                this.locations = items;

                this._loadLocationsMarkers();
            });
        }

        if(this.isMode('reservoir')){

            this._offerLocationService.getItemsAPI(this.itemsApiParams).subscribe((items: OfferLocation[]) => {

                this.locations = items;

                this._loadLocationsMarkers();
            });
        }

        if(this.hasOneOfThisMode(['catalog', 'channel', 'offer-permanent-option-catalog'])){

            this._offerLocationService.getItemsCatalogAPI(this.itemsApiParams).pipe(tap((items: OfferLocation[]): void => {

                this._offerSearchService.totalFilteredOffers = items.length;

            })).subscribe((items: OfferLocation[]) => {

                this.locations = items;

                this._loadLocationsMarkers();

            });
        }

        if(this.hasOneOfThisMode(['tender-personnal-offers', 'tender-proposed-offers', 'hashtag-reservoir', 'hashtag-catalog'])){

            this.loadItemsSourceCallback('map', this.itemsApiParams).pipe(tap((items: OfferLocation[]): void => {

                this._offerSearchService.totalFilteredOffers = items.length;

            })).subscribe((items: OfferLocation[]): void => {

                this.locations = items;

                this._loadLocationsMarkers();
            });
        }
    }

    private _loadLocationsMarkers() {

        this.markers = [];

        this.locations.forEach((item: OfferLocation): void => {

            const marker = item.marker;

            marker.on('click', (): void => {

                this.map.panTo(item.latLng);

                this._ngZone.run((): void => {

                    this.displayedOffer = item.offer;

                    this.displayLocation = item;
                });
            });

            this.markers.push(marker);
        });

        if(this.map && this.fitBounds && this.fitBounds.isValid()){

            this.map.fitBounds(this.fitBounds);
        }
    }

    public resetDisplayedOffer(): void {

        this._ngZone.run((): void => {

            this.displayedOffer = null;
        });
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public resetFilters(filterComponents: FilterComponent[], loadItems: boolean): void {

        this.filterBuilder.resetFields();

        filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        if(loadItems){

            this.loadItems();
        }
    }

    public isMode(mode: ModeType): boolean {

        return this.mode === mode;
    }

    public hasOneOfThisMode(modes: ModeType[]): boolean{

        return modes.some((mode: ModeType) => {

            return this.isMode(mode);
        });

    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    get itemsApiParams(): string[] {

        const extrasData = this._offerSearchService.extrasData$.getValue();

        const params: string[] = [];

        // Adaptation du filtre sur la date de création (utilisé pour définir les offres "Nouveautés")

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}createdAt`)){

            const now: Moment = moment();

            now.subtract(30, 'days');

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value = now.format('YYYY-MM-DD HH:mm:ss');

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).operator = 'gte';

            } else {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}createdAt`).value = null;
            }
        }

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}offerAdded`) && this.filterBuilder.getFieldByKey(`${this.prefixFilter}offerAdded`).value) {

            const status: OfferCatalogStatusType = 'accepted';

            const statusFilter: TextFilterField = new TextFilterField('catalogs.status', 'eq', status);

            params.push(statusFilter.serialize);

            const catalogFilter: TextFilterField = new TextFilterField('catalogs.society.id', 'eq', this.user.society.id);

            params.push(catalogFilter.serialize);

        }

        // Pas dans le catalogue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}notInCatalog`)) {

            if(this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value) {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value = this.user.society.id;
            }
            else {
                this.filterBuilder.getFieldByKey(`${this.prefixFilter}notInCatalog`).value  = null;
            }
        }

        // Type de réponse

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}responseType`) && this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value) {

            const value: OfferSearchResponseType = this.filterBuilder.getFieldByKey(`${this.prefixFilter}responseType`).value;

            if(value === 'addedInCatalog'){

                const status: OfferCatalogStatusType = 'accepted';

                const statusFilter: TextFilterField = new TextFilterField('catalogs.status', 'eq', status);

                params.push(statusFilter.serialize);

                const addedInCatalogFilter: TextFilterField = new TextFilterField('catalogs.society.id', 'eq', this.user.society.id);

                params.push(addedInCatalogFilter.serialize);
            }

            if(value === 'waiting'){

                const waitingFilter: TextFilterField = new TextFilterField(`${this.prefixFilter}notInCatalog`, 'eq', this.user.society.id);

                params.push(waitingFilter.serialize);
            }
        }

        // Vente en ligne

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}onlineSale.enable`)){

            if (!this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}onlineSale.enable`).value  = null;
            }
            else{

                switch (this.mode){

                    case 'reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'offer-permanent-option-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'channel':

                        const extrasData = this._offerSearchService.extrasData$.getValue();

                        if(('isDistributor' in extrasData) && !extrasData['isDistributor']){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'hashtag-reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'hashtag-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
                        }

                        break;
                }
            }
        }

        // Bon cadeaux

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}giftVoucher.enable`)){

            if (!this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}giftVoucher.enable`).value  = null;
            }
            else{

                switch (this.mode){

                    case 'reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'offer-permanent-option-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'channel':

                        params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);

                        break;

                    case 'hashtag-reservoir':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;

                    case 'hashtag-catalog':

                        if(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

                            params.push(`offer.giftVoucher.isAvailableOnlineSale[eq]=1`);
                        }

                        break;
                }
            }
        }

        // Offre ciblée

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}isRestricted`)) {

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value) {

                if(this.isMode('channel')){

                    params.push(`offer.society.id[eq]=${this.user.society.id}`);
                }
            }
            else{

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}isRestricted`).value = null;
            }
        }

        // Option assurance

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOption`)) {

            const selectedOffers = this._offerSearchService.selectedOffers.getValue();

            if([null, undefined].includes(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value)) {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value = null;
            }
            else if(Boolean(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOption`).value)){

                if(selectedOffers.length){

                    params.push(...selectedOffers.map((selectedOffer: Offer): string => {

                        return `${this.prefixFilter}id[in][]=${selectedOffer.id}`;
                    }));
                }
                else{

                    params.push(`${this.prefixFilter}id[in][]=null`);
                }
            }
        }

        // Options assurances

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.id`)) {

            if([null, undefined].includes(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = null;
            }
            else if (Boolean(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value)){

                if(this.isMode('personnal-offers')){

                    this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = 1;

                    this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).operator = 'nnull';
                }

                if(this.hasOneOfThisMode(['catalog', 'hashtag-catalog'])){

                    if(this.hasRole('ROLE_OFFER_DISTRIBUTOR')) {

                        params.push(`catalogs.permanentOptions.id[nnull]=${this.user.society.id}`);

                    } else {

                        params.push(`permanentOptions.id[nnull]=1`);
                    }

                }

            } else {

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.id`).value = null;
            }
        }

        // Options assurances en fonction de la langue

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`)) {

            if([null, undefined].includes(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value)){

                this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value = null;
            }
            else if (Boolean(this.filterBuilder.getFieldByKey(`${this.prefixFilter}permanentOptions.offerPermanentOption.locales`).value)){

                // Filtre sur les options assurances

                const isDistributor: boolean = ('isDistributor' in extrasData) ? extrasData['isDistributor'] : false;

                if(isDistributor){

                    params.push(`permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`)
                }
                else{

                    params.push(`offer.permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`)
                }
            }
        }

        // Nombre d'adulte

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}adult`)) {

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value) {

                params.push(`${this.prefixFilter}adultMax[gte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value}`);

                params.push(`${this.prefixFilter}adultMin[lte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}adult`).value}`);
            }
        }

        // Nombre d'enfant

        if (this.filterBuilder.hasFilter(`${this.prefixFilter}child`)) {

            if (this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value) {

                params.push(`${this.prefixFilter}childMax[gte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value}`);

                params.push(`${this.prefixFilter}childMin[lte]=${this.filterBuilder.getFieldByKey(`${this.prefixFilter}child`).value}`);
            }
        }

        // Offres sans nuitée / avec nuitée

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.value`).value = 1;

        // Prise en compte des filtres effectués par l'internaute

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        // Filtre automatique sur le statut des offres

        if(this.hasOneOfThisMode(['channel', 'catalog', 'offer-permanent-option-catalog', 'hashtag-catalog'])){

            const status: OfferCatalogStatusType = 'accepted';

            const statusFilter: TextFilterField = new TextFilterField('catalogs.status', 'eq', status);

            params.push(statusFilter.serialize);
        }

        // Filtre automatique pour ne pas avoir les offres qui appartiennent à la société dans le catalogue

        if(this.hasOneOfThisMode(['offer-permanent-option-catalog'])){

            params.push(`offer.society.id[ne]=${this.user.society.id}`);
        }

        // Filtre automatique sur la langue des offres

        if(this.locale && this.locale.value){

            const localeFilter: TextFilterField = new TextFilterField(`${this.prefixFilter}locales`, 'lk', this.locale.value);

            params.push(localeFilter.serialize);
        }

        // Filtre automatique sur la société des offres

        if(this.referenced && this.referenced.value){

            const societyFilter: TextFilterField = new TextFilterField(`${this.prefixFilter}society.id`, 'eq', this.user.society.id.toString());

            params.push(societyFilter.serialize);
        }

        if(this.allowBooking && this.allowGiftVoucher && this.allowRequest && this.allowOnlineSale) {

            if(this.allowGiftVoucher.value && !this.allowBooking.value && !this.allowRequest.value && !this.allowOnlineSale.value && this._offerSearchService.displayOnlyGiftVoucherOffer){

                params.push(`${this.prefixFilter}giftVoucher.enable[eq]=1`);

                params.push(`${this.prefixFilter}giftVoucher.isAvailableOnlineSale[eq]=1`);
            }
        }

        if(this._offerSearchService.displayOnlyOnlineSaleOffer){

            const isDistributor: boolean = ('isDistributor' in extrasData) ? extrasData['isDistributor'] : false;

            if(isDistributor){

                params.push(`permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`);

                params.push(`offer.onlineSale.enable[eq]=1`);
            }
            else{

                params.push(`offer.permanentOptions.offerPermanentOption.locales[andlkin][]=${this.getLocale}`);

                params.push(`offer.onlineSale.isAvailableOnlineSale[eq]=1`);
            }
        }

        let splitParams: string[] = [];

        params.forEach((param: string): void => {

            splitParams.push(...param.split('&'));
        });

        // Nettoyage des données obsolètes

        splitParams = splitParams.filter((param: string): boolean => {

            const toDeleteConditions: boolean[] = [
                this.hasOneOfThisMode(['offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog']) && param.includes('permanentOption'),
                this.hasOneOfThisMode(['catalog', 'hashtag-catalog']) && param.includes('offer.permanentOptions.id'),
                this.hasOneOfThisMode(['catalog', 'hashtag-catalog']) && this.hasRole('ROLE_OFFER_DISTRIBUTOR') && param.includes(`${this.prefixFilter}permanentOptions.id`)
            ];

            const toDeleteParam: boolean = toDeleteConditions.some((condition: boolean): boolean => {

                return Boolean(condition);
            });

            return !toDeleteParam;
        });

        // Retour des données

        return splitParams.map((param: string): string => {

            let applyOfferPrefix: boolean = true;

            switch(true){

                case (param.includes(`${this.prefixFilter}locations.city`)):

                    param = param.replace(`${this.prefixFilter}locations.`, '');

                    applyOfferPrefix = false;

                    break;

                case (param.includes(`restrictedProviderSocieties.id`)):

                    param = param.replace(/restrictedProviderSocieties.id/g, 'providerSocieties.id')

                    break;
            }

            if(applyOfferPrefix && !param.startsWith('offer.')){

                param = `offer.${param}`;
            }

            return param;
        });
    }

    get map(): Map {

        return this.directive.getMap();
    }

    get fitBounds(): LatLngBounds {

        const bounds: LatLngBounds = featureGroup(this.markers).getBounds();

        return bounds.isValid() ? bounds : null;
    }

    get prefixFilter(): string {

        return this.hasOneOfThisMode(['channel', 'catalog', 'offer-permanent-option-catalog', 'hashtag-catalog']) ? 'offer.' : '';
    }

    get getLocale(): string {

        return this.locale?.value ? this.locale.value : this.user.locale;
    }
}
