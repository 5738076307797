import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Pagination} from "../models/pagination";
import {OfferAttributeType} from "../models/offer-attribute-type";

@Injectable({
    providedIn: 'root'
})
export class OfferAttributeTypeService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(): Observable<OfferAttributeType[]> {

        return this.getPaginationItemsAPI().pipe(
            map((response: Pagination<OfferAttributeType>): OfferAttributeType[] => {

                return response.items;
            })
        );
    }

    public getPaginationItemsAPI(): Observable<Pagination<OfferAttributeType>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Pagination<OfferAttributeType>
            >(`${this._apiService.getApiUrl(false, true)}/offerAttributes/types`, {
            headers: headers
        });
    }
}
