<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'channel.restricted.title.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" class="btn_cta btn_little btn_add">{{ 'channel.restricted.actions.add.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom du proprietaire -->

                <ng-container [matColumnDef]="getFilterColumnDef('society.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.society.name.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Date de creation -->
                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Site web -->

                <ng-container [matColumnDef]="getFilterColumnDef('marketplace')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.marketplace.value' | translate" [key]="'marketplace.website'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Activé -->

                <ng-container [matColumnDef]="getFilterColumnDef('enable')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.enable.value' | translate" [key]="'enable'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="isEnableItems"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Quota individuel -->

                <ng-container [matColumnDef]="getFilterColumnDef('individualQuota')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.individualQuota.value' | translate" [key]="'individualQuota'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Quota groupe -->

                <ng-container [matColumnDef]="getFilterColumnDef('groupQuota')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.groupQuota.value' | translate" [key]="'groupQuota'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.translations.name.value' | translate" [key]="'translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Titre -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.title')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.translations.title.value' | translate" [key]="'translations.title'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Integration type -->
                <ng-container [matColumnDef]="getFilterColumnDef('integrationType')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'channel.restricted.list.fields.integrationType.value' | translate" [key]="'typeIntegration'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="types"></app-core-select-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="editItem(element.id)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom proprietaire -->

                <ng-container matColumnDef="society.name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.society.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).society.name}}</td>
                </ng-container>

                <!-- Date de creation -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt}}</td>
                </ng-container>

                <!-- Site web -->

                <ng-container matColumnDef="marketplace">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.marketplace.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).marketplace ? getModel(element).marketplace.website : null }}</td>
                </ng-container>

                <!-- Activé -->

                <ng-container matColumnDef="enable">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.enable.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getIsEnable(getModel(element))}}</td>
                </ng-container>

                <!-- Quota individuel -->

                <ng-container matColumnDef="individualQuota">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.individualQuota.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).individualQuota}}</td>
                </ng-container>

                <!-- Quota groupe -->

                <ng-container matColumnDef="groupQuota">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'channel.restricted.list.fields.groupQuota.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).groupQuota}}</td>
                </ng-container>

                <!-- Nom -->

                <ng-container matColumnDef="translations.name">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).translations).name}}</td>
                </ng-container>

                <!-- Titre -->

                <ng-container matColumnDef="translations.title">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.title.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).translations).title}}</td>
                </ng-container>

                <!-- Integration type -->

                <ng-container matColumnDef="integrationType">
                    <th mat-header-cell *matHeaderCellDef>{{'channel.restricted.list.fields.integrationType.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getIntegrationType(getModel(element))}}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>

