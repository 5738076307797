<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'society.provider.own.list.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="openAddItemDialog()" *ngIf="isCreationAllowed" class="btn_cta btn_little btn_add">{{ 'society.provider.add.action.value' | translate }}</button>
                <button (click)="openSendRequestItemDialog()" *ngIf="isSendRequestAllowed" class="btn_cta btn_little btn_add">{{ 'society.provider.request.action.value' | translate }}</button>
            </div>
        </div>
        <div class="row marges">
            <div class="col_12">
                <div class="ck-content subscription_information">
                    <p [innerHTML]="'society.provider.own.list.description.value' | translate"></p>
                </div>
            </div>
        </div>

    </div>

    <div class="content_table">

        <p>{{ 'society.provider.own.list.count.value' | translate }} : {{ society.countEnableProviders }}<ng-container *ngIf="!userHasAccess('UNLIMITED_PROVIDER') && society.countAllowedProviders !== null"> / {{ society.countAllowedProviders }}</ng-container></p>

        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Activé -->

                <ng-container [matColumnDef]="getFilterColumnDef('enable')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'provider.enable.value' | translate" [key]="'enable'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="enableStatus"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Référence Prestataire -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.reference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'provider.reference.value' | translate" [key]="'provider.reference'" [operator]="'eq'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom de la société du prestataire prestataire -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'provider.name.value' | translate" [key]="'provider.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom de l'administrateur du compte prestataire -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.admin.lastName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'provider.admin.lastName.value' | translate" [key]="'provider.admin.lastName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Prénom de l'administrateur du compte prestataire -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.admin.firstName')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'provider.admin.firstName.value' | translate" [key]="'provider.admin.firstName'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Site web du prestataire -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.website')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'provider.website.value' | translate" [key]="'provider.websites.website'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Ville du prestataire -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.addresses.city')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'provider.address.city.value' | translate" [key]="'provider.addresses.city'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Type de commission -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.commission.type')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'provider.commission.type.value' | translate" [key]="'provider.commission.type'" [operator]="'eq'" [itemValueProperty]="'identifier'" [itemLabelProperty]="'label'" [items]="filterCommissionTypes"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Commission déduite automatiquement -->

                <ng-container [matColumnDef]="getFilterColumnDef('provider.commission.incomingPayment')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Nombre d'offres affectées -->

                <ng-container [matColumnDef]="getFilterColumnDef('totalMarketplaceOffers')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="readItem(getModel(element))" *ngIf="isReadAllowed">
                            <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(getModel(element))" *ngIf="isDeletionAllowed">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Activé -->

                <ng-container matColumnDef="enable">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.enable.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="form-group">
                            <div class="app-toggle">
                                <mat-slide-toggle matTooltip="{{(element.enable) ? ('provider.desactivate.value' | translate) : ('provider.activate.value' | translate) }}" [checked]="element.enable" (change)="(element.enable) ? openDesactivateItemDialog($event, element.id) : openActivateItemDialog($event, element.id)" [disabled]="!userHasAccess('SOCIETY_PROVIDER_EDIT_IS_MINE')"></mat-slide-toggle>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Référence Prestataire -->

                <ng-container matColumnDef="provider.reference">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.reference.value' | translate}}</th>
                    <td class="society-provider-reference" mat-cell *matCellDef="let element">{{getModel(element).provider.reference}}</td>
                </ng-container>

                <!-- Nom de la société du prestataire -->

                <ng-container matColumnDef="provider.name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).provider.name}}</td>
                </ng-container>

                <!-- Nom de l’administrateur du compte prestataire -->

                <ng-container matColumnDef="provider.admin.lastName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.admin.lastName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).provider.admin.lastName}}</td>
                </ng-container>

                <!-- Prénom de l’administrateur du compte prestataire -->

                <ng-container matColumnDef="provider.admin.firstName">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.admin.firstName.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).provider.admin.firstName}} </td>
                </ng-container>

                <!-- Site web du prestataire -->

                <ng-container matColumnDef="provider.website">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.website.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).provider.mainWebsite}}</td>
                </ng-container>

                <!-- Ville du prestataire -->

                <ng-container matColumnDef="provider.addresses.city">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.address.city.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getMailingAddress(element).city}}</td>
                </ng-container>

                <!-- Type de commission -->

                <ng-container matColumnDef="provider.commission.type">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.commission.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getCommissionTypeLabel(getModel(element)) }}</td>
                </ng-container>

                <!-- Commission déduite automatiquement -->

                <ng-container matColumnDef="provider.commission.incomingPayment">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.incomingPayment.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getIncomingPaymentLabel(getModel(element)) }}</td>
                </ng-container>

                <!-- Nombre d'offres affectées -->

                <ng-container matColumnDef="totalMarketplaceOffers">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'provider.marketplace.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).totalMarketplaceOffers }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>

