import {MediaImage} from "@lib/media/image/image";
import {Country} from "@core/shared/models/country";
import {Exclude, Expose} from "class-transformer";
import {Currency} from "@core/shared/models/currency";
import {Society} from "@core/shared/models/society";

@Exclude()
export class Transporter {

    @Expose()
    id: number;

    @Expose()
    name: string;

    @Expose()
    currency: Currency;

    @Expose()
    priceHT: number;

    @Expose()
    society: Society;

    @Expose()
    priceTTC: number;

    @Expose()
    vatPercent: number;

    @Expose()
    image: MediaImage;

    @Expose()
    countries: Country[];

    @Expose()
    deliveryTimeStart: string;

    @Expose()
    deliveryTimeEnd: string;

    @Expose()
    enabled: boolean;

    @Expose()
    translations: TransporterTranslation[];

}


export class TransporterTranslation {

    comment: string;

    locale: string;

}
