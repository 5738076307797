<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'society.distributor.update.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'society.distributor.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>
        <mat-tab [label]="'society.distributor.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalDataTemplate"></ng-container>
        </mat-tab>
        <mat-tab [label]="'distributor.automaticCatalogOffersAcceptance.value' | translate">
            <ng-container *ngTemplateOutlet="automaticCatalogOffersAcceptanceTemplate"></ng-container>
            <div class="col_12" *ngIf="autoAcceptanceAdditionnalInfo">
                <span class="message-help">
                    <mat-icon class="tooltip_icon">info</mat-icon>
                    {{ 'society.automaticCatalogOffersAcceptance.autoAcceptanceAdditionnalInfo' | translate }}
                </span>
            </div>
        </mat-tab>
        <mat-tab [label]="'society.distributor.availableOffers.value' | translate">
            <ng-container *ngTemplateOutlet="availableOffersTemplate"></ng-container>
        </mat-tab>
        <mat-tab [label]="'society.distributor.commission.value' | translate">
            <ng-container *ngTemplateOutlet="commissionTemplate"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalDataTemplate>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'distributor.name.value' | translate }}</strong> : {{ societyDistributor.distributor.name }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.email.contact.value' | translate }}</strong> : {{ societyDistributor.distributor.email }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.phone.contact.value' | translate }}</strong> : {{ societyDistributor.distributor.phone }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.immatriculation.value' | translate }}</strong> : {{ societyDistributor.distributor.registration }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.siren.value' | translate }}</strong> : {{ societyDistributor.distributor.identificationNumber }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.website.value' | translate }}</strong> : {{ societyDistributor.distributor.mainWebsite }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.address.value' | translate }}</strong> : {{ addressFields.address }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.zipcode.value' | translate }}</strong> : {{ addressFields.zipcode }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.city.value' | translate }}</strong> : {{ addressFields.city }}
                </p>
                <p>
                    <strong>{{ 'form.user.fields.country.value' | translate }}</strong> : {{ addressFields.country }}
                </p>
            </div>
        </div>
    </div>

</ng-template>

<!-- Accès à toutes les offres -->

<ng-template #automaticCatalogOffersAcceptanceTemplate>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Accès à toutes les offres -->

                <div class="form-group" >
                    <label>{{'society.automaticCatalogOffersAcceptance.approval.self.value' | translate}}</label>
                    <div class="radio" >
                        <input type="radio" [value]="true" formControlName="automaticCatalogOffersAcceptance" class="form-control" id="automaticCatalogOffersAcceptanceYes" >
                        <label for="automaticCatalogOffersAcceptanceYes">{{'form.user.fields.yes.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="false" formControlName="automaticCatalogOffersAcceptance" class="form-control" id="automaticCatalogOffersAcceptanceNo" >
                        <label for="automaticCatalogOffersAcceptanceNo">{{'form.user.fields.no.value' | translate}}</label>
                    </div>
                    <mat-error *ngIf="form.get('automaticCatalogOffersAcceptance').errors && (form.get('automaticCatalogOffersAcceptance').dirty || form.get('automaticCatalogOffersAcceptance').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('automaticCatalogOffersAcceptance').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>

                <br>

                <ng-container *ngIf="!form.get('automaticCatalogOffersAcceptance').value">
                    <div class="form-group col_content y_items_center">
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ 'society.automaticCatalogOffersAcceptance.deactivate.warning.value' | translate }}
                        </span>
                    </div>
                </ng-container>

            </div>
        </div>

    </ng-container>

</ng-template>

<ng-template #availableOffersTemplate>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <p>
                    <strong>{{ 'distributor.marketplace.value' | translate }}</strong> : {{ societyDistributor.totalMarketplaceOffers }}
                </p>
            </div>
            <div class="text_content" *ngFor="let offer of availableOffers$ | async">
                <p>
                    - <a [routerLink]="['/account/offer/read', offer.id]" [queryParams]="{ origin: 'societyDistributor', societyDistributor: societyDistributor.id }">{{ translationService.getFallbackTranslation(offer.translations).name }} {{ offer.reference ? ('(' + offer.reference + ')') : '' }}</a>
                </p>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #commissionTemplate>

    <ng-container *ngIf="distributorHasRole('ROLE_OFFER_DISTRIBUTOR')">

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="text_content">
                    <span [innerHTML]="'distributor.commission.description.travelAgency.title.value' | translate"></span>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="text_content">
                    <p>
                        <strong>{{ 'distributor.commission.incomingPayment.value' | translate }}</strong> : {{ ((incomingPayment ? 'yes' : 'no') + '.value') | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="text_content">
                    <mat-icon class="tooltip_icon">info</mat-icon>
                    <span [innerHTML]="'distributor.commission.description.travelAgency.info.value' | translate"></span>
                </div>
            </div>
        </div>

    </ng-container>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <span [innerHTML]="('distributor.commission.description.' + (distributorHasRole('ROLE_OFFER_DISTRIBUTOR') ? 'travelAgency' : 'businessProvider') + '.value') | translate"></span>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="text_content">
                <span [innerHTML]="'distributor.commission.description.details.value' | translate: {commissionIndividualStay: commissionIndividualStay, commissionIndividualDay: commissionIndividualDay, commissionGroupStay: commissionGroupStay, commissionGroupDay: commissionGroupDay} | safeHtml"></span>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!distributorHasRole('ROLE_OFFER_DISTRIBUTOR')">
        <div class="row marges ligne_form">
            <div class="col_12">
                <div class="text_content">
                    <p>
                        <strong>{{ 'distributor.commission.incomingPayment.value' | translate }}</strong> : {{ ((incomingPayment ? 'yes' : 'no') + '.value') | translate }}
                    </p>
                </div>
            </div>
        </div>
    </ng-container>

</ng-template>
