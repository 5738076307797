import { NgModule } from '@angular/core';
import {ConfirmDialogModule} from "./confirm-dialog/confirm-dialog.module";
import {MediaModule} from "./media/media.module";
import {CmsModule} from "@lib/cms/cms.module";
import {FormModule} from "@lib/form/form.module";

@NgModule({
    exports: [
        ConfirmDialogModule,
        MediaModule,
        FormModule,
        CmsModule
    ]
})
export class LibModule { }
