import {Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";

/**
 * Configuration paramètres du champ
 */
export interface InputNumberConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs: {
        required?: boolean;
        placeholder?: string;
        disabled?: boolean;
        min?: number;
        max?: number;
    };
}

/**
 * Champ input text
 */
@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit, DoCheck {
    /**
     * Paramètres du champ
     */
    @Input() config: InputNumberConfig = {id: null, attrs: {}};

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    /**
     * Valeur du champ texte
     */
    @Input() value: string;

    @Input() readOnly: boolean;


    private differ: KeyValueDiffer<any, any>;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    public constructor(private differs: KeyValueDiffers) {
        this.differ = this.differs.find(this.config).create()
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl(''));

        if (('required' in this.config.attrs) && this.config.attrs.required) {
            validators.push(Validators.required);
        }

        if (('min' in this.config.attrs) && this.config.attrs.min !== null) {
            validators.push(Validators.min(this.config.attrs.min));
        }

        if (('max' in this.config.attrs) && this.config.attrs.max !== null) {
            validators.push(Validators.max(this.config.attrs.max));
        }

        if (('disabled' in this.config.attrs) && this.config.attrs.disabled) {
            this.readOnly = this.config.attrs.disabled;
        }

        this.form.controls[this.config.id].setValidators(validators);

        this.form.valueChanges.subscribe(() => {
            if (this.form.value[this.config.id] && this.form.value[this.config.id] !== '') {
                this.form.value[this.config.id] = parseFloat(this.form.value[this.config.id]);
            } else {
                this.form.value[this.config.id] = null;
            }
        });
    }

    public ngDoCheck(): void {
        const changes = this.differ.diff(this.config.attrs);

        if (changes) {
            changes.forEachChangedItem((r) => {
                if (r.key == 'required') {
                    const validators: any[] = [];

                    this.config.attrs.required = r.currentValue;

                    this.form.removeControl(this.config.id);

                    this.form.addControl(this.config.id, new UntypedFormControl(''));

                    if (('required' in this.config.attrs) && this.config.attrs.required) {
                        validators.push(Validators.required);
                    }

                    this.form.controls[this.config.id].setValidators(validators);

                }
                if (r.key == 'disabled') {

                    this.config.attrs.disabled = r.currentValue;

                    this.readOnly = this.config.attrs.disabled;

                }
            });
        }


    }
}
