import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {RegistrationAffiliationService} from "@core/shared/services/registration-affiliation.service";
import {RegistrationAffiliation} from "@core/shared/models/registration-affiliation";

@Injectable({
    providedIn: 'root'
})
export class RegistrationAffiliationItemResolver  {

    constructor(
        private _registrationAffiliationService: RegistrationAffiliationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._registrationAffiliationService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
