import {Exclude, Expose, Transform} from "class-transformer";
import * as moment from "moment";
import {DATETIME_FORMAT} from "@app/data";
import {Moment} from "moment";
import {Transporter} from "@core/shared/models/transporter";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";
import {Channel} from "@core/shared/models/channel";
import {Promotion} from "@core/shared/models/promotion";
import {Offer} from "@core/shared/models/offer";
import {Currency} from "@core/shared/models/currency";
import {Society} from "@core/shared/models/society";
import {GiftVoucherTemplate} from "@core/shared/models/gift-voucher/gift-voucher-template";
import {GiftVoucherCustomer} from "@core/shared/models/gift-voucher/gift-voucher-customer";
import {GiftVoucherPromotion} from "@core/shared/models/gift-voucher/gift-voucher-promotion";
import {GiftVoucherOffer} from "@core/shared/models/gift-voucher/gift-voucher-offer";
import {User} from "@core/shared/models/user";

export type GiftVoucherStatus = 'booked' | 'not-booked' | 'expired' | 'canceled' | 'forwarded';

@Exclude()
export class GiftVoucher {

    @Expose()
    id: number;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    createdAt: string

    @Expose()
    status: GiftVoucherStatus;

    @Expose()
    reference: string;

    @Expose()
    code: string;

    @Expose()
    type: string;

    @Expose()
    recipient: string;

    @Expose()
    personalizedNote: string;

    @Expose()
    personalizedBeneficiaryName: string;

    @Expose()
    personalizedBuyerName: string;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    expiryDate: string;

    @Expose()
    isRenewable: boolean;

    @Expose()
    isRenewed: boolean;

    @Expose()
    renewalPercentPrice: number;

    @Expose()
    baseRenewalPrice: number;

    @Expose()
    paymentId: string;

    @Expose()
    paymentStatus: number;

    @Expose()
    nbAdult: number;

    @Expose()
    nbChild: number;

    @Expose()
    adultPrice: number;

    @Expose()
    childPrice: number;

    @Expose()
    transporterPriceHT: number;

    @Expose()
    transporterPriceTTC: number;

    @Expose()
    transporterVatPercent: number;

    @Expose()
    vatPercent: number;

    @Expose()
    price: number;

    @Expose()
    promotionPrice: number;

    @Expose()
    totalPrice: number;

    @Expose()
    totalNetPrice: number;

    @Expose()
    commission: number;

    @Expose()
    commissionTTC: number;

    @Expose()
    amountCommissionTTC: number;

    @Expose()
    isPaidCommission: boolean;

    @Expose()
    transporter: Transporter;

    @Expose()
    template: TemplateGiftVoucher;

    @Expose()
    templateData: GiftVoucherTemplate;

    @Expose()
    channel: Channel;

    @Expose()
    promotion: Promotion;

    @Expose()
    promotionData: GiftVoucherPromotion;

    @Expose()
    offer: Offer;

    @Expose()
    currency: Currency;

    @Expose()
    society: Society;

    @Expose()
    offerOwnerSociety: Society;

    @Expose()
    offerOwnerSocietyData: GiftVoucherSociety;

    @Expose()
    buyer: GiftVoucherCustomer;

    @Expose()
    beneficiary: GiftVoucherCustomer;

    @Expose()
    transferredGiftVoucher: GiftVoucher;

    @Expose()
    initialGiftVoucher: GiftVoucher;

    @Expose()
    locale: string;

    @Expose()
    comment: string;

    @Expose()
    acceptTermsAndConditions: boolean;

    @Expose()
    isAllowedDownloadFile: boolean;

    @Expose()
    isDetached: boolean;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    detachedAt: Moment;

    @Expose()
    offerData: GiftVoucherOffer;

    @Expose()
    customerRenewalPrice: number;

    @Expose()
    customerRenewalPriceTTC: number;

    @Expose()
    responsibleUser?: User;
}

export interface GiftVoucherSociety {

    id: number;

    societyName: string;

    email: string;

    phone: string;

}




