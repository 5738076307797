<div class="page_title">
    <div class="row marges">
        <div class="col y_items_center">
            <h1 class="title">{{ 'payment.title.value' | translate }}</h1>
        </div>
    </div>
</div>

<div *ngIf="isSuccess">

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p class="title success">
                    {{ 'creditCard.payment.success.title.value' | translate }}
                </p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p>
                    <strong>{{ 'creditCard.payment.success.thanks.value' | translate }}</strong>
                </p>
            </div>
        </div>
    </div>

</div>
<div *ngIf="!isSuccess">

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p class="title error">
                    {{ 'creditCard.payment.error.title.value' | translate }}
                </p>
            </div>
        </div>
    </div>

    <div class="row marges ligne_form">
        <div class="col_12">
            <div class="form_paymment_reponse">
                <p>
                    {{ 'creditCard.payment.error.content.value' | translate }}
                </p>
            </div>
        </div>
    </div>

</div>

<div class="row marges ligne_form">
    <div class="col_12">
        <div class="form_paymment_reponse">
            <button class="btn_cta btn_little" type="button" (click)="redirectToCard()">
                {{ 'creditCard.self.redirect.value' | translate }}
            </button>
        </div>
    </div>
</div>

