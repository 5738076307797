import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ImageConfig} from "./image";

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    /**
     * API URL
     */
    private API_URL: string;

    /**
     * PREFIX URL
     */
    private readonly IMAGE_URL: string = '/image/';

    /**
     * GALLERY URL
     */
    private readonly GALLERY_URL: string = '/galleries';

    /**
     * Configuration
     */
    private _config: ImageConfig;

    /**
     * @param _http
     */
    constructor(private _http: HttpClient) {

    }

    /**
     * Définit l'URL d'accès à l'API
     *
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    /**
     * @return boolean
     */
    configLoaded(): boolean {

        return (typeof this._config !== 'undefined');
    }

    /**
     * @param config
     */
    setConfig(config: ImageConfig): void {

        this._config = config;
    }

    /**
     * @returns {Observable<IApiFileConfig>}
     */
    loadConfig(context: string): Observable<ImageConfig> {
        return this._http.get<ImageConfig>(`${this.API_URL}${ this.GALLERY_URL }${this.IMAGE_URL}${context}`);
    }
}
