import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {FormService} from "@core/shared/services/form.service";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {FieldCollection} from "@lib/form/field";
import {OfferNetworkProviderService} from "@core/shared/services/offer/offer-network-provider.service";
import {Channel} from "@core/shared/models/channel";
import {TranslateService} from "@ngx-translate/core";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {Society} from "@core/shared/models/society";


@Component({
    selector: 'app-page-offer-group-network-provider-create',
    templateUrl: './page-offer-group-network-provider-create.component.html',
    styleUrls: ['./page-offer-group-network-provider-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageOfferGroupNetworkProviderCreateComponent implements OnInit {

    public origin: { key: string, backLabel: string } = null;

    public editor = ClassicEditor;

    public fieldCollection = new FieldCollection();

    public channels: Object[] = [];

    public providers: {id: number, name: string}[] = [];

    public constructor(
        private _formBuilder: UntypedFormBuilder,
        public _offerNetworkProviderService: OfferNetworkProviderService,
        private _snackBar: MatSnackBar,
        public formService: FormService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _translateService: TranslateService
    ) {
        this.formService.form = this._formBuilder.group({
            label: ['', Validators.required],
            comment: [''],
            networkProviders: new UntypedFormArray([]),
        });
    }

    public ngOnInit(): void {

        this._initOrigin();

        this._activatedRoute.data.subscribe((data: { channels: Channel[], providers: Society[] }): void => {

            data.channels.forEach((channel: Channel) => {
                this.channels.push(
                    {
                        id: channel.id,
                        name: channel.society.name + ' - ' + channel.translations[0].name
                    });
            });

            data.providers.forEach((provider: Society) => {
                this.providers.push(
                    {
                        id: provider.id,
                        name: provider.name + ' (' + provider.identificationNumber + ')'
                    });
            });

        });

        this.formService.submitCallback = (): void => {

            const data: OfferGroupNetworkProvider = this.form.value;

            for (let i = 0; i < data.networkProviders.length; i++) {

                data.networkProviders[i].channels.forEach((channel, index) =>{
                    /** @ts-ignore **/
                    data.networkProviders[i].channels[index] = {'id' : channel};
                });

                data.networkProviders[i].providers.forEach((provider, index) =>{
                    /** @ts-ignore **/
                    data.networkProviders[i].providers[index] = {'id' : provider};
                });
            }

            this._offerNetworkProviderService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('networkProvider.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToOrigin();
            });
        };
    }

    private _initOrigin(): void {

        this.origin = {
            key: this._activatedRoute.snapshot.queryParams['origin'],
            backLabel: `offerGroupNetworkProvider.creation.origin.${this._activatedRoute.snapshot.queryParams['origin']}.value`
        };
    }

    public redirectToOrigin(): void {

        switch (this.origin.key) {

            case 'relationshipList':

                this._router.navigate(['account/relationship/list'], { queryParams: { targetTab: 'provider'}});

                break;
        }
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
