import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Offer} from "@core/shared/models/offer";

@Component({
    selector: 'app-core-offer-picture-gallery-dialog',
    templateUrl: './offer-picture-gallery-dialog.component.html',
    styleUrls: ['./offer-picture-gallery-dialog.component.scss']
})
export class OfferPictureGalleryDialogComponent implements OnInit {

    constructor(
        private _dialogRef: MatDialogRef<OfferPictureGalleryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { offer: Offer }
    ) {
    }

    ngOnInit(): void {
    }

    public close(): void {

        this._dialogRef.close();
    }

    get offer(): Offer {

        return this.data.offer;
    }
}
