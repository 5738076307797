<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{'offer.attribute.title.attribute.value' | translate}}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" class="btn_cta btn_little btn_add">{{ 'offer.attribute.title.create.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Caractéristiques -->

                <ng-container [matColumnDef]="getFilterColumnDef('type.id')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.attribute.grid.features.value' | translate" [key]="'type.id'" [operator]="'eq'" [items]="offerAttributeTypeFilterItems" [itemLabelProperty]="'label'" [itemValueProperty]="'id'"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Attribut -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.label')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.attribute.grid.attribute.value' | translate" [key]="'translations.label'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="editItem(element.id)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Caractéristiques -->

                <ng-container matColumnDef="type.id">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.attribute.grid.features.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).type.translations).label}}</td>
                </ng-container>

                <!-- Attribut -->

                <ng-container matColumnDef="translations.label">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'offer.attribute.grid.attribute.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).label}}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>

