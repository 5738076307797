import {Injectable} from '@angular/core';
import {INavigationItem, NavigationItemTag} from "@navigation/shared/models/navigation";
import {Observable} from "rxjs";
import {ApiService} from "@core/shared/services/api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private _items: INavigationItem[] = [];

    constructor(
        private _httpClient: HttpClient,
        private _apiService: ApiService
    ) {
    }

    clear(): void {

        this._items = [];
    }

    hasChildOpened(item: INavigationItem): boolean {

        const childOpened: INavigationItem = item.children.find((item: INavigationItem): boolean => {

            return item.opened;
        });

        return !!childOpened;
    }

    get itemsAPI(): Observable<INavigationItem[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<INavigationItem[]>(`${this._apiService.getApiUrl(false, true)}/navigation-items`, {
            headers: headers
        }).pipe();
    }

    public defaultOpenedPanelTags(): NavigationItemTag[] {

        return ['BOOKING_MANAGEMENT'];
    }

    get items(): INavigationItem[] {

        return this._items;
    }

    set items(value: INavigationItem[]) {

        this._items = value;
    }
}
