<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'promotionSubscription.read.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">
                <!-- Retour -->
                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'promotionSubscription.list.back.value' | translate }}
                </button>

            </div>
        </div>
    </div>
    <mat-tab-group [@.disabled]="true">
        <mat-tab label="Données générales">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="name"
                               id="name" required="required">
                        <label for="name">{{'promotionSubscription.code.value' | translate}}</label>
                        <mat-error
                                *ngIf="form.get('name').errors && (form.get('name').dirty || form.get('name').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>


            <div class="row marges ligne_form">
                <div class="col_12 ">
                    <div class="border">
                        <p class="title">{{'promotionSubscription.admissionPrice.value' | translate }}</p>
                        <div class="col_12">
                            <div class="form-group margin-bottom">
                                <div [formGroup]="form" class=" app-toggle">
                                    <mat-slide-toggle formControlName="admissionPriceFree" (change)="updateValidatorAdmission()">{{ 'promotionSubscription.admissionPriceFree.value' | translate}}</mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col_12" *ngIf="!form.get('admissionPriceFree').value">
                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="admissionPriceDiscount"
                                       id="admissionPriceDiscount">
                                <label for="admissionPriceDiscount">{{'promotionSubscription.admissionPriceDiscount.value' | translate}}</label>
                                <mat-error
                                        *ngIf="form.get('admissionPriceDiscount').errors && (form.get('admissionPriceDiscount').dirty ||form.get('admissionPriceDiscount').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('admissionPriceDiscount').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                            <div class="form-group">
                                <label>{{ 'promotionSubscription.admissionPriceTypeDiscount.value' | translate }}</label>

                                <select formControlName="admissionPriceTypeDiscount">
                                    <option value="">--</option>
                                    <option *ngFor="let choice of typeDiscountChoices" [value]="choice.id">
                                        {{ choice.name}}
                                    </option>
                                </select>
                                <mat-error>
                                    <div *ngIf="form.controls['admissionPriceTypeDiscount'].invalid && (form.controls['admissionPriceTypeDiscount'].dirty || form.controls['admissionPriceTypeDiscount'].touched)">
                                        <div *ngIf="form.get('admissionPriceTypeDiscount').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 ">
                    <div class="border">
                        <p class="title">{{'promotionSubscription.subscriptionDiscount.value' | translate }}</p>
                        <div class="col_12">
                            <div class="form-group ">
                                <div class=" app-toggle">
                                    <mat-slide-toggle formControlName="subscriptionFree" (change)="updateValidatorSubscription()">{{ 'promotionSubscription.subscriptionFree.value' | translate}}</mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col_12" *ngIf="!form.get('subscriptionFree').value">
                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="subscriptionPriceDiscount"
                                       id="subscriptionPriceDiscount">
                                <label for="subscriptionPriceDiscount">{{'promotionSubscription.admissionPriceDiscount.value' | translate}}</label>
                                <mat-error
                                        *ngIf="form.get('subscriptionPriceDiscount').errors && (form.get('subscriptionPriceDiscount').dirty ||form.get('subscriptionPriceDiscount').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('subscriptionPriceDiscount').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                            <div class="form-group">
                                <label>{{ 'promotionSubscription.admissionPriceTypeDiscount.value' | translate }}</label>

                                <select formControlName="subscriptionPriceTypeDiscount" >
                                    <option value="">--</option>
                                    <option *ngFor="let choice of typeDiscountChoices" [value]="choice.id">
                                        {{ choice.name}}
                                    </option>
                                </select>
                                <mat-error>
                                    <div *ngIf="form.controls['subscriptionPriceTypeDiscount'].invalid && (form.controls['subscriptionPriceTypeDiscount'].dirty || form.controls['subscriptionPriceTypeDiscount'].touched)">
                                        <div *ngIf="form.get('subscriptionPriceTypeDiscount').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row marges ligne_form">
                <div class="col_12 ">
                    <div class="border">
                        <p class="title">{{'promotionSubscription.applicationCriteria.value' | translate }}</p>
                        <!-- Périodicité -->
                        <div class="form-group">
                            <label>{{'form.user.subscription.fields.periodicity.title.value' | translate}}</label>

                            <div class="radio">
                                <input type="radio" value="M" formControlName="periodicity" class="form-control" id="periodicity-month" (click)="resetDuration('M')">
                                <label >{{'form.user.subscription.fields.periodicity.month.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" value="Y" formControlName="periodicity" class="form-control" id="periodicity-year" (click)="resetDuration('Y')">
                                <label >{{'form.user.subscription.fields.periodicity.year.value' | translate}}</label>
                            </div>

                            <mat-error
                                    *ngIf="form.get('periodicity').errors && (form.get('periodicity').dirty || form.get('periodicity').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('periodicity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <!-- Séléction du nombre de licence -->

                        <div class="form-group">
                            <label>{{'promotionSubscription.licenceType.quantity.value' | translate}}</label>

                            <select formControlName="subscription">
                                <option *ngFor="let subscription of availableSubscriptions" [value]="subscription.id">
                                    {{ translationService.getFallbackTranslation(subscription.translations).name }}
                                </option>
                            </select>
                            <mat-error
                                    *ngIf="form.get('subscription').errors && (form.get('subscription').dirty || form.get('subscription').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('subscription').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <div class="form-group">
                            <label>{{'promotionSubscription.subscriptionDurationDiscount.value' | translate}}</label>

                            <select formControlName="subscriptionDurationDiscount">
                                <option [ngValue]="null">{{ 'promotion.discount.duration.infinity.value' | translate }}</option>>
                                <option *ngFor="let option of durationOptions" [ngValue]="option.id">
                                    {{option.name}}
                                </option>
                            </select>
                            <mat-error
                                    *ngIf="form.get('subscriptionDurationDiscount').errors && (form.get('subscriptionDurationDiscount').dirty || form.get('subscriptionDurationDiscount').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('subscriptionDurationDiscount').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>


                        </div>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12 ">
                    <div class="border">
                        <p class="title">{{'promotionSubscription.usersCriteria.value' | translate }}</p>
                        <span class="info">{{'promotionSubscription.choice_user_or_society.value'  | translate}}</span>
                        <br/>
                        <br/>

                        <div class="form-group">
                            <div class="radio">
                                <input type="radio" value="society" formControlName="typeUser" class="form-control" id="society" (change)="updateValidateUser()">
                                <label >{{'promotionSubscription.typeUserSociety.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" value="new" formControlName="typeUser" class="form-control" id="newUser" (change)="updateValidateUser()">
                                <label >{{'promotionSubscription.typeUserNew.value' | translate}}</label>
                            </div>

                            <mat-error
                                    *ngIf="form.get('typeUser').errors && (form.get('typeUser').dirty || form.get('typeUser').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('typeUser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>


                        <div class="form-group" *ngIf="form.get('typeUser').value == 'society'">
                            <app-select-search [disabled]="true" [value]="form.get('society').value" [config]="fieldCollection.getFieldConfig('society')" [form]="form" ></app-select-search>
                            <mat-error>
                                <div *ngIf="form.get('society').invalid && (form.get('society').dirty || form.get('society').touched)">
                                    <div *ngIf="form.get('society').errors?.required">{{ 'form.validation.error.required' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                        <br/>

                        <div formArrayName="customer" *ngIf="form.get('typeUser').value == 'new'">
                            <div formGroupName="0">
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="societyName"
                                           id="societyName">
                                    <label for="societyName">{{'promotionSubscription.societyName.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="customerForm.controls[0].get('societyName').errors && (customerForm.controls[0].get('societyName').dirty || customerForm.controls[0].get('societyName').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('societyName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="firstName"
                                           id="firstName">
                                    <label for="firstName">{{'promotionSubscription.firstName.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="customerForm.controls[0].get('firstName').errors && (customerForm.controls[0].get('firstName').dirty || customerForm.controls[0].get('firstName').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('firstName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="lastName"
                                           id="lastName">
                                    <label for="lastName">{{'promotionSubscription.lastName.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="customerForm.controls[0].get('lastName').errors && (customerForm.controls[0].get('lastName').dirty || customerForm.controls[0].get('lastName').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('lastName').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="email"
                                           id="email">
                                    <label for="email">{{'promotionSubscription.email.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="customerForm.controls[0].get('email').errors && (customerForm.controls[0].get('email').dirty || customerForm.controls[0].get('email').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="code"
                                           id="code">
                                    <label for="code">{{'promotionSubscription.codeDiscount.value' | translate}}</label>
                                    <mat-error
                                            *ngIf="customerForm.controls[0].get('code').errors && (customerForm.controls[0].get('code').dirty || customerForm.controls[0].get('code').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('code').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>


                                <div class="form-group">
                                    <select class="form-control" id="locale" formControlName="locale">
                                        <option *ngFor="let locale of locales$ | async" [value]="locale.id">
                                            {{ locale.label | translate }}
                                        </option>
                                    </select>
                                    <label class="required" for="locale">{{ 'language.value' | translate }}</label>
                                    <mat-error *ngIf="customerForm.controls[0].get('locale').errors && (customerForm.controls[0].get('locale').dirty || customerForm.controls[0].get('locale').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <!-- Date de début -->

                                <div class="form-group">
                                    <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="dateStartPicker" formControlName="dateStart" (click)="dateStartPicker.open()" id="dateStart" placeholder=" ">
                                    <label for="dateStart">{{'promotion.date.validity.start.value' | translate}}</label>
                                    <mat-datepicker #dateStartPicker></mat-datepicker>
                                    <mat-error *ngIf="customerForm.controls[0].get('dateStart').errors && (customerForm.controls[0].get('dateStart').dirty || customerForm.controls[0].get('dateStart').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('dateStart').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="customerForm.controls[0].get('dateStart').errors.startAfter">{{ 'form.control.error.dateStart.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <!-- Date de fin -->

                                <div class="form-group">
                                    <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="dateEndPicker" formControlName="dateEnd" (click)="dateEndPicker.open()" id="dateEnd" placeholder=" ">
                                    <label for="dateEnd">{{'promotion.date.validity.end.value' | translate}}</label>
                                    <mat-datepicker #dateEndPicker></mat-datepicker>
                                    <mat-error *ngIf="customerForm.controls[0].get('dateEnd').errors && (customerForm.controls[0].get('dateEnd').dirty || customerForm.controls[0].get('dateEnd').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerForm.controls[0].get('dateEnd').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="customerForm.controls[0].get('dateEnd').errors.startBefore">{{ 'form.control.error.dateEnd.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                                <div class="row marges ligne_form">
                                    <div class="col_12 ">
                                        <div class="form-group">
                                            <span class="message-help">
                                            <mat-icon class="tooltip_icon">info</mat-icon>{{ 'promotion.date.description.value' | translate }}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</form>

