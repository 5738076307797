import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {OfferOption} from "../../models/offer/offer-option";
import {Pagination} from "../../models/pagination";
import {ApiService} from "../api.service";

@Injectable({
    providedIn: 'root'
})
export class OfferOptionService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(societyId: number, params?: string[]): Observable<OfferOption[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/offerOptions`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferOption[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferOption[] => {

                return items.map((item: object): OfferOption => {

                    return plainToClass(OfferOption, item);
                })
            })
        );
    }

    public getPaginationItemsAPI(societyId: number, params?: string[]): Observable<Pagination<OfferOption>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/offerOptions`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<OfferOption>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<object>): Pagination<OfferOption> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: object): OfferOption => {

                        return plainToClass(OfferOption, item);
                    })
                });
            })
        );
    }

    public getOfferItemsAPI(offerId: number, params?: string[]): Observable<OfferOption[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${offerId}/options`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferOption[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferOption[] => {

                return items.map((item: object): OfferOption => {

                    return plainToClass(OfferOption, item);
                })
            })
        );
    }

    public getItemAPI(id: number): Observable<OfferOption> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<OfferOption>(`${this._apiService.getApiUrl(false, true)}/offerOptions/${id}`, {
            headers: headers
        }).pipe(
            map((item: object): OfferOption => {

                return plainToClass(OfferOption, item);
            })
        );
    }

    public createItemAPI(societyId: number, data: object): Observable<OfferOption> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<OfferOption>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/offerOptions`, data, {
            headers: headers
        }).pipe(
            map((item: object): OfferOption => {

                return plainToClass(OfferOption, item);
            })
        );
    }

    public updateItemAPI(id: number, data: object): Observable<OfferOption> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<OfferOption>(`${this._apiService.getApiUrl(false, true)}/offerOptions/${id}`, data,{
            headers: headers
        }).pipe(
            map((item: object): OfferOption => {

                return plainToClass(OfferOption, item);
            })
        );
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/offerOptions/${id}`,{
            headers: headers
        });
    }
}
