import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from '../api.service';
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {map} from "rxjs/operators";
import {plainToClass} from "class-transformer";
import {Offer} from "@core/shared/models/offer";
import {Pagination} from "@core/shared/models/pagination";
import {Channel} from "@core/shared/models/channel";

@Injectable({
    providedIn: 'root'
})
export class OfferCatalogService {

    public selfItems: BehaviorSubject<OfferCatalog[]> = new BehaviorSubject([]);

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {
    }

    public getItemsAPI(params?: string[]): Observable<OfferCatalog[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/catalog`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferCatalog[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferCatalog[] => {

                return items.map((item: OfferCatalog): OfferCatalog => {

                    return Object.assign(item, {
                        offer: plainToClass(Offer, item.offer)
                    });
                });
            })
        );
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<OfferCatalog>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/catalog`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<OfferCatalog>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<OfferCatalog>): Pagination<OfferCatalog> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: OfferCatalog): OfferCatalog => {

                        return Object.assign(item, {
                            offer: plainToClass(Offer, item.offer)
                        });
                    })
                });
            })
        );
    }

    public getItemsRequestAPI(params?: string[]): Observable<OfferCatalog[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/catalog/request`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<OfferCatalog[]>(url, {
            headers: headers
        }).pipe(
            map((items: object[]): OfferCatalog[] => {

                return items.map((item: OfferCatalog): OfferCatalog => {

                    return Object.assign(item, {
                        offer: plainToClass(Offer, item.offer)
                    });
                });
            })
        );
    }

    public getChannelsRequestAPI(offer, params?: string[]): Observable<Channel[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/${offer}/channels`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Channel[]>(url, {
            headers: headers
        });
    }

    public getPaginationItemsRequestAPI(params?: string[]): Observable<Pagination<OfferCatalog>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/offers/catalog/request`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<OfferCatalog>>(url, {
            headers: headers
        }).pipe(
            map((pagination: Pagination<OfferCatalog>): Pagination<OfferCatalog> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: OfferCatalog): OfferCatalog => {

                        return Object.assign(item, {
                            offer: plainToClass(Offer, item.offer)
                        });
                    })
                });
            })
        );
    }

    public getItemRequestAPI(id: number): Observable<OfferCatalog> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const url: string = `${this._apiService.getApiUrl(false, true)}/offers/catalog/request/${id}`;

        return this._httpClient.get<OfferCatalog>(url, {
            headers: headers
        }).pipe(
            map((item: OfferCatalog): OfferCatalog => {

                return Object.assign(item, {
                    offer: plainToClass(Offer, item.offer)
                });
            })
        );
    }

    public updateItemRequestAPI(id: number, data: object): Observable<OfferCatalog> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const url: string = `${this._apiService.getApiUrl(false, true)}/offers/catalog/request/${id}`;

        return this._httpClient.patch<OfferCatalog>(url, data, {
            headers: headers
        }).pipe(
            map((item: OfferCatalog): OfferCatalog => {

                return Object.assign(item, {
                    offer: plainToClass(Offer, item.offer)
                });
            })
        );
    }

    public createItemAPI(data: object): Observable<any> {

        const url = `${this._apiService.getApiUrl(false, true)}/offers/catalog`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<any>(url, data, {
            headers: headers
        });
    }

    public createItemsAPI(data: object): Observable<any> {

        const url = `${this._apiService.getApiUrl(false, true)}/offers/catalog/multiple`;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<any>(url, data, {
            headers: headers
        });
    }


    public updateItemsAPI(data: object): Observable<Offer[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Offer[]>(`${this._apiService.getApiUrl(false, true)}/offers/catalog/multiple`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/offers/catalog/${id}`,{
            headers: headers
        });
    }

    public getSelfItemByOffer(offer: Offer): OfferCatalog {

        return this.selfItems.getValue().find((item: OfferCatalog): boolean => {

            return item.offer.id === offer.id;
        });
    }

    public addOfferToSelfItems(offerCatalog: OfferCatalog): void {

        const items: OfferCatalog[] = this.selfItems.getValue();

        items.push(offerCatalog);

        this.selfItems.next(items);
    }

    public exportItemsRequest(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/offers/catalog/request/export`, {
            responseType: 'blob'
        });

    }
}
