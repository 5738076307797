import {Exclude, Expose, Transform, Type} from "class-transformer";
import {Moment} from "moment";
import * as moment from "moment";
import {TranslationItem} from "@core/shared/models/translation";
import {Offer} from "@core/shared/models/offer";
import {Society} from "@core/shared/models/society";
import {DATETIME_FORMAT} from "@app/data";
import {PromotionTypeType} from "@core/shared/models/promotion/promotion-type";
import {PromotionPublicType} from "@core/shared/models/promotion/promotion-public";
import {NetworkProvider} from "@core/shared/models/network-provider";
import {parsePrice} from "@core/shared/utils/price";

@Exclude()
export class Promotion {

    @Expose()
    id: number;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    createdAt: Moment;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    dateStart: Moment;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    dateEnd: Moment;

    @Expose()
    code: string;

    @Expose()
    @Transform(({value}) => value ? parsePrice((value as number) * 100, 2, '', '.') : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as number) / 100 : null, {toPlainOnly: true})
    value: number;

    @Expose()
    quota: number;

    @Expose()
    comment: string;

    @Expose()
    enable: boolean;

    @Expose()
    type: PromotionTypeType;

    @Expose()
    public: PromotionPublicType;

    @Expose()
    parsedCustomers: string;

    @Expose()
    countOffers: number;

    @Expose()
    @Type(() => Offer)
    offers: Offer[];

    @Expose()
    locales: string[];

    @Expose()
    timezone: string;

    @Expose()
    distributors: Society[];

    @Expose()
    networkProviders: NetworkProvider[];

    @Expose()
    translations: PromotionTranslation[];

    @Expose()
    allowAllCreatedOffers: boolean;

    @Expose()
    allowAllCatalogOffers: boolean;

    @Expose()
    allowBooking: boolean;

    @Expose()
    allowGiftVoucher: boolean;

    get parsedTimeStart(): string {

        if(!this.dateStart){

            return null;
        }

        return `${this.dateStart.format('HH')}:${this.dateStart.format('mm')}`;
    }

    get parsedTimeEnd(): string {

        if(!this.dateStart){

            return null;
        }

        return `${this.dateEnd.format('HH')}:${this.dateEnd.format('mm')}`;
    }
}

export interface PromotionTranslation extends TranslationItem {

    name: string;
}
