import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import Swiper, {Navigation} from "swiper";

@Component({
    selector: 'app-cms-slideshow',
    templateUrl: './slideshow.component.html',
    styleUrls: ['./slideshow.component.scss']
})
export class CmsSlideshowComponent implements AfterViewInit {

    @Input() slideshow: any;

    @ViewChild('swiper', {static: true}) swiperRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonPrev', {static: true}) swiperButtonPrevRef: ElementRef<HTMLElement>;

    @ViewChild('swiperButtonNext', {static: true}) swiperButtonNextRef: ElementRef<HTMLElement>;

    constructor(private _apiService: ApiService) {
    }

    ngAfterViewInit() {

        this._initSlider();
    }

    private _initSlider(): void {

        new Swiper(this.swiperRef.nativeElement, {
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            autoplay: true,
            modules: [
                Navigation
            ],
            navigation: {
                nextEl: this.swiperButtonNextRef.nativeElement,
                prevEl: this.swiperButtonPrevRef.nativeElement,
            }
        });
    }

    public getSelectedFormat(formats: any) {
        if (!Array.isArray(formats))
            return null;

        let matches = formats.filter((format) => {
            return format['selected'];
        });

        if (matches.length == 0)
            return 'landscape';

        let match = matches[0];

        return match['name'];
    }

    public getVisuelCms(idImage: number, format: string): string {
        return !!idImage ? `${this._apiService.getApiUrl(false, false)}/public/cms/picture/${idImage}/filter/${this.getSelectedFormat(format)}` : null;
    }

    public getImages(images, srcOnly = false) {
        return images.sort((a, b) => a.position > b.position).map(img => srcOnly ? this.getVisuelCms(img.id, img.formats) : img);
    }

    public getHeightWidth(event) {
        event.target.width = event.target.naturalWidth;
    }
}
