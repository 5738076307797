import {Pipe, PipeTransform} from '@angular/core';
import {ApiService} from "@core/shared/services/api.service";
import {Offer} from "@core/shared/models/offer";
import {OfferProgram} from "@core/shared/models/offer/offer-program";

@Pipe({
    name: 'offerProgramPictureSource'
})
export class OfferProgramPictureSourcePipe implements PipeTransform {

    constructor(
        private _apiService: ApiService
    ){
    }

    transform(program: OfferProgram, offer: Offer, locale: string, format: string, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public/offers/${offer.id}/programs/${program.id}/${locale}/picture/filter/${format}/${width}/${quality}`;
    }
}
