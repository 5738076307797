import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OfferPermanentOption} from "@core/shared/models/offer/offer-permanent-option";
import {OfferPermanentOptionService} from "@core/shared/services/offer/offer-permanent-option.service";

@Injectable({
    providedIn: 'root'
})
export class OfferPermanentOptionResolver  {

    constructor(
        private _offerPermanentOptionService: OfferPermanentOptionService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._offerPermanentOptionService.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
