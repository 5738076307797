<form [formGroup]="form">

    <div class="form_part">
        <div class="row marges ligne_form">
            <div class="col_6">

                <!-- Durée de validité des bon cadeaux -->

                <div class="form-group">
                    <mat-select formControlName="validityDuration">
                        <mat-option value="null">-</mat-option>
                        <mat-option *ngFor="let duration of validityDurations" [value]="duration.id">
                            {{ duration.name }}
                        </mat-option>
                    </mat-select>
                    <mat-label>{{ 'giftVoucher.configuration.validity.select.action.value' | translate }}</mat-label>
                </div>

            </div>
        </div>

        <!-- Renouvellement des bon cadeaux  -->

        <div class="row marges ligne_form">
            <div class="col_6">
                <div class="border">
                    <p class="title">{{'giftVoucher.configuration.form.fields.renewal.value' | translate}}</p>
                    <div class="col_12">
                        <div class="form-group margin-bottom">
                            <div [formGroup]="form" class="app-toggle">
                                <mat-slide-toggle formControlName="enableRenewal">{{'giftVoucher.configuration.form.fields.renewal.enable.value' | translate}}</mat-slide-toggle>
                            </div>
                        </div>
                        <div class="form-group">
                            <p class="message-help"><mat-icon>info</mat-icon><span [innerHTML]="'giftVoucher.configuration.form.fields.renewal.enable.help' | translate | safeHtml"></span></p>
                        </div>
                    </div>

                    <!-- Durée du renouvellement -->

                    <div class="col_12" *ngIf="form.get('enableRenewal').value">
                        <div class="form-group">
                            <mat-select formControlName="renewalDuration">
                                <mat-option [value]="null">-</mat-option>
                                <mat-option *ngFor="let duration of renewalDurations" [value]="duration.id">
                                    {{ duration.name }}
                                </mat-option>
                            </mat-select>
                            <mat-label>{{ 'giftVoucher.configuration.form.fields.renewal.duration.value' | translate }}</mat-label>
                            <mat-error *ngIf="form.get('renewalDuration').errors && (form.get('renewalDuration').dirty || form.get('renewalDuration').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('renewalDuration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <ng-container *ngIf="form.get('enableRenewal').value">

                        <!-- Renouvellement payant -->

                        <div class="col_12">
                            <div class="form-group margin-bottom">
                                <div [formGroup]="form" class="app-toggle">
                                    <mat-slide-toggle formControlName="enableRenewalPayment">{{ 'giftVoucher.configuration.form.fields.renewal.payment.value' | translate}}</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="form-group">
                                <span class="message-help"><mat-icon>info</mat-icon>{{ 'giftVoucher.configuration.form.fields.renewal.payment.help' | translate }}</span>
                            </div>
                        </div>

                        <!-- Prix du renouvellement -->

                        <div class="col_12" *ngIf="form.get('enableRenewalPayment').value">
                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="renewalPercentPrice" id="renewalPercentPrice" appInputTextParseMarkup [control]="form.get('renewalPercentPrice')" required>
                                <label for="renewalPercentPrice">{{'giftVoucher.configuration.form.fields.renewal.percentPrice.value' | translate}}</label>
                                <mat-error>
                                    <div *ngIf="form.get('renewalPercentPrice').invalid && (form.get('renewalPercentPrice').dirty || form.get('renewalPercentPrice').touched)">
                                        <div *ngIf="form.get('renewalPercentPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('renewalPercentPrice').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('renewalPercentPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        <div *ngIf="form.get('renewalPercentPrice').errors.max">{{ 'giftVoucher.configuration.validator.renewalPercent.max.value' | translate: {max: defaultMaxPercentRenewal} }}</div>
                                        <div *ngIf="form.get('renewalPercentPrice').errors.min">{{ 'giftVoucher.configuration.validator.renewalPercent.min.value' | translate: {min: defaultMinPercentRenewal} }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!-- Visualisation templates proposés par admin   -->
    <ng-container *ngIf="adminTemplatesGiftVouchers.length > 0">

        <div class="form_part">

            <p class="title_part">{{'giftVoucher.template.picture.admin.value' | translate}}</p>

            <div class="ligne_form row marges">

                <div class="col_6 col_md_4 content_card" *ngFor="let templateAdmin of adminTemplatesGiftVouchers">
                    <div class="card_template" [ngClass]="hasTemplate(templateAdmin) ? 'selected' : ''">
                        <div class="row h_full direction_y">
                            <div class="col_content">
                                <div class="flags">
                                    <span *ngFor="let translation of templateAdmin.translations" class="flag flag-{{ translation.locale }}"></span>
                                </div>
                            </div>
                            <div class="col card_bottom">
                                <div class="row h_full direction_y">
                                    <div class="form-group">
                                        <div class="visual">
                                            <img [src]="templateAdmin | giftVoucherPictureSource: translationService.getFallbackTranslation(templateAdmin.translations).locale : 'landscape' : 250 : 100"/>
                                            <div class="delivery">
                                                <div *ngIf="templateAdmin.isDematerialized" matTooltip="{{ 'giftVoucher.template.form.fields.dematerialized.value' | translate }}" matTooltipPosition="above" class="demat"></div>
                                                <div *ngIf="templateAdmin.isPhysical" matTooltip="{{ 'giftVoucher.template.form.fields.physical.value' | translate }}" matTooltipPosition="above" class="physic"></div>
                                            </div>
                                        </div>
                                        <div class="infos">
                                            <button class="btn_cta btn_little" type="button" id="picture-{{ templateAdmin.id }}" (click)="patchTemplatesValue(templateAdmin)">
                                                {{ 'giftVoucher.configuration.form.fields.select.template.' + (hasTemplate(templateAdmin) ? 'unchecked' : 'checked') + '.value' | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </ng-container>

    <!-- Visualisation templates  -->

    <div class="form_part">

        <p class="title_part">{{'giftVoucher.template.picture.own.value' | translate}}</p>

        <!-- Ajout d'un template  -->

        <div class="ligne_form row marges">
            <div class="col_12">
                <button class="btn_cta btn_little" type="button" (click)="openCreateTemplateDialog()"> {{ 'giftVoucher.template.action.add.value' | translate }}</button>
            </div>
        </div>

        <div class="ligne_form row marges">
            <div class="col_6 col_md_4 content_card" *ngFor="let templateGiftVoucher of templatesGiftVouchers">
                <div class="card_template" [ngClass]="hasTemplate(templateGiftVoucher) ? 'selected' : ''">

                    <div class="row h_full direction_y">

                        <div class="col_content">
                            <div class="flags">
                                <span *ngFor="let translation of templateGiftVoucher.translations" class="flag flag-{{ translation.locale }}"></span>
                            </div>
                        </div>
                        <div class="col card_bottom">
                            <div class="row h_full direction_y">
                                <div class="form-group">
                                    <div class="visual">
                                        <img [src]="templateGiftVoucher | giftVoucherPictureSource: translationService.getFallbackTranslation(templateGiftVoucher.translations).locale : 'landscape' : 250 : 100"/>
                                        <div class="delivery">
                                            <div *ngIf="templateGiftVoucher.isDematerialized" matTooltip="{{ 'giftVoucher.template.form.fields.dematerialized.value' | translate }}" matTooltipPosition="above" class="demat"></div>
                                            <div *ngIf="templateGiftVoucher.isPhysical" matTooltip="{{ 'giftVoucher.template.form.fields.physical.value' | translate }}" matTooltipPosition="above" class="physic"></div>
                                        </div>

                                        <!-- Edition d'un template   -->
                                        <div class="buttons">
                                            <button mat-icon-button (click)="openTemplateVoucherDialog(templateGiftVoucher)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <button mat-icon-button (click)="openDeleteItemDialog(templateGiftVoucher.id)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>

                                    </div>
                                    <div class="infos">
                                        <button class="btn_cta btn_little" type="button" id="picture-{{ translationService.getFallbackTranslation(templateGiftVoucher.translations).id }}" (click)="patchTemplatesValue(templateGiftVoucher)">
                                            {{ 'giftVoucher.configuration.form.fields.select.template.' + (hasTemplate(templateGiftVoucher) ? 'unchecked' : 'checked') + '.value' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>

</form>

