<h1 mat-dialog-title>{{ 'offer.period.delete.action.value' | translate }}</h1>
<mat-dialog-content>
    <app-core-offer-period-delete [offer]="offer" [configuration]="configuration" (delete)="delete.emit()"></app-core-offer-period-delete>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row marges x_center">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="close()">{{ 'cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="submit()">{{ 'submit.action.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>
