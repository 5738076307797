<ng-container *ngIf="!hasChildren && item.path">
    <a [routerLink]="[item.path]" routerLinkActive="active" [target]="(item.target) ? item.target : '_self'" [routerLinkActiveOptions]="{ exact: true }" class="d_flex item_nav y_items_center">
        <img *ngIf="item.image && !disableImage" src="{{item.image}}" alt="Icon" style="margin-right:5px;" width="24px;" />
        <span *ngIf="item.image">{{item.label | translate}}</span>
        <span *ngIf="!item.image" class="{{ item.icon }}">{{item.label | translate}}</span>
    </a>
</ng-container>
<ng-container *ngIf="hasChildren">
    <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggle(item.path);" class="d_flex item_nav y_items_center has_child" [ngClass]="parentClasses">
        <img *ngIf="item.image" src="{{item.image}}" alt="Icon" style="margin-right:5px;" width="24px;" />
        <span *ngIf="item.image">{{item.label | translate}}</span>
        <span *ngIf="!item.image" class="{{ item.icon }}">{{item.label | translate}}</span>
    </div>
    <div class="subnav">
        <app-navigation-sidenav-item *ngFor="let child of item.children" [item]="child" [disableImage]="true"></app-navigation-sidenav-item>
    </div>
</ng-container>
