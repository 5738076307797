
export type SocietyType = 'company' | 'association';

export interface SocietyTypeItem {

    id: SocietyType;

    label: string;
}

export const SOCIETY_TYPE_ITEMS: SocietyTypeItem[] = [
    {
        id: 'company',
        label: 'society.type.company.value'
    },
    {
        id: 'association',
        label: 'society.type.association.value'
    }
];