import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import {Gallery} from "@lib/media/gallery";
import {File} from "@lib/media/file/file";
import {FileUploadConfig} from "@lib/media/file-upload/file-upload";

export interface FileConfig extends InputConfig {

    uploadApiUrl: string;

    gallery: Gallery;

    required: boolean;

    help? : string;

    uploadMaxFileSize?: number;

    upload?: Partial<FileUploadConfig>;
}


@Component({
    selector: 'form-field-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

    @Input() config: FileConfig;

    @Input() file: File;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    constructor() {
    }

    ngOnInit() {

        const validators: ValidatorFn[] = [];

        if(this.config.required){
            validators.push(Validators.required);
        }

        this.form.addControl(this.config.id, new UntypedFormControl('', validators));

        this.form.valueChanges.subscribe(() => {
            if (this.form.value[this.config.id]) {
                if ((typeof this.form.value[this.config.id].file === 'undefined')) {
                    this.file = this.form.value[this.config.id];
                } else {
                    this.file = this.form.value[this.config.id].file;
                }
            }
        });
    }

    add(file: File): void {

        this.file = file;

        this.form.controls[this.config.id].setValue(this.file);
    }

    delete(): void {

        this.file = null;

        this.form.controls[this.config.id].setValue(this.file);
    }
}
