import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {HttpClient} from '@angular/common/http';
import {MatLegacySelectChange as MatSelectChange} from "@angular/material/legacy-select";
import {FormService} from "@core/shared/services/form.service";
import {FieldCollection} from "@lib/form/field";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "@core/shared/services/api.service";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {TranslateService} from "@ngx-translate/core";
import {MenuService} from "@core/shared/services/menu.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Society} from "@core/shared/models/society";
import {Role} from "@core/shared/models/role";
import {Observable, of} from "rxjs";
import {ImageConfig} from "@lib/form/fields/image/image.component";

@Component({
    selector: 'app-page-menu-create',
    templateUrl: './page-menu-create.component.html',
    styleUrls: ['./page-menu-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageMenuCreateComponent implements OnInit {

    public fieldCollection = new FieldCollection();

    public showFieldUrl: boolean = false;

    public showFieldInternUrl: boolean = true;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public roles : string[] = [];

    public locales$: Observable<LocaleItem[]>;

    /**
     *
     * @param _formBuilder
     * @param _http
     * @param formService
     * @param _router
     * @param _apiService
     * @param menuService
     * @param _snackBar
     * @param _translateService
     * @param _activatedRoute
     */
    public constructor(private _formBuilder: UntypedFormBuilder,
                       private _http: HttpClient,
                       public formService: FormService,
                       private _router: Router,
                       private _apiService: ApiService,
                       private menuService: MenuService,
                       private _snackBar: MatSnackBar,
                       private _translateService: TranslateService,
                       private _activatedRoute: ActivatedRoute
    ) {

        this._initLocales();

        this.roles = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'];
        this.formService.form = this._formBuilder.group({
            parent: ['', [Validators.required]],
            image: this._formBuilder.group({
                image: ['', [Validators.required]]
            }),
            typePage: [''],
            internUrl: [''],
            type_user: ['role', Validators.required],
            role: [''],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [''],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this._initTranslationsForm();
        this._buildForm();
    }

    private _initLocales(): void {
        this.locales$ = of(LOCALE_ITEMS);
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                title: ['', [Validators.required]],
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    public ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { societies: Society[] }): void => {
            data.societies.forEach((society: Society) => {
                this.societies.push(
                    {
                        id: society.id,
                        name: society.name
                    });
            });
        });

        this.form.get('locales').valueChanges.subscribe((): void => {

            this._handleTranslationControls();
        });

        this.formService.submitCallback = (): void => {

            const data: object = this.form.value;


            this.menuService.postItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('page.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    public patchRolesValue(role){
        if(this.roles.indexOf(role) > -1){
            const index: number = this.roles.indexOf(role);
            this.roles.splice(index, 1);
        }else{
            this.roles.push(role);
        }
        this.form.get('roles').setValue(this.roles);
    }

    public choiceType(event: MatSelectChange): void {
        this.showFieldUrl = true;
        const fieldUrl: any = this.fieldCollection.getField('internUrl');
        if (fieldUrl) {
            fieldUrl.config.attrs.callbackApi = `${this._apiService.getApiUrl(false, true)}/load/menu/${event.value}`;
        }
    }

    public choiceTypeUrl(): void {
        this.showFieldInternUrl = this.form.value.urlType;
    }

    public changeInfoTitre(event: MatSelectChange): void {
        const fieldTitre: any = this.fieldCollection.getField('title');
        if (fieldTitre) {
            if (event.value) {
                fieldTitre.config.attrs.help = "Pour un item enfant, veuillez ne pas dépasser la limite de 60 caractères";
            } else {
                fieldTitre.config.attrs.help = "Pour un item en racine, veuillez ne pas dépasser la limite de 30 caractères";
            }
        }
    }

    public redirectToList(): void {
        this._router.navigate(['menu/list']);
    }

    private _buildForm(): void {

        this.fieldCollection.addField({
            type: 'text',
            config: {
                id: 'title',
                attrs: {
                    required: true,
                    placeholder: 'Titre',
                    help: "Pour un item en racine, veuillez ne pas dépasser la limite de 30 caractères",
                }
            }
        });


        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'parent',
                attrs: {
                    required: false,
                    placeholder: 'Onglet parent',
                    callbackApi: `${this._apiService.getApiUrl(false, true)}/load/menu/parent`,
                    callbackData: {
                        key: 'id',
                        value: 'name'
                    }

                }
            }
        });

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'typePage',
                attrs: {
                    required: false,
                    placeholder: 'Type de page',
                    choices: [
                        {
                            'pages': 'Page',
                            'categories': 'Categories',
                        }
                    ]
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'internUrl',
                attrs: {
                    required: false,
                    label: this._translateService.instant('menu.url_choice.value'),
                    callbackApi: `${this._apiService.getApiUrl(false, true)}/api/categories`,
                    callbackData: {
                        key: 'id',
                        value: 'title'
                    }
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true,
                }
            }
        })
    }

    get form(): UntypedFormGroup {
        return this.formService.form;
    }


    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    public changeSocieties(event){
        this.form.get('societies').patchValue(event.value);
    }

    public hasRole(role: Role): boolean {
        return this.roles.indexOf(role) >= 0;
    }

    get selectedLocales(): string[] {
        if(!this.form || !this.form.get('locales')){
            return [];
        }

        return this.form.get('locales').value;
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;
        this.translationBuilder.updateItems(locales);
    }

    get imageConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'icon_contenu',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                label: this._translateService.instant('offer.permanentOption.image.value')
            },
        }
    }
}
