import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from "@angular/forms";

@Component({
    selector: 'app-core-offer-presential-form',
    templateUrl: './offer-presential-form.component.html',
    styleUrls: ['./offer-presential-form.component.scss']
})
export class OfferPresentialFormComponent implements OnInit {

    @Input() form: UntypedFormGroup;

    @Input() control: UntypedFormGroup;

    @Input() DOMIdentifier: string;

    @Input() enableMaxField: boolean;

    public presentialMax: number = 19;

    constructor() {
    }

    ngOnInit(): void {

        this.control.get('adultMin').valueChanges.subscribe((): void => {

            this.control.get('adultDefault').updateValueAndValidity();

            this.control.get('adultIncrementalStep').updateValueAndValidity();
        });

        this.control.get('adultMax').valueChanges.subscribe((): void => {

            this.control.get('adultDefault').updateValueAndValidity();

            this.control.get('adultIncrementalStep').updateValueAndValidity();
        });

        this.control.get('adultDefault').valueChanges.subscribe((): void => {

            this.control.get('adultIncrementalStep').updateValueAndValidity();
        });

        this.control.get('childMin').valueChanges.subscribe((): void => {

            this.control.get('childDefault').updateValueAndValidity();

            this.control.get('childIncrementalStep').updateValueAndValidity();
        });

        this.control.get('childMax').valueChanges.subscribe((): void => {

            this.control.get('childDefault').updateValueAndValidity();

            this.control.get('childIncrementalStep').updateValueAndValidity();
        });

        this.control.get('childDefault').valueChanges.subscribe((): void => {

            this.control.get('childIncrementalStep').updateValueAndValidity();
        });
    }

}
