import {Component, Input, OnInit, ViewChild} from '@angular/core';
import allLocales from '@fullcalendar/core/locales-all';
import {DATE_FORMAT} from "@app/data";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferOptionPeriodCalendarService} from "@core/shared/services/offer/offer-option/offer-option-period/offer-option-period-calendar.service";
import {OfferOptionDateService} from "@core/shared/services/offer/offer-option/offer-option-date.service";
import * as moment from "moment";
import {OfferOptionDate} from "@core/shared/models/offer/offer-option/offer-option-date";
import {TranslateService} from "@ngx-translate/core";
import {Moment} from "moment";
import {FullCalendarComponent} from "@fullcalendar/angular";
import {CalendarOptions, DatesSetArg, EventInput} from "@fullcalendar/core";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment'

@Component({
    selector: 'app-core-offer-option-period-calendar',
    templateUrl: './offer-option-period-calendar.component.html',
    styleUrls: ['./offer-option-period-calendar.component.scss']
})
export class OfferOptionPeriodCalendarComponent implements OnInit {

    @Input() option: OfferOption;

    @ViewChild(FullCalendarComponent) calendar: FullCalendarComponent;

    public events: EventInput[] = [];

    public datesInterval: { start: Moment, end: Moment } = {
        start: null,
        end: null
    };

    public calendarOptions: CalendarOptions = {
        plugins: [
            dayGridPlugin,
            interactionPlugin,
            momentPlugin
        ],
        initialView: 'dayGridMonth',
        locales: allLocales,
        locale: null,
        datesSet: this.handleChange.bind(this),
        fixedWeekCount: false
    };

    constructor(
        private _offerOptionPeriodCalendarService: OfferOptionPeriodCalendarService,
        private _offerOptionDateService: OfferOptionDateService,
        private _translateService: TranslateService
    ) {
    }

    ngOnInit(): void {

        this.calendarOptions.locale = this._translateService.currentLang;
    }

    async handleChange(payload: DatesSetArg) {

        this.datesInterval = {
            start: moment(payload.start),
            end: moment(payload.end)
        };

        this.loadEvents();
    }

    public loadEvents(): void {

        this.events = [];

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('dateStart', 'gte', this.datesInterval.start.format(DATE_FORMAT)));

        filterBuilder.addField(new TextFilterField('dateEnd', 'lte', this.datesInterval.end.format(DATE_FORMAT)));

        this._offerOptionDateService.getItemsAPI(this.option.id, filterBuilder.serializedFilters).subscribe((dates: OfferOptionDate[]): void => {

            dates.forEach((date: OfferOptionDate): void => {

                this.addPeriodEvent(date);
            });

            this.calendarOptions.events = this.events;
        });
    }

    public addPeriodEvent(date: OfferOptionDate): void {

        const className: string = '';

        this.events.push(this._offerOptionPeriodCalendarService.createDateEvent(date.id, date.date, className));
    }
}
