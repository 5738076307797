import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Booking, BookingStatus} from "@core/shared/models/booking";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {FieldCollection} from "@lib/form/field";
import {TranslateService} from "@ngx-translate/core";
import {BookingService} from "@core/shared/services/booking.service";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Access} from "@core/shared/models/access";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Promotion} from "@core/shared/models/promotion";
import {parsePrice} from "@core/shared/utils/price";
import {Currency} from "@core/shared/models/currency";
import {Society} from "@core/shared/models/society";
import {OfferIncludedTranslation} from "@core/shared/models/offer/offer-included-translation";
import {OfferIncluded} from "@core/shared/models/offer/offer-included";
import {parseMarkup} from "@core/shared/utils/markup";
import {Country, CountryTranslation} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {Observable, of} from "rxjs";
import {BookingComposition} from "@core/shared/models/booking/booking-composition";
import {Role} from "@core/shared/models/role";
import {BOOKING_STATUS} from "@core/shared/models/booking/booking-status";
import {StatusType} from "@core/shared/models/status-type";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {BehaviorSubject} from "rxjs";
import {BookingCompositionPermanentOption} from "@core/shared/models/booking/booking-composition-permanent-option";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {BookingCompositionParticipant} from "@core/shared/models/booking/booking-composition-participant";

@Component({
    selector: 'page-booking-read',
    templateUrl: './page-booking-read.component.html',
    styleUrls: ['./page-booking-read.component.scss'],
    providers: [
        FormService
    ]
})
export class PageBookingReadComponent implements OnInit {

    public society: Society;

    public booking: Booking;

    public fieldCollection: FieldCollection = new FieldCollection();

    public user: User;

    public currentCollaborator: User = null;

    public statusItems: StatusType[] = BOOKING_STATUS;

    public countries: Country[] = [];

    public submitFormRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public collaboratorSearchConfiguration: SelectSearchConfiguration<User>;

    public collaboratorSearchSourceCallback: (search: string) => Observable<User[]>;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _countryService: CountryService,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _bookingService: BookingService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        public userService: UserService,
        public formService: FormService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this._initCountries();

        this.user = this.userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { society: Society, booking: Booking }): void => {

            this.society = data.society;

            this.booking = data.booking;

            this._buildForm();

            this._hydrateForm();

            this._initCollaborators();

            this._initCurrentCollaborator();

            this._initEvents();
        });

        this.formService.submitCallback = (): void => {

            if(!this.isSubmitEnabled){

                return;
            }

            const data: object = Object.assign(this.form.getRawValue(), {});

            this.submitFormRequest.subscribe((value: boolean): void => {

                if(value) {

                    this._bookingService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe((): void => {

                        this._snackBar.open(this._translateService.instant('booking.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                            duration: 5000
                        });

                        this.redirectToList();
                    });
                }
            });

            // Affichage d'une modale si la configuration implique une modification des stocks / allotements

            if (this.hasStockToUpdateAfterUpdatingStatus) {

                this.openOfferBookingStatusUpdateDialog();
            }
            else {

                this.submitFormRequest.next(true);
            }
        };
    }

    private _buildForm(): void {

        this.formService.form = this._formBuilder.group({
            status: [{value: '', disabled: (!this.isUpdateAllowed || !this.isOfferOwner) }, [Validators.required]],
            comment: [{value: '', disabled: (!this.isUpdateAllowed || !this.isOfferOwner) }],
        });

        this.form.addControl('responsibleUser', this._formBuilder.control({value: '', disabled: (!this.isUpdateCollaboratorAllowed || !this.isInCommercialRelationshipCharge)}));

        if(this.booking.offerOwnerSociety?.id !== this.booking.responsibleSociety.id) {

            this.form.addControl('distributorStatus', this._formBuilder.control({value: '', disabled: !((!this.isUpdateAllowed || this.isInCommercialRelationshipCharge) && !this.isAdmin()) }, [Validators.required]));
            this.form.addControl('commentResponsibleSociety', this._formBuilder.control({value: '', disabled: !((!this.isUpdateAllowed || this.isInCommercialRelationshipCharge) && !this.isAdmin()) }));
        }
    }

    private _hydrateForm(): void {

        this.form.patchValue(Object.assign({...this.booking}, {}));

        if(!this.isSubmitEnabled){

            this.form.disable();
        }
    }

    private _initEvents(): void {

        this.form.get('responsibleUser').valueChanges.subscribe((): void => {

            this._initCurrentCollaborator();
        });
    }

    private _translatedInclusionItemsMap(items: OfferIncluded[]): OfferIncludedTranslation[] {

        return items.map((included: OfferIncluded): OfferIncludedTranslation => {

            return this.translationService.getFallbackTranslation(included.translations);
        });
    }

    private _initCountries(): void {

        this._countryService.getItemsAPI().subscribe((countries: Country[]): void => {

            this.countries = countries;
        });
    }

    private _initCollaborators(): void {

        this.collaboratorSearchConfiguration = {
            multiple: false,
            currentSelectionLabel: 'collaborator.selection.current.value',
            searchActionLabel: 'collaborator.search.action.value',
            selectOptionActionLabel: 'collaborator.option.select.action.value',
            itemLabel: (item: User): string => {

                return `${item.firstName} ${item.lastName}${item.service && item.service.length ? `- ${item.service}` : ''}`;
            }
        }

        this.collaboratorSearchSourceCallback = (search: string): Observable<User[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if(search && search.length){

                params.push(...[
                    `fullName[lk]=${search}`
                ]);
            }

            if(this.isInCommercialRelationshipCharge){

                return this.userService.getPaginationItemSocietyUsers(this.user.id, params).pipe(map((pagination: Pagination<User>): User[] => {

                    return pagination.items;
                }));
            }
            else{

                return of(this.booking.responsibleUser ? [this.booking.responsibleUser] : []);
            }
        }
    }

    private _initCurrentCollaborator(): void {

        this.currentCollaborator = this.form.get('responsibleUser').value || null;
    }

    public parsePriceHT(price: number, currency: Currency = null): string {

        const symbol: string = currency ? currency.symbol : this.currencySymbol;

        return parsePrice(price / 100) + ' ' + symbol + ' ' + this._translateService.instant('offer.price.type.ht.value');
    }

    public parsePriceTTC(price: number, currency: Currency = null): string {

        const symbol: string = currency ? currency.symbol : this.currencySymbol;

        return parsePrice(price / 100) + ' ' + symbol + ' ' + this._translateService.instant('offer.price.type.ttc.value');
    }

    public parsedMarkup(value: number): string {

        return !!value ? (parseMarkup(value) + '%') : '';
    }

    public getPromotion(promotion: Promotion): string {

        return (promotion.value * 100).toFixed(2) + ' %';
    }

    public redirectToList(): void {

        this._router.navigate(['account/booking/list']);
    }

    public getCountryTranslation(code: string): CountryTranslation {

        const item: Country = this.countries.find((country: Country): boolean => {

            return country.code === code;
        });

        if(!item) {

            return null;
        }

        return this.translationService.getFallbackTranslation(item.translations);
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public openOfferBookingStatusUpdateDialog(): void {

        const requestType: string = !this.booking.hasStock ? 'allocation' : 'stock';

        const title: string = this._translateService.instant(`booking.status.${requestType}.update.dialog.title.value`);

        const content: string = this._translateService.instant(`booking.status.${requestType}.update.dialog.content.value`);

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title,
                content
            }
        });

        dialogRef.componentInstance.confirm.subscribe(() =>{

            this.submitFormRequest.next(true);
        });
    }

    public isAdmin(): boolean {

        return this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    }

    get isOnlineSale(): boolean {

        if(this.booking.giftVoucher && this.booking.hasStock) {

            return true;
        }

        return this.booking.type === 'booking' && this.booking.hasStock;
    }

    get isUpdateAllowed(): boolean {

        return this.booking.access === 'write' && this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'BOOKING_EDIT_IS_MINE';
        });
    }

    get isUpdateCollaboratorAllowed(): boolean {

        return this.booking.access === 'write' && this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'BOOKING_EDIT_RESPONSIBLE_USER_IS_MINE';
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get currencySymbol(): string {

        if(!this.booking.channel){

            return '€';
        }

        return this.booking.channel.currency.symbol;
    }

    get isInCommercialRelationshipCharge(): boolean {

        if(!this.society || !this.booking){

            return false;
        }

        return this.society.id === this.booking.responsibleSociety?.id;
    }

    get isOfferOwner(): boolean {

        if(!this.society || !this.booking){

            return false;
        }

        return this.society.id === this.booking.offerOwnerSociety?.id;
    }

    get translatedIncludedsItemFields(): OfferIncludedTranslation[] {

        const matches: OfferIncluded[] = this.booking.offerData.included.filter((included: OfferIncluded): boolean => {

            return included.isIncluded;
        });

        return this._translatedInclusionItemsMap(matches);
    }

    get translatedNotIncludedsItemFields(): OfferIncludedTranslation[] {

        const matches: OfferIncluded[] = this.booking.offerData.included.filter((included: OfferIncluded): boolean => {

            return !included.isIncluded;
        });

        return this._translatedInclusionItemsMap(matches);
    }

    get originUrl(): string {

        return window.location.origin;
    }

    get giftVoucherUrl(): string {

        return window.location.protocol + '//' + 'gift-voucher.' + window.location.hostname;
    }

    get composition(): BookingComposition {

        return this.booking.composition;
    }

    get participants(): BookingCompositionParticipant[] {

        return this.isTypeBooking ? this.booking.composition.participants : this.booking.composition.participants.filter((item: BookingCompositionParticipant): boolean => {

            return item.type === 'child';
        });
    }

    get hasStockToUpdateAfterUpdatingStatus(): boolean {

        /**
         * Le stock doit être mis à jour dans le cas où :
         *
         * On est sur un dossier de vente en ligne:
         *  => Passage de Confirmé / Options posées vers En cours / A traiter /Annulé
         *
         *  Dossier de réservation avec allotement :
         *
         *  => Passage de En cours / A traiter / Annulé vers Confirmé/Options posées
         *  => Passage de Confirmé / Options posées vers En cours / A traiter /Annulé
         */

        const newStatus: BookingStatus = this.form.get('status').value;

        if(this.booking.type === 'request' || this.booking.giftVoucher) {

            return false;
        }

        if(this.booking.status === newStatus) {

            return false;
        }

        const wasValidated: boolean = (['validated', 'option-set'] as BookingStatus[]).includes(this.booking.status);

        const isValidated: boolean = (['validated', 'option-set'] as BookingStatus[]).includes(newStatus);

        const isCancelled: boolean = ([
            'waiting-payment',
            'waiting-validation',
            'to-process',
            'in-progress',
            'canceled'
        ] as BookingStatus[]).includes(newStatus);

        const wasCancelled: boolean = ([
            'waiting-payment',
            'waiting-validation',
            'to-process',
            'in-progress',
            'canceled'
        ] as BookingStatus[]).includes(this.booking.status);

        return (wasValidated && isCancelled && this.booking.hasStock) || (isValidated && wasCancelled);
    }

    get hasOnePermanentOptionOnRequest(): boolean {

        return this.composition.permanentOptions.some((permanentOption: BookingCompositionPermanentOption): boolean => {

            return permanentOption.onRequest;
        });
    }

    get isSubmitEnabled(): boolean {

        return !(['waiting-validation', 'waiting-payment'] as BookingStatus[]).includes(this.booking.status) && !this.isAdmin();
    }

    get isTypeBooking(): boolean {

        return this.booking.type === 'booking';
    }

    get isTypeRequest(): boolean {

        return this.booking.type === 'request';
    }

    get hasAccessCustomer(): boolean {

        return this.userService.hasOneOfThisRoles(this.user, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']);
    }
}

