import {AfterContentInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
    selector: '[appInputTextStripTags]'
})
export class InputTextStripTagsDirective implements AfterContentInit {

    @Input() formControlReference: AbstractControl;

    constructor(private _elementRef: ElementRef) {
    }

    @HostListener('input') onInput() {

        this._apply();
    }

    @HostListener('paste') onPaste() {

        this._apply();
    }

    ngAfterContentInit(): void {

        this._apply();
    }

    private _apply(): void {

        const input: HTMLInputElement = this._elementRef.nativeElement;

        input.value = input.value.replace(/(<([^>]+)>)/gi, '');

        if (this.formControlReference) {

            this.formControlReference.patchValue(input.value);

            this.formControlReference.updateValueAndValidity();
        }
    }
}
