import {Component, OnInit} from '@angular/core';
import {CrudService} from "@core/shared/services/crud.service";

@Component({
    selector: 'app-core-page-hashtag-update',
    templateUrl: './page-hashtag-update.component.html',
    styleUrls: ['./page-hashtag-update.component.scss'],
    providers: [
        CrudService
    ]
})
export class PageHashtagUpdateComponent implements OnInit {

    constructor(
        private _crudService: CrudService
    ) {}

    ngOnInit() {

        this._crudService.setAction('update');
    }
}
