import { Component, OnInit } from '@angular/core';
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Service, ServiceTag, ServiceTranslation} from "@core/shared/models/service";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
  selector: 'app-page-service-subscription-payment-response',
  templateUrl: './page-service-subscription-payment-response.component.html',
  styleUrls: ['./page-service-subscription-payment-response.component.scss']
})
export class PageServiceSubscriptionPaymentResponseComponent implements OnInit {

    public user: User;

    public service: Service;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _activatedRoute: ActivatedRoute,
        private _translationService: TranslationService
    ) {}

    ngOnInit() {

        this.user = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { service: Service }): void => {

            this.service = data.service;
        });
    }

    public goToMangoPayDocuments(): void{

        this._router.navigate(['account/service/list'], { queryParams : { targetTab: 'document' }});
    }

    public goToServices(): void {

        this._router.navigate(['account/service/list']);
    }

    public isOneOfTheseServices(tags: ServiceTag[]): boolean {

        return tags.includes(this.service.tag);
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isSuccess(): boolean {

        return (this._activatedRoute.snapshot.params['response'] === 'success');
    }

    get paymentId(): string {

        return (this._activatedRoute.snapshot.params['reference']);
    }

    get serviceTranslation(): ServiceTranslation {

        return this._translationService.getFallbackTranslation(this.service.translations);
    }
}
