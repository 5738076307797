import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiService} from "@core/shared/services/api.service";
import {CustomerType} from "@core/shared/models/customer/customer-type";

@Injectable({
    providedIn: 'root'
})
export class CustomerTypeService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(params?: string[]): Observable<CustomerType[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/bookings/customers/types`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<CustomerType[]>(url,{
            headers: headers
        });
    }
}
