<mat-dialog-content>

    <p>{{ 'services.request.documents.value' | translate }}</p>

</mat-dialog-content>


<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="back.emit()">{{ 'services.list.item.action.service.back.value' | translate }}</button>
        </div>

        <div class="col_content">
            <button class="btn_cta btn_little" (click)="goToDocuments()" >{{ 'user.account.documents.value' | translate }}</button>
        </div>
    </div>
</mat-dialog-actions>