export type VoucherType = 'isDematerialized' | 'isPhysical';

export interface GiftVoucherType {

    type: VoucherType;

    label: string;
}

export const OFFER_GIFTVOUCHER_TYPES: GiftVoucherType[] = [
    {
        type: 'isDematerialized',
        label: 'giftVoucher.template.form.fields.dematerialized.value'
    },
    {
        type: 'isPhysical',
        label: 'giftVoucher.template.form.fields.physical.value'
    }
];