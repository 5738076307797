export const uniqueArrayOfObjectsByProperty = (data: any[], property: string): any[] => {

    const seen: Set<any> = new Set();

    return [...data].filter(item => {

        if (!seen.has(item[property])) {

            seen.add(item[property]);

            return true;
        }

        return false;
    });
};
