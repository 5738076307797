<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="form-group">
        <textarea placeholder=" " class="form-control" formControlName="refusalReason" id="refusalReason"></textarea>
        <label for="refusalReason">{{'refusalReason.form.value' | translate}}</label>
        <span class="message-help">
            <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: 500 } }}
        </span>
        <mat-error *ngIf="form.get('refusalReason').errors && (form.get('refusalReason').dirty || form.get('refusalReason').touched)">
            <div class="invalid-feedback">
                <div *ngIf="form.get('refusalReason').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                <div *ngIf="form.get('refusalReason').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength: 500 } }}</div>
            </div>
        </mat-error>
    </div>

</form>