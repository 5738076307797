export const environment = {
    production: true,
    apiUrl: 'https://api.ty-win.io',
    enableJiraPlugin: false,
    logoutOn401Error: false,
    logoutOn403Error: true,
    channelSubDomainSuffix: '.ty-win.io',
    channelBaseUrl: 'https://channel.ty-win.io',
    galleryMaxFileSize: 1000000,
    registerUrl: 'https://register.ty-win.io',
    tywinApiVersion: '1.0.0',
    tywinApiDocumentationLink: 'https://documentation.ty-win.io',
    tywinApiProductionBetaEnvironment: 'https://app.beta-ty-win.ovh'
};
