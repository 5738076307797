import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {OfferUpdateConfirmationConfiguration, OfferUpdateConfirmationConfigurationType} from "@core/shared/models/offer/offer-update/offer-update-confirmation";

@Component({
    selector: 'app-core-offer-update-confirmation-dialog',
    templateUrl: './offer-update-confirmation-dialog.component.html',
    styleUrls: ['./offer-update-confirmation-dialog.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferUpdateConfirmationDialogComponent implements OnInit {

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _dialogRef: MatDialogRef<OfferUpdateConfirmationDialogComponent>,
        public formService: FormService,
        @Inject(MAT_DIALOG_DATA) public data: OfferUpdateConfirmationConfiguration
    ) {}

    ngOnInit(): void {

        this._initForm();

        this._initEvents();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({});

        if(this.hasType('gift-voucher')){

            this.form.addControl('giftVoucherManagement', this._formBuilder.control(null, [Validators.required]));
        }

        this.formService.submitCallback = () => {

            this.confirm.emit();
        };
    }

    private _initEvents(): void {

        const events: EventEmitter<void>[] = [
            this.confirm,
            this.cancel
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            });
        });
    }

    public hasType(type: OfferUpdateConfirmationConfigurationType): boolean {

        return this.data.types.includes(type);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
