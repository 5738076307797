<ng-container [formGroup]="form">

    <ng-container [formArrayName]="formArrayName">

        <ng-container [formGroupName]="index">
            <div class="form-group">
                <input type="text" autocomplete="off" value="" placeholder=" " class="form-control" formControlName="value" [id]="DOMIdentifier" appInputTextParsePrice [control]="getPriceControlByFilter(pricePublic, priceType).get('value')">
                <label [for]="DOMIdentifier">
                    {{ labelIdentifier | translate:{ currency: currency } }}
                </label>
                <mat-error *ngIf="getPriceControlByFilter(pricePublic, priceType).get('value').errors && (getPriceControlByFilter(pricePublic, priceType).get('value').dirty || getPriceControlByFilter(pricePublic, priceType).get('value').touched)">
                    <div class="invalid-feedback">
                        <div *ngIf="getPriceControlByFilter(pricePublic, priceType).get('value').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        <div *ngIf="getPriceControlByFilter(pricePublic, priceType).get('value').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                    </div>
                </mat-error>
            </div>
        </ng-container>

    </ng-container>

</ng-container>
