import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {User} from "@core/shared/models/user";

@Component({
  selector: 'app-condition-of-use',
  templateUrl: './condition-of-use-dialog.component.html',
  styleUrls: ['./condition-of-use-dialog.component.scss']
})
export class ConditionOfUseDialogComponent implements OnInit {

  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  public societyName: string;

  constructor(
      private _dialogRef: MatDialogRef<ConditionOfUseDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { societyName: string }
  ) { }

  ngOnInit(): void {

      this.societyName = this.data.societyName;

      this.confirm.subscribe((): void => {

          this._dialogRef.close();
      });
  }

}
