<div class="content_tab">

    <ng-container [formGroup]="parentTermsAndConditions">

        <ng-container formGroupName="society">

            <ng-container formArrayName="termsAndConditions">
                <div class="marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span class="message-help"><mat-icon>info</mat-icon>{{ 'termsAndConditions.info.value' | translate }}</span>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="content_cgvs">
                    <div *ngFor="let _ of termsAndConditionsForm.controls; let i = index" [formGroupName]="indexAsString(i)" class="content_cgv">
                        <div class="header_cgv">
                            <div class="row marges x_between">
                                <div class="col_content y_items_center">
                                    <span class="title">{{ getFormTermsAndConditionControl(i).get('label').value }}</span>
                                </div>
<!--                                <div class="col_content y_items_center">-->
<!--                                    <button type="button" class="btn_cta btn_small btn_red" (click)="deleteTermsAndConditionsControl(i)">{{ 'customerTypology.delete.action.value' | translate }}</button>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="mat-tab-translations">
                            <app-core-translation-add [builder]="getTermsAndConditionsTranslationBuilder(i)" (localeSelect)="getTermsAndConditionsTranslationBuilder(i).addItemControl($event)"></app-core-translation-add>
                            <ng-container formArrayName="translations">
                                <mat-tab-group [@.disabled]="true">
                                    <div *ngFor="let _ of getTermsAndConditionsTranslationBuilder(i).itemsControl.controls; let it = index">
                                        <mat-tab label="{{getTermsAndConditionsTranslationBuilder(i).getLocaleLabel(getTermsAndConditionsTranslationBuilder(i).getItemControl(it).value.locale) | translate }}">
                                            <ng-container [formGroupName]="getTermsAndConditionsTranslationBuilder(i).indexAsString(it)">

                                                <div class="row marges ligne_form">
                                                    <div class="col_12 col_md_6">

                                                        <!-- Téléphone contact -->

                                                        <div class="form-group">

                                                            <app-core-intl-tel-input [formGroup]="getTermsAndConditionsTranslationBuilder(i).getItemControl(it)" [controlName]="'phone'" customPlaceholder="{{'form.user.fields.phoneContact.value' | translate}}" [required]="true"></app-core-intl-tel-input>

                                                            <mat-error *ngIf="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('phone').invalid && (getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('phone').dirty || getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('phone').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('phone').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('phone').errors.validatePhoneNumber">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                    <div class="col_12 col_md_6">

                                                        <!-- Email contact -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="emailTerm" required="required">
                                                            <label for="emailTerm">{{'form.user.fields.email.contact.value' | translate}}</label>
                                                            <span class="message-help"><mat-icon>info</mat-icon>{{ 'termsAndConditions.email.value' | translate }}</span>
                                                            <mat-error *ngIf="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('email').invalid && (getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('email').dirty || getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('email').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">

                                                        <!-- Upload d'un fichier -->

                                                        <div class="form-group">
                                                            <label class="required">{{ 'file.add.action.cgv.value' | translate }}</label>
                                                            <form-field-file [config]="termsAndConditionsFileConfig" [form]="getTermsAndConditionsTranslationBuilder(i).getItemControl(it)" [file]="getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('file').value"></form-field-file>
                                                        </div>

                                                        <div class="form-group">
                                                            <span class="message-help"><mat-icon>info</mat-icon>{{ 'termsAndConditions.warning.value' | translate }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>
                                            <ng-container *ngIf="!getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('id') || !getTermsAndConditionsTranslationBuilder(i).getItemControl(it).get('id').value">
                                                <div class="row marges ligne_form">
                                                    <div class="col_12 x_end">
                                                        <button *ngIf="getTermsAndConditionsTranslationBuilder(i).itemsControl.controls.length > 1" type="button" class="btn_cta btn_small btn_red" (click)="getTermsAndConditionsTranslationBuilder(i).removeItem(it)">{{ 'translation.remove.value' | translate }}</button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-tab>
                                    </div>
                                </mat-tab-group>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row x_center">
                    <button type="button" *ngIf="canAddCgv" class="btn_cta btn_little" (click)="openAddCustomerTypologyDialog()">{{ 'customerTypology.add.action.value' | translate }}</button>
                </div>

            </ng-container>

        </ng-container>

    </ng-container>

</div>
