import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OfferDuration} from '../models/offer/offer-duration';
import {OfferDurationService} from '../services/offer/offer-duration.service';

@Injectable({
    providedIn: 'root'
})
export class OfferDurationItemsResolver  {

    constructor(
        private _offerDurationService: OfferDurationService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        const params: string[] = [
            `sort[position]=ASC`
        ];

        return this._offerDurationService.getItemsAPI(params);
    }
}
