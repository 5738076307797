<div class="page_title">
    <div class="row marges">
        <div class="col y_items_center">
            <h1 class="title">{{ 'giftVoucher.availabilities.update.value' | translate }}</h1>
        </div>
        <div class="col_content">
            <!-- Retour -->

            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                {{ 'giftVoucher.list.back.value' | translate }}
            </button>

        </div>
    </div>
</div>
<div>
    <div class="row">
        <div class="col_12">

            <ng-container *ngIf="(affectedGiftVouchers$ | async) as affectedGiftVouchers">

                <div class="row marges ligne_form" *ngIf="affectedGiftVouchers.length > 0">

                    <div class="form-group col_12 ck-content">

                        <p><strong>{{ 'giftVoucher.availabilities.affectedGiftVouchers.description.value' | translate }} :</strong></p>

                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ 'giftVoucher.availabilities.affectedGiftVouchers.value' | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="affected_gift_vouchers">

                                <div class="row marges">

                                    <div *ngFor="let affectedGiftVoucher of affectedGiftVouchers" class="col_3 y_content_start">

                                        <div class="read_affected_gift_voucher col_12">

                                            <p class="read">
                                                <strong>{{ 'booking.list.fields.reference.value' | translate }} : </strong>
                                                {{ affectedGiftVoucher.reference }}
                                            </p>

                                            <p class="read">
                                                <strong>{{ 'booking.list.fields.createdAt.value' | translate }} : </strong>
                                                {{ affectedGiftVoucher.createdAt | date : 'shortDate' : null : localeId }}
                                            </p>

                                            <p class="read">
                                                <strong>{{ 'giftVoucher.list.fields.beneficiaryFullname.value' | translate }} : </strong>
                                                {{ affectedGiftVoucher.beneficiary.lastName }} {{ affectedGiftVoucher.beneficiary.firstName }}
                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </mat-expansion-panel>

                    </div>

                </div>

            </ng-container>

            <div class="row marges ligne_form">
                <div class="col_content">

                    <!-- Création d'une période -->

                    <button class="btn_cta btn_little" type="button" (click)="openCreatePeriodPanel()">
                        {{ 'giftVoucher.period.update.action.value' | translate }}
                    </button>

                </div>
            </div>
        </div>

        <div class="col_12">

            <div class="bloc_availability calendar-container">

                <app-core-gift-voucher-period-calendar [giftVoucher]="giftVoucher"></app-core-gift-voucher-period-calendar>

            </div>
        </div>
    </div>
</div>

<!-- Création d'une période -->

<ng-container *ngIf="periodCreateConfiguration.enable">

    <div class="offer_date_panel">

        <app-core-gift-voucher-period-create [giftVoucher]="giftVoucher" (close)="closePanels()" (periodCreated)="handlePeriodCreated()"></app-core-gift-voucher-period-create>

    </div>

</ng-container>
