import {Component, OnInit} from '@angular/core';
import {FormService} from '@core/shared/services/form.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {SubscriptionService} from "@core/shared/services/subscription.service";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {PaymentService} from "@core/shared/services/payment.service";
import {PaymentInitialize} from "@core/shared/models/payment-initialize";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {catchError} from "rxjs/operators";
import {Observable, Subject, throwError} from "rxjs";
import {parsePrice} from "@core/shared/utils/price";
import {validateNumericOnly} from "@core/shared/utils/card-number";
import {browserInfos} from "@core/shared/utils/browser-infos";
import {PaymentSubscriptionService} from "@core/shared/services/payment-subscription.service";
import {Card} from "@core/shared/models/card";
import {CardService} from "@core/shared/services/card.service";

@Component({
    selector: 'app-page-subscription-payment',
    templateUrl: './page-subscription-payment.component.html',
    styleUrls: ['./page-subscription-payment.component.scss'],
    providers: [
        FormService
    ]
})
export class PageSubscriptionPaymentComponent implements OnInit {

    public user: User;

    public cardRegistrationUrl: string;

    public subscriptions: SocietySubscription[] = [];

    public loaderPayment: Subject<boolean> = new Subject<boolean>();

    public defaultPayment: boolean = false;

    public toProcessSubscription: SocietySubscription = null;

    public isPaymentDataRequired: boolean = false;

    public isSubmitAllowed: boolean = false;

    public card: Card;

    public paymentFormFieldsInitialized: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _userService: UserService,
        private _paymentService: PaymentService,
        private _subscriptionService: SubscriptionService,
        private _paymentSubscriptionService: PaymentSubscriptionService,
        private _cardService: CardService,
        public formService: FormService
    ){}

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this.defaultPayment = !this.user.society.isValidAccount;

        this._initForm();

        this._loadSubscriptions();

        this._initCard();
    }

    private _loadSubscriptions(): void {

        this._subscriptionService.getItemSubscriptionBySocietyWithoutPagination(this.user.society.id, ['paymentStatus[in][]=0', 'paymentStatus[in][]=-1']).subscribe((subscriptions: SocietySubscription[]): void => {

            this.subscriptions = subscriptions;

            if(this.subscriptions.length){

                const pendingSubscription = this.subscriptions.find((societySubscription: SocietySubscription): boolean => {

                    return societySubscription.paymentStatus === 0;
                });

                const failedSubscription = this.subscriptions.find((societySubscription: SocietySubscription): boolean => {

                    return societySubscription.paymentStatus === -1;
                });

                this.toProcessSubscription = failedSubscription || pendingSubscription;

                this._paymentInitialize(this.user.society.id);
            }
            else{

                this._snackBar.open(this._translateService.instant('payment.noAccess.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
            }
        });
    }

    private _initCard(): void {

        this._cardService.getSocietyItem(this.user.society.id).subscribe((card: Card|null): void => {

            this.card = card;
        });
    }

    private _paymentInitialize(societyId: number): void {

        this.isSubmitAllowed = false;

        this._paymentSubscriptionService.paymentInitialize(societyId).subscribe((paymentInitialize: PaymentInitialize): void => {

            this.isPaymentDataRequired = !!Object.values(paymentInitialize).length;

            if(this.isPaymentDataRequired){

                this.cardRegistrationUrl = paymentInitialize.url;

                if(!this.paymentFormFieldsInitialized){

                    this._initPaymentFormFields();
                }

                this.form.get('accessKey').patchValue(paymentInitialize.accessKeyRef);

                this.form.get('data').patchValue(paymentInitialize.data);

                this.form.get('id').patchValue(paymentInitialize.id);
            }

            this.isSubmitAllowed = true;
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({});

        this.formService.submitCallback = (): void => {

            this.loaderPayment.next(true);

            const dataToValidate: object = {
                societySubscription: {
                    id: this.toProcessSubscription.id
                },
                browserInfo: browserInfos()
            };

            if(this.isPaymentDataRequired){

                const dataToCardRegistration = `cardNumber=${this.form.get('cardNumber').value.replace(/\s/g, '')}&cardExpirationDate=${this.form.get('cardExpirationDate').value.replace('/', '')}&cardCvx=${this.form.get('cardCvx').value}&accessKeyRef=${this.form.get('accessKey').value}&data=${this.form.get('data').value}`;

                this._paymentService.cardRegistrationUrl(this.cardRegistrationUrl, dataToCardRegistration).subscribe((data: ArrayBuffer): void => {

                    dataToValidate['cardRegistration'] = {
                        "data": data,
                        "id": this.form.get('id').value
                    };

                    this._validatePayment(dataToValidate);
                });
            }
            else{

                this._validatePayment(dataToValidate);
            }
        }
    }

    private _initPaymentFormFields(): void {

        this.form.addControl('cardNumber', this._formBuilder.control(null, [Validators.required, (control: UntypedFormControl): (object|null) => {

            const field = {...control};

            let cardNumber = field.value;

            cardNumber = (cardNumber) ? cardNumber.replace(/\s/g, "") : "";

            return validateNumericOnly(cardNumber) === false ? {invalidNUmberCard: {valid: false}} : null;
        }]));

        this.form.addControl('cardExpirationDate', this._formBuilder.control(null, [Validators.required, (control: UntypedFormControl): (object|null) => {

            const field = {...control};

            let cardDate = field.value ? field.value.replace("/", "") : "";

            const currentDate = new Date();

            if (cardDate.length === 4) {

                const year = parseInt(cardDate.substr(2, 2), 10) + 2000;

                const month = parseInt(cardDate.substr(0, 2), 10);

                if (month > 0 && month <= 12) {

                    const currentYear = currentDate.getFullYear();

                    if (currentYear < year){

                        return null;
                    }

                    if (currentYear === year) {

                        const currentMonth = currentDate.getMonth() + 1;

                        if (currentMonth <= month){

                            return null;
                        }
                    }

                    return { invalidDatePast: { valid: false } };
                }
            }

            return { invalidFormatDate: { valid: false } };
        }]));

        this.form.addControl('cardCvx', this._formBuilder.control(null, [Validators.required, (control: UntypedFormControl): (object|null) => {

            const field = {...control};

            const cvv = field.value ? field.value.trim() : "";

            if (validateNumericOnly(cvv) === true) {

                if (cvv.length === 3) {

                    return null;
                }
            }

            return { invalidFormatCvv: { valid: false } };
        }]));

        this.form.addControl('accessKey', this._formBuilder.control(null, [Validators.required]));

        this.form.addControl('data', this._formBuilder.control(null, [Validators.required]));

        this.form.addControl('id', this._formBuilder.control(null, [Validators.required]));

        this.paymentFormFieldsInitialized = true;
    }

    private _validatePayment(dataToValidate: object): void {

        this._paymentSubscriptionService.paymentValidate(this.user.society.id, dataToValidate).pipe(catchError((): Observable<never> => {

            this.loaderPayment.next(false);

            this._paymentInitialize(this.user.society.id);

            this._snackBar.open(this._translateService.instant('payment.error.card.value'), this._translateService.instant('error.notification.close.value'), {
                duration: 8000
            });

            return throwError(this._translateService.instant('payment.error.card.value'));

        })).subscribe((data: { status: string, redirectUrl: string, paymentId: string }): void => {

            this.loaderPayment.next(false);

            if (data.status === 'REDIRECT') {

                location.href = data.redirectUrl;
            }

            if (data.status === 'SUCCESS') {

                if(data.paymentId){

                    this._router.navigate([`account/subscription/payment-response/success/${data.paymentId}`]);
                }
                else{

                    this._router.navigate([`account/subscription/payment-response/success`]);
                }
            }
        });
    }

    public formatPrice(price: number): string {

        return parsePrice(price / 100) + ' €';
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get isCardLoaded(): boolean {

        return this.card !== undefined;
    }
}
