<div class="form-group">
    <div class="row marges">
        <span class="col_content y_items_center mat-label">{{ (options.label) ? options.label : 'Ajouter une image :' }}</span>
        <button type="button" (click)="openGalleryDialog()" [disabled]="!initButton" class="btn_cta btn_little">{{ 'media.image.choice.action' | translate }}</button>
    </div>
</div>
<div class="liste-images" >

    <div *ngIf="image" class="image" >
        <div class="actions">
            <button type="button" mat-stroked-button (click)="delete()" class="delete mat-warn" matTooltip="{{ 'media.image.delete.action' | translate }}" matTooltipPosition="left">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div class="infos">
            <div class="big-label" *ngIf="options.enableTitle">
                <span class="label">{{ 'element.types.image.title' | translate }}</span>
                <input type="text" [(ngModel)]="image.title" (ngModelChange)="update()"/>
            </div>
            <div class="big-label" *ngIf="options.enableSubtitle">
                <span class="label">{{ 'element.types.image.subtitle' | translate }}</span>
                <input type="text" [(ngModel)]="image.subtitle" (ngModelChange)="update()"/>
            </div>
            <div class="big-label" *ngIf="options.enableAlt">
                <span class="label">{{ 'element.types.image.alt' | translate }}</span>
                <input type="text" [(ngModel)]="image.alt" (ngModelChange)="update()"/>
            </div>
            <div class="big-label" *ngIf="options.enableLink">
                <span class="label">{{ 'element.types.image.link' | translate }}</span>
                <input type="text" [(ngModel)]="image.link" (ngModelChange)="update()"/>
            </div>
            <div class="no-padding" *ngIf="options.enableTargetBlank">
                <label class="input-checkbox">
                    <input class="checkbox-target" type="checkbox" [(ngModel)]="image.target" (ngModelChange)="update()"/>
                    <span class="label">{{ 'element.types.image.targetBlank' | translate }}</span>
                </label>
            </div>
        </div>
        <div class="formats">
            <div *ngFor="let format of image.formats | orderBy: '+ratio'" class="form
            at image-element">

                <div [ngClass]="format.selected && !options.format ? 'selected' : ''" (click)="onSelectedFormat(format)" *ngIf="(!options.format) || (options.format && options.format == format.name)">
                    <mat-icon>check</mat-icon>
                    <div class="visuel">
                        <img
                                [src]="getThumbnailPath(format)"
                                [height]="(thumbnailWidth / format.ratio)"
                                [width]="thumbnailWidth"
                                (load)="format.loaded = true;"
                        />
                    </div>
                    <span class="loading fa fa-circle-o-notch" [style.display]="format.loaded ? 'none' : 'block'"></span>
                </div>
                <div class="actions" *ngIf="(!options.format) || (options.format && options.format == format.name)">
                    <button type="button" (click)="openCropperDialog(format)"><mat-icon>crop</mat-icon>{{ 'media.image.format.modify.action' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
