import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {ComponentPortal} from "@angular/cdk/portal";
import {MaterialTooltipHtmlComponent} from "@core/components/material/material-tooltip/material-tooltip-html/material-tooltip-html.component";
import {Overlay, OverlayPositionBuilder, OverlayRef} from "@angular/cdk/overlay";

@Directive({
    selector: '[appCoreMaterialTooltipHtml]'
})
export class MaterialTooltipHtmlDirective {

    @Input() content: string;

    @HostListener('mouseenter') onMouseEnter() {

        this._showTooltip();
    }

    @HostListener('mouseleave') onMouseLeave() {

        this._hideTooltip();
    }

    private _overlayRef: OverlayRef;

    constructor(
        private element: ElementRef,
        private overlay: Overlay,
        private positionBuilder: OverlayPositionBuilder,
    ) {}

    private _showTooltip(): void {

        const positionStrategy = this.positionBuilder
            .flexibleConnectedTo(this.element)
            .withPositions([
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                    offsetY: 12
                },
            ]);

        this._overlayRef = this.overlay.create({ positionStrategy });

        const tooltipPortal = new ComponentPortal(MaterialTooltipHtmlComponent);

        const tooltipInstance = this._overlayRef.attach(tooltipPortal);

        tooltipInstance.instance.content = this.content;
    }

    private _hideTooltip(): void {

        if (this._overlayRef) {

            this._overlayRef.detach();
        }
    }
}
