<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <!-- Actions -->

    <div class="page_title" *ngIf="configuration.showSubmitButton">
        <div class="row marges">

            <div class="col_content y_items_center">

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>
            </div>
        </div>
    </div>

    <ng-container [formGroup]="form">

        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Sélection de la période -->

                <div class="form-group date_range">
                    <label>{{'promotion.period.select.action.value' | translate}}</label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate readonly [placeholder]="'filter.dateInterval.start.value' | translate" formControlName="dateStart" (click)="picker.open()">
                        <input matEndDate  readonly [placeholder]="'filter.dateInterval.end.value' | translate" formControlName="dateEnd" (click)="picker.open()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <div class="row marges">
                        <div class="col_6">
                            <mat-error *ngIf="form.get('dateStart').errors && (form.get('dateStart').dirty || form.get('dateStart').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('dateStart').errors.required">{{ 'promotion.period.dateStart.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                        <div class="col_6">
                            <mat-error *ngIf="form.get('dateEnd').errors && (form.get('dateEnd').dirty || form.get('dateEnd').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('dateEnd').errors.required">{{ 'promotion.period.dateEnd.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </ng-container>

</form>
