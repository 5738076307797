import {Component, Input} from '@angular/core';
/**
 * Champ input text
 */
@Component({
    selector: 'app-cms-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss']
})
export class CmsTextComponent{


    /**
     * Valeur du champ texte
     */
    @Input() text : any;



    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor() {
    }


}
