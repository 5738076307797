<section class="bloc_recherche">
    <div class="box_md marges">
        <div class="row">
            <div class="col_12 x_center">
                <div class="ck-content">
                    <h1 class="titre_h1">404</h1>
                </div>
            </div>
        </div>
    </div>
</section>