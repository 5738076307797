import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {SubdomainResponse} from "@core/shared/models/subdomain";

@Injectable({
    providedIn: 'root'
})
export class SubDomaineService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public isValidDomain(data: object): Observable<SubdomainResponse> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<SubdomainResponse>(`${this._apiService.getApiUrl(false, true)}/channels/validate/subDomain`, data, {
            headers: headers
        });
    }
}
