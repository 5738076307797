import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-core-page-dashboard',
    templateUrl: './page-dashboard.component.html',
    styleUrls: ['./page-dashboard.component.scss']
})
export class PageDashboardComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
