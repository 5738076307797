import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public openFileInNewTab(id: number): void {

        const url: string = `${this._apiService.getApiUrl(false, true)}/files/${id}/download`;

        this._httpClient.get(url, {
            responseType: 'blob'
        }).subscribe((blob: Blob): void =>{

            const objectUrl: string = URL.createObjectURL(blob);

            window.open(objectUrl, '_blank');

            URL.revokeObjectURL(objectUrl);
        });
    }
}
