import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {SocietyIp} from "@core/shared/models/society/society-ip";

@Injectable({
    providedIn: 'root'
})
export class SocietyIpService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(societyId: number, params?: string[]): Observable<SocietyIp[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${societyId}/ips`;

        params = params || [];

        params.push('disablePagination=1');

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<SocietyIp[]>(url, {
            headers: headers
        });
    }

    public updateItemsAPI(societyId: number, data: object[]): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<void>(`${this._apiService.getApiUrl(false, true)}/societies/${societyId}/ips`, data,{
            headers: headers
        });
    }
}
