<table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

    <!-- Filtres -->

    <ng-container>

        <!-- Actions -->

        <ng-container [matColumnDef]="getFilterColumnDef('actions')">
            <th mat-header-cell *matHeaderCellDef>
                <button (click)="resetFilters()" mat-icon-button>
                    <mat-icon>loop</mat-icon>
                </button>
            </th>
        </ng-container>

        <!-- Nom du groupe -->

        <ng-container [matColumnDef]="getFilterColumnDef('name')">
            <th mat-header-cell *matHeaderCellDef>
                <app-core-text-filter #filter [builder]="filterBuilder" [label]="'group.name.value' | translate" [key]="'name'" [operator]="'lk'"></app-core-text-filter>
            </th>
        </ng-container>

        <!-- Commentaire interne Ty-Win -->

        <ng-container [matColumnDef]="getFilterColumnDef('comment')">
            <th mat-header-cell *matHeaderCellDef>
                <app-core-text-filter #filter [builder]="filterBuilder" [label]="'comment.internal.value' | translate" [key]="'comment'" [operator]="'lk'"></app-core-text-filter>
            </th>
        </ng-container>

        <!-- Date de création -->

        <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
            <th mat-header-cell *matHeaderCellDef>
                <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
            </th>
        </ng-container>

        <!-- Date de mise à jour -->

        <ng-container [matColumnDef]="getFilterColumnDef('updatedAt')">
            <th mat-header-cell *matHeaderCellDef>
                <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'updatedAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
            </th>
        </ng-container>

    </ng-container>

    <!-- Liste des données -->

    <ng-container>

        <!--Actions-->

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
            <mat-cell *matCellDef="let element; let i = index">
                <button mat-button (click)="redirectToUpdate(element.id)">
                    <mat-icon>edit</mat-icon>
                </button>

                <button mat-button (click)="openDeleteItemDialog(element.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!-- Nom du groupe -->

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'group.name.value' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ getModel(element).name }}</td>
        </ng-container>

        <!-- Commentaire interne Ty-Win -->

        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>{{ 'comment.internal.value' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ getModel(element).comment }}</td>
        </ng-container>

        <!-- Date de création -->

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>{{ 'createdAt.value' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ getModel(element).createdAt | date : 'dd/MM/yyyy' : null : localeId }}</td>
        </ng-container>

        <!-- Date de mise à jour -->

        <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef>{{ 'updatedAt.value' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ getModel(element).updatedAt | date : 'dd/MM/yyyy' : null : localeId }}</td>
        </ng-container>

    </ng-container>

    <!-- Entêtes de colonne -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <!-- Filtres -->

    <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
