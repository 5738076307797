<h1 mat-dialog-title>{{ 'offer.catalog.request.revoke.value' | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <mat-dialog-content>
        <div class="ck-content">
            <p [innerHTML]="'offer.catalog.request.revoke.confirm.question.value' | translate"></p>
        </div>
        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Commentaire -->

                <div class="form-group">
                    <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                    <label for="comment">{{'comment.value' | translate}}</label>
                </div>

            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="row marges x_center">
            <div class="col_content">
                <button type="button" class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
            </div>
            <div class="col_content">
                <button type="submit" class="btn_cta btn_little">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
            </div>
        </div>
    </mat-dialog-actions>
</form>

