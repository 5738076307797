import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {SocietyProvider} from "@core/shared/models/society/society-provider";
import {SocietyProviderService} from "@core/shared/services/society/society-provider.service";

@Injectable({
    providedIn: 'root'
})
export class SocietyProviderItemResolver  {

    constructor(
        private _service: SocietyProviderService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return this._service.getItemAPI(parseInt(route.paramMap.get('id')));
    }
}
