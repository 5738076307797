import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {Router} from '@angular/router';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {MatSort} from "@angular/material/sort";
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import {PromotionSubscriptionService} from "@core/shared/services/promotion-subscription.service";
import {UserService} from "@core/shared/services/user.service";
import {PROMOTION_SUBSCRIPTION_USED, PromotionSubscription} from "@core/shared/models/promotion-subscription";
import {User} from "@core/shared/models/user";
import {Access} from "@core/shared/models/access";

@Component({
    selector: 'app-core-page-promotion-subscription-list',
    templateUrl: './page-promotion-subscription-list.component.html',
    styleUrls: ['./page-promotion-subscription-list.component.scss']
})
export class PagePromotionSubscriptionListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'name',
        'lastname',
        'firstName',
        'email',
        'society',
        'mentionedSociety',
        'isUsed'
        // 'state',
    ];

    filterBuilder: FilterBuilder;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public items: PromotionSubscription[];

    public user: User;


    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _promotionSubscriptionService: PromotionSubscriptionService,
        private _snackBar: MatSnackBar,
        private _userService:UserService
    ) {
    }

    ngOnInit() {
        this.user = this._userService.currentUser.value;

        this._initFilterBuilder();

    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._promotionSubscriptionService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: PromotionSubscription[]): void => {

            this.items = data;
        })
        ;
    }


    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._promotionSubscriptionService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                map(this.mapApiResult)
            ).subscribe((data: PromotionSubscription[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._promotionSubscriptionService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: PromotionSubscription[]): void => {

            this.items = data;
        });
    }

    public openDeleteItemDialog(id: number): void {

        const page: PromotionSubscription = this.items.find((item: PromotionSubscription): boolean => {

            return item.id === id;
        });


        const title : string = this._translateService.instant('promotionSubscription.restricted.actions.delete.value');

        const content : string = this._translateService.instant('promotionSubscription.restricted.actions.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._promotionSubscriptionService.deleteItemAPI(page.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('promotionSubscription.restricted.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._promotionSubscriptionService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: PromotionSubscription[]): void => {
                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['/account/subscription/promotion/create']);
    }

    public getModel(item: PromotionSubscription): PromotionSubscription {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);

        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    public isPromotionSubscriptionUsed(promotionSubscription: PromotionSubscription): boolean {

        return promotionSubscription.status === PROMOTION_SUBSCRIPTION_USED;
    }

    get mapApiResult(): (data: Pagination<PromotionSubscription>) => PromotionSubscription[] {

        return (data: Pagination<PromotionSubscription>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public hasAccessCreate(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'PROMOTION_SUBSCRIPTION_CREATE';
        });

        return (accesses.length > 0)
    }

    public hasAccessDelete(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'PROMOTION_SUBSCRIPTION_DELETE';
        });

        return (accesses.length > 0)
    }

    public getLastName(promotionSubscription : PromotionSubscription){
        if(promotionSubscription.society){
            return promotionSubscription.society.admin.lastName;
        }
        return promotionSubscription.customer.lastName;
    }

    public getFirstName(promotionSubscription : PromotionSubscription){
        if(promotionSubscription.society){
            return promotionSubscription.society.admin.firstName;
        }
        return promotionSubscription.customer.firstName;
    }

    public getEmail(promotionSubscription : PromotionSubscription){
        if(promotionSubscription.society){
            return promotionSubscription.society.admin.email;
        }
        return promotionSubscription.customer.email;
    }

    public goToView(id): void {
        this._router.navigate(['/account/subscription/promotion/view/', id]);
    }
}
