import {Directive, HostListener, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Directive({
    selector: '[date-card]'
})
export class DateCardDirective {

    @Input() control: AbstractControl;

    @HostListener('input', ['$event'])

    onKeyDown(event: KeyboardEvent) {

        const input = event.target as HTMLInputElement;

        let trimmed = input.value.replace(/\s+/g, '');

        trimmed = trimmed.replace("/", '');

        if (trimmed.length > 4) {

            trimmed = trimmed.substr(0, 4);
        }

        if(!trimmed.match(/^[0-9]+$/)){

            trimmed = trimmed.substr(0,-1);
        }

        let numbers = [];

        for (let i = 0; i < trimmed.length; i += 2) {

            numbers.push(trimmed.substr(i, 2));
        }

        this.control.patchValue(numbers.join('/'));

        this.control.updateValueAndValidity();
    }
}
