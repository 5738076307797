import {Currency} from "../../currency";

export type OfferOptionPriceTypeType = 'HT' | 'TTC';

export interface OfferOptionPriceType {

    type: OfferOptionPriceTypeType;

    label: string;
}

export const OFFER_OPTION_PRICE_TYPES: OfferOptionPriceType[] = [
    {
        type: 'HT',
        label: 'taxes.excluding.value'
    },
    {
        type: 'TTC',
        label: 'taxes.including.value'
    }
];

export type OfferOptionPricePublicType = 'child' | 'adult';

export interface OfferOptionPricePublic {

    type: OfferOptionPricePublicType;

    label: string;
}

export const OFFER_OPTION_PRICE_PUBLICS: OfferOptionPricePublic[] = [
    {
        type: 'adult',
        label: 'offer.public.adult.value'
    },
    {
        type: 'child',
        label: 'offer.public.child.value'
    }
];


export interface OfferOptionPrice {

    id: number;

    type: OfferOptionPriceTypeType;

    public: OfferOptionPricePublicType;

    currency: Currency;

    value: number;
}
